import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";

import Text from "src/components/elements/Text";

import { useReverseTrial } from "../hooks";

export function ReverseTrialBadge() {
  const { team } = useContext(TeamContext);
  const { isReverseTrialRunning } = useReverseTrial();

  return (
    isReverseTrialRunning() && (
      <NavLink
        to="/teams/subscription"
        className="flex flex-col text-[15px] justify-center w-full rounded-10 px-5 py-4 bg-[#C5E4FA] mb-8"
      >
        <Text>{team?.subscription?.remainingTrialDays} trial days left</Text>
        <span className="font-semibold underline">Subscribe now</span>
      </NavLink>
    )
  );
}
