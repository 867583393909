import React, { useContext } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import PageLayout from "src/components/PageLayout";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { TEAMS_SUBSCRIPTION_PLANS } from "src/models/team-subscription";

import {
  teamsUpgradeSources,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";

import CustomRatesPlaceholder from "public/assets/img/teams-features/custom-rates-placeholder.svg";

export default CustomRatesUpgrade;

const FEATURES = [
  { label: "Easily change mileage rates", icon: "car-side" },
  { label: "Track your rate history", icon: "history" },
  { label: "Manage your reimbursements", icon: "money-insert" },
];

function CustomRatesUpgrade({ onPlanUpgradeCallback }) {
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.SETTINGS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    onDone: onPlanUpgradeCallback,
    currentSubPlan: team.subscription?.plan,
  };
  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  const handleUpgradeToTeamsPro = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startGetProPlanFlow();
  };

  return (
    <PageLayout className="page-team-locations bg-beige">
      <PageLayout.Main>
        <div className="lg:h-full flex gap-16 xl:gap-32 justify-between items-start lg:items-center pt-8 pl-8 pr-8 lg:pr-0 lg:pt-0 lg:pl-[124px]">
          <div className="lg:max-w-[345px]">
            <div className="flex flex-col items-start gap-4">
              <MobMenu />
              <Text custom className="text-blue text-15 font-medium">
                Introducing
              </Text>
            </div>
            <h3 className="mt-4">Custom Rates</h3>
            <Text custom paragraph className="mt-[30px] text-15">
              Custom rates allows you to set custom mileage rates for your
              entire team. Upgrade to Teams Pro to start using the Custom Rates
              feature.
            </Text>
            <ul className="mt-[30px] flex flex-col gap-2">
              {FEATURES.map(({ label, icon }) => (
                <li key={label} className="flex items-center gap-2">
                  <Icon name={icon} color="green" />
                  <Text md>{label}</Text>
                </li>
              ))}
            </ul>
            <Button
              lg
              onClick={handleUpgradeToTeamsPro}
              className="mt-[30px] font-medium w-[160px]"
            >
              Upgrade now
            </Button>
          </div>
          <CustomRatesPlaceholder className="hidden lg:block" />
        </div>
      </PageLayout.Main>
      {renderGetProPlanFlow()}
    </PageLayout>
  );
}
