import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { NavLink, useParams } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";

import { apolloClient } from "src/components/context/ApolloContext";

import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import MiqLoader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Page from "src/components/elements/Page";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { ReportCard } from "src/components/pages/DriverDashboard/SharedReports";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { getContactSharedReportByToken } from "src/services/reports";
import { clean, getAccessToken } from "src/services/storage";
import {
  DownloadFormats,
  LoginSources,
  Pages,
  SignupSources,
  TeamsSignupSource,
  WebPages,
  trackReportDownloaded,
  trackWebPageViewed,
} from "src/services/tracking";
import { parseJwt, prepareTeamsOnboardingUrl } from "src/services/utils";

import { version } from "src/env";
import { VALIDATE_EMAIL } from "src/graphql/mutations";

import IndividualWordMark from "public/assets/img/nav/wordmark-individual.svg";

import { CreateTeamPage } from "../DriverDashboard/Teams/CreateTeam";

export default ContactLightweightDashboard;

const ContactUserContext = createContext();

export function ContactUserProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const checkUserExists = async (token) => {
    const { email } = parseJwt(token);
    const accessToken = getAccessToken();
    if (accessToken) {
      const { username } = parseJwt(accessToken);
      if (username === email) {
        return window.location.replace("/user/shared-reports");
      } else {
        clean();
      }
    }
    const {
      data: {
        validateEmail: { userExists },
      },
    } = await apolloClient.mutate({
      mutation: VALIDATE_EMAIL,
      variables: { email },
    });
    setUserExists(!!userExists);
    setReady(true);
  };

  return (
    <ContactUserContext.Provider value={{ userExists, checkUserExists, ready }}>
      {children}
    </ContactUserContext.Provider>
  );
}

function ContactLightweightDashboard() {
  const { miqDashboardContactsLightweightDashWeb } = useFlags();
  const { path } = useRouteMatch();

  if (
    typeof miqDashboardContactsLightweightDashWeb !== "undefined" &&
    !miqDashboardContactsLightweightDashWeb
  ) {
    return window.location.replace("/");
  }

  return (
    <ContactUserProvider>
      <div className="w-screen max-w-full min-h-screen flex bg-white">
        <Nav />
        <Switch>
          <Route path={`${path}/report/:token`} exact>
            <Page title="Shared reports">
              <SharedReportPage />
            </Page>
          </Route>
          <Route path={`${path}/teams/:token?`}>
            <Page title="Teams">
              <TeamCreatePage />
            </Page>
          </Route>
        </Switch>
      </div>
    </ContactUserProvider>
  );
}

function getTeamUpsellCtaLink(userExists) {
  let ctaLink = `/signup?product=teams&recurring=mo&sub=pro&singleSku=true&src=${SignupSources.CONTACTS_DASHBOARD}`;
  if (userExists) {
    ctaLink = `/login?src=${LoginSources.CONTACTS_DASHBOARD}&goTo=${
      prepareTeamsOnboardingUrl(
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
        TEAM_SUBSCRIPTION_TYPES.MONTH,
        TeamsSignupSource.CONTACTS_DASHBOARD_NON_DRIVER
      ) + "&singleSku=true"
    }`;
  }
  return ctaLink;
}

function getAuthLink(userExists) {
  return userExists
    ? `/login?src=${LoginSources.CONTACTS_DASHBOARD}&goTo=/user/shared-reports`
    : `/signup?src=${SignupSources.CONTACTS_DASHBOARD}&goTo=/user/shared-reports`;
}

function TeamCreatePage() {
  const { token } = useParams();

  const { userExists, checkUserExists, ready } = useContext(ContactUserContext);
  const ctaLink = getTeamUpsellCtaLink(userExists);

  useEffect(() => {
    checkUserExists(token);
  }, []);
  if (!ready) return null;

  return (
    <div className="page-content overflow-x-hidden">
      <CreateTeamPage
        onClick={() => {
          window.location.href = ctaLink;
        }}
      />
    </div>
  );
}

function SharedReportPage() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportDetails, setReportDetails] = useState(null);

  const { userExists, checkUserExists, ready } = useContext(ContactUserContext);

  useEffect(() => {
    checkUserExists(token);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const reportData = await getContactSharedReportByToken(token);
      setReportDetails(reportData);
      setLoading(false);
    };
    fetchData();
    trackWebPageViewed({
      currentPage: WebPages.SHARED_REPORTS,
    });
  }, [token]);

  const handleDownload = (type = "pdf") => {
    let downloadFormat = DownloadFormats.PDF;
    if (type === "csv") {
      downloadFormat = DownloadFormats.CSV;
    } else if (type === "xls") {
      downloadFormat = DownloadFormats.XLS;
    }
    trackReportDownloaded({
      page: Pages.SHARED_REPORTS,
      subscriptionId: 0,
      downloadFormat,
    });
  };

  const ctaLink = getTeamUpsellCtaLink(userExists);

  if (!ready) return null;

  return (
    <div className="page-content flex flex-col">
      <div className="page-header relative">
        <div className="title-row">
          <div>
            <div className="flex items-center">
              <MobMenu />
              <div className="flex flex-grow items-center justify-between">
                <h3>Shared Reports</h3>
                {!loading && (
                  <a
                    href={getAuthLink(userExists)}
                    className="mds-button primary"
                  >
                    <span className="mds-button-content">
                      <span className="mds-button-label">
                        {userExists ? "Log in" : "Sign up"}
                      </span>
                    </span>
                  </a>
                )}
              </div>
            </div>
            <div className="flex flex-grow items-center justify-between">
              <Text
                paragraph
                custom
                color="black/70"
                className="mt-1 text-[15px]"
              >
                Reports you have received from other MileIQ drivers.
              </Text>
              {!loading && (
                <Text paragraph color="black/70" className="mt-1 text-[13px]">
                  {userExists
                    ? "Log in to view all reports"
                    : "Create a free account to view all reports"}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="p-[30px] flex-grow">
        {loading ? (
          <MiqLoader />
        ) : (
          <div className="flex flex-wrap gap-[20px] laptop:gap-[10px]">
            <ReportCard
              reportDetails={reportDetails}
              onDownload={handleDownload}
            />
            <SignupCtaCard reportDetails={reportDetails} ctaLink={ctaLink} />
          </div>
        )}
      </div>
    </div>
  );
}

function SignupCtaCard({ reportDetails, ctaLink }) {
  return (
    <div className="relative  border border-border-1 rounded flex flex-col shadow-[0px_5px_10px_rgba(0,0,0,0.04)]">
      <div className="absolute w-full top-[12px] left-[10px] h-[90%] border border-border-1 rounded flex flex-col shadow-[0px_5px_10px_rgba(0,0,0,0.04)]"></div>
      <div className="absolute w-full h-full top-0 left-0 bg-white rounded"></div>
      <div className="blur-md select-none flex flex-col bg-white">
        <ReportCard reportDetails={reportDetails} />
      </div>
      <div className="absolute z-[999] top-0 right-0 w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center gap-[16px] px-[40px] laptop:px-[30px]">
          <Icon name="lock-open" />
          <Text center custom className="text-[15px] font-medium">
            Save time and money with Teams Pro
          </Text>
          <Text center custom className="text-[15px]" color="black/70">
            Automatic mileage tracking for the whole team, plus faster approvals
            for you.
          </Text>
          <a href={ctaLink} className="mds-button primary">
            <span className="mds-button-content">
              <span className="mds-button-label">Start free trial</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

const navItems = [
  {
    isGroupTitle: true,
    title: "Menu",
  },
  {
    label: "Shared Reports",
    // originally user lands on `/contact/report/<token>` page by following the link in the email.
    // but since we have Teams page user can view, we need to keep the token in Teams page url as well,
    // so for Teams page we want the url to be like `/contact/teams/<token>`.
    // this will allow us to generate correct path for Shared Reports,
    // in case user reloads the Teams page and try to access Shared Reports.
    path: window.location.pathname.replace("/contact/teams", "/contact/report"),
    icon: "shared-reports",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "MileIQ for Teams",
  },
  {
    label: "Teams",
    // keep magic link token in case user navigates to Teams page and reloads it
    // `/contact/report/<token>` -> `/contact/teams/<token>`
    path: window.location.pathname.replace("/contact/report", "/contact/teams"),
    icon: "people",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "Help",
  },
  {
    label: "Blog",
    url: "https://www.mileiq.com/blog",
    icon: "book-open",
  },
  {
    label: "Help",
    url: "https://support.mileiq.com",
    icon: "comment-message",
  },
];

function Nav() {
  const { ready } = useContext(ContactUserContext);

  const renderItems = (items) => {
    return items.map((item) => {
      if (item.isGroupTitle) {
        return (
          <li key={`group-${item.title}`} className="tablet:hidden mb-3 px-3">
            <Text md semibold color="black/50">
              {item.title}
            </Text>
          </li>
        );
      }

      const itemRef = createRef();

      const linkContent = (
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <span className="menu-icon" ref={itemRef}>
                {item.icon ? (
                  <Icon
                    name={item.icon}
                    color={item.highlighted ? "blue" : "black"}
                  />
                ) : (
                  <div className="w-6 h-6" />
                )}
              </span>
              <Tooltip
                triggerRef={itemRef}
                alignX="left"
                offset={{ x: 40 }}
                className="hidden tablet:block"
              >
                <Text nowrap>{item.label}</Text>
              </Tooltip>
              <span
                className={`menu-item-label ${
                  item.highlighted ? "text-blue font-semibold" : ""
                }`}
                data-testid="sidenav-link"
              >
                {item.label}
              </span>
            </div>
          </div>
        </div>
      );

      return (
        <li className={`${item.liClass || ""} relative`} key={item.label}>
          {item.url ? (
            <a
              className="group/link"
              href={item.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {linkContent}
            </a>
          ) : (
            <NavLink
              target={item.target}
              rel={item.rel}
              className="group/link"
              activeClassName="active"
              to={item.path}
            >
              {linkContent}
            </NavLink>
          )}
        </li>
      );
    });
  };

  if (!ready) return null;

  return (
    <div className="z-10 w-full tablet:w-0">
      <div className="sidenav">
        <div className="tablet:p-0 sidenav-menu mb-[30px] laptop:mb-[20px] pl-2 mobile:pl-2 w-full relative">
          <Menu />
        </div>
        <nav className="flex-grow self-stretch">
          <ul>{renderItems(navItems)}</ul>
        </nav>
        <NavLogo />
      </div>
    </div>
  );
}

function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const { userExists, ready } = useContext(ContactUserContext);
  const menuItems = [];

  if (ready) {
    const authLink = getAuthLink(userExists);
    menuItems.push({
      key: "login",
      render: () => (
        <a href={authLink} className="flex items-center w-full">
          <Text semibold>{userExists ? "Login" : "Create an account"}</Text>
        </a>
      ),
    });
  }

  return (
    <Dropdown
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      triggerClassName="p-0 bg-transparent shadow-none tablet:h-[25px]"
      contentClassName="top-[52px] mobile:-left-3 -left-3 tablet:left-0 tablet:top-[38px] p-0 border border-border-1"
      minContentWidth={210}
      maxContentWidth={300}
      renderTrigger={() => (
        <div
          className="group/menu flex items-center justify-between w-full"
          data-testid="sidenav-top-menu-label"
        >
          <div>
            <IndividualWordMark />
          </div>
          <div
            className={`flex items-center mobile:h-[40px] tablet:h-auto h-[40px] mobile:p-[10px] p-[10px] tablet:p-0 mobile:ml-0 tablet:ml-[-3px] ml-0 rounded-[14px] mobile:group-hover/menu:bg-beige-medium tablet:group-hover/menu:bg-transparent group-hover/menu:bg-beige-medium  ${
              isOpen
                ? "mobile:bg-beige-medium tablet:bg-transparent bg-beige-medium"
                : ""
            }`}
          >
            <Icon name="chevron-down" className="tablet:scale-75" />
          </div>
        </div>
      )}
    >
      <div className="p-[7px]">
        <ul className="m-0">
          {menuItems.map((item) => {
            let classes =
              "cursor-pointer m-0 px-2.5 py-2.5 rounded-10 hover:bg-beige active:bg-beige-medium mt-1";

            return (
              <li key={item.key} className={classes}>
                {item.render()}
              </li>
            );
          })}
        </ul>
      </div>
    </Dropdown>
  );
}

function NavLogo() {
  return (
    <div className="flex items-center">
      <p className="ml-3 mt-3 text-12 text-black/50 menu-item-label">
        v{version}
      </p>
    </div>
  );
}
