import { attachInAppMessageCSS as nt } from "../../ui/js/iam-css.js";
import ot from "../../util/browser-detector.js";
import { logInAppMessageHtmlClick } from "../log-in-app-message-html-click.js";
import { getUser as Q } from "../../Core/get-user.js";
import { InAppMessage } from "../index.js";
import { keys as rr } from "../../util/code-utils.js";
import { OperatingSystems as rt } from "../../util/device-constants.js";
import { parseQueryStringKeyValues as it } from "../../util/url-utils.js";
import { WindowUtils as H } from "../../util/window-utils.js";
export default function ut(t, n, e, o, s, r) {
  const i = document.createElement("iframe");
  i.setAttribute("title", "Modal Message"), s && (i.style.zIndex = s + 1);
  const u = n => {
      const e = n.getAttribute("href"),
        s = n.onclick;
      return r => {
        if (null != s && "function" == typeof s && !1 === s()) return;
        let u = it(e).abButtonId;
        if (
          ((null != u && "" !== u) || (u = n.getAttribute("id")),
          null != e && "" !== e && 0 !== e.indexOf("#"))
        ) {
          const s =
              "blank" ===
              (n.getAttribute("target") || "").toLowerCase().replace("_", ""),
            c = o || t.openTarget === InAppMessage.OpenTarget.BLANK || s,
            a = () => {
              logInAppMessageHtmlClick(t, u, e), H.openUri(e, c, r);
            };
          c ? a() : t.on(i, a);
        } else logInAppMessageHtmlClick(t, u, e);
        return r.stopPropagation(), !1;
      };
    },
    c = (t, n, e) => {
      const o = `([\\w]+)\\s*=\\s*document.createElement\\(['"]${e}['"]\\)`,
        s = t.match(new RegExp(o));
      if (s) {
        const e = `${s[1]}.setAttribute("nonce", "${n}")`;
        return `${t.slice(0, s.index + s[0].length)};${e};${t.slice(
          s.index + s[0].length
        )}`;
      }
      return null;
    };
  if (
    ((i.onload = () => {
      let o = null;
      if (null != r) {
        (o = document.createElement("html")), (o.innerHTML = t.message);
        const n = o.getElementsByTagName("style");
        for (let t = 0; t < n.length; t++) n[t].setAttribute("nonce", r);
        const e = o.getElementsByTagName("script");
        for (let t = 0; t < e.length; t++) {
          e[t].setAttribute("nonce", r),
            (e[t].innerHTML = e[t].innerHTML.replace(
              /<style>/g,
              `<style nonce='${r}'>`
            ));
          const n = c(e[t].innerHTML, r, "script");
          n && (e[t].innerHTML = n);
          const o = c(e[t].innerHTML, r, "style");
          o && (e[t].innerHTML = o);
        }
      }
      i.contentWindow.focus(),
        i.contentWindow.document.write(o ? o.innerHTML : t.message);
      const s = i.contentWindow.document.getElementsByTagName("head")[0];
      if (null != s) {
        if ((nt(s), t.sn())) {
          const n = document.createElement("style");
          (n.innerHTML = t.css),
            (n.id = t.rn()),
            null != r && n.setAttribute("nonce", r),
            s.appendChild(n);
        }
        const n = i.contentWindow.document.createElement("base");
        n.setAttribute("target", "_parent"), s.appendChild(n);
      }
      const a = i.contentWindow.document.getElementsByTagName("title");
      a.length > 0 && i.setAttribute("title", a[0].textContent);
      const l = {
          closeMessage: function() {
            t.on(i);
          },
          logClick: function() {
            const n = [t, ...arguments];
            logInAppMessageHtmlClick.apply(t, Array.prototype.slice.call(n));
          },
          display: {},
          web: {}
        },
        requestPushPermission = function() {
          return function() {
            const t = arguments;
            import("../../Push/request-push-permission.js").then(n => {
              n.requestPushPermission.apply(
                null,
                Array.prototype.slice.call(t)
              );
            });
          };
        },
        m = {
          requestImmediateDataFlush: async function() {
            const t = arguments,
              n = await import("../../Core/request-immediate-data-flush.js");
            n.requestImmediateDataFlush.apply(
              null,
              Array.prototype.slice.call(t)
            );
          },
          logCustomEvent: async function() {
            const t = arguments,
              n = await import("../../Core/log-custom-event.js");
            n.logCustomEvent.apply(null, Array.prototype.slice.call(t));
          },
          logPurchase: async function() {
            const t = arguments,
              n = await import("../../Core/log-purchase.js");
            n.logPurchase.apply(null, Array.prototype.slice.call(t));
          },
          unregisterPush: async function() {
            const t = arguments,
              n = await import("../../Push/unregister-push.js");
            n.unregisterPush.apply(null, Array.prototype.slice.call(t));
          },
          requestPushPermission: requestPushPermission(),
          changeUser: async function() {
            const t = arguments,
              n = await import("../../Core/change-user.js");
            n.changeUser.apply(null, Array.prototype.slice.call(t));
          }
        },
        f = function(t) {
          return function() {
            m[t].apply(null, Array.prototype.slice.call(arguments));
          };
        };
      for (const t of rr(m)) l[t] = f(t);
      const p = [
          "setFirstName",
          "setLastName",
          "setEmail",
          "setGender",
          "setDateOfBirth",
          "setCountry",
          "setHomeCity",
          "setEmailNotificationSubscriptionType",
          "setLanguage",
          "addAlias",
          "setPushNotificationSubscriptionType",
          "setPhoneNumber",
          "setCustomUserAttribute",
          "addToCustomAttributeArray",
          "removeFromCustomAttributeArray",
          "incrementCustomUserAttribute",
          "setCustomLocationAttribute",
          "addToSubscriptionGroup",
          "removeFromSubscriptionGroup"
        ],
        d = function(t) {
          return function() {
            const n = Q();
            n[t].apply(n, Array.prototype.slice.call(arguments));
          };
        },
        y = {};
      for (let t = 0; t < p.length; t++) y[p[t]] = d(p[t]);
      l.getUser = function() {
        return y;
      };
      const b = { showFeed: n },
        g = function(n) {
          return function() {
            const e = arguments;
            t.on(i, function() {
              b[n].apply(null, Array.prototype.slice.call(e));
            });
          };
        };
      for (const t of rr(b)) l.display[t] = g(t);
      const h = { registerAppboyPushMessages: requestPushPermission() },
        A = function(t) {
          return function() {
            h[t].apply(null, Array.prototype.slice.call(arguments));
          };
        };
      for (const t of rr(h)) l.web[t] = A(t);
      if (
        ((i.contentWindow.appboyBridge = l),
        (i.contentWindow.brazeBridge = l),
        t.un !== InAppMessage.es.cn)
      ) {
        const t = i.contentWindow.document.getElementsByTagName("a");
        for (let n = 0; n < t.length; n++) t[n].onclick = u(t[n]);
        const n = i.contentWindow.document.getElementsByTagName("button");
        for (let t = 0; t < n.length; t++) n[t].onclick = u(n[t]);
      }
      const j = i.contentWindow.document.body;
      if (null != j) {
        t.an() && (j.id = t.htmlId);
        const n = document.createElement("hidden");
        (n.onclick = l.closeMessage),
          (n.className = "ab-programmatic-close-button"),
          j.appendChild(n);
      }
      i.contentWindow.dispatchEvent(new CustomEvent("ab.BridgeReady")),
        -1 !== i.className.indexOf("ab-start-hidden") &&
          ((i.className = i.className.replace("ab-start-hidden", "")), e(i)),
        document.activeElement !== i && i.focus();
    }),
    (i.className =
      "ab-in-app-message ab-start-hidden ab-html-message ab-modal-interactions"),
    ot.OS === rt.ln)
  ) {
    const n = document.createElement("div");
    return (
      (n.className = "ab-ios-scroll-wrapper"), n.appendChild(i), (t.mn = n), n
    );
  }
  return (t.mn = i), i;
}
