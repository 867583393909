import { useMutation, useQuery } from "@apollo/client";
import {
  getYear,
  isBefore,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
} from "date-fns";
import React, { useContext, useEffect, useState } from "react";

import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import useFlash from "src/hooks/useFlash";

import { isTeamsProSubscription } from "src/models/team-subscription";

import { DistanceUnit, trackDistanceUnitUpdated } from "src/services/tracking";
import { COUNTRIES, COUNTRIES_DATA } from "src/services/utils";

import {
  CREATE_RATE,
  RESET_RATES,
  UPDATE_DISTANCE_UNIT,
  UPDATE_RATE,
} from "src/graphql/mutations";
import { GET_RATES } from "src/graphql/queries";

import RatesCA from "./RatesCA";
import RatesErrorBoundary from "./RatesErrorBoundary";
import RatesUK from "./RatesUK";
import RatesUS from "./RatesUS";

export default MileageRatesPage;

const ContriesRate = {
  [COUNTRIES.US]: RatesUS,
  [COUNTRIES.CA]: RatesCA,
  [COUNTRIES.GB]: RatesUK,
};

function MileageRatesPage() {
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);

  const [currentYearRate, setCurrentYearRate] = useState(null);
  const [flash, Flash] = useFlash();

  const ratesQuery = useQuery(GET_RATES, {
    notifyOnNetworkStatusChange: true,
  });

  const [createRate, createRateRes] = useMutation(CREATE_RATE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getRates", "getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const [updateRate, updateRateRes] = useMutation(UPDATE_RATE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getRates", "getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const [resetRates, resetRatesRes] = useMutation(RESET_RATES, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getRates", "getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  useEffect(() => {
    const rateObj = [...(ratesQuery?.data?.rates || [])]
      // sorting decending
      ?.sort((a, b) => {
        return b?.year - a?.year;
      })
      // find current or previous year
      .find((rate) => {
        const currentYear = new Date().getFullYear();
        const valid = rate.year == currentYear || rate.year == currentYear - 1;
        return valid;
      });

    if (rateObj) setCurrentYearRate(JSON.parse(rateObj.dataJSON));
  }, [ratesQuery.data?.rates]);

  const isCustomRatePresent = () =>
    !["country_us", "country_ca", "country_gb"].includes(userData.ratesName) &&
    !currentYearRate.effectiveDate;

  const isBeforeGBFinancialYear = () => {
    const now = new Date();
    let financialYearStart = new Date();
    financialYearStart = setHours(financialYearStart, 0);
    financialYearStart = setMinutes(financialYearStart, 0);
    financialYearStart = setSeconds(financialYearStart, 0);
    financialYearStart = setMilliseconds(financialYearStart, 0);
    financialYearStart = setMonth(financialYearStart, 3);
    financialYearStart = setDate(financialYearStart, 6);
    return isBefore(now, financialYearStart);
  };

  const onSave = (params) => {
    let year = getYear(new Date());

    if (userData.country === COUNTRIES.GB) {
      const beforeGBFinancialYear = isBeforeGBFinancialYear();
      if (beforeGBFinancialYear) {
        year -= 1;
      }
    }

    const { units, objectId, currency } = currentYearRate;
    if (
      isCustomRatePresent() &&
      currentYearRate.year?.toString() === year.toString()
    ) {
      return updateRate({
        variables: {
          units,
          objectId,
          effective_date: null,
          ...params,
        },
      });
    }

    const requestParams = {
      units,
      currency,
      effective_date: null,
      year: year.toString(),
      ...params,
    };

    return createRate({ variables: { ...requestParams } });
  };

  const handleRatesReset = () => {
    return resetRates();
  };

  const canDriverManageRates = () => {
    return !isTeamsProSubscription(team?.subscription?.plan);
  };

  if (!currentYearRate) {
    return <Loader className="p-8" />;
  }

  const CountryRateForm = ContriesRate[userData.country];
  const canManageRates = canDriverManageRates();
  const setByTeam = !canManageRates;

  return (
    <div className="p-[20px] laptop:p-[15px] max-w-fit relative">
      {!setByTeam && (
        <>
          <Metric
            unit={userData.distanceUnit}
            flash={flash}
            country={userData.country}
          />
          <div className="border-t border-border-1 w-full my-[15px]" />
        </>
      )}
      <RatesErrorBoundary>
        <CountryRateForm
          rate={currentYearRate}
          editable={canManageRates}
          setByTeam={setByTeam}
          loading={
            updateRateRes.loading ||
            createRateRes.loading ||
            resetRatesRes.loading
          }
          onSave={(params) => {
            return onSave(params).then(() => {
              const message =
                userData.country === "US"
                  ? `Mileage rate updated to $${params.values.business.default}/${currentYearRate.units}`
                  : "Mileage rate updated";
              flash(<Text>{message}</Text>, {
                type: FlashTypes.SAVED,
              });
            });
          }}
          isCustomRatePresent={isCustomRatePresent()}
          handleRatesReset={() => {
            return handleRatesReset().then(() => {
              flash(<Text>Mileage rate reset to default</Text>, {
                type: FlashTypes.SAVED,
              });
            });
          }}
        />
      </RatesErrorBoundary>
      {isCustomRatePresent() && (
        <div className="mt-4">
          <Text paragraph md color="black/70">
            Please note: you are using the custom rate that you have previously
            set for your mileage reports.
          </Text>
        </div>
      )}
      {Flash}
    </div>
  );
}

const Metric = ({ unit, flash, country }) => {
  const [updateDistanceUnit, { loading }] = useMutation(UPDATE_DISTANCE_UNIT, {
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
    notifyOnNetworkStatusChange: true,
  });
  const [currentUnit, setCurrentUnit] = useState(unit);

  const Metrics = {
    km: COUNTRIES_DATA[country].unitName.toLowerCase(),
    mi: "miles",
  };
  const isMi = currentUnit === "mi";
  const isKm = currentUnit === "km";
  return (
    <>
      <h6 className="mb-[5px]">Distance units</h6>
      <Text paragraph>Logs default to miles for the distance unit.</Text>
      <div className="flex gap-2 my-[15px]">
        <Button
          onClick={() => setCurrentUnit("mi")}
          ghost
          color={isMi ? "blue" : null}
          icon={isMi ? "blue-check" : null}
          className={`py-[10px] px-[20px] border border-border-1 border-solid hover:cursor-pointer ${
            isMi ? "border-2 border-blue font-medium" : ""
          }`}
        >
          Miles
        </Button>
        <Button
          onClick={() => setCurrentUnit("km")}
          ghost
          color={isKm ? "blue" : null}
          icon={isKm ? "blue-check" : null}
          className={`py-[10px] px-[20px] border border-border-1 border-solid hover:cursor-pointer ${
            isKm ? "border-2 border-blue font-medium" : ""
          }`}
        >
          {COUNTRIES_DATA[country].unitName}
        </Button>
      </div>

      <div className="mt-4 flex gap-2">
        <Button
          loading={loading}
          disabled={unit === currentUnit || loading}
          className="font-medium"
          primary
          onClick={() => {
            updateDistanceUnit({
              variables: {
                distanceUnit: currentUnit,
              },
            }).then(() => {
              trackDistanceUnitUpdated({
                distanceUnit: isKm
                  ? DistanceUnit.KILOMETERS
                  : DistanceUnit.MILES,
              });
              flash(
                <Text>Distance units changed to {Metrics[currentUnit]}</Text>,
                {
                  type: FlashTypes.SAVED,
                }
              );
            });
          }}
        >
          Save
        </Button>
        <Button
          disabled={unit === currentUnit || loading}
          className="font-medium"
          ghost
          onClick={() => setCurrentUnit(unit)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
