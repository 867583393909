import { useMutation } from "@apollo/client";
import React, { forwardRef, useImperativeHandle, useState } from "react";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Text from "src/components/elements/Text";

import { ADD_PURPOSE } from "src/graphql/mutations";

export default forwardRef(AddPurposeForm);

function AddPurposeForm(
  {
    sm,
    onAdd,
    onCancel,
    onBlur,
    category,
    cancelBtnProps = { ghost: true, nohover: true },
    existingPurposes,
  },
  ref
) {
  const [val, setVal] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, validate] = usePurposeInputValidation(existingPurposes);
  const [addPurpMutFn, addPurpMutState] = useMutation(ADD_PURPOSE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: () => {
        return save();
      },
    }),
    [val]
  );

  const handleChange = (e) => {
    const v = e.target.value;
    setVal(v);
    if (submitted) validate(v);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
  };

  const save = async () => {
    setSubmitted(true);

    if (!val || val.trim() === "") return;

    if (addPurpMutState.loading) return;

    if (!validate(val)) {
      return;
    }

    const res = await addPurpMutFn({
      variables: {
        data: {
          label: val,
          category,
        },
      },
    });

    onAdd(res.data.addPurpose);
  };

  const canSave = val && val.trim() !== "";

  const inpCls = sm ? "h-[30px] [&_input]:h-[30px] rounded-10" : null;
  const btnCls = sm ? "h-[30px] rounded-10" : null;

  return (
    <form onSubmit={handleSubmit} data-testid="purposes-add-new-form">
      <Input
        value={val}
        onChange={handleChange}
        onBlur={onBlur}
        className={inpCls}
        autoFocus
        valid={!error}
      />
      {error ? (
        <Text
          paragraph
          color="red"
          className={`mt-[5px] text-[11px] animate-slideTopBottom ${
            sm ? "text-[9px] max-w-[200px]" : ""
          }`}
        >
          {error}
        </Text>
      ) : null}
      <div className="flex gap-2 mt-2">
        <Button
          loading={addPurpMutState.loading}
          className={btnCls}
          type="submit"
          disabled={!canSave}
        >
          Save
        </Button>
        <Button
          className={btnCls}
          type="button"
          onClick={onCancel}
          disabled={addPurpMutState.loading}
          {...cancelBtnProps}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

export function usePurposeInputValidation(existingPurposes) {
  const [error, setError] = useState(null);
  function validate(v) {
    const exist = existingPurposes
      .filter((p) => p.isCustom)
      .map((p) => p.label?.toLowerCase())
      .includes(v.toLowerCase());

    if (exist) {
      setError(
        "There is already a custom purpose with the same name. Please enter a unique name."
      );
      return false;
    }

    setError(null);
    return true;
  }

  return [error, validate];
}
