import React from "react";

import Input from "src/components/elements/Input";

import { decimalPlaces, isValidDecimal, numberOnly } from "src/services/utils";

export default CurrencyInput;

function CurrencyInput({
  inPounds,
  className,
  onChange,
  fractionalDigits = 2,
  noCurrency = false,
  ...rest
}) {
  let inpClasses = `relative currency-input [&_input]:min-w-0 before:absolute before:left-3 ${
    className || ""
  }`;

  // for tailwindcss compiler keep it explicit
  if (!noCurrency) {
    if (inPounds) {
      inpClasses += "[&_input]:ml-[9px] before:content-['£']";
    } else {
      inpClasses += "[&_input]:ml-[9px] before:content-['$']";
    }
  }

  const currentOnChange = (e) => {
    if (
      !isValidDecimal(e.target.value) ||
      decimalPlaces(e.target.value) > fractionalDigits
    )
      return false;
    const value = numberOnly(e.target.value);
    const event = { ...e };

    event.target = {
      ...e.target,
      value,
    };

    onChange(event);
  };

  return (
    <Input
      type="text"
      onChange={(e) => currentOnChange(e)}
      className={inpClasses}
      {...rest}
    />
  );
}
