import x from "./base-feed.js";
import {
  bottomHadImpression as de,
  getCardId as be,
  impressOnBottom as ye,
  impressOnTop as Fe,
  markCardAsRead as Te,
  topHadImpression as he
} from "../Card/display/card-display.js";
import { bottomIsInView as _e, topIsInView as xe } from "../util/dom-utils.js";
import { Card, ControlCard } from "../Card/index.js";
import { cardToHtml as Ee } from "../Card/display/card-display.js";
import { isArray as p } from "../util/code-utils.js";
import { KeyCodes as at } from "../util/key-codes.js";
import je from "../l10n/l10n-manager-factory.js";
import { removeSubscription } from "../Core/remove-subscription.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { BRAZE_ACTION_URI_REGEX as O } from "../util/validation-utils.js";
import {
  INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES as ft,
  ineligibleBrazeActionURLErrorMessage as ht,
  getDecodedBrazeAction as J,
  containsUnknownBrazeAction as ct
} from "../util/braze-actions.js";
export const LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE =
  "data-last-requested-refresh";
export const _SUBSCRIPTION_ID_DATA_ATTRIBUTE = "data-update-subscription-id";
export function destroyFeedHtml(e) {
  e &&
    ((e.className = e.className.replace("ab-show", "ab-hide")),
    setTimeout(() => {
      e && e.parentNode && e.parentNode.removeChild(e);
    }, x.Ki));
  const t = e.getAttribute("data-update-subscription-id");
  null != t && removeSubscription(t);
}
export function _generateFeedBody(e, t) {
  const o = document.createElement("div");
  if (
    ((o.className = "ab-feed-body"),
    o.setAttribute("aria-label", "Feed"),
    o.setAttribute("role", "feed"),
    null == e.lastUpdated)
  ) {
    const e = document.createElement("div");
    e.className = "ab-no-cards-message";
    const t = document.createElement("i");
    (t.className = "fa fa-spinner fa-spin fa-4x ab-initial-spinner"),
      e.appendChild(t),
      o.appendChild(e);
  } else {
    let s = !1;
    const logCardClick = t => {
      e.logCardClick(t);
    };
    for (const n of e.cards) {
      const a = n instanceof ControlCard;
      !a || e.dr()
        ? (o.appendChild(Ee(n, logCardClick, t)), (s = s || !a))
        : r.D.error(
            "Received a control card for a legacy news feed. Control cards are only supported with content cards."
          );
    }
    if (!s) {
      const e = document.createElement("div");
      (e.className = "ab-no-cards-message"),
        (e.innerHTML = je.m().get("NO_CARDS_MESSAGE")),
        e.setAttribute("role", "article"),
        o.appendChild(e);
    }
  }
  return o;
}
export function detectFeedImpressions(e, t) {
  if (null != t) {
    const o = [],
      r = t.querySelectorAll(".ab-card");
    e.do || (e.do = {});
    for (let t = 0; t < r.length; t++) {
      const s = be(r[t]);
      if (e.do[s]) continue;
      let n = he(r[t]),
        a = de(r[t]);
      const i = n,
        d = a,
        f = xe(r[t]),
        c = _e(r[t]);
      if (
        (!n && f && ((n = !0), Fe(r[t])),
        !a && c && ((a = !0), ye(r[t])),
        n && a)
      ) {
        if ((f || c || Te(r[t]), i && d)) continue;
        for (const t of e.cards)
          if (t.id === s) {
            (e.do[t.id] = !0), o.push(t);
            break;
          }
      }
    }
    o.length > 0 && e.logCardImpressions(o);
  }
}
export function refreshFeed(e, t) {
  t.setAttribute("aria-busy", "true");
  const o = t.querySelectorAll(".ab-refresh-button")[0];
  null != o && (o.className += " fa-spin");
  const r = new Date().valueOf().toString();
  t.setAttribute("data-last-requested-refresh", r),
    setTimeout(() => {
      if (t.getAttribute("data-last-requested-refresh") === r) {
        const e = t.querySelectorAll(".fa-spin");
        for (let t = 0; t < e.length; t++)
          e[t].className = e[t].className.replace(/fa-spin/g, "");
        const o = t.querySelectorAll(".ab-initial-spinner")[0];
        if (null != o) {
          const e = document.createElement("span");
          (e.innerHTML = je.m().get("FEED_TIMEOUT_MESSAGE")),
            o.parentNode.appendChild(e),
            o.parentNode.removeChild(o);
        }
        "true" === t.getAttribute("aria-busy") &&
          t.setAttribute("aria-busy", "false");
      }
    }, x.uo),
    e.sr();
}
export function feedToHtml(e, t) {
  const o = document.createElement("div");
  (o.className = "ab-feed ab-hide ab-effect-slide"),
    o.setAttribute("role", "dialog"),
    o.setAttribute("aria-label", "Feed"),
    o.setAttribute("tabindex", "-1");
  const r = document.createElement("div");
  (r.className = "ab-feed-buttons-wrapper"),
    r.setAttribute("role", "group"),
    o.appendChild(r);
  const s = document.createElement("i");
  (s.className = "fa fa-times ab-close-button"),
    s.setAttribute("aria-label", "Close Feed"),
    s.setAttribute("tabindex", "0"),
    s.setAttribute("role", "button");
  const n = e => {
    destroyFeedHtml(o), e.stopPropagation();
  };
  s.addEventListener("keydown", e => {
    (e.keyCode !== at.fo && e.keyCode !== at.co) || n(e);
  }),
    (s.onclick = n);
  const a = document.createElement("i");
  (a.className = "fa fa-refresh ab-refresh-button"),
    null == e.lastUpdated && (a.className += " fa-spin"),
    a.setAttribute("aria-label", "Refresh Feed"),
    a.setAttribute("tabindex", "0"),
    a.setAttribute("role", "button");
  const i = t => {
    refreshFeed(e, o), t.stopPropagation();
  };
  return (
    a.addEventListener("keydown", e => {
      (e.keyCode !== at.fo && e.keyCode !== at.co) || i(e);
    }),
    (a.onclick = i),
    r.appendChild(a),
    r.appendChild(s),
    o.appendChild(_generateFeedBody(e, t)),
    (o.onscroll = () => {
      detectFeedImpressions(e, o);
    }),
    o
  );
}
export function updateFeedCards(e, t, o, s, n) {
  if (!p(t)) return;
  const a = [];
  for (const e of t)
    if (e instanceof Card) {
      if (e.url && O.test(e.url)) {
        let t = J(e.url);
        if (ct(t)) {
          r.D.error(ht(ft.yi, "Content Card"));
          continue;
        }
      }
      a.push(e);
    }
  if (((e.cards = a), (e.lastUpdated = o), null != s))
    if ((s.setAttribute("aria-busy", "false"), null == e.lastUpdated))
      destroyFeedHtml(s);
    else {
      const t = s.querySelectorAll(".ab-feed-body")[0];
      if (null != t) {
        const o = _generateFeedBody(e, n);
        t.parentNode.replaceChild(o, t), detectFeedImpressions(e, o.parentNode);
      }
    }
}
export function registerFeedSubscriptionId(e, t) {
  t.setAttribute("data-update-subscription-id", e);
}
