import {
  isArray as p,
  isDate as yt,
  validateValueIsFromEnum as F
} from "../util/code-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { toValidBackendTimeString as Nt } from "../util/date-utils.js";
import {
  isValidEmail as Ct,
  validateCustomString as Y,
  validateStandardString as R
} from "../util/validation-utils.js";
export default class User {
  constructor(t, e) {
    (this.v = t), (this.si = e);
  }
  getUserId(t) {
    null == t &&
      r.D.error(
        "getUserId must be supplied with a callback. e.g., braze.getUser().getUserId(function(userId) {console.log('the user id is ' + userId)})"
      ),
      "function" == typeof t && t(this.v.getUserId());
  }
  addAlias(t, e) {
    return !R(t, "add alias", "the alias", !1) || t.length <= 0
      ? (r.D.error("addAlias requires a non-empty alias"), !1)
      : !R(e, "add alias", "the label", !1) || e.length <= 0
      ? (r.D.error("addAlias requires a non-empty label"), !1)
      : this.si.Nn(t, e).S;
  }
  setFirstName(t) {
    return (
      !!R(t, "set first name", "the firstName", !0) &&
      this.v.nu("first_name", t)
    );
  }
  setLastName(t) {
    return (
      !!R(t, "set last name", "the lastName", !0) && this.v.nu("last_name", t)
    );
  }
  setEmail(t) {
    return null === t || Ct(t)
      ? this.v.nu("email", t)
      : (r.D.error(
          `Cannot set email address - "${t}" did not pass RFC-5322 validation.`
        ),
        !1);
  }
  setGender(t) {
    return (
      "string" == typeof t && (t = t.toLowerCase()),
      !(
        null !== t &&
        !F(
          User.Genders,
          t,
          `Gender "${t}" is not a valid gender.`,
          "User.Genders"
        )
      ) && this.v.nu("gender", t)
    );
  }
  setDateOfBirth(t, e, s) {
    return null === t && null === e && null === s
      ? this.v.nu("dob", null)
      : ((t = parseInt(t)),
        (e = parseInt(e)),
        (s = parseInt(s)),
        isNaN(t) || isNaN(e) || isNaN(s) || e > 12 || e < 1 || s > 31 || s < 1
          ? (r.D.error(
              "Cannot set date of birth - parameters should comprise a valid date e.g. setDateOfBirth(1776, 7, 4);"
            ),
            !1)
          : this.v.nu("dob", `${t}-${e}-${s}`));
  }
  setCountry(t) {
    return !!R(t, "set country", "the country", !0) && this.v.nu("country", t);
  }
  setHomeCity(t) {
    return (
      !!R(t, "set home city", "the homeCity", !0) && this.v.nu("home_city", t)
    );
  }
  setLanguage(t) {
    return (
      !!R(t, "set language", "the language", !0) && this.v.nu("language", t)
    );
  }
  setEmailNotificationSubscriptionType(t) {
    return (
      !!F(
        User.NotificationSubscriptionTypes,
        t,
        `Email notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes"
      ) && this.v.nu("email_subscribe", t)
    );
  }
  setPushNotificationSubscriptionType(t) {
    return (
      !!F(
        User.NotificationSubscriptionTypes,
        t,
        `Push notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes"
      ) && this.v.nu("push_subscribe", t)
    );
  }
  setPhoneNumber(t) {
    return (
      !!R(t, "set phone number", "the phoneNumber", !0) &&
      (null === t || t.match(User.Cn)
        ? this.v.nu("phone", t)
        : (r.D.error(
            `Cannot set phone number - "${t}" did not pass validation.`
          ),
          !1))
    );
  }
  setLastKnownLocation(t, e, s, i, n) {
    return null == t || null == e
      ? (r.D.error(
          "Cannot set last-known location - latitude and longitude are required."
        ),
        !1)
      : ((t = parseFloat(t)),
        (e = parseFloat(e)),
        null != s && (s = parseFloat(s)),
        null != i && (i = parseFloat(i)),
        null != n && (n = parseFloat(n)),
        isNaN(t) ||
        isNaN(e) ||
        (null != s && isNaN(s)) ||
        (null != i && isNaN(i)) ||
        (null != n && isNaN(n))
          ? (r.D.error(
              "Cannot set last-known location - all supplied parameters must be numeric."
            ),
            !1)
          : t > 90 || t < -90 || e > 180 || e < -180
          ? (r.D.error(
              "Cannot set last-known location - latitude and longitude are bounded by ±90 and ±180 respectively."
            ),
            !1)
          : (null != s && s < 0) || (null != n && n < 0)
          ? (r.D.error(
              "Cannot set last-known location - accuracy and altitudeAccuracy may not be negative."
            ),
            !1)
          : this.si.setLastKnownLocation(this.v.getUserId(), t, e, i, s, n).S);
  }
  setCustomUserAttribute(t, e) {
    if (!Y(t, "set custom user attribute", "the given key")) return !1;
    const s = typeof e,
      i = yt(e),
      n = p(e);
    if (
      "number" !== s &&
      "boolean" !== s &&
      !i &&
      !n &&
      null !== e &&
      !Y(e, `set custom user attribute "${t}"`, "the given value")
    )
      return !1;
    if ((i && (e = Nt(e)), n)) {
      for (let s = 0; s < e.length; s++) {
        const r = e[s];
        if (
          !Y(
            r,
            `set custom user attribute "${t}"`,
            "the element in the given array"
          )
        )
          return !1;
      }
      return this.si.In(r.A.An, t, e).S;
    }
    return this.v.setCustomUserAttribute(t, e);
  }
  addToCustomAttributeArray(t, e) {
    return (
      !!Y(t, "add to custom user attribute array", "the given key") &&
      !(
        null != e &&
        !Y(e, "add to custom user attribute array", "the given value")
      ) &&
        this.si.In(r.A.Un, t, e).S
    );
  }
  removeFromCustomAttributeArray(t, e) {
    return (
      !!Y(t, "remove from custom user attribute array", "the given key") &&
      !(
        null != e &&
        !Y(e, "remove from custom user attribute array", "the given value")
      ) &&
        this.si.In(r.A.En, t, e).S
    );
  }
  incrementCustomUserAttribute(t, e) {
    if (!Y(t, "increment custom user attribute", "the given key")) return !1;
    null == e && (e = 1);
    const s = parseInt(e);
    return isNaN(s) || s !== parseFloat(e)
      ? (r.D.error(
          `Cannot increment custom user attribute because the given incrementValue "${e}" is not an integer.`
        ),
        !1)
      : this.si.In(r.A.Fn, t, s).S;
  }
  setCustomLocationAttribute(t, e, s) {
    return (
      !!Y(t, "set custom location attribute", "the given key") &&
      ((null !== e || null !== s) &&
      ((e = parseFloat(e)),
      (s = parseFloat(s)),
      isNaN(e) || e > 90 || e < -90 || isNaN(s) || s > 180 || s < -180)
        ? (r.D.error(
            "Received invalid values for latitude and/or longitude. Latitude and longitude are bounded by ±90 and ±180 respectively, or must both be null for removal."
          ),
          !1)
        : this.si.Tn(t, e, s).S)
    );
  }
  addToSubscriptionGroup(t) {
    return !R(
      t,
      "add user to subscription group",
      "subscription group ID",
      !1
    ) || t.length <= 0
      ? (r.D.error(
          "addToSubscriptionGroup requires a non-empty subscription group ID"
        ),
        !1)
      : this.si.$n(t, User.Bn.SUBSCRIBED).S;
  }
  removeFromSubscriptionGroup(t) {
    return !R(
      t,
      "remove user from subscription group",
      "subscription group ID",
      !1
    ) || t.length <= 0
      ? (r.D.error(
          "removeFromSubscriptionGroup requires a non-empty subscription group ID"
        ),
        !1)
      : this.si.$n(t, User.Bn.UNSUBSCRIBED).S;
  }
  Qr(t, e, s, r, i) {
    this.v.Qr(t, e, s, r, i), this.si.Ln();
  }
  Zr(t) {
    this.v.Zr(t);
  }
}
(User.Cn = /^[0-9 .\\(\\)\\+\\-]+$/),
  (User.Genders = {
    MALE: "m",
    FEMALE: "f",
    OTHER: "o",
    UNKNOWN: "u",
    NOT_APPLICABLE: "n",
    PREFER_NOT_TO_SAY: "p"
  }),
  (User.NotificationSubscriptionTypes = {
    OPTED_IN: "opted_in",
    SUBSCRIBED: "subscribed",
    UNSUBSCRIBED: "unsubscribed"
  }),
  (User.Bn = { SUBSCRIBED: "subscribed", UNSUBSCRIBED: "unsubscribed" }),
  (User.Rn = "user_id"),
  (User.lu = "custom"),
  (User.ee = 997);
