import { getStripeSubscriptionCode } from "src/models/team-subscription";

import http from "src/services/http";

import { COUNTRIES } from "./utils";

const teamsApiUrl = process.env.TEAMS_API_URL;

export const PAYMENT_METHOD = {
  STRIPE: "STRIPE",
  GOOGLE: "GOOGLE",
  APPLE: "APPLE",
  APPLE_SANDBOX: "APPLE-SANDBOX",
};

export const PAYMENT_METHOD_DATA = {
  [PAYMENT_METHOD.STRIPE]: {
    label: "Stripe",
  },
  [PAYMENT_METHOD.GOOGLE]: {
    label: "Google Play Store",
    editPaymentInfoUrl: "https://play.google.com/store/account/subscriptions",
  },
  [PAYMENT_METHOD.APPLE]: {
    label: "App Store",
    editPaymentInfoUrl: "https://apps.apple.com/account/subscriptions",
  },
  [PAYMENT_METHOD.APPLE_SANDBOX]: {
    label: "App Store(Sandbox)",
    editPaymentInfoUrl: "https://apps.apple.com/account/subscriptions",
  },
};

export const SUBSCRIPTION_TYPE = {
  MONTHLY: "MONTHLY",
  ANNUAL: "ANNUAL",
};

export const SUBSCRIPTION_TYPE_DATA = {
  [SUBSCRIPTION_TYPE.MONTHLY]: { label: "Monthly" },
  [SUBSCRIPTION_TYPE.ANNUAL]: { label: "Annual" },
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
};

export const SUBSCRIPTION_STATUS_DATA = {
  [SUBSCRIPTION_STATUS.ACTIVE]: { label: "Active" },
  [SUBSCRIPTION_STATUS.CANCELLED]: { label: "Cancelled" },
  [SUBSCRIPTION_STATUS.EXPIRED]: { label: "Expired" },
};

export const SUBSCRIPTION_PLANS_DATA_BY_COUNTRY = {
  [COUNTRIES.US]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 5.99,
      [SUBSCRIPTION_TYPE.ANNUAL]: 59.99,
    },
  },
  [COUNTRIES.CA]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 7.99,
      [SUBSCRIPTION_TYPE.ANNUAL]: 79.99,
    },
  },
  [COUNTRIES.GB]: {
    prices: {
      [SUBSCRIPTION_TYPE.MONTHLY]: 4.49,
      [SUBSCRIPTION_TYPE.ANNUAL]: 44.99,
    },
  },
};

export function createTeamsCheckoutSession(
  planCode,
  planType,
  quantity,
  returnUri = window.location.href,
  isFreeTrial = false,
  isReverseTrial = false
) {
  const subTypeId = getStripeSubscriptionCode(planCode, planType);
  const uri = new URL(
    `${teamsApiUrl}/checkout?subscription_type=${subTypeId}&quantity=${quantity}`
  );
  if (isReverseTrial === "override") {
    uri.searchParams.append("override", "true");
  } else if (isReverseTrial) {
    uri.searchParams.append("is_reverse_trial", "true");
  }

  if (returnUri) {
    const returnURL = new URL(returnUri);
    returnURL.searchParams.set("code", planCode);
    returnURL.searchParams.set("quantity", quantity);

    if (isFreeTrial) {
      returnURL.searchParams.set("free-trial", "true");
      uri.searchParams.append("free_trial", "true");
    }

    returnURL.searchParams.delete("checkout-status");
    returnURL.searchParams.set("checkout-status", "success");
    uri.searchParams.append("success_redirect", returnURL.toString());

    returnURL.searchParams.delete("checkout-status");
    returnURL.searchParams.set("checkout-status", "cancel");
    uri.searchParams.append("cancel_redirect", returnURL.toString());
  }
  return http.get(uri.toString());
}

export function createTeamsPortalSession() {
  return http.get(`${teamsApiUrl}/portal`);
}

export function fetchInvoices(teamId) {
  return http.get(`${teamsApiUrl}/teams/${teamId}/invoices`);
}

function _updateSubscriptionType(type, preview = true) {
  return http.post(`${teamsApiUrl}/modify-subscription`, {
    subscription_type: type,
    preview,
  });
}

export function updateSubscriptionPreview(planCode, planType) {
  const subTypeId = getStripeSubscriptionCode(planCode, planType);
  return _updateSubscriptionType(subTypeId, true);
}

export function updateSubscription(planCode, planType) {
  const subTypeId = getStripeSubscriptionCode(planCode, planType);
  return _updateSubscriptionType(subTypeId, false);
}
