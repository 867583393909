import { useEffect } from "react";

const useLoadGoogleMaps = () => {
  useEffect(() => {
    const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&libraries=places,geometry&callback=initMap`;
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      });
    };

    loadScript(googleMapsUrl).catch((e) =>
      console.error(e?.message || "Failed to load google maps sdk.")
    );
  }, []);

  window.initMap = function () {};
};

export default useLoadGoogleMaps;
