import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Icon from "src/components/elements/Icon";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

export const LOCATION_SUGGESTION_LIST_ID = "LOCATION_SUGGESTION_LIST_ID";

registerElement(LOCATION_SUGGESTION_LIST_ID, LocationSuggestionList);

function LocationSuggestionList({ trigger, suggestions, onSelect, onClose }) {
  return (
    <Pivoted
      pivot={trigger}
      offset={{ y: 5 }}
      onClickOutside={onClose}
      style={{ zIndex: 30000 }}
      bottomRight
    >
      <div className="p-2 bg-white min-w-[333px] w-max rounded border border-border-1 shadow-md shadow-[#0003]">
        <ul className="m-0 p-0">
          {suggestions.length === 0 && (
            <li className="w-full p-2">
              <Text paragraph md center color="black/70">
                No results found
              </Text>
            </li>
          )}
          {suggestions?.slice(0, 5).map((p) => {
            const name = p.name;
            const highlightStartOffset = p.offset;
            const highlightLength = p.length;

            const title = {
              start: name.slice(0, highlightStartOffset),
              highlighted: name.slice(
                highlightStartOffset,
                highlightStartOffset + highlightLength
              ),
              end: name.slice(highlightStartOffset + highlightLength),
            };
            return (
              <li
                key={p.name}
                className="w-full p-2 rounded-8 hover:bg-beige"
                tabIndex="0"
              >
                <button
                  className="w-full text-left"
                  onClick={() => onSelect(p.name)}
                >
                  <div className="flex gap-3 items-center">
                    <Icon name={"location-pin"} />
                    <Text md>
                      {title.start}
                      <Text md bold>
                        {title.highlighted}
                      </Text>
                      {title.end}
                    </Text>
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </Pivoted>
  );
}
