import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useRef, useState } from "react";

import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Loader from "src/components/elements/Loader";
import Select from "src/components/elements/Select";
import Switcher from "src/components/elements/Switcher";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";

import { isTeamsProSubscription } from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import {
  trackAutomaticReportSubmissionDisabled,
  trackAutomaticReportSubmissionEnabled,
  trackAutomaticReportSubmissionUpdated,
} from "src/services/tracking";

import {
  TOGGLE_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE,
  UPDATE_AUTOMATIC_REPORT_SUBMISSION_RECURRENCE,
} from "src/graphql/mutations";
import { GET_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE } from "src/graphql/queries";

import BadgeTeamsPro from "public/assets/img/badge-sub-pro-2.svg";

export const REMINDER_RECURRENCES = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

const RECURRENCE_OPTIONS = {
  [REMINDER_RECURRENCES.DAILY]: {
    label: "Daily",
  },
  [REMINDER_RECURRENCES.WEEKLY]: {
    label: "Weekly (Sunday)",
  },
  [REMINDER_RECURRENCES.MONTHLY]: {
    label: "Monthly",
  },
};

export default AutomaticReportsPage;

function AutomaticReportsPage() {
  const { user } = useUser();
  const { team } = useContext(TeamContext);
  const { flash } = useAppFlash();
  const {
    miqTeamsAutomaticReportsRecurrenceAllPlatforms:
      isAutomaticReportsRecurrenceEnabled,
  } = useFlags();

  const [isAutomaticReportsEnabled, setAutomaticReportsEnabled] =
    useState(false);

  const [recurrence, setRecurrence] = useState(REMINDER_RECURRENCES.WEEKLY);

  const tooltipRef = useRef(null);

  const { data: preferenceData, networkStatus } = useQuery(
    GET_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const {
          isAutomaticReportSubmissionEnabled,
          automaticReportSubmissionRecurrence,
        } = data.getAutomaticReportSubmissionPreference;

        setRecurrence(
          automaticReportSubmissionRecurrence || REMINDER_RECURRENCES.WEEKLY
        );

        setAutomaticReportsEnabled(isAutomaticReportSubmissionEnabled);
      },
    }
  );

  // This won't catch refetch operations
  const isFetchingPreference = networkStatus === NetworkStatus.loading;

  const [
    toggleAutomaticReportSubmissionPreference,
    { loading: isTogglingPreference },
  ] = useMutation(TOGGLE_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["GetAutomaticReportSubmissionPreference"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const [
    updateAutomaticReportSubmissionRecurrence,
    { loading: isUpdatingRecurrence },
  ] = useMutation(UPDATE_AUTOMATIC_REPORT_SUBMISSION_RECURRENCE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["GetAutomaticReportSubmissionPreference"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const isProSubscription = isTeamsProSubscription(team?.subscription?.plan);
  const showUpsellExperience =
    !isProSubscription || user?.role === USER_TYPES.ADMIN || team?.isExpired;
  const isToggleOn =
    preferenceData?.getAutomaticReportSubmissionPreference
      ?.isAutomaticReportSubmissionEnabled && !showUpsellExperience;
  const currentRecurrence =
    preferenceData?.getAutomaticReportSubmissionPreference
      ?.automaticReportSubmissionRecurrence || REMINDER_RECURRENCES.WEEKLY;

  const isFormReady = isAutomaticReportsEnabled && !isTogglingPreference;
  const isFormLoading = isAutomaticReportsEnabled && isTogglingPreference;

  const handleToggleAutomaticReports = async () => {
    if (isTogglingPreference) return;

    try {
      setAutomaticReportsEnabled((prev) => !prev);

      const {
        data: {
          toggleAutomaticReportSubmissionPreference: {
            code,
            isAutomaticReportSubmissionEnabled,
            error,
          },
        },
      } = await toggleAutomaticReportSubmissionPreference({
        variables: {
          isEnabled: !isToggleOn,
        },
      });

      if (code !== 200) throw new Error(error);

      isAutomaticReportSubmissionEnabled
        ? trackAutomaticReportSubmissionEnabled({
            recurrence: currentRecurrence,
          })
        : trackAutomaticReportSubmissionDisabled();
    } catch (error) {
      setAutomaticReportsEnabled((prev) => !prev);
      console.error(error);

      flash(
        "Something went wrong while updating Automatic Report. Please try again later or contact the support.",
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };

  const handleSaveRecurrenceChange = async () => {
    if (isTogglingPreference) return;

    try {
      const {
        data: {
          updateAutomaticReportSubmissionRecurrence: { code, error },
        },
      } = await updateAutomaticReportSubmissionRecurrence({
        variables: {
          recurrence,
        },
      });

      if (code !== 200) throw new Error(error);
      trackAutomaticReportSubmissionUpdated({ recurrence });
    } catch (error) {
      console.error(error);

      flash(
        "Something went wrong while updating Automatic Report. Please try again later or contact the support.",
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };

  const handleCancelRecurrenceChange = () => {
    setRecurrence(currentRecurrence);
  };

  if (isFetchingPreference) {
    return <Loader className="p-8" />;
  }

  return (
    <div className="relative p-5 laptop:p-[15px]">
      <div className="flex items-start justify-between gap-12 md:gap-16">
        <div>
          <div className="flex items-center gap-2">
            <h6>Automatic Reporting</h6>
            <BadgeTeamsPro className="mt-[-2px]" />
          </div>
          <div className="mt-[5px]">
            <Description shouldUpsell={showUpsellExperience} />
          </div>
        </div>
        {showUpsellExperience && <SwitcherTooltip tooltipRef={tooltipRef} />}
        <div ref={tooltipRef}>
          <Switcher
            disabled={showUpsellExperience}
            lg
            isOn={isAutomaticReportsEnabled}
            label={isAutomaticReportsEnabled ? "Yes" : "No"}
            onChange={handleToggleAutomaticReports}
          />
        </div>
      </div>
      {isAutomaticReportsRecurrenceEnabled && (
        <>
          {isFormLoading && (
            <div className="inline-block mt-6">
              <Loader sm />
            </div>
          )}
          {isFormReady && (
            <div className="mt-6 gap-2">
              <div className="w-[300px]">
                <Select
                  matchTriggerWidth
                  options={RECURRENCE_OPTIONS}
                  selected={recurrence}
                  onSelect={setRecurrence}
                />
              </div>
              {currentRecurrence !== recurrence && (
                <div className="mt-6 flex gap-2.5 items-center">
                  <Button
                    loading={isUpdatingRecurrence}
                    onClick={handleSaveRecurrenceChange}
                  >
                    Save
                  </Button>
                  <Button
                    disabled={isUpdatingRecurrence}
                    ghost
                    onClick={handleCancelRecurrenceChange}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function SwitcherTooltip({ tooltipRef }) {
  const { user } = useUser();
  const { team } = useContext(TeamContext);

  const isProSubscription = isTeamsProSubscription(team?.subscription?.plan);
  const isExpired = team?.isExpired;

  let offset;
  let text;

  switch (user?.role) {
    case USER_TYPES.ADMIN:
      if (isExpired) {
        offset = { y: -90 };
        text = "Want automatic reports? Resubscribe to a Teams Pro plan.";
        break;
      }

      if (isProSubscription) {
        offset = { y: -90 };
        text = "Want Automatic reports? Upgrade yourself to Admin & Driver.";
        break;
      }

      offset = { y: -65 };
      text = "Want Automatic reports? Upgrade to a Teams Pro plan.";
      break;
    case USER_TYPES.DRIVER:
      if (isExpired) {
        offset = { y: -90 };
        text =
          "Want automatic reports? Ask your admin to resubscribe to a Teams Pro plan.";
        break;
      }

      offset = { y: -90 };
      text =
        "Want Automatic reports? Ask your admin to upgrade to a Teams Pro plan.";
      break;
    case USER_TYPES.ADMIN_DRIVER:
      if (isExpired) {
        offset = { y: -90 };
        text = "Want automatic reports? Resubscribe to a Teams Pro plan.";
        break;
      }

      offset = { y: -65 };
      text = "Want Automatic reports? Upgrade to a Teams Pro plan.";
      break;
    default:
      if (isExpired) {
        offset = { y: -90 };
        text =
          "Want automatic reports? Ask your admin to resubscribe to a Teams Pro plan.";
        break;
      }

      offset = { y: -90 };
      text =
        "Want Automatic reports? Ask your admin to upgrade to a Teams Pro plan.";
      break;
  }

  return (
    <Tooltip
      triggerRef={tooltipRef}
      offset={offset}
      alignX="center"
      timeout={500}
      className="max-w-[207px]"
    >
      <span className="text-md">{text}</span>
    </Tooltip>
  );
}

function Description({ shouldUpsell = false }) {
  const { user } = useUser();
  const ref = useRef(null);
  const { team } = useContext(TeamContext);

  const isProSubscription = isTeamsProSubscription(team?.subscription?.plan);
  const adminEmail = team?.owner?.email;
  const userRole = user?.role;

  const businessDriveTooltip = (
    <>
      <span ref={ref} className="cursor-pointer border-b border-dotted">
        business drives
      </span>
      <Tooltip
        triggerRef={ref}
        offset={{ y: -65 }}
        alignX={"center"}
        timeout={500}
        className="max-w-[160px]"
      >
        <span className="text-md">
          Drives that have been classified as Business.
        </span>
      </Tooltip>
    </>
  );

  const learnMoreLink = (
    <a
      className="text-blue"
      target="_blank"
      rel="noopener noreferrer"
      href="https://support.mileiq.com/hc/en-us/articles/21537050381204-How-to-Enable-Automatic-Reports-for-MileIQ-for-Teams-Teams-Pro-only"
    >
      Learn more
    </a>
  );

  const commonDescription = (
    <p className="text-lg">
      Automatically submit all of your {businessDriveTooltip} to your admin on a
      schedule. This won't affect past drives. {learnMoreLink}
    </p>
  );

  let specificDescription = null;

  if (shouldUpsell) {
    switch (userRole) {
      case USER_TYPES.ADMIN:
        if (isProSubscription) {
          specificDescription = (
            <p className="text-lg mt-5">
              This feature is available to Teams Pro drivers only — please
              upgrade yourself to Admin & Driver from your Teams Dashboard to
              access this feature.
            </p>
          );
          break;
        }

        specificDescription = (
          <p className="text-lg mt-5">
            This feature is available to Teams Pro drivers only — please upgrade
            to Teams Pro from your Teams Dashboard to access this feature.
          </p>
        );
        break;
      case USER_TYPES.DRIVER:
        specificDescription = (
          <p className="text-lg mt-5">
            This feature is available to Teams Pro drivers only — contact your
            admin{" "}
            <a href={`mailto:${adminEmail}`} className="text-blue">
              {adminEmail}
            </a>{" "}
            to upgrade to Teams Pro.
          </p>
        );
        break;
      case USER_TYPES.ADMIN_DRIVER:
        specificDescription = (
          <p className="text-lg mt-5">
            This feature is available to Teams Pro drivers only — please upgrade
            to Teams Pro from your Teams Dashboard to access this feature.
          </p>
        );
        break;
      default:
        specificDescription = null;
    }
  }

  return (
    <>
      {commonDescription}
      {specificDescription}
    </>
  );
}
