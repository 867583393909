import React, { useEffect } from "react";

import { trackTeamsReverseTrialEndedPageViewed } from "src/services/tracking";

import IllustrationComeBackTeams from "public/assets/img/reverse-trial/come-back-to-teams.png";
import IllustrationComeBackTeams2x from "public/assets/img/reverse-trial/come-back-to-teams@2x.png";

import { FeaturingItemListTemplate } from "../templates/FeaturingItemListTemplate";

export const ReverseTrialEndedScreen = ({ handleCreateTeam }) => {
  useEffect(() => {
    trackTeamsReverseTrialEndedPageViewed();
  }, []);
  return (
    <FeaturingItemListTemplate
      title="Come back to Teams"
      description={`Your Teams trial has expired. Want to come back to Teams? Upgrade your subscription anytime.`}
      illustration={
        <img
          className="w-full h-full object-contain object-center"
          src={IllustrationComeBackTeams2x}
          srcSet={`${IllustrationComeBackTeams} 300w, ${IllustrationComeBackTeams2x} 600w`}
          sizes="(max-width: 400px) 300px, 600px"
          alt="illustration"
        />
      }
      items={[
        {
          icon: "clock",
          title: "Save time",
          subtitle: "Businesses save 70 payroll hours per employee each year.",
        },
        {
          icon: "billing-receipt",
          title: "Save money",
          subtitle:
            "Track mileage automatically for most accurate reimbursements.",
        },
      ]}
      primaryCTA={{ label: "See Teams plans", onClick: handleCreateTeam }}
    />
  );
};
