import pe from "../InAppMessage/in-app-message-manager-factory.js";
import e, { OPTIONS as T } from "../managers/braze-instance.js";
import gr from "./triggers-provider.js";
export const TriggersProviderFactory = {
  t: !1,
  provider: null,
  er: () => (TriggersProviderFactory.init(), TriggersProviderFactory.provider),
  rg: () => {
    if (!TriggersProviderFactory.provider) {
      const r = e.nn(T.Ao);
      (TriggersProviderFactory.provider = new gr(
        null != r ? r : 30,
        pe.m().Ve(),
        e.l(),
        e.mr(),
        pe.m()
      )),
        e.ar(TriggersProviderFactory.provider);
    }
  },
  init: () => {
    TriggersProviderFactory.t ||
      (TriggersProviderFactory.rg(),
      e.u(TriggersProviderFactory),
      (TriggersProviderFactory.t = !0));
  },
  destroy: () => {
    (TriggersProviderFactory.provider = null), (TriggersProviderFactory.t = !1);
  }
};
