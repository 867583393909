import React, { useContext, useState } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useUsers } from "src/components/context/UsersContext";

import Button from "src/components/elements/Button";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { removeByEmail, resendInvite } from "src/services/teams";
import {
  teamsResendInviteTypes,
  trackTeamsInviteResent,
  trackTeamsInviteRevoked,
} from "src/services/tracking";

export default UserActions;

function UserActions({ onAfterRevokeSuccess, onSuccess, onFailure }) {
  const { team } = useContext(TeamContext);
  const { selectedUsers, clearSelectedUsers } = useUsers();
  const [isSendingReminder, setSendingReminder] = useState(false);
  const [isSendingRevoke, setSendingRevoke] = useState(false);
  const { checkAndHandleDunning } = useTeamsCTA();

  if (!team?.id) return null;

  const handleSendReminder = async () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setSendingReminder(true);
    const count = selectedUsers?.size;
    try {
      const { invites } = await resendInvite(
        team.id,
        Array.from(selectedUsers.values())
      );

      const haveAllInvitesFailed = invites.every(
        (invite) => Object.values(invite)[0] === false
      );

      if (haveAllInvitesFailed) {
        throw new Error("All invites have failed");
      }

      trackTeamsInviteResent({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        resendType: teamsResendInviteTypes.SUBSET,
        resentUserCount: count,
      });

      handleSuccess(
        `Invitation resent to ${count} user${count > 1 ? "s" : ""}!`
      );
    } catch (e) {
      handleError();
    }
    setSendingReminder(false);
  };

  const handleSendRevoke = async () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setSendingRevoke(true);
    const count = selectedUsers.size;
    const users = Array.from(selectedUsers.values());
    try {
      await removeByEmail(team.id, users);

      trackTeamsInviteRevoked({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        revokedUserCount: count,
      });

      handleSuccess(
        `${count} user${count > 1 ? "s" : ""} have been removed from your team`
      );
      onAfterRevokeSuccess();
    } catch (e) {
      handleError();
    }
    setSendingRevoke(false);
  };

  const handleSuccess = (msg) => {
    onSuccess(msg);
    clearSelectedUsers();
  };

  const handleError = () => {
    onFailure(
      <span>
        Sorry, something went wrong. Please try again or message{" "}
        <a className="text-white underline" href="mailto:support@mileiq.com">
          support@mileiq.com
        </a>
      </span>
    );
  };

  return (
    <div className="right-panel">
      <Button icon="close" className="btn-close" onClick={clearSelectedUsers} />
      <div className="px-1">
        <h4>
          {selectedUsers.size} {selectedUsers.size > 1 ? "users" : "user"}
        </h4>
        <h5 style={{ fontWeight: 400 }}>selected</h5>
      </div>
      <div className="mt-8">
        <Button
          lg
          icon="bell-line"
          className="mb-2 w-full font-medium"
          onClick={() => !isSendingReminder && handleSendReminder()}
          loading={isSendingReminder}
        >
          Send a reminder
        </Button>
        <Button
          lg
          secondary
          icon="close-in-circle"
          className="mb-2 w-full font-medium bg-beige-medium hover:bg-beige-dark active:bg-[#D0CBC8]"
          onClick={() => !isSendingRevoke && handleSendRevoke()}
          loading={isSendingRevoke}
        >
          Revoke invitation
        </Button>
      </div>
    </div>
  );
}
