import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AutoClassificationPageQuickTips from "./auto-classification/AutoClassificationQuickTips";
import AutomaticReportsPageQuickTips from "./automatic-reports/AutomaticReportsQuickTips";
import CustomPurposesPageQuickTips from "./custom-purposes/CustomPurposesQuickTips";
import IntegrationsPageQuickTips from "./integrations/IntegrationsQuickTips";
import MileageRatesPageQuickTips from "./mileage-rates/MileageRatesQuickTips";
import NotificationsPageQuickTips from "./notifications/NotificationsQuickTips";
import ProfilePageQuickTips from "./profile/ProfileQuickTips";
import SharePageQuickTips from "./share/ShareQuickTips";
import VehicleAndOdometerPageQuickTips from "./vehicle-and-odometer/VehicleAndOdometerQuickTips";

export default SettingsQuickTips;

function SettingsQuickTips() {
  return (
    <div className="quick-tips">
      <h4 className="mb-4">Quick Tips</h4>
      <SettingsQuickTipsRoutes />
    </div>
  );
}

function SettingsQuickTipsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/profile`}>
        <ProfilePageQuickTips />
      </Route>
      <Route path={`${path}/notifications`}>
        <NotificationsPageQuickTips />
      </Route>
      <Route path={`${path}/integrations`}>
        <IntegrationsPageQuickTips />
      </Route>
      <Route path={`${path}/share`}>
        <SharePageQuickTips />
      </Route>
      <Route path={`${path}/vehicle-and-odometer`}>
        <VehicleAndOdometerPageQuickTips />
      </Route>
      <Route path={`${path}/mileage-rates`}>
        <MileageRatesPageQuickTips />
      </Route>
      <Route path={`${path}/auto-classification`}>
        <AutoClassificationPageQuickTips />
      </Route>
      <Route path={`${path}/custom-purposes`}>
        <CustomPurposesPageQuickTips />
      </Route>
      <Route path={`${path}/automatic-reports`}>
        <AutomaticReportsPageQuickTips />
      </Route>
    </Switch>
  );
}
