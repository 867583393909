import React, { useContext, useEffect, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import IconButton from "src/components/elements/IconButton";
import Input from "src/components/elements/Input";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

import { getPlaceDetails, getPredictions } from "src/services/maps";

import Icon from "../elements/Icon";

export default PlacesAutocomplete;

const REQUEST_DEBOUNCE_MS = 500;
const OPTIONS_LIST_ID = "OPTIONS_LIST";
const PLACE_OPTION_LI_CLASSNAME = "place-option-li";
const PLACE_OPTION_BTN_CLASSNAME = "place-option-btn";

let requestTimeout = null;

function PlacesAutocomplete({
  className,
  placeholder,
  icon,
  onSelectPlace,
  mapObj,
  placeName,
  onBlur,
  onFocus,
  valid,
  bottomRight,
  showPlacesIcon,
  maximumNamedLocations,
  showClearButton,
  handleClear,
  adjustPosition,
  itemClassName,
  inputProps,
}) {
  const { userData } = useContext(UserDataContext);
  const [name, setName] = useState(placeName || "");
  const [predictions, setPredictions] = useState([]);

  const inpBlockRef = useRef();

  const handleSelectPrediction = async (prediction) => {
    try {
      let newPlace = {};
      if (prediction.isNamed) {
        newPlace = {
          isNamed: true,
          name: prediction.name,
          location: {
            latitude: prediction.latitude,
            longitude: prediction.longitude,
          },
        };
      } else {
        const res = await getPlaceDetails(prediction.place_id, {
          map: mapObj?.current || mapObj,
        });
        newPlace = {
          isNamed: false,
          name: prediction.description,
          location: {
            latitude: res.location?.lat() || null,
            longitude: res.location?.lng() || null,
          },
        };
      }
      onSelectPlace(newPlace);
      setName(newPlace.name);
      optionsList.deactivate();
    } catch (err) {
      console.log(err);
    }
  };

  const optionsList = useElement(OPTIONS_LIST_ID, {
    props: {
      trigger: inpBlockRef,
      onClose: () => optionsList.deactivate(),
      onSelect: handleSelectPrediction,
      bottomRight,
      showPlacesIcon,
      adjustPosition,
      itemClassName,
    },
  });

  const handleQueryChange = (e) => {
    const v = e.target.value;

    // notify that input value changed and we don't know place details yet.
    // so we set place without location,
    // to make sure we get latitude and longitude from api
    // and only when user picks any prediction from the list
    onSelectPlace({
      name: v,
      location: {
        latitude: null,
        longitude: null,
      },
    });

    clearTimeout(requestTimeout);
    setName(v);

    if (v.trim() === "") {
      setPredictions([]);
      optionsList.deactivate();
      return;
    }

    requestTimeout = setTimeout(async () => {
      try {
        const res = await getPredictions(v);
        const regEx = new RegExp(v, "gi");
        let named =
          userData?.namedLocations
            ?.filter((loc) => loc.name.match(regEx))
            ?.map((loc) => ({
              matches: [...loc.name.matchAll(regEx)],
              isNamed: true,
              ...loc,
            })) || [];

        if (maximumNamedLocations) {
          named = named.slice(0, maximumNamedLocations);
        }

        const newPredictions = [...named, ...res];
        setPredictions(newPredictions);

        if (!optionsList.open) {
          optionsList.activate({
            props: {
              predictions: [...newPredictions],
            },
          });
        } else {
          optionsList.updateProps({
            predictions: [...newPredictions],
          });
        }
      } catch (err) {
        setPredictions([]);
      }
    }, REQUEST_DEBOUNCE_MS);
  };

  const handleBlur = (e) => {
    // check if blur happend as a result of the click on one of the options in place autocomplete
    // !!! IMPORTANT:
    // !!! In Safari e.relatedTarget === null
    // !!! reason: Safari does not put focus on the <button> (even with tabindex) after it was clicked ,
    // !!! (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus)
    // !!! so, as a workaround we made surrounding <li> focusable with tabindex
    // !!! and we expect to be relatedTarget when option was clicked.
    // !!! in other browser <button> will be in e.relatedTarget

    const clList = e.relatedTarget?.classList;
    const isOptionClicked =
      clList?.contains(PLACE_OPTION_LI_CLASSNAME) || // Safari hack
      clList?.contains(PLACE_OPTION_BTN_CLASSNAME); // normal browsers

    if (!isOptionClicked) {
      optionsList.deactivate();
      if (typeof onBlur === "function") onBlur(e);
    }
  };

  const handleFocus = () => {
    onFocus?.();

    if (predictions?.length > 0) {
      optionsList.activate({
        props: {
          predictions,
        },
      });
    }
  };

  const classes = ["relative", className];
  if (icon) {
    classes.push("[&_input]:ml-[24px]");
  }

  useEffect(() => {
    if (placeName) setName(placeName);
  }, [placeName]);

  return (
    <div ref={inpBlockRef} className={classes.join(" ")}>
      {icon ? (
        <div className="flex absolute pos-vert-center left-[16px]">{icon}</div>
      ) : null}
      <Input
        placeholder={placeholder}
        value={name}
        onChange={handleQueryChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        valid={valid}
        rightIcon={
          showClearButton && (
            <IconButton
              className="right-icon mr-4"
              onClick={() => typeof handleClear === "function" && handleClear()}
              name="close-in-circle"
              color="black/30"
            />
          )
        }
        {...inputProps}
      />
    </div>
  );
}

registerElement(OPTIONS_LIST_ID, PlacesAutocompleteList);
function PlacesAutocompleteList({
  trigger,
  predictions,
  onSelect,
  onClose,
  bottomRight,
  showPlacesIcon,
  adjustPosition,
  itemClassName,
}) {
  return (
    <Pivoted
      pivot={trigger?.current}
      offset={{ y: 5 }}
      onClickOutside={onClose}
      style={{ zIndex: 30000 }}
      bottomRight={bottomRight}
      adjustPosition={adjustPosition}
    >
      <div className="p-2 bg-white w-max max-w-[90vw] rounded border border-border-1 shadow-md shadow-[#0003]">
        <ul className="m-0 p-0 max-h-[260px] overflow-auto">
          {predictions.map((p) => {
            const name = p.isNamed ? p.name : p.description;
            let key = "";
            let hlStart = 0;
            let hlLen = 0;
            if (p.isNamed) {
              key = `${p.namedLocationDetails.id}`;
              const match = p.matches[0];
              if (match) {
                hlStart = match?.index || 0;
                hlLen = match[0] ? match[0].length : 0;
              }
            } else {
              key = p.place_id;
              const subStr = p.matched_substrings[0];
              hlStart = subStr?.offset || 0;
              hlLen = subStr?.length || 0;
            }

            const title = {
              start: name.slice(0, hlStart),
              highlighted: name.slice(hlStart, hlStart + hlLen),
              end: name.slice(hlStart + hlLen),
            };
            return (
              <li
                key={key}
                className={`${PLACE_OPTION_LI_CLASSNAME} w-full rounded-8 hover:bg-beige ${
                  itemClassName || ""
                }`}
                tabIndex="0"
              >
                <button
                  className={`${PLACE_OPTION_BTN_CLASSNAME} w-full p-2 text-left`}
                  onClick={() => onSelect(p)}
                >
                  <div className="flex gap-3 items-center">
                    {showPlacesIcon && (
                      <Icon
                        name={p.isNamed ? "location-pin-alt" : "location-pin"}
                      />
                    )}
                    <Text md>
                      {title.start}
                      <Text md bold>
                        {title.highlighted}
                      </Text>
                      {title.end}
                    </Text>
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </Pivoted>
  );
}
