import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import IconButton from "src/components/elements/IconButton";

export default MiqMobMenu;

function MiqMobMenu() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    return () => {
      document.body.classList.remove("mob-menu-visible");
    };
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [location.key]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("mob-menu-visible");
    } else {
      document.body.classList.remove("mob-menu-visible");
    }
  }, [open]);

  const _handleClick = () => {
    setOpen((_open) => !_open);
  };

  return (
    <div className={`relative ${open ? "w-6 h-6 mr-5" : ""}`}>
      <IconButton
        name={open ? "menu-close" : "menu"}
        color="black"
        className="mob-menu mob-version mr-5"
        onClick={_handleClick}
      />
    </div>
  );
}
