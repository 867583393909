import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";

import QR from "src/components/elements/QR";
import Text from "src/components/elements/Text";

import TextToDownload from "src/components/blocks/TextToDownload";

import useQueryParams from "src/hooks/useQueryParams";

import {
  getPendingUserSubscription,
  removePendingUserSubscription,
} from "src/services/storage";
import {
  AppDownloadRequestMethod,
  UpgradeSource,
  init,
  trackAppDownloadRequested,
  trackUpgradeCompleted,
} from "src/services/tracking";
import { appstoreOnelink, isIphone, isMobile } from "src/services/utils";

import AppleStore from "public/assets/img/appstores/apple.svg";
import GoogleStore from "public/assets/img/appstores/google.svg";
import Illustration from "public/assets/img/illustrations/app-screenshots.png";
import Illustration2x from "public/assets/img/illustrations/app-screenshots@2x.png";

export default WelcomeDownload;

function WelcomeDownload() {
  const qs = useQueryParams();
  const { miqDashboardStandaloneSignupTestWeb: standaloneSignupTest } =
    useFlags();
  useEffect(() => {
    if (qs.get("checkout-status") === "success") {
      const uid = qs.get("uid");
      const pendingSub = getPendingUserSubscription();
      if (pendingSub?.isNew && pendingSub?.uid == uid) {
        init({ id: uid });
        trackUpgradeCompleted({
          src: UpgradeSource.ONBOARDING,
          subType: pendingSub?.subType,
        });
        removePendingUserSubscription();
      }
    }
  }, []);

  const uid = qs.get("uid");
  let qrUrl = window.location.origin + "/qr";
  if (uid) qrUrl += "?uid=" + uid;

  let isABTest1, isABTest2;
  isABTest1 = standaloneSignupTest === "Test 1";
  isABTest2 = standaloneSignupTest === "Test 2";
  const productTestClasses = [];
  if (isABTest1) {
    productTestClasses.push("quick-signup-standalone-test1");
  }
  if (isABTest2) {
    productTestClasses.push("quick-signup-standalone-test2");
  }
  return (
    <div
      className={`${productTestClasses.join(
        " "
      )} w-full pb-[20px] relative page-signup page-signup-welcome bg-[#EBF9FF] mobile:bg-white flex items-center mobile:items-start justify-center`}
    >
      <a
        className="logo-with-text absolute left-[30px] top-[20px] mobile:left-[20px] mobile:top-[20px] z-[100]"
        href="https://mileiq.com"
        target="_blank"
        rel="noopener"
      />
      <div className="grid grid-cols-[490px_490px] items-center gap-[100px] tablet:grid-cols-1">
        <div className="w-[490px] tablet:hidden">
          <img
            src={Illustration}
            srcSet={`${Illustration} 456w, ${Illustration2x} 929w`}
            sizes="(max-width: 1280px) 456px, 929px"
            alt="illustration"
            className="w-full"
          />
        </div>
        <div className="pt-[30px]">
          <div className="relative z-20 w-[490px] mobile:w-full flex flex-col gap-[30px] items-center justify-center p-[60px] mobile:px-[30px] mobile:pt-[115px] bg-white rounded-[30px] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.08)] mobile:shadow-none">
            <div className="max-w-[320px]">
              <Text
                paragraph
                bold
                className="leading-none text-center text-[28px] text-[#171717]"
              >
                Welcome to MileIQ
              </Text>
              <Text
                paragraph
                className="text-center mt-[10px] text-[16px] text-[#171717]/80"
              >
                Download the MileIQ app on your phone, then sign in with your
                email and password to start tracking your drives
              </Text>
            </div>
            {isMobile() ? (
              <div>
                <a
                  href={appstoreOnelink + "?uid" + qs.get("uid")}
                  onClick={() =>
                    trackAppDownloadRequested({
                      method: AppDownloadRequestMethod.CLICK_TO_DOWNLOAD,
                    })
                  }
                >
                  {isIphone() ? <AppleStore /> : <GoogleStore />}{" "}
                </a>
              </div>
            ) : (
              <>
                <div className="flex flex-col gap-[10px] items-center">
                  <div className="w-[150px] h-[150px]">
                    <QR width={150} height={150} url={qrUrl} />
                  </div>
                  <Text
                    paragraph
                    className="max-w-[320px] text-center text-[14px] text-[#171717]/80"
                  >
                    Point your phone’s camera at the QR code and follow the link
                    to download the app, then sign in with your email and
                    password.
                  </Text>
                </div>
                <div className="w-full grid grid-cols-[1fr_auto_1fr] gap-[10px] items-center">
                  <div className="h-[1px] bg-border-1" />
                  <Text className="flex-shrink-0 text-[14px] text-[#171717]/80">
                    Or get a message with a download link
                  </Text>
                  <div className="h-[1px] bg-border-1" />
                </div>
                <div className="w-full flex flex-col gap-[15px]">
                  <TextToDownload />
                </div>
              </>
            )}
            <div className="miq-continue-link-variants mt-[30px] text-center">
              <a href="/" className="underline">
                <Text semibold className="text-[15px]">
                  Continue to Driver Dashboard instead
                </Text>
              </a>
            </div>
          </div>
          <div className="miq-continue-link-control mt-[30px] text-center">
            <a href="/" className="underline">
              <Text semibold className="text-[15px]">
                Continue to Driver Dashboard instead
              </Text>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
