import {
  getAccessToken,
  removeAccessToken,
  removeRefreshToken,
} from "src/services/storage";

import { tryRefreshToken } from "./auth";

export default {
  get,
  post,
  put,
  postFormUrlencoded,
  delete: del,
};

export const NETWORK_STATES = {
  LOADING: "LOADING",
  IDLE: "IDLE",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const apiUrl = process.env.API_URL;

function _url(path) {
  if (path.startsWith("http://") || path.startsWith("https://")) return path;

  if (!path || !path.trim()) throw Error("Invalid path");
  if (path[0] != "/") throw new Error("Path should start with '/'");
  return `${apiUrl}${path}`;
}

async function _tryFetch(path, options = {}) {
  const token = options?.authToken || getAccessToken();
  const _options = {
    mode: "cors",
    ...(!token ? { credentials: "include" } : {}),
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...options,
  };
  const res = await fetch(_url(path), _options);
  let data;
  try {
    if (options.asBlob) {
      data = await res.blob();
    } else {
      data = await res.json();
    }
  } catch (err) {
    data = {};
  }
  if (res.ok) {
    return Promise.resolve(data);
  } else {
    if (res.status === 401) {
      if (options.usedRefreshToken) {
        removeRefreshToken();
        removeAccessToken();
      } else {
        await tryRefreshToken(); // It handles logout if refresh token is invalid

        return await _tryFetch(path, { ...options, usedRefreshToken: true });
      }
    }
    return Promise.reject({ status: res.status, data });
  }
}

async function get(path, options) {
  return _tryFetch(path, options);
}

async function post(path, payload, options = {}) {
  let body;
  try {
    body = payload ? JSON.stringify(payload) : "";
  } catch (err) {
    // console.log(err);
  }
  return _tryFetch(path, {
    method: "POST",
    body,
    ...options,
  });
}

async function put(path, payload, options = {}) {
  let body;
  try {
    body = payload ? JSON.stringify(payload) : "";
  } catch (err) {
    // console.log(err);
  }
  return _tryFetch(path, {
    method: "PUT",
    body,
    ...options,
  });
}

async function postFormUrlencoded(path, payload) {
  return _tryFetch(path, {
    method: "POST",
    body: new URLSearchParams(payload),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
}

async function del(path, options = {}) {
  return _tryFetch(path, {
    method: "DELETE",
    ...options,
  });
}
