import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import { addUsers } from "src/services/teams";
import {
  teamsInviteFailureReasons,
  trackTeamsInviteCompleted,
  trackTeamsInviteFailed,
} from "src/services/tracking";
import { formatCurrency, plural } from "src/services/utils";

import InvitingLoader from "public/assets/img/inviting-users-loader.svg";

export default InviteUsersConfirm;

function InviteUsersConfirm({
  team,
  invites,
  availableSeats,
  show,
  onConfirm,
  onBack,
  onClose,
}) {
  const [loading, setLoading] = useState(false);

  const handleConfirmClicked = async () => {
    console.log(invites);
    if (loading) return;
    setLoading(true);
    try {
      await addUsers(team.id, invites);
      trackTeamsInviteCompleted({
        seatsAdded: invites.length,
        inviteCount: invites.length,
      });
      onConfirm();
    } catch (err) {
      console.log(err);
      trackTeamsInviteFailed({ reason: teamsInviteFailureReasons.OTHER });
    }
  };

  const _renderNotes = () => {
    if (availableSeats && paidInvites.length > 0) {
      let filling;
      const paidInvites = invites.filter(
        (inv) => inv.userType !== USER_TYPES.ADMIN
      );
      if (paidInvites.length > availableSeats) {
        filling = availableSeats;
      } else {
        filling = paidInvites.length;
      }

      return (
        <div className="bg-green-pastel flex items-center mt-7 px-6 py-5 rounded">
          <div>
            <Icon name="chair" color="black" className="mr-6" />
          </div>
          <Text paragraph lg>
            You are filling{" "}
            <Text bold color="green">{`${filling} open ${plural(
              filling,
              "seat",
              "seats"
            )}`}</Text>{" "}
            at no additional cost for the remainder of your billing cycle
          </Text>
        </div>
      );
    }

    return null;
  };

  let subtotal = 0;
  const paidInvites = invites.filter(
    (inv) => inv.userType !== USER_TYPES.ADMIN
  );
  if (paidInvites.length > availableSeats) {
    const { plan, type, percentOff } = team.subscription;
    subtotal =
      TEAMS_SUBSCRIPTION_PLANS_DATA.prices[plan][type] *
      (1 - percentOff / 100) *
      (paidInvites.length - availableSeats);
  }

  return (
    <Modal
      className="w-[540px]"
      show={show}
      onClose={onClose}
      closable={!loading}
      onBack={loading ? null : onBack}
      // loading={loading}
      // loadingText="Processing... This may take a few minutes depending on the number of invites."
      // loadingTextTimeout={10000}
    >
      {loading ? (
        <div
          className="flex flex-col items-center justify-center"
          style={{ minHeight: "270px" }}
        >
          <h4 className="mb-8">{`Inviting ${invites.length} new ${plural(
            invites.length,
            "user",
            "users"
          )}...`}</h4>
          <InvitingLoader />
        </div>
      ) : (
        <>
          <h3 className="text-center mb-8">Confirm Invitation</h3>
          <Text paragraph className="text-center">
            You are about to invite {invites.length}{" "}
            {plural(invites.length, "user", "users")}
          </Text>
          {_renderNotes()}
          {(subtotal > 0 || paidInvites.length === 0) && (
            <div className="flex flex-col items-center mt-8">
              <Text className="mb-2">Subtotal</Text>
              <h4>{`+ ${formatCurrency({ value: subtotal })} per ${
                team.subscription.type === TEAM_SUBSCRIPTION_TYPES.MONTH
                  ? "month"
                  : "year"
              }`}</h4>
            </div>
          )}
          <Button
            lg
            appearance="primary"
            className="w-full mt-8 p-0"
            onClick={handleConfirmClicked}
          >
            Confirm and Invite
          </Button>
        </>
      )}
    </Modal>
  );
}
