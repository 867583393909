import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import TeamContext from "src/components/context/TeamContext";
import { RECORDS_PER_PAGE } from "src/components/context/TeamDrivesContext";
import {
  COLUMNS,
  useTeamDrivesSummary,
} from "src/components/context/TeamDrivesSummaryContext";

import MiqButton from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Pagination from "src/components/elements/Pagination";

import TeamMemberName from "src/components/blocks/reports/teams/TeamMemberName";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { SORT_ORDER } from "src/services/http";
import { WebPages, trackTeamsDrivesListOpened } from "src/services/tracking";

import OkCarWhite from "public/assets/img/ok-car-white.svg";

export default TeamDrivesToApproveSummaryList;

const columnsData = {
  [COLUMNS.DRIVER]: {
    title: "Driver",
    sortable: false,
    extraClass: "pl-4",
    fieldName: "user_email",
  },
  [COLUMNS.DRIVE_COUNT]: {
    title: "Drives submitted",
    extraClass: "report-list-column-fix",
    sortable: false,
    fieldName: "drive_count",
  },
  [COLUMNS.MANUALLY_ADDED_DRIVES_COUNT]: {
    title: "Manually added",
    sortable: false,
    fieldName: "manually_added_count",
  },
  [COLUMNS.DISTANCE]: {
    title: "Distance submitted",
    extraClass: "desktop-version",
    sortable: false,
    fieldName: "distance",
  },
  [COLUMNS.VALUE]: {
    title: "Value submitted",
    sortable: false,
    fieldName: "value",
  },
  [COLUMNS.ACTIONS]: {
    title: "",
    extraClass: "pr-4",
    sortable: false,
    fieldName: "",
  },
};

function TeamDrivesToApproveSummaryList() {
  const {
    driverSummaries,
    page,
    sortBy,
    order,
    isTeamSummaryLoading,
    hasTeamSummaryLoaded,
    isTeamSummaryEmpty,
    loadingItemsCount,
  } = useTeamDrivesSummary();
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleColumnClicked = (columnKey) => {
    if (isTeamSummaryLoading) return;

    let sortOrder = SORT_ORDER.DESC;
    if (sortBy === columnKey) {
      sortOrder = order === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    }

    queryParams.set("sortBy", columnKey);
    queryParams.set("order", sortOrder);
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handlePageClicked = (pageIndex) => {
    if (isTeamSummaryLoading || loadingItemsCount > 0) return;

    queryParams.set("page", pageIndex);
    history.replace({ search: queryParams.toString() });
  };

  const pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage: page,
    total: driverSummaries?.total_results || 0,
    onSelectPage: handlePageClicked,
  };

  const hasPagination = hasTeamSummaryLoaded && driverSummaries?.pages > 1;

  return (
    <div className="team-drives-list flex-grow flex flex-col">
      <div className="miq-list-table team-drives-to-approve-summary-list-table">
        <div className="miq-list-table-header h-[48px] rounded-t-[14px] hover:bg-transparent">
          {Object.keys(columnsData).map((columnKey) => {
            const { extraClass, title, sortable, alignRight } =
              columnsData[columnKey];
            return (
              <div
                key={title}
                className={`select-none truncate p-2 ${
                  sortable ? "cursor-pointer" : ""
                } ${extraClass || ""}`}
                onClick={() => sortable && handleColumnClicked(columnKey)}
              >
                <div className={`flex ${alignRight ? "justify-end" : ""}`}>
                  <p className="text-black/70 text-13 truncate">{title}</p>
                  {sortable && sortBy === columnKey ? (
                    <Icon
                      name="sort"
                      color="black/50"
                      className={`ml-2 transform ${
                        order === SORT_ORDER.DESC ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className="group/driver-summary-list miq-list-table-body">
          {hasTeamSummaryLoaded && isTeamSummaryEmpty && (
            <div className="w-full min-h-[630px] flex flex-col justify-center items-center gap-[24px]">
              <OkCarWhite className="opacity-30" />
              <p className="text-black/60 text-center max-w-[275px]">
                No drives to approve for this time period. Try adjusting your
                filters.
              </p>
            </div>
          )}
          {hasTeamSummaryLoaded && !isTeamSummaryEmpty && (
            <TransitionGroup component="div">
              {driverSummaries.data.map((driver) => (
                <CSSTransition
                  key={driver.id}
                  timeout={200}
                  classNames="removable-item"
                >
                  <TeamDrivesSummaryRow
                    driver={driver}
                    hasFollowingElements={
                      hasPagination || loadingItemsCount > 0
                    }
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          )}
          {(isTeamSummaryLoading || loadingItemsCount > 0) &&
            Array.from({ length: loadingItemsCount }).map((_, i) => (
              <TeamDrivesSummaryRow
                key={`loading-${i}`}
                rowClassName={
                  isTeamSummaryLoading ? "" : "animate-expand-table-row"
                }
                isLoading
                hasFollowingElements={hasPagination}
              />
            ))}
        </div>
      </div>
      {hasTeamSummaryLoaded && hasPagination && (
        <div className="my-[15px] mx-5">
          <Pagination {...pagination} />
        </div>
      )}
    </div>
  );
}

function TeamDrivesSummaryRow({
  rowClassName,
  driver = {},
  hasFollowingElements,
  isLoading,
}) {
  const { team } = useContext(TeamContext);
  const history = useHistory();
  const queryParams = useQueryParams();
  const rangeDates = queryParams.get("rangeDates");
  const { approveTeamDrives } = useTeamDrivesSummary();
  const [isApproving, setIsApproving] = useState(false);
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    id,
    drive_count: driveCount,
    distance,
    manually_added_count: manuallyAddedCount,
    distance_unit: distanceUnit,
    total_value: value,
    total_value_currency_unit: currencyUnit,
    user_email: userEmail,
    user_first_name: userFirstName,
    user_last_name: userLastName,
    parking_fees: parking,
    toll_fees: toll,
  } = driver;

  let link = `/teams/drives-to-approve/${id}`;

  const extras = parseFloat(parking) + parseFloat(toll);
  const totalValue = parseFloat(value) + extras;

  const searchParams = new URLSearchParams();

  if (rangeDates) {
    searchParams.set("rangeDates", rangeDates);
  }

  const driverFullName =
    userFirstName && userLastName ? `${userFirstName} ${userLastName}` : null;
  const driverName = driverFullName || userEmail;

  searchParams.set("driverName", driverName);

  link += `?${searchParams.toString()}`;

  const Wrapper = isLoading ? "div" : Link;

  const handleGoToDrivesList = (e) => {
    e.preventDefault();

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsDrivesListOpened({
      isAllDrivers: false,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      webPage: WebPages.APPROVE_DRIVES_SUMMARY_TEAM_DASHBOARD,
    });

    history.push(link);
  };

  const handleQuickApprove = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsApproving(true);

    await approveTeamDrives({ driverId: id });

    setIsApproving(false);
  };

  return (
    <Wrapper
      onClick={isLoading ? undefined : handleGoToDrivesList}
      to={isLoading ? undefined : link}
      className={`group/link-row ${rowClassName || ""}`}
    >
      <div
        data-chmln="team-drives-to-approve-list-row"
        className={`group/drive miq-list-table-body-row h-[60px] ${
          hasFollowingElements
            ? ""
            : "group-last/link-row:border-none group-last/link-row:rounded-b-[14px]"
        } ${isLoading ? "hover:bg-transparent cursor-auto" : ""}`}
      >
        {/* Driver */}
        <div className="relative p-2 pl-4 truncate">
          {isLoading ? (
            <div className="w-[200px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <TeamMemberName
              member={{
                email: userEmail,
                firstName: userFirstName,
                lastName: userLastName,
              }}
            />
          )}
        </div>

        {/* Drives submitted */}
        <div className="p-2 desktop-version">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">{driveCount}</p>
          )}
        </div>

        {/* Manually added drives count */}
        <div className="p-2 desktop-version">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">{manuallyAddedCount}</p>
          )}
        </div>

        {/* Distance submitted */}
        <div className="p-2 report-list-column-fix">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">
              {distance?.toFixed?.(1)} {distanceUnit}
            </p>
          )}
        </div>

        {/* Value submitted */}
        <div className="p-2 font-semibold text-green">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p>
              {currencyUnit}
              {totalValue?.toFixed?.(2)}
            </p>
          )}
        </div>

        {/* Actions */}
        <div className="p-2 pr-4 flex justify-end">
          {!isLoading && (
            <MiqButton
              onClick={handleGoToDrivesList}
              secondary
              className={`${
                isApproving ? "" : "hidden"
              } min-w-[128px] mr-3  font-medium tablet:block md:group-hover/link-row:block bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]`}
            >
              View drives
            </MiqButton>
          )}

          {!isLoading && (
            <MiqButton
              onClick={handleQuickApprove}
              secondary
              className={`${
                isApproving ? "" : "hidden"
              } min-w-[128px] font-medium tablet:block md:group-hover/link-row:block bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]`}
            >
              {isApproving ? "Approving..." : "Quick approve"}
            </MiqButton>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
