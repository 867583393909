import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import React, { useState } from "react";

import Dropdown from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";
import Text from "src/components/elements/Text";

export default FilterMonths;

const months = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["Jun", "June"],
  ["Jul", "July"],
  ["Aug", "August"],
  ["Sep", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];

function FilterMonths({ selected, onSelect, onClear, onOpen, customLabel }) {
  const [isOpen, setIsOpen] = useState(false);

  const hasValue = selected !== null;
  const label =
    customLabel ||
    (hasValue ? months[getMonth(selected)][1] : "Month of drives");

  const handleSelect = (monthIdx, year) => {
    if (typeof onSelect === "function") onSelect(monthIdx, year);
    setIsOpen(false);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    if (typeof onSelect === "function") onClear(null);
  };

  const handleOpen = () => {
    const cancelOperation = onOpen?.();

    if (cancelOperation) return;

    setIsOpen(true);
  };

  return (
    <Dropdown
      icon="route"
      label={label}
      open={isOpen}
      hasValue={hasValue}
      onOpen={handleOpen}
      onClose={() => setIsOpen(false)}
      minContentWidth={340}
      suffix={
        hasValue && !isOpen ? (
          <IconButton name="close" onClick={handleClear} />
        ) : null
      }
      tooltip={{
        text: "Filter by month of drives",
        alignX: "center",
        offset: { y: 50 },
      }}
      triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
      openTriggerClassName="border-blue hover:border-blue active:border-blue"
    >
      <YearMonthPicker value={selected} onSelect={handleSelect} />
    </Dropdown>
  );
}

export function YearMonthPicker({ value, onSelect }) {
  const now = new Date();
  const currentMonth = getMonth(now);
  const currentYear = getYear(now);

  const [year, setYear] = useState(value ? getYear(value) : currentYear);

  const prevYear = () => {
    setYear((y) => y - 1);
  };

  const nextYear = () => {
    setYear((y) => (y < currentYear ? y + 1 : currentYear));
  };

  return (
    <div className="p-2 flex flex-col">
      <div className="flex items-center justify-between mb-6">
        <IconButton onClick={prevYear} name="chevron-left-small" />
        <Text bold>{year}</Text>
        <IconButton
          onClick={nextYear}
          name="chevron-right-small"
          className={
            year < currentYear
              ? "pointer-events-auto opacity-100"
              : "pointer-events-none opacity-0"
          }
        />
      </div>
      <div className="grid grid-cols-4 grid-rows-3 gap-1">
        {months.map((m, i) => {
          return (
            <button
              key={m}
              className="border-none m-0 p-2 rounded hover:bg-beige active:bg-beige-medium disabled:bg-transparent disabled:text-black/30"
              disabled={year == currentYear ? currentMonth < i : false}
              onClick={() => onSelect(i, year)}
            >
              <Text>{m[0]}</Text>
            </button>
          );
        })}
      </div>
    </div>
  );
}
