import React from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

export default MiqPagination;

function MiqPagination({ pageSize, currentPage, total, onSelectPage }) {
  if (total === 0) return null;

  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(total, (currentPage - 1) * pageSize + pageSize);
  const numPages =
    Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1);

  let pages = [];

  if (numPages <= 7) {
    for (let i = 1; i <= numPages; i++) {
      pages.push(i);
    }
  } else {
    // first page always visible
    pages.push(1);
    if (currentPage < 4) {
      for (let i = 2; i <= 5; i++) {
        pages.push(i);
      }
      pages.push("...");
    } else if (currentPage > numPages - 3) {
      pages.push("...");
      for (let i = numPages - 4; i < numPages; i++) {
        pages.push(i);
      }
    } else {
      if (pages[pages.length - 1] === currentPage - 3) {
        pages.push(currentPage - 2);
      } else {
        pages.push("...");
      }
      pages.push(currentPage - 1, currentPage, currentPage + 1);
      if (currentPage + 3 === numPages) {
        pages.push(currentPage + 2);
      } else {
        pages.push("...");
      }
    }
    // last page always visible
    pages.push(numPages);
  }

  return (
    <div className="miq-pagination">
      <div className="miq-pagination-info">
        <Text>
          Showing{" "}
          <Text bold lg>
            {from}
          </Text>{" "}
          to{" "}
          <Text bold lg>
            {to}
          </Text>{" "}
          of{" "}
          <Text bold lg>
            {total}
          </Text>{" "}
          results
        </Text>{" "}
      </div>
      <div className="flex">
        <button
          className="btn-prev-next prev"
          onClick={() => {
            if (currentPage > 1) {
              onSelectPage(currentPage - 1);
            }
          }}
        >
          <Icon name="chevron-left" className="transform scale-50" />
        </button>
        {pages.map((p, i) => {
          const isPageNum = p !== "...";
          return (
            <button
              key={`${i}_${p}`}
              className={`btn-page ${
                isPageNum ? "btn-page-num" : "btn-page-dots"
              } ${p === currentPage ? "active" : ""}`}
              onClick={() => {
                if (isPageNum && p !== currentPage) onSelectPage(p);
              }}
            >
              <Text className="select-none">{p}</Text>
            </button>
          );
        })}
        <button
          className="btn-prev-next next"
          onClick={() => {
            if (currentPage < numPages) {
              onSelectPage(currentPage + 1);
            }
          }}
        >
          <Icon name="chevron-right" className="transform scale-50" />
        </button>
      </div>
    </div>
  );
}
