import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import ue from "../models/braze-event.js";
import _t from "../models/identifier.js";
import { convertMsToSeconds as l } from "../util/date-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
export default class Rt {
  constructor(s, t, e, i) {
    (this.h = s),
      (this.v = t),
      (this.gt = e),
      (this.uh = 1e3),
      (i = parseFloat(i)),
      isNaN(i) && (i = 1800),
      i < this.uh / 1e3 &&
        (r.D.info(
          "Specified session timeout of " +
            i +
            "s is too small, using the minimum session timeout of " +
            this.uh / 1e3 +
            "s instead."
        ),
        (i = this.uh / 1e3)),
      (this.ah = i);
  }
  mh(s, t) {
    return new ue(this.v.getUserId(), r.A.dh, s, t.iu, { d: l(s - t.fh) });
  }
  ca() {
    const s = this.h.tu(o.eu.ph);
    return null == s ? null : s.iu;
  }
  gh() {
    const s = new Date().valueOf(),
      t = this.gt.wl(),
      e = this.h.I(o.q.wh);
    if (null != e && null == t) return !1;
    const i = null == e || s - e > 1e3 * t;
    return i && this.h.B(o.q.wh, s), i;
  }
  Sh(s, t) {
    return null == t || (!(s - t.fh < this.uh) && t.jh < s);
  }
  mo() {
    const s = new Date().valueOf(),
      t = s + 1e3 * this.ah,
      e = this.h.tu(o.eu.ph);
    if (this.Sh(s, e)) {
      let i = "Generating session start event with time " + s;
      if (null != e) {
        let s = e.bh;
        s - e.fh < this.uh && (s = e.fh + this.xh),
          this.h.Dh(this.mh(s, e)),
          (i += " (old session ended " + s + ")");
      }
      (i += ". Will expire " + t.valueOf()), r.D.info(i);
      const n = new _t(r.it.nt(), t);
      this.h.Dh(new ue(this.v.getUserId(), r.A.Ch, s, n.iu)),
        this.h.ru(o.eu.ph, n);
      return null == this.h.I(o.q.wh) && this.h.B(o.q.wh, s), n.iu;
    }
    return (e.bh = s), (e.jh = t), this.h.ru(o.eu.ph, e), e.iu;
  }
  Gh() {
    const s = this.h.tu(o.eu.ph);
    null != s &&
      (this.h.Nh(o.eu.ph), this.h.Dh(this.mh(new Date().valueOf(), s)));
  }
}
