import Base from "src/models/base";

export default class RatesUS extends Base {
  // data = null;

  constructor(data) {
    super("rates_us_model");
    /* Expected scheme for US rates
      {
          "year": 2013,
          "parse_id": "mnNuzO2njw",
          "display_name": "2013 US IRS RATES",
          "currency": "USD",
          "units": "mi",
          "name": "country_us",
          "effective_date": null,
          "values": {
              "business": {
                  "default": 0.565
              },
              "personal": {
                  "charity": 0.14,
                  "default": 0,
                  "medical": 0.24,
                  "moving": 0.24
              }
          }
      }
    */
    if (data) {
      this.data = data;
    } else {
      this.data = {
        values: {
          business: {
            default: 0,
          },
          personal: {
            charity: 0,
            default: 0,
            medical: 0,
            moving: 0,
          },
        },
      };
    }
  }

  get id() {
    return this.__inst_id;
  }

  get parseId() {
    return this.data?.parse_id;
  }

  get effectiveDateText() {
    return this.data?.effectiveDateText;
  }

  get effectiveDate() {
    const effectiveDate = this.data?.effective_date;
    if (!effectiveDate) {
      return null;
    }
    if (typeof effectiveDate === "string") {
      return new Date(effectiveDate.replace(/z/gi, ""));
    }
    return effectiveDate;
  }

  set effectiveDate(date) {
    this.data.effective_date = date;
  }

  get value() {
    return this.data?.values?.business?.default;
  }

  set value(v) {
    this.data.values.business.default = parseFloat(v);
  }

  clone() {
    return new RatesUS(JSON.parse(JSON.stringify(this.data)));
  }

  get status() {
    return this.data?.status;
  }

  set status(status) {
    this.data.status = status;
  }
}
