import "src/models/typings";

import { useMutation } from "@apollo/client";
import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import { DELETE_REPORT_SUBMISSION } from "src/graphql/mutations";
import { GET_REPORT_SUBMISSIONS } from "src/graphql/queries";

import Button from "../elements/Button";
import { FlashTypes } from "../elements/Flash";
import Text from "../elements/Text";

export default DeleteArchivedReport;

export const ELEMENT_ID = "DELETE_ARCHIVED_REPORT";
registerElement(ELEMENT_ID, DeleteArchivedReport);

function DeleteArchivedReport({
  onDelete,
  onClose,
  flash,
  submissionId,
  reportName,
}) {
  const [deleteReportSubmission, res] = useMutation(DELETE_REPORT_SUBMISSION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GET_REPORT_SUBMISSIONS }],
    onQueryUpdated: (q) => q.refetch(),
  });

  return (
    <Modal
      testId="modal-delete-archived-report"
      closable
      show
      className="w-[440px]"
      onClose={onClose}
    >
      <h4 className="mb-3">Are you sure?</h4>
      <Text>Deleting this report cannot be reversed.</Text>
      <div className="flex justify-end mt-6 gap-2">
        <Button className="font-medium" secondary onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="font-medium"
          destructive
          loading={res.loading}
          onClick={() =>
            deleteReportSubmission({
              variables: {
                data: {
                  submissionId,
                },
              },
            })
              .then(() => {
                onDelete?.();
                flash(<Text>{reportName} deleted</Text>, {
                  type: FlashTypes.SAVED,
                });
              })
              .finally(() => {
                onClose();
              })
          }
        >
          {res.loading ? "Loading..." : "Delete report"}
        </Button>
      </div>
    </Modal>
  );
}
