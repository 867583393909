import e, { OPTIONS as T } from "../../managers/braze-instance.js";
import {
  destroyFeedHtml as z,
  detectFeedImpressions as q,
  feedToHtml as I,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as L,
  refreshFeed as M,
  registerFeedSubscriptionId as $,
  updateFeedCards as k
} from "../../common/feed-display.js";
import { Feed, logFeedDisplayed, subscribeToFeedUpdates } from "../index.js";
import ie from "../feed-provider-factory.js";
import { intersection as te } from "../../util/code-utils.js";
import { setCardHeight as A } from "../../Card/display/card-display.js";
import { setupFeedUI as B } from "../../ui/js/index.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export function showFeed(t, n, o) {
  if (!e.rr()) return;
  B();
  const s = (e, t) => {
      if (null == t) return e;
      const n = [];
      for (let e = 0; e < t.length; e++) n.push(t[e].toLowerCase());
      const o = [];
      for (let t = 0; t < e.length; t++) {
        const r = [];
        for (let n = 0; n < e[t].categories.length; n++)
          r.push(e[t].categories[n].toLowerCase());
        te(r, n).length > 0 && o.push(e[t]);
      }
      return o;
    },
    i = e.nn(T.tn) || e.nn(T.en) || !1;
  let l = !1;
  null == t && ((t = document.body), (l = !0));
  let a = !1,
    f = null;
  null == n
    ? ((f = ie.er().ai()),
      k(f, s(f.cards, o), f.lastUpdated, null, i),
      (a = !0))
    : (f = new Feed(s(n, o), new Date()));
  const u = I(f, i);
  if (a) {
    (null == f.lastUpdated ||
      new Date().valueOf() - f.lastUpdated.valueOf() > Feed.ur) &&
      (r.D.info(
        `Cached feed was older than max TTL of ${Feed.ur} ms, requesting an update from the server.`
      ),
      M(f, u));
    const e = new Date().valueOf(),
      t = subscribeToFeedUpdates(function(t) {
        const n = u.querySelectorAll(".ab-refresh-button")[0];
        if (null != n) {
          let t = 500;
          const o = parseInt(u.getAttribute(L));
          isNaN(o)
            ? (t -= new Date().valueOf() - e)
            : (t -= new Date().valueOf() - o),
            setTimeout(function() {
              n.className = n.className.replace(/fa-spin/g, "");
            }, Math.max(t, 0));
        }
        k(f, s(t.cards, o), t.lastUpdated, u, i);
      });
    $(t, u);
  }
  const m = e => {
    const t = e.querySelectorAll(".ab-feed");
    let n = null;
    for (let o = 0; o < t.length; o++) t[o].parentNode === e && (n = t[o]);
    null != n ? (z(n), n.parentNode.replaceChild(u, n)) : e.appendChild(u),
      setTimeout(function() {
        u.className = u.className.replace("ab-hide", "ab-show");
      }, 0),
      l && u.focus(),
      logFeedDisplayed(),
      q(f, u),
      A(f.cards, e);
  };
  var c;
  null != t
    ? m(t)
    : (window.onload =
        ((c = window.onload),
        function() {
          "function" == typeof c && c(), m(document.body);
        }));
}
