import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDrives } from "src/components/context/TeamDrivesContext";
import { DEFAULT_DATE_RANGE } from "src/components/context/TeamDrivesSummaryContext";

import DropdownFilter from "src/components/elements/DropdownFilter";
import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";

import { CustomDateRangePickerLabel } from "src/components/blocks/dates/CustomDateRangePickerLabel";
import DateRangePicker, {
  PICKER_TYPE,
} from "src/components/blocks/dates/DateRangePicker";
import FilterDrivers from "src/components/blocks/reports/teams/FilterDrivers";
import DownloadTeamDriverReport from "src/components/blocks/teams-drives/DownloadTeamDriverReport";
import TeamDriveSummary from "src/components/blocks/teams-drives/TeamDriveSummary";
import TeamDrivesList from "src/components/blocks/teams-drives/TeamDrivesList";
import TeamDrivesPurposeFilter from "src/components/blocks/teams-drives/filters/TeamDrivesPurposeFilter";

import PageLayout from "src/components/PageLayout";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { DRIVE_REVIEW_STATUS, DRIVE_TYPES } from "src/services/teams";
import {
  TeamDrivesDriversFilters,
  WebPages,
  trackTeamsDrivesListFiltered,
} from "src/services/tracking";

export default TeamDrives;

function TeamDrives() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    teamDrivesSummary,
    isTeamDrivesLoading: drivesLoading,
    loadingItemsCount,
    hasTeamDrivesLoaded,
    selectedRangeDatesFilter,
    driverName,
    driverId,
    selectedDrive,
    pastSelectedDrive,
    setPastSelectedDriveId,
    isGeneratingPDF,
    isGeneratingCSV,
    exportDrives,
    fetchTeamDrivesList,
    page,
    sortBy,
    order,
    driveTypeFilter,
    driveStatusFilter,
    teamMembers,
    filteredDrivers,
    rangeDates,
    purposeFilter,
  } = useTeamDrives();

  const isTeamDrivesLoading = drivesLoading || loadingItemsCount > 0;

  useEffect(() => {
    fetchTeamDrivesList();
  }, [
    page,
    sortBy,
    order,
    selectedRangeDatesFilter,
    driveTypeFilter,
    driveStatusFilter,
    filteredDrivers,
    purposeFilter,
  ]);

  const handleDateRangeFilterSelect = (
    dates,
    datePickerType,
    dateRangeType
  ) => {
    queryParams.set(
      "rangeDates",
      dates.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);

    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      dateFilter:
        datePickerType === PICKER_TYPE.RANGE ? dateRangeType : datePickerType,
      webPage: WebPages.DRIVES_LIST_TEAM_DASHBOARD,
    });

    history.replace({ search: queryParams.toString() });
  };

  const handleClearDateRangeFilter = () => {
    queryParams.set(
      "rangeDates",
      DEFAULT_DATE_RANGE.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handleCloseDriveSummary = () => {
    setPastSelectedDriveId(null);
    queryParams.delete("selectedDriveId");
    history.replace({ search: queryParams.toString() });
  };

  async function handleExportDrives({ type }) {
    await exportDrives({ type });
  }

  const handleDriveTypeFilterSelect = (value) => {
    if (value) {
      queryParams.set("driveType", value);
    } else {
      queryParams.delete("driveType");
    }
    queryParams.set("page", 1);
    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driveTypeFilter: value,
      webPage: WebPages.DRIVES_LIST_TEAM_DASHBOARD,
    });

    history.replace({ search: queryParams.toString() });
  };

  const handleDriveStatusFilterSelect = (value) => {
    if (value && value >= 0) {
      queryParams.set("driveStatus", value);
    } else {
      queryParams.delete("driveStatus");
    }
    queryParams.set("page", 1);

    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      statusFilter: value,
      webPage: WebPages.DRIVES_LIST_TEAM_DASHBOARD,
    });
    history.replace({ search: queryParams.toString() });
  };

  const handleDriverFilterSelect = (filteredDrivers) => {
    if (filteredDrivers.length) {
      queryParams.set(
        "drivers",
        filteredDrivers.map((driver) => driver.id)
      );
    } else {
      queryParams.delete("drivers");
    }

    queryParams.set("page", 1);

    let trackingDriversFilter = TeamDrivesDriversFilters.NONE;
    if (filteredDrivers.length === 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.INDIVIDUAL;
    } else if (filteredDrivers.length > 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.MULTIPLE;
    }
    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driveStatusFilter: value,
      webPage: WebPages.DRIVES_LIST_TEAM_DASHBOARD,
      teamDrivesDriversFilter: trackingDriversFilter,
    });

    history.replace({ search: queryParams.toString() });
  };

  const handlePurposeFilterSelect = (value) => {
    if (value) {
      queryParams.set("purpose", value);
    } else {
      queryParams.delete("purpose");
    }
    queryParams.set("page", 1);
    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      teamDrivesPurposeFilter: value,
      webPage: WebPages.DRIVES_LIST_TEAM_DASHBOARD,
    });
    history.replace({ search: queryParams.toString() });
  };

  const isAllDrivers = driverId === "all-drivers";

  const title = isAllDrivers ? "Entire team" : driverName;
  const handleOpenFilters = () => {
    const dunningStatus = checkAndHandleDunning();

    return dunningStatus === DUNNING_STATUS.EXPIRED;
  };

  const drive = selectedDrive || pastSelectedDrive;

  const totalDrives = teamDrivesSummary?.drives?.value;
  const totalApprovedDrives = teamDrivesSummary?.drives?.approved_value;
  const totalDistance = teamDrivesSummary?.distance?.value;
  const totalApprovedDistance = teamDrivesSummary?.distance?.approved_value;

  const park = parseFloat(teamDrivesSummary?.value?.parking_fees ?? 0);
  const tolls = parseFloat(teamDrivesSummary?.value?.toll_fees ?? 0);
  const value = parseFloat(teamDrivesSummary?.value?.value ?? 0);
  const totalValue = park + tolls + value;

  const approvedPark = parseFloat(
    teamDrivesSummary?.value?.approved_parking_fees ?? 0
  );
  const approvedTolls = parseFloat(
    teamDrivesSummary?.value?.approved_toll_fees ?? 0
  );
  const approvedValue = parseFloat(
    teamDrivesSummary?.value?.approved_value ?? 0
  );
  const totalApprovedValue = approvedPark + approvedTolls + approvedValue;

  const isTotalDrivesZero = totalDrives === 0;
  const isTotalDistanceZero = totalDistance === 0;
  const isTotalValueZero = totalValue === 0;
  const isTotalApprovedDistanceZero = totalApprovedDistance === 0;
  const isTotalApprovedValueZero = totalApprovedValue === 0;

  const formattedTotalDistance = isTotalDistanceZero
    ? "0"
    : totalDistance?.toFixed(1);
  const formattedTotalValue = isTotalValueZero ? "0" : totalValue?.toFixed(2);

  const formattedTotalApprovedDistance = isTotalApprovedDistanceZero
    ? "0"
    : totalApprovedDistance?.toFixed(1);
  const formattedTotalApprovedValue = isTotalApprovedValueZero
    ? "0"
    : totalApprovedValue?.toFixed(2);

  const link = rangeDates
    ? `/teams/drives?rangeDates=${rangeDates}`
    : "/teams/drives";

  return (
    <PageLayout className="page-drives">
      <PageLayout.Main>
        <div className="h-screen flex flex-col">
          <div className="p-5 page-header">
            <div className="flex items-start justify-between gap-2.5">
              <div className="flex items-center">
                <MobMenu />
                <div className="flex flex-wrap items-center gap-2.5">
                  <Link
                    to={link}
                    className="font-semibold text-28 text-black/60 hover:text-black cursor-pointer leading-[130%]"
                  >
                    Drives and reports
                  </Link>
                  <Icon name="chevron-right-small" color="black-op50" />
                  <h3 className="text-28">{title || "-"}</h3>
                </div>
              </div>
              <div
                className="h-full min-w-fit"
                data-chmln="download-driver-reports-button"
              >
                <DownloadTeamDriverReport
                  handleExportDrives={handleExportDrives}
                  isGeneratingCSV={isGeneratingCSV}
                  isGeneratingPDF={isGeneratingPDF}
                  drivers={teamDrivesSummary?.numberOfDrivers}
                />
              </div>
            </div>
            <div className="flex items-center mt-4 gap-3 flex-wrap">
              <DateRangePicker
                onOpen={handleOpenFilters}
                customLabel={CustomDateRangePickerLabel}
                onSelect={handleDateRangeFilterSelect}
                onClear={handleClearDateRangeFilter}
                defaultValue={DEFAULT_DATE_RANGE}
                initialValue={selectedRangeDatesFilter?.values}
                defaultColor="white"
                triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
                openTriggerClassName="border-blue hover:border-blue active:border-blue"
              />
              {isAllDrivers && (
                <FilterDrivers
                  selected={filteredDrivers}
                  drivers={teamMembers}
                  onSelect={handleDriverFilterSelect}
                  customLabel="All drivers"
                />
              )}
              <DropdownFilter
                icon="file-question-alt"
                label="All status"
                value={driveStatusFilter}
                onSelect={handleDriveStatusFilterSelect}
                onOpen={handleOpenFilters}
                dropdownOptions={{
                  contentWidth: 160,
                }}
                options={[
                  {
                    label: "All status",
                    value: DRIVE_REVIEW_STATUS.ANY.toString(),
                    default: true,
                  },
                  {
                    label: "Approved",
                    value: DRIVE_REVIEW_STATUS.ACCEPTED.toString(),
                  },
                  {
                    label: "Auto-approved",
                    value:
                      DRIVE_REVIEW_STATUS.AUTOMATICALLY_ACCEPTED.toString(),
                  },
                  {
                    label: "Rejected",
                    value: DRIVE_REVIEW_STATUS.REJECTED.toString(),
                  },
                  {
                    label: "No status",
                    value: DRIVE_REVIEW_STATUS.PENDING.toString(),
                  },
                ]}
              />
              <DropdownFilter
                icon="m-dotted-circle"
                label="Auto + manually added"
                value={driveTypeFilter}
                onSelect={handleDriveTypeFilterSelect}
                onOpen={handleOpenFilters}
                options={[
                  {
                    label: "Auto + manually added",
                    value: DRIVE_TYPES.ANY,
                    default: true,
                  },
                  {
                    label: "Manually added",
                    value: DRIVE_TYPES.MANUALLY_ADDED,
                  },
                  {
                    label: "Automatically detected",
                    value: DRIVE_TYPES.AUTOMATICALLY_DETECTED,
                  },
                ]}
              />
              <TeamDrivesPurposeFilter
                purposeFilter={purposeFilter}
                handleOpenFilters={handleOpenFilters}
                handlePurposeFilterSelect={handlePurposeFilterSelect}
              />
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="m-5 rounded-[14px] border border-border-1 shadow-sm w-fit min-w-[-webkit-fill-available]">
              <div className="p-[15px] border-b-border-1 border-b">
                <div className="m-[15px] flex gap-10">
                  <Stats
                    leftNumber={totalApprovedDrives}
                    rightNumber={totalDrives}
                    leftLabel="Drives approved"
                    rightLabel="submitted"
                    isEmpty={isTotalDrivesZero}
                    isLoading={isTeamDrivesLoading}
                    isReady={hasTeamDrivesLoaded && !isTeamDrivesLoading}
                  />
                  <Stats
                    leftNumber={formattedTotalApprovedDistance}
                    rightNumber={`${formattedTotalDistance} ${
                      teamDrivesSummary?.distance?.unit || ""
                    }`}
                    leftLabel="Distance approved"
                    rightLabel="submitted"
                    isEmpty={isTotalDistanceZero}
                    isLoading={isTeamDrivesLoading}
                    isReady={hasTeamDrivesLoaded && !isTeamDrivesLoading}
                  />
                  <Stats
                    leftNumber={`${
                      teamDrivesSummary?.value?.unit || ""
                    }${formattedTotalApprovedValue}`}
                    rightNumber={`${
                      teamDrivesSummary?.value?.unit || ""
                    }${formattedTotalValue}`}
                    leftLabel="Value approved"
                    rightLabel="submitted"
                    isEmpty={isTotalValueZero}
                    isLoading={isTeamDrivesLoading}
                    isReady={hasTeamDrivesLoaded && !isTeamDrivesLoading}
                  />
                </div>
              </div>
              <TeamDrivesList />
            </div>
          </div>
        </div>
      </PageLayout.Main>
      <PageLayout.Sidebar
        className={`transition-all duration-200 ease-in-out ${
          selectedDrive ? "" : "w-0"
        }`}
      >
        <div
          className={`right-panel w-[340px] laptop:w-[300px] ${
            selectedDrive ? "open" : "closed"
          }`}
        >
          <TeamDriveSummary
            showSubmittedAt
            drive={drive}
            onClose={handleCloseDriveSummary}
            currency={drive?.currency_unit}
            distanceUnits={drive?.distance_unit}
            showComments
          />
        </div>
      </PageLayout.Sidebar>
    </PageLayout>
  );
}

function Stats({
  leftNumber,
  rightNumber,
  leftLabel,
  rightLabel,
  isEmpty,
  isLoading,
  isReady,
}) {
  return (
    <div className="flex flex-col">
      {isLoading && (
        <div className="w-[100px] max-w-full h-[24px] mb-3 rounded-4 bg-black/10 animate-pulse" />
      )}
      {isReady && (
        <p className="text-24 font-semibold">
          <span
            className={isEmpty ? "text-[rgba(23,23,23,0.5)]" : "text-black"}
          >
            {leftNumber}{" "}
            <span className="text-[rgba(23,23,23,0.5)]">/ {rightNumber}</span>
          </span>
        </p>
      )}
      <p
        className={`text-14 ${
          isEmpty ? "text-[rgba(23,23,23,0.5)]" : "text-[rgba(23,23,23,0.8)]"
        }`}
      >
        {leftLabel}{" "}
        <span className="text-[rgba(23,23,23,0.5)]">/ {rightLabel}</span>
      </p>
    </div>
  );
}
