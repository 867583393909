import React from "react";

import Text from "src/components/elements/Text";

import { RATES_STATUS } from "src/services/utils";

export default RatesBadge;

const colors = {
  [RATES_STATUS.UPCOMING]: "skyblue",
  [RATES_STATUS.CURRENT]: "lime",
  [RATES_STATUS.PAST]: "beige-medium",
};

const text = {
  [RATES_STATUS.UPCOMING]: "Upcoming",
  [RATES_STATUS.CURRENT]: "Current",
  [RATES_STATUS.PAST]: "Past",
};

function RatesBadge({ status }) {
  return (
    <Text className={`miq-badge miq-badge-${colors[status]}`}>
      {text[status]}
    </Text>
  );
}
