const Oe = {
  init: function(n) {
    (void 0 === n && void 0 !== Oe.zg) || (Oe.zg = !!n), Oe.Eg || (Oe.Eg = !0);
  },
  destroy: function() {
    (Oe.Eg = !1), (Oe.zg = void 0), (Oe.nd = void 0);
  },
  setLogger: function(n) {
    "function" == typeof n
      ? (Oe.init(), (Oe.nd = n))
      : Oe.info("Ignoring setLogger call since logger is not a function");
  },
  toggleLogging: function() {
    Oe.init(),
      Oe.zg
        ? (console.log("Disabling Braze logging"), (Oe.zg = !1))
        : (console.log("Enabled Braze logging"), (Oe.zg = !0));
  },
  info: function(n) {
    if (Oe.zg) {
      const o = "Braze: " + n;
      null != Oe.nd ? Oe.nd(o) : console.log(o);
    }
  },
  warn: function(n) {
    if (Oe.zg) {
      const o = "Braze SDK Warning: " + n + " (v4.5.0)";
      null != Oe.nd ? Oe.nd(o) : console.warn(o);
    }
  },
  error: function(n) {
    if (Oe.zg) {
      const o = "Braze SDK Error: " + n + " (v4.5.0)";
      null != Oe.nd ? Oe.nd(o) : console.error(o);
    }
  }
};
export default Oe;
