import React, { useEffect, useRef, useState } from "react";

import { formatPercentage } from "src/services/utils";

export default CircleMetric;

function CircleMetric({
  percentage = 0,
  radius = 40,
  strokeWidth = 8,
  title = "",
  animationTimeInMs = 500,
}) {
  const circleRef = useRef(null);
  const [displayedPercentage, setDisplayedPercentage] = useState(0);

  const diameter = radius * 2;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const interval = percentage > 5 ? animationTimeInMs / percentage : 0;

  useEffect(() => {
    if (!circleRef.current) return;

    circleRef.current.style.strokeDashoffset =
      circumference - (displayedPercentage / 100) * circumference;

    if (displayedPercentage < percentage) {
      const timeout = setTimeout(() => {
        setDisplayedPercentage((prev) => Math.min(prev + 1, percentage));
      }, interval);

      return () => clearTimeout(timeout);
    }
  }, [displayedPercentage, percentage, circumference]);

  return (
    <div className="justify-self-center">
      <div
        className="relative flex flex-col items-center justify-center text-center"
        style={{ minWidth: diameter, minHeight: diameter }}
      >
        <div className="absolute pointer-events-none">
          <svg height={diameter} width={diameter}>
            <circle
              className="stroke-beige-medium/50"
              fill="transparent"
              strokeWidth={strokeWidth}
              r={normalizedRadius}
              cx={radius}
              cy={radius}
            />
            <circle
              ref={circleRef}
              className="stroke-[#B3E1F7]"
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={circumference + " " + circumference}
              style={{ strokeDashoffset: circumference }}
              r={normalizedRadius}
              cx={radius}
              cy={radius}
              transform={`rotate(-90 ${radius} ${radius})`}
            />
          </svg>
        </div>
        <p className="text-[32px] font-medium tabular-nums text-black/80 leading-[125%]">
          {formatPercentage({ percent: displayedPercentage })}
        </p>
        {title && (
          <p className="max-w-[120px] text-12 text-black/60 leading-[120%] mt-1">
            {title}
          </p>
        )}
      </div>
    </div>
  );
}
