import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Text from "src/components/elements/Text";

import { BlogSource, trackBlogSelected } from "src/services/tracking";

import { SET_INTEGRATION_IDENTITY } from "src/graphql/mutations";

export default SetConcurIdentityForm;

function SetConcurIdentityForm({ onSuccess }) {
  const [setIntegrationIdentity, { loading }] = useMutation(
    SET_INTEGRATION_IDENTITY,
    { notifyOnNetworkStatusChange: true }
  );

  const [employeeId, setEmployeeId] = useState("");
  const [identityError, setIdentityError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errorMessage = "This is not a match. Please check your Employee ID";

    try {
      const { data } = await setIntegrationIdentity({
        variables: {
          integrationType: "concur",
          identity: employeeId,
        },
      });

      if (data.setIntegrationIdentity?.code === 200) {
        onSuccess();
      }

      return setIdentityError(errorMessage);
    } catch (error) {
      console.log(error);
      setIdentityError(errorMessage);
    }
  };

  const handleChange = (e) => {
    setEmployeeId(e.target.value);
    setIdentityError(null);
  };
  return (
    <div>
      <h4 className="text-center px-[30px]">
        Please enter your Concur Employee ID
      </h4>
      <Text paragraph center className="mt-[20px] laptop:mt-[15px]">
        Your Concur Employee ID confirms your account. You can find it by
        logging in to Concur, navigating to Profile, and selecting Personal
        Information. Check out{" "}
        <a
          className="underline"
          href="https://support.mileiq.com/hc/en-us/articles/11072100490772-Concur-Integration-How-do-I-find-my-Employee-ID"
          target="_blank"
          onClick={() => {
            trackBlogSelected({
              src: BlogSource.ENABLE_CONCUR_INTEGRATION,
            });
          }}
        >
          this guide
        </a>{" "}
        for more help.
      </Text>
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col mt-[20px] laptop:mt-[15px] gap-[20px]"
      >
        <div>
          <Input
            label="Employee ID"
            name="employeeId"
            placeholder="Employee ID"
            value={employeeId}
            valid={!identityError}
            onChange={handleChange}
          />
          {identityError && (
            <Text
              md
              color="red"
              regular
              paragraph
              className="w-full text-left mt-2"
            >
              {identityError}
            </Text>
          )}
        </div>
        <Button
          lg
          className="flex-grow"
          loading={loading}
          type="submit"
          disabled={!employeeId}
        >
          {loading ? "Loading" : "Next"}
        </Button>
      </form>
    </div>
  );
}
