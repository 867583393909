import { useContext, useRef } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import { NETWORK_STATES } from "src/services/http";
import {
  CancellationAbandonedMethods,
  trackTeamsSubscriptionCancellationAbandoned,
} from "src/services/tracking";

import { CancellationReason } from "../blocks/teams-cancellation/CancellationReason";
import { ClaimDiscount } from "../blocks/teams-cancellation/ClaimDiscount";
import { DowngradeSubscription } from "../blocks/teams-cancellation/DowngradeSubscription";
import { TeamAllTimeStats } from "../blocks/teams-cancellation/TeamAllTimeStats";
import {
  CANCELLATION_STEPS,
  useTeamCancellation,
} from "../context/TeamCancellationContext";
import TeamContext from "../context/TeamContext";

export const ELEMENT_ID = "CANCEL_TEAMS_SUBSCRIPTION";
registerElement(ELEMENT_ID, CancelTeamsSubscription);

function CancelTeamsSubscription({ onClose }) {
  const {
    cancelSubscriptionNetworkState,
    teamAllTimeStatsNetworkState,
    downgradeSubscriptionNetworkState,
    claimDiscountNetworkState,
    checkDiscountEligibilityNetworkState,
    step,
    isEligibleToClaimDiscount,
  } = useTeamCancellation();

  const modalRef = useRef();

  const { team } = useContext(TeamContext);

  const isCancelSubscriptionLoading =
    cancelSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const isDowngradeSubscriptionLoading =
    downgradeSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const isClaimDiscountLoading =
    claimDiscountNetworkState === NETWORK_STATES.LOADING;

  const isTeamAllTimeStatsLoading = [
    NETWORK_STATES.IDLE,
    NETWORK_STATES.LOADING,
  ].includes(teamAllTimeStatsNetworkState);

  const isCheckDiscountEligibilityLoading = [
    NETWORK_STATES.IDLE,
    NETWORK_STATES.LOADING,
  ].includes(checkDiscountEligibilityNetworkState);

  const handleDismissModal = () => {
    trackTeamsSubscriptionCancellationAbandoned({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      teamsPlanId: team.subscription?.plan,
      subType: team.subscription?.type,
      teamId: team.id,
      freeTrial: team.subscription?.isFreeTrialActive,
      teamsDriverCount: team.subscription?.numberOfSeats || 0,
      eligibleToDiscounts: isEligibleToClaimDiscount,
      cancellationStep: step,
      method: CancellationAbandonedMethods.MODAL_DISMISS,
    });

    onClose();
  };

  return (
    <Modal
      closable={
        !isCancelSubscriptionLoading &&
        !isTeamAllTimeStatsLoading &&
        !isDowngradeSubscriptionLoading &&
        !isClaimDiscountLoading &&
        !isCheckDiscountEligibilityLoading
      }
      show
      onClose={handleDismissModal}
      className={step}
      additionalContentSize={
        step === CANCELLATION_STEPS.CANCELLATION_REASON ? 190 : 0
      }
      modalRef={modalRef}
      contentClassName="p-[30px]"
      passiveBackdrop
    >
      {step === CANCELLATION_STEPS.CANCELLATION_REASON && (
        <CancellationReason modalRef={modalRef} closeModal={onClose} />
      )}

      {step === CANCELLATION_STEPS.TEAM_ALL_TIME_STATS && (
        <TeamAllTimeStats closeModal={onClose} />
      )}

      {step === CANCELLATION_STEPS.DOWNGRADE_SUBSCRIPTION && (
        <DowngradeSubscription closeModal={onClose} />
      )}

      {step === CANCELLATION_STEPS.CLAIM_DISCOUNT && (
        <ClaimDiscount closeModal={onClose} />
      )}
    </Modal>
  );
}
