import React, { useContext } from "react";

import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as ADD_VEHICLE_MODAL } from "src/components/modals/AddVehicle";

import useFlash from "src/hooks/useFlash";

import {
  Pages,
  trackVehicleAddCompleted,
  trackVehicleAddStarted,
} from "src/services/tracking";

import VehiclesList from "./VehiclesList";

export default VehicleAndOdometerPage;

function VehicleAndOdometerPage() {
  const { userData } = useContext(UserDataContext);
  const [flash, Flash] = useFlash();
  const vehicleModal = useElement(ADD_VEHICLE_MODAL, {
    props: {
      onClose: () => {
        vehicleModal.deactivate();
      },
      onSubmit: () => {
        vehicleModal.deactivate();
        flash(<Text>Vehicle added</Text>, {
          type: FlashTypes.SAVED,
        });
      },
      onStartAddDetails: ({ type }) => {
        trackVehicleAddStarted({
          page: Pages.VEHICLES,
          type: type,
        });
      },
      onAddDetailsCompleted: ({ type }) => {
        trackVehicleAddCompleted({
          page: Pages.VEHICLES,
          type: type,
        });
      },
    },
  });

  const handleAddVehicleClicked = () => {
    vehicleModal.activate();
  };

  const { vehicles } = userData;
  const hasVehicles = vehicles.lenght > 0;
  const activeVehs = vehicles.filter((v) => v.isActive);
  const hiddenVehs = vehicles.filter((v) => !v.isActive);

  return (
    <div className="max-w-[700px] flex flex-col gap-[20px] p-[20px] laptop:p-[15px] relative">
      <div className="">
        <h6 className="mb-[5px]">
          {hasVehicles ? "Vehicles" : "Add vehicles"}
        </h6>
        <Text paragraph lg>
          Keep track of your vehicles to complete your mileage report. Enter
          your vehicle information and odometer readings.
        </Text>
      </div>
      {activeVehs.length > 0 ? (
        <VehiclesList vehicles={activeVehs} testId="vehicles-list-active" />
      ) : null}
      <div>
        <Button onClick={handleAddVehicleClicked}>Add new vehicle</Button>
      </div>
      {hiddenVehs.length > 0 ? (
        <>
          <div className="border-t border-border-1 pt-[20px]">
            <h6 className="mb-[5px]">Hidden vehicles</h6>
            <Text paragraph lg>
              Here are the vehicles you've previously hidden. You can restore
              any vehicle by clicking Restore vehicle.
            </Text>
          </div>
          {hiddenVehs.length > 0 ? (
            <VehiclesList vehicles={hiddenVehs} testId="vehicles-list-hidden" />
          ) : null}
        </>
      ) : null}
      {Flash}
    </div>
  );
}
