import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import MiqButton from "src/components/elements/Button";
import Dropdown, {
  MiqDropdownItem as DropdownItem,
} from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as CANCEL_SUBSCRIPTION_MODAL } from "src/components/modals/CancelDriverSubscription";

import {
  PAYMENT_METHOD,
  PAYMENT_METHOD_DATA,
  SUBSCRIPTION_STATUS,
} from "src/services/billing";
import { setPendingUserSubscription } from "src/services/storage";
import {
  Pages,
  UpgradeSource,
  trackSubscriptionUpdateStarted,
  trackUpgradeStarted,
} from "src/services/tracking";

import {
  CREATE_DRIVER_CHECKOUT_SESSION,
  CREATE_STRIPE_CUSTOMER_PORTAL_SESSION,
} from "src/graphql/queries";

export default ManageSubscriptionControls;
function ManageSubscriptionControls() {
  const { userData } = useContext(UserDataContext);
  const cancelSubscriptionModal = useElement(CANCEL_SUBSCRIPTION_MODAL, {
    props: {
      onClose: () => {
        cancelSubscriptionModal.deactivate();
      },
    },
  });
  const [portalSessionQueryFn, portalSessionQueryState] = useLazyQuery(
    CREATE_STRIPE_CUSTOMER_PORTAL_SESSION,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const [checkoutQueryFn, checkoutQueryState] = useLazyQuery(
    CREATE_DRIVER_CHECKOUT_SESSION,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const { isTeamsUser } = userData;

  const {
    paymentService,
    status,
    type: subscriptionType,
  } = userData.subscriptionData;

  const isActive = status === SUBSCRIPTION_STATUS.ACTIVE;
  const isCancelled = status === SUBSCRIPTION_STATUS.CANCELLED;
  const isExpired = status === SUBSCRIPTION_STATUS.EXPIRED;

  function startUpgrade({ isNew = false, url }) {
    if (isNew) {
      trackUpgradeStarted({
        page: Pages.SUBSCRIPTION,
        subType: subscriptionType,
        src: UpgradeSource.SUBSCRIPTION_PAGE,
      });
    } else {
      trackSubscriptionUpdateStarted({
        subType: subscriptionType,
        service: paymentService,
      });
    }
    setPendingUserSubscription({
      isNew,
    });
    if (url) window.location.href = url;
  }

  function purchaseNewSubscription() {
    checkoutQueryFn({
      variables: {
        subscriptionType,
      },
      onCompleted: (data) => {
        const url = data?.createDriverCheckoutSession?.url;
        startUpgrade({ isNew: true, url });
      },
    });
  }

  function handleManageSubscription() {
    if (portalSessionQueryState.loading || checkoutQueryState.loading) return;

    if (isExpired) {
      return purchaseNewSubscription();
    }

    switch (paymentService) {
      case PAYMENT_METHOD.APPLE:
      case PAYMENT_METHOD.APPLE_SANDBOX:
      case PAYMENT_METHOD.GOOGLE:
        if (PAYMENT_METHOD_DATA[paymentService].editPaymentInfoUrl) {
          startUpgrade({
            url: PAYMENT_METHOD_DATA[paymentService].editPaymentInfoUrl,
          });
        }
        break;
      case PAYMENT_METHOD.STRIPE:
        portalSessionQueryFn({
          onCompleted: (data) => {
            const url = data?.createStripeCustomerPortalSession?.url;
            startUpgrade({ url, isNew: isCancelled });
          },
        });
        break;
      default:
        break;
    }
  }

  function startCancelationFlow() {
    cancelSubscriptionModal.activate({ props: { paymentService } });
  }

  return (
    <>
      {isActive && !isTeamsUser ? (
        <ManageSubscriptionDropdown
          onCancel={startCancelationFlow}
          onEdit={handleManageSubscription}
          loading={portalSessionQueryState.loading}
          canCancel={isActive}
        />
      ) : (
        <MiqButton
          onClick={handleManageSubscription}
          disabled={portalSessionQueryState.loading}
          loading={portalSessionQueryState.loading}
          destructive={isTeamsUser && isActive}
        >
          {!isActive
            ? "Renew subscription"
            : isTeamsUser && isActive
            ? "Cancel subscription"
            : "Manage subscription"}
        </MiqButton>
      )}
    </>
  );
}

function ManageSubscriptionDropdown({ onEdit, onCancel, canCancel, loading }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loading) close();
  }, [loading]);

  function close() {
    setOpen(false);
  }

  const label = "Manage subscription";
  if (loading) {
    return (
      <MiqButton loading={loading} disabled={loading}>
        {label}
      </MiqButton>
    );
  }

  return (
    <Dropdown
      triggerClassName="bg-blue border-2 border-transparent active:border-[#1179FF] active:bg-blue-hover hover:bg-blue-hover justify-between px-[15px]"
      openTriggerClassName="bg-blue-hover border-[#1179FF]"
      contentClassName="border border-border-1 right-0 xl:right-auto"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      contentWidth={218}
      processElements={({ triggerEl, containerEl }) => {
        if (!triggerEl || !containerEl) return;

        const containerRect = containerEl.getBoundingClientRect();
        const triggerRect = triggerEl.getBoundingClientRect();

        containerEl.style.left = triggerRect.width - containerRect.width + "px";
        containerEl.style.top = triggerRect.height + 4 + "px";
      }}
      renderTrigger={() => (
        <div className="flex items-center gap-[7px]">
          <Text lg semibold className="hidden md:block" color="white">
            {label}
          </Text>
          <Text lg semibold className="block md:hidden" color="white">
            Manage
          </Text>
          <div className="flex items-center justify-center h-5 w-5">
            <Icon name="caret" color="white" />
          </div>
        </div>
      )}
    >
      <DropdownItem
        icon="edit"
        onClick={() => {
          close();
          onEdit?.();
        }}
        textProps={{
          custom: true,
          className: "text-[15px] font-medium leading-none",
        }}
      >
        Edit subscription
      </DropdownItem>
      <DropdownItem
        disabled={!canCancel}
        icon="cancel"
        color="red"
        onClick={() => {
          close();
          onCancel?.();
        }}
        textProps={{
          custom: true,
          className: "text-[15px] font-medium leading-none",
        }}
      >
        Cancel subscription
      </DropdownItem>
    </Dropdown>
  );
}
