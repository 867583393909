import React, { useState } from "react";

import { getTime24 } from "src/services/utils";
import {
  DAYS,
  convertToTimePickerSlots,
  getEpochStartUTCDate,
} from "src/services/work-hours";

import { ComplexTimeSlotsByDay } from "./ComplexTimeSlotsByDay";

export const ComplexSchedule = ({ workHours, onChange, hasError }) => {
  const [workHoursConverted, setWorkHoursConverted] = useState(
    convertToTimePickerSlots(workHours)
  );

  const handleChange = (value, startOrEnd, slotIndex, dayName) => {
    const correctedHours = getTime24(value);
    const newHour = Object.assign({});

    if (startOrEnd === "start") {
      newHour.start = getEpochStartUTCDate(correctedHours, value.minutes);
    } else {
      newHour.end = getEpochStartUTCDate(correctedHours, value.minutes);
    }

    DAYS.map((day, index) => {
      if (workHoursConverted[day].length) {
        if (day == dayName) {
          workHoursConverted[day][slotIndex] = {
            start: new Date(
              workHoursConverted[day][slotIndex].start.setDate(index + 1)
            ),
            end: new Date(
              workHoursConverted[day][slotIndex].end.setDate(index + 1)
            ),
            [startOrEnd]: new Date(newHour[startOrEnd].setDate(index + 1)),
          };
        } else {
          workHoursConverted[day].every((item) => {
            return {
              start: new Date(item.start.setDate(index + 1)),
              end: new Date(item.end.setDate(index + 1)),
            };
          });
        }
      }
    });
    setWorkHoursConverted({ ...workHoursConverted });
    onChange(workHoursConverted);
  };

  const handleChangeSlotsCount = (type, arr, slot_index, week_day) => {
    switch (type) {
      case "delete": {
        workHoursConverted[week_day].splice(slot_index, 1);
        break;
      }
      case "add": {
        let correctedStartHours = getTime24(arr.at(-1).timeStart);
        let correctedEndHours = getTime24(arr.at(-1).timeEnd);
        let newHour = Object.assign({});
        newHour.start = getEpochStartUTCDate(
          correctedStartHours,
          arr.at(-1).timeStart.minutes
        );
        newHour.end = getEpochStartUTCDate(
          correctedEndHours,
          arr.at(-1).timeEnd.minutes
        );
        workHoursConverted[week_day] = [
          ...workHoursConverted[week_day],
          newHour,
        ];
        break;
      }
    }

    DAYS.map((day, index) => {
      if (workHoursConverted[day].length) {
        workHoursConverted[day].every((item) => {
          return {
            start: new Date(item.start.setDate(index + 1)),
            end: new Date(item.end.setDate(index + 1)),
          };
        });
      }
    });
    setWorkHoursConverted({ ...workHoursConverted });
    onChange({ ...workHoursConverted });
  };

  return (
    <>
      {Object.keys(convertToTimePickerSlots(workHours)).map((key, index) => {
        return (
          <ComplexTimeSlotsByDay
            workHours={workHours}
            index={index}
            day={DAYS[index]}
            key={index}
            onChange={handleChange}
            onChangeSlotsCount={handleChangeSlotsCount}
            hasError={hasError}
          />
        );
      })}
    </>
  );
};
