import * as braze from "@braze/web-sdk";
import React, { useContext, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { CustomRatesProvider } from "src/components/context/CustomRatesContext";
import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { TeamDrivesProvider } from "src/components/context/TeamDrivesContext";
import { TeamDrivesSummaryProvider } from "src/components/context/TeamDrivesSummaryContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";
import { TeamOverviewProvider } from "src/components/context/TeamOverviewContext";
import { TeamReportsProvider } from "src/components/context/TeamReportsContext";
import { useUser } from "src/components/context/UserContext";
import { UsersProvider } from "src/components/context/UsersContext";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Page from "src/components/elements/Page";
import { BannerStrip, TopBanner } from "src/components/elements/TopBanner";

import { DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID } from "src/components/modals/DunningResubscribe";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "src/components/modals/DunningSubscriptionRecoverPayment";
import { DUNNING_TEAMS_WARNING_MODAL_ID } from "src/components/modals/DunningTeamsWarning";

import CustomRates from "src/components/pages/TeamsDashboard/CustomRates";
import { TeamsOnboarding } from "src/components/pages/TeamsDashboard/Onboarding";
import ReportDetails from "src/components/pages/TeamsDashboard/ReportDetails";
import ReportsOrUpgrade from "src/components/pages/TeamsDashboard/ReportsOrUpgrade";
import Settings from "src/components/pages/TeamsDashboard/Settings";
import Subscription from "src/components/pages/TeamsDashboard/Subscription";
import TeamDrives from "src/components/pages/TeamsDashboard/TeamDrives";
import TeamDrivesSummary from "src/components/pages/TeamsDashboard/TeamDrivesSummary";
import TeamDrivesToApprove from "src/components/pages/TeamsDashboard/TeamDrivesToApprove";
import TeamDrivesToApproveSummary from "src/components/pages/TeamsDashboard/TeamDrivesToApproveSummary";
import TeamLocations from "src/components/pages/TeamsDashboard/TeamLocations";
import TeamsOverview from "src/components/pages/TeamsDashboard/TeamsOverview";
import Users from "src/components/pages/TeamsDashboard/Users";
import WelcomePage from "src/components/pages/TeamsDashboard/WelcomePage";

import BestExperienceNotification from "src/components/BestExperienceNotification";
import { TeamsDashboardSidenav } from "src/components/Sidenav";

import { useFlags } from "src/hooks/useFlags";
import useQueryParams from "src/hooks/useQueryParams";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsLiteSubscription,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import { report } from "src/services/error-reporting";
import {
  getShouldShowWelcomePage,
  getShouldTrackSignupCompleteAfterCheckout,
  removeShouldShowWelcomePage,
  removeShouldTrackSignupCompleteAfterCheckout,
  setDunningExpiredSubscriptionModalLastSeen,
  setDunningPaymentFailedModalLastSeen,
} from "src/services/storage";
import {
  TeamsResubscribeModalSources,
  TeamsUpdatePaymentSources,
  trackTeamsSignupCompleted,
} from "src/services/tracking";

import ZendeskWidget from "src/vendors/zendesk";

import SharedReportPage from "../DriverDashboard/SharedReports";

export default TeamsDashboardPages;

function goHome() {
  window.location.href = "/";
}

function TeamsDashboardPages() {
  window["name"] = "mileiq.teams";
  const history = useHistory();
  const { path } = useRouteMatch();
  const { user } = useUser();
  const { flash } = useAppFlash();
  const searchParams = useQueryParams();
  const { team, loading } = useContext(TeamContext);
  const [showWelcomePage, setShowWelcomePage] = useState(
    getShouldShowWelcomePage()
  );
  const { daysUntilGracePeriodEnds, dayLabel, resetDunningState } =
    useTeamDunning();

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.TOP_BANNER,
      },
    }
  );

  useEffect(() => {
    if (!loading && showWelcomePage) {
      setTimeout(() => {
        removeShouldShowWelcomePage();
        setShowWelcomePage(false);
      }, 2000);
    }
  }, [showWelcomePage, loading]);

  useEffect(() => {
    if (!team && !window.location.pathname.match(/onboarding/gi)) {
      let redirect = `${path}/onboarding`;
      const params = searchParams.toString();
      redirect += params ? `?${params}` : "";
      history.replace(redirect);
    }
    if (searchParams.has("joined")) {
      flash("You've joined your team!", {
        type: FlashTypes.SAVED,
      });
      searchParams.delete("joined");
    }
  }, []);

  if (loading)
    return (
      <div className="w-screen h-screen">
        <Loader />
      </div>
    );

  if (!user) {
    goHome();
    return null;
  }

  // non-admin should not be able to access Teams Dashboard
  if (team && !team.hasAdminWithId(user.id)) {
    report(new Error("Non admin user tried to access Teams Dashboard"));

    goHome();
    return null;
  }

  if (showWelcomePage) {
    return (
      <div className="w-screen min-h-screen flex bg-white">
        <WelcomePage />;
      </div>
    );
  }

  return (
    <Switch>
      <Route path={`${path}/onboarding`}>
        <TeamsOnboarding />
      </Route>
      <Route path={`${path}`}>
        <div className="w-full h-full flex flex-col">
          <TopBanner>
            {team?.isInGracePeriod && (
              <BannerStrip backgroundVariant="warning">
                <Icon name="warning" />
                <p>
                  Your last payment failed. You have {daysUntilGracePeriodEnds}{" "}
                  {dayLabel} to update your payment information before you lose
                  access to your account.{" "}
                  <button
                    className="font-semibold text-blue"
                    onClick={() => subscriptionRecoverPayment.activate()}
                  >
                    Update payment
                  </button>
                </p>
              </BannerStrip>
            )}
            {team?.isExpired && (
              <BannerStrip backgroundVariant="danger">
                <Icon name="error" />
                <p>
                  Your subscription has expired.{" "}
                  <button
                    className="font-semibold text-blue"
                    onClick={() => subscriptionRecoverPayment.activate()}
                  >
                    Resubscribe
                  </button>
                </p>
              </BannerStrip>
            )}
          </TopBanner>
          <div
            className="w-screen max-w-full flex bg-white"
            style={{
              minHeight: "calc(100vh - 40px)",
            }}
            // style={{ minHeight: `calc(100vh - ${user?.isVerified ? 40 : 80}px)` }}
          >
            <TeamsRoutes />
          </div>
        </div>
      </Route>
    </Switch>
  );
}

function TeamsRoutes() {
  const {
    miqDashboardTeamsOverviewPageWeb: { active: isTeamsOverviewActive },
  } = useFlags();
  const { team } = useContext(TeamContext);
  const { path } = useRouteMatch();
  const {
    miqDashTeamLocationsWeb: isTeamLocationsPageEnabled,
    miqDashboardContactsLightweightDashWeb,
  } = useFlags();
  const {
    shouldShowPaymentFailedModal,
    shouldShowExpiredSubscriptionModal,
    resetDunningState,
  } = useTeamDunning();
  const history = useHistory();
  const queryParams = useQueryParams();

  const paymentWarningModal = useElement(DUNNING_TEAMS_WARNING_MODAL_ID, {
    props: {
      onClose: () => {
        paymentWarningModal.deactivate();
        const setModalLastSeen = team?.isExpired
          ? setDunningExpiredSubscriptionModalLastSeen
          : setDunningPaymentFailedModalLastSeen;

        setModalLastSeen(team.id);
      },
    },
  });

  const resubscribeModal = useElement(
    DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID,
    {
      props: {
        onClose: () => {
          resubscribeModal.deactivate();
        },
        source: TeamsResubscribeModalSources.URL_PARAMS,
      },
    }
  );

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.URL_PARAMS,
      },
    }
  );

  useEffect(() => {
    braze.logCustomEvent("teams_dash_view");

    if (queryParams.get("resubscribe")) {
      resubscribeModal.activate();

      queryParams.delete("resubscribe");
      history.replace({ search: queryParams.toString() });
      return;
    }

    if (queryParams.get("updatePayment")) {
      subscriptionRecoverPayment.activate();

      queryParams.delete("updatePayment");
      history.replace({ search: queryParams.toString() });
      return;
    }

    if (
      shouldShowPaymentFailedModal() ||
      shouldShowExpiredSubscriptionModal()
    ) {
      paymentWarningModal.activate();
    }
  }, []);

  let redirect = path;

  if (team) {
    redirect += isTeamsOverviewActive ? "/overview" : "/users";
  }

  // this is for Tinuiti TV ads pixel
  setTimeout(() => {
    if (team?.id && team?.id == getShouldTrackSignupCompleteAfterCheckout()) {
      trackTeamsSignupCompleted({
        plan: team?.subscription?.plan,
        seatsAddedCount: team?.subscription?.numberOfSeats,
        freeTrial: team?.subscription?.isFreeTrialActive,
      });
      removeShouldTrackSignupCompleteAfterCheckout();
    }

    if (!team) {
      // this is for Tinuiti TV ads pixel
      setTimeout(() => {
        // note: this is because /teams can be accessed
        // so we need to redirect to /teams/onboarding
        // cuz onboarding has no sidebars etc...
        location.href = "/teams/onboarding";
      }, 200);
      return;
    }
  }, 200);

  return (
    <TeamReportsProvider>
      <UsersProvider>
        <CustomRatesProvider>
          <TeamsDashboardSidenav />
          <ZendeskWidget />
          <BestExperienceNotification />
          <div className="page-content relative">
            <Switch>
              <Route path={`${path}/overview`}>
                <PageGuard flag="miqDashboardTeamsOverviewPageWeb">
                  <Page title="Overview">
                    <TeamOverviewProvider>
                      <TeamsOverview />
                    </TeamOverviewProvider>
                  </Page>
                </PageGuard>
              </Route>
              <Route path={`${path}/users`}>
                <Page title="Users">
                  <Users />
                </Page>
              </Route>
              <Route path={`${path}/drives/:driver_id`} exact>
                {/* TODO: Fix page guard when flag is active for specific cohorts */}
                {/* The bug consist in not having the page available in the first render,
                therefore not being able to directly access the page via URL or refreshing the page. */}
                <PageGuard
                  flag="miqTeamsDrivesAllPlatforms"
                  allowedSubscriptions={[
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS,
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                  ]}
                >
                  <Page title="Drives">
                    <TeamDrivesProvider>
                      <TeamDrives />
                    </TeamDrivesProvider>
                  </Page>
                </PageGuard>
              </Route>
              <Route path={`${path}/drives`} exact>
                <PageGuard
                  flag="miqTeamsDrivesAllPlatforms"
                  allowedSubscriptions={[
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS,
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                  ]}
                >
                  <Page title="Drives and reports">
                    <TeamDrivesSummaryProvider>
                      <TeamDrivesSummary />
                    </TeamDrivesSummaryProvider>
                  </Page>
                </PageGuard>
              </Route>
              <Route path={`${path}/drives-to-approve/:driver_id`} exact>
                <PageGuard
                  flag="miqTeamsDrivesAllPlatforms"
                  allowedSubscriptions={[
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS,
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                  ]}
                >
                  <Page title="Drives to approve">
                    <TeamDrivesProvider>
                      <TeamDrivesToApprove />
                    </TeamDrivesProvider>
                  </Page>
                </PageGuard>
              </Route>
              <Route path={`${path}/drives-to-approve`}>
                <PageGuard
                  flag="miqTeamsDrivesAllPlatforms"
                  allowedSubscriptions={[
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS,
                    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                  ]}
                >
                  <Page title="Drives to approve">
                    <TeamDrivesSummaryProvider>
                      <TeamDrivesToApproveSummary />
                    </TeamDrivesSummaryProvider>
                  </Page>
                </PageGuard>
              </Route>
              <Route path={`${path}/reports/:report_id`} exact>
                <ReportDetails />
              </Route>
              <Route path={`${path}/reports`} exact>
                <Page title="Reports">
                  <ReportsOrUpgrade />
                </Page>
              </Route>
              <Route path={`${path}/custom-rates`} exact>
                <Page title="Rates and Units">
                  <CustomRates />
                </Page>
              </Route>
              {isTeamLocationsPageEnabled && (
                <Route path={`${path}/locations`} exact>
                  <Page title="Team Locations">
                    <TeamLocations />
                  </Page>
                </Route>
              )}
              <Route path={`${path}/subscription`}>
                <Page title="Subscription Plan">
                  <Subscription />
                </Page>
              </Route>
              <Route path={`${path}/settings`}>
                <Page title="Settings">
                  <Settings />
                </Page>
              </Route>{" "}
              {miqDashboardContactsLightweightDashWeb && (
                <Route path={`${path}/shared-reports`} exact>
                  <Page title="Shared Reports">
                    <SharedReportPage />
                  </Page>
                </Route>
              )}
              <Redirect to={redirect} />
            </Switch>
          </div>
        </CustomRatesProvider>
      </UsersProvider>
    </TeamReportsProvider>
  );
}

function PageGuard({
  children,
  flag,
  allowedSubscriptions = [
    TEAMS_SUBSCRIPTION_PLANS.TEAMS,
    TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
  ],
}) {
  const { team } = useContext(TeamContext);
  const flags = useFlags();
  const currentSubscription = team?.subscription?.plan;

  function isCurrentSubscriptionAllowed() {
    if (!team || !currentSubscription) {
      return false;
    }

    if (
      allowedSubscriptions.includes(TEAMS_SUBSCRIPTION_PLANS.TEAMS) &&
      isTeamsSubscription(currentSubscription)
    ) {
      return true;
    }

    if (
      allowedSubscriptions.includes(TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE) &&
      isTeamsLiteSubscription(currentSubscription)
    ) {
      return true;
    }

    if (
      allowedSubscriptions.includes(TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO) &&
      isTeamsProSubscription(currentSubscription)
    ) {
      return true;
    }

    return false;
  }

  const isFlagActive = flags[flag]?.active || flags[flag] === true;
  const subscriptionAllowed = isCurrentSubscriptionAllowed();

  if (isFlagActive && subscriptionAllowed) {
    return children;
  }

  return <Redirect to="/teams/users" />;
}
