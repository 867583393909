import _t from "../models/identifier.js";
import { getByteLength as U } from "../util/string-utils.js";
import ei from "../models/push-token.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
export default class bt {
  constructor(t, s) {
    (this.gt = t), (this.h = s);
  }
  getUserId() {
    const t = this.h.tu(o.eu.su);
    if (null == t) return null;
    let s = t.iu,
      e = U(s);
    if (e > User.ee) {
      for (; e > User.ee; ) (s = s.slice(0, s.length - 1)), (e = U(s));
      (t.iu = s), this.h.ru(o.eu.su, t);
    }
    return s;
  }
  uu(t) {
    const s = null == this.getUserId();
    this.h.ru(o.eu.su, new _t(t)), s && this.h.ou(t);
  }
  setCustomUserAttribute(t, s) {
    if (this.gt.hu(t))
      return (
        r.D.info('Custom Attribute "' + t + '" is blocklisted, ignoring.'), !1
      );
    const e = {};
    return (e[t] = s), this.nu(User.lu, e, !0);
  }
  nu(t, s, e, i) {
    const u = this.h.mu(this.getUserId(), t, s);
    let o = "",
      h = t,
      n = s;
    return (
      e &&
        ((o = " custom"),
        "object" == typeof s && ((h = Object.keys(s)[0]), (n = s[h]))),
      !i && u && r.D.info(`Logged${o} attribute ${h} with value ${n}`),
      u
    );
  }
  Qr(t, s, e, i, u) {
    this.nu("push_token", t, !1, !0),
      this.nu("custom_push_public_key", e, !1, !0),
      this.nu("custom_push_user_auth", i, !1, !0),
      this.nu("custom_push_vapid_public_key", u, !1, !0);
    const h = r.xt.Ft,
      n = new r.qt(h, r.D),
      l = new ei(t, s, e, i, u);
    this.h.B(o.q.Pn, l.ss()), n.setItem(h.$t.cu, h.se, !0);
  }
  Zr(t) {
    if (
      (this.nu("push_token", null, !1, !0),
      this.nu("custom_push_public_key", null, !1, !0),
      this.nu("custom_push_user_auth", null, !1, !0),
      this.nu("custom_push_vapid_public_key", null, !1, !0),
      t)
    ) {
      const t = r.xt.Ft,
        s = new r.qt(t, r.D);
      this.h.B(o.q.Pn, !1), s.setItem(t.$t.cu, t.se, !1);
    }
  }
}
