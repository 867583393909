import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";

import { PURPOSE_CATEGORY } from "src/models/purpose";

export const Categories = {
  [PURPOSE_CATEGORY.BUSINESS]: "Business drives",
  [PURPOSE_CATEGORY.PERSONAL]: "Personal drives",
};

const ClassificationDropdown = ({ onSelect }) => {
  const icons = {
    [PURPOSE_CATEGORY.BUSINESS]: "suitcase",
    [PURPOSE_CATEGORY.PERSONAL]: "home",
  };
  const [category, setCategory] = useState(null);
  const [open, setOpen] = useState(false);

  const _updateCategory = (category) => {
    setCategory(category);
    onSelect(category);
    setOpen(false);
  };

  return (
    <Dropdown
      testId="classification"
      icon={category ? icons[category] : "car"}
      label={category ? Categories[category] : "All drives"}
      open={open}
      triggerClassName="bg-blue-mediumLight"
      hasValue={!!category}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      suffix={
        category && !open ? (
          <IconButton
            name="close"
            onClick={(e) => {
              e.stopPropagation();
              _updateCategory(null);
            }}
          />
        ) : null
      }
    >
      <div className="w-[222px]">
        <div className="relative flex">
          <Button
            data-testid="all"
            ghost
            onClick={() => _updateCategory(null)}
            className="w-full justify-start"
            icon="car"
          >
            All drives
          </Button>
          {category === null && (
            <div className="absolute top-0 right-0 m-[6px]">
              <Icon name="check-mark" />
            </div>
          )}
        </div>
        <div className="relative flex">
          <Button
            data-testid="business"
            onClick={() => _updateCategory(PURPOSE_CATEGORY.BUSINESS)}
            ghost
            className="w-full justify-start"
            icon="suitcase"
          >
            {Categories[PURPOSE_CATEGORY.BUSINESS]}
          </Button>
          {category === PURPOSE_CATEGORY.BUSINESS && (
            <div className="absolute top-0 right-0 m-[6px]">
              <Icon name="check-mark" />
            </div>
          )}
        </div>
        <div className="relative flex">
          <Button
            data-testid="personal"
            ghost
            onClick={() => _updateCategory(PURPOSE_CATEGORY.PERSONAL)}
            className="w-full justify-start"
            icon="home"
          >
            {Categories[PURPOSE_CATEGORY.PERSONAL]}
          </Button>
          {category === PURPOSE_CATEGORY.PERSONAL && (
            <div className="absolute top-0 right-0 m-[6px]">
              <Icon name="check-mark" />
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default ClassificationDropdown;
