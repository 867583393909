import Base from "src/models/base";

export default class RatesUK extends Base {
  constructor(data) {
    super("rates_uk_model");
    /* Expected scheme
      {
          "year": 2017,
          "parse_id": "whocares",
          "display_name": "UK (Standard)",
          "currency": "GBP",
          "units": "mi",
          "name": "country_gb",
          "effective_date": null,
          "values": {
              "business": {
                  "default": 0.45,
                  "vehicle": {
                      "automobile": 0.45,
                      "automobile_slab_2": 0.25,
                      "motorcycle": 0.24,
                      "motorcycle_slab_2": 0.24,
                      "bicycle": 0.2,
                      "bicycle_slab_2": 0.2
                  }
              },
              "personal": {
                  "default": 0
              }
          }
      }
    */

    if (data) {
      this.data = data;
    } else {
      this.data = {
        values: {
          business: {
            default: 0,
            vehicle: {
              automobile: 0,
              automobile_slab_2: 0,
              motorcycle: 0,
              motorcycle_slab_2: 0,
              bicycle: 0,
              bicycle_slab_2: 0,
            },
          },
          personal: {
            default: 0,
          },
        },
      };
    }
  }

  get id() {
    return this.__inst_id;
  }

  get parseId() {
    return this.data?.parse_id;
  }

  get effectiveDateText() {
    return this.data?.effectiveDateText;
  }

  get effectiveDate() {
    const effectiveDate = this.data?.effective_date;
    if (!effectiveDate) {
      return null;
    }
    if (typeof effectiveDate === "string") {
      return new Date(effectiveDate.replace(/z/gi, ""));
    }
    return effectiveDate;
  }

  set effectiveDate(date) {
    this.data.effective_date = date;
  }

  clone() {
    return new RatesUK(JSON.parse(JSON.stringify(this.data)));
  }

  get car() {
    return this.data?.values?.business?.vehicle?.automobile;
  }

  set car(v) {
    this.data.values.business.vehicle.automobile = parseFloat(v);
  }

  get car2() {
    return this.data?.values?.business?.vehicle?.automobile_slab_2;
  }
  set car2(v) {
    this.data.values.business.vehicle.automobile_slab_2 = parseFloat(v);
  }

  get moto() {
    return this.data?.values?.business?.vehicle?.motorcycle;
  }
  set moto(v) {
    this.data.values.business.vehicle.motorcycle = parseFloat(v);
  }

  get moto2() {
    return this.data?.values?.business?.vehicle?.motorcycle_slab_2;
  }
  set moto2(v) {
    this.data.values.business.vehicle.motorcycle_slab_2 = parseFloat(v);
  }

  get bicycle() {
    return this.data?.values?.business?.vehicle?.bicycle;
  }
  set bicycle(v) {
    this.data.values.business.vehicle.bicycle = parseFloat(v);
  }

  get bicycle2() {
    return this.data?.values?.business?.vehicle?.bicycle_slab_2;
  }
  set bicycle2(v) {
    this.data.values.business.vehicle.bicycle_slab_2 = parseFloat(v);
  }

  get status() {
    return this.data?.status;
  }

  set status(status) {
    this.data.status = status;
  }
}
