import xt from "./custom-event-data.js";
import Dt from "./custom-event-property-data.js";
import kt from "./filter-set.js";
import Qt from "./in-app-message-click-data.js";
import Wt from "./purchase-data.js";
import Xt from "./purchase-property-data.js";
import Yt from "./push-click-data.js";
import V from "./trigger-events.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export default class Kt {
  constructor(e, t) {
    (this.type = e), (this.data = t);
  }
  ec(e, t) {
    return Kt.tc[this.type] === e && (null == this.data || this.data.Hl(t));
  }
  static du(e, t) {
    let a = null;
    try {
      a = window.atob(e);
    } catch (t) {
      return (
        r.D.info("Failed to unencode analytics id " + e + ": " + t.message), !1
      );
    }
    return t === a.split("_")[0];
  }
  static fromJson(e) {
    const t = e.type;
    let r;
    switch (t) {
      case Kt.rc.OPEN:
        r = null;
        break;
      case Kt.rc.Rr:
        r = Wt.fromJson(e.data);
        break;
      case Kt.rc.ac:
        r = Xt.fromJson(e.data);
        break;
      case Kt.rc.vr:
        r = Yt.fromJson(e.data);
        break;
      case Kt.rc.be:
        r = xt.fromJson(e.data);
        break;
      case Kt.rc.sc:
        r = Dt.fromJson(e.data);
        break;
      case Kt.rc.Ar:
        r = Qt.fromJson(e.data);
        break;
      case Kt.rc.ks:
        r = null;
    }
    return new Kt(t, r);
  }
  ss() {
    return { t: this.type, d: this.data ? this.data.ss() : null };
  }
  static Sn(e) {
    let t, r;
    switch (e.t) {
      case Kt.rc.OPEN:
        t = null;
        break;
      case Kt.rc.Rr:
        t = new Wt(e.d);
        break;
      case Kt.rc.ac:
        (r = e.d || {}), (t = new Xt(r.id, kt.Sn(r.pf || [])));
        break;
      case Kt.rc.vr:
        t = new Yt(e.d);
        break;
      case Kt.rc.be:
        t = new xt(e.d);
        break;
      case Kt.rc.sc:
        (r = e.d || {}), (t = new Dt(r.e, kt.Sn(r.pf || [])));
        break;
      case Kt.rc.Ar:
        t = new Qt(e.d);
        break;
      case Kt.rc.ks:
        t = null;
    }
    return new Kt(e.t, t);
  }
}
(Kt.rc = {
  OPEN: "open",
  Rr: "purchase",
  ac: "purchase_property",
  vr: "push_click",
  be: "custom_event",
  sc: "custom_event_property",
  Ar: "iam_click",
  ks: "test"
}),
  (Kt.tc = {}),
  (Kt.tc[Kt.rc.OPEN] = V.OPEN),
  (Kt.tc[Kt.rc.Rr] = V.Rr),
  (Kt.tc[Kt.rc.ac] = V.Rr),
  (Kt.tc[Kt.rc.vr] = V.vr),
  (Kt.tc[Kt.rc.be] = V.be),
  (Kt.tc[Kt.rc.sc] = V.be),
  (Kt.tc[Kt.rc.Ar] = V.Ar),
  (Kt.tc[Kt.rc.ks] = V.ks);
