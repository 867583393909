import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDrives } from "src/components/context/TeamDrivesContext";
import { DEFAULT_DATE_RANGE } from "src/components/context/TeamDrivesSummaryContext";

import MiqButton from "src/components/elements/Button";
import DropdownFilter from "src/components/elements/DropdownFilter";
import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";

import { CustomDateRangePickerLabel } from "src/components/blocks/dates/CustomDateRangePickerLabel";
import DateRangePicker, {
  PICKER_TYPE,
} from "src/components/blocks/dates/DateRangePicker";
import TeamDriveSummary from "src/components/blocks/teams-drives/TeamDriveSummary";
import TeamDrivesToApproveList from "src/components/blocks/teams-drives/TeamDrivesToApproveList";
import TeamDrivesPurposeFilter from "src/components/blocks/teams-drives/filters/TeamDrivesPurposeFilter";

import PageLayout from "src/components/PageLayout";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
  DRIVE_TYPES,
} from "src/services/teams";
import {
  WebPages,
  trackTeamsDriveReviewCtaSelected,
  trackTeamsDrivesListFiltered,
} from "src/services/tracking";
import { DriveReviewCTA } from "src/services/tracking/avo/Avo";

import OkCar from "public/assets/img/ok-car.svg";

export default TeamDrivesToApprove;

function TeamDrivesToApprove() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    teamDrivesSummary,
    isTeamDrivesLoading,
    hasTeamDrivesLoaded,
    selectedRangeDatesFilter,
    driverName,
    driverId,
    selectedDrive,
    pastSelectedDrive,
    setPastSelectedDriveId,
    isGeneratingPDF,
    isGeneratingCSV,
    exportDrives,
    fetchTeamDrivesList,
    page,
    sortBy,
    order,
    isTeamDrivesEmpty,
    nextDriverToReview,
    rangeDates,
    reviewDrives,
    isReviewDrivesLoading,
    driveTypeFilter,
    shouldRenderEmptyState,
    purposeFilter,
  } = useTeamDrives();

  useEffect(() => {
    fetchTeamDrivesList({
      additionalFilters: {
        review_status: DRIVE_REVIEW_STATUS.PENDING,
      },
    });
  }, [
    page,
    sortBy,
    order,
    selectedRangeDatesFilter,
    driverId,
    driveTypeFilter,
    purposeFilter,
  ]);

  const handleDateRangeFilterSelect = (
    dates,
    datePickerType,
    dateRangeType
  ) => {
    queryParams.set(
      "rangeDates",
      dates.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);

    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      dateFilter:
        datePickerType === PICKER_TYPE.RANGE ? dateRangeType : datePickerType,
      webPage: WebPages.APPROVE_DRIVES_LIST_TEAM_DASHBOARD,
    });

    history.replace({ search: queryParams.toString() });
  };

  const handleClearDateRangeFilter = () => {
    queryParams.set(
      "rangeDates",
      DEFAULT_DATE_RANGE.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handleCloseDriveSummary = () => {
    setPastSelectedDriveId(null);
    queryParams.delete("selectedDriveId");
    history.replace({ search: queryParams.toString() });
  };

  const handleReviewDrives = async ({ action }) => {
    await reviewDrives({
      driveIds: [drive.id],
      action,
      additionalFilters: {
        review_status: DRIVE_REVIEW_STATUS.PENDING,
      },
      shouldRemoveReviewedDrives: true,
    });
  };

  const handleDriveTypeFilterSelect = (value) => {
    if (value) {
      queryParams.set("driveType", value);
    } else {
      queryParams.delete("driveType");
    }
    queryParams.set("page", 1);
    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driveTypeFilter: value,
      webPage: WebPages.APPROVE_DRIVES_LIST_TEAM_DASHBOARD,
    });
    history.replace({ search: queryParams.toString() });
  };

  const handlePurposeFilterSelect = (value) => {
    if (value) {
      queryParams.set("purpose", value);
    } else {
      queryParams.delete("purpose");
    }
    queryParams.set("page", 1);
    trackTeamsDrivesListFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      teamDrivesPurposeFilter: value,
      webPage: WebPages.APPROVE_DRIVES_LIST_TEAM_DASHBOARD,
    });
    history.replace({ search: queryParams.toString() });
  };

  const handleDunningCheck = () => {
    const dunningStatus = checkAndHandleDunning();
    return dunningStatus === DUNNING_STATUS.EXPIRED;
  };

  const title = driverId === "all-drivers" ? "All members" : driverName;

  const drive = selectedDrive || pastSelectedDrive;

  const totalDrives = teamDrivesSummary?.drives?.value || 0;
  const manuallyAddedDrives = teamDrivesSummary?.drives?.manual_value || 0;
  const totalDistance = teamDrivesSummary?.distance?.value || 0;

  const park = parseFloat(teamDrivesSummary?.value?.parking_fees ?? 0);
  const tolls = parseFloat(teamDrivesSummary?.value?.toll_fees ?? 0);
  const value = parseFloat(teamDrivesSummary?.value?.value ?? 0);
  const totalValue = park + tolls + value;

  const hasNoDrivesRecorded = totalDrives === 0;
  const hasNoDistanceRecorded = totalDistance === 0;
  const hasNoValueRecorded = totalValue === 0;
  const hasNoManuallyAddedDrivesRecorded = manuallyAddedDrives === 0;

  const formattedTotalDistance = hasNoDistanceRecorded
    ? "0"
    : totalDistance?.toFixed(1);
  const formattedTotalValue = hasNoValueRecorded ? "0" : totalValue?.toFixed(2);

  const link = rangeDates
    ? `/teams/drives-to-approve?rangeDates=${rangeDates}`
    : "/teams/drives-to-approve";

  return (
    <PageLayout className="page-drives-to-approve">
      <PageLayout.Main>
        <div className="h-screen flex flex-col">
          <div className="p-5 page-header">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <MobMenu />
                <div className="flex items-center gap-2.5">
                  <Link
                    to={link}
                    className="font-semibold text-28 text-black/60 hover:text-black cursor-pointer leading-[130%]"
                  >
                    Drives to approve
                  </Link>
                  <Icon name="chevron-right-small" color="black-op50" />
                  <h3 className="text-28">{title || "-"}</h3>
                </div>
              </div>
            </div>

            <p className="max-w-[900px] text-black/80 mt-2">
              You can mark drives as Approved and Rejected as you review. These
              status labels are only visible to admins currently and your
              drivers would not be notified. To find drives that have been
              approved or rejected, go to the All Drives page.
            </p>
            <div className="flex items-center mt-4 gap-3 flex-wrap">
              <DateRangePicker
                customLabel={CustomDateRangePickerLabel}
                onOpen={handleDunningCheck}
                onSelect={handleDateRangeFilterSelect}
                onClear={handleClearDateRangeFilter}
                defaultValue={DEFAULT_DATE_RANGE}
                initialValue={selectedRangeDatesFilter?.values}
                defaultColor="white"
                triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
                openTriggerClassName="border-blue hover:border-blue active:border-blue"
              />
              <DropdownFilter
                icon="m-dotted-circle"
                label="Auto + manually added"
                value={driveTypeFilter}
                onOpen={handleDunningCheck}
                onSelect={handleDriveTypeFilterSelect}
                options={[
                  {
                    label: "Auto + manually added",
                    value: DRIVE_TYPES.ANY,
                    default: true,
                  },
                  {
                    label: "Manually added",
                    value: DRIVE_TYPES.MANUALLY_ADDED,
                  },
                  {
                    label: "Automatically detected",
                    value: DRIVE_TYPES.AUTOMATICALLY_DETECTED,
                  },
                ]}
              />
              <TeamDrivesPurposeFilter
                purposeFilter={purposeFilter}
                handleOpenFilters={handleDunningCheck}
                handlePurposeFilterSelect={handlePurposeFilterSelect}
              />
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            {hasTeamDrivesLoaded &&
            isTeamDrivesEmpty &&
            !shouldRenderEmptyState ? (
              <div className="w-full h-full flex flex-col justify-center items-center gap-10 py-8">
                <OkCar />
                <div className="text-center">
                  <p className="text-[20px] font-semibold">Great job!</p>
                  <div className="mt-2">
                    <p>
                      You have reviewed all drives from {driverName || "-"} in
                      this time period.
                    </p>
                    <p className="max-h-[21px]">
                      Download a{" "}
                      <button
                        onClick={() => exportDrives({ type: "pdf" })}
                        className={`text-blue font-semibold ${
                          isGeneratingPDF ? "cursor-progress" : ""
                        }`}
                      >
                        PDF
                        {isGeneratingPDF && (
                          <Icon
                            name="spinner"
                            className="inline-block scale-50 -ml-0.5 mb-[3.5px] w-[18px] h-[9px]"
                          />
                        )}
                      </button>{" "}
                      or{" "}
                      <button
                        onClick={() => exportDrives({ type: "csv" })}
                        className={`text-blue font-semibold ${
                          isGeneratingCSV ? "cursor-progress" : ""
                        }`}
                      >
                        CSV{" "}
                        {isGeneratingCSV && (
                          <Icon
                            name="spinner"
                            className="inline-block scale-50 -ml-1.5 mb-[3.5px] w-[18px] h-[9px]"
                          />
                        )}
                      </button>{" "}
                      of approved drives from {driverName || "-"}.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center gap-5">
                  <p className="font-semibold text-16">What's next?</p>
                  {nextDriverToReview && (
                    <Link
                      onClick={() => {
                        trackTeamsDriveReviewCtaSelected({
                          orgId: team.orgId,
                          orgGroupId: team.orgGroupId,
                          subPlan: team.subscription?.plan,
                          driveReviewCta: DriveReviewCTA.CONTINUE_REVIEW,
                        });
                      }}
                      to={nextDriverToReview.link}
                      className="w-[500px] min-h-[90px] flex gap-6 justify-between items-center border border-border-1 rounded-[16px] p-5 hover:border-blue"
                    >
                      <div className="flex flex-col">
                        <p className="font-medium">
                          Review drives from {nextDriverToReview.driverName}
                        </p>
                        <p className="text-[rgba(23,23,23,0.6)]">
                          {nextDriverToReview.driverName} has{" "}
                          {nextDriverToReview.driveCount}{" "}
                          {nextDriverToReview.driveCount > 1
                            ? "drives"
                            : "drive"}{" "}
                          that needs review in the same time period.
                        </p>
                      </div>
                      <Icon name="arrow-right" color="black/50" />
                    </Link>
                  )}
                  <Link
                    onClick={() => {
                      trackTeamsDriveReviewCtaSelected({
                        orgId: team.orgId,
                        orgGroupId: team.orgGroupId,
                        subPlan: team.subscription?.plan,
                        driveReviewCta: DriveReviewCTA.ALL_DRIVES,
                      });
                    }}
                    to={`/teams/drives/${driverId}?rangeDates=${rangeDates}&driverName=${driverName}`}
                    className="w-[500px] min-h-[90px] flex gap-6 justify-between  items-center border border-border-1 rounded-[16px] p-5 hover:border-blue"
                  >
                    <p className="font-medium">
                      View all drives from {driverName || "-"}
                    </p>
                    <Icon name="arrow-right" color="black/50" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="m-5 rounded-[14px] border border-border-1 shadow-sm w-fit min-w-[-webkit-fill-available]">
                <div className="p-[15px] border-b-border-1 border-b">
                  <div className="m-[15px] flex gap-10">
                    <div className="flex flex-col">
                      {isTeamDrivesLoading && (
                        <div className="w-[50px] max-w-full h-[24px] mb-3 rounded-4 bg-black/10 animate-pulse" />
                      )}
                      {hasTeamDrivesLoaded && (
                        <p className="text-21 font-semibold">
                          <span
                            className={`font-semibold text-24 ${
                              hasNoDrivesRecorded
                                ? "text-[rgba(23,23,23,0.12)]"
                                : ""
                            }`}
                          >
                            {totalDrives}
                          </span>
                        </p>
                      )}
                      <p className="text-14 text-[rgba(23,23,23,0.6)]">
                        {totalDrives > 1 ? "Drives" : "Drive"} submitted
                      </p>
                    </div>
                    <div className="flex flex-col">
                      {isTeamDrivesLoading && (
                        <div className="w-[50px] max-w-full h-[24px] mb-3 rounded-4 bg-black/10 animate-pulse" />
                      )}
                      {hasTeamDrivesLoaded && (
                        <p className="text-21 font-semibold">
                          <span
                            className={`font-semibold text-24 ${
                              hasNoManuallyAddedDrivesRecorded
                                ? "text-[rgba(23,23,23,0.12)]"
                                : ""
                            }`}
                          >
                            {manuallyAddedDrives}
                          </span>
                        </p>
                      )}
                      <p className="text-14 text-[rgba(23,23,23,0.6)]">
                        Manually added
                      </p>
                    </div>
                    <div className="flex flex-col">
                      {isTeamDrivesLoading && (
                        <div className="w-[50px] max-w-full h-[24px] mb-3 rounded-4 bg-black/10 animate-pulse" />
                      )}
                      {hasTeamDrivesLoaded && (
                        <p className="text-21 font-semibold">
                          <span
                            className={`font-semibold text-24 ${
                              hasNoDistanceRecorded
                                ? "text-[rgba(23,23,23,0.12)]"
                                : ""
                            }`}
                          >
                            {`${formattedTotalDistance} ${
                              teamDrivesSummary.distance.unit || ""
                            }`}
                          </span>
                        </p>
                      )}
                      <p className="text-14 text-[rgba(23,23,23,0.6)]">
                        Distance submitted
                      </p>
                    </div>
                    <div className="flex flex-col">
                      {isTeamDrivesLoading && (
                        <div className="w-[50px] max-w-full h-[24px] mb-3 rounded-4 bg-black/10 animate-pulse" />
                      )}
                      {hasTeamDrivesLoaded && (
                        <p className="text-21 font-semibold">
                          <span
                            className={`font-semibold text-24 ${
                              hasNoValueRecorded
                                ? "text-[rgba(23,23,23,0.12)]"
                                : ""
                            }`}
                          >
                            {teamDrivesSummary.value.unit || ""}
                            {formattedTotalValue}
                          </span>
                        </p>
                      )}
                      <p className="text-14 text-[rgba(23,23,23,0.6)]">
                        Value submitted
                      </p>
                    </div>
                  </div>
                </div>
                <TeamDrivesToApproveList />
              </div>
            )}
          </div>
        </div>
      </PageLayout.Main>
      <PageLayout.Sidebar
        className={`transition-all duration-200 ease-in-out ${
          selectedDrive ? "" : "w-0"
        }`}
      >
        <div
          className={`right-panel w-[340px] laptop:w-[300px] justify-between ${
            selectedDrive ? "open" : "closed"
          }`}
        >
          <TeamDriveSummary
            showSubmittedAt
            drive={drive}
            onClose={handleCloseDriveSummary}
            currency={drive?.currency_unit}
            distanceUnits={drive?.distance_unit}
            showComments
          />
          <div className="flex justify-between gap-2.5 p-4">
            <MiqButton
              loading={isReviewDrivesLoading}
              className="grow basis-0 bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]"
              secondary
              icon="ban"
              onClick={() =>
                handleReviewDrives({
                  action: DRIVE_REVIEW_STATUS_ACTION.REJECT,
                })
              }
            >
              Reject
            </MiqButton>
            <MiqButton
              loading={isReviewDrivesLoading}
              className="grow basis-0 bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]"
              secondary
              icon="checked-circle-black"
              onClick={() =>
                handleReviewDrives({
                  action: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
                })
              }
            >
              Approve
            </MiqButton>
          </div>
        </div>
      </PageLayout.Sidebar>
    </PageLayout>
  );
}
