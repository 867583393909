import ti from "./client-hints-parser.js";
import ni from "./user-agent-parser.js";
import { Browsers as ri, OperatingSystems as rt } from "./device-constants.js";
class si {
  constructor() {
    const t = navigator.userAgentData ? ti : ni;
    (this.Sg = new t()),
      (this.userAgent = navigator.userAgent),
      (this.browser = this.Sg.tf()),
      (this.version = this.Sg.ef()),
      this.Zo().then(t => (this.OS = t)),
      (this.language = (
        navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        navigator.systemLanguage ||
        ""
      ).toLowerCase()),
      (this.Wo = si.Bg(this.userAgent));
  }
  Og() {
    return this.browser !== ri.Pg || this.version > 8;
  }
  Ja() {
    return this.browser === ri.kg;
  }
  Xo() {
    return this.OS || null;
  }
  Zo() {
    return this.OS
      ? Promise.resolve(this.OS)
      : this.Sg.Zo(si.xg).then(t => ((this.OS = t), t));
  }
  static Bg(t) {
    t = t.toLowerCase();
    const i = [
      "googlebot",
      "bingbot",
      "slurp",
      "duckduckbot",
      "baiduspider",
      "yandex",
      "facebookexternalhit",
      "sogou",
      "ia_archiver",
      "https://github.com/prerender/prerender",
      "aolbuild",
      "bingpreview",
      "msnbot",
      "adsbot",
      "mediapartners-google",
      "teoma"
    ];
    for (let n = 0; n < i.length; n++) if (-1 !== t.indexOf(i[n])) return !0;
    return !1;
  }
}
si.xg = [
  { string: navigator.platform, if: "Win", identity: rt.Dg },
  { string: navigator.platform, if: "Mac", identity: rt.jg },
  { string: navigator.platform, if: "BlackBerry", identity: "BlackBerry" },
  { string: navigator.platform, if: "FreeBSD", identity: "FreeBSD" },
  { string: navigator.platform, if: "OpenBSD", identity: "OpenBSD" },
  { string: navigator.platform, if: "Nintendo", identity: "Nintendo" },
  { string: navigator.platform, if: "SunOS", identity: "SunOS" },
  { string: navigator.platform, if: "PlayStation", identity: "PlayStation" },
  { string: navigator.platform, if: "X11", identity: "X11" },
  {
    string: navigator.userAgent,
    if: ["iPhone", "iPad", "iPod"],
    identity: rt.ln
  },
  { string: navigator.platform, if: "Pike v", identity: rt.ln },
  { string: navigator.userAgent, if: ["Web0S"], identity: "WebOS" },
  {
    string: navigator.platform,
    if: ["Linux armv7l", "Android"],
    identity: rt.Fg
  },
  { string: navigator.userAgent, if: ["Android"], identity: rt.Fg },
  { string: navigator.platform, if: "Linux", identity: "Linux" }
];
const ot = new si();
export { ot as default, si as BrowserDetector };
