import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isThisYear from "date-fns/isThisYear";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Checkbox from "src/components/elements/Checkbox";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Pagination from "src/components/elements/Pagination";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { DATE_RANGES } from "src/components/blocks/DateRangePicker";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { getReports } from "src/services/reports";
import {
  reportDownloadSources,
  reportOpenSources,
  trackDriverReportOpened,
} from "src/services/tracking";

import ImgEmptyStateDefault from "public/assets/img/car-with-stuff.svg";
import ImgEmptyStateFiltered from "public/assets/img/empty-car.svg";

import TeamMemberName from "./TeamMemberName";

export default ReportsList;

const RECORDS_PER_PAGE = 20;

const PAGE_STATES = {
  IDLE: "IDLE",
  REPORTS_LOADING: "REPORTS_LOADING",
  REPORTS_ERROR: "REPORTS_ERROR",
  REPORTS_EMPTY: "REPORTS_EMPTY",
  REPORTS_LOADED: "REPORTS_LOADED",
};

const COLUMNS = {
  DRIVER: "DRIVER",
  DATE_SUBMITTED: "DATE_SUBMITTED",
  DRIVES: "DRIVES",
  DISTANCE: "DISTANCE",
  MANUAL: "MANUAL",
  VALUE: "VALUE",
  ACTIONS: "ACTIONS",
};

const colsData = {
  [COLUMNS.SELECT]: {
    title: "",
    extraClass: "pl-5",
    sortable: false,
    isToggleAll: true,
  },
  [COLUMNS.DRIVER]: {
    title: "Driver",
    sortable: true,
    fieldName: "user_email",
    tooltip: { text: "The submitter of the report", alignX: "left" },
  },
  [COLUMNS.DATE_SUBMITTED]: {
    title: "Submitted",
    extraClass: "desktop-version",
    sortable: true,
    fieldName: "last_submitted_at",
    tooltip: { text: "Date when the report was submitted" },
  },
  [COLUMNS.DRIVES]: {
    title: "Drives",
    extraClass: "desktop-version",
    sortable: true,
    fieldName: "last_drive_datetime",
    tooltip: { text: "Month of drives contained in the report" },
  },
  [COLUMNS.DISTANCE]: {
    title: "Distance",
    extraClass: "report-list-column-fix",
    sortable: true,
    fieldName: "total_distance",
    tooltip: { text: "Total traveled distance" },
  },
  [COLUMNS.MANUAL]: {
    title: "Man. Added",
    extraClass: "desktop-version",
    sortable: true,
    fieldName: "total_manual_drives",
    tooltip: {
      text: "Number of manually added drives",
    },
  },
  [COLUMNS.VALUE]: {
    title: "Value",
    extraClass: "pr-8 text-green",
    alignRight: true,
    sortable: true,
    fieldName: "total_value",
    tooltip: {
      text: "Total value of business drives",
      alignX: "right",
    },
  },
  [COLUMNS.ACTIONS]: {
    title: "",
    extraClass: "pr-5",
    sortable: false,
  },
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const EmptyVal = () => (
  <Text bold className="text-gray-light">
    -
  </Text>
);

function ReportsList({ onReportClicked, filters, withDefaultFilters }) {
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const {
    isReportSelected,
    areAllReportsSelected,
    isAnyReportSelected,
    toggleReport,
    toggleReports,
    generateReportAsPDF,
    generateReportAsCSV,
    generatingPDF,
    generatingCSV,
  } = useTeamReports();

  const isFirstLoad = useRef(true);
  const history = useHistory();
  const queryParams = useQueryParams();
  const pageParam = queryParams.get("page");
  const sortParam = queryParams.get("sort");
  const orderParam = queryParams.get("order");

  const [reports, setReports] = useState([]);
  const [reportsMeta, setReportsMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
  const [sortByCol, setSortByCol] = useState(
    sortParam || COLUMNS.DATE_SUBMITTED
  );
  const [sortByColOrder, setSortByColOrder] = useState(
    orderParam || SORT_ORDER.DESC
  );
  const [pageState, setPageState] = useState(PAGE_STATES.IDLE);
  const [downloadReportDropdownOpen, setDownloadReportDropdownOpen] =
    useState(null);

  const refs = {
    [COLUMNS.SELECT]: useRef(),
    [COLUMNS.DRIVER]: useRef(),
    [COLUMNS.DATE_SUBMITTED]: useRef(),
    [COLUMNS.DRIVES]: useRef(),
    [COLUMNS.DISTANCE]: useRef(),
    [COLUMNS.MANUAL]: useRef(),
    [COLUMNS.VALUE]: useRef(),
  };

  const { checkAndHandleDunning } = useTeamsCTA();

  const loadReports = async (page, sortCol, sortOrder) => {
    if (pageState === PAGE_STATES.REPORTS_LOADING) return;

    setPageState(PAGE_STATES.REPORTS_LOADING);
    setCurrentPage(page);
    setSortByCol(sortCol);
    setSortByColOrder(sortOrder);

    if (isFirstLoad.current) isFirstLoad.current = false;

    queryParams.set("sort", sortCol);
    queryParams.set("order", sortOrder);
    queryParams.set("page", page);

    history.replace({
      search: queryParams.toString(),
    });

    try {
      const { dateRange, drivers, monthOfDrives } = filters;
      const { reports, metadata } = await getReports(team?.id, {
        dateRange,
        drivers,
        month: getMonth(monthOfDrives),
        year: getYear(monthOfDrives),
        page,
        pageSize: RECORDS_PER_PAGE,
        orderBy: colsData[sortCol].fieldName,
        isDesc: sortOrder === SORT_ORDER.DESC,
      });
      setReports(reports);
      setReportsMeta(metadata);
      setPageState(
        reports?.length > 0
          ? PAGE_STATES.REPORTS_LOADED
          : PAGE_STATES.REPORTS_EMPTY
      );
    } catch (err) {
      console.log(err);
      console.log("failed to load reports");
      setPageState(PAGE_STATES.REPORTS_ERROR);
    }
  };

  useEffect(() => {
    // This effect triggers when (1) the component is mounted and (2) when the filters change
    // 1: If the component is mounted, we want to load the reports for the first time, respecting the page from query params
    // 2: If the filters change, we want to force the page to be 1 because the filters will affect the pagination
    const page = isFirstLoad.current ? currentPage : 1;

    loadReports(page, sortByCol, sortByColOrder);
  }, [filters]);

  if (!team?.id) return null;

  const openReportDetail = (report, source) => {
    trackDriverReportOpened({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subscriptionId: userData.subscriptionType,
      source,
    });
    onReportClicked(report);
  };

  const handleColClicked = (colCode) => {
    let sortOrd = SORT_ORDER.DESC;
    if (sortByCol === colCode) {
      sortOrd =
        sortByColOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    }

    console.log("Sort: ", colCode, sortOrd);
    loadReports(currentPage, colCode, sortOrd);
  };

  const handleDownloadPDFClicked = async (e, report) => {
    e.stopPropagation();

    await generateReportAsPDF(report.id, reportDownloadSources.REPORTS_ROW);

    setDownloadReportDropdownOpen(null);
  };

  const handleDownloadCSVClicked = async (e, report) => {
    e.stopPropagation();

    await generateReportAsCSV(report.id, reportDownloadSources.REPORTS_ROW);

    setDownloadReportDropdownOpen(null);
  };

  const renderEmptyState = () => {
    let txt = "";
    if (withDefaultFilters) {
      txt =
        "This is where you will find all of your drivers' submitted reports.";
    } else {
      switch (filters.dateRangeType) {
        case DATE_RANGES.LAST_30_DAYS:
          txt = "No reports have been submitted in the last 30 days.";
          break;
        case DATE_RANGES.LAST_3_MONTHS:
          txt = "No reports have been submitted in the last 3 months.";
          break;
        case DATE_RANGES.LAST_12_MONTHS:
          txt = "No reports have been submitted in the last 12 months.";
          break;
        case DATE_RANGES.YEAR_TO_DATE:
          txt = "No reports have been submitted this year.";
          break;
        default:
          txt = "No reports have been submitted.";
      }
    }
    return (
      <>
        {withDefaultFilters ? (
          <ImgEmptyStateDefault />
        ) : (
          <ImgEmptyStateFiltered />
        )}
        <Text
          lg
          color="black/70"
          className="mt-6 text-center"
          style={{ maxWidth: "270px" }}
        >
          {txt}
        </Text>
      </>
    );
  };

  let filteredReports = [...reports];

  if (filteredReports?.length > RECORDS_PER_PAGE) {
    filteredReports = filteredReports.slice(
      (currentPage - 1) * RECORDS_PER_PAGE,
      (currentPage - 1) * RECORDS_PER_PAGE + RECORDS_PER_PAGE
    );
  }

  let pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage,
    total: reportsMeta?.total_results || 0,
    onSelectPage: (i) => {
      queryParams.set("page", i);
      history.replace({ search: queryParams.toString() });

      loadReports(i, sortByCol, sortByColOrder);
    },
  };

  return (
    <div className="reports-list flex-grow h-full flex flex-col">
      {pageState === PAGE_STATES.REPORTS_LOADING && (
        <Loader
          message="Processing... This may take a few minutes depending on the number of reports."
          timeout={10000}
        />
      )}

      {pageState === PAGE_STATES.REPORTS_EMPTY && (
        <div className="flex-grow flex flex-col items-center justify-center">
          {renderEmptyState()}
        </div>
      )}

      {pageState === PAGE_STATES.REPORTS_LOADED && (
        <>
          <div className="miq-list-table reports-list-table">
            <div className="miq-list-table-header">
              {Object.keys(colsData).map((k) => {
                const col = colsData[k];

                if (col.isToggleAll) {
                  return (
                    <div
                      key={k}
                      className={`select-none cursor-pointer p-2 ${
                        col.extraClass || ""
                      }`}
                    >
                      <Checkbox
                        checked={areAllReportsSelected(filteredReports)}
                        indeterminate={isAnyReportSelected(filteredReports)}
                        onChange={() => {
                          const dunningStatus = checkAndHandleDunning();

                          if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

                          toggleReports(filteredReports);
                        }}
                      />
                    </div>
                  );
                }

                return (
                  <div
                    key={col.title}
                    className={`select-none p-2 ${
                      col.sortable ? "cursor-pointer" : ""
                    } ${col.extraClass || ""}`}
                    onClick={() => col.sortable && handleColClicked(k)}
                  >
                    <div
                      className={`flex ${col.alignRight ? "justify-end" : ""}`}
                    >
                      <div ref={refs[k]} className="relative">
                        {col.tooltip && (
                          <Tooltip
                            triggerRef={refs[k]}
                            offset={{ y: 30 }}
                            alignX={col.tooltip.alignX || "center"}
                            timeout={1000}
                          >
                            <Text nowrap>{col.tooltip.text}</Text>
                          </Tooltip>
                        )}
                        <Text color="black/70">{col.title}</Text>
                      </div>
                      {col.sortable && sortByCol === k ? (
                        <Icon
                          name="sort"
                          color="black/50"
                          className={`ml-2 transform ${
                            sortByColOrder === SORT_ORDER.DESC
                              ? "rotate-0"
                              : "rotate-180"
                          }`}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="miq-list-table-body">
              {filteredReports.map((r) => {
                const id = r.id;
                let submittedAt = null;
                if (r.lastSubmittedAt) {
                  submittedAt = isThisYear(r.lastSubmittedAt)
                    ? format(r.lastSubmittedAt, "MMM d")
                    : format(r.lastSubmittedAt, "MMM d, yyyy");
                }
                const driverEmail = r.userEmail;
                const driverFirstName = r.userFirstName;
                const driverLastName = r.userLastName;
                const distance = r.formattedDistance;
                const drives = r.formattedDrivesMonth || null;
                const value = r.formattedValue;
                const manualDrives = r.totalManualDrives;

                const isSelected = isReportSelected(r);
                const dropdownOpen = downloadReportDropdownOpen === id;

                return (
                  <div
                    key={id}
                    className={`miq-list-table-body-row ${
                      isSelected ? "bg-beige" : ""
                    }`}
                    onClick={() => {
                      const dunningStatus = checkAndHandleDunning();

                      if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

                      openReportDetail(r, reportOpenSources.REPORT_ROW);
                    }}
                  >
                    <div className="p-2 pl-5">
                      <div className="flex items-center justify-start">
                        <label
                          className="relative cursor-pointer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="absolute w-[38px] h-[38px] left-[-10px] top-[-10px] z-10" />
                          <Checkbox
                            checked={isReportSelected(r)}
                            onChange={() => {
                              const dunningStatus = checkAndHandleDunning();

                              if (dunningStatus === DUNNING_STATUS.EXPIRED)
                                return;

                              toggleReport(r);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="relative p-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
                      {driverEmail ? (
                        <TeamMemberName
                          member={{
                            email: driverEmail,
                            firstName: driverFirstName,
                            lastName: driverLastName,
                          }}
                        />
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="p-2 desktop-version">
                      {submittedAt ? (
                        <Text className="text-black">{submittedAt}</Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="p-2 desktop-version">
                      {drives ? (
                        <Text className="text-black">{drives}</Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="p-2 report-list-column-fix">
                      {distance ? (
                        <Text className="text-black">{distance}</Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="p-2 desktop-version">
                      {manualDrives ? (
                        <Text className="text-black">{manualDrives}</Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="p-2 pr-8 text-right">
                      {value ? (
                        <Text bold className="text-green">
                          {value}
                        </Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                    <div className="pr-5">
                      <div className="flex gap-3">
                        <Dropdown
                          triggerClassName="p-0 shadow-none border-none bg-transparent"
                          contentClassName="top-[40px] left-[-150px] border border-border-1"
                          stopPropagation
                          renderTrigger={() => (
                            <div data-chmln="reports-download-icon-legacy">
                              <ActionIcon
                                icon="download-2-fixed"
                                className="scale-[0.9]"
                                tooltipText="Download report"
                                paused={dropdownOpen}
                              />
                            </div>
                          )}
                          open={dropdownOpen}
                          onClose={() => setDownloadReportDropdownOpen(null)}
                          onOpen={() => {
                            const dunningStatus = checkAndHandleDunning();

                            if (dunningStatus === DUNNING_STATUS.EXPIRED)
                              return;

                            setDownloadReportDropdownOpen((prev) =>
                              prev === id ? null : id
                            );
                          }}
                        >
                          <div className="flex flex-col">
                            <div
                              onClick={(e) => handleDownloadPDFClicked(e, r)}
                              className="flex items-center gap-2 py-2 px-4  rounded hover:bg-beige-medium"
                            >
                              <Icon
                                name="download-2-fixed"
                                className="scale-[0.8]"
                              />
                              <Text semibold>
                                {generatingPDF
                                  ? "Generating..."
                                  : "Download PDF"}
                              </Text>
                            </div>
                            <div
                              onClick={(e) => handleDownloadCSVClicked(e, r)}
                              className="flex items-center gap-2 py-2 px-4 rounded hover:bg-beige-medium"
                            >
                              <Icon
                                name="download-2-fixed"
                                className="scale-[0.8]"
                              />
                              <Text semibold>
                                {generatingCSV
                                  ? "Generating..."
                                  : "Download CSV"}
                              </Text>
                            </div>
                          </div>
                        </Dropdown>
                        <ActionIcon
                          icon="eye-fixed"
                          className="scale-[1.2]"
                          onClick={(e) => {
                            e.stopPropagation();

                            const dunningStatus = checkAndHandleDunning();

                            if (dunningStatus === DUNNING_STATUS.EXPIRED)
                              return;

                            openReportDetail(r, reportOpenSources.EYE_ICON);
                          }}
                          tooltipText="View report"
                          offsetY={46}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-10 pl-8 pr-5">
            <Pagination {...pagination} />
          </div>
        </>
      )}
    </div>
  );
}

function ActionIcon({
  onClick,
  icon,
  className,
  tooltipText,
  paused,
  offsetY = 30,
}) {
  const ref = useRef();

  return (
    <>
      <Tooltip
        paused={paused}
        triggerRef={ref}
        offset={{ y: offsetY }}
        alignX="center"
      >
        {tooltipText}
      </Tooltip>
      <Icon iconRef={ref} name={icon} className={className} onClick={onClick} />
    </>
  );
}
