import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default NotificationsPageQuickTips;

function NotificationsPageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          Check out the{" "}
          <QuickTipsLink url="https://mileiq.com/blog-en-us">
            MileIQ Blog
          </QuickTipsLink>{" "}
          for small business advice and tax information. You can also sign up
          for our weekly newsletter there.*
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/204481155-What-if-I-m-not-receiving-my-report-emails-">
            What if I’m not receiving my report emails?
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          How to: Redeem a{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/204480785-How-to-Redeem-a-Promo-Code">
            Promo code
          </QuickTipsLink>{" "}
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          *Note: Please see the "unsubscribe" link in the the MileIQ Weekly
          Newsletter to unsubscribe from this particular publication.
        </Text>
      </div>
    </>
  );
}
