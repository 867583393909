import "src/models/typings";

import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";

import TeamContext from "src/components/context/TeamContext";
import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Checkbox from "src/components/elements/Checkbox";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Pagination from "src/components/elements/Pagination";
import TabButton from "src/components/elements/TabButton";
import Text from "src/components/elements/Text";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { MEMBER_STATUS } from "src/models/team-member";
import { isTeamsProSubscription } from "src/models/team-subscription";
import { DRIVERS_LIST_ACTIONS } from "src/models/user";

import {
  getRemindBannerLastCallDate,
  setRemindBannerLastCallDate,
} from "src/services/storage";
import { resendInvite } from "src/services/teams";
import {
  teamsUserSelectionTypes,
  trackTeamsInviteResent,
  trackTeamsUserListDownloaded,
} from "src/services/tracking";
import { plural } from "src/services/utils";

import ImgCarWithPaperPlane from "public/assets/img/car-paper-plane.svg";
import ImgNoInvited from "public/assets/img/covered-car.png";

import { useUsers } from "../context/UsersContext";
import SearchInput from "../elements/SearchInput";
import TeamMemberName from "./reports/teams/TeamMemberName";

export default TeamMembersList;

const FILTERS = {
  ALL: "ALL",
  ADMINS: "ADMINS",
  INVITED: "INVITED",
};

const EmptyState = {
  [FILTERS.ALL]: { text: "", renderImg: () => null },
  [FILTERS.ADMINS]: { text: "", renderImg: () => null },
  [FILTERS.INVITED]: {
    text: "No invited users.",
    renderImg: () => <img src={ImgNoInvited} title="No invited users." />,
  },
  SEARCH: {
    text: "No search results found",
    renderImg: () => <img src={ImgNoInvited} title="No search results found" />,
  },
};

const Tabs = [
  {
    id: FILTERS.ALL,
    label: "All users",
    icon: "people",
    filter: (m) => m.status !== MEMBER_STATUS.ARCHIVED,
  },
  {
    id: FILTERS.ADMINS,
    label: "Admins",
    icon: "avatar-admin",
    filter: (m) => m.isAdmin && m.status === MEMBER_STATUS.ACTIVE,
  },
  {
    id: FILTERS.INVITED,
    label: "Invited",
    icon: "paper-plane-outline",
    filter: (m) => m.status === MEMBER_STATUS.INVITED,
  },
];

const COLUMNS = {
  SELECT: "SELECT",
  USER: "USER",
  STATUS: "STATUS",
  DATE_ADDED: "DATE_ADDED",
  ACTIONS: "ACTIONS",
};

const colsData = {
  [COLUMNS.SELECT]: {
    id: COLUMNS.SELECT,
    title: "",
    extraClass: "pl-10",
    sortable: false,
    isToggleAll: true,
  },
  [COLUMNS.USER]: {
    id: COLUMNS.USER,
    title: "User",
    extraClass: "pl-8",
    sortable: true,
  },
  [COLUMNS.STATUS]: {
    id: COLUMNS.STATUS,
    title: "Status",
    sortable: true,
  },
  [COLUMNS.DATE_ADDED]: {
    id: COLUMNS.DATE_ADDED,
    title: "Date added",
    sortable: true,
  },
  [COLUMNS.ACTIONS]: {
    id: COLUMNS.ACTIONS,
    title: "",
    extraClass: "pr-8",
    sortable: false,
  },
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const RECORDS_PER_PAGE = 30;

function MemberAvatar({ member }) {
  const isAdmin = member.isAdmin;
  const isDriver = member.isDriver;
  const isActive = member.status === MEMBER_STATUS.ACTIVE;
  const bgClass = isActive
    ? isAdmin
      ? "bg-sundance"
      : "bg-green"
    : "bg-beige-medium";
  let icon = "car";
  if (isAdmin) {
    if (isDriver) {
      icon = "car-admin";
    } else {
      icon = "avatar-admin";
    }
  }

  return (
    <div
      className={`flex-shrink-0 flex items-center justify-center w-10 h-10 rounded ${bgClass}`}
    >
      <Icon name={icon} color="white" />
    </div>
  );
}

function getMemberStatusText(m) {
  let statusText = "";
  if (m.status === MEMBER_STATUS.INVITED) {
    statusText = "Invited";
  } else if (m.status === MEMBER_STATUS.ACTIVE) {
    statusText = m.isAdmin
      ? m.isDriver
        ? "Admin & Driver"
        : "Admin"
      : "Driver";
  }
  return statusText;
}

/* eslint-disable-next-line */
function TeamMembersList({
  members,
  onInviteUserClicked,
  onInviteAdminClicked,
  loading,
  onAction,
  onSuccess,
  onFailure,
}) {
  const [sortByCol, setSortByCol] = useState(COLUMNS.DATE_SUBMITTED);
  const [sortByColOrder, setSortByColOrder] = useState(SORT_ORDER.DESC);
  const [isSendingReminderFromBanner, setSendingReminderFromBanner] =
    useState(false);
  const [search, setSearch] = useState("");

  const {
    toggleUser,
    toggleUsers,
    isUserSelected,
    areAllUsersSelected,
    isAnyUserSelected,
  } = useUsers();

  // we need to keep the row highlighted if its actions menu is open.
  // storing row keys in an array let us not be worried about
  // the order of onClose and onOpen callbacks from actions menus of different rows
  const [actingOn, setActingOn] = useState([]);

  const query = useQueryParams();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(
    parseInt(query.get("page")) || 1
  );
  const [currentFilter, setCurrentFilter] = useState(
    query.get("tab") || FILTERS.ALL
  );

  const { team } = useContext(TeamContext);
  const { user } = useUser();

  const { checkAndHandleDunning } = useTeamsCTA();

  if (!user?.id || !team?.id) return null;

  const handleToggleActionMenu = (open, rowKey) => {
    if (open) {
      setActingOn((list) => [...list, rowKey]);
    } else {
      setActingOn((list) => list.filter((key) => key !== rowKey));
    }
  };

  const handleColClicked = (colCode) => {
    console.log("Col clicked: ");
    console.log(colsData[colCode]);
    let sortOrd = SORT_ORDER.DESC;
    if (sortByCol === colCode) {
      sortOrd =
        sortByColOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
      setSortByColOrder(sortOrd);
    } else {
      setSortByCol(colCode);
      setSortByColOrder(SORT_ORDER.DESC);
    }

    console.log("Sort: ", colCode, sortOrd);
  };

  const handleInviteClicked = () => {
    if (currentFilter === FILTERS.ADMINS) {
      onInviteAdminClicked();
    } else {
      onInviteUserClicked();
    }
  };

  const toggleAll = () => {
    toggleUsers(filteredMembers);
  };

  const handleDownloadUserList = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsUserListDownloaded({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      plan: team.subscription?.plan,
    });
    const header = "Email Address,Status,Date added,Driver,Admin";
    const csv = members.reduce((acc, member) => {
      const {
        user: { createdAt, email, isAdmin, isDriver, isEnabled, isVerified },
      } = member;
      console.log(isEnabled, isVerified);
      const status = member.statusName;
      return `${acc}\n${email},${status},${createdAt},${
        isDriver ? "Yes" : "No"
      },${isAdmin ? "Yes" : "No"}`;
    }, header);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${team?.name?.replace(/[^A-Z0-9]/gi, "_")}_UserExport_${new Date()
        .toJSON()
        .slice(0, 10)}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSendReminderFromBanner = async (members) => {
    try {
      setSendingReminderFromBanner(true);

      const { invites } = await resendInvite(team.id, members);

      const haveAllInvitesFailed = invites.every(
        (invite) => Object.values(invite)[0] === false
      );

      if (haveAllInvitesFailed) {
        throw new Error("All invites have failed");
      }

      onSuccess(
        `Invitation resent to ${members.length} ${plural(
          members.length,
          "user",
          "users"
        )}!`
      );

      trackTeamsInviteResent({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        resendType: teamsUserSelectionTypes.BULK,
        resentUserCount: members.length,
      });

      setRemindBannerLastCallDate();
    } catch (e) {
      console.error(e);

      onFailure("Failed to send reminder");
    } finally {
      setSendingReminderFromBanner(false);
    }
  };

  const formatDate = (date) => format(new Date(date), "MMM d, yyyy");
  const isInvitedTab = currentFilter === FILTERS.INVITED;

  const _changeFilter = (id) => {
    query.set("tab", id);
    query.set("page", 1);
    setCurrentFilter(id);
    setCurrentPage(1);
    history.push(`/teams/users?${query.toString()}`);
  };

  let filteredMembers = [...members];
  const tab = Tabs.find((t) => t.id === currentFilter);
  if (tab && tab.filter) {
    filteredMembers = members.filter(tab.filter);
  }

  const filteredMembersBeforeSearch = filteredMembers.length;

  filteredMembers = filteredMembers.filter((m) => {
    const memberStatusText = getMemberStatusText(m);
    const formattedInviteDate = m.invitedAt && formatDate(m.invitedAt);
    const searchTextLowerCase = search.toLowerCase();
    const isNameSearched =
      m.firstName?.toLowerCase().includes(searchTextLowerCase) ||
      m.lastName?.toLowerCase().includes(searchTextLowerCase);

    return (
      m.email.toLowerCase().includes(searchTextLowerCase) ||
      memberStatusText.toLowerCase().includes(searchTextLowerCase) ||
      formattedInviteDate?.toLowerCase().includes(searchTextLowerCase) ||
      isNameSearched
    );
  });

  switch (sortByCol) {
    case COLUMNS.DATE_ADDED:
      filteredMembers.sort((a, b) => {
        if (sortByColOrder === SORT_ORDER.ASC) {
          return a.invitedAt - b.invitedAt;
        } else {
          return b.invitedAt - a.invitedAt;
        }
      });
      break;

    case COLUMNS.STATUS:
      filteredMembers.sort((a, b) => {
        const aStatus = getMemberStatusText(a);
        const bStatus = getMemberStatusText(b);
        if (sortByColOrder === SORT_ORDER.ASC) {
          return aStatus.localeCompare(bStatus);
        } else {
          return bStatus.localeCompare(aStatus);
        }
      });
      break;

    case COLUMNS.USER:
      filteredMembers.sort((a, b) => {
        if (sortByColOrder === SORT_ORDER.ASC) {
          return a.email.localeCompare(b.email);
        } else {
          return b.email.localeCompare(a.email);
        }
      });
      break;

    default:
      filteredMembers.sort((a, b) => a.email.localeCompare(b.email));
  }

  // apply pagination
  let pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage,
    total: filteredMembers.length,
    onSelectPage: (i) => {
      query.set("page", i);
      setCurrentPage(i);
      history.push(`/teams/users?${query.toString()}`);
    },
  };

  const filteredMembersBeforePagination = [...filteredMembers];

  if (filteredMembers?.length > RECORDS_PER_PAGE) {
    filteredMembers = filteredMembers.slice(
      (currentPage - 1) * RECORDS_PER_PAGE,
      (currentPage - 1) * RECORDS_PER_PAGE + RECORDS_PER_PAGE
    );
  }

  const shouldShowRemindBanner =
    isInvitedTab &&
    filteredMembers.length > 0 &&
    differenceInDays(new Date(), new Date(getRemindBannerLastCallDate())) >= 1;

  const shouldShowSearchEmptyState =
    filteredMembers.length === 0 && filteredMembersBeforeSearch > 0;

  const CurrentEmptyState =
    EmptyState[shouldShowSearchEmptyState ? "SEARCH" : currentFilter];

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-6">
        <div className="teams-users-list-tabs flex items-center">
          {Tabs.map((t) => (
            <TabButton
              key={t.id}
              className="mr-2"
              label={t.label}
              icon={t.icon || "avatar"}
              active={t.id === currentFilter}
              count={members.filter(t.filter).length}
              onClick={() => {
                _changeFilter(t.id);
              }}
            />
          ))}
        </div>
        <div>
          <SearchInput
            onChange={setSearch}
            value={search}
            placeholder="Search by user"
          />
        </div>
      </div>
      {shouldShowRemindBanner && (
        <div className="flex justify-between items-center bg-beige rounded mx-6 py-5 px-6 ">
          <div className="flex items-center">
            <div className="p-2">
              <ImgCarWithPaperPlane />
            </div>
            <div className="ml-5">
              <Text paragraph semibold>
                Remind all of your teammates to join your Team on MileIQ
              </Text>
              <Text paragraph md>
                There{" "}
                {plural(filteredMembersBeforePagination.length, "is", "are")}{" "}
                {filteredMembersBeforePagination.length} invited{" "}
                {plural(
                  filteredMembersBeforePagination.length,
                  "user",
                  "users"
                )}{" "}
                who{" "}
                {plural(filteredMembersBeforePagination.length, "has", "have")}{" "}
                not yet joined your team. Send them a friendly reminder to join!
              </Text>
            </div>
          </div>
          <Button
            loading={isSendingReminderFromBanner}
            onClick={() => {
              const dunningStatus = checkAndHandleDunning();

              if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

              handleSendReminderFromBanner(filteredMembersBeforePagination);
            }}
            className="ml-5"
          >
            Send everyone a reminder
          </Button>
        </div>
      )}
      <div className="flex-grow">
        {filteredMembers.length === 0 ? (
          <div className="teams-users-list-empty flex w-full h-full items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="mb-6">{CurrentEmptyState.renderImg()}</div>
              <Text
                paragraph
                lg
                color="black/70"
                className="w-full text-center mb-7"
              >
                {CurrentEmptyState.text}
              </Text>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`miq-list-table users-list-table ${
                isInvitedTab ? "has-checkbox" : ""
              }`}
            >
              <div className="miq-list-table-header">
                {Object.keys(colsData).map((k) => {
                  const col = colsData[k];
                  let extraClass = col.extraClass;
                  if (!isInvitedTab && col.id === COLUMNS.SELECT) return;
                  if (isInvitedTab && col.id === COLUMNS.USER) {
                    extraClass = "";
                  }
                  if (col.isToggleAll && isInvitedTab) {
                    return (
                      <div
                        key={col.id}
                        className={`select-none cursor-pointer p-2 ${
                          extraClass || ""
                        }`}
                      >
                        <Checkbox
                          checked={areAllUsersSelected(filteredMembers)}
                          indeterminate={isAnyUserSelected(filteredMembers)}
                          onChange={toggleAll}
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      key={col.id}
                      className={`select-none cursor-pointer p-2 ${
                        extraClass || ""
                      }`}
                      onClick={() => handleColClicked(k)}
                    >
                      <div
                        className={`flex ${
                          col.alignRight ? "justify-end" : ""
                        }`}
                      >
                        <Text className="text-black/70">{col.title}</Text>
                        {col.sortable && sortByCol === k ? (
                          <Icon
                            name="sort"
                            color="black/50"
                            className={`ml-2 transform ${
                              sortByColOrder === SORT_ORDER.DESC
                                ? "rotate-0"
                                : "rotate-180"
                            }`}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="miq-list-table-body">
                {filteredMembers.map((m) => {
                  let statusText = getMemberStatusText(m);
                  const dateAdded = m.invitedAt
                    ? formatDate(m.invitedAt)
                    : null;

                  const isHovered = actingOn.includes(m.email);

                  return (
                    <div
                      key={m.email}
                      className={`miq-list-table-body-row cursor-default ${
                        isHovered ? "hovered" : ""
                      }`}
                    >
                      {isInvitedTab && (
                        <div className="p-2 pl-10">
                          <div className="flex items-center justify-start">
                            <Checkbox
                              checked={isUserSelected(m)}
                              onChange={() => toggleUser(m)}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={`relative p-2 ${isInvitedTab ? "" : "pl-8"}`}
                      >
                        <div className="flex items-center">
                          <MemberAvatar member={m} />
                          <div className="ml-4 w-3/4">
                            <TeamMemberName member={m} adminMeTag />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 desktop-version">
                        <Text>{statusText}</Text>
                      </div>
                      <div className="p-2 desktop-version">
                        <Text>{dateAdded}</Text>
                      </div>
                      <div className="p-2 pr-8">
                        <div className="flex items-center justify-end">
                          <Actions
                            member={m}
                            onSelect={onAction}
                            onToggle={(open) =>
                              handleToggleActionMenu(open, m.email)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex px-8 py-4 justify-between flex-wrap">
              <button
                className="team-members-list-invite-more-btn"
                onClick={handleInviteClicked}
              >
                <span
                  className="icon-wrap"
                  style={{ width: "40px", height: "40px" }}
                >
                  <Icon name="plus" color="white" />
                </span>
                <Text bold>
                  {currentFilter === FILTERS.ADMINS
                    ? "Invite admins to my team"
                    : "Invite users to my team"}
                </Text>
              </button>
              <Button
                ghost
                nohover
                icon="download"
                className="mt-4 px-0"
                onClick={handleDownloadUserList}
              >
                <Text semibold>Download full user list</Text>
              </Button>
            </div>
            <div className="my-10 pl-8 pr-5">
              <Pagination {...pagination} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

/**
 *
 * @param {{member: TeamMember }} props
 * @returns
 */
function Actions({ member, onToggle, onSelect }) {
  /** @type {{team: Team}} */
  const { team } = useContext(TeamContext);
  /** @type {User} */
  const { user: me } = useUser();
  const { checkAndHandleDunning } = useTeamsCTA();

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = async (key) => {
    handleToggle(false);
    switch (key) {
      case DRIVERS_LIST_ACTIONS.RESEND_INTIVE:
        await resendInvite(team.id, [member]);
        onSelect(key, member);
        break;
      case DRIVERS_LIST_ACTIONS.UPGRADE_TO_ADMIN_AND_DRIVER:
        onSelect(key, member);
        break;
      case DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_ADMIN:
        onSelect(key, member);
        break;
      case DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_DRIVER:
        onSelect(key, member);
        break;
      case DRIVERS_LIST_ACTIONS.REMOVE_FROM_TEAM:
        onSelect(key, member);
        break;
      default:
        onSelect(key, member);
        break;
    }
  };

  const handleToggle = (open) => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setIsOpen(open);
    onToggle(open);
  };

  const btnClasses =
    "w-full border-none outline-none hover:bg-beige active:bg-beige-medium flex items-center p-2.5 rounded";

  const isMe = me.id === member.id;
  const isOwner = member.id === team.owner.id;
  const isInvited = member.status === MEMBER_STATUS.INVITED;
  const isActive = member.status === MEMBER_STATUS.ACTIVE;

  let showUpgradeToAdminAndDriver =
    isActive &&
    ((member.isAdmin && !member.isDriver) ||
      (member.isDriver && !member.isAdmin));

  let showDowngradeToAdmin = isActive && member.isAdmin && member.isDriver;
  if (isOwner && me.id !== team.owner.id) {
    showDowngradeToAdmin = false; // do not let other admins to downgrade the owner
  }

  let showDowngradeToDriver =
    isActive && member.id !== me.id && member.isAdmin && member.isDriver;
  if (isOwner && me.id !== team.owner.id) {
    showDowngradeToDriver = false; // do not let other admins to downgrade the owner
  }

  let showResend = isInvited;

  let showRemove = member.id !== me.id;
  if (isOwner && me.id !== team.owner.id) {
    showRemove = false; // do not let other admins to remove the owner
  }

  const isPro = isTeamsProSubscription(team.subscription?.plan);

  if (
    !showUpgradeToAdminAndDriver &&
    !showDowngradeToAdmin &&
    !showDowngradeToDriver &&
    !showResend &&
    !showRemove
  ) {
    return null; // no available options
  }

  return (
    <Dropdown
      className="user-list-actions-picker ml-2"
      open={isOpen}
      onOpen={() => handleToggle(true)}
      onClose={() => handleToggle(false)}
      renderTrigger={() => <Icon name="ellipsis-vert" />}
      triggerClassName="shadow-none bg-transparent"
      contentClassName="right-0 top-10 p-2"
    >
      <ul className="p-0 m-0">
        {showUpgradeToAdminAndDriver ? (
          <>
            {isPro || isMe ? (
              <li>
                <button
                  className={btnClasses}
                  onClick={() =>
                    handleOptionSelect(
                      DRIVERS_LIST_ACTIONS.UPGRADE_TO_ADMIN_AND_DRIVER
                    )
                  }
                >
                  <Icon name="car-admin" color="black" className="mr-3 w-6" />
                  <Text>Upgrade to Admin & Driver</Text>
                </button>
              </li>
            ) : (
              <div
                className="flex items-center px-2.5 py-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon name="car-admin" color="black/50" className="mr-3" />
                <div className="flex flex-col">
                  <Text color="black/50">Upgrade to Admin & Driver</Text>
                  <Text md color="black/50" className="flex items-baseline">
                    Pro feature
                    <Button
                      link
                      className="text-blue text-13 ml-2 font-bold"
                      onClick={() =>
                        handleOptionSelect(DRIVERS_LIST_ACTIONS.UPGRADE_TO_PRO)
                      }
                    >
                      Upgrade to Pro
                    </Button>
                  </Text>
                </div>
              </div>
            )}
          </>
        ) : null}
        {showDowngradeToAdmin && (
          <li>
            <button
              className={btnClasses}
              onClick={() =>
                handleOptionSelect(DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_ADMIN)
              }
            >
              <Icon name="avatar-admin" color="black" className="mr-3 w-6" />
              <Text>Downgrade to Admin</Text>
            </button>
          </li>
        )}
        {showDowngradeToDriver && (
          <li>
            <button
              className={btnClasses}
              onClick={() =>
                handleOptionSelect(DRIVERS_LIST_ACTIONS.DOWNGRADE_TO_DRIVER)
              }
            >
              <Icon name="car" color="black" className="mr-3 w-6" />
              <Text>Downgrade to Driver</Text>
            </button>
          </li>
        )}
        {showResend && (
          <li>
            <button
              className={btnClasses}
              onClick={() =>
                handleOptionSelect(DRIVERS_LIST_ACTIONS.RESEND_INTIVE)
              }
            >
              <Icon
                name="paper-plane-outline"
                color="black"
                className="mr-3 w-6"
              />
              <Text>Resend invitation</Text>
            </button>
          </li>
        )}
        {showRemove && (
          <li>
            <button
              className={btnClasses}
              onClick={() =>
                handleOptionSelect(DRIVERS_LIST_ACTIONS.REMOVE_FROM_TEAM)
              }
            >
              <Icon name="close-in-circle" color="black" className="mr-3 w-6" />
              <Text>
                {member.status === MEMBER_STATUS.INVITED
                  ? "Revoke invitation"
                  : "Remove from team"}
              </Text>
            </button>
          </li>
        )}
      </ul>
    </Dropdown>
  );
}
