import React from "react";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

import { formatCurrency, toUIDistance } from "src/services/utils";

const Header = ({ totalDrives, value, distance, onClose, currency, unit }) => (
  <>
    <div className="relative flex flex-col gap-[6px] pt-[15px]">
      <Text>{totalDrives} drives selected</Text>
      <h5>
        <span className="drive-value">
          {formatCurrency({ value, currency })}
        </span>
        {toUIDistance(distance, unit).toFixed(1)} {unit}
      </h5>
      <Button
        data-testid="header-onClose"
        ghost
        icon="close"
        onClick={onClose}
        className="close-btn absolute right-0 top-0"
      />
    </div>
  </>
);

export default Header;
