import {
  formatIncompletePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { CountryFlag } from "src/components/elements/CountryFlag";
import Text from "src/components/elements/Text";

import { COUNTRIES, COUNTRIES_DATA } from "src/services/utils";

import Icon from "./Icon";

const countries = Object.values(COUNTRIES)
  .map((country) => ({
    code: country,
    name: COUNTRIES_DATA[country].name,
    phoneCallingCode: COUNTRIES_DATA[country].phoneCallingCode,
  }))
  // this is respect to the design, but for longer list, better to use name comparison
  .sort((a, b) => a.phoneCallingCode - b.phoneCallingCode);

const MiqPhoneInput = React.forwardRef(
  (
    {
      className,
      sm,
      valid,
      style,
      label,
      onChange,
      onBlur,
      onFocus,
      disabled,
      defaultCountry,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const [country, setCountry] = useState(defaultCountry);
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");

    let classes = ["miq-input miq-phone-number-input"];

    if (className) classes.push(className);

    if (sm) classes.push("miq-input-small");

    if (!valid) classes.push("invalid");
    if (disabled) classes.push("disabled");

    if (focused) classes.push("focused");

    if (disabled) classes.push("disabled");

    let otherProps = { ...rest };

    // if no onChange handler provided
    // make input uncontrolled
    if (!onChange) {
      otherProps.defaultValue = value?.toString();
    } else {
      otherProps.value = value?.toString();
    }

    const handleChange = (e) => {
      const value = e.target.value;
      let pn;
      try {
        pn = parsePhoneNumber(value, country);
        if (pn) {
          const formatted = formatIncompletePhoneNumber(value, country);
          setValue(formatted);
        }
      } catch (err) {
        setValue(value);
      } finally {
        if (typeof onChange === "function") {
          onChange({
            value,
            country,
            number: pn?.number,
            formatted: pn && formatIncompletePhoneNumber(value, country),
            isValid: pn?.isValid(),
          });
        }
      }
    };

    let theInput = (
      <div className={`relative overflow-visible ${classes.join(" ")}`}>
        <CountrySelect hide={focused} onChange={setCountry} />
        <input
          ref={ref}
          style={style}
          disabled={disabled}
          placeholder={placeholder}
          {...otherProps}
          autoFocus
          type="tel"
          onChange={handleChange}
          onBlur={(e) => {
            setFocused(false);
            if (typeof onBlur === "function") {
              onBlur(e);
            }
          }}
          onFocus={(e) => {
            setFocused(true);
            if (typeof onFocus === "function") {
              onFocus(e);
            }
          }}
        />
      </div>
    );

    if (label) {
      theInput = (
        <label>
          <Text semibold className="inline-block mb-2">
            {label}
          </Text>
          {theInput}
        </label>
      );
    }

    return theInput;
  }
);

export { MiqPhoneInput as PhoneInput };

MiqPhoneInput.propTypes = {
  sm: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string,
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string,
};

MiqPhoneInput.defaultProps = {
  sm: false,
  valid: true,
  className: "",
  defaultCountry: COUNTRIES.US,
  placeholder: "Phone number",
};

const CountrySelect = ({ hide, defaultCountry, onChange }) => {
  const [showSelect, setShowSelect] = useState(hide);
  const [selected, setSelected] = useState(defaultCountry);

  const toggle = () => setShowSelect((cur) => !cur);

  const handleChange = (country) => {
    setSelected(country);
    setShowSelect(false);
    if (typeof onChange === "function") {
      onChange(country);
    }
  };

  useEffect(() => {
    if (hide && showSelect) setShowSelect(false);
  }, [hide]);

  return (
    <div className={`miq-phone-country-wrapper ${showSelect ? "is-open" : ""}`}>
      <span className="miq-phone-country-current" onClick={toggle}>
        <CountryFlag country={selected} className="inline mr-2" />
        <Icon name="sort" />
      </span>
      <div className="miq-phone-country-selector">
        {countries.map(({ code, name, phoneCallingCode }) => (
          <div
            key={code}
            className="miq-phone-country-item font-medium"
            onClick={() => handleChange(code)}
          >
            <CountryFlag country={code} className={"inline mr-2"} />
            {name} (+
            {phoneCallingCode})
          </div>
        ))}
      </div>
    </div>
  );
};

CountrySelect.propTypes = {
  hide: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
};

CountrySelect.defaultProps = {
  hide: true,
  defaultCountry: COUNTRIES.US,
};
