import { loadStripe } from "@stripe/stripe-js";
import { isBefore, startOfToday } from "date-fns";
import { createContext, useContext, useEffect, useState } from "react";

import { report } from "src/services/error-reporting";
import { NETWORK_STATES } from "src/services/http";
import {
  getDriverDunningExpiredSubscriptionModalLastSeen,
  getDriverDunningPaymentFailedModalLastSeen,
  getDunningExpiredSubscriptionModalLastSeen,
  getDunningPaymentFailedModalLastSeen,
} from "src/services/storage";
import { getPaymentIntent } from "src/services/teams";

import TeamContext from "./TeamContext";
import { UserDataContext } from "./UserContext";

export const TeamDunningContext = createContext();

export function useTeamDunning() {
  return useContext(TeamDunningContext);
}

export const TeamDunningProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [createPaymentIntentNetworkState, setCreatePaymentIntentNetworkState] =
    useState(NETWORK_STATES.IDLE);

  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.STRIPE_PUBLIC_KEY));
  }, []);

  const {
    isInGracePeriod = false,
    isExpired = false,
    daysUntilGracePeriodEnds = 0,
  } = team || {};

  const dayLabel = daysUntilGracePeriodEnds > 1 ? "days" : "day";

  const shouldShowPaymentFailedModal = () => {
    const lastSeenAt = getDunningPaymentFailedModalLastSeen(team?.id);

    if (lastSeenAt) {
      const lastSeenDate = new Date(parseInt(lastSeenAt));
      const today = startOfToday();

      return isInGracePeriod && isBefore(lastSeenDate, today);
    }

    return isInGracePeriod;
  };

  const shouldShowExpiredSubscriptionModal = () => {
    const lastSeenAt = getDunningExpiredSubscriptionModalLastSeen(team?.id);

    if (lastSeenAt) {
      const lastSeenDate = new Date(parseInt(lastSeenAt));
      const today = startOfToday();

      return isExpired && isBefore(lastSeenDate, today);
    }

    return isExpired;
  };

  const shouldShowDriversPaymentFailedModal = () => {
    const lastSeenAt = getDriverDunningPaymentFailedModalLastSeen(
      team?.id,
      userData?.id
    );

    const isHalfwayThroughGracePeriod = daysUntilGracePeriodEnds <= 7;

    if (lastSeenAt) {
      const lastSeenDate = new Date(parseInt(lastSeenAt));
      const today = startOfToday();

      return (
        isInGracePeriod &&
        isHalfwayThroughGracePeriod &&
        isBefore(lastSeenDate, today)
      );
    }

    return isInGracePeriod && isHalfwayThroughGracePeriod;
  };

  const shouldShowDriversExpiredSubscriptionModal = () => {
    const lastSeenAt = getDriverDunningExpiredSubscriptionModalLastSeen(
      team?.id,
      userData?.id
    );

    if (lastSeenAt) {
      const lastSeenDate = new Date(parseInt(lastSeenAt));
      const today = startOfToday();

      return isExpired && isBefore(lastSeenDate, today);
    }

    return isExpired;
  };

  const executeCreatePaymentIntent = async () => {
    try {
      setCreatePaymentIntentNetworkState(NETWORK_STATES.LOADING);

      const paymentIntent = await getPaymentIntent();

      setPaymentIntent(paymentIntent);
      setCreatePaymentIntentNetworkState(NETWORK_STATES.LOADED);

      return true;
    } catch (error) {
      report(error);

      setCreatePaymentIntentNetworkState(NETWORK_STATES.ERROR);

      return false;
    }
  };

  const resetDunningState = () => {
    setPaymentIntent(null);
    setCreatePaymentIntentNetworkState(NETWORK_STATES.IDLE);
  };

  return (
    <TeamDunningContext.Provider
      value={{
        shouldShowPaymentFailedModal,
        shouldShowExpiredSubscriptionModal,
        shouldShowDriversPaymentFailedModal,
        shouldShowDriversExpiredSubscriptionModal,
        executeCreatePaymentIntent,
        resetDunningState,
        stripePromise,
        paymentIntent,
        createPaymentIntentNetworkState,
        daysUntilGracePeriodEnds,
        dayLabel,
      }}
    >
      {children}
    </TeamDunningContext.Provider>
  );
};
