import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import SetConcurIdentityForm from "../blocks/integrations/SetConcurIdentityForm";

export default SetConcurIdentityModal;

export const ELEMENT_ID = "SET_CONCUR_IDENTITY_MODAL";
registerElement(ELEMENT_ID, SetConcurIdentityModal);

function SetConcurIdentityModal({ onClose, onSuccess }) {
  return (
    <Modal closable className="w-[450px]" onClose={onClose}>
      <SetConcurIdentityForm onSuccess={onSuccess} />
    </Modal>
  );
}
