import pe from "../in-app-message-manager-factory.js";
import e from "../../managers/braze-instance.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import { showInAppMessage } from "./show-in-app-message.js";
import { subscribeToInAppMessage } from "../subscribe-to-in-app-message.js";
export function automaticallyShowInAppMessages() {
  if (!e.rr()) return;
  ss();
  const s = pe.m();
  if (null == s.Xe()) {
    const r = subscribeToInAppMessage(s => showInAppMessage(s));
    s.Ze(r);
  }
  return s.Xe();
}
