import { addDays, format } from "date-fns";
import React, { useEffect } from "react";

import { trackTeamsReverseTrialHowItWorksPageViewed } from "src/services/tracking";

import IllustrationHowItWorks from "public/assets/img/reverse-trial/how-it-works.png";
import IllustrationHowItWorks2x from "public/assets/img/reverse-trial/how-it-works@2x.png";

import { FeaturingTimelineTemplate } from "../templates/FeaturingTimelineTemplate";

export function ReverseTrialHowItWorksScreen({
  daysInTrial,
  handleStartTrial,
  handleBack,
}) {
  useEffect(() => {
    trackTeamsReverseTrialHowItWorksPageViewed();
  }, []);
  return (
    <FeaturingTimelineTemplate
      title={"How it works"}
      items={[
        {
          isActive: true,
          title: "Free trial starts today!",
          subtitle:
            "No card required. You can still access and manage your individual plan. ",
        },
        {
          title: "Invite your team",
          subtitle:
            "Invite unlimited drivers and test out all that Teams has to offer. Past drives and reports won't be affected.",
        },
        {
          title: () =>
            `Trial ends on ${format(
              addDays(new Date(), daysInTrial),
              "MMM dd"
            )}`,
          subtitle:
            "Upgrade to the Teams plan that's best for you. If you don't want to upgrade, your account automatically goes back to an individual plan.",
        },
      ]}
      illustration={
        <img
          className="w-full h-full object-contain"
          src={IllustrationHowItWorks}
          srcSet={`${IllustrationHowItWorks} 300w, ${IllustrationHowItWorks2x} 600px`}
          sizes="(max-width: 400px) 300w, 600px"
          alt="illustration"
        />
      }
      primaryCTA={{ label: "Start free trial", onClick: handleStartTrial }}
      onBack={handleBack}
    />
  );
}
