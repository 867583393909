import React from "react";
import { useState } from "react";

import Text from "src/components/elements/Text";

export default MiqTextarea;

function MiqTextarea({
  testId,
  label,
  onFocus,
  onBlur,
  className,
  height,
  maxHeight,
  ...rest
}) {
  const [focused, setFocused] = useState(false);

  let theTextarea = (
    <div
      data-testid={testId || "textarea"}
      style={{
        maxHeight: maxHeight ? `${maxHeight}px` : "auto",
        minHeight: height ? `${height}px` : 0,
      }}
      className={`miq-textarea ${focused ? "textarea-focused" : ""} ${
        className || ""
      }`}
    >
      <textarea
        className="textarea"
        style={{
          height,
          maxHeight: maxHeight ? `${maxHeight}px` : "auto",
          minHeight: height ? `${height}px` : 0,
        }}
        onFocus={(e) => {
          setFocused(true);
          if (typeof onFocus == "function") onFocus(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          if (typeof onBlur == "function") onBlur(e);
        }}
        {...rest}
      />
    </div>
  );

  if (label) {
    theTextarea = (
      <label>
        <Text semibold className="inline-block mb-[10px]">
          {label}
        </Text>
        {theTextarea}
      </label>
    );
  }
  return theTextarea;
}
