import ot from "../util/browser-detector.js";
import ue from "../models/braze-event.js";
import _t from "../models/identifier.js";
import {
  isArray as p,
  keys as rr,
  validateValueIsFromEnum as F,
  values as At
} from "../util/code-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { User } from "../User/index.js";
export const STORAGE_KEYS = {
  eu: {
    su: "ab.storage.userId",
    Jo: "ab.storage.deviceId",
    ph: "ab.storage.sessionId"
  },
  q: {
    Sa: "ab.test",
    Ta: "ab.storage.events",
    Ia: "ab.storage.attributes",
    Ka: "ab.storage.attributes.anonymous_user",
    Yo: "ab.storage.device",
    ua: "ab.storage.sdk_metadata",
    ha: "ab.storage.session_id_for_cached_metadata",
    Pn: "ab.storage.pushToken",
    ei: "ab.storage.newsFeed",
    hi: "ab.storage.lastNewsFeedRefresh",
    P: "ab.storage.cardImpressions",
    ol: "ab.storage.serverConfig",
    Ya: "ab.storage.triggers",
    Da: "ab.storage.triggers.ts",
    wh: "ab.storage.messagingSessionStart",
    Yt: "ab.storage.cc",
    vs: "ab.storage.ccLastFullSync",
    ys: "ab.storage.ccLastCardUpdated",
    $: "ab.storage.ccClicks",
    O: "ab.storage.ccImpressions",
    K: "ab.storage.ccDismissals",
    Ga: "ab.storage.lastDisplayedTriggerTimesById",
    Na: "ab.storage.lastDisplayedTriggerTime",
    va: "ab.storage.triggerFireInstancesById",
    Mn: "ab.storage.signature",
    As: "ab.storage.brazeSyncRetryCount",
    _s: "ab.storage.sdkVersion",
    ze: "ab.storage.ff",
    qe: "ab.storage.ffLastRefreshAt"
  },
  ae: "ab.optOut"
};
export default class G {
  constructor(t, e) {
    (this.Ma = t), (this.Ca = e);
  }
  ru(t, e) {
    let s = e;
    null != e && e instanceof _t && (s = e.ss()), this.Ma.store(t, s);
  }
  ya(t) {
    const e = this.tu(t);
    null != e && ((e.bh = new Date().valueOf()), this.ru(t, e));
  }
  tu(t) {
    return _t.Sn(this.Ma.lr(t));
  }
  Nh(t) {
    this.Ma.remove(t);
  }
  bo(t) {
    if (null == t || 0 === t.length) return !1;
    p(t) || (t = [t]);
    let e = this.Ca.lr(STORAGE_KEYS.q.Ta);
    (null != e && p(e)) || (e = []);
    for (let s = 0; s < t.length; s++) e.push(t[s].ss());
    return this.Ca.store(STORAGE_KEYS.q.Ta, e);
  }
  Dh(t) {
    return null != t && this.bo([t]);
  }
  Ua() {
    let t = this.Ca.lr(STORAGE_KEYS.q.Ta);
    this.Ca.remove(STORAGE_KEYS.q.Ta), null == t && (t = []);
    const e = [];
    let s = !1,
      o = null;
    if (p(t))
      for (let s = 0; s < t.length; s++)
        ue.Fa(t[s]) ? e.push(ue.Sn(t[s])) : (o = s);
    else s = !0;
    if (s || null != o) {
      let n = "Stored events could not be deserialized as Events";
      s &&
        (n += ", was " + Object.prototype.toString.call(t) + " not an array"),
        null != o &&
          (n += ", value at index " + o + " does not look like an event"),
        (n +=
          ", serialized values were of type " +
          typeof t +
          ": " +
          JSON.stringify(t)),
        e.push(new ue(null, r.A.qs, new Date().valueOf(), null, { e: n }));
    }
    return e;
  }
  B(t, e) {
    return (
      !!F(
        STORAGE_KEYS.q,
        t,
        "StorageManager cannot store object.",
        "STORAGE_KEYS.OBJECTS"
      ) && this.Ca.store(t, e)
    );
  }
  I(t) {
    return (
      !!F(
        STORAGE_KEYS.q,
        t,
        "StorageManager cannot retrieve object.",
        "STORAGE_KEYS.OBJECTS"
      ) && this.Ca.lr(t)
    );
  }
  Xs(t) {
    return (
      !!F(
        STORAGE_KEYS.q,
        t,
        "StorageManager cannot remove object.",
        "STORAGE_KEYS.OBJECTS"
      ) && (this.Ca.remove(t), !0)
    );
  }
  clearData() {
    const t = rr(STORAGE_KEYS.eu),
      e = rr(STORAGE_KEYS.q);
    for (let e = 0; e < t.length; e++) {
      const s = t[e];
      this.Ma.remove(STORAGE_KEYS.eu[s]);
    }
    for (let t = 0; t < e.length; t++) {
      const s = e[t];
      this.Ca.remove(STORAGE_KEYS.q[s]);
    }
  }
  La(t) {
    return t || STORAGE_KEYS.q.Ka;
  }
  Aa(t) {
    let e = this.Ca.lr(STORAGE_KEYS.q.Ia);
    null == e && (e = {});
    const s = this.La(t[User.Rn]);
    for (let r in t)
      r === User.Rn ||
        (null != e[s] && null != e[s][r]) ||
        this.mu(t[User.Rn], r, t[r]);
  }
  mu(t, e, s) {
    let r = this.Ca.lr(STORAGE_KEYS.q.Ia);
    null == r && (r = {});
    const o = this.La(t);
    let n = r[o];
    if (
      (null == n && ((n = {}), null != t && (n[User.Rn] = t)), e === User.lu)
    ) {
      null == n[e] && (n[e] = {});
      for (let t in s) n[e][t] = s[t];
    } else n[e] = s;
    return (r[o] = n), this.Ca.store(STORAGE_KEYS.q.Ia, r);
  }
  Ba() {
    const t = this.Ca.lr(STORAGE_KEYS.q.Ia);
    this.Ca.remove(STORAGE_KEYS.q.Ia);
    const e = [];
    for (let s in t) null != t[s] && e.push(t[s]);
    return e;
  }
  ou(t) {
    const e = this.Ca.lr(STORAGE_KEYS.q.Ia);
    if (null != e) {
      const s = this.La(null),
        r = e[s];
      null != r &&
        ((e[s] = void 0),
        this.Ca.store(STORAGE_KEYS.q.Ia, e),
        (r[User.Rn] = t),
        this.Aa(r));
    }
    const s = this.tu(STORAGE_KEYS.eu.ph);
    let r = null;
    null != s && (r = s.iu);
    const o = this.Ua();
    if (null != o)
      for (let e = 0; e < o.length; e++) {
        const s = o[e];
        null == s.userId && s.sessionId == r && (s.userId = t), this.Dh(s);
      }
  }
  Pa() {
    return this.Ca.xa;
  }
}
G._a = class {
  constructor(t) {
    (this._r = t), (this.xa = ot.Ja() ? 3 : 10);
  }
  ka(t) {
    return t + "." + this._r;
  }
  store(t, e) {
    const s = { v: e };
    try {
      return localStorage.setItem(this.ka(t), JSON.stringify(s)), !0;
    } catch (t) {
      return r.D.info("Storage failure: " + t.message), !1;
    }
  }
  lr(t) {
    try {
      const e = JSON.parse(localStorage.getItem(this.ka(t)));
      return null == e ? null : e.v;
    } catch (t) {
      return r.D.info("Storage retrieval failure: " + t.message), null;
    }
  }
  remove(t) {
    try {
      localStorage.removeItem(this.ka(t));
    } catch (t) {
      return r.D.info("Storage removal failure: " + t.message), !1;
    }
  }
};
G.ne = class {
  constructor(t, e) {
    (this._r = t), (this.Va = this.Qa()), (this.Ha = 576e3), (this.Wa = !!e);
  }
  ka(t) {
    return null != this._r ? t + "." + this._r : t;
  }
  Qa() {
    let t = 0,
      e = document.location.hostname;
    const s = e.split("."),
      r = "ab._gd";
    for (; t < s.length - 1 && -1 === document.cookie.indexOf(r + "=" + r); )
      t++,
        (e = "." + s.slice(-1 - t).join(".")),
        (document.cookie = r + "=" + r + ";domain=" + e + ";");
    return (
      (document.cookie =
        r + "=;expires=" + new Date(0).toGMTString() + ";domain=" + e + ";"),
      e
    );
  }
  ie() {
    const t = new Date();
    return t.setTime(t.getTime() + 60 * this.Ha * 1e3), t.getFullYear();
  }
  Xa() {
    const t = At(STORAGE_KEYS.eu),
      e = document.cookie.split(";");
    for (let s = 0; s < e.length; s++) {
      let r = e[s];
      for (; " " === r.charAt(0); ) r = r.substring(1);
      let o = !1;
      for (let e = 0; e < t.length; e++)
        if (0 === r.indexOf(t[e])) {
          o = !0;
          break;
        }
      if (o) {
        const t = r.split("=")[0];
        -1 === t.indexOf("." + this._r) && this.Za(t);
      }
    }
  }
  store(t, e) {
    this.Xa();
    const s = new Date();
    s.setTime(s.getTime() + 60 * this.Ha * 1e3);
    const o = "expires=" + s.toUTCString(),
      n = "domain=" + this.Va;
    let i;
    i = this.Wa ? e : encodeURIComponent(JSON.stringify(e));
    const a = this.ka(t) + "=" + i + ";" + o + ";" + n + ";path=/";
    return a.length >= 4093
      ? (r.D.info(
          "Storage failure: string is " +
            a.length +
            " chars which is too large to store as a cookie."
        ),
        !1)
      : ((document.cookie = a), !0);
  }
  lr(t) {
    const e = [],
      s = this.ka(t) + "=",
      o = document.cookie.split(";");
    for (let n = 0; n < o.length; n++) {
      let i = o[n];
      for (; " " === i.charAt(0); ) i = i.substring(1);
      if (0 === i.indexOf(s))
        try {
          let t;
          (t = this.Wa
            ? i.substring(s.length, i.length)
            : JSON.parse(decodeURIComponent(i.substring(s.length, i.length)))),
            e.push(t);
        } catch (e) {
          return (
            r.D.info("Storage retrieval failure: " + e.message),
            this.remove(t),
            null
          );
        }
    }
    return e.length > 0 ? e[e.length - 1] : null;
  }
  remove(t) {
    this.Za(this.ka(t));
  }
  Za(t) {
    const e = t + "=;expires=" + new Date(0).toGMTString();
    (document.cookie = e), (document.cookie = e + ";path=/");
    const s = e + ";domain=" + this.Va;
    (document.cookie = s), (document.cookie = s + ";path=/");
  }
};
G.Oa = class {
  constructor() {
    (this.$a = {}), (this.El = 5242880), (this.xa = 3);
  }
  store(t, e) {
    const s = { value: e },
      o = this.Sl(e);
    return o > this.El
      ? (r.D.info(
          "Storage failure: object is ≈" +
            o +
            " bytes which is greater than the max of " +
            this.El
        ),
        !1)
      : ((this.$a[t] = s), !0);
  }
  Sl(t) {
    const e = [],
      s = [t];
    let r = 0;
    for (; s.length; ) {
      const t = s.pop();
      if ("boolean" == typeof t) r += 4;
      else if ("string" == typeof t) r += 2 * t.length;
      else if ("number" == typeof t) r += 8;
      else if ("object" == typeof t && -1 === e.indexOf(t)) {
        e.push(t);
        for (let e in t) s.push(t[e]);
      }
    }
    return r;
  }
  lr(t) {
    const e = this.$a[t];
    return null == e ? null : e.value;
  }
  remove(t) {
    this.$a[t] = null;
  }
};
G.wa = class {
  constructor(t, e, s) {
    (this._l = []),
      e && this._l.push(new G.ne(t)),
      s && this._l.push(new G._a(t)),
      this._l.push(new G.Oa(t));
  }
  store(t, e) {
    let s = !0;
    for (let r = 0; r < this._l.length; r++) s = this._l[r].store(t, e) && s;
    return s;
  }
  lr(t) {
    for (let e = 0; e < this._l.length; e++) {
      const s = this._l[e].lr(t);
      if (null != s) return s;
    }
    return null;
  }
  remove(t) {
    for (let e = 0; e < this._l.length; e++) this._l[e].remove(t);
  }
};
