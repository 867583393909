import {
  dateFromUnixTimestamp as h,
  rehydrateDateAfterJsonization as w
} from "../../util/date-utils.js";
import r from "../../../shared-lib/braze-shared-lib.js";
import Kt from "./trigger-condition.js";
import { validateValueIsFromEnum as F } from "../../util/code-utils.js";
export default class mt {
  constructor(t, i, s, e, r, l, o, n, h, a, u, d) {
    (this.id = t),
      (this.Ph = i || []),
      void 0 === s && (s = null),
      (this.startTime = s),
      void 0 === e && (e = null),
      (this.endTime = e),
      (this.priority = r || 0),
      (this.type = l),
      (this.$h = n || 0),
      null == a && (a = 1e3 * (this.$h + 30)),
      (this.$i = a),
      (this.data = o),
      null == h && (h = mt.Vh),
      (this.qh = h),
      (this.Kh = u),
      (this.Wh = d || null);
  }
  Yh(t) {
    return (
      null == this.Wh || (this.qh !== mt.Vh && t - this.Wh >= 1e3 * this.qh)
    );
  }
  Zh(t) {
    this.Wh = t;
  }
  gu(t) {
    const i = t + 1e3 * this.$h;
    return Math.max(i - new Date().valueOf(), 0);
  }
  pu(t) {
    const i = new Date().valueOf() - t,
      s = null == t || isNaN(i) || null == this.$i || i < this.$i;
    return (
      s ||
        r.D.info(
          `Trigger action ${this.type} is no longer eligible for display - fired ${i}ms ago and has a timeout of ${this.$i}ms.`
        ),
      !s
    );
  }
  static fromJson(t) {
    const i = t.id,
      s = [];
    for (let i = 0; i < t.trigger_condition.length; i++)
      s.push(Kt.fromJson(t.trigger_condition[i]));
    const e = h(t.start_time),
      r = h(t.end_time),
      l = t.priority,
      o = t.type,
      n = t.delay,
      a = t.re_eligibility,
      u = t.timeout,
      d = t.data,
      m = t.min_seconds_since_last_trigger;
    return F(
      mt._i,
      o,
      "Could not construct Trigger from server data",
      "Trigger.Types"
    )
      ? new mt(i, s, e, r, l, o, d, n, a, u, m)
      : null;
  }
  ss() {
    const t = [];
    for (let i = 0; i < this.Ph.length; i++) t.push(this.Ph[i].ss());
    return {
      i: this.id,
      c: t,
      s: this.startTime,
      e: this.endTime,
      p: this.priority,
      t: this.type,
      da: this.data,
      d: this.$h,
      r: this.qh,
      tm: this.$i,
      ss: this.Kh,
      ld: this.Wh
    };
  }
  static Sn(t) {
    const i = [];
    for (let s = 0; s < t.c.length; s++) i.push(Kt.Sn(t.c[s]));
    return new mt(
      t.i,
      i,
      w(t.s),
      w(t.e),
      t.p,
      t.t,
      t.da,
      t.d,
      t.r,
      t.tm,
      t.ss,
      t.ld
    );
  }
}
(mt.Vh = -1), (mt._i = { Ti: "inapp", Tu: "templated_iam" });
