import { ControlCard } from "../Card/index.js";
export const FEED_ANIMATION_DURATION = 500;
export default class x {
  constructor(e, r) {
    (this.cards = e), (this.lastUpdated = r);
  }
  getUnreadCardCount() {
    let e = 0;
    for (const r of this.cards) r.viewed || r instanceof ControlCard || e++;
    return e;
  }
  dr() {
    throw new Error("Must be implemented in a subclass");
  }
  logCardImpressions() {
    throw new Error("Must be implemented in subclass");
  }
  logCardClick() {
    throw new Error("Must be implemented in subclass");
  }
  sr() {
    throw new Error("Must be implemented in subclass");
  }
}
(x.ur = 6e4), (x.Ki = 500), (x.uo = 1e4);
