import e from "../managers/braze-instance.js";
import HtmlMessage from "./models/html-message.js";
import pe from "./in-app-message-manager-factory.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageHtmlClick(s, t, o) {
  if (!e.rr()) return !1;
  if (!(s instanceof HtmlMessage))
    return (
      r.D.error(
        "inAppMessage argument to logInAppMessageHtmlClick must be an HtmlMessage object."
      ),
      !1
    );
  let m = r.A.pi;
  null != t && (m = r.A.mi);
  const i = pe.m().G(s, m, t, o);
  if (i.S)
    for (let r = 0; r < i.ve.length; r++)
      W.er().je(V.Ar, [s.triggerId, t], i.ve[r]);
  return i.S;
}
