import { isDate as yt } from "../../util/code-utils.js";
import {
  convertMsToSeconds as l,
  dateFromUnixTimestamp as h,
  secondsAgo as Ht,
  secondsInTheFuture as Ut
} from "../../util/date-utils.js";
export default class Gt {
  constructor(t, s, e, i) {
    (this.Xl = t),
      (this.Vl = s),
      (this.comparator = e),
      (this.Yl = i),
      this.Vl === Gt.Th.Zl &&
        this.comparator !== Gt._h.Ah &&
        this.comparator !== Gt._h.yh &&
        this.comparator !== Gt._h.Mh &&
        this.comparator !== Gt._h.Rh &&
        (this.Yl = h(this.Yl));
  }
  Hl(t) {
    let s = null;
    switch ((null != t && (s = t[this.Xl]), this.comparator)) {
      case Gt._h.Hh:
        return null != s && s.valueOf() === this.Yl.valueOf();
      case Gt._h.Lh:
        return null == s || s.valueOf() !== this.Yl.valueOf();
      case Gt._h.Ih:
        return typeof s == typeof this.Yl && s > this.Yl;
      case Gt._h.Ah:
        return this.Vl === Gt.Th.Zl
          ? null != s && yt(s) && Ht(s) <= this.Yl
          : typeof s == typeof this.Yl && s >= this.Yl;
      case Gt._h.Oh:
        return typeof s == typeof this.Yl && s < this.Yl;
      case Gt._h.yh:
        return this.Vl === Gt.Th.Zl
          ? null != s && yt(s) && Ht(s) >= this.Yl
          : typeof s == typeof this.Yl && s <= this.Yl;
      case Gt._h.Uh:
        return (
          null != s &&
          "string" == typeof s &&
          typeof s == typeof this.Yl &&
          null != s.match(this.Yl)
        );
      case Gt._h.Qh:
        return null != s;
      case Gt._h.Xh:
        return null == s;
      case Gt._h.Mh:
        return null != s && yt(s) && Ut(s) < this.Yl;
      case Gt._h.Rh:
        return null != s && yt(s) && Ut(s) > this.Yl;
      case Gt._h.Bh:
        return (
          null == s ||
          typeof s != typeof this.Yl ||
          "string" != typeof s ||
          null == s.match(this.Yl)
        );
    }
    return !1;
  }
  static fromJson(t) {
    return new Gt(
      t.property_key,
      t.property_type,
      t.comparator,
      t.property_value
    );
  }
  ss() {
    let t = this.Yl;
    return (
      yt(this.Yl) && (t = l(t.valueOf())),
      { k: this.Xl, t: this.Vl, c: this.comparator, v: t }
    );
  }
  static Sn(t) {
    return new Gt(t.k, t.t, t.c, t.v);
  }
}
(Gt._h = {
  Hh: 1,
  Lh: 2,
  Ih: 3,
  Ah: 4,
  Oh: 5,
  yh: 6,
  Uh: 10,
  Qh: 11,
  Xh: 12,
  Mh: 15,
  Rh: 16,
  Bh: 17
}),
  (Gt.Th = { Fh: "boolean", kh: "number", Jh: "string", Zl: "date" });
