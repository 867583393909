import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
} from "src/models/team-subscription";
import { USER_TYPES, USER_TYPES_DATA } from "src/models/user";

import { isValidEmail } from "src/services/utils";

export default MiqInviteUserTag;

function MiqInviteUserTag({
  onClose,
  onSelectType,
  onUpgrade,
  onEmailChange,
  tag,
  forceInvalid,
  hideDisabledRoleOptions,
  subscription,
}) {
  const [content, setContent] = useState(tag.email);
  const containerRef = useRef();
  const inputRef = useRef();

  const isEditing = tag.focus === true;

  const onClickOutside = useCallback(
    (e) => {
      if (!containerRef?.current) return;
      if (isEditing && !containerRef.current.contains(e.target)) {
        onEmailChange(inputRef.current.value);
      }
    },
    [isEditing]
  );

  useEffect(() => {
    if (isEditing) {
      document.body.addEventListener("mousedown", onClickOutside);
    } else {
      document.body.removeEventListener("mousedown", onClickOutside);
    }

    return () => document.body.removeEventListener("mousedown", onClickOutside);
  }, [tag]);

  if (!tag) return null;

  const isValid = isEditing || (!forceInvalid && isValidEmail(tag.email));

  return (
    <span
      ref={containerRef}
      className={`miq-tag flex items-center h-9 ${
        !isValid
          ? "border-2 border-red bg-red-pastel"
          : USER_TYPES_DATA[tag.userType].className
      } px-3 rounded m-1`}
      onClick={(e) => e.stopPropagation()}
    >
      <Icon
        className="miq-tag-close mr-2 cursor-pointer"
        name="close-small"
        color="black"
        onClick={onClose}
      />
      <span className="relative">
        <span className="whitespace-pre text-transparent">{content}</span>
        <input
          autoFocus={isEditing}
          disabled={!isEditing}
          ref={inputRef}
          className="w-full absolute top-0 left-0 bg-transparent border-none outline-none disabled:cursor-default"
          onChange={(e) => setContent(e.target.value)}
          onKeyDown={(e) => {
            if (["Enter", ",", " ", "Tab"].includes(e.key)) {
              e.preventDefault();
              onEmailChange(e.target.value);
            } else if (e.key === "Backspace" && e.target.value === "") {
              onClose();
            }
          }}
          value={content}
        />
      </span>
      {!isValid ? (
        <Icon name="alert" color="red" className="ml-2" />
      ) : (
        <UserRolePicker
          selected={tag.userType}
          onSelect={(t) => onSelectType(t)}
          onUpgrade={(plan) => {
            onUpgrade(plan, content);
          }}
          hideDisabledRoleOptions={hideDisabledRoleOptions}
          subscription={subscription}
          dropdownOptions={{
            position: "bottom",
          }}
        />
      )}
    </span>
  );
}

MiqInviteUserTag.defaultProps = {
  onClose: () => {},
  onSelectType: () => {},
  onUpgrade: () => {},
  onEmailChange: () => {},
  tag: null,
};

MiqInviteUserTag.propTypes = {
  onClose: PropTypes.func,
  onSelectType: PropTypes.func,
  onUpgrade: PropTypes.func,
  onEmailChange: PropTypes.func,
  tag: PropTypes.object,
};

export function UserRolePicker({
  selected,
  onSelect,
  onUpgrade,
  hideDisabledRoleOptions,
  subscription, // used to override current team subscription
  dropdownOptions,
  disabled,
  excludeTypes,
  ignoreSubscription,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { team } = useContext(TeamContext);
  const plan =
    subscription?.plan ||
    team?.pendingSubscription?.plan ||
    team?.subscription?.plan;

  const handleTypeSelect = (k) => {
    onSelect(k);
    setIsOpen(false);
  };

  const handleUpgradeClicked = (plan, k) => {
    if (!team?.subscription) {
      setTimeout(() => {
        onSelect(k);
      }, 500);
    }

    onUpgrade(plan);
  };

  const label = USER_TYPES_DATA[selected]?.label || "Role";

  const userTypes = Object.values(USER_TYPES).filter(
    (t) => !excludeTypes.includes(t)
  );

  const otherOpts = {
    ...(dropdownOptions || {}),
    minContentWidth: dropdownOptions?.minContentWidth || 130,
  };

  return (
    <Dropdown
      label={label}
      className={`user-role-picker ml-2 ${!selected ? "no-selection" : ""}`}
      open={isOpen}
      onOpen={() => setIsOpen(disabled ? false : true)}
      onClose={() => setIsOpen(false)}
      {...otherOpts}
    >
      <ul className="p-0 m-0">
        {userTypes.map((k) => {
          let isDisabled = false;
          if (!ignoreSubscription) {
            isDisabled =
              (k === USER_TYPES.ADMIN || k === USER_TYPES.ADMIN_DRIVER) &&
              !isTeamsProSubscription(plan);
          }
          if (
            !team?.pendingSubscription?.plan &&
            isDisabled &&
            hideDisabledRoleOptions
          )
            return null;

          // const isSelected = k === selected;
          const { icon, label } = USER_TYPES_DATA[k];
          return (
            <li key={k} className="selection-option">
              {isDisabled ? (
                <div
                  className="flex items-center p-2"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Icon name={icon} color="black/50" className="mr-3" />
                  <div className="flex flex-col">
                    <Text color="black/50">{label}</Text>
                    <Text md color="black/50" className="flex items-baseline">
                      Pro feature
                      <Button
                        link
                        className="text-blue text-13 ml-2 font-bold"
                        onClick={() =>
                          handleUpgradeClicked(
                            TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                            k
                          )
                        }
                      >
                        {team?.subscription ? "Upgrade " : "Switch "} to Pro
                      </Button>
                    </Text>
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className={`w-full flex items-center font-normal border-none outline-none p-2 rounded-8 hover:bg-beige active:bg-beige-medium `}
                  onClick={() => handleTypeSelect(k)}
                >
                  <Icon name={icon} className="mr-3" />
                  <Text>{label}</Text>
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </Dropdown>
  );
}

UserRolePicker.defaultProps = {
  selected: null,
  onSelect: () => {},
  onUpgrade: () => {},
  hideDisabledRoleOptions: false,
  subscription: null,
  dropdownOptions: null,
  disabled: false,
  excludeTypes: [],
  ignoreSubscription: false,
};

UserRolePicker.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  onUpgrade: PropTypes.func,
  hideDisabledRoleOptions: PropTypes.bool,
  subscription: PropTypes.object,
  dropdownOptions: PropTypes.object,
  disabled: PropTypes.bool,
  excludeTypes: PropTypes.array,
  ignoreSubscription: PropTypes.bool,
};
