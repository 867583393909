import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";

import { Pages, trackEmailSettingUpdated } from "src/services/tracking";

import { UPDATE_EMAIL_PREFERENCES } from "src/graphql/mutations";
import { GET_EMAIL_PREFERENCES } from "src/graphql/queries";

import Logo from "public/assets/img/nav/wordmark-individual.svg";

export default EmailPreferences;

function EmailPreferences() {
  const { userId } = useParams();
  const [queryFn, queryState] = useLazyQuery(GET_EMAIL_PREFERENCES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [updateMutFn, updateMutState] = useMutation(UPDATE_EMAIL_PREFERENCES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [currentPrefs, setCurrentPrefs] = useState({});
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [other, setOther] = useState(false);
  const [promo, setPromo] = useState(false);
  const [warnings, setWarnings] = useState(false);
  const [all, setAll] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!userId) {
      setError(true);
      return;
    }
    queryFn({
      variables: {
        userId,
      },
      onCompleted: (data) => {
        if (!data?.emailPreferences) {
          setError(true);
        } else {
          setCurrentPrefs({
            weekly: data.emailPreferences.sendWeekly,
            monthly: data.emailPreferences.sendMonthly,
            other: data.emailPreferences.sendOther,
            promo: data.emailPreferences.sendPromo,
            warnings: data.emailPreferences.sendWarnings,
          });
        }
      },
      onError: () => {
        setError(true);
      },
    });
  }, []);

  useEffect(() => {
    setWeekly(currentPrefs.weekly);
    setMonthly(currentPrefs.monthly);
    setOther(currentPrefs.other);
    setPromo(currentPrefs.promo);
    setWarnings(currentPrefs.warnings);
  }, [currentPrefs]);

  useEffect(() => {
    if (weekly || monthly || other || promo || warnings) setAll(false);
  }, [weekly, monthly, other, promo || warnings]);

  const handleToggleWeekly = () => {
    setWeekly(!weekly);
  };

  const handleToggleMonthly = () => {
    setMonthly(!monthly);
  };

  const handleToggleOther = () => {
    setOther(!other);
  };

  const handleTogglePromo = () => {
    setPromo(!promo);
  };

  const handleToggleWarnings = () => {
    setWarnings(!warnings);
  };

  const handleToggleAll = () => {
    if (all) {
      setAll(false);
    } else {
      setWeekly(false);
      setMonthly(false);
      setOther(false);
      setPromo(false);
      setWarnings(false);
      setAll(true);
    }
  };

  const handleSave = () => {
    const data = {
      page: Pages.EMAIL_SETTINGS,
    };
    if (weekly !== currentPrefs.weekly) data.weeklyEnabled = weekly;
    if (monthly !== currentPrefs.monthly) data.monthlyEnabled = monthly;
    if (other !== currentPrefs.other) data.otherEnabled = other;
    if (promo !== currentPrefs.promo) data.promoEnabled = promo;
    if (warnings !== currentPrefs.warnings) data.warningsEnabled = warnings;
    if (all) data.unsubAllSelected = true;
    trackEmailSettingUpdated(data);

    updateMutFn({
      variables: {
        userId,
        sendWeekly: weekly,
        sendMonthly: monthly,
        sendOther: other,
        sendPromo: promo,
        sendWarnings: warnings,
      },
      onCompleted: (data) => {
        if (data?.updateEmailPreferences?.ok) {
          setCurrentPrefs({
            weekly,
            monthly,
            other,
            promo,
            warnings,
          });
        }
      },
    });
  };

  const handleDiscard = () => {
    setCurrentPrefs({ ...currentPrefs }); // will trigger useEffect for currentRefs change
  };

  if (queryState.loading) return <Loader />;

  if (error)
    return (
      <div className="flex flex-col justify-center mx-auto">
        <div className="mb-[70px]">
          <Logo />
        </div>
        <h4>
          Oops, a system error has occurred. <br /> Please try again in a few
          minutes.
        </h4>
      </div>
    );

  if (!queryState?.data?.emailPreferences) return null;

  const canSave =
    weekly !== currentPrefs.weekly ||
    monthly !== currentPrefs.monthly ||
    other !== currentPrefs.other ||
    promo !== currentPrefs.promo ||
    warnings !== currentPrefs.warnings;

  return (
    <div className="page-email-preferences bg-white w-full h-full">
      <div className="h-full max-w-[760px] px-[30px] pt-[30px] mx-auto grid grid-rows-[1fr_auto] gap-8">
        <div className="flex flex-col justify-center">
          <div className="mb-[70px]">
            <Logo />
          </div>
          <h5 className="mb-1">
            Email preferences for {queryState.data.emailPreferences.email}
          </h5>

          <Text paragraph>
            We'd love to stay in touch! Subscribing to our emails keeps you
            updated on your drive summaries, MileIQ news, resources, and product
            changes.
          </Text>

          <div className="mt-5">
            <Option
              title="Weekly drive summary email"
              description="Summary, detail & stats for the last week's drives"
              selected={weekly}
              onToggle={handleToggleWeekly}
              disabled={updateMutState.loading}
            />
            <Option
              title="Monthly drive summary email"
              description="Last month at a glance"
              selected={monthly}
              onToggle={handleToggleMonthly}
              disabled={updateMutState.loading}
            />
            <Option
              title="Other emails"
              description="Product updates, company news, resources you can use to help your business"
              selected={other}
              onToggle={handleToggleOther}
              disabled={updateMutState.loading}
            />
            <Option
              title="Promotional emails"
              description="Receive special discounts and promotions from MileIQ"
              selected={promo}
              onToggle={handleTogglePromo}
              disabled={updateMutState.loading}
            />
            <Option
              title="Warning and alert emails"
              description="We'll send alerts when we notice something goes wrong, including not tracking or detecting drives"
              selected={warnings}
              onToggle={handleToggleWarnings}
              disabled={updateMutState.loading}
            />
            <Option
              title="Unsubscribe from all emails"
              description="You will no longer receive any email communications from MileIQ"
              selected={all}
              onToggle={handleToggleAll}
              disabled={updateMutState.loading}
            />
          </div>
          <div className="mt-8 flex gap-3">
            <Button
              onClick={handleSave}
              disabled={!canSave || updateMutState.loading}
              loading={updateMutState.loading}
            >
              Update preferences
            </Button>
            <Button
              secondary
              onClick={handleDiscard}
              disabled={updateMutState.loading}
            >
              Discard changes
            </Button>
          </div>
        </div>

        <div className="flex items-center gap-5 pb-8">
          <a
            href="https://mileiq.com/privacy"
            target="_blank"
            className="text-13 underline text-black/70"
          >
            Privacy
          </a>
          <a
            href="https://mileiq.com/terms"
            target="_blank"
            className="text-13 underline text-black/70"
          >
            Terms of Service
          </a>
          <Text md color="black/70">
            Copyright © {new Date().getFullYear()} MileIQ. All rights reserved
          </Text>
        </div>
      </div>
    </div>
  );
}

function Option({ title, description, selected, onToggle, disabled }) {
  return (
    <div className="border-beige-medium border-t flex justify-between py-5">
      <div className="mr-[10px]">
        <Text paragraph lg bold>
          {title}
        </Text>
        <Text md color="black/70">
          {description}
        </Text>
      </div>
      <Switcher
        lg
        containerClassName="self-start"
        isOn={selected}
        label={selected ? "Yes" : "No"}
        onChange={onToggle}
        disabled={disabled}
      />
    </div>
  );
}
