import r from "../../shared-lib/braze-shared-lib.js";
import e from "../managers/braze-instance.js";
import { newFeatureFlagFromJson as tt } from "./feature-flag-factory.js";
import ee from "./feature-flag.js";
import ir from "./feature-flags-provider-factory.js";
export function getFeatureFlag(a) {
  if (!e.rr()) return;
  if (!e.ir().ye())
    return r.D.info("Feature flags are not enabled."), new ee(a);
  const t = ir.er().xe();
  return t[a] ? tt(t[a]) : new ee(a);
}
