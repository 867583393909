import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { RECORDS_PER_PAGE } from "src/components/context/TeamDrivesContext";
import {
  COLUMNS,
  useTeamDrivesSummary,
} from "src/components/context/TeamDrivesSummaryContext";

import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Pagination from "src/components/elements/Pagination";
import Text from "src/components/elements/Text";

import TeamMemberName from "src/components/blocks/reports/teams/TeamMemberName";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { SORT_ORDER } from "src/services/http";
import {
  WebPages,
  trackTeamsDrivesExportStarted,
  trackTeamsDrivesListOpened,
} from "src/services/tracking";

import SleepingCar from "public/assets/img/sleeping-car-transparent.svg";

export default TeamDrivesSummaryList;

const columnsData = {
  [COLUMNS.DRIVER]: {
    title: "Driver",
    sortable: false,
    extraClass: "pl-7",
  },
  [COLUMNS.DRIVE_COUNT]: {
    title: "Drives submitted",
    extraClass: "report-list-column-fix",
    sortable: false,
  },
  [COLUMNS.APPROVED_DRIVE_COUNT]: {
    title: "Drives approved",
    extraClass: "report-list-column-fix",
    sortable: false,
  },
  [COLUMNS.DISTANCE]: {
    title: "Distance submitted",
    extraClass: "desktop-version",
    sortable: false,
  },
  [COLUMNS.APPROVED_DISTANCE]: {
    title: "Distance approved",
    extraClass: "desktop-version",
    sortable: false,
  },
  [COLUMNS.VALUE]: {
    title: "Value submitted",
    extraClass: "pr-8",
    sortable: false,
  },
  [COLUMNS.APPROVED_VALUE]: {
    title: "Value approved",
    extraClass: "pr-8",
    sortable: false,
  },
  [COLUMNS.ACTIONS]: {
    title: "",
    extraClass: "pr-8",
    sortable: false,
  },
};

function TeamDrivesSummaryList() {
  const {
    driverSummaries,
    page,
    sortBy,
    order,
    isTeamSummaryLoading,
    hasTeamSummaryLoaded,
    filteredDrivers,
  } = useTeamDrivesSummary();
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleColumnClicked = (columnKey) => {
    if (isTeamSummaryLoading) return;

    let sortOrder = SORT_ORDER.DESC;
    if (sortBy === columnKey) {
      sortOrder = order === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    }

    queryParams.set("sortBy", columnKey);
    queryParams.set("order", sortOrder);
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handlePageClicked = (pageIndex) => {
    if (isTeamSummaryLoading) return;

    queryParams.set("page", pageIndex);
    history.replace({ search: queryParams.toString() });
  };

  const pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage: page,
    total: driverSummaries?.total_results || 0,
    onSelectPage: handlePageClicked,
  };

  const hasPagination = driverSummaries?.pages > 1;

  return (
    <div className="team-drives-list flex-grow flex flex-col">
      <div className="miq-list-table team-drives-summary-list-table">
        <div className="miq-list-table-header h-[48px] rounded-t-[14px] hover:bg-transparent">
          {Object.keys(columnsData).map((columnKey) => {
            const { extraClass, title, sortable, alignRight } =
              columnsData[columnKey];
            return (
              <div
                key={title}
                className={`select-none truncate p-2 ${
                  sortable ? "cursor-pointer" : ""
                } ${extraClass || ""}`}
                onClick={() => sortable && handleColumnClicked(columnKey)}
              >
                <div className={`flex ${alignRight ? "justify-end" : ""}`}>
                  <p className="text-black/70 text-13 truncate">{title}</p>
                  {sortable && sortBy === columnKey ? (
                    <Icon
                      name="sort"
                      color="black/50"
                      className={`ml-2 transform ${
                        order === SORT_ORDER.DESC ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className="group/driver-summary-list miq-list-table-body">
          {isTeamSummaryLoading &&
            Array.from({ length: 9 }).map((_, i) => (
              <TeamDrivesSummaryRow isLoading key={`loading-${i}`} />
            ))}
          {hasTeamSummaryLoaded && driverSummaries.data.length === 0 && (
            <div className="w-full min-h-[630px] flex flex-col justify-center items-center gap-[24px]">
              <SleepingCar />
              <p className="text-black/50 text-center max-w-[292px]">
                No drives found matching the selected criteria. Try adjusting
                your filters.
              </p>
            </div>
          )}
          {hasTeamSummaryLoaded && driverSummaries.data.length > 0 && (
            <div data-chmln="team-drives-summary-table">
              {filteredDrivers.length !== 1 && (
                <TeamDrivesSummaryAllMembersRow />
              )}
              {driverSummaries.data.map((driver) => (
                <TeamDrivesSummaryRow
                  key={driver.id}
                  driver={driver}
                  hasPagination={hasPagination}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {hasTeamSummaryLoaded && hasPagination && (
        <div className="my-[15px] mx-5">
          <Pagination {...pagination} />
        </div>
      )}
    </div>
  );
}

function TeamDrivesSummaryAllMembersRow() {
  const { team } = useContext(TeamContext);
  const history = useHistory();
  const { teamSummary, filteredDrivers } = useTeamDrivesSummary();
  const { value, distance, drives } = teamSummary;
  const { checkAndHandleDunning } = useTeamsCTA();

  const queryParams = useQueryParams();
  const rangeDates = queryParams.get("rangeDates");

  const extras =
    parseFloat(value?.toll_fees ?? 0) + parseFloat(value?.parking_fees ?? 0);
  const totalValue = parseFloat(value?.value ?? 0) + extras;

  const approvedExtras =
    parseFloat(value?.approved_toll_fees ?? 0) +
    parseFloat(value?.approved_parking_fees ?? 0);
  const approvedTotalValue =
    parseFloat(value?.approved_value ?? 0) + approvedExtras;

  let link = "/teams/drives/all-drivers";

  if (rangeDates) {
    link += `?rangeDates=${rangeDates}`;
  }

  const handleGoToDrivesList = (e) => {
    e.preventDefault();

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsDrivesListOpened({
      isAllDrivers: true,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      webPage: WebPages.DRIVES_SUMMARY_TEAM_DASHBOARD,
    });

    history.push(link);
  };

  return (
    <Link to={link} onClick={handleGoToDrivesList} className="group/link-row">
      <div
        className="group/drive miq-list-table-body-row h-[60px] group-last/link-row:border-none group-last/link-row:rounded-b-[14px]"
        data-chmln="team-drives-summary-team-row"
      >
        {/* Driver */}
        <div className="relative p-2 pl-7 overflow-ellipsis overflow-hidden whitespace-nowrap">
          {filteredDrivers.length > 0 ? "Drivers filtered" : "Entire team"}
        </div>

        {/* Drives submitted */}
        <div className="p-2 desktop-version">
          <p className="text-black">{drives?.value}</p>
        </div>

        {/* Drives approved */}
        <div className="p-2 desktop-version">
          <p className="text-black">{drives?.approved_value}</p>
        </div>

        {/* Distance submitted */}
        <div className="p-2 report-list-column-fix">
          <p className="text-black">
            {distance?.value?.toFixed?.(1)} {distance?.unit}
          </p>
        </div>

        {/* Distance approved */}
        <div className="p-2 report-list-column-fix">
          <p className="text-black">
            {distance?.approved_value?.toFixed?.(1)} {distance?.unit}
          </p>
        </div>

        {/* Total value submitted */}
        <div className="p-2 font-semibold text-green">
          <p>
            {value?.unit}
            {totalValue?.toFixed?.(2)}
          </p>
        </div>

        {/* Total value approved */}
        <div className="p-2 font-semibold text-green">
          <p>
            {value?.unit}
            {approvedTotalValue?.toFixed?.(2)}
          </p>
        </div>

        {/* Actions */}
        <div
          className="p-2 w-fit"
          data-chmln="download-all-driver-reports-icon"
        >
          <DownloadReportDropdown />
        </div>
      </div>
    </Link>
  );
}

function TeamDrivesSummaryRow({ driver = {}, hasPagination, isLoading }) {
  const { team } = useContext(TeamContext);
  const history = useHistory();
  const queryParams = useQueryParams();
  const { checkAndHandleDunning } = useTeamsCTA();
  const rangeDates = queryParams.get("rangeDates");

  const {
    id,
    drive_count: driveCount,
    distance,
    distance_unit: distanceUnit,
    total_value: value,
    total_value_currency_unit: currencyUnit,
    user_email: userEmail,
    user_first_name: userFirstName,
    user_last_name: userLastName,
    parking_fees: parking,
    toll_fees: toll,
    approved_total_value: approvedTotalValue,
    approved_distance: approvedDistance,
    approved_drive_count: approvedDriveCount,
  } = driver;

  let link = `/teams/drives/${id}`;

  const extras = parseFloat(parking) + parseFloat(toll);
  const totalValue = parseFloat(value) + extras;

  const searchParams = new URLSearchParams();

  if (rangeDates) {
    searchParams.set("rangeDates", rangeDates);
  }

  const driverFullName =
    userFirstName && userLastName ? `${userFirstName} ${userLastName}` : null;
  const driverName = driverFullName || userEmail;

  searchParams.set("driverName", driverName);

  link += `?${searchParams.toString()}`;

  const Wrapper = isLoading ? "div" : Link;

  const handleGoToDrivesList = (e) => {
    e.preventDefault();

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsDrivesListOpened({
      isAllDrivers: false,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      webPage: WebPages.DRIVES_SUMMARY_TEAM_DASHBOARD,
    });

    history.push(link);
  };

  return (
    <Wrapper
      onClick={isLoading ? undefined : handleGoToDrivesList}
      to={isLoading ? undefined : link}
      className={`group/link-row ${isLoading ? "pointer-events-none" : ""}`}
    >
      <div
        data-chmln="team-drives-summary-driver-row"
        className={`group/drive miq-list-table-body-row h-[60px] ${
          !hasPagination
            ? "group-last/link-row:border-none group-last/link-row:rounded-b-[14px]"
            : ""
        } ${isLoading ? "pointer-events-none" : ""}`}
      >
        {/* Driver */}
        <div className="relative p-2 pl-7 truncate">
          {isLoading ? (
            <div className="w-[200px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <TeamMemberName
              member={{
                email: userEmail,
                firstName: userFirstName,
                lastName: userLastName,
              }}
            />
          )}
        </div>

        {/* Drives submitted */}
        <div className="p-2 desktop-version">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">{driveCount}</p>
          )}
        </div>

        {/* Drives approved */}
        <div className="p-2 desktop-version">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">{approvedDriveCount}</p>
          )}
        </div>

        {/* Distance */}
        <div className="p-2 report-list-column-fix">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">
              {distance?.toFixed?.(1)} {distanceUnit}
            </p>
          )}
        </div>

        {/* Distance approved */}
        <div className="p-2 report-list-column-fix">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p className="text-black">
              {approvedDistance?.toFixed?.(1)} {distanceUnit}
            </p>
          )}
        </div>

        {/* Total value */}
        <div className="p-2 font-semibold text-green">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p>
              {currencyUnit}
              {totalValue?.toFixed?.(2)}
            </p>
          )}
        </div>

        {/* Total value approved */}
        <div className="p-2 font-semibold text-green">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <p>
              {currencyUnit}
              {approvedTotalValue?.toFixed?.(2)}
            </p>
          )}
        </div>

        {/* Actions */}
        <div className="p-2 w-fit">
          {isLoading ? (
            <div className="w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
          ) : (
            <div data-chmln="download-driver-reports-icon">
              <DownloadReportDropdown driver={driver} />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

function DownloadReportDropdown({ driver }) {
  const {
    downloadReportDropdownOpen,
    setDownloadReportDropdownOpen,
    exportDrives,
    isGeneratingCSV,
    isGeneratingPDF,
    driverSummaries,
  } = useTeamDrivesSummary();
  const { team } = useContext(TeamContext);
  const driverId = driver?.id || "all-drivers";
  const isAllDrivers = driverId === "all-drivers";
  const isMultipleReports = isAllDrivers && driverSummaries?.data?.length > 1;

  const isDropdownOpen = downloadReportDropdownOpen === driverId;
  const { checkAndHandleDunning } = useTeamsCTA();

  async function handleExportDrives({ type }) {
    await exportDrives({ type, driverId });
  }

  return (
    <Dropdown
      triggerClassName={`px-2 shadow-none border-none hover:bg-beige-medium w-10 ${
        isDropdownOpen ? "bg-beige-medium" : "bg-transparent"
      }`}
      contentClassName={`top-[40px] border border-border-1 ${
        isMultipleReports ? "left-[-120px]" : "left-[-90px]"
      }`}
      stopPropagation
      renderTrigger={() => (
        <>
          <Icon name={"download-2-fixed"} className={"scale-90"} />
        </>
      )}
      open={isDropdownOpen}
      onClose={() => setDownloadReportDropdownOpen(null)}
      onOpen={() => {
        const dunningStatus = checkAndHandleDunning();
        if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

        trackTeamsDrivesExportStarted({
          orgId: team.orgId,
          orgGroupId: team.orgGroupId,
          subPlan: team.subscription?.plan,
        });

        setDownloadReportDropdownOpen(driverId);
      }}
    >
      <div className="flex flex-col">
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleExportDrives({ type: "pdf" });
            setDownloadReportDropdownOpen(null);
          }}
          className="flex items-center gap-2 py-2 p-2.5 rounded-8 hover:bg-beige"
        >
          <Text semibold>
            {" "}
            {isGeneratingPDF
              ? "Generating..."
              : isMultipleReports
              ? `Download PDFs (${driverSummaries?.data?.length})`
              : "Download PDF"}
          </Text>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleExportDrives({ type: "csv" });
            setDownloadReportDropdownOpen(null);
          }}
          className="flex items-center gap-2 py-2  p-2.5 rounded-8 hover:bg-beige"
        >
          <Text semibold>
            {isGeneratingCSV
              ? "Generating..."
              : isMultipleReports
              ? `Download CSVs (${driverSummaries?.data?.length})`
              : "Download CSV"}
          </Text>
        </div>
      </div>
    </Dropdown>
  );
}
