import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Alert, AlertVariants } from "src/components/elements/Alert";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { useFiles } from "src/hooks/useFiles";

const UPLOAD_SUCCESS = "Upload successful!";
const GENERIC_ERROR =
  "Sorry, we're having trouble reading your file. Please check the formatting and try again, or contact support for help.";

export function Dropzone({
  accept,
  maxFiles,
  children,
  onFileRead,
  onUploadError,
  onFileValidator,
  onUpdateFileList,
  isLoading: isParentLoading = null,
  isError: isParentError = null,
  isSuccess: isParentSuccess = null,
}) {
  const files = useFiles();
  const [isLoading, setLoading] = useState(isParentLoading);
  const [isError, setError] = useState(isParentError);
  const [isSuccess, setSuccess] = useState(isParentSuccess);

  const validator = (newFiles) => {
    onClear();
    return onFileValidator(newFiles);
  };

  const removeFile = (file) => {
    clearAlerts();
    files.remove(file.id);
  };

  const clearAlerts = () => {
    setError(null);
    setSuccess(null);
  };

  const onClear = () => {
    files.clean();
    clearAlerts();
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles?.length) {
        return setError(GENERIC_ERROR);
      }
      clearAlerts();
      if (!onFileRead) {
        return files.concat(acceptedFiles);
      }
      try {
        setLoading(true);
        const filesRead = await Promise.allSettled(
          acceptedFiles?.map(onFileRead)
        );
        let errors = 0;
        const goodFiles = filesRead?.reduce((acc, file) => {
          if (file.status === "fulfilled") {
            acc.push(file.value);
          } else {
            setError(GENERIC_ERROR);
            errors++;
            console.error(file.reason);
          }
          return acc;
        }, []);
        if (!errors) setSuccess(UPLOAD_SUCCESS);
        files.concat(goodFiles);
      } catch (e) {
        setError(GENERIC_ERROR);
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [files.concat, onFileRead, clearAlerts, setLoading, setError, setSuccess]
  );

  useEffect(() => {
    onUpdateFileList(Array.from(files.files.values()));
  }, [files.files]);

  useEffect(() => {
    if (isError) onUploadError(isError);
  }, [isError]);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    validator,
  });

  const DropArea = (
    <div
      {...getRootProps({
        className: `miq-dropzone ${
          isFocused && "border-blue-medium bg-blue-pastelLight"
        } ${isDragAccept && "border-blue-medium bg-blue-pastelLight"} ${
          isDragReject && "border-red bg-red-pastel cursor-not-allowed"
        }
      `,
      })}
    >
      <input {...getInputProps()} />
      <Icon name="upload" className="mb-1" />
      <Text md color="black/70">
        {isDragActive
          ? isDragReject
            ? "File type is not supported"
            : "Drop it here ..."
          : "Choose a file or drag it here"}
      </Text>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div className="mt-4 mb-6 w-full flex flex-col items-center justify-center rounded border-2 border-dashed border-black/10 py-6">
          <span
            style={{ maxWidth: "2rem" }}
            className="inline-block animate-spin"
          >
            <Icon name="spinner-yellow" />
          </span>
        </div>
      ) : (
        (files?.files?.size &&
          Array.from(files?.files?.values()).map(FileItem(removeFile))) ||
        DropArea
      )}
      <div className="mb-6">
        {isError && (
          <Alert variant={AlertVariants.ERROR} show={isError}>
            {isError}
          </Alert>
        )}
        {isSuccess && (
          <Alert variant={AlertVariants.SUCCESS} show={isSuccess}>
            {isSuccess}
          </Alert>
        )}
      </div>
      {children({ open, isLoading })}
    </>
  );
}

Dropzone.defaultProps = {
  onFileValidator: () => {},
  onUpdateFileList: () => {},
};

const FileItem = (removeFile) => (file) => {
  const {
    id,
    file: {
      file: { name },
    },
  } = file;
  return (
    <div
      key={id}
      className={`mt-4 mb-4 flex flex-row items-center p-2 px-4 text-black/70 rounded border border-1 border-solid border-black/10 justify-between`}
    >
      <div className="flex flex-row">
        <Icon name="file" color="black" />
        <Text className="ml-2" color="black/70">
          {name}
        </Text>
      </div>
      <Icon
        name="close"
        className="cursor-pointer"
        onClick={() => removeFile(file)}
      />
    </div>
  );
};
