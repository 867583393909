import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default ProfilePageQuickTips;

function ProfilePageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/115000158843-Top-5-Tips-for-New-Users">
            Top Five Tips
          </QuickTipsLink>{" "}
          for New Users from our Customer Success Team
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/218138823-What-you-can-do-in-MileIQ">
            What You Can Do In MileIQ
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
