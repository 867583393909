export default class Ge {
  constructor(t, e) {
    (this.td = "undefined" == typeof window ? self : window),
      (this.ed = t),
      (this.nd = e);
  }
  od() {
    if ("indexedDB" in this.td) return this.td.indexedDB;
  }
  rd() {
    try {
      if (null == this.od()) return !1;
      {
        const t = this.od().open("Braze IndexedDB Support Test");
        if (
          ((t.onupgradeneeded = () => t.result.close()),
          (t.onsuccess = () => t.result.close()),
          "undefined" != typeof window)
        ) {
          const t = window.chrome || window.browser || window.msBrowser;
          if (t && t.runtime && t.runtime.id)
            return (
              this.nd.info(
                "Not using IndexedDB for storage because we are running inside an extension"
              ),
              !1
            );
        }
        return !0;
      }
    } catch (t) {
      return (
        this.nd.info(
          "Not using IndexedDB for storage due to following error: " + t
        ),
        !1
      );
    }
  }
  sd(t, e) {
    const n = this.od().open(this.ed.dd, this.ed.VERSION);
    if (null == n) return "function" == typeof e && e(), !1;
    const o = this;
    return (
      (n.onupgradeneeded = t => {
        o.nd.info(
          "Upgrading indexedDB " + o.ed.dd + " to v" + o.ed.VERSION + "..."
        );
        const e = t.target.result;
        for (const t in o.ed.$t)
          o.ed.$t.hasOwnProperty(t) &&
            !e.objectStoreNames.contains(o.ed.$t[t]) &&
            e.createObjectStore(o.ed.$t[t]);
      }),
      (n.onsuccess = n => {
        const i = n.target.result;
        (i.onversionchange = () => {
          i.close(),
            "function" == typeof e && e(),
            o.nd.error(
              "Needed to close the database unexpectedly because of an upgrade in another tab"
            );
        }),
          t(i);
      }),
      (n.onerror = t => (
        o.nd.info(
          "Could not open indexedDB " +
            o.ed.dd +
            " v" +
            o.ed.VERSION +
            ": " +
            t.target.errorCode
        ),
        "function" == typeof e && e(),
        !0
      )),
      !0
    );
  }
  setItem(t, e, n, o, i) {
    if (!this.rd()) return "function" == typeof i && i(), !1;
    const r = this;
    return this.sd(s => {
      if (!s.objectStoreNames.contains(t))
        return (
          r.nd.error(
            "Could not store object " +
              e +
              " in " +
              t +
              " on indexedDB " +
              r.ed.dd +
              " - " +
              t +
              " is not a valid objectStore"
          ),
          "function" == typeof i && i(),
          void s.close()
        );
      const d = s.transaction([t], "readwrite");
      d.oncomplete = () => s.close();
      const u = d.objectStore(t).put(n, e);
      (u.onerror = () => {
        r.nd.error(
          "Could not store object " +
            e +
            " in " +
            t +
            " on indexedDB " +
            r.ed.dd
        ),
          "function" == typeof i && i();
      }),
        (u.onsuccess = () => {
          "function" == typeof o && o();
        });
    }, i);
  }
  getItem(t, e, n) {
    if (!this.rd()) return !1;
    const o = this;
    return this.sd(i => {
      if (!i.objectStoreNames.contains(t))
        return (
          o.nd.error(
            "Could not retrieve object " +
              e +
              " in " +
              t +
              " on indexedDB " +
              o.ed.dd +
              " - " +
              t +
              " is not a valid objectStore"
          ),
          void i.close()
        );
      const r = i.transaction([t], "readonly");
      r.oncomplete = () => i.close();
      const s = r.objectStore(t).get(e);
      (s.onerror = () => {
        o.nd.error(
          "Could not retrieve object " +
            e +
            " in " +
            t +
            " on indexedDB " +
            o.ed.dd
        );
      }),
        (s.onsuccess = t => {
          const e = t.target.result;
          null != e && n(e);
        });
    });
  }
  hr(t, e, n) {
    if (!this.rd()) return "function" == typeof n && n(), !1;
    const o = this;
    return this.sd(i => {
      if (!i.objectStoreNames.contains(t))
        return (
          o.nd.error(
            "Could not retrieve last record from " +
              t +
              " on indexedDB " +
              o.ed.dd +
              " - " +
              t +
              " is not a valid objectStore"
          ),
          "function" == typeof n && n(),
          void i.close()
        );
      const r = i.transaction([t], "readonly");
      r.oncomplete = () => i.close();
      const s = r.objectStore(t).openCursor(null, "prev");
      (s.onerror = () => {
        o.nd.error(
          "Could not open cursor for " + t + " on indexedDB " + o.ed.dd
        ),
          "function" == typeof n && n();
      }),
        (s.onsuccess = t => {
          const o = t.target.result;
          null != o && null != o.value && null != o.key
            ? e(o.key, o.value)
            : "function" == typeof n && n();
        });
    }, n);
  }
  br(t, e) {
    if (!this.rd()) return !1;
    const n = this;
    return this.sd(o => {
      if (!o.objectStoreNames.contains(t))
        return (
          n.nd.error(
            "Could not delete record " +
              e +
              " from " +
              t +
              " on indexedDB " +
              n.ed.dd +
              " - " +
              t +
              " is not a valid objectStore"
          ),
          void o.close()
        );
      const i = o.transaction([t], "readwrite");
      i.oncomplete = () => o.close();
      i.objectStore(t).delete(e).onerror = () => {
        n.nd.error(
          "Could not delete record " +
            e +
            " from " +
            t +
            " on indexedDB " +
            n.ed.dd
        );
      };
    });
  }
  Jt(t, e) {
    if (!this.rd()) return !1;
    const n = this;
    return this.sd(o => {
      if (!o.objectStoreNames.contains(t))
        return (
          n.nd.error(
            "Could not retrieve objects from " +
              t +
              " on indexedDB " +
              n.ed.dd +
              " - " +
              t +
              " is not a valid objectStore"
          ),
          void o.close()
        );
      const i = o.transaction([t], "readwrite");
      i.oncomplete = () => o.close();
      const r = i.objectStore(t),
        s = r.openCursor(),
        d = [];
      (s.onerror = () => {
        d.length > 0
          ? (n.nd.info(
              "Cursor closed midway through for " +
                t +
                " on indexedDB " +
                n.ed.dd
            ),
            e(d))
          : n.nd.error(
              "Could not open cursor for " + t + " on indexedDB " + n.ed.dd
            );
      }),
        (s.onsuccess = t => {
          const n = t.target.result;
          if (null != n) {
            if (null != n.value && null != n.key) {
              r.delete(n.key).onsuccess = () => {
                d.push(n.value);
              };
            }
            n.continue();
          } else d.length > 0 && e(d);
        });
    });
  }
  clearData() {
    if (!this.rd()) return !1;
    const t = [];
    for (const e in this.ed.$t)
      this.ed.$t.hasOwnProperty(e) &&
        this.ed.$t[e] !== this.ed.$t.oe &&
        t.push(this.ed.$t[e]);
    const e = this;
    return this.sd(function(n) {
      const o = n.transaction(t, "readwrite");
      o.oncomplete = () => n.close();
      for (let n = 0; n < t.length; n++) {
        const i = t[n];
        o.objectStore(i).clear().onerror = function() {
          e.nd.error(
            "Could not clear " + this.source.name + " on indexedDB " + e.ed.dd
          );
        };
      }
      o.onerror = function() {
        e.nd.error("Could not clear object stores on indexedDB " + e.ed.dd);
      };
    });
  }
}
Ge.ep = {
  Ft: {
    dd: "AppboyServiceWorkerAsyncStorage",
    VERSION: 6,
    $t: {
      Kl: "data",
      jr: "pushClicks",
      cu: "pushSubscribed",
      ud: "fallbackDevice",
      Mt: "cardUpdates",
      oe: "optOut",
      $r: "pendingData",
      On: "sdkAuthenticationSignature"
    },
    se: 1
  }
};
