import React, { useEffect, useRef, useState } from "react";

import { useUserData } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Select from "src/components/elements/Select";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";

import { tryRefreshToken } from "src/services/auth";
import { createTeam, updateTeam } from "src/services/teams";
import { COUNTRIES } from "src/services/utils";

export default CreateTeamForm;

const countrySelectorData = {
  [COUNTRIES.US]: "United States",
  [COUNTRIES.CA]: "Canada",
  [COUNTRIES.GB]: "United Kingdom",
};

const INDUSTRY_OPTIONS = {
  Construction: {
    label: "Construction",
  },
  "Creative Services": {
    label: "Creative Services",
  },
  Finance: {
    label: "Finance",
  },
  "Food and Beverage": {
    label: "Food and Beverage",
  },
  "Health/Medical": {
    label: "Health/Medical",
  },
  Legal: {
    label: "Legal",
  },
  "Maintenance Services": {
    label: "Maintenance Services",
  },
  "Non-profit": {
    label: "Non-profit",
  },
  "Real Estate": {
    label: "Real Estate",
  },
  "Sales/Retail": {
    label: "Sales/Retail",
  },
  "Talent/Staffing Agency": {
    label: "Talent/Staffing Agency",
  },
  Technology: {
    label: "Technology",
  },
  Transportation: {
    label: "Transportation",
  },
  Other: {
    label: "Other",
  },
};

const COMPANY_SIZE_OPTIONS = {
  "1-10": {
    label: "1-10 employees",
  },
  "11-20": {
    label: "11-20 employees",
  },
  "21-100": {
    label: "21-100 employees",
  },
  "101-500": {
    label: "101-500 employees",
  },
  "500+": {
    label: "500+ employees",
  },
};

function isValidName(name) {
  return name?.trim() !== "";
}

function isValidIndustry(industry, customIndustry) {
  return industry === "Other" ? !!customIndustry : !!industry;
}

function isValidCountry(country) {
  return Object.values(COUNTRIES).includes(country.toUpperCase());
}

function isValidCompanySize(companySize) {
  return Object.keys(COMPANY_SIZE_OPTIONS).includes(companySize);
}

function isValidTeamInfo(
  questions = {},
  { name, country, industry, customIndustry, companySize }
) {
  if (!isValidName(name) || !isValidCountry(country)) return false;

  const { askForIndustry, askForCompanySize } = questions;

  if (askForIndustry && !isValidIndustry(industry, customIndustry)) {
    return false;
  }

  if (askForCompanySize && !isValidCompanySize(companySize)) {
    return false;
  }

  return true;
}

function getIndustryOption(industry) {
  if (!industry) return "";

  return INDUSTRY_OPTIONS[industry] ? industry : "Other";
}

function getIndustryValue(industry, customIndustry) {
  if (!industry) return "";

  return industry === "Other" ? customIndustry : industry;
}

function CreateTeamForm({
  team,
  onSubmit,
  btnTitle = "Create team",
  isFreeTrialsActive,
  skipTrialCta,
  checkoutInProgress = false,
  canChangeSku = true,
  canGoBack = false,
}) {
  const { userData } = useUserData();
  const presetIndustry = getIndustryOption(team?.industry);
  const isCustomPresetIndustry = presetIndustry === "Other";
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSkipTrial, setLoadingSkipTrial] = useState(false);
  const [newName, setNewName] = useState(team?.name || "");
  const [newCountry, setNewCountry] = useState(
    team?.country || userData?.country || ""
  );
  const [industry, setIndustry] = useState(presetIndustry);
  const [customIndustry, setCustomIndustry] = useState(
    isCustomPresetIndustry ? team?.industry : ""
  );
  const [companySize, setCompanySize] = useState(team?.size || "");
  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const {
    miqDashboardTeamsOnboardingQuestionsWeb: questions = {
      askForCompanySize: true,
      askForIndustry: true,
      askForSeatsNum: false,
      askForCountry: true,
    },
  } = useFlags();

  const countryTooltipRef = useRef();
  const seatsTooltipRef = useRef();

  const industryValue = getIndustryValue(industry, customIndustry);

  const handleNameChange = (e) => {
    const val = e.target.value || "";
    setNewName(val);
  };

  const handleCustomIndustryChange = (e) => {
    const val = e.target.value || "";
    setCustomIndustry(val);
  };

  const handleSubmit = async (e, skipFreeTrial = false) => {
    e.preventDefault();
    if (
      !isValidTeamInfo(questions, {
        name: newName,
        country: newCountry,
        industry,
        customIndustry,
        companySize,
      })
    )
      return;

    if (loading) return;

    setLoading(true);
    setLoadingSkipTrial(skipFreeTrial);
    try {
      if (!team?.id) {
        await createTeam(
          newName.trim(),
          newCountry,
          industryValue,
          companySize
        );
        await tryRefreshToken();
      } else {
        await updateTeam(newName, newCountry, industryValue, companySize);
      }
      onSubmit({
        skipFreeTrial,
        industry: industryValue,
        companySize,
        numberOfSeats: askForSeatsNum ? numberOfSeats : 1,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setLoadingSkipTrial(false);
  };

  const handleSelectCountry = (k) => {
    setNewCountry(k);
  };

  const handleNumberOfSeatsChange = (e) => {
    const existingSeats = team?.subscription?.numberOfSeats || 0;
    const val = Number(e.target?.value || 1);
    setNumberOfSeats(val);
    if (existingSeats > val) {
      setError(
        <>
          There are currently {existingSeats} drivers in your team. To remove a
          driver, please {/* TODO: measure click on this link */}
          <a href="/teams/users" className="underline">
            go here
          </a>
          .
        </>
      );
    } else {
      setError(null);
    }
  };

  const canSubmit = isValidTeamInfo(questions, {
    name: newName,
    country: newCountry,
    industry,
    customIndustry,
    companySize,
  });

  const isCustomIndustry = industry === "Other";

  const { askForIndustry, askForCompanySize, askForSeatsNum, askForCountry } =
    questions;

  const existingTeam = team?.id || userData?.isTeamsUser;
  let title = "Create a Team";
  let subtitle = "Answer a few questions to set up a Team for your company.";
  if (existingTeam) {
    title = "Confirm your team details";
    subtitle = "You can add or remove drivers anytime.";
  }

  useEffect(() => {
    if (team?.subscription?.numberOfSeats) {
      setNumberOfSeats(team?.subscription?.numberOfSeats);
    }
  }, [team?.subscription?.numberOfSeats]);

  return (
    <div className="w-[560px] mobile:w-max flex flex-col p-[40px] mobile:pt-[90px] mobile:px-[20px] justify-center items-center bg-white rounded-[30px] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.08)] mobile:shadow-none">
      <h2 className="text-center">{title}</h2>
      <Text paragraph custom className="text-center mt-[10px] text-black/70">
        {subtitle}
      </Text>

      <form
        onSubmit={handleSubmit}
        className="mt-[40px] w-full flex flex-col gap-[24px]"
      >
        <div className="w-full">
          <label htmlFor="teamName" className="block mb-2.5">
            <Text semibold>What's the name of your company?</Text>
          </label>
          <Input
            autoFocus
            id="teamName"
            disabled={loading || checkoutInProgress}
            value={newName}
            onChange={handleNameChange}
            placeholder="Enter company name"
            className="w-full"
          />
        </div>
        {askForIndustry ? (
          <div className="w-full">
            <label className="block mb-2.5">
              <Text semibold>What industry are you in?</Text>
            </label>
            <div className="flex gap-2.5">
              <div className={isCustomIndustry ? "md:w-[190px]" : "w-full"}>
                <Select
                  disabled={loading || checkoutInProgress}
                  options={INDUSTRY_OPTIONS}
                  placeholder="Select industry"
                  selected={industry}
                  onSelect={(industry) => {
                    setIndustry(industry);
                    setCustomIndustry("");
                  }}
                  listClassName="max-h-[310px] overflow-y-auto"
                  matchTriggerWidth
                />
              </div>
              {isCustomIndustry && (
                <Input
                  disabled={loading || checkoutInProgress}
                  id="teamCustomIndustry"
                  value={customIndustry}
                  onChange={handleCustomIndustryChange}
                  placeholder="Please specify"
                  className="flex-grow"
                />
              )}
            </div>
          </div>
        ) : null}
        {askForCompanySize ? (
          <div className="w-full">
            <label className="block mb-2.5">
              <Text semibold>
                How many people at your company drive for work?
              </Text>
            </label>
            <div className="w-full">
              <Select
                disabled={loading || checkoutInProgress}
                options={COMPANY_SIZE_OPTIONS}
                placeholder="Select number of employees"
                selected={companySize}
                onSelect={setCompanySize}
                listClassName="max-h-[310px] overflow-y-auto"
                matchTriggerWidth
              />
            </div>
          </div>
        ) : null}
        {askForSeatsNum ? (
          <div className="w-full">
            <label className="block mb-2.5 relative">
              <Text semibold>
                {existingTeam
                  ? `How many drivers would you like to continue with?`
                  : `How many drivers would you like to start with?`}
              </Text>
              <span ref={seatsTooltipRef}>
                <Icon
                  name="question-circle"
                  className="inline-block ml-1 align-middle"
                />
              </span>
              <Tooltip
                width={240}
                offset={{ y: 24, x: -56 }}
                triggerRef={seatsTooltipRef}
                className="py-2.5 px-3"
              >
                <Text paragraph>
                  You can always add more drivers anytime you like.
                </Text>
              </Tooltip>
            </label>
            <div className="w-full">
              <Input
                disabled={loading || checkoutInProgress}
                type="number"
                min="1"
                max="9999"
                id="teamNumberOfSeats"
                value={numberOfSeats}
                onChange={handleNumberOfSeatsChange}
                className="flex-grow"
              />
              {error && (
                <Text paragraph className="text-red pt-2">
                  {error}
                </Text>
              )}
            </div>
          </div>
        ) : null}
        {askForCountry ? (
          <div className="w-full">
            <label className="block mb-2.5 relative">
              <Text semibold>Where are you located?</Text>
              <span ref={countryTooltipRef}>
                <Icon
                  name="question-circle"
                  className="inline-block ml-1 align-middle"
                />
              </span>
              <Tooltip
                width={240}
                offset={{ y: 24, x: -56 }}
                triggerRef={countryTooltipRef}
                className="py-2.5 px-3"
              >
                <Text paragraph>
                  This will set your team's default location and the
                  corresponding currency and unit of length. To change it, you
                  will have to contact{" "}
                  <a
                    href="https://support.mileiq.com"
                    target="_blank"
                    className="underline"
                  >
                    Customer Support
                  </a>
                  .
                </Text>
              </Tooltip>
            </label>
            <Select
              disabled={loading || checkoutInProgress}
              options={{
                [COUNTRIES.US]: {
                  label: countrySelectorData[COUNTRIES.US],
                },
                [COUNTRIES.GB]: {
                  label: countrySelectorData[COUNTRIES.GB],
                },
                [COUNTRIES.CA]: {
                  label: countrySelectorData[COUNTRIES.CA],
                },
              }}
              placeholder="Select country"
              selected={newCountry}
              onSelect={handleSelectCountry}
              matchTriggerWidth
            />
          </div>
        ) : null}
        <Button
          lg
          type="submit"
          className="w-full mt-[16px]"
          disabled={!canSubmit || loading || checkoutInProgress}
          loading={loading && !loadingSkipTrial}
        >
          {btnTitle}
        </Button>
        {canChangeSku && isFreeTrialsActive ? (
          <div className="mt-[6px] flex items-center justify-center">
            <Button
              onClick={(e) => handleSubmit(e, true)}
              link
              className={`text-[rgba(0,0,0,0.44)] text-md select-none p-[2px] ${
                loading || !canSubmit || checkoutInProgress
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              {skipTrialCta}
            </Button>
          </div>
        ) : null}
        {canGoBack ? (
          <div className="mt-[6px] flex items-center justify-center">
            <Button
              onClick={() => window.history.back()}
              link
              className={`text-[rgba(0,0,0,0.44)] text-md select-none p-[2px]`}
            >
              Go back to previous page
            </Button>
          </div>
        ) : null}
      </form>
    </div>
  );
}
