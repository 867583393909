import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";

import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import {
  trackDistanceRateUpdateCompleted,
  trackDistanceRateUpdateStarted,
} from "src/services/tracking";
import { ratesInKmToMiles, ratesInMilesToKm } from "src/services/utils";

import { UPDATE_REGION } from "src/graphql/mutations";
import { GET_REGION } from "src/graphql/queries";

const Regions = {
  NW: "nw",
  NOC: "noc",
};

const RatesCA = ({
  rate,
  editable,
  setByTeam,
  loading,
  onSave = () => {},
  isCustomRatePresent,
  handleRatesReset = () => {},
}) => {
  const updateStarted = useRef(false);
  const customRates = useCustomRates();
  const defaultCountryRates = customRates?.defaultCountryRates?.data;

  const source = defaultCountryRates?.source || "CRA";
  const year = defaultCountryRates?.year || "2022";
  const value = regionalRates(
    defaultCountryRates?.values?.business || {
      default: 0.983,
      ge_3125: 0.885,
    },
    defaultCountryRates?.units || "km"
  );

  const regionQuery = useQuery(GET_REGION, {
    notifyOnNetworkStatusChange: true,
  });

  const [updateRegion] = useMutation(UPDATE_REGION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["GetRegion", "getRates"],
    onQueryUpdated: (q) => q.refetch(),
  });

  function toHumanCents(value = 0, toFixed = 2) {
    return /\.(.+)/.exec(Number(value)?.toFixed(toFixed))[1];
  }

  function regionalRates(rateMap, convertTo, toNumber = false) {
    if (!convertTo) return rateMap;
    const rateMapCopy = { ...rateMap };
    Object.keys(rateMapCopy).forEach((r) => {
      let rate = +rateMapCopy[r];
      if (convertTo === "km") {
        rate = ratesInMilesToKm(rate);
      } else {
        rate = ratesInKmToMiles(rate);
      }

      if (toNumber) {
        rateMapCopy[r] = Number(rate.toFixed(3));
      } else {
        rateMapCopy[r] = rate.toFixed(3);
      }
    });
    return rateMapCopy;
  }

  const defaultValues = regionalRates(rate.values.business, rate.units);
  const [rates, setRates] = useState(defaultValues);

  useEffect(() => {
    setRates(regionalRates(rate.values.business, rate.units));
  }, [JSON.stringify(rate)]);

  const handleRateChanged = (key, val) => {
    if (!updateStarted.current) {
      trackDistanceRateUpdateStarted();
    }
    updateStarted.current = true;
    setRates((prevVal) => ({ ...prevVal, [key]: val }));
  };

  const handleSave = async () => {
    let values = { ...rate.values };
    values.business = regionalRates(rates, "mi", true);
    await onSave({ values });
    trackDistanceRateUpdateCompleted();
    updateStarted.current = false;
  };

  const handleCancel = () => {
    setRates(defaultValues);
    updateStarted.current = false;
  };

  const isResidentOfNwCanada = regionQuery?.data?.region === Regions.NW;
  const newRegion =
    regionQuery?.data?.region === Regions.NW ? Regions.NOC : Regions.NW;

  const isModified = JSON.stringify(defaultValues) !== JSON.stringify(rates);

  return (
    <>
      <h6 className="mb-[5px]">Rates</h6>
      <Text paragraph>
        {setByTeam
          ? "You are part of a team which means that your team's admin sets your currency, mileage rate, and distance unit. Current rate is listed below."
          : `The ${year} ${source} rates for Canada is ${toHumanCents(
              value?.default
            )} cent per kilometre for the first 5,000 kilometres driven in a tax year, then ${toHumanCents(
              value?.ge_3125
            )} cent for each subsequent kilometre. For Northern Canada (Northwest Territories, Yukon and Nunavut), there is an additional 4 cent per kilometre allowed for travel.`}
      </Text>
      <div className="my-[20px] laptop:my-[15px]">
        <div className="flex items-center">
          <Text semibold className="w-1/3">
            Resident of Northern Canada
          </Text>
        </div>
        <div className="mt-2 w-full flex items-center flex-grow">
          {regionQuery.loading ? (
            <Loader className="p-8" />
          ) : (
            <Switcher
              disabled={setByTeam || !editable}
              lg
              isOn={isResidentOfNwCanada}
              onChange={() => updateRegion({ variables: { newRegion } })}
            />
          )}
        </div>
      </div>
      <div className="flex gap-[20px] my-[20px] laptop:my-[15px]">
        <div className="flex flex-col gap-[5px]">
          <Text semibold>First 5,000 kms</Text>
          <RatesInput
            disabled={setByTeam || !editable}
            prefix={`$/${rate.units}`}
            rate={rates.default}
            onChange={(val) => handleRateChanged("default", val)}
            placeholder={rates.default}
          />
        </div>
        <div className="flex flex-col gap-[5px]">
          <Text semibold>After 5,000 kms</Text>
          <RatesInput
            disabled={setByTeam || !editable}
            prefix={`$/${rate.units}`}
            rate={rates.ge_3125}
            onChange={(val) => handleRateChanged("ge_3125", val)}
            placeholder={rates.ge_3125}
          />
        </div>
        {!setByTeam && isCustomRatePresent && (
          <div className="flex flex-col gap-[5px]">
            <button
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => {
                return handleRatesReset();
              }}
            >
              Reset Rates
            </button>
          </div>
        )}
      </div>
      {setByTeam ? null : (
        <div className="mt-4 flex gap-2">
          <Button
            loading={loading}
            disabled={!isModified || loading}
            className="font-medium"
            primary
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            disabled={!isModified || loading}
            className="font-medium"
            ghost
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default RatesCA;
