import { STORAGE_KEYS as o } from "./storage-manager.js";
import u from "./subscription-manager.js";
import r from "../../shared-lib/braze-shared-lib.js";
export default class St {
  constructor(t, i, s) {
    (this.h = t),
      (this.xn = i || !1),
      (this.zn = s),
      (this.qn = new u()),
      (this.Gn = 0),
      (this.Hn = 1);
  }
  Jn() {
    return this.xn;
  }
  Kn() {
    return this.h.I(o.q.Mn);
  }
  setSdkAuthenticationSignature(t) {
    const i = this.Kn();
    this.h.B(o.q.Mn, t);
    const s = r.xt.Ft;
    new r.qt(s, r.D).setItem(s.$t.On, this.Hn, t), i !== t && this.Qs();
  }
  Qn() {
    this.h.Xs(o.q.Mn);
    const t = r.xt.Ft;
    new r.qt(t, r.D).br(t.$t.On, this.Hn);
  }
  subscribeToSdkAuthenticationFailures(t) {
    return this.zn.ut(t);
  }
  Vn(t) {
    this.zn.St(t);
  }
  Wn() {
    this.qn.removeAllSubscriptions();
  }
  Xn() {
    this.Gn += 1;
  }
  Yn() {
    return this.Gn;
  }
  Qs() {
    this.Gn = 0;
  }
}
