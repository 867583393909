import React, { useContext } from "react";
import { useHistory } from "react-router";

import DrivesContext from "src/components/context/DrivesContext";
import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Actions from "src/components/blocks/drives/Actions";
import Notes from "src/components/blocks/drives/Notes";
import SelectVehicle from "src/components/blocks/drives/SelectVehicle";
import PurposePicker from "src/components/blocks/drives/classification/PurposePicker";
import Inputs from "src/components/blocks/drives/details/inputs/Inputs";
import Header from "src/components/blocks/drives/details/multiple-drives-details/Header";

import Drive from "src/models/drive";
import { PURPOSE_CATEGORY } from "src/models/purpose";

import {
  CustomPurposeSource,
  DriveEditDetail,
  Pages,
  ReportCreationFailure,
  trackCustomPurposeAddCompleted,
  trackCustomPurposeAddStarted,
  trackDriveClassifyCompleted,
  trackDriveClassifyStarted,
  trackDriveEdited,
  trackReportCreationCompleted,
  trackReportCreationFailed,
  trackReportCreationStarted,
  trackVehicleAddCompleted,
  trackVehicleAddStarted,
} from "src/services/tracking";

import CreateReport from "../../../reports/CreateReport";

/**
 *
 * @param {{drives: Drive[] }} props
 * @returns
 */
function MultipleDrivesDetails({
  drives,
  onClose,
  onEditDrives,
  unit,
  currency,
}) {
  const history = useHistory();
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);
  const { onPurposeSelectedFromDriveDetails } = useContext(DrivesContext);

  const onMultipleEditDrives = async (fields, notifySuccess = true) => {
    const editDrivesMap = drives.map((drive) => {
      if (drive.isRoundTripStop) {
        return [
          {
            objectId: drive.id,
            ...fields,
          },
          {
            objectId: drive.secondId,
            ...fields,
          },
        ];
      }
      return {
        objectId: drive.id,
        ...fields,
      };
    });

    return onEditDrives(editDrivesMap.flat(1), notifySuccess);
  };

  const getTotalFromField = (field) =>
    drives.reduce((acc, next) => acc + next[field], 0);

  const totalDriveValueSum = drives.reduce((acc, next) => {
    if (next.isRoundTripStop) {
      return acc + next.value;
    }

    return acc + next.value + next.parkingFees + next.tollFees;
  }, 0);

  const samePurpose = drives.every(
    (d) =>
      d.purpose.id === drives[0].purpose.id &&
      d.purpose.category === drives[0].purpose.category
  );

  const getFieldValue = (field) => {
    const allElementsHaveSameValue = drives.every(
      (element) => element[field] === drives[0][field]
    );
    if (allElementsHaveSameValue) return drives[0][field];

    return null;
  };

  const handleSelectPurpose = async (p) => {
    await onMultipleEditDrives({ category: p.category, purpose: p.id }, false);
    trackDriveClassifyCompleted({
      page: Pages.DRIVE_DETAILS,
      count: drives.length,
      type: p.category,
      existingTypes: drives.map((d) => d.purpose.category),
      purpose: p.id,
    });
    onPurposeSelectedFromDriveDetails(
      drives?.map((d) => new Drive(d)) || [],
      p
    );
  };

  const handleSelectVehicle = (vehicle) => {
    trackDriveEdited({
      count: drives.length,
      detail: DriveEditDetail.VEHICLE,
    });
    onMultipleEditDrives({ vehicleId: vehicle.id });
  };

  const handleEditNotes = (data) => {
    trackDriveEdited({
      count: drives.length,
      detail: DriveEditDetail.NOTES,
    });
    return onMultipleEditDrives(data);
  };

  const handleEditInputs = (data) => {
    if (typeof data.googleDistance !== "undefined") {
      trackDriveEdited({
        count: drives.length,
        detail: DriveEditDetail.DISTANCE,
      });
    }
    if (typeof data.parkingFees !== "undefined") {
      trackDriveEdited({
        count: drives.length,
        detail: DriveEditDetail.PARKING,
      });
    }
    if (typeof data.tollFees !== "undefined") {
      trackDriveEdited({
        count: drives.length,
        detail: DriveEditDetail.TOLLS,
      });
    }
    return onMultipleEditDrives(data);
  };

  const handleStartSelectPurpose = ({ category }) => {
    trackDriveClassifyStarted({
      page: Pages.DRIVE_DETAILS,
      count: drives.length,
      type: category,
      existingTypes: drives.map((d) => d.purpose.category),
    });
  };

  const handleAddPurposeStarted = ({ category }) => {
    trackCustomPurposeAddStarted({
      category,
      src: CustomPurposeSource.DRIVES_FILTER,
    });
  };

  const handleAddPurposeCompleted = ({ category }) => {
    trackCustomPurposeAddCompleted({
      category,
      src: CustomPurposeSource.DRIVES_FILTER,
    });
  };

  const totalDistance = getTotalFromField("googleDistance");
  const hasUnclassifedDrives =
    drives.filter(
      (drive) => drive.purpose.category === PURPOSE_CATEGORY.UNCLASSIFIED
    ).length > 0;

  let businessDrivesCount = 0;
  let personalDrivesCount = 0;

  drives?.forEach((d) => {
    if (d.purpose.category === PURPOSE_CATEGORY.PERSONAL) {
      personalDrivesCount++;
    } else if (d.purpose.category === PURPOSE_CATEGORY.BUSINESS) {
      businessDrivesCount++;
    }
  });

  const hasBusinessDrives = businessDrivesCount > 0;

  const reportDriveIds = drives.reduce((acc, next) => {
    if (next.isRoundTripStop) {
      acc = acc.concat([next.id, next.secondId]);
    } else {
      acc.push(next.id);
    }

    return acc;
  }, []);

  return (
    <div
      data-testid="multiple-drives"
      className="drive-details flex flex-col gap-[20px] laptop:gap-[15px]"
    >
      <Header
        onClose={onClose}
        value={totalDriveValueSum}
        totalDrives={drives.length}
        distance={totalDistance}
        unit={unit}
        currency={currency}
      />

      <PurposePicker
        selected={samePurpose ? drives[0].purpose : null}
        onStart={handleStartSelectPurpose}
        onSelect={handleSelectPurpose}
        onAddNewStarted={handleAddPurposeStarted}
        onAddNewCompleted={handleAddPurposeCompleted}
      />
      <Inputs
        isMultipleDrive
        onEditDrive={handleEditInputs}
        distance={totalDistance}
        parkingFees={getFieldValue("parkingFees")}
        tollFees={getFieldValue("tollFees")}
      />
      <SelectVehicle
        onStartAddDetails={({ type }) => {
          trackVehicleAddStarted({
            page: Pages.DRIVE_DETAILS,
            type: type,
          });
        }}
        onAddDetailsCompleted={({ type }) => {
          trackVehicleAddCompleted({
            page: Pages.DRIVE_DETAILS,
            type: type,
          });
        }}
        onSelect={handleSelectVehicle}
        selected={{
          id: getFieldValue("vehicleId"),
          type: getFieldValue("vehicleType"),
        }}
      />
      <Notes onEditDrive={handleEditNotes} />
      <CreateReport
        onStart={() => {
          trackReportCreationStarted({
            page: Pages.DRIVE_DETAILS,
            businessDrivesCount,
            personalDrivesCount,
            subscriptionId: userData.subscriptionType,
            orgId: team?.orgId,
            orgGroupId: team?.orgGroupId,
          });
        }}
        onFinish={(
          success,
          msg,
          { recipientType, contactRelationship, markAsReported }
        ) => {
          if (success) {
            trackReportCreationCompleted({
              page: Pages.DRIVE_DETAILS,
              businessDrivesCount,
              personalDrivesCount,
              subscriptionId: userData.subscriptionType,
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              recipientType,
              contactRelationship,
              markAsReported,
            });
            history.push({
              pathname: "/user/reports",
              state: {
                showReportMessage: msg,
              },
            });
          } else {
            trackReportCreationFailed({
              page: Pages.DRIVE_DETAILS,
              subscriptionId: userData.subscriptionType,
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reportCreationFailure: ReportCreationFailure.SOMETHING_WENT_WRONG,
            });
          }
        }}
        hasBusinessDrives={hasBusinessDrives}
        disabled={hasUnclassifedDrives}
        icon="file-text"
        className="w-full relative font-medium"
        secondary
        reportData={{
          driveIds: reportDriveIds,
        }}
      >
        Report
      </CreateReport>
      <Actions drives={drives} />
    </div>
  );
}
export default MultipleDrivesDetails;
