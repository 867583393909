import React, { useEffect, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Input from "src/components/elements/Input";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

export default TimePicker;
export const ELEMENT_ID = "TIME_PICKER_CARD";

registerElement(ELEMENT_ID, TimePickerCard);

function TimePicker({
  time,
  onUpdate,
  isTimeValid,
  className,
  contentType,
  hasError,
  icon,
}) {
  const [isSeparated, setIsSeparated] = useState(false);

  useEffect(() => {
    if (contentType == "separated") {
      setIsSeparated(true);
    }
  }, [contentType]);

  const triggerRef = useRef();

  const tp = useElement(ELEMENT_ID, {
    props: {
      onUpdate: (time) => {
        onUpdate(time);
      },
      onClickOutside: () => {
        tp.deactivate();
      },
      isTimeValid,
    },
  });

  const handlePickerClicked = () => {
    tp.activate({
      props: {
        trigger: triggerRef.current,
        time: { ...time },
      },
    });
  };

  const { hours, minutes, isAm } = time;

  const minutesFormatted = `0${minutes}`.slice(-2); // 1 -> 01, 11 -> 11

  const timeFormatted = `${hours}:${minutesFormatted} ${
    isSeparated ? "" : isAm ? "AM" : "PM"
  }`;

  let classes = [
    `w-full rounded-14-12 ${
      tp.open
        ? "border-blue-medium border-2 p-0"
        : "p-[1px] border border-border-1 hover:border-black/30"
    } h-40-35`,
  ];

  if (className) classes.push(className);

  if (hasError) {
    classes.push("border-red border-2");
  }

  return (
    <button
      ref={triggerRef}
      className={classes.join(" ")}
      onClick={handlePickerClicked}
    >
      <span className="flex justify-between items-center px-[10px]">
        <Icon name={icon ? icon : "clock"} />
        <Text className="flex-grow px-3">{timeFormatted}</Text>
        {isSeparated && <Text color="black/50">{isAm ? "AM" : "PM"}</Text>}
      </span>
    </button>
  );
}

function TimePickerCard({
  trigger,
  time,
  isTimeValid,
  onUpdate,
  onClickOutside,
}) {
  const [hours, setHours] = useState(time?.hours || 0);
  const [minutes, setMinutes] = useState(time?.minutes || 0);
  const [isAm, setIsAm] = useState(!!time?.isAm);

  const hoursInp = useRef();
  const minutesInp = useRef();

  const handleUpdate = (time) => {
    let newTime = { ...time };
    if (typeof isTimeValid === "function") {
      const validationRes = isTimeValid(newTime);
      if (!validationRes.isValid && validationRes.recommended) {
        newTime = { ...validationRes.recommended };
      }
    }
    setHours(newTime.hours);
    hoursInp.current.value = newTime.hours;

    setMinutes(newTime.minutes);
    minutesInp.current.value = newTime.minutes;

    setIsAm(newTime.isAm);

    if (typeof onUpdate === "function") {
      onUpdate(newTime);
    }
  };

  const handleChangeHours = (e) => {
    const val = e.target.value.slice(0, 2);

    let h = parseInt(val);
    if (isNaN(h) || h == 0) h = 1; // don't allow NaN or 0
    h = Math.abs(h);
    if (h > 12) h = 12;
    handleUpdate({ hours: h, minutes, isAm });
  };

  const handleChangeMinutes = (e) => {
    const val = e.target.value.slice(0, 2);

    let m = parseInt(val);
    if (isNaN(m)) m = 0;

    if (m > 59) m = 59;
    if (m < 0) m = 0;
    handleUpdate({ hours, minutes: m, isAm });
  };

  const handleHoursUp = () => {
    let h = parseInt(hours);

    if (isNaN(h)) h = 0;

    // edge cases:
    //  11AM + 1 -> 12PM
    //  11PM + 1 -> 12AM
    if (h == 11) {
      handleUpdate({ hours: 12, minutes, isAm: !isAm });
      return;
    }

    h++;
    if (h >= 12) h = 1;
    handleUpdate({ hours: h, minutes, isAm });
  };

  const handleHoursDown = () => {
    let h = parseInt(hours);

    if (isNaN(h)) h = 0;

    // edge cases:
    //  12PM - 1 -> 11AM
    //  12AM - 1 -> 11PM
    if (h == 12) {
      handleUpdate({ hours: 11, minutes, isAm: !isAm });
      return;
    }

    h--;
    if (h <= 0) h = 12;
    handleUpdate({ hours: h, minutes, isAm });
  };

  const handleMinutesUp = () => {
    let m = parseInt(minutes);

    if (isNaN(m)) m = 0;

    m++;
    if (m === 60) m = 0;
    handleUpdate({ hours, minutes: m, isAm });
  };

  const handleMinutesDown = () => {
    let m = parseInt(minutes);

    if (isNaN(m)) m = 0;

    m--;
    if (m < 0) m = 59;
    handleUpdate({ hours, minutes: m, isAm });
  };

  const handleToggleAm = () => {
    handleUpdate({ hours, minutes, isAm: !isAm });
  };

  return (
    <Pivoted
      pivot={trigger}
      onClickOutside={onClickOutside}
      style={{ zIndex: 20000 }}
    >
      <div className="bg-white p-2.5 rounded-20 shadow-xl">
        <div className="flex items-center">
          <div className="flex flex-col items-center">
            <IconButton
              name="caret"
              className="rotate-180"
              onClick={handleHoursUp}
            />
            <Input
              ref={hoursInp}
              className="my-2 w-[50px] laptop:w-[40px] [&_input]:leading-[35px] [&_input]:min-w-0 [&_input]:w-full [&_input]:px-1 [&_input]:text-center [&_input]:text-18 laptop:[&_input]:text-15"
              value={hours.toString()}
              onBlur={handleChangeHours}
            />
            <IconButton name="caret" onClick={handleHoursDown} />
          </div>
          <Text semibold className="mx-1 text-18 leading-[35px]">
            :
          </Text>
          <div className="flex flex-col items-center">
            <IconButton
              name="caret"
              className="rotate-180"
              onClick={handleMinutesUp}
            />
            <Input
              ref={minutesInp}
              className="my-2 w-[50px] laptop:w-[40px] [&_input]:leading-[35px] [&_input]:min-w-0 [&_input]:w-full [&_input]:px-1 [&_input]:text-center [&_input]:text-18 laptop:[&_input]:text-15"
              value={minutes.toString()}
              onBlur={handleChangeMinutes}
            />
            <IconButton name="caret" onClick={handleMinutesDown} />
          </div>
          <div className="flex flex-col  ml-3 items-center">
            <IconButton
              name="caret"
              className="rotate-180"
              onClick={handleToggleAm}
            />
            <div className="flex items-center justify-center my-2 w-[50px] laptop:w-[40px] h-40-35 rounded border border-border-1">
              <Text className="text-18 laptop:text-15">
                {isAm ? "AM" : "PM"}
              </Text>
            </div>
            <IconButton name="caret" onClick={handleToggleAm} />
          </div>
        </div>
      </div>
    </Pivoted>
  );
}
