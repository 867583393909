import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "src/components/context/UserContext";

import { tryRefreshToken } from "src/services/auth";
import { getTeam, getTeamsMe } from "src/services/teams";
import { trackTeamsUpgradeAbandoned } from "src/services/tracking";
import { timeout } from "src/services/utils";

import useQueryParams from "./useQueryParams";

export const TEAMS_NETWORK_STATES = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const useTeams = (user) => {
  const [teamNetworkState, setTeamNetworkState] = useState(
    TEAMS_NETWORK_STATES.IDLE
  );
  const [team, setTeam] = useState(null);
  const queryParams = useQueryParams();
  const history = useHistory();
  const { refreshUser } = useUser();

  useEffect(() => {
    const setupTeams = async () => {
      setTeamNetworkState(TEAMS_NETWORK_STATES.LOADING);

      // load team
      try {
        let [team, teamsMe] = await Promise.all([getTeam(), getTeamsMe()]);

        const isTeamAdmin = !!teamsMe?.isAdmin;
        const isTeamDriver = !!teamsMe?.isDriver;
        const hasTokenWithAdminPrivileges = !!user.isAdmin;
        const hasTokenWithTeamDriverPrivileges = !!user.isDriver;

        if (
          isTeamAdmin !== hasTokenWithAdminPrivileges ||
          isTeamDriver !== hasTokenWithTeamDriverPrivileges
        ) {
          await tryRefreshToken();
          await refreshUser();
        }

        // HACK: retry few times to make sure team really doesn't have a subscription
        // WHY: there might be delay(2-5 secs) after user buys a sub via stripe checkout
        // and our system actually knows about it(updated via webhook by stripe)
        {
          if (isTeamAdmin && !team.subscription) {
            let maxRetries = 3;
            while (maxRetries > 0) {
              maxRetries--;
              await timeout(2000);
              team = await getTeam();
              if (team.subscription) break;
            }
          }
        }
        setTeam(team);
        setTeamNetworkState(TEAMS_NETWORK_STATES.LOADED);

        const checkoutStatus =
          queryParams.get("checkout-status") ||
          queryParams.get("checkout_status");

        if (checkoutStatus === "cancel" && queryParams.get("code")) {
          const quantity = queryParams.get("quantity");
          const planCode = queryParams.get("code");
          const freeTrial = queryParams.get("free-trial");

          trackTeamsUpgradeAbandoned({
            orgId: team.orgId,
            orgGroupId: team.orgGroupId,
            seatsPurchased: parseInt(quantity) || 0,
            driverCount: team.subscription?.numberOfSeats || 0,
            freeTrial: freeTrial === "true",
            selectedPlan: planCode,
          });

          queryParams.delete("quantity");
          queryParams.delete("code");
          queryParams.delete("type");
          queryParams.delete("free-trial");
          // queryParams.delete("checkout-status");
          // queryParams.delete("checkout_status");

          history.replace({
            search: queryParams.toString(),
          });
        }
      } catch (err) {
        setTeamNetworkState(TEAMS_NETWORK_STATES.ERROR);
        setTeam(null);
      }
    };

    if (user?.id) setupTeams();
  }, [user?.id]);

  const refreshTeam = async (forceUpdate) => {
    if (!user?.id) return;
    try {
      if (forceUpdate || team?.hasAdminWithId(user?.id)) {
        const team = await getTeam();
        setTeam(team);
        return team;
      } else {
        window.location.href = "/";
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return { team, teamNetworkState, refreshTeam };
};

export default useTeams;
