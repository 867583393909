import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as UNSAVED_CHANGES_PROMPT_MODAL_ID } from "src/components/modals/UnsavedChangesPrompt";

import { useFlags } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";
import usePrompt from "src/hooks/usePrompt";

import {
  AutoClassificationPrefs,
  WorkHoursFailure,
  WorkHoursUpdateSource,
  trackFrequentDrivesPreferenceChanged,
  trackWorkHoursUpdateCompleted,
  trackWorkHoursUpdateFailed,
  trackWorkHoursUpdateStarted,
} from "src/services/tracking";
import {
  DAYS,
  ONE_DAY,
  WEEKDAYS,
  convertToTimePickerSlots,
  convertToUnix,
  convertWorkHoursObjectFromV1toV3,
  getDefaultHours,
  getUTCTimeString,
  isScheduleComplex,
} from "src/services/work-hours";

import {
  DELETE_WORK_HOURS,
  EDIT_WORK_HOURS,
  UPDATE_COMMON_ROUTES_OPT_OUT_MUTATION,
} from "src/graphql/mutations";
import { GET_WORK_HOURS } from "src/graphql/queries";

import { ComplexSchedule } from "./ComplexSchedule";
import { SimpleSchedule } from "./SimpleSchedule";

export default AutoClassificationPage;

const HOURS_DRIVES = {
  PERSONAL_HOURS: "PERSONAL_HOURS",
  BUSINESS_HOURS: "BUSINESS_HOURS",
  OUTSIDE_HOURS: "OUTSIDE_HOURS",
};

const AUTOCLASSIFICATION_RULE_TYPE = {
  BUSINESS: "business",
  PERSONAL: "personal",
  NONE: "none",
};

const workHoursV1DataToRender = [
  {
    title: "Auto-classify Personal Hours Drives ",
    text: `Turn this on to automatically classify your drives <b><i>outside</i></b> of your predetermined Work Hours as Personal.`,
    type: HOURS_DRIVES.PERSONAL_HOURS,
  },
];

const workHoursV3DataToRender = [
  {
    title: "Classify drives during Work Hours",
    text: "Enabling this will automatically classify all of your drives within your work hours as Business.",
    type: HOURS_DRIVES.BUSINESS_HOURS,
  },
  {
    title: "Classify drives outside Work Hours",
    text: "Enabling this will automatically classify all of your drives outside your work hours as Personal.",
    type: HOURS_DRIVES.OUTSIDE_HOURS,
  },
];

function AutoClassificationPage() {
  const { miqSettingsWorkHoursV3: isWorkHoursV3Enabled } = useFlags();
  const { userData } = useContext(UserDataContext);
  const [isComplexSchedule, setIsComplexSchedule] = useState(false);
  const [stateWorkHours, setStateWorkHours] = useState({});
  const [stateWorkHoursConverted, setStateWorkHoursConverted] = useState({});
  const [errorSlot, setErrorSlot] = useState({});
  const [flash, Flash] = useFlash();
  const history = useHistory();

  const workHoursDataToRender = isWorkHoursV3Enabled
    ? workHoursV3DataToRender
    : workHoursV1DataToRender;

  const handleDiscard = async () => {
    const canRoute = await Promise.resolve(() => {});
    if (canRoute) {
      if (unblockRef) {
        unblockRef.current();
      }
      unsavedChangesModal.deactivate();
      history.push(currentPath.current);
    }
  };

  const handleSave = async () => {
    const canRoute = await onSave();
    if (canRoute) {
      if (unblockRef) {
        unblockRef.current();
      }
      unsavedChangesModal.deactivate();
      history.push(currentPath.current);
    }
  };

  const unsavedChangesModal = useElement(UNSAVED_CHANGES_PROMPT_MODAL_ID, {
    props: {
      onCancel: handleDiscard,
      onOk: handleSave,
    },
  });
  const { unblockRef, currentPath, setDirty } = usePrompt({
    history,
    modalElement: unsavedChangesModal,
  });

  const {
    loading: workHoursQueryLoading,
    data: workHoursQueryData,
    refetch,
  } = useQuery(GET_WORK_HOURS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const normalizedWorkHours = isWorkHoursV3Enabled
        ? convertWorkHoursObjectFromV1toV3(data.workHours)
        : data.workHours;

      setStateWorkHours(normalizedWorkHours);
      setStateWorkHoursConverted(convertToTimePickerSlots(normalizedWorkHours));
      setIsComplexSchedule(isScheduleComplex(data));
    },
  });

  const [toggleFreqDrivesMutFn, { loading: freqDrivesLoading }] = useMutation(
    UPDATE_COMMON_ROUTES_OPT_OUT_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserData"],
      onQueryUpdated: (q) => q.refetch(),
    }
  );

  const [deleteWorkHoursMutFn, { loading: deleteWorkHoursLoading }] =
    useMutation(DELETE_WORK_HOURS, {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getWorkHours"],
      onQueryUpdated: (q) => q.refetch(),
      onCompleted: () =>
        flash(<Text>Work Hours updated</Text>, {
          type: FlashTypes.SAVED,
        }),
    });

  const [editWorkHoursMutFn, { loading: editWorkHoursLoading }] = useMutation(
    EDIT_WORK_HOURS,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getWorkHours"],
      onQueryUpdated: (q) => q.refetch(),
      onCompleted: () =>
        flash(<Text>Work Hours updated</Text>, {
          type: FlashTypes.SAVED,
        }),
    }
  );

  const toggleSwitch = async (key) => {
    let workHoursDataToCreate = {};

    switch (key) {
      case "OPT_OUT":
        {
          const isEnabled = Boolean(userData.commonRoutesOptOut);
          trackFrequentDrivesPreferenceChanged({ isEnabled });

          await toggleFreqDrivesMutFn({
            variables: {
              data: {
                commonRoutesOptOut: !isEnabled,
                origin: "commonRoutesOptOut",
              },
            },
          });
        }
        break;
      case HOURS_DRIVES.PERSONAL_HOURS:
        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.AUTO_CLASSIFY_PERSONAL,
          autoClassifyDuringWorkHoursPref: AutoClassificationPrefs.ENABLED,
          autoClassifyOutsideWorkHoursPref: AutoClassificationPrefs.DISABLED,
        });
        if (workHoursQueryData?.workHours) {
          await deleteWorkHoursMutFn();
        } else {
          await editWorkHoursMutFn({
            variables: {
              data: getDefaultHours(),
            },
          });
        }
        break;
      case HOURS_DRIVES.BUSINESS_HOURS:
        if (stateWorkHours && stateWorkHours.processRule) {
          workHoursDataToCreate = {
            processRule:
              stateWorkHours.processRule === AUTOCLASSIFICATION_RULE_TYPE.NONE
                ? AUTOCLASSIFICATION_RULE_TYPE.BUSINESS
                : AUTOCLASSIFICATION_RULE_TYPE.NONE,
            processRuleOutsideHours:
              stateWorkHours.processRuleOutsideHours ??
              AUTOCLASSIFICATION_RULE_TYPE.NONE,
            shifts: stateWorkHours.shifts,
          };
        } else {
          workHoursDataToCreate = {
            processRule: AUTOCLASSIFICATION_RULE_TYPE.BUSINESS,
            processRuleOutsideHours: AUTOCLASSIFICATION_RULE_TYPE.NONE,
            shifts: getDefaultHours().shifts,
          };
        }

        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.AUTO_CLASSIFY_BUSINESS,
          autoClassifyDuringWorkHoursPref:
            workHoursDataToCreate.processRule !==
            AUTOCLASSIFICATION_RULE_TYPE.NONE,
          autoClassifyOutsideWorkHoursPref:
            workHoursDataToCreate.processRuleOutsideHours !==
            AUTOCLASSIFICATION_RULE_TYPE.NONE,
        });

        await editWorkHoursMutFn({
          variables: {
            data: workHoursDataToCreate,
          },
        });

        break;
      case HOURS_DRIVES.OUTSIDE_HOURS:
        workHoursDataToCreate = {
          processRule:
            stateWorkHours?.processRule ?? AUTOCLASSIFICATION_RULE_TYPE.NONE,
          processRuleOutsideHours:
            stateWorkHours &&
            stateWorkHours.processRuleOutsideHours &&
            stateWorkHours.processRuleOutsideHours ===
              AUTOCLASSIFICATION_RULE_TYPE.NONE
              ? AUTOCLASSIFICATION_RULE_TYPE.PERSONAL
              : AUTOCLASSIFICATION_RULE_TYPE.NONE,
          shifts: stateWorkHours
            ? stateWorkHours.shifts
            : getDefaultHours().shifts,
        };

        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.AUTO_CLASSIFY_PERSONAL,
          autoClassifyDuringWorkHoursPref:
            workHoursDataToCreate.processRule !==
            AUTOCLASSIFICATION_RULE_TYPE.NONE,
          autoClassifyOutsideWorkHoursPref:
            workHoursDataToCreate.processRuleOutsideHours !==
            AUTOCLASSIFICATION_RULE_TYPE.NONE,
        });

        await editWorkHoursMutFn({
          variables: {
            data: workHoursDataToCreate,
          },
        });
    }
  };

  const udpateStateWorkHours = (isComplex) => {
    const isOriginallyComplex = isScheduleComplex(workHoursQueryData);
    let workHours = workHoursQueryData?.workHours;
    if (!isComplex && isOriginallyComplex) workHours = getDefaultHours();
    let workHoursConverted = convertToTimePickerSlots(workHours);
    if (!isComplex) {
      WEEKDAYS.map((day, index) => {
        workHoursConverted[day][0] = {
          start: new Date(workHoursConverted[day][0].start.setDate(index + 1)),
          end: new Date(workHoursConverted[day][0].end.setDate(index + 1)),
        };
      });
    }
    setStateWorkHoursConverted(workHoursConverted);
    setStateWorkHours(workHours);
  };

  const changeScheduleType = (isComplex) => {
    setIsComplexSchedule(isComplex);
    setErrorSlot({});

    udpateStateWorkHours(isComplex);

    setDirty((isChanged) => {
      if (!isChanged) {
        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.CHANGE_HOURS,
          autoClassifyDuringWorkHoursPref: false,
          autoClassifyOutsideWorkHoursPref: false,
        });
      }
      return true;
    });
  };

  const onSimpleScheduleChange = (workHoursConverted) => {
    setDirty((isChanged) => {
      if (!isChanged) {
        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.CHANGE_HOURS,
          autoClassifyDuringWorkHoursPref: false,
          autoClassifyOutsideWorkHoursPref: false,
        });
      }
      return true;
    });

    setStateWorkHoursConverted(workHoursConverted);
    setErrorSlot({});
  };

  const onComplexScheduleChange = (workHoursConverted) => {
    setDirty((isChanged) => {
      if (!isChanged) {
        trackWorkHoursUpdateStarted({
          src: WorkHoursUpdateSource.CHANGE_HOURS,
          autoClassifyDuringWorkHoursPref: false,
          autoClassifyOutsideWorkHoursPref: false,
        });
      }
      return true;
    });

    setStateWorkHoursConverted(workHoursConverted);
    setErrorSlot({});
  };

  const onSave = async () => {
    let shifts = [];
    let hasErrorObject = {};
    setErrorSlot({});

    DAYS.forEach((day) => {
      const whForDay = stateWorkHoursConverted[day];
      whForDay?.forEach((slot, index) => {
        // if start time is great or equal to end time show error
        //added "getUTCTimeString(slot.end) != "00:00:00"" because we are faceing issue connected 12 am or pm
        const startUTCString = getUTCTimeString(slot.start);
        const endUTCString = getUTCTimeString(slot.end);
        if (startUTCString >= endUTCString && endUTCString != "00:00:00") {
          hasErrorObject.errorSlotDay = day;
          hasErrorObject.errorSlotIndex = index;
        }
        // if previous slot end time bigger than current slot start time and
        // previous slot end  time smaller than current slot and time
        // show error
        else if (
          whForDay[index - 1] &&
          getUTCTimeString(whForDay[index - 1].end) > startUTCString &&
          getUTCTimeString(whForDay[index - 1].end) < endUTCString
        ) {
          hasErrorObject.errorSlotDay = day;
          hasErrorObject.errorSlotIndex = index;
        }
        // if start and end time are equal, show error
        else if (startUTCString == endUTCString) {
          hasErrorObject.errorSlotDay = day;
          hasErrorObject.errorSlotIndex = index;
        }

        shifts.push([convertToUnix(slot.start), convertToUnix(slot.end)]);
      });
    });

    setErrorSlot(hasErrorObject);
    shifts.forEach((shift, key) => {
      // if converted shift's end time is less than start time, add one day for the end
      // we can do it because already added validation
      // we are faceing this issue because converting dates to unix
      // all is coming from js Date object differences
      if (shift[1] < shift[0]) {
        shift[1] = shift[1] + ONE_DAY;
      }
      // if (shifts[key - 1] && shifts[key][0] < shifts[key - 1][0]) {
      // if current shift's start time is less than previous shift's start time and
      // current shift's end time is great than previous shifts start time,
      // add one day in milliseconds
      if (
        shifts[key - 1] &&
        shifts[key][0] < shifts[key - 1][0] &&
        shifts[key][1] > shifts[key - 1][0]
      ) {
        shift[0] = shift[0] + ONE_DAY;
        shift[1] = shift[1] + ONE_DAY;
      }
    });

    const workHoursDataToUpdate = {
      processRule:
        stateWorkHours?.processRule ?? AUTOCLASSIFICATION_RULE_TYPE.NONE,
      processRuleOutsideHours:
        stateWorkHours?.processRuleOutsideHours ??
        AUTOCLASSIFICATION_RULE_TYPE.NONE,
      shifts: [...shifts],
    };

    if (Object.keys(hasErrorObject).length === 0) {
      await editWorkHoursMutFn({
        variables: {
          data: workHoursDataToUpdate,
        },
      });
      setDirty(false);
      trackWorkHoursUpdateCompleted({
        isComplex: isComplexSchedule,
        autoClassifyDuringWorkHoursPreference:
          workHoursDataToUpdate.processRule !==
          AUTOCLASSIFICATION_RULE_TYPE.NONE,
        autoClassifyOutsideWorkHoursPreference:
          workHoursDataToUpdate.processRuleOutsideHours !==
          AUTOCLASSIFICATION_RULE_TYPE.NONE,
      });
    } else {
      trackWorkHoursUpdateFailed({
        reason: WorkHoursFailure.OVERLAPPING_TIME,
      });
    }

    return "true";
  };

  const onCancel = () => {
    refetch();
    setDirty(false);
  };

  const loading =
    freqDrivesLoading ||
    deleteWorkHoursLoading ||
    workHoursQueryLoading ||
    editWorkHoursLoading;

  const isModified =
    JSON.stringify(convertToTimePickerSlots(stateWorkHours)) !==
    JSON.stringify(stateWorkHoursConverted);

  return (
    <>
      {loading ? (
        <Loader className="p-8" />
      ) : (
        <>
          <div className="max-w-[702px] laptop:min-w-[702px] p-[20px] laptop:p-[15px] relative">
            <div className="border-beige-medium border-b flex justify-between gap-[40px] pb-[25px]">
              <div>
                <h6 className="mb-[5px]">Frequent Drives</h6>
                <Text paragraph lg>
                  Get drives automatically classified in the future by teaching
                  MileIQ about the places you drive the most.
                </Text>
              </div>
              <Switcher
                lg
                containerClassName="self-start"
                isOn={!userData.commonRoutesOptOut}
                label={!userData.commonRoutesOptOut ? "Yes" : "No"}
                onChange={() => toggleSwitch("OPT_OUT")}
              />
            </div>
            <h6 className="mt-[20px] laptop:mt-[15px]">Work Hours</h6>
            {workHoursDataToRender.map((itemToRender, index) => {
              return (
                <div
                  key={itemToRender.title}
                  className={`
                    border-beige-medium 
                    ${
                      index < Object.keys(workHoursDataToRender).length - 1
                        ? "border-b"
                        : ""
                    } 
                    flex justify-between gap-[40px] py-[15px]
                  `}
                >
                  <div>
                    <Text paragraph bold className="mb-[5px]">
                      {itemToRender.title}
                    </Text>
                    <Text
                      paragraph
                      color="black/70"
                      dangerouslySetInnerHTML={{ __html: itemToRender.text }}
                    />
                  </div>
                  {itemToRender.type === HOURS_DRIVES.OUTSIDE_HOURS && (
                    <Switcher
                      lg
                      isOn={
                        stateWorkHours &&
                        stateWorkHours.processRuleOutsideHours &&
                        stateWorkHours.processRuleOutsideHours !== "none"
                      }
                      label={
                        stateWorkHours &&
                        stateWorkHours.processRuleOutsideHours !== "none"
                          ? "Yes"
                          : "No"
                      }
                      onChange={() => toggleSwitch(itemToRender.type)}
                    />
                  )}
                  {itemToRender.type !== HOURS_DRIVES.OUTSIDE_HOURS && (
                    <Switcher
                      lg
                      isOn={
                        stateWorkHours &&
                        stateWorkHours.processRule !==
                          AUTOCLASSIFICATION_RULE_TYPE.NONE
                      }
                      label={
                        stateWorkHours &&
                        stateWorkHours.processRule !==
                          AUTOCLASSIFICATION_RULE_TYPE.NONE
                          ? "Yes"
                          : "No"
                      }
                      onChange={() => toggleSwitch(itemToRender.type)}
                    />
                  )}
                </div>
              );
            })}
            {stateWorkHours &&
              (stateWorkHours.processRule !==
                AUTOCLASSIFICATION_RULE_TYPE.NONE ||
                stateWorkHours.processRuleOutsideHours !==
                  AUTOCLASSIFICATION_RULE_TYPE.NONE) && (
                <>
                  <div className="">
                    <Text
                      paragraph
                      md
                      className="mb-[10px] mt-[5px] laptop:mt-0"
                    >
                      My Work Hours:
                    </Text>
                    {!isComplexSchedule ? (
                      <SimpleSchedule
                        workHours={stateWorkHours}
                        onChange={onSimpleScheduleChange}
                        hasError={errorSlot}
                      />
                    ) : (
                      <ComplexSchedule
                        workHours={stateWorkHours}
                        onChange={onComplexScheduleChange}
                        hasError={errorSlot}
                      />
                    )}
                    <button
                      className="flex items-center bg-transparent border-none outline-none
                          font-medium text-blue mt-[25px]"
                      onClick={() => changeScheduleType(!isComplexSchedule)}
                    >
                      {isComplexSchedule ? (
                        <>
                          <Icon
                            name="minus"
                            color="blue"
                            className="mr-2 self-center"
                          />
                          Simplify my schedule
                        </>
                      ) : (
                        <>
                          <Icon name="plus" color="blue" className="mr-2" />I
                          have a more complex schedule
                        </>
                      )}
                    </button>
                  </div>
                  <div className="mt-[25px] mb-[25px] flex">
                    <Button
                      onClick={onSave}
                      className="font-medium mr-2.5"
                      disabled={!isModified}
                    >
                      Save
                    </Button>
                    <Button
                      ghost
                      type="button"
                      disabled={!isModified}
                      className="font-medium"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
          </div>
        </>
      )}
      {Flash}
    </>
  );
}
