import "src/models/typings";

import * as braze from "@braze/web-sdk";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { AvoInspector, AvoInspectorEnv } from "avo-inspector";
import mixpanel from "mixpanel-browser";
import * as rudderanalytics from "rudder-sdk-js";

import Avo from "./Avo";

function createAvo(
  appName,
  apiKey,
  env,
  version,
  systemProps,
  destinationOptions,
  defaultEventProps
) {
  let inspector = new AvoInspector({
    appName,
    version,
    apiKey,
    env,
  });

  let segment;

  Avo.initAvo(
    {
      env,
      inspector,
      webDebugger: 1,
      webDebuggerOptions: {
        position: Avo.WebDebuggerPosition.BottomLeft({ bottom: 80, left: 20 }),
      },
    },
    // system properties
    systemProps,
    // destinationOptions
    destinationOptions,
    // Rudderstack
    {
      make: (env, key) => {
        const opts = {};
        if (env !== "prod") opts.logLevel = "DEBUG";
        rudderanalytics.load(
          key,
          "https://mileiqjoeplpv.dataplane.rudderstack.com",
          opts
        );
        rudderanalytics.page();
      },
      logEvent: (eventName, eventProperties) => {
        rudderanalytics.track(eventName, eventProperties);
      },
      setUserProperties: () => {},
      identify: (userId) => rudderanalytics.identify(userId),
      unidentify: () => rudderanalytics.reset(),
    },
    // SegementTeamsDestination
    {
      make: (env, key) => {
        segment = AnalyticsBrowser.load({ writeKey: key });
      },
      logEvent: function logEvent(eventName, eventProperties) {
        segment.track(eventName, eventProperties);
      },
      setUserProperties: () => {},
      identify: (userId) => segment.identify(userId),
      unidentify: () => segment.reset(),
    },
    // Mixpanel
    {
      make: (env, key) => {
        mixpanel.init(key, { debug: env === "dev" });
      },
      logEvent: (eventName, eventProperties) => {
        mixpanel.track(eventName, eventProperties);
      },
      setUserProperties: function (userId, userProperties) {
        mixpanel.people.set(userProperties);
      },
      identify: (userId) => mixpanel.identify(userId),
      unidentify: () => mixpanel.reset(),
    },
    // Braze
    {
      make: () => {
        braze.initialize(process.env.BRAZE_KEY, {
          baseUrl: "sdk.iad-01.braze.com",
          enableLogging: process.env.APP_ENV !== "production",
          allowUserSuppliedJavascript: true,
        });
        braze.automaticallyShowInAppMessages();
        braze.openSession();
      },
      logEvent: (eventName, eventProperties) => {
        braze.logCustomEvent(eventName, eventProperties);
      },
      setUserProperties: (userId, userProperties) => {
        for (const key in userProperties) {
          braze.getUser().setCustomUserAttribute(key, userProperties[key]);
        }
      },
      identify: (userId) => braze.changeUser(userId),
      unidentify: () => {
        // from Braze docs:
        // We recommend against changing the user ID when a user logs out,
        // as it makes you unable to target the previously logged-in user with reengagement campaigns.
        // If you anticipate multiple users on the same device,
        // but only want to target one of them when your app is in a logged-out state,
        // we recommend separately keeping track of the user ID you want to target
        // while logged out and switching back to that user ID as part of your app’s logout process.
      },
    }
  );

  function track(eventName, props) {
    Avo[eventName](Object.assign({}, defaultEventProps, props));
  }

  function identify(user) {
    rudderanalytics.identify(user.id, {
      email: user.email,
    });
    segment.identify(user.id, {
      email: user.email,
    });
    mixpanel.identify(user.distinctId || user.id);
    braze.changeUser(user.id);
  }

  window.Avo = Avo;

  return {
    inspector,
    identify,
    track,
  };
}

let __avo_appName = "Web Dashboard";
let __avo_apiKey = Avo.avoInspectorApiKey;
let __avo_env = AvoInspectorEnv.Dev;
let __avo_version = "v1";
let __avo_systemProps = null;
let __avo_defaultEventProps = {
  platform: Avo.Platform.WEB,
};

__avo_env =
  process.env.AVO_ENV === "production"
    ? AvoInspectorEnv.Prod
    : AvoInspectorEnv.Dev;

var __avo_destinationOptions = {};

const avo = createAvo(
  __avo_appName,
  __avo_apiKey,
  __avo_env,
  __avo_version,
  __avo_systemProps,
  __avo_destinationOptions,
  __avo_defaultEventProps
);

export default avo;

export { Avo, createAvo };
