import React, { useEffect } from "react";

import { trackTeamsReverseTrialAvailablePageViewed } from "src/services/tracking";

import IllustrationTryTeams from "public/assets/img/reverse-trial/try-teams.png";
import IllustrationTryTeams2x from "public/assets/img/reverse-trial/try-teams@2x.png";

import { FeaturingItemListTemplate } from "../templates/FeaturingItemListTemplate";

export const ReverseTrialAvailableScreen = ({
  daysInTrial,
  handleTryForFree,
  handleCreateTeam,
}) => {
  useEffect(() => {
    trackTeamsReverseTrialAvailablePageViewed();
  }, []);
  return (
    <FeaturingItemListTemplate
      title="Try MileIQ for Teams"
      description={`Save time on mileage paperwork with Teams. Try it free for ${daysInTrial} days.`}
      illustration={
        <img
          className="w-full h-full object-cover object-left-top"
          src={IllustrationTryTeams2x}
          srcSet={`${IllustrationTryTeams} 300w, ${IllustrationTryTeams2x} 600w`}
          sizes="(max-width: 400px) 300px, 600px"
          alt="illustration"
        />
      }
      items={[
        {
          icon: "reports",
          title: "One place for all mileage reports",
          subtitle:
            "Get a reporting dashboard for all of your team's drives — no more digging through the inbox.",
        },
        {
          icon: "checked-circle-black",
          title: "Approve drives faster",
          subtitle:
            "Name locations your team visits often to validate drives at a glance and approve with a click.",
        },
        {
          icon: "card",
          title: "No more billing headaches",
          subtitle:
            "Add or remove drivers from your team anytime. No need to share company credit cards or reimburse drivers for individual MileIQ subscriptions.",
        },
      ]}
      primaryCTA={{ label: "Try for free", onClick: handleTryForFree }}
      secondaryCTA={{
        label: "Skip trial and subscribe",
        onClick: handleCreateTeam,
      }}
      disclaimer="Don't worry — any drives you or your drivers previously tracked with MileIQ won't be affected.
        "
    />
  );
};
