import React, { useRef } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

const TooltipIcon = ({ text, iconName }) => {
  const iconRef = useRef();
  return (
    <>
      <Tooltip
        triggerRef={iconRef}
        alignX="center"
        offset={{
          y: 25,
        }}
      >
        <Text sm nowrap>
          {text}
        </Text>
      </Tooltip>
      <Icon
        iconRef={iconRef}
        className="scale-[0.9] laptop:scale-[0.75]"
        name={iconName}
      />
    </>
  );
};

const DriveTypes = ({ drive, withRoutes }) => {
  return (
    <div className="flex gap-1 ml-5">
      {drive.isAutoClassified && (
        <TooltipIcon
          text="Work hours"
          iconName={
            drive.isAutoClassifiedWorkHours
              ? "auto-classified-work-hours"
              : "auto-classified-frequent"
          }
        />
      )}
      {drive.isJoinedDrive ? (
        <TooltipIcon text="Joined drive" iconName="joined-drive" />
      ) : (
        <>
          {withRoutes && drive.isRoundTrip && (
            <TooltipIcon
              text="Round trip without a stop"
              iconName="round-trip"
            />
          )}
          {withRoutes && drive.isRoundTripStop && (
            <TooltipIcon
              text="Round trip with a stop"
              iconName="round-trip-stop"
            />
          )}
        </>
      )}
    </div>
  );
};

export default DriveTypes;
