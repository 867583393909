import { useState } from "react";

import { useUserData } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import TextToDownload from "src/components/blocks/TextToDownload";

import PageLayout from "src/components/PageLayout";

import Step1Illustration from "public/assets/img/illustrations/cold-start-step-1.png";
import DriveCardllustration from "public/assets/img/illustrations/drive-card.png";
import DriveCardllustration2x from "public/assets/img/illustrations/drive-card@2x.png";

export function GetStartedPage() {
  const { userData } = useUserData();

  const downloadDone = userData?.mobileAppUsed;
  const loginDone = userData?.mobileAppUsed;
  const takeDriveDone = userData?.hasRealDrivesTaken;

  return (
    <>
      <PageLayout className="page-create-team bg-beige overflow-x-hidden">
        <PageLayout.Main>
          <div className="px-[15px] py-[15px]">
            <MobMenu />
          </div>
          <div className="w-full min-w-[900px] max-w-[1400px] min-h-full flex justify-center pt-[80px]">
            <div>
              <h4>You're almost there!</h4>
              <Text paragraph className="mt-[6px] text-black/80">
                Let’s make sure you’re set up for success. Complete these three
                steps to get up and running. It's as easy as 1, 2, 3.
              </Text>
              <div className="w-[886px] my-[30px] flex flex-col gap-[20px]">
                <Step
                  num={1}
                  done={downloadDone}
                  title={"Step 1: Download the MileIQ mobile app"}
                  current={!downloadDone && !loginDone && !takeDriveDone}
                >
                  <div className="flex gap-[60px]">
                    <div>
                      <Text>
                        Download the MileIQ app on your phone to start tracking
                        your drives
                      </Text>
                      <div className="mt-[30px] flex flex-col gap-[10px]">
                        <TextToDownload />
                      </div>
                    </div>
                    <div className="overflow-hidden -mb-[25px] flex-shrink-0 w-[270px] h-[250px]">
                      <img src={Step1Illustration} alt="illustration" />
                    </div>
                  </div>
                </Step>
                <Step
                  num={2}
                  done={loginDone}
                  current={downloadDone && !loginDone && !takeDriveDone}
                  title={
                    "Step 2: Log in to the mobile app using the same credentials you used to sign up"
                  }
                >
                  <ol className="flex flex-col gap-[3px] list-decimal list-inside leading-[155%]">
                    <li>
                      <Text color="black/80">
                        Once you have opened the app, select{" "}
                        <Text bold color="black">
                          “Log in”
                        </Text>
                      </Text>
                    </li>
                    <li>
                      <Text color="black/80">
                        Log in using the email address and password you just
                        registered with.
                      </Text>
                    </li>
                  </ol>
                </Step>
                <Step
                  num={3}
                  done={takeDriveDone}
                  current={downloadDone && loginDone && !takeDriveDone}
                  title={"Step 3: Capture your first drive"}
                  last
                >
                  <div className="grid grid-cols-2 items-start justify-center">
                    <Text color="black/80" className="leading-[155%]">
                      After granting the necessary permissions and ensuring
                      drive detection is activated, let the app take care of the
                      rest. It operates in the background, automatically logging
                      your drives which will appear a few minutes after you've
                      reached your destination.
                    </Text>
                    <div className="flex justify-center">
                      <img
                        className="w-[220px] -mb-[26px]"
                        src={DriveCardllustration}
                        srcSet={`${DriveCardllustration} 236w, ${DriveCardllustration2x} 472w`}
                        sizes="(max-width: 1280px) 236px, 472px"
                        alt="illustration"
                      />
                    </div>
                  </div>
                </Step>
              </div>
            </div>
          </div>
        </PageLayout.Main>
      </PageLayout>
    </>
  );
}

function Step({ done, title, num, children, current, last }) {
  const [open, setOpen] = useState(current);
  function handleClick() {
    setOpen((o) => !o);
  }
  let expandable = true;
  if (done || (current && last)) {
    expandable = false;
  }
  return (
    <div
      className={`w-full rounded overflow-hidden ${
        done ? "bg-green-pastel" : "bg-white shadow-sm"
      }`}
    >
      <div
        className={`flex items-center gap-[25px] select-none ${
          expandable ? "cursor-pointer" : ""
        } p-[25px] ${open ? "pb-0" : ""}`}
        onClick={expandable ? handleClick : null}
      >
        {done ? (
          <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-green">
            <Icon name="check-mark" color="white" />
          </div>
        ) : (
          <div
            className={`w-[40px] h-[40px] flex items-center justify-center rounded-full ${
              current ? "bg-black text-white" : "bg-beige-medium"
            }`}
          >
            <Text
              semibold
              className="group-[.os-windows]:pb-[2px] group-[.os-macos]:pt-[1px]"
            >
              {num}
            </Text>
          </div>
        )}
        <Text custom className="text-[16px] font-medium">
          {title}
        </Text>
        {expandable ? (
          <Icon
            name="chevron-down"
            className={`ml-auto ${open ? "rotate-180" : ""}`}
          />
        ) : null}
      </div>
      {!done && open ? (
        <div className="px-[90px] pb-[25px] animate-fade-in-200">
          {children}
        </div>
      ) : null}
    </div>
  );
}
