import React, { useState } from "react";

import MiqCheckbox from "src/components/elements/Checkbox";
import Dropdown from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";

const UNSPECIFIED_VEHICLE = { id: "VEHICLE_UNSPECIFIED", name: "Unassigned" };

const getVehicles = (selection) =>
  Object.keys(selection)
    .filter((key) => selection[key].checked)
    .map((key) => ({
      id: key,
      label: selection[key].label,
    }));

const getLabel = (selection) => {
  const vehicles = getVehicles(selection);
  if (vehicles.length === 1) {
    return vehicles[0].label;
  } else if (vehicles.length >= 2) {
    return `Vehicles ${vehicles.length}`;
  }

  return "Any vehicle";
};

const VehicleDropdown = ({ onSelect, vehicles }) => {
  const [selectedVehicles, setSelectedVehicles] = useState({});
  const [open, setOpen] = useState(false);

  const _updateSelection = (newSelection) => {
    setSelectedVehicles(newSelection);
    onSelect(getVehicles(newSelection));
    setOpen(false);
  };

  const handleUpdateSelection = (checked, v) => {
    const label = v.name || v.model || v.make;
    if (checked) {
      if (v.id === UNSPECIFIED_VEHICLE.id) {
        _updateSelection({
          [UNSPECIFIED_VEHICLE.id]: {
            checked: true,
            label: UNSPECIFIED_VEHICLE.name,
          },
        });
        return;
      } else {
        _updateSelection({
          ...selectedVehicles,
          [v.id]: {
            checked,
            label,
          },
          [UNSPECIFIED_VEHICLE.id]: {
            checked: false,
            label: UNSPECIFIED_VEHICLE.name,
          },
        });
        return;
      }
    }

    _updateSelection({
      ...selectedVehicles,
      [v.id]: {
        checked,
        label,
      },
    });
  };

  const currentVehicles =
    vehicles.length > 0
      ? [...vehicles.filter((v) => v.isActive), UNSPECIFIED_VEHICLE]
      : [UNSPECIFIED_VEHICLE];

  return (
    <Dropdown
      testId="vehicles"
      icon="car-side"
      label={getLabel(selectedVehicles)}
      open={open}
      triggerClassName="bg-blue-mediumLight"
      hasValue={getVehicles(selectedVehicles).length}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      suffix={
        Object.keys(selectedVehicles).length > 0 && !open ? (
          <IconButton
            name="close"
            onClick={(e) => {
              e.stopPropagation();
              _updateSelection({});
            }}
          />
        ) : null
      }
    >
      <div className="max-h-[600px] overflow-y-auto">
        {currentVehicles.map((v) => {
          const label = v.name || v.model || v.make;
          return (
            <div className="p-3" key={v.id}>
              <MiqCheckbox
                testId="vehicle-chx"
                onChange={(checked) => handleUpdateSelection(checked, v)}
                checked={selectedVehicles[v.id]?.checked}
                label={label}
              />
            </div>
          );
        })}
      </div>
    </Dropdown>
  );
};

export default VehicleDropdown;
