import { useContext } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import MiqMobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import PageLayout from "src/components/PageLayout";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import { TEAMS_SUBSCRIPTION_PLANS } from "src/models/team-subscription";

import {
  teamsUpgradeFeatures,
  teamsUpgradeSources,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";

import Illustration2x from "public/assets/img/illustrations/team-location-upsell-illustration-2x.png";
import Illustration from "public/assets/img/illustrations/team-location-upsell-illustration.png";

export default TeamLocationsUpsell;

function TeamLocationsUpsell() {
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.TEAMS_LOCATIONS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.TEAMS_LOCATIONS,
      });
    },
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  const handleUpgradeToTeamsPro = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startGetProPlanFlow();
  };

  return (
    <PageLayout className="page-team-locations bg-beige">
      <PageLayout.Main>
        <div className="lg:h-full flex gap-16 xl:gap-32 justify-between items-start lg:items-center pt-8 pl-8 pr-8 lg:pr-0 lg:pt-0 lg:pl-[124px]">
          <div className="lg:max-w-[345px]">
            <div className="flex flex-col items-start gap-4">
              <MiqMobMenu />
              <Text custom className="text-blue text-15 font-medium">
                Introducing
              </Text>
            </div>
            <h3 className="mt-4">Team Locations</h3>
            <Text custom paragraph className="mt-[30px] text-15">
              Team Locations helps you keep your reports organized by letting
              you assign names to places your team visits often.
            </Text>
            <Text custom paragraph className="mt-5 text-15">
              Upgrade to Teams Pro to start using Team Locations.
            </Text>
            <Button
              lg
              onClick={handleUpgradeToTeamsPro}
              className="mt-[30px] font-medium w-[160px]"
            >
              Upgrade now
            </Button>
          </div>
          <img
            src={Illustration}
            srcSet={`${Illustration} 540w, ${Illustration2x} 1080w`}
            sizes="(max-width: 1280px) 450px, 540px"
            alt="illustration"
            className="hidden lg:block mr-32"
          />
        </div>
      </PageLayout.Main>
      {renderGetProPlanFlow()}
    </PageLayout>
  );
}
