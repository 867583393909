import React from "react";

import Icon from "src/components/elements/Icon";

export default MiqFlash;

export const FlashTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  SAVING: "SAVING",
  SAVED: "SAVED",
  SPECIAL: "SPECIAL",
  BLACK: "BLACK",
};

function MiqFlash({ visible, msg, type, className }) {
  let typeClass;
  let typeIcon;
  switch (type) {
    case FlashTypes.SAVING:
      typeClass = "miq-flash-msg-saving";
      break;
    case FlashTypes.SAVED:
      typeClass = "miq-flash-msg-saved";
      typeIcon = (
        <Icon
          name="checked-circle"
          color="white"
          className="stroke-saved mr-1 scale-75"
        />
      );
      break;
    case FlashTypes.ERROR:
      typeClass = "miq-flash-msg-error";
      typeIcon = <Icon name="close-in-circle" color="white" className="mr-2" />;
      break;
    case FlashTypes.SPECIAL:
      typeClass = "miq-flash-msg-special";
      break;
    case FlashTypes.BLACK:
      typeIcon = <Icon name="transparent-checked-circle" className="mr-2" />;
      typeClass = "miq-flash-msg-black";
      break;
    case FlashTypes.SUCCESS:
    default:
      typeClass = "miq-flash-msg-success";
      typeIcon = (
        <Icon
          name="checked-circle"
          color="white"
          className="stroke-green mr-2"
        />
      );
      break;
  }
  return (
    <div
      className={`miq-flash-msg ${typeClass} ${
        visible ? "on-screen" : "off-screen"
      } ${className || ""}`}
    >
      <div className="miq-flash-msg-content text-white flex items-center">
        {typeIcon || ""}
        <div>{msg}</div>

        {/* Useful for transparent foreground colors */}
        <div className="miq-flash-msg-content-bg" />
      </div>
    </div>
  );
}
