import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { useNotifications } from "src/components/context/NotificationsContext";
import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import { DUNNING_UNABLE_TO_JOIN_TEAM_MODAL_ID } from "src/components/modals/DunningUnableToJoinTeam";

import { useCountdown } from "src/hooks/useCountdown";
import useFlash from "src/hooks/useFlash";
import useQueryParams from "src/hooks/useQueryParams";

import { SUBSCRIPTION_STATUS } from "src/services/billing";

import InviteDog from "public/assets/img/invite-dog.svg";

export function TeamInvitedPage() {
  const [flash, Flash] = useFlash();
  const { team } = useContext(TeamContext);
  const { startCountdown, secondsLeft } = useCountdown();
  const { notifications, acceptInvite, loading } = useNotifications();
  const searchParams = useQueryParams();

  const unableToJoinTeamModal = useElement(
    DUNNING_UNABLE_TO_JOIN_TEAM_MODAL_ID,
    {
      props: {
        onClose: () => {
          unableToJoinTeamModal.deactivate();
        },
      },
    }
  );

  const tokenFromSearchParams = searchParams?.get?.("accept-invite");

  const teamName = notifications?.team_name;
  const isAdmin = notifications?.is_admin;
  const isDriver = notifications?.is_driver;
  const isAccepted = !!notifications?.accepted_at;
  const link = notifications?.invite_link;
  const adminEmail = (notifications?.admin || team?.owner)?.email;
  const isTeamSubscriptionExpired = notifications?.is_team_subscription_expired;

  const handleSuccess = () => {
    if (isAdmin) {
      startCountdown(3);
    } else {
      flash("You've joined your team!", {
        type: FlashTypes.SAVED,
      });
    }
  };

  const handleError = (e) => {
    console.error(e);

    if (e.message === "Member cannot accept invite from an expired team") {
      unableToJoinTeamModal.activate();
      return;
    }
    flash("Something went wrong. Please try again.", {
      type: FlashTypes.ERROR,
    });
  };

  const handleAccept = async (token, acceptedFromEmailLink) => {
    try {
      if (isTeamSubscriptionExpired) {
        unableToJoinTeamModal.activate();
        return;
      }

      await acceptInvite(token, acceptedFromEmailLink);
      handleSuccess();
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (secondsLeft === 1) {
      // note: history.push is not working here  ¯\_(ツ)_/¯
      window.location.href = "/teams/users?joined";
    }
  }, [secondsLeft]);

  useEffect(() => {
    if (tokenFromSearchParams) {
      searchParams?.delete?.("accept-invite");
      const acceptedFromEmailLink = true;
      handleAccept(tokenFromSearchParams, acceptedFromEmailLink);
    }
  }, [tokenFromSearchParams]);

  if (loading || secondsLeft) {
    return (
      <Loader
        message={
          Boolean(secondsLeft) && (
            <>
              Redirecting you to Teams
              <br /> dashboard in {secondsLeft}...
            </>
          )
        }
      />
    );
  }

  return (
    <>
      {!isAccepted && link ? (
        <AcceptRequest
          {...{
            teamName,
            adminEmail,
            isAdmin,
            isDriver,
            link,
            handleAccept,
            isTeamSubscriptionExpired,
          }}
        />
      ) : (
        <AcceptRequestDone {...{ adminEmail }} />
      )}
      {Flash}
    </>
  );
}

const AcceptRequest = ({
  teamName,
  adminEmail,
  isAdmin,
  isDriver,
  handleAccept,
  isTeamSubscriptionExpired,
}) => {
  const { userData } = useContext(UserDataContext);

  const role =
    isAdmin && isDriver ? "Admin & Driver" : isAdmin ? "Admin" : false;

  return (
    <div className="w-full h-full flex items-center justify-between">
      <div className="flex-shrink-0 max-w-[370px] my-0 mr-[20px] ml-[80px] laptop:ml-[60px] min1920:ml-[200px] min2560:ml-[260px]">
        <h3>You've been invited to join a team.</h3>
        <Text paragraph className="mt-[15px]">
          <strong>{adminEmail}</strong> has invited you to{" "}
          {role ? `be an ${role} of ` : "join "}
          <strong>{teamName}</strong> on MileIQ for Teams.
        </Text>
        {isTeamSubscriptionExpired && (
          <p className="mt-[15px]">
            Unfortunately, your team's account is currently expired. Please
            contact your admin to resubscribe.
          </p>
        )}
        <Button
          disabled={isTeamSubscriptionExpired}
          className="mt-[20px]"
          onClick={() => handleAccept()}
        >
          Accept invitation
        </Button>
        {Boolean(
          !isTeamSubscriptionExpired &&
            userData?.isPremium &&
            userData?.subscriptionData?.status === SUBSCRIPTION_STATUS.ACTIVE
        ) && (
          <Text paragraph className="mt-[20px]">
            Don't pay twice! <br />
            <strong>
              <NavLink className="text-red underline" to="/user/subscription">
                Downgrade your existing account
              </NavLink>
            </strong>
          </Text>
        )}
      </div>
      <div className="overflow-hidden rounded-l flex-shrink-0">
        <InviteDog />
      </div>
    </div>
  );
};

const AcceptRequestDone = ({ adminEmail }) => {
  return (
    <div className="w-full h-full flex items-center justify-between">
      <div className="flex-shrink-0 max-w-[370px] my-0 mr-[20px] ml-[80px] laptop:ml-[60px] min1920:ml-[200px] min2560:ml-[260px]">
        <h3>You are part of a team.</h3>
        <Text paragraph className="mt-[15px]">
          Your admin is{" "}
          <strong>
            <a href={`mailto:${adminEmail}`} className="text-blue underline">
              {adminEmail}
            </a>
          </strong>
        </Text>
        <Text paragraph className="mt-[15px]">
          If you would like to create or join another team, please contact our{" "}
          <strong>
            <a
              href={`https://support.mileiq.com`}
              className="text-blue underline"
            >
              customer support
            </a>
          </strong>{" "}
          team.
        </Text>
      </div>
      <div className="overflow-hidden rounded-l flex-shrink-0 flex-grow h-[70%] teams-invited-bg"></div>
    </div>
  );
};
