import r from "../../shared-lib/braze-shared-lib.js";
import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
import b from "../util/net.js";
import {
  newFeatureFlagFromJson as tt,
  newFeatureFlagFromSerializedValue as et
} from "./feature-flag-factory.js";
export default class er extends y {
  constructor(t, s, r) {
    super(),
      (this.gt = t),
      (this.vt = s),
      (this.h = r),
      (this.he = new u()),
      e.jt(this.he);
  }
  Rs(t) {
    if (this.gt.ye() && null != t && t.feature_flags) {
      this.De = [];
      for (const e of t.feature_flags) {
        let t = tt(e);
        t && this.De.push(t);
      }
      (this.Ne = new Date().getTime()), this._e(), this.he.St(this.De);
    }
  }
  xe() {
    const t = this.h.I(o.q.ze) || {};
    let e = {};
    for (let s in t) {
      let r = et(t[s]);
      r && (e[r.id] = r);
    }
    return e;
  }
  Ws(t) {
    return this.he.ut(t);
  }
  refreshFeatureFlags(t, e, s) {
    if (!this.Re(s))
      return void (
        this.Se ||
        (this.Se = this.gt.Te(() => {
          this.refreshFeatureFlags(t, e);
        }))
      );
    const r = this.vt.Ps({}, !0),
      i = this.vt.Bs(r, !1, !0);
    this.vt.Gs(r, () => {
      b.Hs({
        url: `${this.vt.Ks()}/feature_flags/sync`,
        headers: i,
        data: r,
        S: s => {
          this.vt.Os(r, s)
            ? (this.vt.Qs(), this.Rs(s), "function" == typeof t && t())
            : "function" == typeof e && e();
        },
        error: t => {
          this.vt.Vs(t, "retrieving feature flags"),
            "function" == typeof e && e();
        }
      });
    });
  }
  Re(t) {
    if (!t) {
      const t = parseFloat(this.gt.$e());
      let e = !1;
      if (!isNaN(t)) {
        if (-1 === t) return r.D.info("Feature flag refreshes not allowed"), !1;
        e = new Date().getTime() >= (this.Ne || 0) + 1e3 * t;
      }
      if (!e)
        return (
          r.D.info(`Feature flag refreshes were rate limited to ${t} seconds`),
          !1
        );
    }
    return this.gt.ye();
  }
  _e() {
    const t = {};
    for (let e = 0; e < this.De.length; e++) {
      let s = this.De[e],
        r = s.ss();
      t[s.id] = r;
    }
    this.h.B(o.q.ze, t), this.h.B(o.q.qe, this.Ne);
  }
}
