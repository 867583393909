import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  Intent,
  trackProductCreateTeamPreferenceSelected,
  trackProductJoinTeamPreferenceSelected,
  trackProductPersonalPreferenceSelected,
  trackProductPreferenceSubmitted,
} from "src/services/tracking";

import IconCreate from "public/assets/img/onboarding/usage-create-team.svg";
import IconJoin from "public/assets/img/onboarding/usage-join-team.svg";
import IconMyself from "public/assets/img/onboarding/usage-myself.svg";
import ImgSentToAdmin from "public/assets/img/sent-to-admin.svg";

export default UsageIntentPicker;

function UsageIntentPicker({ onSubmit, onSkip }) {
  const options = [
    {
      id: "standalone",
      intent: Intent.USE_PERSONAL,
      icon: <IconMyself />,
      label: "For myself",

      description:
        "I plan to use MileIQ to track my own mileage for reimbursement or tax purposes.",
    },
    {
      id: "create-team",
      intent: Intent.CREATE_TEAM,
      icon: <IconCreate />,
      label: "Create a team",
      description:
        "I'm a manager and would like to create a new team account to track drives and log mileage for my drivers.",
    },
    {
      id: "join-team",
      intent: Intent.JOIN_TEAM,
      icon: <IconJoin />,
      label: "Join a team",
      description:
        "I've already been invited to a team on MileIQ, and I'm ready to join.",
    },
  ];
  const [selected, setSelected] = useState(options[0]);
  const [wannaJoinTeam, setWannaJoinTeam] = useState(false);
  const canSubmit = selected;

  const handleSelectOptions = (option) => {
    if (option?.id === "standalone") {
      trackProductPersonalPreferenceSelected();
    }
    if (option?.id === "create-team") {
      trackProductCreateTeamPreferenceSelected();
    }
    if (option?.id === "join-team") {
      trackProductJoinTeamPreferenceSelected();
    }
    setSelected(option);
  };

  const handleSubmit = () => {
    trackProductPreferenceSubmitted(selected?.intent);
    if (selected?.id === "join-team") {
      return setWannaJoinTeam(true);
    }

    onSubmit(selected?.id);
  };

  const handleBack = () => {
    setWannaJoinTeam(false);
  };

  const handleSkip = () => {
    onSkip();
  };

  return (
    <div className="flex min-h-[100vh] justify-center max-w-[100vw]">
      <div className="step-details w-1/2 min-h-[100vh] flex justify-center min-w-full">
        <div className="step-details-content flex flex-col items-center py-[124px] md:py-10 max-w-[800px] self-center">
          {wannaJoinTeam ? (
            <>
              <div className="w-full flex items-center justify-between">
                <div className="w-10">
                  <Button
                    text
                    icon="arrow-right"
                    iconClassName="transform rotate-180"
                    color="black"
                    className="hidden lg:flex md:absolute md:top-12 md:left-32"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </div>
                <div className="w-10" />
              </div>
              <h2 className="text-center">
                To join a team, check your inbox for an invitation from your
                admin
              </h2>
              <Text paragraph className="text-center mt-3 text-black/70">
                In order to join a team, you need to locate your invitation in
                your inbox, and click Accept Invitation.
              </Text>
              <div className="mt-14">
                <ImgSentToAdmin />
              </div>
              <Button
                ghost
                nohover
                className="w-full mt-24 mb-5 font-medium"
                onClick={handleSkip}
              >
                Take me to the Driver Dashboard instead{" "}
                <Icon name="arrow-right" />
              </Button>
            </>
          ) : (
            <>
              <h2 className="text-center">How would you like to use MileIQ?</h2>
              <Text paragraph className="text-center mt-3 text-black/70">
                Help us understand your role and we'll streamline your
                experience accordingly.
              </Text>
              <div className="mt-16">
                <ul className="m-0 p-0 flex gap-4 flex-wrap">
                  {options.map((option) => {
                    const { icon, label, description } = option;
                    const isActive = selected?.label === label;
                    return (
                      <li
                        key={label}
                        className={`p-6 flex flex-col flex-1 text-center cursor-pointer rounded relative items-center border ${
                          isActive
                            ? "border-blue border-2 bg-skyblue"
                            : "border-black/10 border-2 bg-transparent"
                        }`}
                        onClick={() => handleSelectOptions(option)}
                      >
                        <div className="absolute top-2.5 right-2.5">
                          {isActive ? (
                            <Icon name="checked-circle" color="blue" />
                          ) : (
                            <span className="block w-[20px] h-[20px] border-2 border-black/30 rounded-full" />
                          )}
                        </div>
                        <div className="flex justify-center flex-shrink-0 mr-3 mt-2">
                          {icon}
                        </div>
                        <h6 className="mt-6">{label}</h6>
                        <Text
                          paragraph
                          md
                          center
                          className="text-black/70 leading-5 mt-2"
                        >
                          {description}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Button
                lg
                className="w-full mt-12"
                disabled={!canSubmit}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
