import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import Feed from "./feed.js";
import {
  newCardFromFeedJson as st,
  newCardFromSerializedValue as S
} from "../Card/util/card-factory.js";
import { rehydrateDateAfterJsonization as w } from "../util/date-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
export default class re extends y {
  constructor(t, s) {
    super(),
      (this.h = t),
      (this.si = s),
      (this.yt = new u()),
      e.jt(this.yt),
      this.zt();
  }
  zt() {
    const t = this.h.I(o.q.ei) || [],
      s = [];
    for (let i = 0; i < t.length; i++) {
      const e = S(t[i]);
      null != e && s.push(e);
    }
    (this.Zt = s), (this.ri = w(this.h.I(o.q.hi)));
  }
  oi(t) {
    const s = [];
    let i = null;
    const e = this.h.I(o.q.P) || {},
      r = {};
    for (let h = 0; h < t.length; h++) {
      i = t[h];
      const o = st(i);
      null != o && (e[o.id] && ((o.viewed = !0), (r[o.id] = !0)), s.push(o));
    }
    this.h.B(o.q.P, r),
      (this.Zt = s),
      this.Ss(),
      (this.ri = new Date()),
      this.h.B(o.q.hi, this.ri);
  }
  Ss() {
    const t = [];
    for (let s = 0; s < this.Zt.length; s++) t.push(this.Zt[s].ss());
    this.h.B(o.q.ei, t);
  }
  Rs(t) {
    null != t &&
      t.feed &&
      (this.zt(),
      this.oi(t.feed),
      this.yt.St(new Feed(this.Zt.slice(), this.ri)));
  }
  ai() {
    this.zt();
    const t = [],
      s = new Date();
    for (let i = 0; i < this.Zt.length; i++)
      (null == this.Zt[i].expiresAt || this.Zt[i].expiresAt >= s) &&
        t.push(this.Zt[i]);
    return new Feed(t, this.ri);
  }
  Is() {
    this.si.requestFeedRefresh();
  }
  Ws(t) {
    return this.yt.ut(t);
  }
  clearData(t) {
    null == t && (t = !1),
      (this.Zt = []),
      (this.ri = null),
      t && (this.h.Xs(o.q.ei), this.h.Xs(o.q.hi)),
      this.yt.St(new Feed(this.Zt.slice(), this.ri));
  }
}
