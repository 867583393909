import React from "react";
import { useState } from "react";
import { useContext } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import Drive from "src/models/drive";

import {
  formatCurrency,
  getDriveValue,
  roundDistance,
  toUIDistance,
} from "src/services/utils";

import { UserDataContext } from "../context/UserContext";

export default JoinDrivesModal;

export const ELEMENT_ID = "JOIN_DRIVES_MODAL";
registerElement(ELEMENT_ID, JoinDrivesModal);

const DriveData = ({ name, date, isJoinedDrive, dateFormatter }) => (
  <div className="text-left flex flex-col break-all leading-[21px] laptop:leading-[18px]">
    <Text semibold={isJoinedDrive}>{name}</Text>
    {isJoinedDrive ? (
      <Text>{dateFormatter.format(new Date(date), "hh:mmaaa")}</Text>
    ) : (
      <Text>{dateFormatter.format(new Date(date), "hh:mmaaa, MMM d")}</Text>
    )}
  </div>
);

const DriveDistanceAndAmount = ({ distance, value, isJoinedDrive }) => {
  const { userData } = useContext(UserDataContext);
  return (
    <>
      <Text className="mr-3">
        {toUIDistance(distance, userData.distanceUnit)} {userData.distanceUnit}
      </Text>{" "}
      <Text semibold={isJoinedDrive}>
        {formatCurrency({ value, currency: userData.currency })}
      </Text>
    </>
  );
};

const DriveEntry = ({ drive, isJoinedDrive, value }) => {
  return (
    <div className="flex justify-between gap-4 p-4">
      <div className="flex gap-4 w-2/6 items-start">
        <Icon
          name={isJoinedDrive ? "link" : "car"}
          className="mt-[8px] laptop:mt-[6px]"
        />
        <DriveData
          isJoinedDrive={isJoinedDrive}
          name={drive.startLocation.fullAddress}
          date={drive.startDate}
          dateFormatter={drive.dateFormatter}
        />
      </div>
      <div className="w-2/6">
        <div className="flex gap-4">
          <Icon name="large-arrow-right" className="items-start mt-1" />
          <DriveData
            isJoinedDrive={isJoinedDrive}
            name={drive.endLocation.fullAddress}
            date={drive.endDate}
            dateFormatter={drive.dateFormatter}
          />
        </div>
      </div>
      <div className="text-right gap-4 w-2/6">
        <DriveDistanceAndAmount
          isJoinedDrive={isJoinedDrive}
          distance={roundDistance(drive.googleDistance)}
          value={value || getDriveValue(drive)}
        />
      </div>
    </div>
  );
};

/**
 *
 * @param {{drives: Drive[] }}
 * @returns
 */
function JoinDrivesModal({ drives, onClose, onJoin }) {
  const sortedDrives = drives.sort((d1, d2) => {
    const ed1 = new Date(d1.endDate);
    const ed2 = new Date(d2.endDate);
    return ed1 - ed2;
  });

  const firstDrive = sortedDrives.at(0);
  const lastDrive = sortedDrives.at(-1);

  const joinedDrive = new Drive();
  joinedDrive.startLocation = firstDrive.startLocation;
  joinedDrive.endLocation = lastDrive.endLocation;
  joinedDrive.startDate = firstDrive.startDate;
  joinedDrive.endDate = lastDrive.endDate;
  joinedDrive.googleDistance = roundDistance(
    sortedDrives.reduce((acc, next) => acc + next.googleDistance, 0)
  );
  joinedDrive.timeZoneDiffInHours = firstDrive.timeZoneDiffInHours;

  const [loading, setLoading] = useState(false);

  const joinMutation = async () => {
    setLoading(true);
    await onJoin(sortedDrives);
    setLoading(false);

    onClose();
  };

  return (
    <Modal
      closable
      onClose={() => onClose({ isCancel: true })}
      className="join-drives-modal"
    >
      <div className="text-center">
        <h4 className="mb-3">
          Do you want to join these {sortedDrives.length} drives?
        </h4>
        <div className="relative">
          <section
            className={`${
              sortedDrives.length >= 5 ? "fade" : ""
            } overflow-auto max-h-[290px] rounded border border-border-1 mt-8`}
          >
            {sortedDrives.map((drive, i) => (
              <div key={drive.id}>
                <DriveEntry drive={drive} />
                {sortedDrives.length - 1 !== i && (
                  <div className="relative divider">
                    <div className="bg-white absolute translate-x-[45%] translate-y-[-50%] flex justify-center border border-border-1 rounded-full w-[30px] h-[30px]">
                      <Icon name="plus" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </section>
        </div>
        <div className="rounded bg-sand/50 my-3">
          <DriveEntry
            isJoinedDrive
            drive={joinedDrive}
            value={sortedDrives.reduce(
              (acc, next) =>
                acc + next.value + next.parkingFees + next.tollFees,
              0
            )}
          />
        </div>
        <div className="flex justify-end gap-2 mt-8">
          <Button
            lg
            loading={loading}
            className="w-full font-medium"
            primary
            onClick={joinMutation}
          >
            {loading ? "Loading..." : "Yes, I want to join drives"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
