import { createContext, useContext, useState } from "react";

import { useUser } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";

import { report } from "src/services/error-reporting";
import { NETWORK_STATES } from "src/services/http";
import {
  fetchTeamAutoReviewSettings,
  updateTeamAutoReviewSettings,
} from "src/services/teams";
import {
  trackAutomaticReviewDisabled,
  trackAutomaticReviewEnabled,
} from "src/services/tracking";

import { useAppFlash } from "./Flash";
import TeamContext from "./TeamContext";

const AutoReviewContext = createContext();

export const useTeamsAutoReview = () => {
  const context = useContext(AutoReviewContext);

  if (!context) {
    throw new Error(
      "useTeamsAutoReview must be used within a AutoReviewContext"
    );
  }

  return context;
};

export const AutoReviewProvider = ({ children }) => {
  const { team } = useContext(TeamContext);
  const { user } = useUser();
  const [isEnabled, setIsEnabled] = useState(false);
  const [
    updateAutoReviewSettingsNetworkState,
    setUpdateAutoReviewSettingsNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const [
    fetchAutoReviewSettingsNetworkState,
    setFetchAutoReviewSettingsNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const isFetching =
    fetchAutoReviewSettingsNetworkState === NETWORK_STATES.LOADING;
  const isSubmitting =
    updateAutoReviewSettingsNetworkState === NETWORK_STATES.LOADING;
  const { flash } = useAppFlash();

  async function fetchAutoReviewSettings() {
    try {
      if (team.isExpired) {
        setIsEnabled(false);
        return;
      }
      setFetchAutoReviewSettingsNetworkState(NETWORK_STATES.LOADING);
      const autoReviewSettings = await fetchTeamAutoReviewSettings();
      setFetchAutoReviewSettingsNetworkState(NETWORK_STATES.LOADED);
      setIsEnabled(autoReviewSettings.state);
    } catch (err) {
      setFetchAutoReviewSettingsNetworkState(NETWORK_STATES.ERROR);
      report(err);
      console.log("Failed to fetch automatic update settings", err);
    }
  }

  async function toggleAutoReview() {
    //TODO: Add AVO events
    if (isSubmitting) return;

    try {
      setUpdateAutoReviewSettingsNetworkState(NETWORK_STATES.LOADING);

      const payload = { type: "team_locations", data: { state: !isEnabled } };
      const autoReviewSettings = await updateTeamAutoReviewSettings(payload);

      setIsEnabled(autoReviewSettings.state);

      const trackingFunction = autoReviewSettings.state
        ? trackAutomaticReviewEnabled
        : trackAutomaticReviewDisabled;

      trackingFunction({
        userId: user?.id,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription.plan,
        country: team.country,
        freeTrial: team.subscription?.isFreeTrialActive,
      });
      setUpdateAutoReviewSettingsNetworkState(NETWORK_STATES.LOADED);
    } catch (err) {
      setUpdateAutoReviewSettingsNetworkState(NETWORK_STATES.ERROR);
      console.log("Failed to toggle automatic review settings", err);
      report(err);
      flash(<Text>Auto-approvals failed to update.</Text>, {
        type: FlashTypes.ERROR,
      });
    }
  }

  return (
    <AutoReviewContext.Provider
      value={{
        isEnabled,
        toggleAutoReview,
        fetchAutoReviewSettings,
        isFetching,
        isSubmitting,
      }}
    >
      {children}
    </AutoReviewContext.Provider>
  );
};
