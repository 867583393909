import {
  addPassiveEventListener as oe,
  buildSvg as ae,
  detectSwipe as d,
  DIRECTIONS as c
} from "../../util/dom-utils.js";
import { createCloseButton as i } from "../../util/component-utils.js";
import { isTransparent as ne, toRgba as me } from "../../util/color-utils.js";
import FullScreenMessage from "../models/full-screen-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import ModalMessage from "../models/modal-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import { logInAppMessageClick } from "../log-in-app-message-click.js";
import { _handleBrazeAction as m } from "../../Core/handle-braze-action.js";
import ut from "./html-message-to-html.js";
import ce from "./modal-utils.js";
import r from "../../../shared-lib/braze-shared-lib.js";
export default function le(e, o, s, t, a, n) {
  if (((e.ke = document.activeElement), e instanceof HtmlMessage))
    return ut(e, o, s, t, a, n);
  const l = (function(e, o, s, t, a) {
    const n = document.createElement("div");
    (n.className = "ab-in-app-message ab-start-hidden ab-background"),
      a && (n.style.zIndex = a + 1),
      e.Me() &&
        ((n.className += " ab-modal-interactions"),
        n.setAttribute("tabindex", "-1")),
      e.sn() ||
        ((n.style.color = me(e.textColor)),
        (n.style.backgroundColor = me(e.backgroundColor)),
        ne(e.backgroundColor) && (n.className += " ab-no-shadow"));
    const c = () => {
      -1 !== n.className.indexOf("ab-start-hidden") &&
        ((n.className = n.className.replace("ab-start-hidden", "")), s(n));
    };
    e.imageStyle === InAppMessage.ImageStyle.GRAPHIC &&
      (n.className += " graphic"),
      e.orientation === InAppMessage.Orientation.LANDSCAPE &&
        (n.className += " landscape"),
      0 === e.buttons.length &&
        (e.clickAction !== InAppMessage.ClickAction.NONE &&
          (n.className += " ab-clickable"),
        (n.onclick = s => (
          e.on(n, () => {
            logInAppMessageClick(e),
              e.clickAction === InAppMessage.ClickAction.URI
                ? m(
                    e.uri,
                    s,
                    t || e.openTarget === InAppMessage.OpenTarget.BLANK
                  )
                : e.clickAction === InAppMessage.ClickAction.NEWS_FEED && o();
          }),
          s.stopPropagation(),
          !1
        )));
    const l = i(
      "Close Message",
      e.sn() ? void 0 : me(e.closeButtonColor),
      () => {
        e.on(n);
      }
    );
    n.appendChild(l), a && (l.style.zIndex = a + 2);
    const d = document.createElement("div");
    d.className = "ab-message-text";
    const u = (e.messageAlignment || e.Ce).toLowerCase();
    d.className += " " + u + "-aligned";
    let b = !1;
    const f = document.createElement("div");
    if (((f.className = "ab-image-area"), e.imageUrl)) {
      if (e.cropType === InAppMessage.CropType.CENTER_CROP) {
        const o = document.createElement("span");
        (o.className = "ab-center-cropped-img"),
          (o.style.backgroundImage = "url(" + e.imageUrl + ")"),
          o.setAttribute("role", "img"),
          o.setAttribute("aria-label", "Modal Image"),
          e.Le(o),
          f.appendChild(o);
      } else {
        const o = document.createElement("img");
        o.setAttribute("src", e.imageUrl),
          e.Le(o),
          (b = !0),
          (o.onload = c),
          setTimeout(c, 1e3),
          f.appendChild(o);
      }
      n.appendChild(f), (d.className += " ab-with-image");
    } else if (e.icon) {
      f.className += " ab-icon-area";
      const o = document.createElement("span");
      (o.className = "ab-icon"),
        e.sn() ||
          ((o.style.backgroundColor = me(e.iconBackgroundColor)),
          (o.style.color = me(e.iconColor)));
      const s = document.createElement("i");
      (s.className = "fa"),
        s.appendChild(document.createTextNode(e.icon)),
        s.setAttribute("aria-hidden", !0),
        o.appendChild(s),
        f.appendChild(o),
        n.appendChild(f),
        (d.className += " ab-with-icon");
    }
    if ((oe(d, "touchstart", function() {}), e.header && e.header.length > 0)) {
      const o = document.createElement("h1");
      (o.className = "ab-message-header"), (e.Ie = r.it.nt()), (o.id = e.Ie);
      const s = (
        e.headerAlignment || InAppMessage.TextAlignment.CENTER
      ).toLowerCase();
      (o.className += " " + s + "-aligned"),
        e.sn() || (o.style.color = me(e.headerTextColor)),
        o.appendChild(document.createTextNode(e.header)),
        d.appendChild(o);
    }
    return d.appendChild(e.Ae()), n.appendChild(d), b || c(), (e.mn = n), n;
  })(e, o, s, t, a);
  if (e instanceof FullScreenMessage || e instanceof ModalMessage) {
    const s = e instanceof FullScreenMessage ? "ab-fullscreen" : "ab-modal";
    (l.className += ` ${s} ab-centered`),
      ce.Be(e, o, l, t),
      ce.Ee(l),
      ce.Ge(e.Ie, l);
  } else if (e instanceof SlideUpMessage) {
    l.className += " ab-slideup";
    const o = l.getElementsByClassName("ab-close-button")[0];
    if (null != o) {
      const s = ae(
        "0 0 11.38 19.44",
        "M11.38 9.72l-9.33 9.72L0 17.3l7.27-7.58L0 2.14 2.05 0l9.33 9.72z",
        e.sn() ? void 0 : me(e.closeButtonColor)
      );
      s.setAttribute("class", "ab-chevron"), o.appendChild(s);
    }
    let s, t;
    d(l, c.ot, e => {
      (l.className += " ab-swiped-left"), o.onclick(e);
    }),
      d(l, c.et, e => {
        (l.className += " ab-swiped-right"), o.onclick(e);
      }),
      e.slideFrom === InAppMessage.SlideFrom.TOP
        ? ((s = c.He), (t = " ab-swiped-up"))
        : ((s = c.Je), (t = " ab-swiped-down")),
      d(l, s, e => {
        (l.className += t), o.onclick(e);
      });
  }
  return l;
}
