const dt = {
  Br: () =>
    "serviceWorker" in navigator &&
    "undefined" != typeof ServiceWorkerRegistration &&
    "showNotification" in ServiceWorkerRegistration.prototype &&
    "PushManager" in window,
  Yr: () =>
    "safari" in window &&
    "pushNotification" in window.safari &&
    "function" == typeof window.safari.pushNotification.permission &&
    "function" == typeof window.safari.pushNotification.requestPermission,
  isPushSupported: () => dt.Br() || dt.Yr(),
  isPushBlocked: () => {
    const i =
        dt.isPushSupported() &&
        "Notification" in window &&
        null != window.Notification &&
        null != window.Notification.permission &&
        "denied" === window.Notification.permission,
      n =
        dt.isPushSupported() &&
        (!("Notification" in window) || null == window.Notification);
    return i || n;
  },
  isPushPermissionGranted: () =>
    dt.isPushSupported() &&
    "Notification" in window &&
    null != window.Notification &&
    null != window.Notification.permission &&
    "granted" === window.Notification.permission,
  ji: () =>
    !dt.isPushBlocked() && dt.isPushSupported() && !dt.isPushPermissionGranted()
};
export default dt;
