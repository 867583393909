import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

import useElement from "src/lib/layers/useElement";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as EXISTING_NAMED_LOCATION_MODAL_ID } from "src/components/modals/ExistingNamedLocation";
import { ELEMENT_ID as NEW_NAMED_LOCATION_MODAL_ID } from "src/components/modals/NewNamedLocation";

import useFlash from "src/hooks/useFlash";

import { getRefetchParams } from "src/services/apollo-utils";
import {
  trackDriveEdited,
  trackNamedLocationCreated,
  trackNamedLocationEdited,
} from "src/services/tracking";
import { handleEnterKeyPress } from "src/services/utils";

import {
  ADD_NAMED_LOCATION,
  CREATE_NAMED_LOCATION_MUTATION,
  RENAME_NAMED_LOCATION,
} from "src/graphql/mutations";

import NamedLocationInput from "./NamedLocationInput";

export const LocationType = {
  START: "start",
  END: "end",
  STOP: "stop",
};

const RouteInput = ({
  location,
  locationType,
  driveId,
  isRoundTripStop,
  date,
  dateFormatter,
  className,
  inputClassName,
  ...rest
}) => {
  let locationToDisplay = location.fullAddress;

  if (location.isNamed) {
    locationToDisplay = location.displayName;
  }

  const [previousValue, setPreviousValue] = useState(
    location.displayName || ""
  );
  const [value, setValue] = useState(locationToDisplay);
  const [nameLocationFocused, setNameLocationFocused] = useState(false);
  const [flash, Flash] = useFlash();

  useEffect(() => {
    setValue(locationToDisplay);
    setPreviousValue(locationToDisplay);
  }, [location]);

  const trackEditLocation = () => {
    const DETAIL_NAME = {
      start: "Start Location Name",
      end: "End Location Name",
      stop: "End Location Name",
    };

    trackDriveEdited({
      count: 1,
      detail: DETAIL_NAME[locationType],
    });
  };

  const newLocModal = useElement(NEW_NAMED_LOCATION_MODAL_ID, {
    props: {
      onClose: () => {
        newLocModal.deactivate();
      },
      namedLocation: value,
      onNewNamedLocation: async () => {
        trackEditLocation();
        await addFirstNamedLocation({
          variables: {
            namedLocationInput: {
              nameLocation: value,
              driveId: String(driveId),
              location: {
                latitude: location.latitude,
                longitude: location.longitude,
              },
            },
          },
        });
        trackNamedLocationCreated();
        if (!errorAddNamedLocation) {
          setPreviousValue(value);
          flash(<Text>Location named</Text>, {
            type: FlashTypes.SAVED,
          });
        }
      },
    },
  });

  const existLocModal = useElement(EXISTING_NAMED_LOCATION_MODAL_ID, {
    props: {
      onClose: () => {
        existLocModal.deactivate();
      },
      namedLocation: value,
      onRenameNamedLocation: async () => {
        trackEditLocation();
        await renameNamedLocation({
          variables: {
            renameNamedLocationInput: {
              driveId: String(driveId),
              id: location.namedLocationDetails.id,
              name: value,
            },
          },
        });

        trackNamedLocationEdited(true);

        if (!errorRenameNamedLocation) {
          setPreviousValue(value);
          flash(<Text>Location renamed</Text>, {
            type: FlashTypes.SAVED,
          });
        }
      },
      onCreateNamedLocation: async () => {
        trackEditLocation();
        await createNamedLocation({
          variables: {
            createNewNamedLocationInput: {
              isStartLoc: locationType === LocationType.START,
              namedLocationInput: {
                location: {
                  latitude: location.latitude,
                  longitude: location.longitude,
                },
                driveId: String(driveId),
                nameLocation: value,
              },
            },
          },
        });
        trackNamedLocationEdited(false);
        if (!errorCreateNamedLocation) {
          setPreviousValue(value);
          flash(<Text>Location named</Text>, {
            type: FlashTypes.SAVED,
          });
        }
      },
    },
  });

  const [addFirstNamedLocation, { error: errorAddNamedLocation }] = useMutation(
    ADD_NAMED_LOCATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getUserData"],
      // Disabling cache for roundtrip with stop named location because it does not support stop location
      // So it provides a bad experience. The values of the InputRoute flick due to the cache
      // response from the GraphQL, it get's automatically updated once getUserDrives is refetched
      fetchPolicy: isRoundTripStop ? "no-cache" : "network-only",
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    }
  );
  const [renameNamedLocation, { error: errorRenameNamedLocation }] =
    useMutation(RENAME_NAMED_LOCATION, {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getUserData"],
      // Disabling cache for roundtrip with stop named location because it does not support stop location
      // So it provides a bad experience. The values of the InputRoute flick due to the cache
      // response from the GraphQL, it get's automatically updated once getUserDrives is refetched
      fetchPolicy: isRoundTripStop ? "no-cache" : "network-only",
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    });
  const [createNamedLocation, { error: errorCreateNamedLocation }] =
    useMutation(CREATE_NAMED_LOCATION_MUTATION, {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getUserData"],
      // Disabling cache for roundtrip with stop named location because it does not support stop location
      // So it provides a bad experience. The values of the InputRoute flick due to the cache
      // response from the GraphQL, it get's automatically updated once getUserDrives is refetched
      fetchPolicy: isRoundTripStop ? "no-cache" : "network-only",
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    });

  const existingNamedLocation = () => {
    existLocModal.activate();
  };

  const addNamedLocation = () => {
    newLocModal.activate();
  };

  const onSaveNamedLocation = () => {
    if (location.isNamed) {
      existingNamedLocation();
    } else {
      addNamedLocation();
    }
  };

  const classes = `named-location miq-icon-input-wrap relative ${
    className || ""
  }`;
  const inpClasses = `miq-icon-input ${inputClassName || ""} ${
    nameLocationFocused ? "focused" : ""
  } ${location.isNamed ? "[&_input]:text-transparent" : ""}`;

  const locationNameDiffer =
    previousValue?.trim() === value?.trim() || !value?.trim();

  return (
    <div className={classes} data-testid="route-input">
      {locationType === LocationType.START && (
        <Icon
          name="start-location-input"
          className="green-icon absolute z-10 top-[50%] -translate-y-[50%] left-[20px] -translate-x-[50%]"
        />
      )}
      {locationType === LocationType.STOP && (
        <Icon
          name="stop-location-input"
          className="stop-icon absolute z-10 top-[50%] -translate-y-[50%] left-[20px] -translate-x-[50%]"
        />
      )}
      {locationType === LocationType.END && (
        <Icon
          name="end-location-input"
          className="red-icon absolute z-10 top-[50%] -translate-y-[50%] left-[20px] -translate-x-[50%]"
        />
      )}

      <Input
        className={inpClasses}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder={location.isNamed ? "" : "Name a location"}
        onKeyPress={(e) => {
          if (!locationNameDiffer) {
            const eventHanlder = handleEnterKeyPress(onSaveNamedLocation);
            eventHanlder(e);
          }
        }}
        {...rest}
      />
      {location.isNamed && (
        <NamedLocationInput
          onKeyPress={(e) => {
            if (!locationNameDiffer) {
              const eventHanlder = handleEnterKeyPress(onSaveNamedLocation);
              eventHanlder(e);
            }
          }}
          onFocus={() => setNameLocationFocused(true)}
          onBlur={() => setNameLocationFocused(false)}
          value={value}
          setValue={setValue}
        />
      )}
      <div className="miq-icon right-icon">
        {locationNameDiffer ? (
          <span data-testid="time">
            {dateFormatter.format(date, "hh:mmaaa")}
          </span>
        ) : (
          <Button
            text
            data-testid="save"
            onClick={onSaveNamedLocation}
            className="text-blue font-semibold text-15 cursor-pointer"
          >
            Save
          </Button>
        )}
      </div>
      {Flash}
    </div>
  );
};

export default RouteInput;
