import React from "react";

import Text from "src/components/elements/Text";

export default FilterButton;

function FilterButton({ label, active, onClick, className }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`miq-filter-btn ${active ? "miq-filter-btn-active" : ""} ${
        className || ""
      }`}
    >
      <Text bold className="miq-filter-btn-label">
        {label}
      </Text>
    </button>
  );
}
