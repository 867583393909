import "src/models/typings";

import React from "react";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

import { formatCurrency, toUIDistance } from "src/services/utils";
import { getDriveValue } from "src/services/utils";

const Header = ({ drive, onClose, currency, unit }) => {
  return (
    <div className="relative flex flex-col gap-[6px] pt-[15px]">
      <Text>{drive.dateFormatter.format(drive.endDate, "EEEE, MMM d")}</Text>
      <h5 className="break-all">
        <span className="drive-value">
          {formatCurrency({ value: getDriveValue(drive), currency })}
        </span>
        {toUIDistance(drive.googleDistance, unit, true)} {unit}
      </h5>
      <Button
        data-testid="header-onClose"
        ghost
        icon="close"
        onClick={onClose}
        className="close-btn absolute right-0 top-0"
      />
    </div>
  );
};

export default Header;
