import React, { useEffect, useRef, useState } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import {
  trackDistanceRateUpdateCompleted,
  trackDistanceRateUpdateStarted,
} from "src/services/tracking";

const getRatesMap = (rate) => rate.values.business.vehicle;

const RatesUK = ({
  rate,
  editable,
  setByTeam,
  loading,
  onSave = () => {},
  isCustomRatePresent,
  handleRatesReset = () => {},
}) => {
  const updateStarted = useRef(false);
  const customRates = useCustomRates();
  const defaultCountryRates = customRates?.defaultCountryRates?.data;

  const source = defaultCountryRates?.source || "HMRC";
  const year = defaultCountryRates?.year || "2022";
  const value = defaultCountryRates?.values?.business || {
    default: 0.45,
    vehicle: {
      automobile: 0.45,
      automobile_slab_2: 0.25,
      bicycle: 0.2,
      bicycle_slab_2: 0.2,
      motorcycle: 0.24,
      motorcycle_slab_2: 0.24,
    },
  };

  const defaultValues = getRatesMap(rate);
  const [rates, setRates] = useState(defaultValues);

  useEffect(() => {
    setRates(getRatesMap(rate));
  }, [JSON.stringify(rate)]);

  const handleRateChanged = (key, val) => {
    if (!updateStarted.current) {
      trackDistanceRateUpdateStarted();
    }
    updateStarted.current = true;
    setRates((prevVal) => ({ ...prevVal, [key]: Number(val) }));
  };

  const handleSave = async () => {
    let values = { ...rate.values };
    values.business = {
      vehicle: {
        ...rates,
      },
    };

    await onSave({ values });
    updateStarted.current = false;
    trackDistanceRateUpdateCompleted();
  };

  const handleCancel = () => {
    setRates(defaultValues);
    updateStarted.current = false;
  };

  const isModified = JSON.stringify(defaultValues) !== JSON.stringify(rates);

  return (
    <>
      <h6 className="mb-[5px]">Rates</h6>
      <Text paragraph>
        {setByTeam
          ? "You are part of a team which means that your team's admin sets your currency, mileage rate, and distance unit. Current rate is listed below."
          : `The ${year} ${source} rates for cars and vans is £${value?.vehicle?.automobile} for the first 10,000 business miles in a tax year and then £${value?.vehicle?.automobile_slab_2} for each subsequent mile. For motorcycles, it is £${value?.vehicle?.motorcycle}, and for bikes, it is £${value?.vehicle?.bicycle} for all business miles.`}
      </Text>
      <div className="my-[20px] laptop:my-[15px] grid grid-cols-[160px,auto,1fr] gap-[15px]">
        <Text semibold>Vehicles</Text>
        <Text semibold>First 10,000 Miles</Text>
        <Text semibold>After 10,000 Miles</Text>
        {/* Cars and Vans */}
        <div className="flex items-center">
          <Icon name="car" className="mr-3" />
          <Text>Cars and Vans</Text>
        </div>
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.automobile}
          onChange={(val) => handleRateChanged("automobile", val)}
          placeholder={rates.automobile}
        />
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.automobile_slab_2}
          onChange={(val) => handleRateChanged("automobile_slab_2", val)}
          placeholder={rates.automobile_slab_2}
        />

        {/* Motorcycles */}
        <div className="flex items-center">
          <Icon name="motorbike" className="mr-3" />
          <Text>Motorcycles</Text>
        </div>
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.motorcycle}
          onChange={(val) => handleRateChanged("motorcycle", val)}
          placeholder={rates.motorcycle}
        />
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.motorcycle_slab_2}
          onChange={(val) => handleRateChanged("motorcycle_slab_2", val)}
          placeholder={rates.motorcycle_slab_2}
        />

        {/* Bikes */}
        <div className="flex items-center">
          <Icon name="bicycle" className="mr-3" />
          <Text>Bikes</Text>
        </div>
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.bicycle}
          onChange={(val) => handleRateChanged("bicycle", val)}
          placeholder={rates.bicycle}
        />
        <RatesInput
          disabled={setByTeam || !editable}
          prefix={`£/${rate.units}`}
          rate={rates.bicycle_slab_2}
          onChange={(val) => handleRateChanged("bicycle_slab_2", val)}
          placeholder={rates.bicycle_slab_2}
        />
        {!setByTeam && isCustomRatePresent && (
          <button
            style={{ cursor: "pointer" }}
            onClick={() => {
              return handleRatesReset();
            }}
          >
            Reset Rates
          </button>
        )}
      </div>
      {setByTeam ? null : (
        <div className="mt-4 flex gap-2">
          <Button
            loading={loading}
            disabled={!isModified || loading}
            className="font-medium"
            primary
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            disabled={!isModified || loading}
            className="font-medium"
            ghost
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default RatesUK;
