/* eslint-disable */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import EmailInput from "src/components/elements/EmailInput";
import Text from "src/components/elements/Text";

import useQueryParams from "src/hooks/useQueryParams";

import { trackPasswordResetRequested } from "src/services/tracking";
import { isValidEmail } from "src/services/utils";

import { SEND_RESET_PASSWORD_EMAIL } from "src/graphql/queries";

import ResentCar from "public/assets/img/resent-vehicle.svg";

export const ForgotPasswordEmailStep = () => {
  const searchParams = useQueryParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState(searchParams?.get?.("username") || "");

  const [sent, setSent] = useState(false);

  const [sendResetPasswordEmail, { loading }] = useLazyQuery(
    SEND_RESET_PASSWORD_EMAIL,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleEmailChange = (email) => {
    setErrorMessage(null);
    setEmail(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    if (!isValidEmail(email)) {
      return setErrorMessage("Please enter a valid email address");
    }

    trackPasswordResetRequested({ email });

    try {
      searchParams?.set?.("username", email);
      setErrorMessage(null);

      const { data } = await sendResetPasswordEmail({
        fetchPolicy: "network-only",
        variables: { username: email },
      });

      if (data?.sendResetPasswordEmail?.code === 200) {
        setSent(true);
      }
      if (data?.sendResetPasswordEmail?.code > 200) {
        setErrorMessage("An account with this email address does not exist");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="text-center mx-auto">
      {sent ? (
        <EmailSent onResend={handleSubmit} username={email} loading={loading} />
      ) : (
        <form onSubmit={handleSubmit}>
          <h1 className="text-36 mb-5 mx-auto text-center">Forgot password?</h1>
          <Text
            paragraph
            center
            color="black/70"
            className="text-lg mb-9 mx-auto"
          >
            You can reset it by entering your email address and clicking "Reset
            Password".
          </Text>
          <div>
            <EmailInput
              autoFocus
              className={"mt-12 mb-5"}
              value={email}
              onChange={handleEmailChange}
              error={errorMessage}
            />
            <Button
              lg
              className="w-full font-medium mt-[15px] laptop:mt-[10px]"
              type="submit"
              loading={loading}
            >
              Reset Password
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

const EmailSent = ({ onResend, username, loading }) => {
  return (
    <div className="text-center mx-auto" style={{ maxWidth: "500px" }}>
      <ResentCar className="mb-5 mx-auto" />
      <h1 className="text-36 mb-5 mx-auto text-center">Check your email!</h1>
      <Text paragraph center color="black/70" className="text-lg mb-9 mx-auto">
        Instructions for how to change your password have been sent to{" "}
        <strong>{username}</strong>. Please check for an email from us and then
        follow the link to create a new password.
      </Text>
      <Button
        lg
        type="button"
        className="underline bg-transparent text-black m-auto"
        loading={loading}
        onClick={onResend}
      >
        Resend email
      </Button>
    </div>
  );
};
