import React from "react";

import useElement from "src/lib/layers/useElement";

import { ELEMENT_ID as ENLARGED_MAP_ID } from "src/components/modals/EnlargedMap";

import { trackDriveMapEnlarged } from "src/services/tracking";

/**
 *
 * @param {{drive: Drive }} props
 * @returns
 */
const StaticMap = ({ drive, className, unit, currency, imgClassName }) => {
  const enlargedMapModal = useElement(ENLARGED_MAP_ID);

  const openEnlargedMap = () => {
    trackDriveMapEnlarged();
    enlargedMapModal.activate({
      props: {
        drive,
        unit,
        currency,
        onClose: () => enlargedMapModal.deactivate(),
      },
    });
  };

  return (
    <div className="hover:cursor-pointer">
      <div
        className={`relative ${className || ""}`}
        onClick={openEnlargedMap}
        tabIndex={0}
      >
        <img
          data-testid="static-map"
          className={`w-full h-full rounded border border-border-1 ${
            imgClassName || ""
          }`}
          alt="Static map"
          src={drive.map?.startEndUrl}
        />
      </div>
    </div>
  );
};

export default StaticMap;
