import React from "react";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

export default SaveSettingsConfirm;

function SaveSettingsConfirm({ loading, onCancel, onConfirm }) {
  return (
    <Modal
      loading={loading}
      closable={false}
      className="w-[440px] save-settings-confirm-modal"
    >
      <>
        <h5>You have unsaved changes!</h5>
        <Text paragraph lg className="mt-3">
          Would you like to save these changes?
        </Text>
        <div className="flex justify-end mt-8">
          <Button secondary type="button" onClick={onCancel}>
            Discard changes
          </Button>
          <Button className="ml-3" onClick={onConfirm}>
            Save changes
          </Button>
        </div>
      </>
    </Modal>
  );
}
