import * as React from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

export function FeaturingItemListTemplate({
  title,
  description,
  items,
  illustration,
  primaryCTA,
  secondaryCTA,
  disclaimer,
}) {
  const enhancedItems = (items || []).map(({ icon, title, subtitle }) => ({
    Icon: () => <Icon name={icon} color="green" className="mr-2 w-6" />,
    title,
    subtitle,
  }));
  return (
    <>
      <div className="bg-[#D6F2FF] w-full h-full flex md:items-center md:justify-center">
        <div className="bg-white w-full h-full md:w-auto md:h-auto md:rounded-24 shadow-lg py-24 pb-12 px-8 md:px-32 flex-shrink-0 max-w-[992px] my-0 md:mx-[80px]">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="flex-1">
              {title && <h2 className="mt-[15px]">{title}</h2>}
              {description && (
                <p className="text-15 mt-[15px]">{description}</p>
              )}
              <ul className="mt-[20px]">
                {enhancedItems.map((item) => (
                  <li
                    key={item.title}
                    className="flex flex-col items-start pb-[15px] mb-[7px] laptop:mb-[9px]"
                  >
                    <div className="flex items-center justify-center">
                      <item.Icon />
                      <h4 className="font-normal text-15">
                        {typeof item.title === "function"
                          ? item.title()
                          : item.title}
                      </h4>
                    </div>
                    <p className="text-13 opacity-50 mt-[5px]">
                      {typeof item.subtitle === "function"
                        ? item.subtitle()
                        : item.subtitle}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="overflow-hidden rounded-20 w-full h-[200px] md:h-auto md:w-[310px] flex justify-center items-center">
              {illustration}
            </div>
          </div>
          <div className="flex flex-row justify-center items-center py-8 mt-8 gap-4 border-t border-border-1">
            {primaryCTA && (
              <Button
                className="flex-1 h-[55px] text-[15px]"
                onClick={primaryCTA.onClick}
              >
                {primaryCTA.label}
              </Button>
            )}
            <div className="md:w-[310px]">
              {secondaryCTA && (
                <Button
                  className="w-full h-[55px] text-[15px]"
                  secondary
                  onClick={secondaryCTA.onClick}
                >
                  {secondaryCTA.label}
                </Button>
              )}
            </div>
          </div>
          {disclaimer && (
            <Text
              paragraph
              className="max-w-[340px] mx-auto text-center font-normal text-[12px]"
            >
              {disclaimer}
            </Text>
          )}
        </div>
      </div>
    </>
  );
}
