import React, { useContext } from "react";

import useElement from "src/lib/layers/useElement";

import { useAppFlash } from "src/components/context/Flash";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as ADD_CONTACT_MODAL } from "src/components/modals/AddContact";

import {
  trackContactAddCompleted,
  trackContactAddStarted,
} from "src/services/tracking";

import ContactsList from "./ContactsList";

export default ContactsPage;

function ContactsPage() {
  const { flash } = useAppFlash();
  const { userData } = useContext(UserDataContext);
  const addContactModal = useElement(ADD_CONTACT_MODAL, {
    props: {
      onClose: () => {
        addContactModal.deactivate();
      },
      onSubmit: (contact) => {
        addContactModal.deactivate();
        flash(<Text>Contact added</Text>, {
          type: FlashTypes.SAVED,
        });
        trackContactAddCompleted({
          subscriptionId: userData?.subscriptionType,
          page: "Settings",
          contactRelationship: contact.relationship,
        });
      },
    },
  });

  const handleAddContact = () => {
    addContactModal.activate();
    trackContactAddStarted({
      page: "Settings",
      subscriptionId: userData?.subscriptionType,
    });
  };

  return (
    <div className="max-w-[700px] flex flex-col gap-[20px] p-[20px] laptop:p-[15px] relative">
      <div className="">
        <h6 className="mb-[5px]">Contacts</h6>
        <Text paragraph lg>
          Add the contacts you frequently share your reports with and streamline
          your reporting process.
        </Text>
      </div>
      <ContactsList testId="contacts-list-active" />
      <div>
        <Button onClick={handleAddContact}>Add new contact</Button>
      </div>
    </div>
  );
}
