import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Select from "src/components/elements/Select";

import { REMINDER_PERIOD } from "src/models/report-reminder";

import { plural } from "src/services/utils";

import { useReportReminder } from "../context/TeamsReportReminderContext";

export const CUSTOM_REPORT_REMINDER_MODAL_ELEMENT_ID =
  "CUSTOM_REPORT_REMINDER_MODAL_ELEMENT_ID";
registerElement(CUSTOM_REPORT_REMINDER_MODAL_ELEMENT_ID, CustomReportReminder);

function CustomReportReminder({ onClose }) {
  const [error, setError] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState(REMINDER_PERIOD.DAY);
  const [repeatFrequency, setRepeatFrequency] = useState(1);
  const { setCustomReminderSettings, setReminderPeriod } = useReportReminder();

  async function handleSubmit(e) {
    e.preventDefault();

    if (repeatFrequency <= 0) {
      return setError("Please enter a number larger than 0.");
    }

    setCustomReminderSettings({
      period: selectedPeriod,
      frequency: repeatFrequency,
    });
    setReminderPeriod(REMINDER_PERIOD.CUSTOM);
    onClose();
  }

  function handleChangeRepeatFrequency(e) {
    setError("");
    let { value } = e.target;
    value = parseInt(value);
    if (isNaN(value)) value = 0;
    value = Math.abs(value);
    setRepeatFrequency(value);
  }

  const periodOptions = {
    [REMINDER_PERIOD.DAY]: {
      label: plural(repeatFrequency, "Day", "Days"),
    },
    [REMINDER_PERIOD.WEEK]: {
      label: plural(repeatFrequency, "Week", "Weeks"),
    },
    [REMINDER_PERIOD.MONTH]: {
      label: plural(repeatFrequency, "Month", "Months"),
    },
    [REMINDER_PERIOD.YEAR]: {
      label: plural(repeatFrequency, "Year", "Years"),
    },
  };

  return (
    <Modal
      passiveBackdrop
      closable={false}
      onClose={onClose}
      className="w-[440px]"
      contentClassName="p-[30px]"
    >
      <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[30px]">
        <div>
          <h5>Custom schedule</h5>
          <p className="mt-2.5 text-15">
            Set a custom schedule for your reminder.
          </p>
        </div>
        <div className="flex flex-col gap-2.5">
          <label className="text-15 font-medium">Repeats every</label>
          <div className="flex gap-2.5">
            <Input
              inputProps={{
                className: "w-[70px] min-w-[unset]",
              }}
              valid={!error}
              maxLength={3}
              value={repeatFrequency}
              onChange={handleChangeRepeatFrequency}
            />
            <div className="flex-grow">
              <Select
                options={periodOptions}
                selected={selectedPeriod}
                onSelect={setSelectedPeriod}
                dropdownOptions={{
                  contentClassName: "min-w-max w-full",
                  processElements: null,
                }}
              />
            </div>
          </div>
          {error && <p className="text-red text-13">{error}</p>}
        </div>
        <div className="flex justify-end gap-2.5">
          <Button
            type="button"
            secondary
            className="w-[110px] font-medium"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" primary className="w-[110px] font-medium">
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
}
