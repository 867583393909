import React, { useRef, useState } from "react";

import Dropdown from "src/components/elements/Dropdown";
import Text from "src/components/elements/Text";

import { KEY_CODES } from "src/services/utils";

import Icon from "./Icon";

export default MiqSelect;

function MiqSelect({
  alignRight,
  alignTop,
  selected,
  noSelectionLabel,
  options,
  onOpen,
  onClose,
  onSelect,
  dropdownOptions,
  disabled,
  matchTriggerWidth,
  placeholder,
  listClassName,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const dropdownRef = useRef();
  const selectRef = useRef();

  const close = () => {
    setIsOpen(false);
    onClose?.();
    setTabIndex(0);
  };

  const handleSelect = (k) => {
    onSelect?.(k);
    close();
    setTimeout(() => {
      dropdownRef.current.focusTrigger();
    }, 100);
  };

  const handleOnOpen = () => {
    onOpen?.();
    setIsOpen(disabled ? false : true);
  };

  const handleTriggerOnKeyUp = (e) => {
    const { code } = e;

    if (
      ![
        KEY_CODES.enter,
        KEY_CODES.space,
        KEY_CODES.arrowUp,
        KEY_CODES.arrowDown,
      ].includes(code)
    )
      return;

    e.preventDefault();

    handleOnOpen();
    setTimeout(() => {
      const optBtn = selectRef.current.querySelector(
        ".selection-option button"
      );
      optBtn.focus();
      setTabIndex(-1);
    }, 200);
  };

  const handleOptionOnKeyUp = (e) => {
    if (e.code == KEY_CODES.escape) {
      e.preventDefault();
      handleOnClose();
      setTimeout(() => {
        dropdownRef.current.focusTrigger();
      }, 100);
      return;
    }
    if (e.code == KEY_CODES.arrowUp || e.code == KEY_CODES.arrowDown) {
      e.preventDefault();
      const isUp = e.code == KEY_CODES.arrowUp;
      const opt = e.target;
      let optToFocusParent;
      if (isUp) {
        optToFocusParent = opt.parentElement.previousElementSibling;
      } else {
        optToFocusParent = opt.parentElement.nextElementSibling;
      }
      if (optToFocusParent) {
        optToFocusParent.querySelector("button")?.focus();
      }

      return false;
    }
  };

  const handleOnBlur = () => {
    handleOnClose();
    setTabIndex(0);
  };

  const handleOnClose = () => {
    close();
  };

  const selectedOption =
    selected && options[selected] ? options[selected] : noSelectionLabel;

  const otherOpts = {
    triggerClassName:
      "miq-select-trigger w-full justify-between border border-border-1 shadow-none outline-none",
    processElements: ({ containerEl, triggerEl }) => {
      if (!triggerEl || !containerEl) return;
      containerEl.style.position = "fixed";
      containerEl.style.left = 0;
      containerEl.style.top = 0;
      const containerRect = containerEl.getBoundingClientRect();
      const triggerRect = triggerEl.getBoundingClientRect();
      if (alignRight) {
        containerEl.style.left =
          triggerRect.x -
          containerRect.x +
          triggerRect.width -
          containerRect.width +
          "px";
      } else {
        containerEl.style.left = triggerRect.x + "px";
      }

      if (matchTriggerWidth) {
        containerEl.style.width = triggerRect.width + "px";
      }

      if (alignTop) {
        containerEl.style.top = triggerRect.y - containerRect.height - 4 + "px";
      } else {
        containerEl.style.top =
          triggerRect.y - containerRect.y + triggerRect.height + 4 + "px";
      }
    },
    ...(dropdownOptions || {}),
  };

  const ddClasses = ["miq-select"];
  if (!selected) ddClasses.push("no-selection");
  if (disabled) ddClasses.push("disabled");

  return (
    <Dropdown
      ref={dropdownRef}
      label={selectedOption?.label || selectedOption || placeholder || ""}
      labelClassName="font-normal"
      icon={selectedOption?.icon || null}
      className={ddClasses.join(" ")}
      open={isOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      onKeyUp={handleTriggerOnKeyUp}
      tabIndex={tabIndex}
      {...otherOpts}
    >
      <ul ref={selectRef} className={`p-0 m-0 ${listClassName || ""}`}>
        {Object.keys(options).map((k) => {
          const isSelected = k === selected;
          const { icon, label, dropdownLabel, className } = options[k];
          return (
            <li
              key={k}
              className={`selection-option ${isSelected ? "is-selected" : ""}`}
            >
              <button
                type="button"
                className="w-full whitespace-nowrap flex items-center font-normal border-none outline-none px-2.5 py-2.5 pr-5 rounded-8"
                onClick={() => handleSelect(k)}
                onKeyUp={handleOptionOnKeyUp}
                onBlur={(e) => {
                  const newTarget = e.relatedTarget;
                  if (
                    !newTarget?.parentElement?.classList?.contains(
                      "selection-option"
                    )
                  ) {
                    handleOnBlur();
                  }
                }}
              >
                {icon && <Icon name={icon} className="mr-2" />}
                <Text paragraph className={className}>
                  {dropdownLabel || label}
                </Text>
              </button>
            </li>
          );
        })}
      </ul>
    </Dropdown>
  );
}
