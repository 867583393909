import React, { useCallback, useEffect, useRef } from "react";
import { useImperativeHandle } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

export default React.forwardRef(MiqDropdown);

function MiqDropdown(
  {
    icon,
    iconColor,
    label,
    renderTrigger,
    open,
    onOpen,
    onClose,
    onFocus,
    onKeyUp,
    tabIndex = -1,
    hasValue,
    className,
    triggerClassName,
    labelClassName,
    openTriggerClassName,
    contentClassName,
    minContentWidth,
    maxContentWidth,
    contentWidth,
    position,
    processElements,
    stopPropagation,
    suffix,
    tooltip,
    children,
    testId,
  },
  ref
) {
  const triggerRef = useRef();
  const containerRef = useRef();

  const onClickOutside = useCallback(
    (e) => {
      if (!containerRef?.current) return;
      if (open && !containerRef.current.contains(e.target)) {
        onClose();
      }
    },
    [open]
  );
  useImperativeHandle(
    ref,
    () => {
      return {
        focusTrigger: () => {
          triggerRef.current?.focus();
        },
      };
    },
    []
  );
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.body.addEventListener("click", onClickOutside);
      }, 200);
    } else {
      document.body.removeEventListener("click", onClickOutside);
    }
    return () => document.body.removeEventListener("click", onClickOutside);
  }, [open]);

  const handleTriggerClicked = useCallback(
    (e) => {
      e.preventDefault();

      open ? onClose?.() : onOpen?.();
      if (stopPropagation) e.stopPropagation();
    },
    [open, onOpen]
  );

  const positionElement = useCallback((position) => {
    const triggerEl = triggerRef?.current;
    const containerEl = containerRef?.current;

    if (!triggerEl || !containerEl) return;
    containerEl.style.position = "fixed";
    containerEl.style.left = 0;
    containerEl.style.top = 0;
    const containerRect = containerEl.getBoundingClientRect();
    const triggerRect = triggerEl.getBoundingClientRect();

    switch (position) {
      case "top":
        containerEl.style.left =
          triggerRect.x -
          containerRect.x +
          triggerRect.width -
          containerRect.width +
          triggerRect.width +
          10 +
          "px";
        containerEl.style.top =
          triggerRect.y - containerRect.y - containerRect.height - 4 + "px";
        break;
      case "bottom":
        containerEl.style.left =
          triggerRect.x -
          containerRect.x +
          triggerRect.width -
          containerRect.width +
          triggerRect.width +
          10 +
          "px";
        containerEl.style.top =
          triggerRect.y - containerRect.y + triggerRect.height + 4 + "px";
        break;
      case "left":
        containerEl.style.left =
          triggerRect.x -
          containerRect.x +
          triggerRect.width -
          containerRect.width -
          triggerRect.width -
          10 +
          "px";
        containerEl.style.top =
          triggerRect.y -
          containerRect.y -
          containerRect.height / 2 +
          10 +
          "px";
        break;
      case "right":
        containerEl.style.left =
          triggerRect.x - containerRect.x + triggerRect.width + 10 + "px";
        containerEl.style.top =
          triggerRect.y -
          containerRect.y -
          containerRect.height / 2 +
          10 +
          "px";
        break;
      default:
        containerEl.style.left = 0;
        containerEl.style.top = 0;
    }
  }, []);

  const calcPosition = useCallback(() => {
    if (typeof processElements === "function") {
      processElements({
        triggerEl: triggerRef?.current,
        containerEl: containerRef?.current,
      });
    } else if (["top", "bottom", "left", "right"].includes(position)) {
      positionElement(position);
    }
  }, [triggerRef, containerRef, position, processElements, positionElement]);

  useEffect(() => {
    calcPosition();
  }, [open, position]);

  useEffect(() => {
    if (processElements || position) {
      window.addEventListener("resize", calcPosition);
      document.addEventListener("scroll", calcPosition, true);
    }

    return () => {
      if (processElements || position) {
        window.removeEventListener("resize", calcPosition);
        document.removeEventListener("scroll", calcPosition, true);
      }
    };
  }, [processElements, position]);

  return (
    <div
      className={`miq-dropdown ${open ? "open" : ""} ${
        hasValue ? "has-value" : ""
      } ${className || ""}`}
    >
      <div
        ref={triggerRef}
        data-testid={testId || "miq-trigger-dropdown"}
        className={`miq-dropdown-trigger ${triggerClassName || ""} ${
          (open && openTriggerClassName) || ""
        }`}
        onClick={handleTriggerClicked}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
      >
        {typeof renderTrigger === "function" ? (
          renderTrigger()
        ) : (
          <>
            {!!icon && (
              <Icon
                name={icon}
                color={iconColor || "black"}
                className="prefix"
              />
            )}
            <Text
              data-testid="miq-dropdown-label"
              semibold
              className={`miq-dropdown-label ${labelClassName || ""}`}
            >
              {label}
            </Text>
            <div className="suffix">
              {suffix || <Icon name="caret" color="black" />}
            </div>
          </>
        )}
      </div>

      {tooltip && !open && (
        <Tooltip
          triggerRef={triggerRef}
          timeout={1000}
          offset={tooltip.offset}
          alignX={tooltip.alignX}
        >
          <Text nowrap>{tooltip.text}</Text>
        </Tooltip>
      )}

      {open ? (
        <div
          ref={containerRef}
          className={`miq-dropdown-content ${contentClassName || ""}`}
          style={{
            width: `${contentWidth}px`,
            minWidth: `${minContentWidth || 0}px`,
            maxWidth: maxContentWidth ? `${maxContentWidth || 0}px` : undefined,
          }}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export function MiqDropdownItem({
  icon,
  color,
  children,
  className,
  onClick,
  disabled,
  containerProps,
  textProps,
  containerRef,
}) {
  return (
    <button
      ref={containerRef}
      className={`w-full p-2.5 flex items-center text-left gap-2.5  rounded-8 transition-all ${
        disabled
          ? "cursor-not-allowed bg-transparent hover:bg-transparent active:bg-transparent"
          : "cursor-pointer hover:bg-beige active:bg-beige-medium"
      } ${className || ""}`}
      onClick={disabled ? undefined : onClick}
      {...containerProps}
    >
      {icon && (
        <div className="w-5 h-5 flex items-center">
          <Icon name={icon} color={disabled ? "black-op50" : color} />
        </div>
      )}
      <Text color={disabled ? "black/50" : color} {...textProps}>
        {children}
      </Text>
    </button>
  );
}
