import { apolloClient } from "src/components/context/ApolloContext";

import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "src/services/storage";

import { LOGIN_WITH_FIREBASE, REFRESH_TOKEN } from "src/graphql/mutations";

import { report } from "./error-reporting";
import { trackLogout } from "./tracking";

const SESSION_EMAIL_HINT_KEY = "miq_sessionEmailHint";

export const setSessionEmailHint = (email) => {
  sessionStorage?.setItem(SESSION_EMAIL_HINT_KEY, email);
};
export const getSessionEmailHint = () => {
  const email = sessionStorage?.getItem(SESSION_EMAIL_HINT_KEY);
  removeSessionEmailHint();
  return email;
};
export const removeSessionEmailHint = () => {
  sessionStorage?.removeItem(SESSION_EMAIL_HINT_KEY);
};

export const ACCOUNT_REALM_TYPE = {
  UNKNOWN: 0,
  MILEIQ: 1,
  MSA: 2,
  MSA_NON_EMAIL: 3,
  AAD: 4,
  MSA_AND_AAD: 5,
};

export const INPUT_ERROR_MESSAGES = {
  INCORRECT_CURRENT_PASSWORD: "Looks like you entered an incorrect password",
  INCOMPLEX_PASSWORD: "The password isn't complex enough",
  VALID_PASSWORD_REQUIRED: "Please enter a valid password",
  NOT_MATCH: "Passwords do not match",
  SAME_AS_OLD_PASSWORD:
    "Your new password must be different. Please try again.",
};

export async function logout() {
  trackLogout();
  window.location.href = "/logout";
}

export async function tryRefreshToken() {
  const refreshToken = getRefreshToken();

  const removeTokensAndLogout = () => {
    removeRefreshToken();
    removeAccessToken();
  };

  try {
    if (!refreshToken) throw new Error("Refresh token not found");

    removeAccessToken(false);

    const res = await apolloClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        token: refreshToken,
      },
    });

    const {
      code,
      token,
      refreshToken: newRefreshToken,
    } = res.data.refreshToken;

    if (code === 200) {
      setAccessToken(token);
      setRefreshToken(newRefreshToken);
    } else {
      throw new Error(`Failed to refresh token with status code ${code}`);
    }
  } catch (err) {
    removeTokensAndLogout();

    throw err;
  }
}

export async function loginWithFirebase(
  accessToken,
  email = null,
  source = null
) {
  try {
    const res = await apolloClient.mutate({
      mutation: LOGIN_WITH_FIREBASE,
      variables: {
        token: accessToken,
      },
    });
    return res.data.loginWithFirebase;
  } catch (err) {
    report({
      devInfo:
        "auth.js::loginWithFirebase::catch Failed to login with firebase using LOGIN_WITH_FIREBASE",
      properties: { accessToken, email, source },
      error: err,
    });
    return null;
  }
}
