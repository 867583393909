import format from "date-fns/format";
import isThisYear from "date-fns/isThisYear";
import React, { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import TeamContext from "src/components/context/TeamContext";
import {
  COLUMNS,
  RECORDS_PER_PAGE,
  useTeamDrives,
} from "src/components/context/TeamDrivesContext";

import MiqButton from "src/components/elements/Button";
import MiqCheckbox from "src/components/elements/Checkbox";
import Icon from "src/components/elements/Icon";
import Pagination from "src/components/elements/Pagination";
import Tooltip from "src/components/elements/Tooltip";

import DistanceWithCommute from "src/components/blocks/teams-drives/DistanceWithCommute";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { SORT_ORDER } from "src/services/http";
import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
} from "src/services/teams";
import { WebPages, trackTeamsDriveSelected } from "src/services/tracking";
import { isValidDate } from "src/services/utils";

import OkCarWhite from "public/assets/img/ok-car-white.svg";

export default TeamDrivesToApproveList;

const COLUMNS_DATA = {
  [COLUMNS.SELECT]: {
    title: "",
    sortable: false,
    extraClass: "pl-4",
    isToggleAll: true,
  },
  [COLUMNS.START_LOCATION]: {
    title: "Start Location",
    extraClass: "desktop-version",
    sortable: false,
    fieldName: "start_location",
  },
  [COLUMNS.END_LOCATION]: {
    title: "End Location",
    extraClass: "desktop-version",
    sortable: false,
    fieldName: "end_location",
  },
  [COLUMNS.MANUALLY_ADDED]: {
    title: "Manual",
    extraClass: "desktop-version",
    sortable: false,
    fieldName: "is_manually_added",
  },
  [COLUMNS.PURPOSE]: {
    title: "Purpose",
    extraClass: "desktop-version",
    sortable: false,
    fieldName: "purpose",
  },
  [COLUMNS.DATE]: {
    title: "Date",
    extraClass: "report-list-column-fix",
    sortable: true,
    fieldName: "end_date",
  },
  [COLUMNS.DISTANCE]: {
    title: "Distance",
    extraClass: "desktop-version",
    sortable: true,
    fieldName: "distance",
  },
  [COLUMNS.VALUE]: {
    title: "Total value",
    alignRight: true,
    sortable: true,
    fieldName: "total_value",
  },
  [COLUMNS.COMMENTS]: {
    title: "",
    sortable: false,
    fieldName: "comments",
  },
  [COLUMNS.ACTIONS]: {
    title: "",
    alignRight: true,
    sortable: false,
    fieldName: "",
    extraClass: "pr-5",
  },
};

function TeamDrivesToApproveList() {
  const {
    teamDrives,
    isTeamDrivesLoading,
    hasTeamDrivesLoaded,
    page,
    sortBy,
    order,
    selectedDrive,
    setPastSelectedDriveId,
    areAllDrivesChecked,
    isAnyDriveChecked,
    isTeamDrivesEmpty,
    loadingItemsCount,
    checkAllDrives,
    shouldRenderEmptyState,
  } = useTeamDrives();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const handleColumnClicked = (columnKey) => {
    if (isTeamDrivesLoading) return;

    let sortOrder = SORT_ORDER.DESC;
    if (sortBy === columnKey) {
      sortOrder = order === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    }

    queryParams.set("sortBy", columnKey);
    queryParams.set("order", sortOrder);
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handlePageClicked = (pageIndex) => {
    if (isTeamDrivesLoading || loadingItemsCount > 0) return;

    queryParams.set("page", pageIndex);
    history.replace({ search: queryParams.toString() });
  };

  const handleRowClicked = (driveId) => {
    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    const currentDriveId = selectedDrive?.id;
    const isSelected = currentDriveId === driveId;
    const newSelectedDriveId = isSelected ? null : driveId;

    if (selectedDrive) {
      setPastSelectedDriveId(currentDriveId);
    }

    if (newSelectedDriveId) {
      queryParams.set("selectedDriveId", newSelectedDriveId);

      trackTeamsDriveSelected({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        webPage: WebPages.APPROVE_DRIVES_LIST_TEAM_DASHBOARD,
      });
    } else {
      queryParams.delete("selectedDriveId");
    }

    history.replace({ search: queryParams.toString() });
  };

  const pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage: page,
    total: teamDrives?.total_results || 0,
    onSelectPage: handlePageClicked,
  };

  const hasPagination = teamDrives?.pages > 1;
  const hasSelectedDrive = !!selectedDrive;

  return (
    <div className="flex-grow flex flex-col">
      <div
        className={`miq-list-table team-drives-to-approve-list-table ${
          hasSelectedDrive ? "without-actions-column" : ""
        }`}
      >
        <div className="miq-list-table-header h-[40px] hover:bg-transparent">
          {Object.keys(COLUMNS_DATA).map((columnKey) => {
            const { extraClass, title, sortable, alignRight, isToggleAll } =
              COLUMNS_DATA[columnKey];

            if (isToggleAll) {
              return (
                <div
                  key={columnKey}
                  className={`select-none cursor-pointer p-2 ${
                    extraClass || ""
                  }`}
                >
                  <MiqCheckbox
                    stopPropagation
                    checked={areAllDrivesChecked}
                    indeterminate={isAnyDriveChecked}
                    onChange={checkAllDrives}
                    disabled={isTeamDrivesLoading || isTeamDrivesEmpty}
                  />
                </div>
              );
            }

            return (
              <div
                key={columnKey}
                className={`select-none p-2 ${
                  sortable ? "cursor-pointer" : ""
                } ${extraClass || ""}`}
                onClick={() => sortable && handleColumnClicked(columnKey)}
              >
                <div className={`flex ${alignRight ? "justify-end" : ""}`}>
                  <p className="text-[rgba(23,23,23,0.6)] text-13 truncate">
                    {title}
                  </p>
                  {sortable && sortBy === columnKey ? (
                    <Icon
                      name="sort"
                      color="tertiary"
                      className={`ml-2 transform ${
                        order === SORT_ORDER.DESC ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {hasTeamDrivesLoaded && isTeamDrivesEmpty && shouldRenderEmptyState && (
          <div className="w-full min-h-[630px] flex flex-col justify-center items-center gap-[24px]">
            <OkCarWhite className="opacity-30" />
            <p className="text-black/60 text-center max-w-[275px]">
              No drives to approve for this time period. Try adjusting your
              filters.
            </p>
          </div>
        )}
        {hasTeamDrivesLoaded && !isTeamDrivesEmpty && (
          <TransitionGroup component="div">
            {teamDrives.data.map((drive) => (
              <CSSTransition
                key={drive.id}
                timeout={200}
                classNames="removable-item"
              >
                <TeamDriveRow
                  drive={drive}
                  isSelected={selectedDrive?.id === drive.id}
                  onClick={() => handleRowClicked(drive.id)}
                  hasFollowingElements={hasPagination || loadingItemsCount > 0}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
        {(isTeamDrivesLoading || loadingItemsCount > 0) &&
          Array.from({ length: loadingItemsCount }).map((_, i) => (
            <TeamDriveRow
              isLoading
              key={`loading-${i}`}
              rowClassName={
                isTeamDrivesLoading ? "" : "animate-expand-table-row"
              }
              hasFollowingElements={hasPagination}
            />
          ))}
      </div>
      {hasPagination && (
        <div className="my-[15px] mx-5">
          <Pagination {...pagination} />
        </div>
      )}
    </div>
  );
}

const ManuallyAddedIcon = () => {
  const tooltipRef = useRef();

  return (
    <>
      <Icon
        iconRef={tooltipRef}
        className="cursor-pointer"
        name="m-in-circle"
      />
      <Tooltip
        width={122}
        offset={{ y: -45, x: 0 }}
        alignX="center"
        triggerRef={tooltipRef}
        className="py-2.5 px-3"
        timeout={500}
      >
        <p className="text-13">Manually added</p>
      </Tooltip>
    </>
  );
};

const TeamDriveRow = ({
  rowClassName,
  hasFollowingElements,
  drive = {},
  isSelected,
  onClick = () => {},
  isLoading,
}) => {
  const { checkedDrives, reviewDrives, checkDrive } = useTeamDrives();

  const {
    start_location: startLocation,
    end_location: endLocation,
    started_at: startedAt,
    ended_at: endedAt,
    distance,
    distance_unit: distanceUnit,
    total_value: value,
    currency_unit: currencyUnit,
    is_manually_added: isManuallyAdded,
    toll_fees: tolls,
    parking_fees: parking,
    commute_distance: commuteDistance,
    total_distance: totalDistance,
    purpose,
    has_comment: hasComment,
  } = drive;

  const endDate = new Date(endedAt);
  const startDate = new Date(startedAt);
  const startTime = isValidDate(startDate) && format(startDate, "hh:mm a");
  const endTime = isValidDate(endDate) && format(endDate, "hh:mm a");
  const extras = parseFloat(parking) + parseFloat(tolls);
  const totalValue = parseFloat(value) + extras;

  const commentTooltipRef = useRef(null);

  const driveDate =
    isValidDate(endDate) &&
    format(endDate, isThisYear(endDate) ? "MMM d" : "MMM d, yyyy");

  return (
    <div
      role="button"
      tabIndex="0"
      className={`w-full text-start miq-list-table-body-row h-[60px] group/row-button ${
        hasFollowingElements ? "" : "last:border-none last:rounded-b-[14px]"
      } ${isSelected ? "bg-beige" : ""} ${
        isLoading ? "pointer-events-none" : ""
      } ${rowClassName || ""}`}
      onClick={onClick}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      {/* Select */}
      <div className="p-4 pr-1">
        {isLoading ? (
          <div className="w-[20px] max-w-full h-[20px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <MiqCheckbox
            stopPropagation
            checked={checkedDrives.includes(drive.id)}
            onChange={() => checkDrive({ driveId: drive.id })}
          />
        )}
      </div>

      {/* Start Location */}
      <div className="p-2 truncate">
        {isLoading ? (
          <div className="w-[280px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-between">
              <span className="text-black truncate">{startLocation}</span>
              <Icon name="arrow-right-3" className="relative -right-3" />
            </div>
            <span className="text-black/70 text-sm">{startTime}</span>
          </div>
        )}
      </div>
      {/* End Location */}
      <div className="p-2 desktop-version truncate">
        {isLoading ? (
          <div className="w-[280px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <div className="flex flex-col">
            <span className="text-black truncate">{endLocation}</span>
            <span className="text-black/70 text-sm">{endTime}</span>
          </div>
        )}
      </div>
      {/* Is Manually Added */}
      <div className="p-2 desktop-version">
        {isLoading ? (
          <div className="mx-auto w-[24px] max-w-full h-[24px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          isManuallyAdded && <ManuallyAddedIcon />
        )}
      </div>
      {/* Purpose */}
      <div className="p-2 desktop-version">
        {isLoading ? (
          <div className="w-[140px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <p className="text-black truncate">{purpose}</p>
        )}
      </div>
      {/* Date */}
      <div className="p-2 desktop-version">
        {isLoading ? (
          <div className="w-[90px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <p className="text-black">{driveDate}</p>
        )}
      </div>
      {/* Distance */}
      <div className="p-2 report-list-column-fix">
        {isLoading ? (
          <div className="w-[60px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <DistanceWithCommute
            distanceUnit={distanceUnit}
            totalDistance={totalDistance}
            finalDistance={distance}
            commute={commuteDistance}
            tooltipOffset={{ y: -100 }}
            className="-ml-2"
          >
            <span className="text-black whitespace-nowrap">
              {distance?.toFixed?.(1)} {distanceUnit}
            </span>
          </DistanceWithCommute>
        )}
      </div>
      {/* Total value */}
      <div className="p-2">
        {isLoading ? (
          <div className="ml-auto w-[50px] max-w-full h-[16px] rounded-4 bg-black/10 animate-pulse" />
        ) : (
          <p className="text-green font-semibold text-end">
            {currencyUnit}
            {totalValue?.toFixed?.(2)}
          </p>
        )}
      </div>

      <div>
        {hasComment && (
          <>
            <Icon iconRef={commentTooltipRef} name="comment-dots" />
            <Tooltip
              offset={{ y: -50, x: 0 }}
              alignX="center"
              className="py-2.5 px-3"
              timeout={500}
              triggerRef={commentTooltipRef}
            >
              Has comment
            </Tooltip>
          </>
        )}
      </div>

      {/* Actions */}
      <div
        className={`${
          isSelected ? "flex" : "hidden"
        } group-focus-visible/row-button:flex group-hover/row-button:flex justify-end gap-3 pr-5 `}
      >
        <MiqButton
          onClick={(e) => {
            e.stopPropagation();
            reviewDrives({
              driveIds: [drive.id],
              action: DRIVE_REVIEW_STATUS_ACTION.REJECT,
              additionalFilters: { review_status: DRIVE_REVIEW_STATUS.PENDING },
              shouldRemoveReviewedDrives: true,
            });
          }}
          tooltip={{
            text: "Reject",
            alignX: "center",
            timeout: 500,
            offset: { y: -45 },
          }}
          icon="ban"
          secondary
          className="bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]"
        />
        <MiqButton
          onClick={(e) => {
            e.stopPropagation();
            reviewDrives({
              driveIds: [drive.id],
              action: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
              additionalFilters: { review_status: DRIVE_REVIEW_STATUS.PENDING },
              shouldRemoveReviewedDrives: true,
            });
          }}
          tooltip={{
            text: "Approve",
            alignX: "center",
            timeout: 500,
            offset: { y: -45 },
          }}
          secondary
          icon="checked-circle-black"
          className="bg-[rgba(23,23,23,0.06)] hover:bg-[rgba(23,23,23,0.12)]"
        />
      </div>
    </div>
  );
};
