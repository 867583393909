import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useRef, useState } from "react";

import useElement from "src/lib/layers/useElement";

import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Flash, { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import PasswordInput from "src/components/elements/PasswordInput";
import Text from "src/components/elements/Text";

import { validations } from "src/components/blocks/auth/PasswordValidations";
import { Captcha, captchaErrors } from "src/components/blocks/captcha";

import { ELEMENT_ID as RESET_PASSWORD_MODAL_ID } from "src/components/modals/ResetPassword";

import useFlash from "src/hooks/useFlash";

import { INPUT_ERROR_MESSAGES } from "src/services/auth";
import { report } from "src/services/error-reporting";
import {
  trackPasswordResetCompleted,
  trackPasswordResetFailed,
  trackPasswordResetStarted,
} from "src/services/tracking";

import { SET_NEW_PASSWORD } from "src/graphql/mutations";
import { CONFIRM_PASSWORD } from "src/graphql/queries";

export default ChangePassword;

function ChangePassword({ isMSAUser }) {
  const captchaRef = useRef();
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const { user } = useUser();

  const [msg, setMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [isInvalidCurentPass, setInvalidCurrentPass] = useState(false);
  const [showNewPasswordFields, setShowNewPasswordFields] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isIncomplex, setIsIncomplex] = useState(false);
  const [notMatched, setNotMatched] = useState(false);
  const [sameAsOldPassword, setSameAsOldPassword] = useState(false);

  const [flash, SendEmailFlash] = useFlash();

  const handleForgotPasswordClick = () => {
    if (isMSAUser) {
      window.location.href = `https://account.live.com/ResetPassword.aspx?mn=${encodeURIComponent(
        user.email
      )}`;
      return;
    }
    resetPasswordModal.activate();
  };

  const resetPasswordModal = useElement(RESET_PASSWORD_MODAL_ID, {
    props: {
      onClose: () => resetPasswordModal.deactivate(),
      onSubmit: () => {
        resetPasswordModal.deactivate();
        flash(<Text>{`Instructions sent to ${user.email}`}</Text>, {
          type: FlashTypes.SAVED,
        });
      },
      email: user.email,
    },
  });

  const [confirmPassword] = useLazyQuery(CONFIRM_PASSWORD);

  const onCaptchaLoad = () => {
    setLoadingCaptcha(false);
  };

  const handleConfirmPasswordClick = async () => {
    if (loadingCaptcha) return;
    try {
      let headers;
      if (captchaRef?.current) {
        const captchaToken = await captchaRef.current.executeAsync?.();
        captchaRef.current.reset?.();
        if (!captchaToken) {
          throw new Error(captchaErrors.RECAPTCHA_TOKEN_REQUIRED);
        }
        headers = {
          "x-recaptcha-token": captchaToken,
        };
      }

      trackPasswordResetStarted({ email: user.email });
      setSameAsOldPassword(false);
      setInvalidCurrentPass(false);
      const { data } = await confirmPassword({
        fetchPolicy: "network-only",
        variables: {
          password: currentPassword,
          username: user.email,
        },
        context: {
          headers,
        },
      });
      const isValid = data?.confirmPassword.is_valid_password;
      if (!isValid) {
        setInvalidCurrentPass(true);
        trackPasswordResetFailed({ email: user.email });
      } else {
        setShowNewPasswordFields(true);
      }
    } catch (e) {
      const errorMessage =
        e?.message ||
        e ||
        "Something went wrong while reseting this account password.";
      report(errorMessage);
      if (
        [400, 422, 500].includes(e?.networkError?.statusCode) ||
        (errorMessage === captchaErrors.RECAPTCHA_TOKEN_REQUIRED &&
          captchaRef?.current)
      ) {
        captchaRef.current.reset?.();
        captchaRef.current.execute?.();
      }
      flash(
        <p>
          Something went wrong while reseting your account password. Please try
          again later or contact the support.
        </p>,
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };

  const [setNewPassMutFn, { loading, error }] = useMutation(SET_NEW_PASSWORD, {
    notifyOnNetworkStatusChange: true,
  });

  const handleSaveClick = async (notifySuccess = true) => {
    setMsg("Saving...");
    setVisible(true);

    if (
      validations.find((val) => val.matched == false) ||
      newPassword != confirmNewPassword
    ) {
      setIsIncomplex(validations.find((val) => val.matched == false) || false);
      setNotMatched(newPassword != confirmNewPassword ? true : false);
      setVisible(false);

      // track reset password error
      let details = {};
      validations.forEach((v) => {
        details[v.key] = v.reg.test(newPassword);
      });
      trackPasswordResetFailed({ email: user.email, ...details });
      return;
    }

    try {
      const { data } = await setNewPassMutFn({
        variables: {
          data: {
            newPassword,
          },
        },
      });

      if (notifySuccess && data.setPassword.code == 200) {
        trackPasswordResetCompleted({ email: user.email });
        clearState();
        setCurrentPassword("");
        setMsg("Password updated");
        setTimeout(() => {
          setVisible(false);
        }, 1500);
      } else if (notifySuccess && (await data.setPassword.code) == 215) {
        clearState();
        setMsg("An error has ocurred!");
        setShowNewPasswordFields(true);
        setSameAsOldPassword(true);
        trackPasswordResetFailed({ email: user.email });
        setTimeout(() => {
          setVisible(false);
        }, 1500);
      } else {
        setVisible(false);
      }
    } catch (e) {
      console.log(e);
      setMsg("An error has ocurred!");
      trackPasswordResetFailed({ email: user.email });
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  };

  const handleCancelClick = () => {
    clearState();
    setCurrentPassword("");
    setSameAsOldPassword(false);
  };

  const clearState = () => {
    setShowNewPasswordFields(false);
    setNewPassword("");
    setConfirmNewPassword("");
    setIsIncomplex(false);
    setNotMatched(false);
    setSameAsOldPassword(false);
  };

  validations.forEach((val) => {
    val.matched = newPassword && newPassword.match(val.reg) ? true : false;
  });

  return (
    <>
      <div className="relative" data-testis="change-password">
        <div className="mb-[15px] flex justify-between">
          <h6>Change password</h6>
          <button
            type="button"
            onClick={handleForgotPasswordClick}
            className="bg-transparent border-none outline-none font-medium text-blue"
          >
            Forgot Password?
          </button>
        </div>
        <PasswordInput
          label="Current password"
          className="w-[340px]"
          value={currentPassword}
          disabled={showNewPasswordFields}
          onChange={setCurrentPassword}
          valid={!isInvalidCurentPass}
          errorMessage={INPUT_ERROR_MESSAGES.INCORRECT_CURRENT_PASSWORD}
          forChangePassword={true}
        />
        {showNewPasswordFields ? (
          <div>
            <PasswordInput
              label="New Password"
              className="w-[340px] mt-5"
              value={newPassword}
              onChange={setNewPassword}
              valid={!isIncomplex}
              errorMessage={INPUT_ERROR_MESSAGES.INCOMPLEX_PASSWORD}
              forChangePassword={true}
            />
            <div className="mt-5 mb-4">
              {validations.map((val) => (
                <div className="flex mb-1" key={val.title}>
                  <Icon
                    className="mr-3"
                    name={val.matched ? "checked-green" : "dot"}
                    color={val.matched ? "none" : isIncomplex ? "red" : "gray"}
                  />
                  <Text>{val.title}</Text>
                </div>
              ))}
            </div>
            <PasswordInput
              label="Confirm New Password"
              className="w-[340px] mt-5"
              value={confirmNewPassword}
              onChange={setConfirmNewPassword}
              valid={!notMatched && !sameAsOldPassword}
              errorMessage={
                sameAsOldPassword
                  ? INPUT_ERROR_MESSAGES.SAME_AS_OLD_PASSWORD
                  : INPUT_ERROR_MESSAGES.NOT_MATCH
              }
              forChangePassword={true}
            />
            <div className="mt-[15px] flex">
              <Button
                onClick={handleSaveClick}
                className="font-medium mr-2.5"
                disabled={loadingCaptcha}
              >
                Save
              </Button>
              <Button
                ghost
                type="button"
                className="font-medium"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex mt-[7px] justify-center">
              <Captcha ref={captchaRef} onLoad={onCaptchaLoad} />
            </div>
            <Button
              disabled={loadingCaptcha || !currentPassword.length}
              onClick={handleConfirmPasswordClick}
              className="flex-grow font-medium mt-[8px]"
            >
              Continue
            </Button>
          </>
        )}
      </div>
      {SendEmailFlash}
      <Flash
        className="miq-flash-single-msg"
        visible={visible}
        msg={msg}
        type={
          loading
            ? FlashTypes.SAVING
            : error || sameAsOldPassword
            ? FlashTypes.ERROR
            : FlashTypes.SAVED
        }
      />
    </>
  );
}
