import React from "react";

export default CustomActiveDot;

function CustomActiveDot({ cx, cy, onMouseOver, onMouseOut }) {
  return (
    <g>
      <circle cx={cx} cy={cy} r={4} fill={"#206DD0"} stroke="none" />
      <circle
        className="cursor-pointer"
        cx={cx}
        cy={cy}
        r={45}
        fill="transparent"
        stroke="none"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </g>
  );
}
