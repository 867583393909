/**
 * This page serves one purpose - track if our QR code to download the app was scanned
 * Here we just redirect to the apple/google store
 */
import { useEffect } from "react";

import Loader from "src/components/elements/Loader";

import useQueryParams from "src/hooks/useQueryParams";

import {
  AppDownloadRequestMethod,
  init,
  trackAppDownloadRequested,
} from "src/services/tracking";
import { appstoreOnelink } from "src/services/utils";

export default QRTargetPage;

function QRTargetPage() {
  const qs = useQueryParams();
  useEffect(() => {
    const uid = qs.get("uid");
    if (uid) {
      init({ id: uid });
    }
    trackAppDownloadRequested({
      method: AppDownloadRequestMethod.QR_CODE,
    });
    // redirect after timeout to ensure avo sent the telemetry
    setTimeout(() => {
      window.location.href = appstoreOnelink + "?uid" + qs.get("uid");
    }, 1000);
  }, []);
  return <Loader />;
}
