import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { WebPages, trackWebPageViewed } from "src/services/tracking";

export const ROUTE_TELEMETRY_MAP = {
  "/teams/overview": WebPages.OVERVIEW_TEAM_DASHBOARD,
  "/teams/users": WebPages.MEMBERS_TEAM_DASHBOARD,
  "/teams/drives-to-approve/": WebPages.APPROVE_DRIVES_LIST_TEAM_DASHBOARD,
  "/teams/drives-to-approve": WebPages.APPROVE_DRIVES_SUMMARY_TEAM_DASHBOARD,
  "/teams/drives/": WebPages.DRIVES_LIST_TEAM_DASHBOARD,
  "/teams/drives": WebPages.DRIVES_SUMMARY_TEAM_DASHBOARD,
  "/teams/reports": WebPages.REPORTS_TEAM_DASHBOARD,
  "/teams/custom-rates": WebPages.CUSTOM_RATES_TEAM_DASHBOARD,
  "/teams/locations": WebPages.TEAM_LOCATIONS_TEAM_DASHBOARD,
  "/teams/subscription": WebPages.SUBSCRIPTION_TEAM_DASHBOARD,
  "/teams/settings": WebPages.SETTINGS_TEAM_DASHBOARD,
  "/user/get-started": WebPages.GET_STARTED_DRIVER_DASHBOARD,
  "/user/drives": WebPages.DRIVES_DRIVER_DASHBOARD,
  "/user/reports": WebPages.REPORTS_DRIVER_DASHBOARD,
  "/user/subscription": WebPages.SUBSCRIPTION_DRIVER_DASHBOARD,
  "/user/settings/profile": WebPages.PROFILE_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/notifications":
    WebPages.NOTIFICATIONS_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/integrations":
    WebPages.INTEGRATIONS_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/share": WebPages.SHARE_MILEIQ_DRIVER_DASHBOARD,
  "/user/settings/vehicle-and-odometer":
    WebPages.VEHICLE_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/mileage-rates":
    WebPages.MILEAGE_RATES_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/auto-classification":
    WebPages.AUTO_CLASSIFICATION_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/custom-purposes":
    WebPages.CUSTOM_PURPOSES_SETTINGS_DRIVER_DASHBOARD,
  "/user/settings/automatic-reports":
    WebPages.AUTOMATIC_REPORTS_SETTINGS_DRIVER_DASHBOARD,
  "/user/teams": WebPages.CREATE_TEAM_TEAMS_DASHBOARD,
  "/login": WebPages.LOGIN,
  "/signup": WebPages.SIGNUP,
};

export function guessPageFromPath(path) {
  const routeTelemetryMapKeys = Object.keys(ROUTE_TELEMETRY_MAP);

  if (routeTelemetryMapKeys.includes(path)) {
    return ROUTE_TELEMETRY_MAP[path];
  }

  const objectKey = routeTelemetryMapKeys.find((route) => path.includes(route));
  return objectKey ? ROUTE_TELEMETRY_MAP[objectKey] : undefined;
}

function dispatchTelemetryEvent({ currentPath, previousPath }) {
  const currentPage = guessPageFromPath(currentPath);
  const previousPage = guessPageFromPath(previousPath) || undefined;

  if (!currentPage) return;

  trackWebPageViewed({
    currentPage,
    previousPage,
  });
}

export function usePageTracking() {
  const history = useHistory();
  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    if (history) {
      // Initial page load
      if (!previousPath) {
        const currentPath = history.location.pathname;

        dispatchTelemetryEvent({
          currentPath,
          previousPath,
        });

        setPreviousPath(currentPath);
      }

      const unlisten = history.listen((location) => {
        const currentPath = location.pathname;

        if (currentPath !== previousPath) {
          dispatchTelemetryEvent({ currentPath, previousPath });
        }

        setPreviousPath(currentPath);
      });

      return () => {
        unlisten();
      };
    }
  }, [history, previousPath]);
}
