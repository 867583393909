import { useContext } from "react";

import TeamContext from "src/components/context/TeamContext";

import { useFlagsMethods } from "src/hooks/useFlags";

export function useReverseTrial() {
  const { team } = useContext(TeamContext);
  const { evalWithDetails } = useFlagsMethods();
  const subscription = team?.subscription;

  const evalFlagReverseTrial = () => {
    return evalWithDetails(
      "miq.subscription.teams.reverse-trial.all-platforms"
    );
  };

  const isReverseTrialRunning = () => {
    return subscription?.status === "trialing";
  };

  const hasTrialEnded = () => {
    if (!team) return false;
    // verify status is correct
    return (
      evalFlagReverseTrial().value &&
      ["canceled", "ended"].includes(subscription?.status)
    );
  };

  const canAccessTeamsDashboard = () => {
    return !hasTrialEnded();
  };

  return {
    evalFlagReverseTrial,
    isReverseTrialRunning,
    hasTrialEnded,
    canAccessTeamsDashboard,
  };
}
