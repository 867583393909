import React, { createContext, useContext, useMemo, useState } from "react";

import EmailReportModal from "src/components/modals/EmailReport";

import {
  getBulkReportsDownloadLink,
  getReportDownloadLink,
} from "src/services/reports";
import {
  reportDownloadFormats,
  reportSelectionTypes,
  trackDriverReportDownloaded,
  trackDriverReportEmailCompleted,
  trackDriverReportEmailStarted,
  trackDriverReportSelected,
} from "src/services/tracking";
import { downloadFileFromLink } from "src/services/utils";

import { FlashTypes } from "../elements/Flash";
import Text from "../elements/Text";
import { useAppFlash } from "./Flash";
import TeamContext from "./TeamContext";
import { UserDataContext } from "./UserContext";

const TeamReportsContext = createContext({});

const KEY = "id";

export function useTeamReports() {
  return useContext(TeamReportsContext);
}

export const TeamReportsProvider = ({ children }) => {
  const { flash } = useAppFlash();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const [selectedReports, setSelectedReports] = useState(new Map());
  const [pastSelectedReports, setPastSelectedReports] = useState(new Map());
  const [selectedDrive, setSelectedDrive] = useState(null);
  const [pastSelectedDrive, setPastSelectedDrive] = useState(null);
  const [generatingCSV, setGeneratingCSV] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [detailedReport, setDetailedReport] = useState(null);
  const [showEmailReportModal, setShowEmailReportModal] = useState(false);
  const [emailReportModalSource, setEmailReportModalSource] = useState(null);

  const trackReportDownload = ({ format, numberOfReports, source }) => {
    trackDriverReportDownloaded({
      orgGroupId: team.orgGroupId,
      orgId: team.orgId,
      subscriptionId: userData.subscriptionType,
      format,
      numberOfReports,
      source,
    });
  };

  const trackReportSelected = (selectionType) => {
    trackDriverReportSelected({
      orgGroupId: team.orgGroupId,
      orgId: team.orgId,
      subscriptionId: userData.subscriptionType,
      selectionType,
    });
  };

  const selectReport = (report) => {
    const newSelectedReports = new Map(selectedReports);
    newSelectedReports.set(report[KEY], report);

    setPastSelectedReports(selectedReports);
    setSelectedReports(newSelectedReports);
    trackReportSelected(reportSelectionTypes.INDIVIDUAL);
  };

  const isReportSelected = (report) => {
    return selectedReports.has(report[KEY]);
  };

  const areAllReportsSelected = (reports) => {
    return reports.every(isReportSelected);
  };

  const isAnyReportSelected = (reports) => {
    return reports.some(isReportSelected);
  };

  const toggleReport = (report) => {
    const newSelected = new Map(selectedReports);

    if (newSelected.has(report[KEY])) {
      newSelected.delete(report[KEY]);
    } else {
      newSelected.set(report[KEY], report);
      trackReportSelected(reportSelectionTypes.INDIVIDUAL);
    }

    setPastSelectedReports(selectedReports);
    setSelectedReports(newSelected);
  };

  const toggleReports = (reports) => {
    const newSelected = new Map(selectedReports);

    if (reports.some(isReportSelected)) {
      reports.forEach((report) => newSelected.delete(report[KEY], report));
    } else {
      reports.forEach((report) => newSelected.set(report[KEY], report));
      trackReportSelected(reportSelectionTypes.ALL);
    }

    setPastSelectedReports(selectedReports);
    setSelectedReports(newSelected);
  };

  const unselectAllReports = () => {
    setPastSelectedReports(selectedReports);
    setSelectedReports(new Map());
  };

  const getSelectedReports = () => {
    return Array.from(selectedReports.values());
  };

  const getPastSelectedReports = () => {
    return Array.from(pastSelectedReports.values());
  };

  const generateReportAsCSV = async (
    reportId,
    source,
    automaticallyOpen = true
  ) => {
    if (generatingCSV) return;

    setGeneratingCSV(true);

    try {
      const links = await getReportDownloadLink(team.id, reportId, {
        csv: true,
      });

      if (links?.csv && automaticallyOpen) {
        trackReportDownload({
          format: reportDownloadFormats.CSV,
          numberOfReports: 1,
          source,
        });

        const fileName =
          links.csv?.split?.(".csv")?.[0]?.split?.("/")?.pop?.() ||
          "MileIQ_Report";

        await downloadFileFromLink({
          fileName,
          fileExtension: "csv",
          link: links.csv,
        });
      }

      return links?.csv;
    } catch (error) {
      console.log("Failed to generate report", error);
      flash(<Text>Failed to generate a CSV report</Text>, {
        type: FlashTypes.ERROR,
      });
    } finally {
      setGeneratingCSV(false);
    }
  };

  const generateReportAsPDF = async (
    reportId,
    source,
    automaticallyOpen = true
  ) => {
    if (generatingPDF) return;

    setGeneratingPDF(true);

    try {
      const links = await getReportDownloadLink(team.id, reportId, {
        pdf: true,
      });

      if (links?.pdf && automaticallyOpen) {
        trackReportDownload({
          format: reportDownloadFormats.PDF,
          numberOfReports: 1,
          source,
        });
        window.open(links.pdf, "_blank");
      }

      return links?.pdf;
    } catch (error) {
      console.log("Failed to generate report", error);
      flash(<Text>Failed to generate a PDF report</Text>, {
        type: FlashTypes.ERROR,
      });
    } finally {
      setGeneratingPDF(false);
    }
  };

  const generateBulkReportsAsCSV = async (
    reportsIds,
    source,
    automaticallyOpen = true
  ) => {
    if (generatingCSV) return;

    setGeneratingCSV(true);

    try {
      const data = await getBulkReportsDownloadLink(team.id, reportsIds, "csv");

      if (data?.link && automaticallyOpen) {
        trackReportDownload({
          format: reportDownloadFormats.CSV,
          numberOfReports: reportsIds.length,
          source,
        });
        window.open(data.link, "_blank");
      }

      return data?.link;
    } catch (error) {
      console.log("Failed to bulk generate report", error);
      flash(<Text>Failed to generate multiple CSV reports</Text>, {
        type: FlashTypes.ERROR,
      });
    } finally {
      setGeneratingCSV(false);
    }
  };

  const generateBulkReportsAsPDF = async (
    reportsIds,
    source,
    automaticallyOpen = true
  ) => {
    if (generatingPDF) return;

    setGeneratingPDF(true);

    try {
      const data = await getBulkReportsDownloadLink(team.id, reportsIds, "pdf");

      if (data?.link && automaticallyOpen) {
        trackReportDownload({
          format: reportDownloadFormats.PDF,
          numberOfReports: reportsIds.length,
          source,
        });
        window.open(data.link, "_blank");
      }

      return data?.link;
    } catch (error) {
      console.log("Failed to bulk generate report", error);
      flash(<Text>Failed to generate multiple PDF reports</Text>, {
        type: FlashTypes.ERROR,
      });
    } finally {
      setGeneratingPDF(false);
    }
  };

  const openEmailReportModal = (source) => {
    trackDriverReportEmailStarted({
      orgGroupId: team.orgGroupId,
      orgId: team.orgId,
      source,
    });

    setEmailReportModalSource(source);
    setShowEmailReportModal(true);
  };

  const contextValues = useMemo(
    () => ({
      selectedReports,
      detailedReport,
      selectReport,
      setDetailedReport,
      isReportSelected,
      areAllReportsSelected,
      isAnyReportSelected,
      toggleReport,
      toggleReports,
      unselectAllReports,
      getSelectedReports,
      getPastSelectedReports,
      generateReportAsCSV,
      generateReportAsPDF,
      generateBulkReportsAsCSV,
      generateBulkReportsAsPDF,
      openEmailReportModal,
      setSelectedDrive,
      setPastSelectedDrive,
      pastSelectedDrive,
      generatingCSV,
      generatingPDF,
      selectedDrive,
    }),
    [
      pastSelectedDrive,
      pastSelectedReports,
      selectedReports,
      detailedReport,
      generatingCSV,
      generatingPDF,
      selectedDrive,
    ]
  );

  return (
    <TeamReportsContext.Provider value={contextValues}>
      {children}
      {showEmailReportModal && (
        <EmailReportModal
          show={showEmailReportModal}
          onClose={() => setShowEmailReportModal(false)}
          onSent={(email) => {
            trackDriverReportEmailCompleted({
              email,
              source: emailReportModalSource,
            });

            flash(
              <Text>
                Report sent to <Text bold>{email}</Text>
              </Text>
            );
          }}
          report={detailedReport || getSelectedReports()[0]}
        />
      )}
    </TeamReportsContext.Provider>
  );
};
