import "src/models/typings";

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { getRefetchParams } from "src/services/apollo-utils";
import {
  FrequentDriveEditAction,
  trackFrequentDriveEdited,
} from "src/services/tracking";

import { STOP_AUTOCLASSIFICATION } from "src/graphql/mutations";

export default AutoClassified;
/**
 *
 * @param {{ drive: Drive, isBanner: Boolean }} props
 * @returns
 */
function AutoClassified({ drive, isBanner }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [stopAutoclassifyMutFn, { loading }] = useMutation(
    STOP_AUTOCLASSIFICATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getDrivesTotals"],
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    }
  );

  const handleEditClicked = () => {
    if (drive.isAutoClassifiedWorkHours) {
      history.push(`/user/settings/auto-classification`);
    } else if (drive.isAutoClassifiedFrequentDrives) {
      setIsOpen(true);
    }
  };

  const handleStopAutoClassificationClicked = async () => {
    await stopAutoclassifyMutFn({
      variables: {
        data: {
          routeId: drive.routeId,
        },
      },
    });
    trackFrequentDriveEdited({
      action: FrequentDriveEditAction.STOP_AUTO_CLASSIFY,
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    trackFrequentDriveEdited({
      action: FrequentDriveEditAction.CANCEL,
    });
    setIsOpen(false);
  };

  const purposeLabel = drive.isBusiness ? "Business" : "Personal";

  const textBannerProps = {
    md: true,
  };
  const textNoBannerProps = {
    color: "black/70",
    lg: true,
    nowrap: true,
  };

  const textProps = isBanner ? textBannerProps : textNoBannerProps;
  return (
    <div
      className={`flex items-center justify-start ${
        isBanner
          ? "bg-lime h-[40px] -mx-[15px] -mt-[15px] px-[15px] relative"
          : ""
      }`}
    >
      <Icon
        name="auto-classified-frequent"
        className={`scale-75 ${isBanner ? "-ml-[8px]" : ""}`}
      />
      <span className="ml-[4px]">
        <Text {...textProps} className={isBanner ? "font-bold" : "font-normal"}>
          {drive.isAutoClassifiedWorkHours ? "Work Hours" : "Frequent Drive"}
        </Text>{" "}
        <Text {...textProps}>(Auto-Classified)</Text>
      </span>
      {!drive.isAutoClassifiedFrequentDrivesOverriden ? (
        <Button
          link
          className={isBanner ? "ml-auto" : "no-underline"}
          onClick={handleEditClicked}
        >
          <Text md bold color={isBanner ? "black" : "blue"}>
            Edit
          </Text>
        </Button>
      ) : null}
      {isOpen && (
        <div className="fixed w-[340px] animate-slideTopBottom z-[99999] shadow-lg flex flex-col gap-5 rounded right-3 top-[44px] bg-white p-5">
          <Text paragraph>
            This drive was auto-classified as <Text bold>{purposeLabel}</Text>.
            Do you want MileIQ to stop auto-classifying drives like this?
          </Text>
          <div className="flex items-center justify-end gap-3">
            <Button disabled={loading} secondary onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              destructive
              disabled={loading}
              loading={loading}
              onClick={handleStopAutoClassificationClicked}
            >
              Stop Auto-classify
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
