import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle, useRef } from "react";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";

const MiqModal = ({
  closable,
  onBack,
  onClose,
  loading,
  loadingText,
  loadingTextTimeout,
  children,
  className,
  closableClassName,
  contentClassName,
  backdropClassName,
  childrenAsBackground,
  passiveBackdrop, // if set to true, clicking on the backdrop won't close the modal
  onBackdropClicked,
  testId = "modal-content",
  additionalContentSize,
  topBarComponent,
  modalRef,
}) => {
  const contentRef = useRef();
  const additionalContentRef = useRef();
  const innerRef = useRef();

  useImperativeHandle(modalRef, () => innerRef.current);

  let withTopBar = false;
  if (closable || onBack) {
    withTopBar = true;
  }

  const escClose = (event) => {
    if (event.key === "Escape") {
      onClose?.();
    }
  };

  const calcContentSize = () => {
    if (contentRef.current && additionalContentRef.current) {
      const { height, y } = contentRef.current.getBoundingClientRect();
      const scrollY = innerRef?.current?.scrollTop;
      const desiredTop = y + scrollY + height;

      additionalContentRef.current.style.height = additionalContentSize + "px";
      additionalContentRef.current.style.top = desiredTop + "px";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", calcContentSize);
    window.addEventListener("keydown", escClose);

    calcContentSize();

    document.body.classList.add("overflow-hidden");

    return () => {
      window.removeEventListener("resize", calcContentSize);
      window.removeEventListener("keydown", escClose);
      document.body.classList.remove("overflow-hidden");
    };
  }, [contentRef, additionalContentRef, escClose]);

  return (
    <div
      onAnimationEnd={calcContentSize}
      ref={innerRef}
      className={`miq-modal-backdrop bg-black/30 scroll-smooth ${
        backdropClassName || ""
      }`}
      onClick={(e) => {
        // if clicked on the backdrop element itself
        // and not bubbled from some child element in the modal
        if (e.currentTarget === e.target) {
          if (typeof onBackdropClicked === "function") {
            onBackdropClicked();
          } else if (typeof onClose === "function" && !passiveBackdrop) {
            onClose();
          }
        }
      }}
    >
      {/* This is the additional content that is used to make the modal scrollable */}
      {Boolean(additionalContentSize) && (
        <div
          ref={additionalContentRef}
          className="absolute w-1 pointer-events-none"
        />
      )}

      {/* This is the actual modal content */}
      <div
        ref={contentRef}
        data-testid={testId}
        className={`miq-modal ${className || ""}`}
      >
        {loading ? (
          <div className="miq-modal-loader-wrap" style={{ height: 320 }}>
            <Loader message={loadingText} timeout={loadingTextTimeout} />
          </div>
        ) : (
          <>
            {withTopBar ? (
              <div
                className={`miq-modal-top-bar w-full flex p-[20px] pb-0 laptop:p-[10px] laptop:pb-0 ${
                  !onBack ? "absolute z-[999]" : ""
                } `}
              >
                {topBarComponent}
                {onBack ? (
                  <Button
                    ghost
                    nohover
                    icon="arrow-right"
                    iconClassName="flip"
                    onClick={onBack}
                    className="mr-auto px-0"
                  >
                    Back
                  </Button>
                ) : null}
                {closable ? (
                  <Button
                    ghost
                    icon="close"
                    onClick={onClose}
                    className={`ml-auto ${closableClassName || ""}`}
                  />
                ) : null}
              </div>
            ) : null}
            <div
              className={`${contentClassName} ${
                childrenAsBackground
                  ? "miq-modal-as-background absolute p-0 top-0 left-0 w-full"
                  : ""
              } ${
                onBack ? "pt-[15px] laptop:pt-[10px]" : ""
              } miq-modal-content-wrap`}
            >
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MiqModal;

MiqModal.propTypes = {
  closable: PropTypes.bool,
  contentClassName: PropTypes.string,
};

MiqModal.defaultProps = {
  closable: true,
  contentClassName: "",
};
