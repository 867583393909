import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";
import TextArea from "src/components/elements/Textarea";

import { TEAMS_SUBSCRIPTION_PLANS_DATA } from "src/models/team-subscription";

import { prepareTeamsOnboardingUrl } from "src/services/utils";

import {
  GET_CONCUR_REPORTS,
  GET_INTEGRATION_IDENTITY,
  LIST_INTEGRATIONS,
} from "src/graphql/queries";

import SetConcurIdentityForm from "../integrations/SetConcurIdentityForm";

export default ConcurReport;

function ConcurReport({
  onSubmit,
  submitLoading,
  reportError,
  clearError,
  onIdentitySet,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [data, setData] = useState({});
  const { team } = useContext(TeamContext);

  const { data: integrationsData, loading: loadingIntegrations } = useQuery(
    LIST_INTEGRATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );
  const {
    data: identityData,
    loading: identityLoading,
    refetch: refetchIdentity,
  } = useQuery(GET_INTEGRATION_IDENTITY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      integrationType: "concur",
    },
  });

  const [
    fetchConcurReports,
    { data: concurReportsData, loading: concurReportsLoading },
  ] = useLazyQuery(GET_CONCUR_REPORTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const concurIdentity = identityData?.integrationIdentity?.identity?.identity;
  const concurReports = concurReportsData?.getConcurReports?.reports || [];

  useEffect(() => {
    if (identityData?.integrationIdentity?.identity?.identity) {
      fetchConcurReports({
        variables: {
          concurUsername: concurIdentity,
        },
      }).then((res) => {
        const reports = res?.data?.getConcurReports?.reports || [];
        let report = reports?.[0] || { id: null, name: "No reports available" };

        handleDataChange({ report });
      });
    }
  }, [identityData]);

  const handleSetIdentitySuccess = () => {
    refetchIdentity();
    onIdentitySet();
  };

  const handleDataChange = (newValues) => {
    reportError && clearError();
    setData((prevData) => ({ ...prevData, ...newValues }));
  };

  const handleSelectConcurReport = (report) => {
    setDropdownOpen(false);
    handleDataChange({ report });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      reportName: data.reportName,
      reportKey: data.report.id,
      expenseService: "concur",
      notes: data.notes,
      concurUsername: concurIdentity,
      shouldReport: true,
    });
  };

  const isStandalone = !team;
  const isTeamConcurEnabled =
    integrationsData?.integrations?.integrations?.some(
      (i) => i.type === "concur"
    );
  const isIdentitySet = Boolean(concurIdentity);

  if (loadingIntegrations || identityLoading || concurReportsLoading) {
    return (
      <div className="my-32">
        <Loader />
      </div>
    );
  }

  if (isStandalone) {
    return (
      <div>
        <h4 className="text-center">Concur Integration is a Teams feature</h4>
        <Text center paragraph className="mt-2">
          Concur Integration is a feature for our Teams and Teams Pro plans.
          This feature allows you to submit your MileIQ reports directly to
          Concur. If this is something you'd like, you'll need to create a
          MileIQ Team. If there's a different person in your company who needs
          to create and manage your Team, please share this{" "}
          <a
            className="underline"
            href="https://dashboard.mileiq.com/documents/TeamsOneSheeter.pdf"
            target="_blank"
          >
            overview doc
          </a>{" "}
          with them so they can get the ball rolling.
        </Text>
        <Button
          lg
          onClick={() => (window.location.href = prepareTeamsOnboardingUrl())}
          className="w-full mt-7"
        >
          Create a team
        </Button>
      </div>
    );
  }

  if (!isTeamConcurEnabled) {
    return (
      <div>
        <h5 className="text-center">
          Your team has not enabled Concur integration
        </h5>
        <Text center paragraph className="mt-2">
          Concur integration allows you to submit your MileIQ reports directly
          to Concur. If this is something you'd like, please let your admin know
          at{" "}
          <a
            className="underline font-medium"
            href={`mailto:${team?.owner?.email}`}
          >
            {team?.owner?.email}
          </a>
        </Text>
      </div>
    );
  }

  if (!isIdentitySet) {
    return <SetConcurIdentityForm onSuccess={handleSetIdentitySuccess} />;
  }

  const isFormValid = data?.reportName && data?.report?.id;

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-7">
      <Input
        autoFocus
        label="Report name*"
        placeholder="Report name required"
        value={data.reportName}
        onChange={(e) => handleDataChange({ reportName: e.target.value })}
      />
      <Dropdown
        hasValue
        open={dropdownOpen}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        triggerClassName="border border-border-1 bg-white"
        renderTrigger={() => (
          <div className="w-full flex justify-between gap-3">
            <Text semibold className="miq-dropdown-label truncate">
              {data.report?.name}
            </Text>
            <Icon name="caret" color="black" />
          </div>
        )}
        label={"Report 1"}
        contentClassName="w-full border border-border-1 top-[50px]"
      >
        <div className="max-h-[250px] overflow-y-auto">
          <ul className="flex flex-col gap-2">
            {concurReports.map((report) => (
              <li
                onClick={() => handleSelectConcurReport(report)}
                className={`p-3 truncate rounded-10 cursor-pointer hover:bg-beige ${
                  concurReports?.length > 4 ? "mr-3" : ""
                }`}
                key={report.id}
              >
                {report.name}
              </li>
            ))}
          </ul>
        </div>
      </Dropdown>
      <TextArea
        height={220}
        placeholder="Notes"
        onChange={(e) => handleDataChange({ notes: e.target.value })}
      />
      {reportError && (
        <Text
          md
          color="red"
          regular
          paragraph
          className="w-full text-left mt-2"
        >
          {reportError}
        </Text>
      )}
      <Button
        loading={submitLoading}
        type="submit"
        lg
        disabled={team?.isExpired || !isFormValid}
        tooltip={
          team?.isExpired && {
            text: `Want to submit reports to Concur? Ask your admin to resubscribe to ${
              TEAMS_SUBSCRIPTION_PLANS_DATA.labels[team?.subscription?.plan]
            }.`,
            textProps: {
              nowrap: false,
            },
            className: "w-[207px]",
            offset: { x: 100, y: -55 },
            alignX: "center",
            timeout: 500,
          }
        }
      >
        {submitLoading ? "Loading" : "Create report"}
      </Button>
    </form>
  );
}
