import React, { useContext, useRef } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as ADD_VEHICLE_MODAL_ID } from "src/components/modals/AddVehicle";

import { VehicleTypeIcon } from "src/components/pages/DriverDashboard/settings/vehicle-and-odometer/VehiclesList";

import { getVehicleDisplayName } from "src/services/utils";

export default SelectVehicle;

const VEHICLES_LIST = "VEHICLES_LIST";
function SelectVehicle({
  onSelect,
  onStartAddDetails,
  onAddDetailsCompleted,
  selected,
}) {
  const { userData } = useContext(UserDataContext);
  const addVehicleModal = useElement(ADD_VEHICLE_MODAL_ID, {
    props: {
      onSubmit: (newVehicleId) => {
        addVehicleModal.deactivate(); // close modal
        onSelect(newVehicleId); // select created vehicle
      },
      onStartAddDetails,
      onAddDetailsCompleted,
      onClose: () => addVehicleModal.deactivate(),
    },
  });

  const triggerRef = useRef();

  const vehiclesListElement = useElement(VEHICLES_LIST, {
    props: {
      onAddClicked: () => {
        vehiclesListElement.deactivate();
        addVehicleModal.activate();
      },
      onSelectVehicle: (v) => {
        vehiclesListElement.deactivate();
        onSelect(v);
      },
      onClickOutside: () => {
        vehiclesListElement.deactivate();
      },
    },
  });

  const options = userData.vehicles.map((v) => ({
    id: v.id,
    type: v.type,
    label: getVehicleDisplayName(v),
    isActive: v.isActive,
  }));

  const handlePickerClicked = () => {
    vehiclesListElement.activate({
      props: {
        trigger: triggerRef.current,
        vehicles: options,
      },
    });
  };

  return (
    <div className="w-full">
      <Text semibold className="inline-block mb-[10px]">
        Vehicle
      </Text>
      <button
        ref={triggerRef}
        className={`w-full rounded-14-12 ${
          vehiclesListElement.open
            ? "border-blue-medium border-2 p-0"
            : "p-[1px] border border-border-1 hover:border-black/30"
        } h-40-35`}
        onClick={handlePickerClicked}
      >
        <span className="flex items-center px-3">
          <Icon name={VehicleTypeIcon[selected.type]} color="black" />
          <Text className="flex-grow ml-3">
            {options.find((opt) => opt.id == selected.id)?.label ||
              "Select vehicle"}
          </Text>
          <Icon name="caret" color="black" className="ml-auto" />
        </span>
      </button>
    </div>
  );
}

registerElement(VEHICLES_LIST, SelectVehicleOptionsList);
function SelectVehicleOptionsList({
  trigger,
  vehicles,
  onAddClicked,
  onSelectVehicle,
  onClickOutside,
}) {
  const onSelect = (v) => {
    onSelectVehicle(v);
  };

  return (
    <Pivoted
      pivot={trigger}
      offset={{ y: 5 }}
      onClickOutside={onClickOutside}
      style={{ zIndex: 40000 }}
      matchPivotWidth
      adjustPosition={(top, left, elRect) => {
        let t = top;
        let l = left;
        const maxTop =
          document.documentElement.clientHeight - elRect.height - 10;
        if (t > maxTop) {
          t = maxTop;
        }
        return { top: t, left: l };
      }}
    >
      <div className="w-full max-h-[300px] overflow-auto p-2 bg-white rounded border border-border-1 shadow-md shadow-[#0003]">
        <ul className="m-0 p-0">
          {vehicles
            .filter((v) => v.isActive)
            .map((v) => {
              return (
                <li key={v.id} className="w-full p-2 rounded-8 hover:bg-beige">
                  <button
                    className="w-full flex items-center"
                    onClick={() => onSelect(v)}
                  >
                    <Icon name={VehicleTypeIcon[v.type]} />
                    <Text md className="ml-4">
                      {v.label}
                    </Text>
                  </button>
                </li>
              );
            })}
          <li key="add_new" className="w-full p-2 rounded-8 hover:bg-beige">
            <button className="w-full flex items-center" onClick={onAddClicked}>
              <Icon name="add-circle" className="w-[24px]" />
              <Text md semibold className="ml-4">
                Add a new vehicle
              </Text>
            </button>
          </li>
        </ul>
      </div>
    </Pivoted>
  );
}
