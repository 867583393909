import React, { useContext } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { COUNTRIES } from "src/services/utils";

import { useCustomRates } from "../context/CustomRatesContext";
import TeamContext from "../context/TeamContext";
import QuickTipsLink from "../elements/QuickTipsLink";

export default QuickTips;

function QuickTips({
  showCustomRate,
  showUserQuickTips,
  showLocationsQuickTips,
}) {
  const {
    team: { country },
  } = useContext(TeamContext);
  const { defaultRates } = useCustomRates();
  return (
    <div className="quick-tips">
      <h4 className="mb-4">Quick Tips</h4>
      {showCustomRate && country === COUNTRIES.US && (
        <div className="tip">
          <div className="flex flex-col">
            <div>
              <Text>
                {`The IRS sets a standard mileage rate for the US. The current rate
                  for ${
                    defaultRates?.data?.year || new Date().getFullYear()
                  } is listed below:`}
              </Text>
            </div>
            <div className="flex justify-between mt-3">
              <Text bold>Current rate</Text>
              <Text bold>${defaultRates?.value || "0.000"}</Text>
            </div>
          </div>
        </div>
      )}
      {showUserQuickTips && (
        <>
          <div className="tip">
            <Icon name="book-open" color="black" />
            <Text>
              Open this{" "}
              <QuickTipsLink url="https://dashboard.mileiq.com/documents/TeamsAdminGuide.pdf">
                guide
              </QuickTipsLink>{" "}
              for more FAQs and best practices about setting your team up
            </Text>
          </div>
          <div className="tip">
            <Icon name="book-open" color="black" />
            <Text>
              Share this{" "}
              <QuickTipsLink url="https://dashboard.mileiq.com/documents/UserGuide.pdf">
                guide
              </QuickTipsLink>{" "}
              with your drivers for instructions on joining your Team and
              setting up the app
            </Text>
          </div>
        </>
      )}
      {showLocationsQuickTips && (
        <div className="tip">
          <Icon name="book-open" color="black" />
          <Text>
            Learn more about{" "}
            <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/16528854850324">
              Team Locations
            </QuickTipsLink>
          </Text>
        </div>
      )}
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text>
          Here's our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/4407410810132">
            Quick Guide
          </QuickTipsLink>{" "}
          for how to add and remove users
        </Text>
      </div>
      <div className="tip">
        <Icon name="video" color="black" />
        <Text>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/4407480125716">
            Employee Onboarding
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text>
          Are you an admin and a driver? Here’s{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/4408142328852">
            how to add yourself as a driver
          </QuickTipsLink>
        </Text>
      </div>
    </div>
  );
}
