import React from "react";

import Icon from "src/components/elements/Icon";

export const AlertVariants = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const Alert = ({ variant, show, children, className, ...props }) => {
  let typeClass;
  let typeIcon;

  switch (variant) {
    case AlertVariants.ERROR:
      typeClass = "bg-red-pastel";
      typeIcon = <Icon name="close-in-circle" color="red" className="mr-2" />;
      break;
    case AlertVariants.SUCCESS:
    default:
      typeClass = "bg-green-pastel";
      typeIcon = <Icon name="checked-circle" color="green" className="mr-2" />;
      break;
  }

  return (
    <div
      {...props}
      className={`flex flex-row p-2 rounded items-center px-4 text-black/70 ${typeClass} transition-opacity ease-in-out delay-1000 ${
        !show && "hidden"
      } ${className}`}
    >
      {typeIcon}
      {children}
    </div>
  );
};
