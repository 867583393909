import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { TEAMS_SUBSCRIPTION_PLANS_DATA } from "src/models/team-subscription";

import { NETWORK_STATES } from "src/services/http";
import {
  ModalAbandonMethods,
  trackTeamsUpdatePaymentAbandoned,
  trackTeamsUpdatePaymentCompleted,
  trackTeamsUpdatePaymentFailed,
  trackTeamsUpdatePaymentStarted,
} from "src/services/tracking";
import { timeout } from "src/services/utils";

import AttentionCar2 from "public/assets/img/illustrations/attention-car-2.png";

export const DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID =
  "DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID";

registerElement(
  DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
  DunningSubscriptionRecoverPayment
);

function DunningSubscriptionRecoverPayment({ onClose, source }) {
  const {
    executeCreatePaymentIntent,
    paymentIntent,
    createPaymentIntentNetworkState,
    stripePromise,
  } = useTeamDunning();

  const { team } = useContext(TeamContext);

  const trackingParams = {
    orgId: team?.orgId,
    orgGroupId: team?.orgGroupId,
    subPlan: team?.subscription?.plan,
    subType: team?.subscription?.type,
    daysUntilGracePeriodEnds: team?.daysUntilGracePeriodEnds,
    teamsDriverCount: team?.subscription?.numberOfSeats,
    subscriptionExpired: team?.isExpired,
  };

  useEffect(() => {
    executeCreatePaymentIntent();

    trackTeamsUpdatePaymentStarted({
      ...trackingParams,
      teamsUpdatePaymentSource: source,
    });
  }, []);

  const handleClose = (modalAbandonMethod) => {
    trackTeamsUpdatePaymentAbandoned({
      ...trackingParams,
      modalAbandonMethod,
    });

    onClose();
  };

  const isLoading = [NETWORK_STATES.LOADING, NETWORK_STATES.IDLE].includes(
    createPaymentIntentNetworkState
  );

  const hasError = createPaymentIntentNetworkState === NETWORK_STATES.ERROR;

  const teamPlanLabel = team?.subscription?.plan
    ? TEAMS_SUBSCRIPTION_PLANS_DATA.labels[team.subscription.plan]
    : "Teams";

  const descriptionErrorCopy = (
    <p>
      Something went wrong while creating a secure payment environment. Please
      try again, or{" "}
      <a
        href="https://support.mileiq.com/"
        target="_blank"
        rel="noopener"
        className="text-blue"
      >
        contact support
      </a>
      .
    </p>
  );
  const currency = paymentIntent?.currency === "EUR" ? "£" : "$";
  const amount = paymentIntent?.amount?.toFixed?.(2);

  const titleCopy = team?.isExpired
    ? "Enter payment information"
    : "Update payment";
  const descriptionLoadedCopy = team?.isExpired ? (
    <p>
      Please enter an up-to-date payment method to resubscribe to{" "}
      {teamPlanLabel} with a total payment of{" "}
      <span className="font-medium">
        {currency}
        {amount}
      </span>{" "}
      today.
    </p>
  ) : (
    <p>
      Please enter an up-to-date payment information to retry your{" "}
      {teamPlanLabel} payment of{" "}
      <span className="font-medium">
        {currency}
        {amount}
      </span>
      .
    </p>
  );

  return (
    <Modal
      closable={false}
      className={hasError ? "w-[440px]" : "w-[606px]"}
      contentClassName={
        isLoading ? "flex min-h-[450px] items-center justify-center" : undefined
      }
      onClose={() => handleClose(ModalAbandonMethods.MODAL_DISMISS)}
      passiveBackdrop
    >
      {isLoading ? (
        <Loader
          message="Processing... This may take a few minutes."
          timeout={10000}
        />
      ) : (
        <div className="flex flex-col">
          <div>
            <h5>{hasError ? "Something went wrong" : titleCopy}</h5>
            <div className="mt-2.5">
              {hasError ? descriptionErrorCopy : descriptionLoadedCopy}
            </div>
          </div>
          <div className="mt-[30px]">
            {createPaymentIntentNetworkState === NETWORK_STATES.LOADED && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: paymentIntent?.payment_intent_secret,
                }}
              >
                <PaymentForm
                  onClose={onClose}
                  onCancel={() => handleClose(ModalAbandonMethods.CLOSE_CTA)}
                  teamPlanLabel={teamPlanLabel}
                />
              </Elements>
            )}
            {hasError && (
              <>
                <div className="w-full flex justify-center p-2.5">
                  <img
                    src={AttentionCar2}
                    alt="Illustration of a sad car"
                    className="h-[138px]"
                  />
                </div>
                <div className="flex items-center justify-end gap-2.5 mt-[30px]">
                  <Button
                    secondary
                    className="font-medium"
                    onClick={() => handleClose(ModalAbandonMethods.CLOSE_CTA)}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="font-medium"
                    onClick={executeCreatePaymentIntent}
                  >
                    Try again
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

function PaymentForm({ onClose, onCancel, teamPlanLabel }) {
  const [isLoading, setLoading] = useState(false);
  const { team, refreshTeam } = useContext(TeamContext);
  const { flash } = useAppFlash();
  const stripe = useStripe();
  const elements = useElements();
  const { paymentIntent } = useTeamDunning();

  const submitButtonCopy = team?.isExpired
    ? "Resubscribe"
    : "Save and retry payment";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error, paymentIntent: confirmedPaymentIntent } =
      await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          save_payment_method: true,
          return_url: window.location.href,
        },
      });

    const trackingParams = {
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
      subPlan: team?.subscription?.plan,
      subType: team?.subscription?.type,
      daysUntilGracePeriodEnds: team?.daysUntilGracePeriodEnds,
      teamsDriverCount: team?.subscription?.numberOfSeats,
      subscriptionExpired: team?.isExpired,
      paymentAmountDue: paymentIntent?.amount,
    };

    if (error || confirmedPaymentIntent?.status !== "succeeded") {
      setLoading(false);

      const errorMessage = error?.message || "An unexpected error occurred.";

      trackTeamsUpdatePaymentFailed({
        ...trackingParams,
        teamsUpdatePaymentFailReason: errorMessage,
      });

      flash(
        <Text custom className="text-15 leading-[21px]">
          {errorMessage}
        </Text>,
        {
          type: FlashTypes.ERROR,
        }
      );

      return;
    }

    trackTeamsUpdatePaymentCompleted(trackingParams);

    let updatedTeam = await refreshTeam();

    while (updatedTeam?.isPaymentFailing) {
      await timeout(2000);
      updatedTeam = await refreshTeam();
    }

    setLoading(false);

    flash(
      <Text custom className="text-15 leading-[21px]">
        Your {teamPlanLabel} payment was successful. You're all set!
      </Text>,
      {
        type: FlashTypes.BLACK,
      }
    );

    onClose();
  };

  return (
    <form className="flex flex-col gap-[30px]" onSubmit={handleSubmit}>
      <PaymentElement
        className="p-5 rounded-[14px] bg-[#f9fafc] min-h-[200px]"
        options={{
          business: {
            name: "MileIQ",
          },
        }}
      />
      <div className="flex items-center justify-end gap-2.5">
        <Button
          className="font-medium min-w-[120px]"
          disabled={isLoading}
          secondary
          onClick={onCancel}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={`font-medium ${
            team?.isExpired ? "min-w-[119px]" : "min-w-[176px]"
          }`}
          type="submit"
        >
          {isLoading ? <Icon name="spinner" /> : submitButtonCopy}
        </Button>
      </div>
    </form>
  );
}
