import React, { useRef } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import AutoClassified from "./AutoClassified";

const DriveType = ({ text, iconName }) => {
  const iconRef = useRef();
  return (
    <div className="flex items-center gap-1">
      <Icon
        color="black/70"
        iconRef={iconRef}
        className="scale-[0.8] laptop:scale-[0.65] w-[25px]"
        name={iconName}
      />
      <Text color="black/70" lg nowrap>
        {text}
      </Text>
    </div>
  );
};

const DriveTypes = ({ drive }) => {
  const { miqDashMapDashboardRoutesWeb } = useFlags();
  return (
    <div className="flex flex-col gap-1 -mt-[14px] -mb-[8px]">
      {drive.isAutoClassified && miqDashMapDashboardRoutesWeb && (
        <AutoClassified drive={drive} />
      )}
      {drive.isJoinedDrive ? (
        <DriveType text="Joined drive" iconName="joined-drive" />
      ) : (
        <>
          {miqDashMapDashboardRoutesWeb && (
            <>
              {drive.isRoundTrip && (
                <DriveType text="Round trip" iconName="round-trip" />
              )}
              {drive.isRoundTripStop && (
                <DriveType
                  text="Round trip with a stop"
                  iconName="round-trip-stop"
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default DriveTypes;
