import { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { useTeamLocations } from "src/components/context/TeamLocationsContext";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { NETWORK_STATES } from "src/services/http";

export const EDIT_TEAM_LOCATION_MODAL_ID = "EDIT_TEAM_LOCATION_MODAL_ID";

registerElement(EDIT_TEAM_LOCATION_MODAL_ID, EditTeamLocation);

function EditTeamLocation({ onClose, selectedTeamLocation }) {
  const {
    editTeamLocation,
    editTeamLocationNetworkState,
    editTeamLocationError,
    resetTeamLocationErrorStates,
  } = useTeamLocations();
  const [locationName, setLocationName] = useState(selectedTeamLocation?.name);

  const isEditingTeamLocation =
    editTeamLocationNetworkState === NETWORK_STATES.LOADING;
  const errorField = editTeamLocationError?.field;
  const errorMessage = editTeamLocationError?.message;

  const handleSetLocationName = (e) => {
    setLocationName(e.target.value);
    resetTeamLocationErrorStates();
  };

  const handleSubmitLocation = async (e) => {
    e?.preventDefault();

    if (isEditingTeamLocation) return;

    const success = await editTeamLocation(selectedTeamLocation?.id, {
      name: locationName,
    });

    if (success) {
      onClose();
    }
  };

  const isLocationNameValid = locationName?.trim?.().length > 0;

  return (
    <Modal
      show
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      contentClassName="pt-[30px]"
      passiveBackdrop
    >
      <h5>Rename Team Location</h5>
      <Text paragraph className="text-15 mt-[10px]">
        This change will be reflected in future reports.
      </Text>
      <form className="mt-[30px] flex flex-col" onSubmit={handleSubmitLocation}>
        <div className="flex flex-col gap-2">
          <Text className="text-15" semibold>
            Address
          </Text>
          <Text className="text-15 leading-[21px]">
            {selectedTeamLocation?.address}
          </Text>
        </div>
        <div className="flex flex-col gap-[10px] mt-5">
          <Text className="text-15" semibold>
            Location name
          </Text>
          <Input
            value={locationName}
            onChange={handleSetLocationName}
            name="locationName"
            placeholder="Name this location"
            valid={errorField !== "name"}
            autoComplete="off"
          />
          {errorField === "name" && errorMessage && (
            <Text color="red" className="text-13">
              {errorMessage}
            </Text>
          )}
        </div>
        <div className="flex gap-[10px] justify-end mt-[30px]">
          <Button
            type="button"
            secondary
            onClick={onClose}
            className="w-[110px] font-medium text-15"
          >
            Cancel
          </Button>
          <Button
            loading={isEditingTeamLocation}
            disabled={
              !locationName ||
              locationName === selectedTeamLocation?.name ||
              !!errorField ||
              !isLocationNameValid
            }
            type="submit"
            primary
            className="min-w-[110px] font-medium text-15"
          >
            Rename
          </Button>
        </div>
      </form>
    </Modal>
  );
}
