import "src/models/typings";

import React, { useContext, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Checkbox from "src/components/elements/Checkbox";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import Purpose from "src/models/purpose";

export default DrivesFiltersModal;

export const ELEMENT_ID = "DRIVES_FILTERS_MODAL";
registerElement(ELEMENT_ID, DrivesFiltersModal);

export const DRIVES_FILTERS = {
  FREQUENT_DRIVES: "FREQUENT_DRIVES",
  WORK_HOURS: "WORK_HOURS",
  MANUALLY_CLASSIFIED: "MANUALLY_CLASSIFIED",
  WITHOUT_NOTES: "WITHOUT_NOTES",
  VEHICLE_UNSPECIFIED: "VEHICLE_UNSPECIFIED",
};

function DrivesFiltersModal({ onClose, selectedFilters, onSubmit }) {
  const [otherSelected, setOtherSelected] = useState([
    ...(selectedFilters?.other || []),
  ]);
  const [purposesSelected, setPurposesSelected] = useState([
    ...(selectedFilters?.purposes || []),
  ]);
  const [vehiclesSelected, setVehiclesSelected] = useState([
    ...(selectedFilters?.vehicles || []),
  ]);

  const handleUpdateOthers = (isSelected, filterId) => {
    setOtherSelected((selected) => {
      if (isSelected) {
        return [...selected, filterId];
      } else {
        return selected.filter((fid) => fid !== filterId);
      }
    });
  };
  const handleUpdatePurposes = (isSelected, filterId) => {
    setPurposesSelected((selected) => {
      if (isSelected) {
        return [...selected, filterId];
      } else {
        return selected.filter((fid) => fid !== filterId);
      }
    });
  };
  const handleUpdateVehicles = (isSelected, filterId) => {
    if (isSelected) {
      if (filterId === DRIVES_FILTERS.VEHICLE_UNSPECIFIED) {
        setVehiclesSelected([filterId]);
        return;
      } else {
        setVehiclesSelected((selected) => {
          return selected
            .filter((fid) => fid !== DRIVES_FILTERS.VEHICLE_UNSPECIFIED)
            .concat(filterId);
        });
        return;
      }
    }

    setVehiclesSelected((selected) => {
      if (isSelected) {
        return [...selected, filterId];
      } else {
        return selected.filter((fid) => fid !== filterId);
      }
    });
  };

  const handleClearAll = () => {
    setOtherSelected([]);
    setPurposesSelected([]);
    setVehiclesSelected([]);
  };
  const handleSubmit = () => {
    onSubmit({
      other: otherSelected,
      purposes: purposesSelected,
      vehicles: vehiclesSelected,
    });
  };

  const hasAnyFilter =
    otherSelected.length > 0 ||
    purposesSelected.length > 0 ||
    vehiclesSelected.length > 0;

  return (
    <Modal
      testId="drives-filters-modal"
      closable
      show
      onClose={onClose}
      className="drives-filters-modal w-[436px] max-h-[760px]"
    >
      <>
        <div className="flex flex-col gap-8 max-h-[600px] overflow-auto mt-6">
          <OtherFilters
            selected={otherSelected}
            onUpdate={handleUpdateOthers}
          />
          <PurposeFilters
            selected={purposesSelected}
            onUpdate={handleUpdatePurposes}
          />
          <VehiclesFilters
            selected={vehiclesSelected}
            onUpdate={handleUpdateVehicles}
          />
        </div>
        <div className="flex justify-between items-center mt-5 pt-5 pb-2 -mx-[30px] laptop:-mx-[20px] px-7 border-t border-border-1">
          <Button
            secondaryBordered
            onClick={handleClearAll}
            disabled={!hasAnyFilter}
          >
            Clear all
          </Button>
          <Button onClick={handleSubmit}>Done</Button>
        </div>
      </>
    </Modal>
  );
}

function FilterOption({ title, subtitle, selected, onChange }) {
  let label = <Text>{title}</Text>;
  if (subtitle) {
    label = (
      <span className="flex flex-col">
        {label}
        <Text color="black/50">{subtitle}</Text>
      </span>
    );
  }
  return <Checkbox label={label} checked={selected} onChange={onChange} />;
}

function OtherFilters({ selected, onUpdate }) {
  return (
    <div>
      <h5 className="mb-4 sticky top-0 bg-white">Filters</h5>
      <div className="flex gap-4">
        <div className="w-1/2 flex flex-col gap-y-4">
          <FilterOption
            title="Frequent Drives"
            subtitle="(Auto-Classified)"
            selected={selected.includes(DRIVES_FILTERS.FREQUENT_DRIVES)}
            onChange={(isChecked) =>
              onUpdate(isChecked, DRIVES_FILTERS.FREQUENT_DRIVES)
            }
          />
          <FilterOption
            title="Work Hours"
            subtitle="(Auto-Classified)"
            selected={selected.includes(DRIVES_FILTERS.WORK_HOURS)}
            onChange={(isChecked) =>
              onUpdate(isChecked, DRIVES_FILTERS.WORK_HOURS)
            }
          />
        </div>
        <div className="w-1/2 flex flex-col gap-y-4">
          <FilterOption
            title="Manually classified"
            selected={selected.includes(DRIVES_FILTERS.MANUALLY_CLASSIFIED)}
            onChange={(isChecked) =>
              onUpdate(isChecked, DRIVES_FILTERS.MANUALLY_CLASSIFIED)
            }
          />
          <FilterOption
            title="Without notes"
            selected={selected.includes(DRIVES_FILTERS.WITHOUT_NOTES)}
            onChange={(isChecked) =>
              onUpdate(isChecked, DRIVES_FILTERS.WITHOUT_NOTES)
            }
          />
        </div>
      </div>
    </div>
  );
}

function PurposeFilters({ selected, onUpdate }) {
  const { userData } = useContext(UserDataContext);

  /** @type {[Purpose]} */
  const business = [];
  /** @type {[Purpose]} */
  const personal = [];

  userData.purposes.forEach((purpData) => {
    const p = new Purpose(purpData);
    if (p.isBusiness) business.push(p);
    if (p.isPersonal) personal.push(p);
  });

  return (
    <div>
      <h5 className="mb-4 sticky top-0 bg-white">Purposes</h5>
      <div className="flex gap-4">
        <div className="w-1/2 flex flex-col gap-y-4">
          <span className="flex items-center">
            <Icon
              name="suitcase"
              className="-ml-[2px] [&_path]:fill-black/70"
            />
            <Text md className="ml-2" color="black/70">
              Business
            </Text>
          </span>
          {business.map((bp) => {
            return (
              <FilterOption
                key={bp.id}
                title={bp.label}
                selected={selected.includes(bp.id)}
                onChange={(isChecked) => onUpdate(isChecked, bp.id)}
              />
            );
          })}
        </div>
        <div className="w-1/2 flex flex-col gap-y-4">
          <span className="flex items-center">
            <Icon name="home" className="-ml-[2px] [&_path]:fill-black/70" />
            <Text md className="ml-2" color="black/70">
              Personal
            </Text>
          </span>
          {personal.map((pp) => {
            return (
              <FilterOption
                key={pp.id}
                title={pp.label}
                selected={selected.includes(pp.id)}
                onChange={(isChecked) => onUpdate(isChecked, pp.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
function VehiclesFilters({ selected, onUpdate }) {
  const { userData } = useContext(UserDataContext);

  return (
    <div>
      <h5 className="mb-4 sticky top-0 bg-white">Vehicles</h5>
      <div className="grid grid-cols-2 gap-4">
        {userData.vehicles.map((v) => {
          return (
            <FilterOption
              key={v.id}
              title={v.name || v.model || v.make}
              selected={selected.includes(v.id)}
              onChange={(isChecked) => onUpdate(isChecked, v.id)}
            />
          );
        })}
        <FilterOption
          title="Unspecified"
          selected={selected.includes(DRIVES_FILTERS.VEHICLE_UNSPECIFIED)}
          onChange={(isChecked) =>
            onUpdate(isChecked, DRIVES_FILTERS.VEHICLE_UNSPECIFIED)
          }
        />
      </div>
    </div>
  );
}
