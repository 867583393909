import "src/models/typings";

import { isEqual } from "date-fns";
import React, { useContext, useState } from "react";

import SettingsContext from "src/components/context/SettingsContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";

import EffectiveDatePicker from "src/components/blocks/custom-rates/EffectiveDatePicker";
import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import { COUNTRIES, COUNTRIES_DATA } from "src/services/utils";

export default RatesFormUS;

const pref = `${COUNTRIES_DATA[COUNTRIES.US].currency.sign}/mi`;

function RatesFormUS({
  rates: defaultRates,
  compareRates,
  isRevertingTo,
  onSave,
  onCancel,
  disabled,
  loading,
}) {
  /** @type {{team: Team}} */
  const { team } = useContext(TeamContext);
  const ctxData = useContext(SettingsContext);

  const initialRates = defaultRates || team.cloneRatesForEdit();
  const ratesToCompare = compareRates || initialRates;

  /** @type {[RatesUS, React.Dispatch<React.SetStateAction<RatesUS>]} */
  const [rates, setRates] = useState(initialRates);
  const [rateEmpty, setRateEmpty] = useState(false);

  if (!team?.id) return;

  const updateRates = (newRates) => {
    setRates(newRates);
    ctxData.unsavedRates = newRates;
    setRateEmpty(false);
  };

  const handleChange = (v) => {
    const newRates = rates.clone();
    newRates.value = v;
    updateRates(newRates);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(rates);
  };

  const handleCancelClicked = () => {
    ctxData.unsavedRates = null;
    setRates(team.cloneRatesForEdit());
    onCancel();
  };

  const handleEffectiveDateChange = (date) => {
    const newRates = rates.clone();
    newRates.effectiveDate = date;
    updateRates(newRates);
  };

  const handleClearInput = () => {
    setRateEmpty(true);
    ctxData.unsavedRates = null;
  };

  const isDifferentDate = !isEqual(
    rates.effectiveDate,
    ratesToCompare.effectiveDate
  );
  const isValidValue = rates.value >= 0 && rates.value !== ratesToCompare.value;

  const canSaveRate = !rateEmpty && (isValidValue || isDifferentDate);

  const isDisabled = disabled || loading;

  return (
    <form className="w-full mt-8" onSubmit={handleSubmit}>
      <div className="flex gap-5">
        <RatesInput
          prefix={pref}
          rate={rates.value}
          disabled={isRevertingTo || isDisabled}
          onChange={handleChange}
          placeholder={initialRates.value}
          onClear={handleClearInput}
        />
        <div className="w-[250px]">
          <EffectiveDatePicker
            alignment={{ offset: { y: 5 } }}
            className="ml-5"
            isOpen={false}
            disabled={isDisabled}
            defaultDate={rates.effectiveDate}
            onSelect={handleEffectiveDateChange}
          />
        </div>
      </div>
      <div className="flex mt-8">
        <Button
          type="submit"
          className="mr-2"
          disabled={isDisabled || (!canSaveRate && !isRevertingTo)}
          loading={loading}
        >
          Save
        </Button>
        <Button
          ghost
          type="button"
          disabled={isDisabled}
          onClick={handleCancelClicked}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
