import React, { useEffect, useRef, useState } from "react";

import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Input from "src/components/elements/Input";

export default SearchInput;

function SearchInput({
  className,
  inputClassName,
  value = "",
  onChange,
  onClear,
  autoFocus = true,
  ...rest
}) {
  const [val, setVal] = useState(value);
  const inpRef = useRef();

  const updateVal = (val) => {
    setVal(val);
    if (typeof onChange === "function") onChange(val);
  };

  const handleChange = (e) => {
    let val = e.target.value || "";
    updateVal(val);
  };

  const handleClear = (e) => {
    // hack: when used inside the Dropdown
    // we need to stop propagation
    // to not trigger onClickOutside handler,
    // because it won't work as expected
    // since the button will be immediatily removed after click
    e.stopPropagation();
    updateVal("");
    if (autoFocus) {
      inpRef.current.focus();
    }

    if (typeof onClear === "function") onClear();
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  const classes = `miq-icon-input-wrap ${className || ""}`;
  const inpClasses = `miq-icon-input ${inputClassName || ""}`;

  const showClear = val?.length > 0;
  return (
    <div className={classes}>
      <Icon
        name="search-magnifier"
        className="left-icon z-[1] pointer-events-none"
      />
      <Input
        ref={inpRef}
        className={inpClasses}
        {...rest}
        onChange={handleChange}
        value={val}
      />
      {showClear && (
        <IconButton
          className="right-icon"
          onClick={handleClear}
          name="close-in-circle-20x20"
        />
      )}
    </div>
  );
}
