import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import ImgCarWithDog from "public/assets/img/car-with-dog-2.svg";

export default ConcurIdentitySuccess;

export const ELEMENT_ID = "SET_CONCUR_IDENTITY_SUCCESS_MODAL";
registerElement(ELEMENT_ID, ConcurIdentitySuccess);

function ConcurIdentitySuccess() {
  return (
    <Modal closable={false} className="w-[450px]">
      <div className="flex flex-col items-center pt-8 pb-28 gap-14">
        <h4>Concur Integration Enabled</h4>
        <ImgCarWithDog />
      </div>
    </Modal>
  );
}
