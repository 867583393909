import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import useMapUtilities from "./useMapUtilities";

const propTypes = {
  id: PropTypes.string,
  startLocation: PropTypes.object,
  endLocation: PropTypes.object,
  stopLocation: PropTypes.object,
  defaultCenter: PropTypes.object,
  pinSize: PropTypes.array,
  centerOnMarkerClick: PropTypes.bool,
  withRoutes: PropTypes.bool,
  infoWindows: PropTypes.bool,
  isRoundTrip: PropTypes.bool,
  mapClassName: PropTypes.string,
  onInit: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  stopDate: PropTypes.instanceOf(Date),
  stopDuration: PropTypes.number,
  polylines: PropTypes.arrayOf(PropTypes.string),
};

const Map = ({
  withRoutes,
  defaultCenter,
  containerClassName,
  centerOnMarkerClick,
  mapClassName,
  infoWindows,
  onInit,
  startLocation,
  endLocation,
  stopLocation,
  startDate,
  endDate,
  stopDate,
  stopDuration,
  polylines,
  isRoundTrip,
}) => {
  const googleMapRef = useRef(null);
  const {
    addStartMarker,
    fitMapBounds,
    addEndMarker,
    addRoundTripMarkers,
    drawRoutes,
    markers,
  } = useMapUtilities({
    withRoutes,
    infoWindows,
    centerOnMarkerClick,
    startLocation,
    startDate,
    endDate,
    stopDate,
    stopDuration,
    endLocation,
    stopLocation,
  });

  useEffect(() => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      const map = new window.google.maps.Map(googleMapRef.current, {
        mapId: process.env.GOOGLE_MAPS_ID,
        fullscreenControl: false,
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_CENTER,
        },
      });
      if (typeof onInit === "function") onInit(map);

      if (
        withRoutes &&
        (stopLocation || isRoundTrip) &&
        startLocation &&
        endLocation
      ) {
        addRoundTripMarkers(map);
      } else {
        if (startLocation) addStartMarker(map);
        if (endLocation) addEndMarker(map);
      }

      if (withRoutes && polylines) {
        drawRoutes(polylines, map, bounds);
        return;
      }

      if (startLocation && endLocation) {
        if (markers[0]) bounds.extend(markers[0].latLng);
        if (markers[1]) bounds.extend(markers[1].latLng);
        if (withRoutes && stopLocation) {
          if (markers[2]) bounds.extend(markers[2].latLng);
        }
        fitMapBounds(map, bounds);
      } else if (startLocation || endLocation) {
        const c = markers[0].latLng;
        map.setCenter(c);
        if (c.lat() === defaultCenter.lat && c.lng() === defaultCenter.lng) {
          map.setZoom(3);
        }
      }
    }
  }, [
    startLocation?.latitude,
    startLocation?.longitude,
    endLocation?.latitude,
    endLocation?.longitude,
    stopLocation?.latitude,
    stopLocation?.longitude,
    JSON.stringify(polylines),
  ]);

  return (
    <div data-testid="google-map" className={containerClassName}>
      <div className="relative h-full">
        <div
          ref={googleMapRef}
          className={`absolute w-full h-full ${mapClassName || ""}`}
        />
      </div>
    </div>
  );
};

Map.propTypes = propTypes;
export default Map;
