import React from "react";

import Text from "src/components/elements/Text";

import ImgWelcomAboard from "public/assets/img/car-with-dog.svg";
import MiqLogo from "public/assets/img/miq-logo-black-orange.svg";

export default WelcomePage;

function WelcomePage() {
  return (
    <div className="page-onboarding bg-white w-full h-full relative flex items-center justify-center">
      <span className="logo absolute top-8 left-8">
        <MiqLogo />
      </span>

      <div
        style={{ width: "500px" }}
        className="flex flex-col justify-center items-center"
      >
        <h2 className="text-center">Welcome aboard!</h2>
        <Text paragraph lg color="black/70" className="mt-8 mb-12">
          Taking you to the admin dashboard...
        </Text>
        <ImgWelcomAboard />
      </div>
    </div>
  );
}
