import { useContext } from "react";

import { FlagsContext } from "src/components/context/FlagsContext";

export const useFlags = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlags must be used within a FlagsProvider");
  }

  return context.data;
};

export const useFlagsMethods = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlagsMethods must be used within a FlagsProvider");
  }

  return context.methods;
};
