import chameleon from "@chamaeleonidae/chmln";
import mixpanel from "mixpanel-browser";
import { createContext, useContext, useEffect, useState } from "react";

import { useUser, useUserData } from "src/components/context/UserContext";

import { useFlags } from "src/hooks/useFlags";

import { USER_TYPES } from "src/models/user";

import { report } from "src/services/error-reporting";
import { SUBSCRIPTION_TYPE } from "src/services/utils";

const ChameleonContext = createContext();

export function useChameleon() {
  return useContext(ChameleonContext);
}

export function ChameleonProvider({ children }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isChameleonActive, setIsChameleonActive] = useState(false);
  const { miqDashboardDisplayChameleonWeb: isEnabled } = useFlags();
  const { user } = useUser();
  const { userData } = useUserData();

  useEffect(() => {
    if (isEnabled && !window?.chmln) {
      try {
        chameleon.init(process.env.CHAMELEON_API_KEY, {
          fastUrl: "https://fast.chameleon.io/",
        });
        window.mixpanel = mixpanel; // Make mixpanel accessible globally
        setIsInitialized(true);
      } catch (error) {
        report(error);
      }
    } else if (!isEnabled && window?.chmln) {
      try {
        window.chmln.clear();
        setIsInitialized(false);
        setIsChameleonActive(false);
      } catch (error) {
        report(error);
      }
    }
  }, [isEnabled]);

  useEffect(() => {
    //  Chameleon charges us on MTUs, this will be a temporary solution to avoid unnecessary charges
    const isChameleonToBeEnabled =
      [SUBSCRIPTION_TYPE.TEAMS_STANDARD, SUBSCRIPTION_TYPE.TEAMS_PRO].includes(
        userData?.subscriptionType
      ) &&
      (user.role === USER_TYPES.ADMIN || user.role === USER_TYPES.ADMIN_DRIVER);

    if (isInitialized && user?.id && isChameleonToBeEnabled) {
      try {
        chameleon.identify(user.id, {
          email: user.email,
          name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
          created: user.createdAt,
          isPremium: user.isPremium,
          role: user.role,
          subscriptionType: userData?.subscriptionType,
        });
        setIsChameleonActive(false);
      } catch (error) {
        report(error);
      }
    }
  }, [isInitialized, user, userData]);

  function showChameleonTour(tourId) {
    if (isChameleonActive && window?.chmln) {
      try {
        window.chmln.show(tourId);
      } catch (error) {
        report(error);
      }
    }
  }

  function trackChameleonEvent(eventName) {
    if (isChameleonActive && window?.chmln) {
      try {
        window.chmln.track(eventName);
      } catch (error) {
        report(error);
      }
    }
  }

  return (
    <ChameleonContext.Provider
      value={{ showChameleonTour, trackChameleonEvent, isChameleonActive }}
    >
      {children}
    </ChameleonContext.Provider>
  );
}
