import React, { useContext } from "react";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";

import { ELEMENT_ID as REPORT_ACTIONS_MODAL } from "src/components/modals/ReportActions";
import { ELEMENT_ID as REPORT_ACTIONS_MODALV2 } from "src/components/modals/ReportActionsV2";

/*
  Drives page:
    payload == { drives: [id1, id2, ...]}
  Reports page:
    payload == { date_from: '2022-01-01',
                  date_to:   '2022-02-02'}
*/
const CreateReport = ({
  reportData,
  hasBusinessDrives,
  onStart,
  onFinish,
  children,
  ...rest
}) => {
  const { team } = useContext(TeamContext);
  const reportActionsModal = useElement(REPORT_ACTIONS_MODAL, {
    props: {
      onClose: () => {
        reportActionsModal.deactivate();
      },
      hasBusinessDrives,
      onReportSent: (
        success,
        showReportMessage,
        { recipientType, contactRelationship, markAsReported }
      ) => {
        success && reportActionsModal.deactivate();
        onFinish?.(success, showReportMessage, {
          recipientType,
          contactRelationship,
          markAsReported,
        });
      },
      reportData,
    },
  });
  const reportActionsModalV2 = useElement(REPORT_ACTIONS_MODALV2, {
    props: {
      onClose: () => {
        reportActionsModalV2.deactivate();
      },
      hasBusinessDrives,
      onReportSent: (
        success,
        showReportMessage,
        { recipientType, contactRelationship, markAsReported }
      ) => {
        success && reportActionsModalV2.deactivate();
        onFinish?.(success, showReportMessage, {
          recipientType,
          contactRelationship,
          markAsReported,
        });
      },
      reportData,
    },
  });

  const handleStartReportCreation = () => {
    onStart?.();
    if (!team) {
      return reportActionsModalV2.activate();
    }
    reportActionsModal.activate();
  };

  return (
    <Button {...rest} onClick={handleStartReportCreation}>
      {children}
    </Button>
  );
};

export default CreateReport;
