import { Button } from "@mileiq/design-system";
import { format, getYear } from "date-fns";
import React, { useContext, useEffect, useState } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useUserData } from "src/components/context/UserContext";

import MiqLoader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
  isTeamsProSubscription,
} from "src/models/team-subscription";

import { getContactSharedReports } from "src/services/reports";
import {
  DownloadFormats,
  Pages,
  TeamsSignupSource,
  WebPages,
  trackReportDownloaded,
  trackWebPageViewed,
} from "src/services/tracking";
import {
  COUNTRIES,
  COUNTRIES_DATA,
  formatCurrency,
  round,
  roundDistance,
  toUIDistance,
} from "src/services/utils";
import { prepareTeamsOnboardingUrl } from "src/services/utils";

export default SharedReportPage;

function SharedReportPage() {
  const { userData } = useUserData();
  const { team } = useContext(TeamContext);
  const [loadingReport, setLoadingReport] = useState(true);
  const [reports, setReports] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingReport(true);
      const reportsData = await getContactSharedReports().catch((error) => {
        console.error(error);
      });
      setReports(reportsData);
      setLoadingReport(false);
    };
    fetchData();
    trackWebPageViewed({
      currentPage: WebPages.SHARED_WITH_ME,
    });
  }, []);

  const handleDownloaded = (type = "pdf") => {
    let downloadFormat = DownloadFormats.PDF;
    if (type === "csv") {
      downloadFormat = DownloadFormats.CSV;
    } else if (type === "xls") {
      downloadFormat = DownloadFormats.XLS;
    }
    trackReportDownloaded({
      page: Pages.SHARED_WITH_ME,
      downloadFormat,
      subscriptionId: userData.subscriptionType,
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
    });
  };

  const isTeamsProUser = isTeamsProSubscription(team?.subscription?.plan);

  return (
    <div className="w-screen max-w-full min-h-screen flex bg-white">
      <div className="w-full flex flex-col">
        <div className="page-header relative">
          <div className="title-row">
            <div>
              <div className="flex items-center">
                <MobMenu />
                <h3>Shared with me</h3>
              </div>
              <Text paragraph color="black/70 text-[15px]" className="mt-1">
                Reports you have received from other MileIQ drivers.
              </Text>
            </div>
          </div>
        </div>
        <div className="p-[30px] flex-grow">
          {loadingReport ? (
            <MiqLoader />
          ) : (
            <div className="grid grid-cols-[repeat(4,275px)] laptop:grid-cols-[repeat(4,245px)] tablet:grid-cols-[repeat(4,218px)] gap-[20px] laptop:gap-[10px]">
              {reports
                ? reports.map((report) => (
                    <ReportCard
                      reportDetails={report}
                      key={report.submission_id}
                      onDownload={handleDownloaded}
                    />
                  ))
                : null}
              {!isTeamsProUser ? (
                <TryFreeTrialCard total={reports?.length || 0} />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const formatDate = (startDate, endDate) => {
  const shortMonthsTitleDateFormats = {
    month: ["MMM d", "MMM d, yyyy"],
    custom: ["MMM d", "MMM d, yyyy"],
    range: ["MMM d", "MMM d, yyyy"],
  };

  if (getYear(startDate) === getYear(new Date()))
    return `${format(
      startDate,
      shortMonthsTitleDateFormats.month[0]
    )} - ${format(endDate, shortMonthsTitleDateFormats.month[0])}`;
  else {
    return `${format(
      startDate,
      shortMonthsTitleDateFormats.month[1]
    )} - ${format(endDate, shortMonthsTitleDateFormats.month[1])}`;
  }
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const downloadXlsOrCsv = (reportDetails, type) => {
  fetch(type === "xls" ? reportDetails.xls_link : reportDetails.csv_link)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      const formattedStartDate = format(
        new Date(reportDetails.start_date),
        "yyyy-MM-dd"
      );
      const formattedEndDate = format(
        new Date(reportDetails.end_date),
        "yyyy-MM-dd"
      );

      const reportName = reportDetails.report_name;
      const docName = reportDetails.report_name;

      a.download = `MileIQ_${reportName}_${formattedStartDate}_${formattedEndDate}.${docName.toLowerCase()}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};

export function Card({ children, className }) {
  const cls = `w-[275px] laptop:w-[245px] tablet:w-[218px] min-h-[323px] laptop:min-h-[295px] p-[20px] laptop:p-[15px]
      border border-border-1 rounded flex flex-col
      shadow-[0px_5px_10px_rgba(0,0,0,0.04)] ${className || ""}`;
  return <div className={cls}>{children}</div>;
}

function TryFreeTrialCard({ total }) {
  let cardCls = "shared-reports-signup-cta border-none shadow-none";
  if (total > 4) {
    cardCls += " col-[4] row-[1]";
  }

  return (
    <Card className={cardCls}>
      <div className="h-full flex flex-col justify-end gap-[20px]">
        <div className="flex  items-center ">
          <div className="flex flex-col gap-[12px]">
            <Text semibold>Try Teams Pro</Text>
            <Text>
              See how much easier mileage admin can be — and save hours by
              approving drives with a click
            </Text>
          </div>
        </div>
        <Button
          label="Start 1-month free trial"
          onClick={() => {
            window.location.href =
              prepareTeamsOnboardingUrl(
                TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
                TEAM_SUBSCRIPTION_TYPES.MONTH,
                TeamsSignupSource.CONTACTS_DASHBOARD_STANDALONE
              ) + "&singleSku=true";
          }}
        />
      </div>
    </Card>
  );
}

export function ReportCard({ reportDetails, onDownload }) {
  if (!reportDetails) return null;

  const country = reportDetails.country?.toUpperCase() || COUNTRIES.US;
  const unit = reportDetails.unit
    ? reportDetails.unit
    : reportDetails.use_metric
    ? "km"
    : "mi";
  const currency = country === COUNTRIES.GB ? "£" : "$";

  return (
    <Card>
      <div className="flex flex-col gap-[20px]">
        <div className="flex flex-col">
          <Text custom className="truncate font-bold leading-[21px]">
            {reportDetails.report_name}
          </Text>
          <Text
            color="black/70"
            className="leading-[21px] h-[21px] overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {reportDetails.user_email}
          </Text>
          <Text color="black/70" className="leading-[21px]">
            {formatDate(
              new Date(reportDetails.start_date),
              new Date(reportDetails.end_date)
            )}
          </Text>
        </div>
        <div>
          <div className="pb-2 flex border-b border-dashed border-black/10">
            <h5 className="text-green">
              {formatCurrency({
                value: reportDetails.total_drive_value,
                currency: currency,
              })}
            </h5>
          </div>
          <div className="border-b border-dashed border-black/10 py-[7px] laptop:py-[6px] flex justify-between">
            <Text>Drives</Text>
            <Text>{Number(reportDetails.total_drive_count)}</Text>
          </div>
          <div className="border-b border-dashed border-black/10 drive-bottom-border py-[7px] laptop:py-[6px] flex justify-between">
            <Text>
              {unit === "mi" ? "Miles" : COUNTRIES_DATA[country].unitName}
            </Text>
            <Text>
              {toUIDistance(
                roundDistance(reportDetails.total_drive_distance),
                unit
              )}
            </Text>
          </div>
          <div className="border-b border-dashed border-black/10 drive-bottom-border py-[7px] laptop:py-[6px] flex justify-between">
            <Text>Average</Text>
            <Text>
              {formatCurrency({
                value: round(
                  reportDetails.total_drive_value /
                    reportDetails.total_drive_count,
                  2
                ),
                currency: currency,
              })}
            </Text>
          </div>
        </div>
        <div className="flex items-center justify-between mt-[15px]">
          <div>
            <Text color="black/70">
              {format(new Date(reportDetails.submission_date), "MMM dd, yyyy")}
            </Text>
          </div>
          <div className="flex gap-2 items-center">
            {reportDetails.xls_link ? (
              <a
                download
                href={reportDetails.xls_link}
                className="miq-btn miq-btn-secondary font-medium"
                onClick={(e) => {
                  if (isSafari()) {
                    e.preventDefault();
                    downloadXlsOrCsv(reportDetails, "xls");
                  }
                  onDownload("xls");
                }}
              >
                XLS
              </a>
            ) : reportDetails.csv_link ? (
              <a
                download
                href={reportDetails.csv_link}
                className="miq-btn miq-btn-secondary font-medium"
                onClick={(e) => {
                  if (isSafari()) {
                    e.preventDefault();
                    downloadXlsOrCsv(reportDetails, "csv");
                  }
                  onDownload("csv");
                }}
              >
                CSV
              </a>
            ) : null}
            {reportDetails.pdf_link ? (
              <a
                download
                target="_blank"
                href={reportDetails.pdf_link}
                className="miq-btn miq-btn-secondary font-medium"
                onClick={() => {
                  onDownload("pdf");
                }}
              >
                PDF
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  );
}
