import React, { useContext } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";
import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

import { COUNTRIES } from "src/services/utils";

export default MileageRatesPageQuickTips;

function MileageRatesPageQuickTips() {
  const customRates = useCustomRates();
  const defaultCountryRates = customRates?.defaultCountryRates?.data?.values;
  const source = customRates?.defaultCountryRates?.data?.source || "IRS";
  const year = customRates?.defaultCountryRates?.data?.year || "2022";

  const { userData } = useContext(UserDataContext);
  return (
    <>
      {userData.country === COUNTRIES.US && defaultCountryRates && (
        <div className="tip">
          <Icon
            name="light-bulb"
            className="self-start mt-[5px]"
            color="black"
          />
          <Text md>
            The {source} sets standard mileage rates for the US. The {year}{" "}
            rates are listed below by purpose:
            <br />
            <br />
            Business: ${defaultCountryRates?.business?.default}
            <br />
            Medical: ${defaultCountryRates?.personal?.medical}
            <br />
            Charity: ${defaultCountryRates?.personal?.charity}
            <br />
            Moving (one-time): ${defaultCountryRates?.personal?.moving}
            <br />
            Personal: $0.00
            <br />
            Commute: $0.00
            <br />
          </Text>
        </div>
      )}
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/203801459-How-to-Edit-the-Business-Rate-and-Distance-Unit">
            How to: Edit the Business Rate and Distance Unit
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://mileiq.com/blog-en-us/ask-the-tax-expert-what-if-im-partially-reimbursed-for-mileage">
            What if I'm Partially Reimbursed for Mileage?
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://mileiq.com/blog-en-us/mileage-tax-deduction">
            Mileage Deduction 101
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
