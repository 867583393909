import React, { useContext } from "react";

import useFlash from "src/hooks/useFlash";

const FlashContext = React.createContext({});

export function useAppFlash() {
  return useContext(FlashContext);
}

export const FlashProvider = ({ children }) => {
  const [flash, Flash] = useFlash();
  return (
    <FlashContext.Provider
      value={{
        flash,
      }}
    >
      {children}
      {Flash}
    </FlashContext.Provider>
  );
};
