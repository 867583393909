import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { useContacts } from "../context/ContactsContext";

export default RemoveContactModal;

export const REMOVE_CONTACT_MODAL_ID = "REMOVE_CONTACT_MODAL";
registerElement(REMOVE_CONTACT_MODAL_ID, RemoveContactModal);

function RemoveContactModal({ onClose, onSuccess, onError, contact }) {
  const {
    removeContact,
    removeContactState: { loading },
  } = useContacts();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await removeContact(contact?.id);

      onSuccess(`Contact removed`);
    } catch (error) {
      onError(`Something went wrong while removing contact. Please try again.`);
    }
  };

  return (
    <Modal closable={false} className="w-[400px]" onClose={onClose}>
      <h5>Are you sure you want to remove this contact?</h5>
      <p className="mt-2">
        This will remove{" "}
        <Text semibold>{contact?.email || "this contact"}</Text> from your
        contacts
      </p>
      <div className="flex justify-end gap-2 mt-6">
        <Button disabled={loading} onClick={onClose} secondary>
          Cancel
        </Button>
        <Button
          destructive
          disabled={loading}
          loading={loading}
          onClick={handleSubmit}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
}
