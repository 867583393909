import React, { useEffect, useRef } from "react";

export default Pivoted;

/**
 * Component that could position itself around the `pivot` element
 *
 * @param {{ pivot: HTMLElement }} props
 * @returns
 */
function Pivoted({
  pivot,
  children,
  onClickOutside,
  style,
  right,
  bottom,
  bottomRight,
  left,
  offset,
  matchPivotWidth,
  adjustPosition,
}) {
  /** @type {React.MutableRefObject<HTMLElement>} */
  const elRef = useRef();

  const handleClickOutside = (e) => {
    if (!elRef?.current) return;
    if (!elRef.current.contains(e.target) && !pivot?.contains(e.target)) {
      onClickOutside();
    }
  };

  const calcPosition = () => {
    if (pivot && elRef?.current) {
      const el = elRef.current;

      // fixed position is calculated relative to the containing block
      // most of the time it is the viewport
      // but containing block could also be the first parent element with transform property set
      // so first we need to position the element at the top of its containing block
      el.style.position = "fixed";
      el.style.top = 0;
      el.style.left = 0;

      const pivotRect = pivot.getBoundingClientRect();

      if (matchPivotWidth) {
        el.style.width = pivotRect.width + "px";
      }

      const elRect = el.getBoundingClientRect();
      // return;
      const offsetX = offset?.x || 0;
      const offsetY = offset?.y || 0;
      let t = 0;
      let l = 0;
      switch (true) {
        case left:
          t = pivotRect.y + pivotRect.height / 2 - elRect.height / 2;
          l = pivotRect.x - elRect.width;
          break;
        case right:
          t = pivotRect.y + pivotRect.height / 2 - elRect.height / 2;
          l = pivotRect.x + pivotRect.width;
          break;
        case bottomRight:
          t = pivotRect.y + pivotRect.height;
          l = pivotRect.x + pivotRect.width - elRect.width;
          break;
        case bottom:
        default:
          t = pivotRect.y + pivotRect.height;
          l = pivotRect.x;
      }

      if (typeof adjustPosition === "function") {
        const newPos = adjustPosition(t, l, elRect, pivotRect);
        t = newPos.top;
        l = newPos.left;
      }

      el.style.top = offsetY + t + "px";
      el.style.left = offsetX + l + "px";
    }
  };

  useEffect(() => {
    calcPosition();
  }, [pivot, elRef.current, children]);

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside, true);
    window.addEventListener("resize", calcPosition);
    document.addEventListener("scroll", calcPosition, true);

    return () => {
      document.body.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("resize", calcPosition);
      document.removeEventListener("scroll", calcPosition, true);
    };
  }, []);

  return (
    <div ref={elRef} className="fixed animate-fade-in-200" style={style}>
      {children}
    </div>
  );
}
