import React, { useEffect, useState } from "react";

import Input from "src/components/elements/Input";

export default RatesInput;

const validNumRegex = /^(([0])|([1-9]{1,}))(\.[0-9]{0,3})?$/;
const tooMuchDecimalsRegex = /^(([0])|([1-9]{1,}))(\.[0-9]{4,})?$/;
function RatesInput({
  rate,
  onChange,
  onClear,
  prefix,
  placeholder,
  disabled,
}) {
  const [val, setVal] = useState(rate);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val?.trim() === "") {
      setVal("");
      if (typeof onClear === "function") onClear();
      return;
    }

    // allow entering '.' as decimal separator in non-empty value.
    // only updates the UI,
    // but doesn't notify that the actual rate value changed
    if (val.indexOf(".") === val.length - 1 && val.length > 1) {
      setVal(val);
      return;
    }

    // allow delete extra symbols while editing
    if (
      tooMuchDecimalsRegex.test(val) &&
      tooMuchDecimalsRegex.test(rate) &&
      val.length < rate.toString().length
    ) {
      onChange(val);
      setVal(val);
      return;
    }

    if (validNumRegex.test(val)) {
      setVal(val);
      onChange(val);
    }
  };

  useEffect(() => {
    setVal(rate);
  }, [rate]);

  return (
    <Input
      style={{ width: "190px" }}
      prefix={prefix}
      disabled={disabled}
      value={val?.toString()}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}
