import React, { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { removeByEmail } from "src/services/teams";
import {
  trackTeamsRemoveUserCompleted,
  trackTeamsRemoveUserStarted,
} from "src/services/tracking";

export default RemoveUsersConfirm;

function RemoveUsersConfirm({ team, user, onClose, onConfirm }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.email) {
      trackTeamsRemoveUserStarted({ email: user.email });
    }
  }, [user]);

  if (!user) return null;
  const handleRemove = async () => {
    if (loading) return;

    setLoading(true);

    try {
      await removeByEmail(team.id, user);
      trackTeamsRemoveUserCompleted({ email: user.email });
      setLoading(false);
      onConfirm();
    } catch (err) {
      console.log(err);
      alert("Failed to remove");
      setLoading(false);
    }
  };

  let title = "";
  let desc = null;
  switch (true) {
    // Admin & Driver
    case user.isAdmin && user.isDriver:
      title = "Are you sure you want to remove this user?";
      desc = (
        <Text paragraph lg className="mt-3">
          This will remove <Text bold>{user.email}</Text> from your team and
          they will lose access to your team's Admin dashboard and their MileIQ
          Unlimited subscription.
        </Text>
      );
      break;
    // Admin
    case user.isAdmin:
      title = "Are you sure you want to remove this user?";
      desc = (
        <Text paragraph lg className="mt-3">
          This will remove <Text bold>{user.email}</Text> from your team and
          they will lose access to your team's Admin dashboard.
        </Text>
      );
      break;
    // Driver
    case user.isDriver:
      title = "Are you sure you want to remove this user from your team?";
      desc = (
        <Text paragraph lg className="mt-3">
          This will remove <Text bold>{user.email}</Text> from your team and
          they will lose access to their MileIQ Unlimited subscription.
        </Text>
      );
      break;
  }

  return (
    <Modal
      closable={false}
      onClose={onClose}
      loading={loading}
      className="w-[400px] remove-user-confirm-modal"
    >
      <>
        <h5>{title}</h5>
        <div className="mt-3">{desc}</div>

        <div className="flex justify-end mt-5">
          <Button secondary className="text-13" onClick={onClose}>
            Cancel
          </Button>
          <Button destructive className="ml-3 text-13" onClick={handleRemove}>
            Remove
          </Button>
        </div>
      </>
    </Modal>
  );
}
