import { isObject as Jt } from "../util/code-utils.js";
import {
  convertMsToSeconds as l,
  timestampOrNow as Vt
} from "../util/date-utils.js";
export default class ue {
  constructor(t, i, s, r, e) {
    (this.userId = t),
      (this.type = i),
      (this.time = Vt(s)),
      (this.sessionId = r),
      (this.data = e);
  }
  Mi() {
    const t = {
      name: this.type,
      time: l(this.time),
      data: this.data || {},
      session_id: this.sessionId
    };
    return null != this.userId && (t.user_id = this.userId), t;
  }
  ss() {
    return {
      u: this.userId,
      t: this.type,
      ts: this.time,
      s: this.sessionId,
      d: this.data
    };
  }
  static fromJson(t) {
    return new ue(t.user_id, t.name, t.time, t.session_id, t.data);
  }
  static Fa(t) {
    return null != t && Jt(t) && null != t.t && "" !== t.t;
  }
  static Sn(t) {
    return new ue(t.u, t.t, t.ts, t.s, t.d);
  }
}
