import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useUser, useUserData } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";

import { useFlags, useFlagsMethods } from "src/hooks/useFlags";

const settingsNav = [
  {
    isGroupTitle: true,
    title: "Account",
  },
  {
    label: "Profile",
    path: "/user/settings/profile",
    icon: "user",
  },
  {
    label: "Notifications",
    path: "/user/settings/notifications",
    icon: "notification",
  },
  {
    label: "Integrations",
    path: "/user/settings/integrations",
    icon: "link-rounded",
  },
  {
    label: "Share MileIQ",
    path: "/user/settings/share",
    icon: "share",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "Drives",
  },
  {
    label: "Vehicle & odometer",
    path: "/user/settings/vehicle-and-odometer",
    icon: "odometer",
  },
  {
    label: "Mileage rates",
    path: "/user/settings/mileage-rates",
    icon: "rates",
  },
  {
    label: "Auto-classification",
    path: "/user/settings/auto-classification",
    icon: "truck",
  },
  {
    label: "Custom purposes",
    path: "/user/settings/custom-purposes",
    icon: "pencil",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "Reports",
    hidden: ({ team }) => {
      return !!team;
    },
  },
  {
    label: "Contacts",
    path: "/user/settings/contacts",
    hidden: ({ team }) => {
      return !!team;
    },
    icon: "user",
    liClass: "last-in-group",
  },
  {
    isGroupTitle: true,
    title: "Team Settings",
    hidden: ({ flags, team }) => {
      return (
        flags?.miqDashboardTeamsDriverAutomaticReportsWeb === false || !team
      );
    },
  },
  {
    label: "Automatic Reporting",
    path: "/user/settings/automatic-reports",
    icon: "chart",
    hidden: ({ flags, team }) => {
      return (
        flags?.miqDashboardTeamsDriverAutomaticReportsWeb === false || !team
      );
    },
  },
];

export function SettingsSidenav() {
  return <Sidenav items={settingsNav} renderMenu={() => {}} />;
}

function Sidenav({ items }) {
  const { userData } = useUserData();
  const { user } = useUser();
  const { team } = useContext(TeamContext);
  const flags = useFlags();
  const { track } = useFlagsMethods();

  return (
    <div className="settings-sidenav w-[245px] laptop:w-[230px]">
      <nav className="flex-grow self-stretch">
        <ul>
          {items.map((item) => {
            if (
              typeof item?.hidden === "function"
                ? item.hidden({ user, team, flags, userData })
                : item?.hidden
            )
              return null;
            const linkContent = (
              <>
                <div className="flex w-[40px] justify-center">
                  <span className="menu-icon">
                    <Icon name={item.icon} color="black" />
                  </span>
                </div>
                <span className="menu-item-label" data-testid="sidenav-link">
                  {item.label}
                </span>
              </>
            );
            if (item.isGroupTitle) {
              return (
                <li key={item.title} className="mb-3">
                  <h5>{item.title}</h5>
                </li>
              );
            }
            return (
              <li className={`${item.liClass || ""}`} key={item.label}>
                <NavLink
                  activeClassName="active"
                  to={item.path}
                  onClick={() => {
                    if (item.label === "Vehicle & odometer") {
                      track("A/A test");
                    }
                  }}
                >
                  {linkContent}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
