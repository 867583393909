import { useContext, useEffect } from "react";

import {
  CANCELLATION_STEPS,
  useTeamCancellation,
} from "src/components/context/TeamCancellationContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";

import { NETWORK_STATES } from "src/services/http";
import {
  CancellationAbandonedMethods,
  trackTeamsAllTimeStatsViewed,
  trackTeamsSubscriptionCancellationAbandoned,
} from "src/services/tracking";
import { formatNumberWithSuffix } from "src/services/utils";

export function TeamAllTimeStats({ closeModal }) {
  const {
    goToNextStep,
    distanceStats,
    reportsStats,
    valueStats,
    membersStats,
    shouldSkipClaimDiscountStep,
    shouldSkipDowngradeStep,
    cancelSubscriptionNetworkState,
    cancelSubscription,
    currentSubscriptionPlan,
    currentSubscriptionType,
    isEligibleToClaimDiscount,
  } = useTeamCancellation();
  const { team } = useContext(TeamContext);

  useEffect(() => {
    trackTeamsAllTimeStatsViewed({
      currentSubscriptionPlan,
      currentSubscriptionType,
      driverCount: team.subscription?.numberOfSeats || 0,
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      eligibleToDiscounts: isEligibleToClaimDiscount,
    });
  }, []);

  const isCancelSubscriptionLoading =
    cancelSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const memberCount = membersStats?.value || 1;

  const shouldOfferCancellationStraightAway =
    shouldSkipDowngradeStep && shouldSkipClaimDiscountStep;

  const handleCancelPlan = async (e) => {
    e.preventDefault();

    const successfullyCanceledSubscription = await cancelSubscription();

    if (successfullyCanceledSubscription) closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    trackTeamsSubscriptionCancellationAbandoned({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      teamsPlanId: team.subscription?.plan,
      subType: team.subscription?.type,
      teamId: team.id,
      freeTrial: team.subscription?.isFreeTrialActive,
      teamsDriverCount: team.subscription?.numberOfSeats || 0,
      eligibleToDiscounts: isEligibleToClaimDiscount,
      cancellationStep: CANCELLATION_STEPS.TEAM_ALL_TIME_STATS,
      method: CancellationAbandonedMethods.KEEP_MY_PLAN_CTA,
    });

    closeModal();
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-[30px] mt-5">
      <h5>You've accomplished so much with MileIQ</h5>
      <div className="grid grid-cols-3 gap-2.5 justify-between text-center">
        <div className="flex flex-col items-center gap-[11px] pt-5 pb-[25px] rounded-[14px] bg-[#ebfcb0]">
          <Icon name="reports" className="z-10 w-[22px] h-[22px]" />
          <p className="z-10 text-[34px] font-semibold leading-[40px]">
            {formatNumberWithSuffix({
              value: reportsStats?.value,
              fractionDigits: reportsStats?.value > 1000 ? 1 : 0,
            })}
          </p>
          <p className="z-10 px-5 text-[18px] leading-[22px]">
            {reportsStats?.label}
          </p>
        </div>
        <div className="flex flex-col items-center gap-[11px] pt-5 pb-[25px] rounded-[14px] bg-[#D6F2FF]">
          <Icon name="distance" className="z-10 w-[22px] h-[22px]" />
          <p className="z-10 text-[34px] font-semibold leading-[40px]">
            {formatNumberWithSuffix({
              value: distanceStats?.value,
              fractionDigits: 1,
            })}
          </p>
          <p className="z-10 px-5 text-[18px] leading-[22px]">
            {distanceStats?.label}
          </p>
        </div>
        <div className="flex flex-col items-center gap-[11px] pt-5 pb-[25px] rounded-[14px] bg-[#FFF2D6]">
          <Icon name="money-bill" className="z-10 w-[22px] h-[22px]" />
          <p className="z-10 text-[34px] font-semibold leading-[40px]">
            {valueStats?.unit}
            {formatNumberWithSuffix({
              value: valueStats?.value,
              fractionDigits: valueStats?.value >= 1000 ? 1 : 0,
            })}
          </p>
          <p className="z-10 px-5 text-[18px] leading-[22px]">
            {valueStats?.label}
          </p>
        </div>
      </div>
      <div>
        <p>
          If you cancel your subscription, the{" "}
          <span className="font-semibold">
            {memberCount} {memberCount > 1 ? "teammates" : "teammate"}
          </span>{" "}
          you support will lose access to unlimited mileage tracking.
        </p>
        <p className="mt-[20px]">Are you sure you want to cancel?</p>
      </div>
      <div className="flex items-center gap-2.5 justify-end">
        <Button
          className="font-medium min-w-[163.55px]"
          secondary
          onClick={
            shouldOfferCancellationStraightAway
              ? handleCancelPlan
              : goToNextStep
          }
          type="button"
        >
          {isCancelSubscriptionLoading ? (
            <Icon name="spinner" />
          ) : (
            <span>
              {shouldOfferCancellationStraightAway
                ? "Cancel subscription"
                : "Continue to cancel"}
            </span>
          )}
        </Button>
        <Button
          disabled={isCancelSubscriptionLoading}
          className="w-[120px] font-medium"
          type="submit"
        >
          Keep my plan
        </Button>
      </div>
    </form>
  );
}
