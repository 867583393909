import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { UserDataContext, useUser } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as EDIT_EMAIL_MODAL } from "src/components/modals/EditEmail";
import { ELEMENT_ID as EDIT_NAME_MODAL } from "src/components/modals/EditName";

import useFlash from "src/hooks/useFlash";
import useQueryParams from "src/hooks/useQueryParams";

import {
  trackProfileNameAddCompleted,
  trackProfileNameAddStarted,
  trackProfileNameUpdateCompleted,
  trackProfileNameUpdateStarted,
  trackUsernameEditStarted,
} from "src/services/tracking";

export default ContactInfo;

function ContactInfo({ isEditEmailEnabled }) {
  const { user, refreshUser } = useUser();
  const { userData } = useContext(UserDataContext);
  const [flash, successFlash] = useFlash();
  const queryParams = useQueryParams();
  const history = useHistory();

  const nameExists = user.firstName && user.lastName;

  const editNameModal = useElement(EDIT_NAME_MODAL, {
    props: {
      onClose: () => {
        editNameModal.deactivate();
        queryParams.delete("action");
        history.replace({ search: queryParams.toString() });
      },
      onSubmit: () => {
        nameExists
          ? trackProfileNameUpdateCompleted({
              subscriptionId: userData.subscriptionType,
            })
          : trackProfileNameAddCompleted({
              subscriptionId: userData.subscriptionType,
            });
        flash(
          <Text>
            {nameExists
              ? `Your name has been updated.`
              : `Your name has been successfully added.`}
          </Text>,
          {
            type: FlashTypes.BLACK,
            hideAfterMs: 1500,
          }
        );
        setTimeout(refreshUser, 1500);
      },
    },
  });

  const editEmailModal = useElement(EDIT_EMAIL_MODAL, {
    props: {
      onClose: () => {
        editEmailModal.deactivate();
        queryParams.delete("action");
        history.replace({ search: queryParams.toString() });
      },
      onSubmit: () => {
        flash(<Text>Your email address was successfully updated!</Text>, {
          type: FlashTypes.BLACK,
          hideAfterMs: 1500,
        });
        setTimeout(refreshUser, 1500);
      },
    },
  });

  const handleEditNameClick = () => {
    queryParams.set("action", "editName");
    history.push({ search: queryParams.toString() });
  };

  const handleEditEmailClick = () => {
    queryParams.set("action", "editEmail");
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    if (queryParams.get("action") === "editName" && !editNameModal.open) {
      nameExists
        ? trackProfileNameUpdateStarted({
            subscriptionId: userData.subscriptionType,
          })
        : trackProfileNameAddStarted({
            subscriptionId: userData.subscriptionType,
          });
      editNameModal.activate();
    } else if (editNameModal.open) {
      editNameModal.deactivate();
    }
    if (queryParams.get("action") === "editEmail" && !editEmailModal.open) {
      trackUsernameEditStarted({ subscriptionId: userData.subscriptionType });
      isEditEmailEnabled && editEmailModal.activate();
    } else if (editEmailModal.open) {
      editEmailModal.deactivate();
    }
  }, [queryParams]);

  return (
    <>
      <div className="relative flex flex-col gap-6" data-testid="contact-info">
        <h6>Contact Info</h6>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-1">
            <Text bold>Name</Text>
          </div>
          <div className="col-span-5">
            <Text lg>
              {nameExists ? (
                `${user.firstName} ${user.lastName}`
              ) : (
                <span className={"text-black/50 italic"}>Not set</span>
              )}
              <button
                type="button"
                onClick={handleEditNameClick}
                className="bg-transparent border-none outline-none text-blue ml-2"
              >
                {nameExists ? "Edit" : "Add Name"}
              </button>
            </Text>
          </div>
          <div className="col-span-1">
            <Text bold>Email address</Text>
          </div>
          <div className="col-span-5">
            <Text>
              {user.email}
              {isEditEmailEnabled ? (
                <button
                  type="button"
                  onClick={handleEditEmailClick}
                  className="bg-transparent border-none outline-none text-blue ml-2"
                >
                  Edit
                </button>
              ) : (
                <span className={"ml-2 text-black/50"}>
                  (Contact{" "}
                  <a
                    href="https://support.mileiq.com/hc/en-us/requests/new"
                    target="_blank"
                    rel="noopener"
                    className="underline"
                  >
                    support
                  </a>{" "}
                  to edit)
                </span>
              )}
            </Text>
          </div>
        </div>
      </div>
      {successFlash}
    </>
  );
}
