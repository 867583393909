import Base from "src/models/base";

export default class AutoReviewSettings extends Base {
  state = false;

  constructor(data) {
    super("auto_review_settings_model");
    if (data) {
      this.state = data.state || false;
    }
  }
}
