import { Button, TextInput } from "@mileiq/design-system";
import React, { useRef } from "react";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import useFlash from "src/hooks/useFlash";

import { ShareMileiqSources, trackShareMileiq } from "src/services/tracking";

const SHARE_URL = "https://mileiq.page.link/share";

const SHARE_OPTIONS = {
  EMAIL: "EMAIL",
  LINKEDIN: "LINKEDIN",
  TWITTER: "TWITTER",
  FACEBOOK: "FACEBOOK",
};

const shareOptionsData = {
  [SHARE_OPTIONS.EMAIL]: {
    name: "mail",
    url: "mailto:?subject=Save time and money with MileIQ&body=",
    text: "I use MileIQ and love it, and I think you will too. Maximizes your tax deductions and reimbursements hassle free. Go here to download it for free and let me know what you think. ",
  },
  [SHARE_OPTIONS.TWITTER]: {
    name: "twitter",
    url: "https://twitter.com/intent/tweet?&hashtags=mileiq,savemoney,greatapp&url=",
    text: "Check out MileIQ, a great app that I use, that maximizes my tax deductions and reimbursements. Download for free!",
  },
  [SHARE_OPTIONS.LINKEDIN]: {
    name: "linkedin",
    url: "https://www.linkedin.com/shareArticle?mini=true&url=",
    title:
      "Join the millions of drivers who’ve saved billions of dollars with MileIQ.",
    text: "Check out MileIQ, the app I use to automatically log my mileage. I’m saving time every week and I’m going to get my biggest mileage deduction ever this year.",
  },
  [SHARE_OPTIONS.FACEBOOK]: {
    name: "facebook",
    url: "https://www.facebook.com/sharer/sharer.php?u=",
    title:
      "Join the millions of drivers who’ve saved billions of dollars with MileIQ.",
    text: "Check out MileIQ, the app I use to automatically log my mileage. I’m saving time every week and I’m going to get my biggest mileage deduction ever this year.",
  },
};

export default SharePage;

function SharePage() {
  const refs = {
    [SHARE_OPTIONS.EMAIL]: useRef(),
    [SHARE_OPTIONS.FACEBOOK]: useRef(),
    [SHARE_OPTIONS.LINKEDIN]: useRef(),
    [SHARE_OPTIONS.TWITTER]: useRef(),
  };

  const [flash, Flash] = useFlash();

  const onCopied = () => {
    trackShareMileiq(ShareMileiqSources.URL);
    flash(<Text>Link copied</Text>, {
      type: FlashTypes.SAVED,
    });
  };

  const handleShareClick = (key) => {
    const { url, text, title } = shareOptionsData[key];
    switch (key) {
      case SHARE_OPTIONS.EMAIL: {
        trackShareMileiq(ShareMileiqSources.EMAIL);
        const emailURL = url + text + SHARE_URL;
        location.href = emailURL;
        break;
      }
      case SHARE_OPTIONS.TWITTER: {
        trackShareMileiq(ShareMileiqSources.TWITTER);
        const twitterURL = `${url + SHARE_URL}&text=${text}`;
        window.open(twitterURL, "_blank", "width=400,height=400");
        break;
      }
      case SHARE_OPTIONS.LINKEDIN: {
        trackShareMileiq(ShareMileiqSources.LINKED_IN);
        const linkedinURL = `${url + SHARE_URL}&title=${title}&summary=${
          text + SHARE_URL
        }`;
        window.open(linkedinURL, "_blank", "width=400,height=400");
        break;
      }
      case SHARE_OPTIONS.FACEBOOK: {
        trackShareMileiq(ShareMileiqSources.FACEBOOK);
        const facebookURL = `${url + SHARE_URL}&title=${title}&description=${
          text + SHARE_URL
        }`;
        window.open(facebookURL, "_blank", "width=400,height=400");
        break;
      }
    }
  };

  return (
    <div
      className="flex flex-col gap-[15px] p-[20px] laptop:p-[15px] relative"
      data-testid="share-page"
    >
      <h6>Share MileIQ</h6>
      <Text paragraph lg>
        Spread the love and share MileIQ with your friends and colleagues. Click
        on the copy link to share manually or select the right social platform
        icon.
      </Text>
      <CopyUrl onCopied={onCopied} />
      <div className="flex">
        {Object.keys(shareOptionsData).map((key) => {
          const item = shareOptionsData[key];
          return (
            <button
              key={key}
              ref={refs[key]}
              className="w-[45px] h-[45px] rounded-full mr-2.5 bg-sand"
              onClick={() => handleShareClick(key)}
            >
              <Icon name={item.name} />
              <Tooltip
                triggerRef={refs[key]}
                offset={{ y: 50 }}
                alignX={"center"}
                timeout={1000}
              >
                <Text className="capitalize" nowrap>
                  {item.name}
                </Text>
              </Tooltip>
            </button>
          );
        })}
      </div>
      {Flash}
    </div>
  );
}
function CopyUrl({ onCopied }) {
  const ref = useRef(null);
  const handleCopyClick = async () => {
    ref.current?.select();
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(SHARE_URL);
    } else {
      document.execCommand("copy");
    }
    onCopied();
  };

  return (
    <div className="flex">
      <div className="min-w-[306px]">
        <TextInput ref={ref} readOnly value={SHARE_URL} />
      </div>
      <div className="relative z-[999] -left-6">
        <Button label="Copy" onClick={handleCopyClick} />
      </div>
    </div>
  );
}
