import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import ReportsPage from "src/components/pages/TeamsDashboard/Reports";

import PageLayout from "src/components/PageLayout";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import {
  teamsUpgradeSources,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import { COUNTRIES } from "src/services/utils";

import CAIllustration2x from "public/assets/img/illustrations/ca-team-reports-upsell-illustration-2x.png";
import CAIllustration from "public/assets/img/illustrations/ca-team-reports-upsell-illustration.png";
import USIllustration2x from "public/assets/img/illustrations/team-reports-upsell-illustration-2x.png";
import USIllustration from "public/assets/img/illustrations/team-reports-upsell-illustration.png";
import UKIllustration2x from "public/assets/img/illustrations/uk-team-reports-upsell-illustration-2x.png";
import UKIllustration from "public/assets/img/illustrations/uk-team-reports-upsell-illustration.png";

export default ReportsOrUpgrade;

function ReportsOrUpgrade() {
  const { team } = useContext(TeamContext);
  if (!team?.id) return null;

  const planId = team.pendingSubscription?.plan || team.subscription?.plan;

  let canShowReports = false;
  if (isTeamsSubscription(planId) || isTeamsProSubscription(planId)) {
    canShowReports = true;
  }

  return canShowReports ? <ReportsPage /> : <Upgrade />;
}

const FEATURES = [
  "Centralized billing",
  "Custom rates",
  "Addresses on reports (Teams Pro)",
  "Multiple admins (Teams Pro)",
  "Team Locations (Teams Pro)",
];

const IMG_SETTINGS = {
  [COUNTRIES.US]: {
    src: USIllustration,
    srcSet: `${USIllustration} 540w, ${USIllustration2x} 1080w`,
  },
  [COUNTRIES.CA]: {
    src: CAIllustration,
    srcSet: `${CAIllustration} 540w, ${CAIllustration2x} 1080w`,
  },
  [COUNTRIES.GB]: {
    src: UKIllustration,
    srcSet: `${UKIllustration} 540w, ${UKIllustration2x} 1080w`,
  },
};

function Upgrade() {
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  if (!team?.id) return null;

  const imageSettings = IMG_SETTINGS[userData?.country || COUNTRIES.US];

  const flowOpts = {
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: teamsUpgradeSources.REPORTS_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
      });
    },
    currentSubPlan: team.subscription?.plan,
    excludePlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE],
  };
  const [startUpgradeFlow, renderUpgradeFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow({
        ...flowOpts,
        onDone: () => {
          history.push("/teams/users");
        },
      }) // select new plan and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(flowOpts); // select plan and then invite first users

  const handleUpgradeClicked = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    startUpgradeFlow();
  };

  return (
    <>
      <PageLayout className="page-upgrade-for-reports bg-beige overflow-x-hidden">
        <PageLayout.Main>
          <div className="lg:h-full flex gap-16 xl:gap-32 justify-between items-start lg:items-center pt-8 pl-8 pr-8 lg:pr-0 lg:pt-0 lg:pl-[124px]">
            <div className="lg:max-w-[370px]">
              <div className="flex flex-col items-start gap-4">
                <MobMenu />
              </div>
              <h3>Want to see your team's reports here?</h3>
              <div className="lg:max-w-[345px]">
                <Text custom paragraph className="mt-[30px] text-15">
                  Upgrade to view all your drivers' reports on your dashboard
                  and get access to consolidated reports.
                </Text>
                <Text custom paragraph className="mt-5 text-15">
                  You'll also enjoy other premium features, including:
                </Text>
              </div>
              <ul className="mt-[30px] flex flex-col gap-2">
                {FEATURES.map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <Icon name="check-mark" color="green" />
                    <Text md>{feature}</Text>
                  </li>
                ))}
              </ul>
              <Button
                lg
                onClick={handleUpgradeClicked}
                className="mt-[30px] font-medium w-[160px]"
              >
                Upgrade now
              </Button>
            </div>
            <img
              src={imageSettings.src}
              srcSet={imageSettings.srcSet}
              sizes="(max-width: 1280px) 450px, 506px"
              alt="illustration"
              className="hidden lg:block mr-32"
            />
          </div>
        </PageLayout.Main>
      </PageLayout>
      {renderUpgradeFlow()}
    </>
  );
}
