import e, { OPTIONS as T } from "../../managers/braze-instance.js";
import ControlMessage from "../models/control-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import pe from "../in-app-message-manager-factory.js";
import se from "../display/get-animation-effect.js";
import le from "../display/in-app-message-to-html.js";
import { logInAppMessageImpression } from "../log-in-app-message-impression.js";
import {
  ORIENTATION as fe,
  WindowUtils as H
} from "../../util/window-utils.js";
import { isURIJavascriptOrData as j } from "../../util/url-utils.js";
import { KeyCodes as at } from "../../util/key-codes.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import r from "../../../shared-lib/braze-shared-lib.js";
import { toRgba as me } from "../../util/color-utils.js";
export function showInAppMessage(t, s, o) {
  if (!e.rr()) return;
  if ((ss(), null == t)) return !1;
  if (t instanceof ControlMessage)
    return (
      r.D.info(
        "User received control for a multivariate test, logging to Braze servers."
      ),
      logInAppMessageImpression(t),
      !0
    );
  if (!(t instanceof InAppMessage)) return !1;
  const i = pe.m();
  t.Ji();
  const n = t instanceof HtmlMessage;
  if (n && !t.trusted && !e.tr())
    return (
      r.D.error(
        'HTML in-app messages are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these messages.'
      ),
      i.ni(t.triggerId, InAppMessage.li.ih),
      !1
    );
  if ((null == s && (s = document.body), t.Me())) {
    if (s.querySelectorAll(".ab-modal-interactions").length > 0)
      return (
        r.D.info(
          `Cannot show in-app message ${t.message} because another message is being shown.`
        ),
        i.ni(t.triggerId, InAppMessage.li.eh),
        !1
      );
  }
  if (H.so()) {
    const e = H.io();
    if (
      (e === fe.PORTRAIT &&
        t.orientation === InAppMessage.Orientation.LANDSCAPE) ||
      (e === fe.LANDSCAPE &&
        t.orientation === InAppMessage.Orientation.PORTRAIT)
    ) {
      const s = e === fe.PORTRAIT ? "portrait" : "landscape",
        o =
          t.orientation === InAppMessage.Orientation.PORTRAIT
            ? "portrait"
            : "landscape";
      return (
        r.D.info(
          `Not showing ${o} in-app message ${t.message} because the screen is currently ${s}`
        ),
        i.ni(t.triggerId, InAppMessage.li.hh),
        !1
      );
    }
  }
  if (!e.tr()) {
    let e = !1;
    if (t.buttons && t.buttons.length > 0) {
      const s = t.buttons;
      for (let t = 0; t < s.length; t++)
        if (s[t].clickAction === InAppMessage.ClickAction.URI) {
          const o = s[t].uri;
          e = j(o);
        }
    } else t.clickAction === InAppMessage.ClickAction.URI && (e = j(t.uri));
    if (e)
      return (
        r.D.error(
          'Javascript click actions are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these actions.'
        ),
        i.ni(t.triggerId, InAppMessage.li.ih),
        !1
      );
  }
  const a = document.createElement("div");
  if (
    ((a.className = "ab-iam-root v3"),
    (a.className += se(t)),
    a.setAttribute("role", "complementary"),
    t.an() && (a.id = t.htmlId),
    e.nn(T.no) && (a.style.zIndex = e.nn(T.no) + 1),
    s.appendChild(a),
    t.sn())
  ) {
    const s = document.createElement("style");
    (s.innerHTML = t.css),
      (s.id = t.rn()),
      null != e.nn(T.ao) && s.setAttribute("nonce", e.nn(T.ao)),
      document.getElementsByTagName("head")[0].appendChild(s);
  }
  const l = t instanceof SlideUpMessage,
    m = le(
      t,
      () => {
        import("../../Feed/ui/show-feed.js").then(e => e.showFeed());
      },
      s => {
        if (t.Me() && t.ll()) {
          const o = document.createElement("div");
          if (
            ((o.className = "ab-page-blocker"),
            t.sn() || (o.style.backgroundColor = me(t.frameColor)),
            e.nn(T.no) && (o.style.zIndex = e.nn(T.no)),
            a.appendChild(o),
            !e.nn(T.qi))
          ) {
            const e = new Date().valueOf();
            o.onclick = o => {
              new Date().valueOf() - e > InAppMessage.Eh &&
                (t.on(s), o.stopPropagation());
            };
          }
          a.appendChild(s), s.focus(), t.Yi(a);
        } else if (l) {
          const e = document.querySelectorAll(".ab-slideup");
          let o = null;
          for (let t = e.length - 1; t >= 0; t--)
            if (e[t] !== s) {
              o = e[t];
              break;
            }
          if (t.slideFrom === InAppMessage.SlideFrom.TOP) {
            let e = 0;
            null != o && (e = o.offsetTop + o.offsetHeight),
              (s.style.top = Math.max(e, 0) + "px");
          } else {
            let e = 0;
            null != o &&
              (e =
                (window.innerHeight || document.documentElement.clientHeight) -
                o.offsetTop),
              (s.style.bottom = Math.max(e, 0) + "px");
          }
        } else if (n && !e.nn(T.qi)) {
          const e = t;
          s.contentWindow.addEventListener("keydown", function(t) {
            t.keyCode === at.Vi && e.closeMessage();
          });
        }
        logInAppMessageImpression(t),
          t.dismissType === InAppMessage.DismissType.AUTO_DISMISS &&
            setTimeout(() => {
              a.contains(s) && t.on(s);
            }, t.duration),
          "function" == typeof o && o();
      },
      e.nn(T.lo),
      e.nn(T.no),
      e.nn(T.ao)
    );
  return (n || l) && (a.appendChild(m), t.Yi(a)), !0;
}
