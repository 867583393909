import React, { useState } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import TimePicker from "src/components/elements/TimePicker";

import { getIsAm, getTimePickerObject } from "src/services/work-hours";

export const ComplexTimeSlotsByDay = ({
  workHours,
  day,
  onChange,
  onChangeSlotsCount,
  index,
  hasError,
}) => {
  const [stateWorkHours, setStateWorkHours] = useState(
    getTimePickerObject(workHours, false, index)[index]
  );

  const handleChange = (val, startOrEnd, slotIndex, day) => {
    switch (startOrEnd) {
      case "start":
        stateWorkHours[slotIndex].timeStart = val;
        break;
      case "end":
        stateWorkHours[slotIndex].timeEnd = val;
        break;
    }
    setStateWorkHours([...stateWorkHours]);
    onChange(val, startOrEnd, slotIndex, day);
  };

  const onDeleteTimeSlot = (index, day) => {
    if (stateWorkHours.length == 1) {
      stateWorkHours[0] = {
        ...stateWorkHours[0],
        timeStart: {},
        timeEnd: {},
      };
    } else {
      stateWorkHours.splice(index, 1);
    }
    setStateWorkHours([...stateWorkHours]);
    onChangeSlotsCount("delete", stateWorkHours, index, day);
  };

  const onAddTimeSlot = (index, day) => {
    if (
      Object.keys(stateWorkHours[0]?.timeStart).length === 0 &&
      Object.keys(stateWorkHours[0]?.timeEnd).length === 0
    ) {
      stateWorkHours[0] = {
        ...stateWorkHours[0],
        timeStart: { hours: 9, minutes: 0, isAm: true },
        timeEnd: { hours: 6, minutes: 0, isAm: false },
      };
    } else {
      stateWorkHours.push({
        index,
        day,
        timeStart: {
          hours: stateWorkHours.at(-1).timeEnd.hours,
          minutes: stateWorkHours.at(-1).timeEnd.minutes,
          isAm: stateWorkHours.at(-1).timeEnd.isAm,
        },
        timeEnd: {
          hours:
            stateWorkHours.at(-1).timeEnd.hours == 12
              ? 1
              : stateWorkHours.at(-1).timeEnd.hours + 1,
          minutes: stateWorkHours.at(-1).timeEnd.minutes,
          isAm: getIsAm(stateWorkHours.at(-1).timeEnd),
        },
      });
    }

    setStateWorkHours([...stateWorkHours]);
    onChangeSlotsCount("add", stateWorkHours, index, day);
  };

  const isOverlap = () => {
    return (
      stateWorkHours[0].timeStart.hours ==
        stateWorkHours.at(-1).timeEnd.hours &&
      stateWorkHours[0].timeStart.isAm == stateWorkHours.at(-1).timeEnd.isAm &&
      stateWorkHours.length > 1
    );
  };

  const renderTimeSlotsContent = () => (
    <div>
      {stateWorkHours.map((item, index) => {
        return (
          <div key={index}>
            <div className="flex flex-row justify-between items-center my-[5px]">
              <div className="flex">
                <TimePicker
                  time={stateWorkHours[index].timeStart}
                  className="w-[150px] bg-white [&_.miq-icon]:scale-90"
                  contentType="separated"
                  onUpdate={(value) =>
                    handleChange(value, "start", index, item.day)
                  }
                  hasError={
                    hasError.errorSlotIndex == index &&
                    hasError.errorSlotDay == item.day
                  }
                  icon="auto-classified-work-hours"
                />
                <Icon name="arrow-right" color="black" className="" />
                <TimePicker
                  time={stateWorkHours[index].timeEnd}
                  className="w-[150px] bg-white [&_.miq-icon]:scale-90"
                  contentType="separated"
                  onUpdate={(value) =>
                    handleChange(value, "end", index, item.day)
                  }
                  hasError={
                    hasError.errorSlotIndex == index &&
                    hasError.errorSlotDay == item.day
                  }
                  icon="auto-classified-work-hours"
                />
              </div>
              <div className="grid grid-cols-2 ml-5 w-[53px]">
                <button
                  className="w-6 h-6 rounded-full bg-beige-medium col-start-1 col-end-3"
                  onClick={() => onDeleteTimeSlot(index, item.day)}
                >
                  <Icon name="minus" color="black" />
                </button>
                {isOverlap() ? null : (
                  <button
                    className="w-6 h-6 rounded-full bg-beige-medium col-end-7 col-span-2"
                    onClick={() => onAddTimeSlot(item.index, item.day)}
                  >
                    <Icon name="plus" />
                  </button>
                )}
              </div>
            </div>
            {hasError &&
              hasError.errorSlotDay == item.day &&
              hasError.errorSlotIndex == index && (
                <Text lg color="red" className="ml-1">
                  The slot overlaps with another work shift
                </Text>
              )}
          </div>
        );
      })}
    </div>
  );

  const renderEmptyTimeSlotsContent = () => {
    return (
      <div className="flex items-center">
        <Text lg className="mr-[180px] my-[17px]">
          No Work Hours
        </Text>
        <div className="grid grid-cols-2 ml-5 w-[53px]">
          <button
            className="w-6 h-6 rounded-full bg-beige-medium col-end-7 col-span-2"
            onClick={() =>
              onAddTimeSlot(stateWorkHours[0].index, stateWorkHours[0].day)
            }
          >
            <Icon name="plus" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-[547px] min-h-[60px] bg-beige rounded-[14px] flex items-center pl-5 pr-2.5 my-[10px] py-[5px] justify-between">
      <Text paragraph lg semibold className="w-[90px] capitalize">
        {day}
      </Text>
      {stateWorkHours.length &&
      Object.keys(stateWorkHours[0]?.timeStart).length != 0 &&
      Object.keys(stateWorkHours[0]?.timeEnd).length != 0
        ? renderTimeSlotsContent()
        : renderEmptyTimeSlotsContent()}
    </div>
  );
};
