import React from "react";

import Icon from "src/components/elements/Icon";

export default MiqIconButton;

function MiqIconButton({ className, name, color, testId, ...rest }) {
  return (
    <button
      data-testid={testId || "miq-icon-btn"}
      type="button"
      className={`miq-icon-btn-minimal ${className || ""}`}
      {...rest}
    >
      <Icon name={name} color={color} />
    </button>
  );
}
