import { isAfter } from "date-fns";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import getDate from "date-fns/getDate";
import getDay from "date-fns/getDay";
import getDaysInMonth from "date-fns/getDaysInMonth";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import setDate from "date-fns/setDate";
import setMonth from "date-fns/setMonth";
import setYear from "date-fns/setYear";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

export default MiqCalendar;

const MODES = {
  DAYS: "DAYS",
  MONTHS: "MONTH",
  YEARS: "YEARS",
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DAYS_SHORT = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const MAX_YEAR = 3000;

function BtnPrevNext({ next, disabled, onClick }) {
  return (
    <Button
      secondary
      icon={next ? "chevron-right" : "chevron-left"}
      onClick={onClick}
      disabled={disabled}
      className={disabled ? "opacity-30" : ""}
    />
  );
}

function BtnPrev({ disabled, onClick }) {
  return <BtnPrevNext disabled={disabled} onClick={onClick} />;
}

function BtnNext({ disabled, onClick }) {
  return <BtnPrevNext next disabled={disabled} onClick={onClick} />;
}

function MiqCalendar({ onSelect, selected, minDate, maxDate }) {
  const now = startOfDay(new Date());
  const [currentDate, setCurrentDate] = useState(
    selected && isAfter(selected, minDate) ? startOfDay(selected) : now
  );
  const [mode, setMode] = useState(MODES.DAYS);

  const handleDateSelected = (date) => {
    const newDate = startOfDay(date);
    setCurrentDate(newDate);
    onSelect(newDate);
  };

  const handleMonthSelected = ({ month, year }) => {
    console.log(month, year);
    let newDate = setMonth(currentDate, month);
    newDate = setYear(newDate, year);
    setCurrentDate(newDate);
    setMode(MODES.DAYS);
  };

  const handleYearSelected = ({ year }) => {
    const newDate = setYear(currentDate, year);
    setCurrentDate(newDate);
    setMode(MODES.MONTHS);
  };

  return (
    <div
      className="miq-calendar"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {mode === MODES.DAYS && (
        <DayPicker
          date={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={handleDateSelected}
          onDrillDown={() => setMode(MODES.MONTHS)}
        />
      )}
      {mode === MODES.MONTHS && (
        <MonthPicker
          date={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={handleMonthSelected}
          onDrillDown={() => setMode(MODES.YEARS)}
        />
      )}
      {mode === MODES.YEARS && (
        <YearPicker
          date={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={handleYearSelected}
        />
      )}
    </div>
  );
}

function DayPicker({
  date: selectedDate,
  minDate,
  maxDate,
  onSelect,
  onDrillDown,
}) {
  const now = startOfDay(new Date());
  const _selectedDate = selectedDate ? selectedDate : now;

  const dayNow = getDate(now);
  const monthNow = getMonth(now);
  const yearNow = getYear(now);

  const minDay = minDate ? getDate(minDate) : 0;
  const minMonth = minDate ? getMonth(minDate) : 0;
  const minYear = minDate ? getYear(minDate) : 0;

  const maxDay = maxDate ? getDate(maxDate) : getDaysInMonth(_selectedDate);
  const maxMonth = maxDate ? getMonth(maxDate) : 11;
  const maxYear = maxDate ? getYear(maxDate) : MAX_YEAR;

  const selectedYear = getYear(_selectedDate);
  const selectedMonth = getMonth(_selectedDate);
  const selectedDay = getDate(_selectedDate);

  const [currentDate, setCurrentDate] = useState(_selectedDate);

  const nextMonth = () => {
    const currYear = getYear(currentDate);
    const currMonth = getMonth(currentDate);
    if (currYear >= maxYear && currMonth >= maxMonth) return;
    setCurrentDate(addMonths(currentDate, 1));
  };

  const prevMonth = () => {
    const currYear = getYear(currentDate);
    const currMonth = getMonth(currentDate);
    if (currYear <= minYear && currMonth <= minMonth) return;

    setCurrentDate(addMonths(currentDate, -1));
  };

  const handleDaySelected = (day) => {
    onSelect(setDate(currentDate, day));
  };

  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);

  const monthStartDayOfWeek = getDay(startOfMonth(currentDate));
  const totalDaysInMonth = getDaysInMonth(currentDate);
  let days = new Array(monthStartDayOfWeek).fill(null);
  for (let i = 0; i < totalDaysInMonth; i++) {
    const day = i + 1;
    days.push({
      day,
      isDisabled:
        (currentYear <= minYear && currentMonth <= minMonth && day < minDay) ||
        (currentYear >= maxYear && currentMonth >= maxMonth && day > maxDay),
      isHighlighted:
        day === selectedDay &&
        currentYear === selectedYear &&
        currentMonth === selectedMonth,
      isToday:
        day === dayNow && currentMonth === monthNow && currentYear === yearNow,
    });
  }

  return (
    <>
      <div
        className="flex justify-between items-center mb-5"
        data-testid="calendar"
      >
        <BtnPrev
          onClick={prevMonth}
          disabled={currentMonth <= minMonth && currentYear <= minYear}
        />
        <Button ghost onClick={onDrillDown}>
          {format(currentDate, "MMM yyyy")}
        </Button>
        <BtnNext
          onClick={nextMonth}
          disabled={currentMonth >= maxMonth && currentYear >= maxYear}
        />
      </div>
      <div className="grid grid-cols-[repeat(7,40px)] gap-[3px]">
        {DAYS_SHORT.map((d) => (
          <div
            key={d}
            className="flex items-center justify-center w-[40px] h-[40px] laptop:w-[35px] laptop:h-[35px]"
          >
            <Text md semibold color="black" className="text-center">
              {d}
            </Text>
          </div>
        ))}
        {days.map((d, i) => {
          const key = d ? `${i}-${currentMonth}-${currentYear}` : `empty-${i}`;
          return (
            <div
              key={key}
              className="w-[40px] h-[40px] laptop:w-[35px] laptop:h-[35px]"
            >
              {d ? (
                <Button
                  data-testid="calendar-day"
                  ghost
                  disabled={d.isDisabled}
                  onClick={() => handleDaySelected(d.day)}
                  className={`w-full h-full ${
                    d.isHighlighted
                      ? "bg-blue text-white"
                      : d.isToday
                      ? "border-border-2 border"
                      : ""
                  }`}
                >
                  {d.day}
                </Button>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
}

function MonthPicker({ date, minDate, maxDate, onSelect, onDrillDown }) {
  const now = startOfDay(new Date());
  const monthSelected = getMonth(date ? date : now);
  const yearSelected = getYear(date ? date : now);

  const minYear = minDate ? getYear(minDate) : 0;
  const minMonth = minDate ? getMonth(minDate) : 0;

  const maxMonth = maxDate ? getMonth(maxDate) : 11;
  const maxYear = maxDate ? getYear(maxDate) : MAX_YEAR;

  const [year, setYear] = useState(getYear(date ? date : now));

  const nextYear = () => {
    if (year >= maxYear) return;
    setYear((y) => y + 1);
  };

  const prevYear = () => {
    if (year <= minYear) return;
    setYear((y) => y - 1);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <BtnPrev onClick={prevYear} disabled={year <= minYear} />
        <Button ghost onClick={onDrillDown}>
          {year}
        </Button>
        <BtnNext onClick={nextYear} disabled={year >= maxYear} />
      </div>
      <div className="grid grid-cols-3 gap-2">
        {MONTHS.map((monthName, monthIdx) => {
          return (
            <div key={monthName}>
              <Button
                ghost
                disabled={
                  (year <= minYear && monthIdx < minMonth) ||
                  (year >= maxYear && monthIdx > maxMonth)
                }
                onClick={() => onSelect({ year, month: monthIdx })}
                className={`w-full text-center ${
                  monthIdx === monthSelected && year === yearSelected
                    ? "bg-blue text-white"
                    : ""
                }`}
              >
                {monthName}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function YearPicker({ date, minDate, maxDate, onSelect }) {
  const now = startOfDay(new Date());
  const minYear = minDate ? getYear(minDate) : 0;
  const maxYear = maxDate ? getYear(maxDate) : MAX_YEAR;
  const yearSelected = getYear(date ? date : now);
  const yearsToShow = 12;
  const [offset, setOffset] = useState(0);

  const rangeStart = yearSelected - 4 + offset * yearsToShow;
  const rangeEnd = rangeStart + yearsToShow;

  const prevRange = () => {
    if (rangeStart <= minYear) return;
    setOffset((o) => o - 1);
  };

  const nextRange = () => {
    if (rangeEnd >= maxYear) return;
    setOffset((o) => o + 1);
  };

  const yearsToSelect = [];
  for (let i = 0; i < yearsToShow; i++) {
    yearsToSelect.push(rangeStart + i);
  }

  const range = `${yearsToSelect[0]} - ${yearsToSelect[yearsToShow - 1]}`;

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <BtnPrev onClick={prevRange} disabled={rangeStart <= minYear} />
        <Text>{range}</Text>
        <BtnNext onClick={nextRange} disabled={rangeEnd >= maxYear} />
      </div>
      <div className="grid grid-cols-3 gap-2">
        {yearsToSelect.map((y) => {
          return (
            <div key={y}>
              <Button
                ghost
                disabled={y < minYear || y > maxYear}
                onClick={() => onSelect({ year: y })}
                className={`w-full text-center ${
                  y === yearSelected ? "bg-blue text-white" : ""
                }`}
              >
                {y}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
