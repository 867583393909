import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import {
  DEFAULT_DATE_RANGE,
  useTeamDrivesSummary,
} from "src/components/context/TeamDrivesSummaryContext";

import MobMenu from "src/components/elements/MobMenu";

import { CustomDateRangePickerLabel } from "src/components/blocks/dates/CustomDateRangePickerLabel";
import DateRangePicker, {
  PICKER_TYPE,
} from "src/components/blocks/dates/DateRangePicker";
import FilterDrivers from "src/components/blocks/reports/teams/FilterDrivers";
import TeamDrivesToApproveSummaryList from "src/components/blocks/teams-drives/TeamDrivesToApproveSummaryList";

import PageLayout from "src/components/PageLayout";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { DRIVE_REVIEW_STATUS } from "src/services/teams";
import {
  TeamDrivesDriversFilters,
  trackTeamsDrivesSummaryFiltered,
} from "src/services/tracking";

export default TeamDrivesToApproveSummary;

function TeamDrivesToApproveSummary() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    teamMembers,
    selectedRangeDatesFilter,
    filteredDrivers,
    fetchTeamDrivesSummary,
    page,
    sortBy,
    order,
    hasTeamSummaryLoaded,
    isTeamSummaryLoading,
    isTeamSummaryEmpty,
    teamSummary,
    driverSummaries,
  } = useTeamDrivesSummary();

  useEffect(() => {
    fetchTeamDrivesSummary({
      additionalFilters: {
        review_status: DRIVE_REVIEW_STATUS.PENDING,
      },
    });
  }, [page, sortBy, order, selectedRangeDatesFilter, filteredDrivers]);

  const handleDateRangeFilterSelect = (
    dates,
    datePickerType,
    dateRangeType
  ) => {
    queryParams.set(
      "rangeDates",
      dates.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);
    trackTeamsDrivesSummaryFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      dateFilter:
        datePickerType === PICKER_TYPE.RANGE ? dateRangeType : datePickerType,
    });
    history.replace({ search: queryParams.toString() });
  };

  const handleClearDateRangeFilter = () => {
    queryParams.set(
      "rangeDates",
      DEFAULT_DATE_RANGE.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handleDriverFilterSelect = (filteredDrivers) => {
    if (filteredDrivers.length) {
      queryParams.set(
        "drivers",
        filteredDrivers.map((driver) => driver.id)
      );
    } else {
      queryParams.delete("drivers");
    }

    queryParams.set("page", 1);
    let trackingDriversFilter = TeamDrivesDriversFilters.NONE;
    if (filteredDrivers.length === 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.INDIVIDUAL;
    } else if (filteredDrivers.length > 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.MULTIPLE;
    }

    trackTeamsDrivesSummaryFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driversFilter: trackingDriversFilter,
    });
    history.replace({ search: queryParams.toString() });
  };

  const handleDunningCheck = () => {
    const dunningStatus = checkAndHandleDunning();
    return dunningStatus === DUNNING_STATUS.EXPIRED;
  };

  const totalDrives = teamSummary?.drives?.value || 0;
  const totalDrivesLabel = `${totalDrives} ${
    totalDrives > 1 ? "drives" : "drive"
  }`;

  const totalDrivers = driverSummaries?.total_results || 0;
  const hasMultipleDrivers = totalDrivers > 1;
  const totalDriversLabel = `${totalDrivers} ${
    hasMultipleDrivers ? "drivers" : "driver"
  }`;

  return (
    <PageLayout className="page-drives-to-approve-summary">
      <PageLayout.Main>
        <div className="h-full flex flex-col">
          <div className="p-5 page-header">
            <div className="flex items-center">
              <MobMenu />
              <h3 className="text-28">Drives to approve</h3>
            </div>
            <p className="mt-2 text-black/80 max-w-[80ch]">
              This is a summary of drives submitted by your team that need your
              review. Click on each driver row below to see all business drives
              submitted by that driver. When you approve or reject drives, your
              drivers will <span className="italic">not</span> be notified.
            </p>
            <div className="flex items-center mt-4 gap-3">
              <DateRangePicker
                customLabel={CustomDateRangePickerLabel}
                onOpen={handleDunningCheck}
                onSelect={handleDateRangeFilterSelect}
                onClear={handleClearDateRangeFilter}
                defaultValue={DEFAULT_DATE_RANGE}
                initialValue={selectedRangeDatesFilter?.values}
                defaultColor="white"
                triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
                openTriggerClassName="border-blue hover:border-blue active:border-blue"
              />
              <FilterDrivers
                onOpen={handleDunningCheck}
                selected={filteredDrivers}
                drivers={teamMembers}
                onSelect={handleDriverFilterSelect}
                customLabel="All drivers"
              />
            </div>
          </div>
          <div className="px-5">
            <div className="mt-5">
              {isTeamSummaryLoading && (
                <div className="w-[600px] max-w-full h-[24px] rounded-4 bg-black/10 animate-pulse" />
              )}
              {hasTeamSummaryLoaded && !isTeamSummaryEmpty && (
                <p className="text-16">
                  You have{" "}
                  <span className="font-semibold">{totalDrivesLabel}</span> that
                  needs review from {hasMultipleDrivers ? "these" : "this"}{" "}
                  <span className="font-semibold">{totalDriversLabel}</span> in
                  this time period.
                </p>
              )}
            </div>
            <div className="mt-5 rounded-[14px] border border-border-1 shadow-sm">
              <TeamDrivesToApproveSummaryList />
            </div>
          </div>
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
}
