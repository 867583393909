import "src/models/typings";

import { NetworkStatus, useQuery } from "@apollo/client";
import { endOfMonth, format, getYear, startOfMonth } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import DateRangePicker from "src/components/blocks/dates/DateRangePicker";
import CreateReport from "src/components/blocks/reports/CreateReport";
import ClassificationDropdown, {
  Categories,
} from "src/components/blocks/reports/drives/ClassificationDropdown";
import VehicleDropdown from "src/components/blocks/reports/drives/VehicleDropdown";

import { ELEMENT_ID as DELETE_ARCHIVED_REPORT } from "src/components/modals/DeleteArchivedReport";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";

import { PURPOSE_CATEGORY } from "src/models/purpose";

import {
  DownloadFormats,
  Pages,
  ReportCreationFailure,
  trackReportCreationCompleted,
  trackReportCreationFailed,
  trackReportCreationStarted,
  trackReportDeleted,
  trackReportDownloaded,
  trackReportsFiltered,
} from "src/services/tracking";
import {
  COUNTRIES_DATA,
  formatCurrency,
  round,
  roundDistance,
  toUIDistance,
} from "src/services/utils";

import {
  GET_DRIVES_QUERY,
  GET_INTEGRATION_IDENTITY,
  GET_REPORT_SUBMISSIONS,
  LIST_INTEGRATIONS,
} from "src/graphql/queries";

export default ReportsPage;

const DEFAULT_DATE_TYPE = "month";
const getDefaultDate = ({ shouldExcludeFutureDrives = false } = {}) => {
  const now = new Date();
  const startDate = startOfMonth(now);
  const endDate = shouldExcludeFutureDrives ? now : endOfMonth(now);

  return [startDate, endDate];
};

function ReportsPage() {
  const { userData } = useContext(UserDataContext);
  /**  @type {{team: Team}} */
  const { team } = useContext(TeamContext);

  const { miqExcludeFutureDrivesAllPlatforms: shouldExcludeFutureDrives } =
    useFlags();

  const [notificationMessage, setNotificationMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    getDefaultDate({ shouldExcludeFutureDrives })
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [datePickerType, setDatePickerType] = useState(DEFAULT_DATE_TYPE);
  const [dateRangeType, setDateRangeType] = useState(null);
  const [startDate, endDate] = selectedDate;
  const [flash, Flash] = useFlash();
  const location = useLocation();
  const drivesListQuery = useQuery(GET_DRIVES_QUERY, {
    variables: {
      userMetric: true,
      isReport: true,
      categories: [PURPOSE_CATEGORY.PERSONAL, PURPOSE_CATEGORY.BUSINESS],
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
    notifyOnNetworkStatusChange: true,
  });

  const reportSubmissionsQuery = useQuery(GET_REPORT_SUBMISSIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { data: integrationsData, loading: loadingIntegrations } = useQuery(
    LIST_INTEGRATIONS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const { data: identityData, loading: loadingIdentity } = useQuery(
    GET_INTEGRATION_IDENTITY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        integrationType: "concur",
      },
    }
  );

  const _updateFilters = ({
    dates,
    dateType,
    rangeType = null,
    vehicles,
    category,
  }) => {
    const filters = {
      dateRange: {
        type: datePickerType === "range" ? dateRangeType : datePickerType,
      },
      vehicles: selectedVehicles,
      category: selectedCategory,
    };
    if (typeof dates !== "undefined") {
      let [startDate, endDate] = dates;

      if (shouldExcludeFutureDrives) {
        const now = new Date();
        if (endDate > now) {
          endDate = now;
        }
      }

      setSelectedDate([startDate, endDate]);
    }
    if (typeof dateType !== "undefined") {
      setDatePickerType(dateType);
      setDateRangeType(rangeType);
      filters.dateRange.type = dateType === "range" ? rangeType : dateType;
    }

    if (typeof vehicles !== "undefined") {
      setSelectedVehicles(vehicles);
      filters.vehicles = vehicles;
    }

    if (typeof category !== "undefined") {
      setSelectedCategory(category);
      filters.category = category;
    }
    trackReportsFiltered({
      filters,
      subscriptionId: userData?.subscriptionType,
      orgId: team?.orgId,
      orgGroupId: team?.orgGroupId,
    });
  };

  const handleSelectDateFilter = (dates, dateType, rangeType) => {
    _updateFilters({
      dates,
      dateType,
      rangeType,
    });
  };

  const handleDateFilterCleared = () => {
    _updateFilters({
      dates: getDefaultDate({ shouldExcludeFutureDrives }),
      dateType: DEFAULT_DATE_TYPE,
      rangeType: null,
    });
  };

  const handleSelectCategoryFilter = (category) => {
    _updateFilters({ category });
  };

  const handleSelectVehicleFilter = (vehicles) => {
    _updateFilters({ vehicles });
  };

  const isIdentitySet = Boolean(
    identityData?.integrationIdentity?.identity?.identity
  );

  const isTeamConcurEnabled =
    integrationsData?.integrations?.integrations?.some(
      (i) => i.type === "concur"
    );

  useEffect(() => {
    drivesListQuery.refetch({
      isReport: true,
      categories: selectedCategory || [
        PURPOSE_CATEGORY.PERSONAL,
        PURPOSE_CATEGORY.BUSINESS,
      ],
      vehicles: selectedVehicles?.map((v) => v.id),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }, [startDate, endDate, selectedCategory, selectedVehicles]);

  useEffect(() => {
    const processingReports =
      reportSubmissionsQuery?.data?.reportSubmissions?.processing.length;

    if (
      processingReports > 0 &&
      reportSubmissionsQuery.status !== NetworkStatus.poll
    ) {
      reportSubmissionsQuery.startPolling(1000);
    }

    if (processingReports === 0) {
      reportSubmissionsQuery.stopPolling();
    }
  }, [
    JSON.stringify(reportSubmissionsQuery?.data?.reportSubmissions?.processing),
  ]);

  useEffect(() => {
    if (notificationMessage || location.state?.showReportMessage) {
      flash(
        <Text>{notificationMessage || location.state?.showReportMessage}</Text>,
        {
          type: FlashTypes.SAVED,
        }
      );
      if (location.state?.showReportMessage)
        window.history.replaceState({ showReportMessage: "" }, "");
    }
  }, [
    location.state?.showReportMessage,
    location.state?.error,
    notificationMessage,
  ]);

  const drives = drivesListQuery.data?.drives;

  let businessDrivesCount = 0;
  let personalDrivesCount = 0;

  drives?.forEach((d) => {
    if (d.purpose.category === PURPOSE_CATEGORY.PERSONAL) {
      personalDrivesCount++;
    } else if (d.purpose.category === PURPOSE_CATEGORY.BUSINESS) {
      businessDrivesCount++;
    }
  });

  const hasBusinessDrives = businessDrivesCount > 0;

  return (
    <PageLayout className="page-user-drives">
      <PageLayout.Main className="flex flex-col">
        <div className="reports" data-testid="reports">
          <div className="page-header relative">
            <div className="title-row flex items-center justify-between ">
              <div>
                <div className="flex items-center">
                  <MobMenu />
                  <h3>Reports</h3>
                </div>
                <Text paragraph color="black/70 text-[15px]" className="mt-1">
                  Select filters below to create a report
                </Text>
              </div>
            </div>
            <div className="mt-4 gap-2 inline-flex">
              <DateRangePicker
                onSelect={handleSelectDateFilter}
                value={selectedDate}
                onClear={handleDateFilterCleared}
              />
              <ClassificationDropdown onSelect={handleSelectCategoryFilter} />
              <VehicleDropdown
                vehicles={userData?.vehicles || []}
                onSelect={handleSelectVehicleFilter}
              />
            </div>
            {drivesListQuery.loading ? (
              <Loader className="p-8" />
            ) : (
              <div className="summary-container">
                <div className="flex">
                  <div className="flex flex-col gap-2">
                    <ReportTitle
                      startDate={startDate}
                      endDate={endDate}
                      datePickerType={datePickerType}
                      selectedCategory={selectedCategory}
                      selectedVehicles={selectedVehicles}
                    />
                  </div>
                  <div className="ml-auto">
                    <CreateReport
                      hasBusinessDrives={hasBusinessDrives}
                      onStart={() => {
                        trackReportCreationStarted({
                          page: Pages.REPORTS,
                          businessDrivesCount,
                          personalDrivesCount,
                          subscriptionId: userData.subscriptionType,
                          orgId: team?.orgId,
                          orgGroupId: team?.orgGroupId,
                        });
                      }}
                      onFinish={(
                        success,
                        msg,
                        { recipientType, contactRelationship, markAsReported }
                      ) => {
                        if (success) {
                          reportSubmissionsQuery.refetch();
                          setNotificationMessage(msg);
                          trackReportCreationCompleted({
                            page: Pages.REPORTS,
                            businessDrivesCount,
                            personalDrivesCount,
                            subscriptionId: userData.subscriptionType,
                            orgId: team?.orgId,
                            orgGroupId: team?.orgGroupId,
                            recipientType,
                            contactRelationship,
                            markAsReported,
                          });
                        } else {
                          trackReportCreationFailed({
                            page: Pages.REPORTS,
                            subscriptionId: userData.subscriptionType,
                            orgId: team?.orgId,
                            orgGroupId: team?.orgGroupId,
                            reportCreationFailure:
                              ReportCreationFailure.SOMETHING_WENT_WRONG,
                          });
                        }
                      }}
                      reportData={{
                        dateFrom: startDate,
                        dateTo: endDate,
                        vehicleIds: selectedVehicles?.map((v) => v.id),
                        categoryId: selectedCategory,
                      }}
                      disabled={drives?.length === 0}
                      icon="file-medical"
                      primary
                      className="w-full relative font-medium"
                    >
                      Create this report
                    </CreateReport>
                  </div>
                </div>
                <SummaryContainer
                  drives={drives}
                  unit={userData.distanceUnit}
                  currency={userData.currency}
                />
              </div>
            )}
          </div>
          {drivesListQuery.loading ||
          loadingIntegrations ||
          loadingIdentity ||
          [NetworkStatus.loading, NetworkStatus.refetch].includes(
            reportSubmissionsQuery.networkStatus
          ) ? (
            <Loader className="p-8" />
          ) : (
            <ArchivedReportContainer
              isIdentitySet={isIdentitySet}
              isTeamConcurEnabled={isTeamConcurEnabled}
              currency={userData.currency}
              country={userData.country}
              flash={flash}
              processingReports={
                reportSubmissionsQuery.data?.reportSubmissions?.processing
              }
              completedReports={
                reportSubmissionsQuery.data?.reportSubmissions?.completed
              }
            />
          )}
        </div>
      </PageLayout.Main>
      {Flash}
    </PageLayout>
  );
}
const ReportTitle = ({
  startDate,
  endDate,
  selectedCategory,
  datePickerType,
  selectedVehicles,
}) => {
  const fullMonthsDateFormats = {
    month: ["MMMM d", "MMMM d, yyyy"],
    custom: ["MMMM d", "MMMM d, yyyy"],
    range: ["MMMM d", "MMMM d, yyyy"],
  };

  const shortMonthsTitleDateFormats = {
    month: ["MMM d", "MMM d, yyyy"],
    custom: ["MMM d", "MMM d, yyyy"],
    range: ["MMM d", "MMM d, yyyy"],
  };

  const getDateFormats = (formats) => {
    if (datePickerType === "month") {
      if (getYear(startDate) === getYear(new Date()))
        return `${format(startDate, formats.month[0])} - ${format(
          endDate,
          formats.month[0]
        )}`;
      else {
        return `${format(startDate, formats.month[1])} - ${format(
          endDate,
          formats.month[1]
        )}`;
      }
    } else if (["custom", "range"].includes(datePickerType)) {
      if (getYear(startDate) === getYear(endDate)) {
        return `${format(startDate, formats.custom[0])} - ${format(
          endDate,
          formats.custom[0]
        )}`;
      } else {
        return `${format(startDate, formats.range[1])} - ${format(
          endDate,
          formats.range[1]
        )}`;
      }
    }
  };

  const getSelectedVehicles = () => {
    if (selectedVehicles?.length === 1) {
      return selectedVehicles[0].label;
    } else if (selectedVehicles?.length > 1) {
      const hasUnassignedVehicle = selectedVehicles.some(
        (v) => v.label === "Unassigned"
      );
      if (hasUnassignedVehicle)
        return `${selectedVehicles.length - 1} vehicles + Unassigned`;
      else return `${selectedVehicles.length} vehicles`;
    }

    return null;
  };

  return (
    <>
      <h5>{getDateFormats(fullMonthsDateFormats)}</h5>
      <Text color="black/70">
        {`Drive range: ${getDateFormats(shortMonthsTitleDateFormats)} • ${
          Categories[selectedCategory] || "All drives"
        } • ${getSelectedVehicles() || "Any vehicle"}`}
      </Text>
    </>
  );
};

const EXPENSE_SERVICE_MAP = {
  concur: {
    icon: "concur",
    label: "Concur Report",
  },
  email: {
    icon: "file-edit",
    label: "Custom Report",
  },
};

const ArchivedReport = ({
  submissionId,
  docName,
  docObjectKey,
  pdfObjectKey,
  reportName,
  startDate,
  endDate,
  submissionDate,
  totalDrives,
  value,
  distance,
  flash,
  loading,
  unit,
  currency,
  country,
  expenseService,
}) => {
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);

  const delArchivedReportModal = useElement(DELETE_ARCHIVED_REPORT, {
    props: {
      onDelete: () => {
        trackReportDeleted({
          subscriptionId: userData.subscriptionType,
          orgId: team?.orgId,
          orgGroupId: team?.orgGroupId,
        });
      },
      onClose: () => {
        delArchivedReportModal.deactivate();
      },
      submissionId,
      reportName,
      flash,
    },
  });

  const formatDate = (startDate, endDate) => {
    const shortMonthsTitleDateFormats = {
      month: ["MMM d", "MMM d, yyyy"],
      custom: ["MMM d", "MMM d, yyyy"],
      range: ["MMM d", "MMM d, yyyy"],
    };

    if (getYear(startDate) === getYear(new Date()))
      return `${format(
        startDate,
        shortMonthsTitleDateFormats.month[0]
      )} - ${format(endDate, shortMonthsTitleDateFormats.month[0])}`;
    else {
      return `${format(
        startDate,
        shortMonthsTitleDateFormats.month[1]
      )} - ${format(endDate, shortMonthsTitleDateFormats.month[1])}`;
    }
  };

  const expenseServiceData = EXPENSE_SERVICE_MAP[expenseService];

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  const isReportOlderThan90Days = (submissionDate) => {
    const today = new Date();
    const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 90));
    return new Date(submissionDate) < ninetyDaysAgo;
  };

  const downloadCsv = (docObjectKey) => {
    fetch(docObjectKey)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
        const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");

        a.download = `MileIQ_${reportName}_${formattedStartDate}_${formattedEndDate}.${docName.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <div className="report" data-testid="archived-report">
      {loading ? (
        <div className="h-[88%] relative">
          <Loader />
          <div className="text-center w-full top-[70%] absolute">
            <Text lg bold center>
              Generating report
            </Text>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-baseline gap-2">
            <Text custom className="truncate font-bold leading-[21px]">
              {reportName}
            </Text>
            <IconButton
              data-testid="delete-archived-report"
              onClick={() => delArchivedReportModal.activate()}
              name="trash-alt"
              className="opacity-[.4]"
            />
          </div>
          <Text color="black/70 leading-[21px]">
            {formatDate(new Date(startDate), new Date(endDate))}
          </Text>
          <div className="min-h-[24px]">
            {expenseServiceData && (
              <div className="flex items-center">
                <Icon
                  className="scale-75 -ml-[5.5px]"
                  name={expenseServiceData.icon}
                  color="black-op50"
                />
                <Text md semibold color="black/50">
                  {expenseServiceData.label}
                </Text>
              </div>
            )}
          </div>
          <div className="mt-4">
            <div className="pb-2 flex">
              <h5 className="text-green">
                {formatCurrency({ value, currency })}
              </h5>
            </div>
            <div className="drive-top-border py-[7px] laptop:py-[6px] flex justify-between">
              <Text>Drives</Text>
              <Text>{totalDrives}</Text>
            </div>
            <div className="drive-top-border drive-bottom-border py-[7px] laptop:py-[6px] flex justify-between">
              <Text>
                {unit === "mi" ? "Miles" : COUNTRIES_DATA[country].unitName}
              </Text>
              <Text>{toUIDistance(roundDistance(distance), unit)}</Text>
            </div>
            <div className="drive-top-border drive-bottom-border py-[7px] laptop:py-[6px] flex justify-between">
              <Text>Average</Text>
              <Text>
                {formatCurrency({
                  value: round(value / totalDrives, 2),
                  currency,
                })}
              </Text>
            </div>
            <div className="mt-[15px]">
              <Text color="black/70">
                {format(new Date(submissionDate), "MMM dd, yyyy")}
              </Text>
            </div>
            <div className="grid grid-cols-2 gap-2 items-center mt-[15px]">
              {isReportOlderThan90Days(submissionDate) ? (
                <a disabled className="miq-btn miq-btn-secondary font-medium">
                  {docName}
                </a>
              ) : (
                <a
                  download
                  href={docObjectKey}
                  className="miq-btn miq-btn-secondary font-medium"
                  onClick={(e) => {
                    if (isSafari()) {
                      e.preventDefault();
                      downloadCsv(docObjectKey);
                    }
                    trackReportDownloaded({
                      subscriptionId: userData.subscriptionType,
                      orgId: team?.orgId,
                      orgGroupId: team?.orgGroupId,
                      downloadFormat:
                        docName?.toUpperCase() === "XLS"
                          ? DownloadFormats.XLS
                          : DownloadFormats.CSV,
                    });
                  }}
                >
                  {docName}
                </a>
              )}

              {isReportOlderThan90Days(submissionDate) ? (
                <a disabled className="miq-btn miq-btn-secondary font-medium">
                  PDF
                </a>
              ) : (
                <a
                  download
                  target="_blank"
                  href={pdfObjectKey}
                  className="miq-btn miq-btn-secondary font-medium"
                  onClick={() => {
                    trackReportDownloaded({
                      subscriptionId: userData.subscriptionType,
                      orgId: team?.orgId,
                      orgGroupId: team?.orgGroupId,
                      downloadFormat: DownloadFormats.PDF,
                    });
                  }}
                >
                  PDF
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Summary = ({
  unit,
  currency,
  totalValue = 0,
  totalDrives = 0,
  totalDistance = 0,
  totalParking = 0,
  totalTolls = 0,
}) => (
  <div className="summary flex gap-[15px] mt-7" data-testid="summary">
    <div className="detail">
      <div className="title">
        <Icon name="money-bill" />
        <Text>Total value</Text>
      </div>
      <h5 className="text-green">
        {formatCurrency({ value: totalValue, currency })}
      </h5>
    </div>
    <div className="detail">
      <div className="title">
        <Icon name="car" />
        <Text>Drives</Text>
      </div>
      <h5>{totalDrives}</h5>
    </div>
    <div className="detail">
      <div className="title">
        <Icon name="arrows-h" />
        <Text>Distance</Text>
      </div>
      <h5>
        {toUIDistance(roundDistance(totalDistance), unit)} {unit}
      </h5>
    </div>
    <div className="detail">
      <div className="title">
        <Icon name="parking-square" />
        <Text>Parking</Text>
      </div>
      <h5>{formatCurrency({ value: totalParking, currency })}</h5>
    </div>
    <div className="detail">
      <div className="title">
        <Icon name="tolls" />
        <Text>Tolls</Text>
      </div>
      <h5>{formatCurrency({ value: totalTolls, currency })}</h5>
    </div>
  </div>
);

export const SummaryContainer = ({ drives, unit, currency }) => {
  const sum = (field) => drives?.reduce((acc, next) => acc + next[field], 0);
  const parking = sum("parkingFees");
  const tolls = sum("tollFees");
  return (
    <>
      {drives?.length > 0 ? (
        <Summary
          unit={unit}
          currency={currency}
          totalValue={sum("value") + parking + tolls}
          totalDrives={drives?.length}
          totalDistance={sum("googleDistance")}
          totalParking={parking}
          totalTolls={tolls}
        />
      ) : (
        <div
          className="mt-[35px] flex items-center gap-6"
          data-testid="no-drives"
        >
          <Icon name="warning-car" />
          <Text>No drives to report with the above criteria</Text>
        </div>
      )}
    </>
  );
};
export const ArchivedReportContainer = ({
  currency,
  country,
  processingReports,
  completedReports,
  flash,
  isTeamConcurEnabled,
  isIdentitySet,
}) => {
  return (
    <>
      {processingReports?.length > 0 || completedReports?.length > 0 ? (
        <div className="p-[20px] laptop:p-[15px] flex flex-col flex-wrap">
          <div className="leading-[21px]">
            <Text bold>Archived Reports</Text>
            <Text paragraph color="black/70">
              Please note: Archived Reports expire after 90 days and will need
              to be recreated.
            </Text>
            {isTeamConcurEnabled && isIdentitySet && (
              <Text paragraph color="black/70">
                If too many people in your company are submitting reports
                simultaneously, you may receive a reporting error. If so, please
                try submitting 250 drives at a time.
              </Text>
            )}
          </div>
          <div className="archived-reports">
            {processingReports?.map((report) => (
              <ArchivedReport key={report.submissionEventKey} loading />
            ))}
            {completedReports?.map((report) => (
              <ArchivedReport
                currency={currency}
                country={country}
                unit={report.unit}
                key={report.submissionId}
                docName={report.docName}
                docObjectKey={report.docObjectKey}
                pdfObjectKey={report.pdfObjectKey}
                submissionId={report.submissionId}
                submissionDate={report.submissionDate}
                reportName={report.reportName}
                startDate={report.startDate}
                endDate={report.endDate}
                value={report.driveValue}
                totalDrives={report.driveCount}
                distance={report.distance}
                expenseService={report.expenseService}
                flash={flash}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[450px] gap-2 ">
          <Icon name="sleeping-car" className="mb-6" />
          <Text className="text-18" bold>
            You don't have any past reports to view
          </Text>
          <Text>Use the report builder above to create your next report.</Text>
        </div>
      )}
    </>
  );
};
