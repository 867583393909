import React, { createContext, useContext, useState } from "react";

import {
  eventPages,
  teamsUserSelectionActions,
  teamsUserSelectionTypes,
  trackUserSelected,
} from "src/services/tracking";

import TeamContext from "./TeamContext";

const UsersContext = createContext({ selectedUsers: new Map() });

const KEY = "email";

export function useUsers() {
  return useContext(UsersContext);
}

export const UsersProvider = ({ children }) => {
  const { team } = useContext(TeamContext);
  const [selectedUsers, setSelectedUsers] = useState(new Map());

  const toggleUser = (user) => {
    const newSelectedUsers = new Map(selectedUsers);
    let userSelectionAction = teamsUserSelectionActions.SELECT;

    if (newSelectedUsers.has(user[KEY])) {
      newSelectedUsers.delete(user[KEY]);
      userSelectionAction = teamsUserSelectionActions.UNSELECT;
    } else {
      newSelectedUsers.set(user[KEY], user);
    }

    trackUserSelected({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      userSelectionAction,
      userSelectionType: teamsUserSelectionTypes.INDIVIDUAL,
      page: eventPages.USERS,
    });

    setSelectedUsers(newSelectedUsers);
  };

  const toggleUsers = (users) => {
    const newSelected = new Map(selectedUsers);
    let userSelectionAction = teamsUserSelectionActions.SELECT;

    if (users.some(isUserSelected)) {
      users.forEach((user) => newSelected.delete(user[KEY], user));
      userSelectionAction = teamsUserSelectionActions.UNSELECT;
    } else {
      users.forEach((user) => newSelected.set(user[KEY], user));
    }

    trackUserSelected({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      userSelectionAction,
      userSelectionType: teamsUserSelectionTypes.BULK,
      page: eventPages.USERS,
    });

    setSelectedUsers(newSelected);
  };

  const selectUser = (user) => {
    const newSelectedUsers = new Map(selectedUsers);
    newSelectedUsers.set(user[KEY], user);
    setSelectedUsers(newSelectedUsers);
  };

  const removeUser = (user) => {
    const newSelectedUsers = new Map(selectedUsers);
    newSelectedUsers.delete(user[KEY]);
    setSelectedUsers(newSelectedUsers);
  };

  const clearSelectedUsers = () => {
    setSelectedUsers(new Map());
  };

  const isUserSelected = (user) => {
    return selectedUsers.has(user[KEY]);
  };

  const areAllUsersSelected = (users) => {
    return users.every(isUserSelected);
  };

  const isAnyUserSelected = (users) => {
    return users.some(isUserSelected);
  };

  return (
    <UsersContext.Provider
      value={{
        selectedUsers,
        isUserSelected,
        toggleUser,
        toggleUsers,
        selectUser,
        removeUser,
        clearSelectedUsers,
        areAllUsersSelected,
        isAnyUserSelected,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
