import { useMutation } from "@apollo/client";
import { Button, FormControl, PasswordInput } from "@mileiq/design-system";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "src/components/context/UserContext";

import MiqCheckbox from "src/components/elements/Checkbox";
import Icon from "src/components/elements/Icon";

import useQueryParams from "src/hooks/useQueryParams";

import { setAccessToken, setRefreshToken } from "src/services/storage";
import {
  trackLoginCompleted,
  trackLoginFailed,
  trackPasswordResetStarted,
} from "src/services/tracking";
import { parseJwt, prepRedirectUrlFromSearchParams } from "src/services/utils";

import { LOGIN_WITH_CREDENTIALS } from "src/graphql/mutations";

export function LoginPasswordStep() {
  const inpRef = useRef();
  const history = useHistory();
  const searchParams = useQueryParams();
  const forgotPasswordSearchParams = new URLSearchParams(searchParams);
  forgotPasswordSearchParams.set(
    "username",
    forgotPasswordSearchParams.get("email")
  );
  forgotPasswordSearchParams.delete("email");

  const [remember, setRemember] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [password, setPassword] = useState("");
  const [showAttemptLimitWarning, setShowAttemptLimitWarning] = useState(false);

  const { refreshUser } = useUser();

  const email = searchParams?.get?.("email");

  const [login, { loading }] = useMutation(LOGIN_WITH_CREDENTIALS, {
    notifyOnNetworkStatusChange: true,
  });

  const focusInput = () => {
    setTimeout(() => inpRef.current?.focus(), 100);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    if (password?.trim() === "") {
      trackLoginFailed({ email });
      return setErrorMessage("Please enter a password");
    }

    try {
      const {
        data: {
          login: { code, token, refreshToken },
        },
      } = await login({
        variables: {
          username: email.toLowerCase(),
          password,
        },
      });

      if (code === 200) {
        // Get parseId
        const { sub } = parseJwt(token);
        trackLoginCompleted({ multiSession: !!remember, userId: sub });
        setAccessToken(token);
        setRefreshToken(refreshToken);

        await refreshUser();

        const url = prepRedirectUrlFromSearchParams(searchParams, "/");
        return window.location.replace(url);
      }

      if (code === 401) {
        trackLoginFailed({ email });
        setErrorMessage("Looks like you entered an incorrect password");
        focusInput();
      }

      if (code === 400 || code === 403) {
        setShowAttemptLimitWarning(true);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("Something went wrong. Please try again later");
      focusInput();
    }
  };

  const handlePasswordChange = (password) => {
    setErrorMessage(null);
    setPassword(password);
  };

  const handleBackClick = () => history.goBack();

  return (
    <>
      <div className="flex cursor-pointer" onClick={handleBackClick}>
        <Icon name="arrow-left" />
        <span className="ml-3 text-black/70 font-medium">{email}</span>
      </div>
      <h2 className="mt-16">Enter password</h2>
      <form onSubmit={handleSubmit} noValidate>
        {showAttemptLimitWarning && (
          <div className="flex items-center gap-3 bg-red-pastel rounded-10 p-6 mt-5 text-left">
            <Icon name="alert-warning" color="red" />
            <div>
              <p>
                This account has been locked because of too many failed login
                attempts. To unlock it, please{" "}
                <a
                  href={`/forgot-password?${forgotPasswordSearchParams.toString()}`}
                  onClick={() => trackPasswordResetStarted({ email })}
                  className="text-blue hover:text-blue-hover underline font-medium"
                >
                  {" "}
                  reset your password.
                </a>
              </p>
            </div>
          </div>
        )}
        <div
          className={
            showAttemptLimitWarning
              ? "opacity-40 pointer-events-none"
              : "opacity-100"
          }
        >
          <div className={showAttemptLimitWarning ? "mt-5" : "mt-12"}>
            <FormControl error={errorMessage}>
              <PasswordInput
                autoFocus
                valid={!errorMessage}
                placeholder="Password"
                onChange={handlePasswordChange}
                value={password}
                disabled={loading}
                data-testid="login-password-input"
                ref={inpRef}
              />
            </FormControl>
          </div>
          <label className="flex mt-5">
            <MiqCheckbox checked={remember} onChange={setRemember} /> Remember
            me
          </label>
          <div className="mt-8" style={{ "--button-width": "100%" }}>
            <Button
              label="Log in"
              type="submit"
              loading={loading}
              data-testid="login-btn"
            />
          </div>
        </div>
      </form>
      <div className="mt-12">
        <a
          href={`/forgot-password?${forgotPasswordSearchParams.toString()}`}
          onClick={() => trackPasswordResetStarted({ email })}
          className="text-blue hover:text-blue-hover underline font-medium"
        >
          Forgot Password
        </a>
      </div>
    </>
  );
}
