import { rehydrateDateAfterJsonization as w } from "../util/date-utils.js";
export default class ei {
  constructor(t, i, l, s, u) {
    (this.endpoint = t || null),
      (this.Dn = i || null),
      (this.publicKey = l || null),
      (this.zl = s || null),
      (this.gl = u || null);
  }
  ss() {
    return {
      e: this.endpoint,
      c: this.Dn,
      p: this.publicKey,
      u: this.zl,
      v: this.gl
    };
  }
  static Sn(t) {
    return new ei(t.e, w(t.c), t.p, t.u, t.v);
  }
}
