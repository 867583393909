import G, { STORAGE_KEYS as o } from "./storage-manager.js";
import r from "../../shared-lib/braze-shared-lib.js";
const Mt = {
  Fo: (e, t) => {
    let a = !1;
    try {
      if (localStorage && localStorage.getItem)
        try {
          localStorage.setItem(o.q.Sa, !0),
            localStorage.getItem(o.q.Sa) &&
              (localStorage.removeItem(o.q.Sa), (a = !0));
        } catch (e) {
          if (
            ("QuotaExceededError" !== e.name &&
              "NS_ERROR_DOM_QUOTA_REACHED" !== e.name) ||
            !(localStorage.length > 0)
          )
            throw e;
          a = !0;
        }
    } catch (e) {
      r.D.info("Local Storage not supported!");
    }
    const l = Mt.Ea(),
      c = new G.wa(e, l && !t, a);
    let n = null;
    return (n = a ? new G._a(e) : new G.Oa()), new G(c, n);
  },
  Ea: () =>
    navigator.cookieEnabled ||
    ("cookie" in document &&
      (document.cookie.length > 0 ||
        (document.cookie = "test").indexOf.call(document.cookie, "test") > -1))
};
export default Mt;
