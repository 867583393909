import React from "react";

import DropdownFilter from "src/components/elements/DropdownFilter";

import { PURPOSE_IDS } from "src/services/purposes";

const TeamDrivesPurposeFilter = ({
  purposeFilter,
  handleOpenFilters,
  handlePurposeFilterSelect,
}) => {
  return (
    <DropdownFilter
      icon="tag"
      label="All purposes"
      value={purposeFilter}
      onOpen={handleOpenFilters}
      onSelect={handlePurposeFilterSelect}
      options={[
        {
          label: "All purposes",
          value: null,
          default: true,
        },
        {
          label: "Business",
          value: PURPOSE_IDS.BUSINESS,
        },
        {
          label: "Business + Commute",
          value: PURPOSE_IDS.BUSINESS_PLUS_COMMUTE,
        },
        {
          label: "Between Offices",
          value: PURPOSE_IDS.BETWEEN_OFFICES,
        },
        {
          label: "Customer Visit",
          value: "Customer Visit",
        },
        {
          label: "Meeting",
          value: PURPOSE_IDS.MEETING,
        },
        {
          label: "Errand/Supplies",
          value: PURPOSE_IDS.ERRAND_SUPPLIES,
        },
        {
          label: "Meal/Entertain",
          value: PURPOSE_IDS.MEAL_ENTERTAIN,
        },
        {
          label: "Temporary Site",
          value: PURPOSE_IDS.TEMPORARY_SITE,
        },
        {
          label: "Airport/Travel",
          value: PURPOSE_IDS.AIRPORT_TRAVEL,
        },
        {
          label: "Other purposes",
          value: "Other",
        },
      ]}
    />
  );
};

export default TeamDrivesPurposeFilter;
