import { initializeApp } from "firebase/app";
import {
  OAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";

import { loginWithFirebase } from "./auth";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const providers = {
  microsoft: new OAuthProvider("microsoft.com"),
};

const auth = getAuth();

let firebaseUser = null;

auth.onAuthStateChanged((user) => {
  firebaseUser = user;
});

export const getFirebaseUser = () => firebaseUser;

export const signInWithMicrosoft = (email) => {
  const provider = providers.microsoft;
  provider.setCustomParameters({ login_hint: email });
  return signInWithRedirect(auth, provider);
};

export const getAuthRedirectResult = async () =>
  getRedirectResult(auth).then(async (result) => {
    if (!result) return;

    return await loginWithFirebase(
      result.user.accessToken,
      result.user.email,
      "getAuthRedirectResult"
    );
  });
