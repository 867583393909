import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";

import Flash from "src/components/elements/Flash";

const flashPortal = document.querySelector("#portal-flash");

export default function useFlash() {
  const [msg, setMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(null);

  const hideFlashTimeout = useRef(null);
  const hideFlashTextTimeout = useRef(null);

  const _flash = (msg, { type, hideAfterMs, onHide, ignoreTimeout } = {}) => {
    // clear timeouts set by prev calls so it won't close or clear text in new flash unexpectedly
    clearTimeout(hideFlashTimeout.current);
    clearTimeout(hideFlashTextTimeout.current);

    const t = hideAfterMs || 5000;

    const hideFlash = () => {
      setVisible(false); // starts css animation

      hideFlashTextTimeout.current = setTimeout(() => {
        setMsg(null); // unset msg after css animation
        if (typeof onHide === "function") onHide();
      }, 1000);
    };

    setMsg(typeof msg === "function" ? msg(hideFlash) : msg);
    setVisible(true);
    setType(type);

    if (!ignoreTimeout) {
      hideFlashTimeout.current = setTimeout(() => {
        hideFlash();
      }, t);
    }
  };

  return [
    _flash,
    createPortal(
      <Flash visible={visible} msg={msg} type={type} />,
      flashPortal
    ),
  ];
}
