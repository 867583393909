import React from "react";

import TabButton from "src/components/elements/TabButton";

import { PURPOSE_CATEGORY } from "src/models/purpose";

export default Tabs;

export const TABS = {
  ALL: "ALL",
  UNCLASSIFIED: "UNCLASSIFIED",
  BUSINESS: "BUSINESS",
  PERSONAL: "PERSONAL",
};

export const TABS_DATA = [
  {
    id: TABS.ALL,
    label: "All",
    filter: (d) => d,
    categories: [
      PURPOSE_CATEGORY.UNCLASSIFIED,
      PURPOSE_CATEGORY.PERSONAL,
      PURPOSE_CATEGORY.BUSINESS,
    ],
  },
  {
    id: TABS.UNCLASSIFIED,
    label: "Unclassified",
    icon: "alert-octagon",
    filter: (d) => d.isUnclassified,
    categories: [PURPOSE_CATEGORY.UNCLASSIFIED],
  },
  {
    id: TABS.BUSINESS,
    label: "Business",
    icon: "suitcase",
    filter: (d) => d.isBusiness,
    categories: [PURPOSE_CATEGORY.BUSINESS],
  },
  {
    id: TABS.PERSONAL,
    label: "Personal",
    icon: "home",
    filter: (d) => d.isPersonal,
    categories: [PURPOSE_CATEGORY.PERSONAL],
  },
];

export function getTotalPerTab(tabId, totals) {
  let total = 0;
  if (totals) {
    switch (tabId) {
      case TABS.ALL:
        total = totals.all;
        break;
      case TABS.UNCLASSIFIED:
        total = totals.unclassified;
        break;
      case TABS.PERSONAL:
        total = totals.personal;
        break;
      case TABS.BUSINESS:
        total = totals.business;
        break;
    }
  }
  return total;
}

function Tabs({ current, onTabClicked, totals }) {
  return (
    <div className="user-drives-list-tabs flex items-center gap-[10px] laptop:gap-[5px]">
      {TABS_DATA.map((t) => {
        const count = getTotalPerTab(t.id, totals);
        return (
          <TabButton
            key={t.id}
            label={t.label}
            icon={t.icon}
            active={t.id === current}
            count={count}
            important={t.id === TABS.UNCLASSIFIED}
            onClick={() => {
              onTabClicked(t.id);
            }}
          />
        );
      })}
    </div>
  );
}
