import React from "react";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import HeroImg from "public/assets/img/car-warn.svg";

export default BestExperienceModal;
function BestExperienceModal({ onClose }) {
  return (
    <Modal
      onClose={onClose}
      className="best-experience-modal w-[360px]"
      passiveBackdrop
    >
      <div className="flex flex-col items-center gap-[10px]">
        <HeroImg />
        <h6 className="text-center">
          For best experience, access the Dashboard from a larger device
        </h6>
        <Button className="w-full mt-[10px]" onClick={onClose}>
          Got it
        </Button>
      </div>
    </Modal>
  );
}
