import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";

import PageLayout from "src/components/PageLayout";

import SettingsPages from "./settings/SettingsPages";
import SettingsQuickTips from "./settings/SettingsQuickTips";

export default SettingsPage;

function SettingsPage() {
  const { path } = useRouteMatch();
  const loading = false;

  return (
    <>
      <PageLayout className="page-user-settings">
        <PageLayout.Main className="flex flex-col">
          {loading ? (
            <Loader className="p-8" />
          ) : (
            <>
              <div className="page-header relative">
                <div className="title-row flex items-center justify-between ">
                  <div className="flex items-center">
                    <MobMenu />
                    <h3>Settings</h3>
                  </div>
                </div>
              </div>
              <SettingsPages />
            </>
          )}
        </PageLayout.Main>
        <Switch>
          {/*
          exclude these paths to render quick tips;
          do not render these paths
          */}
          <Route path={[`${path}/contacts`]}></Route>
          {/* but render anyother */}
          <Route>
            <PageLayout.Sidebar>
              <SettingsQuickTips />
            </PageLayout.Sidebar>
          </Route>
        </Switch>
      </PageLayout>
    </>
  );
}
