import "src/models/typings";

import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { CONTACT_TYPES, isValidEmail } from "src/services/utils";

import { useContacts } from "../context/ContactsContext";
import { Alert, AlertVariants } from "../elements/Alert";
import MiqSelect from "../elements/Select";

export default AddContactModal;

export const ELEMENT_ID = "ADD_CONTACT_MODAL";
registerElement(ELEMENT_ID, AddContactModal);

function AddContactModal({ onClose, onSubmit }) {
  const [state, setState] = useState({});
  const [emailError, setEmailError] = useState("");
  const { user } = useUser();
  const { contacts, addContact, addContactState } = useContacts();

  const canSubmit = () => {
    const name = state?.name;
    const email = state?.email?.trim();
    const relationship = state?.relationship?.trim();
    const other = state?.other?.trim();
    return (
      name?.length >= 3 &&
      isValidEmail(email) &&
      relationship &&
      (relationship !== "Other" || other.length > 3)
    );
  };

  const handleChange = (param) => (event) => {
    if (param === "email") {
      setEmailError("");
    }
    const value =
      event?.target?.value || (typeof event === "string" ? event : "");
    setState((state) => ({
      ...state,
      [param]: value,
    }));
  };

  const handleValidateEmail = () => {
    if (state?.email && !isValidEmail(state?.email)) {
      setEmailError("Please enter a valid email");
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state?.email?.trim()?.toLowerCase() == user?.email?.toLowerCase()) {
      setEmailError(
        "You cannot add yourself to the contacts list. Your are already included in the share list."
      );
      return;
    }

    if (
      contacts?.find(
        (c) => c?.email?.toLowerCase() === state?.email?.toLowerCase()
      )
    ) {
      setEmailError("You have already added this contact");
      return;
    }

    if (!canSubmit()) return;

    const fullNameArray = (state?.name || "").split(" ");
    const firstName = fullNameArray[0] || "";
    const lastName = (fullNameArray.length > 1 && fullNameArray.pop()) || "";

    const contact = {
      firstName,
      lastName,
      email: state?.email,
      relationship: state?.relationship,
    };

    const { id } = await addContact(contact);
    onSubmit?.({ id, ...contact });
  };

  return (
    <Modal
      closable
      show
      className="w-[440px]"
      onClose={onClose}
      testId="add-contact-modal"
    >
      <>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-[20px] laptop:gap-[15px]"
        >
          <h4>Add a contact</h4>
          <>
            <Input
              label="Full name"
              placeholder="Full name"
              value={state?.name}
              onChange={handleChange("name")}
            />
            <Input
              type="email"
              label="Email"
              placeholder="Email"
              value={state?.email}
              onChange={handleChange("email")}
              onBlur={handleValidateEmail}
            />
            <Alert variant={AlertVariants.ERROR} show={emailError}>
              {emailError}
            </Alert>
            <ContactRelationship onChange={handleChange("relationship")} />
            <Alert variant={AlertVariants.ERROR} show={addContactState?.error}>
              Something wrong while creating a contact, please try again.
            </Alert>
            <div className="flex gap-3 justify-end">
              <Button
                type="submit"
                data-testid="add-contact-submit-btn"
                disabled={!canSubmit() || addContactState?.loading}
                loading={addContactState?.loading}
              >
                Add
              </Button>
            </div>
          </>
        </form>
      </>
    </Modal>
  );
}

function ContactRelationship({ onChange }) {
  const [state, setState] = useState({ selected: null, other: "" });
  const { contactTypes } = useContacts();

  const PLACEHOLDER = "Select";

  const options = {};

  // contactTypes is the new options, but its schemed as an array of objects with label property and its value
  // so we need to convert it to an object with the key as the value and the value as the object
  if (Array.isArray(contactTypes)) {
    contactTypes.forEach(({ label }) => {
      options[label] = { label };
    });
  }

  const handleRelationshipChange = (val) => {
    setState((state) => ({
      ...state,
      selected: val,
    }));
    onChange(options[val].label);
  };

  const handleOtherInputChange = (event) => {
    const value =
      event?.target?.value || (typeof event === "string" ? event : "");
    setState((state) => ({
      ...state,
      other: value,
    }));
    onChange(value?.trim() || options[state?.selected].label);
  };

  return (
    <div className="relative">
      <label className="block mb-2.5 relative">
        <Text semibold>Relationship</Text>
      </label>
      <MiqSelect
        alignRight
        noSelectionLabel={PLACEHOLDER}
        selected={state?.selected}
        onSelect={handleRelationshipChange}
        dropdownOptions={{
          contentClassName: "w-full !left-0 absolute top-none",
        }}
        options={Object.entries(options)
          ?.filter((entry) => !entry?.[1]?.hidden)
          .reduce((acc, [key, value]) => {
            if (!value.hidden) {
              acc[key] = value;
            }
            return acc;
          }, {})}
      />
      {state?.selected == CONTACT_TYPES.OTHER && (
        <Input
          className="mt-2.5"
          placeholder="Enter contact relationship"
          value={state?.other}
          onChange={handleOtherInputChange}
        />
      )}
    </div>
  );
}
