import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

import useQueryParams from "src/hooks/useQueryParams";

import {
  trackPasswordResetCompleted,
  trackPasswordResetFailed,
} from "src/services/tracking";
import { AUTH_SCREENS } from "src/services/utils";

import { RESET_PASSWORD_WITH_TOKEN } from "src/graphql/mutations";

import { PasswordValidations, validations } from "../PasswordValidations";

export const ResetPasswordStep = () => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const username = searchParams.get("username");
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [isValid, setValid] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [errorSameAsCurrent, setErrorSameAsCurrent] = useState(false);

  const handlePasswordChange = (password) => {
    return setPassword(password);
  };

  const [resetPasswordWithToken, { loading }] = useMutation(
    RESET_PASSWORD_WITH_TOKEN
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    if (!isValid || !password.length) {
      let details = {};
      validations.forEach((v) => {
        details[v.key] = v.reg.test(password);
      });
      trackPasswordResetFailed({ email: username, ...details });
      setErrorSameAsCurrent(false);
      return setShowValidationErrors(true);
    }

    try {
      const { data } = await resetPasswordWithToken({
        variables: {
          data: {
            token,
            password,
            confirmPassword: password,
          },
        },
        onCompleted: () => {
          trackPasswordResetCompleted({ email: username });
        },
      });

      const responseCode = data?.resetPassword?.code;

      if (typeof responseCode === "undefined") {
        setShowValidationErrors(false);
        setErrorSameAsCurrent(false);
        searchParams?.delete?.("token");
        const params = searchParams?.toString();
        return history.replace(
          `${history.location.pathname}${params ? "?" + params : ""}`
        );
      } else if (responseCode == 215) {
        setShowValidationErrors(true);
        setErrorSameAsCurrent(true);
        return false;
      }

      return history.replace(`/login?email=${username}`, {
        screen: AUTH_SCREENS.EMAIL,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mx-auto">
      <h1 className="text-36 mb-5 mx-auto text-center">Change your password</h1>
      <Text
        paragraph
        center
        color="black/70"
        className="text-lg mb-14 mx-auto text-center"
      >
        Enter a new password for <strong>{username}</strong>
      </Text>
      <form onSubmit={handleSubmit} noValidate>
        <div>
          <PasswordValidations
            hasConfirmPassword
            password={password}
            onChange={handlePasswordChange}
            onValid={setValid}
            showValidationErrors={showValidationErrors}
            errorSameAsCurrent={errorSameAsCurrent}
          />
          <Button
            lg
            className={"w-full font-medium mt-5"}
            type="submit"
            loading={loading}
          >
            Change Password
          </Button>
        </div>
      </form>
    </div>
  );
};
