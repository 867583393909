import { format } from "date-fns";
import { createContext, useContext, useState } from "react";

import { NETWORK_STATES } from "src/services/http";
import { getTeamStats } from "src/services/teams";
import { trackTeamsOverviewMetricsFailed } from "src/services/tracking";

import TeamContext from "./TeamContext";

const TeamOverviewContext = createContext(null);

export function useTeamOverview() {
  return useContext(TeamOverviewContext);
}

export function TeamOverviewProvider({ children }) {
  const [teamOverviewNetworkState, setTeamOverviewNetworkState] = useState(
    NETWORK_STATES.IDLE
  );
  const [teamStats, setTeamStats] = useState(null);
  const { team } = useContext(TeamContext);

  const fetchTeamStats = async () => {
    try {
      setTeamOverviewNetworkState(NETWORK_STATES.LOADING);
      const data = await getTeamStats(team.id, {
        current_month: format(new Date(), "yyyy-MM"),
      });
      setTeamOverviewNetworkState(NETWORK_STATES.LOADED);

      data?.total?.values.reverse();

      setTeamStats(data);
      return data;
    } catch (error) {
      setTeamOverviewNetworkState(NETWORK_STATES.ERROR);
      console.error("Something went wrong while fetching team stats", error);
      trackTeamsOverviewMetricsFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        subType: team.subscription?.type,
      });
    }
  };

  return (
    <TeamOverviewContext.Provider
      value={{ teamStats, teamOverviewNetworkState, fetchTeamStats }}
    >
      {children}
    </TeamOverviewContext.Provider>
  );
}
