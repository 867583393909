import { getMinutes } from "date-fns";

export const DAYS = [
  "monday",
  "tuesday",
  "wednesdey",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const WEEKDAYS = [
  "monday",
  "tuesday",
  "wednesdey",
  "thursday",
  "friday",
];
export const WEEKENDS = ["saturday", "sunday"];

export const ONE_DAY = 86400;

// Get time from UTC Time String
export const getUTCTimeString = (time) => {
  return time.toUTCString().split(" ").at(-2);
};

// Get day index
export const getDayIndexFromSeconds = (seconds) =>
  Math.floor(+(seconds / (24 * 3600))) % 7;

// Convert milliseconds to unix,
// if it's smaller than 0, we are adding 1 more day
// because we are using new date object
export const convertToUnix = (date) => {
  const unixDate = date.getTime();
  if (unixDate < 0) {
    return unixDate / 1000 + ONE_DAY;
  }
  return unixDate / 1000;
};

// Return new Date object by given params
export const getEpochStartUTCDate = (hours, minutes, dayOffset = 0) => {
  const currentDate = new Date(
    Date.UTC(1970, 0, 1 + dayOffset, hours, minutes)
  );

  return currentDate;
};

// Get time from milliseconds
export const getTimeFromSeconds = (seconds) => {
  const dayIndex = getDayIndexFromSeconds(seconds);
  const hours = Math.floor((seconds - ONE_DAY * dayIndex) / 3600);
  const minutes = Math.floor(
    (seconds - ONE_DAY * dayIndex - 3600 * hours) / 60
  );

  return getEpochStartUTCDate(hours, minutes, dayIndex);
};

export const getSecondsForDayIndex = (dateTime, dayIndex) =>
  ((24 * dayIndex + dateTime.hours()) * 60 + dateTime.minutes()) * 60;

//get default hours
export const getDefaultHours = () => {
  const shifts = [
    [32400, 64800],
    [118800, 151200],
    [205200, 237600],
    [291600, 324000],
    [378000, 410400],
  ];

  return { shifts, processRule: "personal" };
};

//convert shift hours to structured object
export const convertToTimePickerSlots = (hours) => {
  const workHours = {};
  if (!hours) {
    return workHours;
  }

  const shifts = hours?.shifts;

  if (shifts) {
    DAYS.forEach((day) => {
      workHours[day] = [];
    });

    shifts.forEach((shift) => {
      const start = shift[0];
      const end = shift[1];

      const startDayIndex = getDayIndexFromSeconds(start);
      workHours[DAYS[startDayIndex]].push({
        start: getTimeFromSeconds(start),
        end: getTimeFromSeconds(end),
      });
    });
  }

  return workHours;
};

//check ap or pm
export const getIsAm = (timeObj) => {
  if (timeObj.hours == 11 && timeObj.isAm) {
    return false;
  } else if (timeObj.hours == 11 && !timeObj.isAm) {
    return true;
  } else {
    return timeObj.isAm;
  }
};

//return hours in am/pm format
export function getHoursAMPM(date) {
  const hours = date.getUTCHours();

  if (hours === 0) return { hours: 12, isAm: true }; // 00:00 -> 12:00 AM

  if (hours >= 1 && hours <= 11) return { hours, isAm: true }; // 01:00 - 11:00 -> 01:00 AM - 11:00 AM

  if (hours === 12) return { hours: 12, isAm: false }; // 12:00 -> 12:00 PM

  if (hours >= 13 && hours <= 23) return { hours: hours - 12, isAm: false }; // 13:00 - 23:00 -> 01:00 PM - 11:00 PM
}

//create object for TimePicker
export const getTimePickerObject = (workHours, simple, dayIndex) => {
  if (simple) {
    const theDateStart = convertToTimePickerSlots(workHours).monday[0].start;
    const theDateEnd = convertToTimePickerSlots(workHours).monday[0].end;
    const { hours: startHours, isAm: startIsAm } = getHoursAMPM(theDateStart);
    const { hours: endHours, isAm: endIsAm } = getHoursAMPM(theDateEnd);
    const startMinutes = getMinutes(theDateStart);
    const endMinutes = getMinutes(theDateEnd);

    return {
      timeStart: { hours: startHours, minutes: startMinutes, isAm: startIsAm },
      timeEnd: { hours: endHours, minutes: endMinutes, isAm: endIsAm },
    };
  } else {
    let timePickerObjArr = [];
    Object.keys(convertToTimePickerSlots(workHours)).map((key) => {
      if (convertToTimePickerSlots(workHours)[key].length > 1) {
        let tmpArr = [];
        convertToTimePickerSlots(workHours)[key].map((v) => {
          const theDateStart = v.start;
          const theDateEnd = v.end;
          const { hours: startHours, isAm: startIsAm } =
            getHoursAMPM(theDateStart);
          const { hours: endHours, isAm: endIsAm } = getHoursAMPM(theDateEnd);
          const startMinutes = getMinutes(theDateStart);
          const endMinutes = getMinutes(theDateEnd);

          tmpArr.push({
            timeStart: {
              hours: startHours,
              minutes: startMinutes,
              isAm: startIsAm,
            },
            timeEnd: { hours: endHours, minutes: endMinutes, isAm: endIsAm },
            index: dayIndex,
            day: key,
          });
        });
        timePickerObjArr.push(tmpArr);
      } else if (convertToTimePickerSlots(workHours)[key].length == 1) {
        const theDateStart = convertToTimePickerSlots(workHours)[key][0].start;
        const theDateEnd = convertToTimePickerSlots(workHours)[key][0].end;
        const { hours: startHours, isAm: startIsAm } =
          getHoursAMPM(theDateStart);
        const { hours: endHours, isAm: endIsAm } = getHoursAMPM(theDateEnd);
        const startMinutes = getMinutes(theDateStart);
        const endMinutes = getMinutes(theDateEnd);

        timePickerObjArr.push([
          {
            timeStart: {
              hours: startHours,
              minutes: startMinutes,
              isAm: startIsAm,
            },
            timeEnd: { hours: endHours, minutes: endMinutes, isAm: endIsAm },
            index: dayIndex,
            day: key,
          },
        ]);
      } else {
        timePickerObjArr.push([
          {
            timeStart: {},
            timeEnd: {},
            index: dayIndex,
            day: key,
          },
        ]);
      }
    });
    return timePickerObjArr;
  }
};

//check is schedule simple or complexed
export const isScheduleComplex = (hours) => {
  if (!hours || !hours.workHours) {
    return false;
  }

  const slots = convertToTimePickerSlots(hours.workHours);

  let isScheduleComplex = false;

  if (!slots) {
    return isScheduleComplex;
  }

  if (!slots.monday || slots.monday.length !== 1) {
    return true;
  }

  WEEKDAYS.forEach((day) => {
    if (!slots[day]) {
      isScheduleComplex = true;
      return;
    }

    if (
      slots[day].length !== 1 ||
      slots[day][0].start.toTimeString() !==
        slots.monday[0].start.toTimeString() ||
      slots[day][0].end.toTimeString() !== slots.monday[0].end.toTimeString()
    ) {
      isScheduleComplex = true;
    }
  });

  WEEKENDS.forEach((day) => {
    if (slots[day].length > 0) {
      isScheduleComplex = true;
    }
  });

  return isScheduleComplex;
};

export const convertWorkHoursObjectFromV1toV3 = (workHours) => {
  if (!workHours) return workHours;

  if (workHours.shifts && workHours.processRule !== "personal") {
    return workHours;
  }

  const normalizedWorkHours = {
    ...workHours,
    processRule: "none",
    processRuleOutsideHours: "personal",
  };

  return normalizedWorkHours;
};
