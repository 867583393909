import PropTypes from "prop-types";
import React from "react";

import Text from "src/components/elements/Text";

const MiqInput = React.forwardRef(
  (
    {
      className,
      sm,
      leftIcon,
      rightIcon,
      value,
      valid,
      prefix,
      prefixClassName,
      style,
      label,
      secondaryLabel,
      onBlur,
      onFocus,
      disabled,
      type,
      inputProps,
      ...rest
    },
    ref
  ) => {
    let classes = ["miq-input"];

    if (className) classes.push(className);

    if (sm) classes.push("miq-input-small");

    if (!valid) classes.push("invalid");
    if (disabled) classes.push("disabled");

    let otherProps = { ...rest };

    // if no onChange handler provided
    // make input uncontrolled
    if (!otherProps.onChange) {
      otherProps.defaultValue = value?.toString();
    } else {
      otherProps.value = value?.toString();
    }

    let theInput = (
      <div className={classes.join(" ")}>
        {leftIcon}
        <input
          ref={ref}
          style={style}
          disabled={disabled}
          type={type}
          {...inputProps}
          {...otherProps}
          onBlur={(e) => {
            if (typeof onBlur === "function") {
              onBlur(e);
            }
          }}
          onFocus={(e) => {
            if (typeof onFocus === "function") {
              onFocus(e);
            }
          }}
        />
        {rightIcon}
      </div>
    );

    if (label) {
      theInput = (
        <label>
          <Text
            semibold
            className={`inline-block ${
              secondaryLabel ? "mb-[4px]" : "mb-[10px]"
            }`}
          >
            {label}
          </Text>
          {secondaryLabel && (
            <div className="flex flex-col">
              <Text md className="inline-block mb-2 text-black/70">
                {secondaryLabel}
              </Text>
            </div>
          )}
          {theInput}
        </label>
      );
    }

    if (prefix) {
      return (
        <div className={`miq-input-with-prefix`} style={style}>
          <Text className={`prefix ${prefixClassName || ""}`}>{prefix}</Text>
          {theInput}
        </div>
      );
    }

    return theInput;
  }
);
export default MiqInput;

MiqInput.propTypes = {
  sm: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MiqInput.defaultProps = {
  sm: false,
  valid: true,
  className: "",
  value: "",
};
