import { format, isSameYear } from "date-fns";

import { PICKER_TYPE } from "src/components/blocks/dates/DateRangePicker";

export const CustomDateRangePickerLabel = ({
  startDate,
  endDate,
  currentLabel,
  datePickerType,
}) => {
  const isStartYearSameAsCurrentYear = isSameYear(startDate, new Date());
  const isEndYearSameAsCurrentYear = isSameYear(endDate, new Date());
  const formatSameYear = "MMM d";
  const formatDifferentYear = "MMM d, yyyy";
  const formatString =
    isStartYearSameAsCurrentYear && isEndYearSameAsCurrentYear
      ? formatSameYear
      : formatDifferentYear;
  const formattedStartDate = format(startDate, formatString);
  const formattedEndDate = format(endDate, formatString);

  const isMonthPicker = datePickerType === PICKER_TYPE.MONTH;
  const dateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <div>
      <span className="font-semibold">Driven in</span>{" "}
      <span className="font-normal">
        {isMonthPicker ? dateRange : currentLabel}
      </span>{" "}
      {datePickerType === PICKER_TYPE.RANGE && (
        <span className="font-normal">({dateRange})</span>
      )}
    </div>
  );
};
