import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default IntegrationsPageQuickTips;

function IntegrationsPageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/11268740428180">
            MileIQ Teams: Submitting your report to Concur
          </QuickTipsLink>{" "}
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/11059941337364">
            MileIQ Teams: Concur Integration Settings (Driver)
          </QuickTipsLink>{" "}
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/11072100490772">
            Concur Integration: How do I find my Employee ID
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
