import Base from "src/models/base";

export const PURPOSE_CATEGORY = {
  UNCLASSIFIED: 0,
  PERSONAL: 1,
  BUSINESS: 2,
};

export default class Purpose extends Base {
  id = null;
  label = null;
  category = null;

  constructor(data) {
    super("purpose_model");
    this.id = data.id;
    this.label = data?.label || "";
    this.category = data?.category || PURPOSE_CATEGORY.UNCLASSIFIED;
  }

  get isUnclassified() {
    return this.category === PURPOSE_CATEGORY.UNCLASSIFIED;
  }

  get isBusiness() {
    return this.category === PURPOSE_CATEGORY.BUSINESS;
  }

  get isPersonal() {
    return this.category === PURPOSE_CATEGORY.PERSONAL;
  }

  get isMedical() {
    return this.isPersonal && this.id?.toLowerCase() === "medical";
  }

  get isCharity() {
    return this.isPersonal && this.id?.toLowerCase() === "charity";
  }

  get isMoving() {
    return this.isPersonal && this.id?.toLowerCase() === "moving";
  }
}
