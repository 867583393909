import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "src/components/elements/Button";
import EmailInput from "src/components/elements/EmailInput";
import MiqLoader from "src/components/elements/Loader";
import PasswordInput from "src/components/elements/PasswordInput";
import Text from "src/components/elements/Text";

import useQueryParams from "src/hooks/useQueryParams";

import { ACCOUNT_REALM_TYPE, loginWithFirebase } from "src/services/auth";
import {
  getAuthRedirectResult,
  getFirebaseUser,
  signInWithMicrosoft,
} from "src/services/firebase";
import { isValidEmail } from "src/services/utils";

import {
  AUTHENTICATE_MIQ_USER,
  AUTHENTICATE_SESSION_USER,
  EXCHANGE_JWT_TO_SESSION_TOKEN,
  VALIDATE_EMAIL,
} from "src/graphql/mutations";
import { GET_PARTNER_CLIENT_NAME } from "src/graphql/queries";

import Logo from "public/assets/img/miq-logo-black-orange.svg";

export const PartnerPage = () => {
  const history = useHistory();
  const searchParams = useQueryParams();

  const oAuthEmail = searchParams.get("oauth_hint");
  const clientId = searchParams.get("client_id") || "";
  const redirectUrl = searchParams.get("redirect_url") || "";
  const originalState = searchParams.get("state");

  const [oAuthLoading, setOAuthLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(oAuthEmail || "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const partnerClientNameQuery = useQuery(GET_PARTNER_CLIENT_NAME, {
    variables: {
      clientId,
      redirectUrl,
    },
    notifyOnNetworkStatusChange: true,
  });

  const clientName =
    partnerClientNameQuery?.data?.getPartnerClientName?.clientName;

  const clientNameError =
    partnerClientNameQuery?.data?.getPartnerClientName?.error;

  const [validateEmail, { loading: validateEmailLoading }] = useMutation(
    VALIDATE_EMAIL,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const [authenticateMIQUser, { loading: authMIQLoading }] = useMutation(
    AUTHENTICATE_MIQ_USER,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const [authenticateSessionUser, { loading: authSessionLoading }] =
    useMutation(AUTHENTICATE_SESSION_USER, {
      notifyOnNetworkStatusChange: true,
    });

  const [exchangeJwtToSessionToken, { loading: exchangeTokenLoading }] =
    useMutation(EXCHANGE_JWT_TO_SESSION_TOKEN, {
      notifyOnNetworkStatusChange: true,
    });

  const loading =
    authMIQLoading ||
    authSessionLoading ||
    validateEmailLoading ||
    oAuthLoading ||
    exchangeTokenLoading;

  const handleEmailChange = (email) => {
    setErrorMessage(null);
    setEmail(email);
  };

  const handlePasswordChange = (password) => {
    setErrorMessage(null);
    setPassword(password);
  };

  const handleMIQAuthenticate = async () => {
    if (!password) {
      throw new Error("Please enter your password");
    }

    const {
      data: {
        authenticateMIQUser: { authCode, code, error },
      },
    } = await authenticateMIQUser({
      variables: {
        clientId,
        username: email,
        password,
        redirectUrl,
      },
    });

    if (code === 200) {
      const _redirectUrl = `${redirectUrl}?auth_code=${authCode}&state=${originalState}`;

      window.location.href = _redirectUrl;

      return;
    }

    throw new Error(error || "An error occurred. Please try again later");
  };

  const handleMSAAuthenticate = async (accessToken) => {
    const errorMessage =
      "There was an error logging in with Microsoft. Please try again.";

    const {
      data: {
        exchangeJwtToSessionToken: {
          code: exchangeTokenStatusCode,
          token: sessionToken,
        },
      },
    } = await exchangeJwtToSessionToken({
      variables: {
        token: accessToken,
      },
    });

    if (exchangeTokenStatusCode !== 200) {
      throw new Error(errorMessage);
    }

    const {
      data: {
        authenticateSessionUser: {
          authCode,
          code: authSessionResponseCode,
          error,
        },
      },
    } = await authenticateSessionUser({
      variables: {
        clientId,
        sessionToken,
        redirectUrl,
      },
    });

    if (authSessionResponseCode === 200) {
      const _redirectUrl = `${redirectUrl}?auth_code=${authCode}&state=${originalState}`;

      window.location.href = _redirectUrl;

      return;
    }

    throw new Error(error || errorMessage);
  };

  const tryExistingMSASession = async () => {
    const firebaseUser = getFirebaseUser();

    const firebaseAccessToken = firebaseUser?.accessToken;

    if (
      firebaseAccessToken &&
      firebaseUser?.email?.toLowerCase() === email?.toLowerCase()
    ) {
      setOAuthLoading(true);

      const { token } = await loginWithFirebase(
        firebaseAccessToken,
        email,
        "partner"
      );

      await handleMSAAuthenticate(token);

      return true;
    }

    return false;
  };

  const handleValidateEmail = async () => {
    if (!isValidEmail(email)) {
      throw new Error("Please enter a valid email address");
    }

    const {
      data: {
        validateEmail: { realm, userExists },
      },
    } = await validateEmail({ variables: { email } });

    if (!userExists) {
      throw new Error("An account with this email address does not exist");
    }

    if (realm === ACCOUNT_REALM_TYPE.MSA) {
      setShowPassword(false);
      setOAuthLoading(true);

      const existingSession = await tryExistingMSASession();

      if (existingSession) return;

      searchParams.set("oauth_hint", email);

      history.push(`/partner?${searchParams.toString()}`);

      return await signInWithMicrosoft(email);
    }

    setShowPassword(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    try {
      setErrorMessage(null);

      await handleValidateEmail();

      if (showPassword) {
        await handleMIQAuthenticate();
      }
    } catch (err) {
      console.error(err);

      setErrorMessage(err.message);
    } finally {
      setOAuthLoading(false);
    }
  };

  const handleMicrosoftFeedback = async () => {
    try {
      setOAuthLoading(true);

      const response = await getAuthRedirectResult();

      if (!response) return;

      await handleMSAAuthenticate(response.token);
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
    } finally {
      setOAuthLoading(false);
    }
  };

  useEffect(() => {
    if (oAuthEmail) {
      handleMicrosoftFeedback();
    }
  }, []);

  const showPageLoader = partnerClientNameQuery.loading;
  const showPageError = !!clientNameError;
  const showForm =
    !showPageLoader && !showPageError && !!partnerClientNameQuery.data;

  return (
    <div className="relative w-full">
      <a
        className="absolute left-[35px] top-[30px] z-10"
        href="https://mileiq.com"
        target="_blank"
        rel="noopener"
      >
        <Logo />
      </a>
      <div className="relative w-full h-full table table-fixed">
        <div className="w-full table-cell align-middle">
          <div className="login-wrapper relative my-0 mx-auto bg-white px-6 sm:px-0">
            {showPageLoader && <MiqLoader />}
            {showPageError && (
              <div className="text-center">
                <h2>Something went wrong</h2>
                <Text
                  color="black/70"
                  paragraph
                  center
                  className="mt-5 text-18"
                >
                  {clientNameError}
                </Text>
              </div>
            )}
            {showForm && (
              <div className="text-center">
                <h2>
                  Authorize {clientName} to access your MileIQ drive
                  information.
                </h2>
                <Text
                  color="black/70"
                  paragraph
                  center
                  className="mt-5 text-18"
                >
                  {clientName} will have access to your: Drives, Vehicle
                  information, Parking & Tolls
                </Text>
                <form noValidate onSubmit={handleSubmit}>
                  <EmailInput
                    autoFocus
                    className={"mt-10"}
                    disabled={loading}
                    value={email}
                    onChange={handleEmailChange}
                    invalid={!!errorMessage}
                  />
                  {showPassword && (
                    <PasswordInput
                      className="mt-5"
                      autoFocus
                      placeholder="Password"
                      valid={!errorMessage}
                      onChange={handlePasswordChange}
                      value={password}
                    />
                  )}
                  {errorMessage && (
                    <div className="text-left mt-2">
                      <Text lg regular left color="red">
                        {errorMessage}
                      </Text>
                    </div>
                  )}
                  <Button
                    lg
                    className="w-full font-medium mt-5"
                    type="submit"
                    loading={loading}
                  >
                    Next
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
