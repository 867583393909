import React, { useContext, useState } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { emailReport } from "src/services/reports";
import { isValidEmail } from "src/services/utils";

export default EmailReport;

function EmailReport({ report, show, onClose, onSent }) {
  const { team } = useContext(TeamContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const _close = () => {
    if (typeof onClose === "function") onClose();
  };

  const _send = async () => {
    if (loading) return;
    setSubmitted(true);

    console.log("send");
    if (isValidEmail(email)) {
      try {
        setLoading(true);
        await emailReport(team.id, report.id, email);
        _close();
        if (typeof onSent === "function") onSent(email);
      } catch (err) {
        console.log("failed to email report");
      }
      setLoading(false);
    }
  };

  const isValid = isValidEmail(email);

  return (
    <Modal
      show={show}
      onClose={_close}
      closable={false}
      loading={loading}
      className="w-[440px] email-report-modal"
    >
      <>
        <h5>Email this report</h5>
        <Text paragraph lg className="mt-3">
          Please enter the email address where you'd like to send this Report
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            _send();
          }}
          className="mt-8"
        >
          <Input
            autoFocus
            placeholder="Enter email address"
            className="w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            valid={submitted ? isValid : true}
          />
          {!isValid && submitted && (
            <Text paragraph color="red" className="mt-2">
              Please enter a valid email address
            </Text>
          )}
          <div className="flex justify-end mt-8">
            <Button secondary type="button" disabled={loading} onClick={_close}>
              Cancel
            </Button>
            <Button
              className="ml-3"
              type="submit"
              disabled={loading || (submitted && !isValid)}
            >
              Send
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
}
