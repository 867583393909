import qt from "../models/backend-errors.js";
import ue from "../models/braze-event.js";
import {
  convertMsToSeconds as l,
  convertSecondsToMs as Bt
} from "../util/date-utils.js";
import s from "../common/event-logger.js";
import { isArray as p, isEqual as ii } from "../util/code-utils.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "./storage-manager.js";
export default class Ot {
  constructor(t, e, s, i, r, o, n, a, u, h, c) {
    (this.Ur = t),
      (this.h = e),
      (this.Ko = s),
      (this.v = i),
      (this.j = r),
      (this.gt = o),
      (this._r = n),
      (this.Bo = a),
      (this.Do = u),
      (this.Go = h),
      (this.sa = c),
      (this.oa = ["npm"]);
  }
  Ps(t, e) {
    const s = this.Ur.te(),
      i = s.Mi(),
      r = this.h.I(o.q.Yo);
    ii(r, i) || (t.device = i),
      (t.api_key = this._r),
      (t.time = l(new Date().valueOf(), !0));
    const n = this.h.I(o.q.ua) || [],
      a = this.h.I(o.q.ha) || "";
    if (
      (this.oa.length > 0 &&
        (!ii(n, this.oa) || a !== this.j.ca()) &&
        (t.sdk_metadata = this.oa),
      (t.sdk_version = this.Do),
      this.Go && (t.sdk_flavor = this.Go),
      (t.app_version = this.sa),
      (t.device_id = s.id),
      e)
    ) {
      const e = this.v.getUserId();
      null != e && (t.user_id = e);
    }
    return t;
  }
  Os(t, e, i) {
    const o = e.auth_error,
      n = e.error;
    if (!o && !n) return !0;
    if (o) {
      let e;
      this.Ko.Xn();
      const s = { errorCode: o.error_code };
      for (const t of i)
        p(t) && "X-Braze-Auth-Signature" === t[0] && (s.signature = t[1]);
      t.respond_with && t.respond_with.user_id
        ? (s.userId = t.respond_with.user_id)
        : t.user_id && (s.userId = t.user_id);
      const n = o.reason;
      return (
        n
          ? ((s.reason = n), (e = `due to ${n}`))
          : (e = `with error code ${o.error_code}.`),
        this.Ko.Jn() ||
          (e +=
            ' Please use the "enableSdkAuthentication" initialization option to enable authentication.'),
        r.D.error(`SDK Authentication failed ${e}`),
        this.la(t.events, t.attributes),
        this.Ko.Vn(s),
        !1
      );
    }
    if (n) {
      let i = n;
      switch (i) {
        case qt.fa:
          const o = "Received successful response with empty body.";
          return s.G(r.A.qs, { e: o }), r.D.info(o), !1;
        case qt.ma:
          const n = "Received successful response with invalid JSON";
          return s.G(r.A.qs, { e: n + ": " + e.response }), r.D.info(n), !1;
        case qt.pa:
          i = `The API key "${t.api_key}" is invalid for the baseUrl ${this.Bo}`;
          break;
        case qt.Ra:
          i =
            "Sorry, we are not currently accepting your requests. If you think this is in error, please contact us.";
          break;
        case qt.ba:
          i = "No device identifier. Please contact support@braze.com";
      }
      r.D.error("Backend error: " + i);
    }
    return !1;
  }
  ga(t, e, s, i) {
    return !!((t && 0 !== t.length) || (e && 0 !== e.length) || s || i);
  }
  qa(t, e, s, i) {
    const r = [],
      o = t => t || "",
      n = o(this.v.getUserId());
    let a,
      u,
      h,
      c,
      l = this.Ai(t, e);
    if (s.length > 0) {
      const t = [];
      for (const e of s) {
        if (((a = e.Mi()), this.Ko.Jn())) {
          if (n && !a.user_id) {
            c || ((c = {}), (c.events = [])), c.events.push(a);
            continue;
          }
          if (o(a.user_id) !== n) {
            u || (u = []), u.push(a);
            continue;
          }
        }
        t.push(a);
      }
      t.length > 0 && (l.events = t);
    }
    if (i.length > 0) {
      const t = [];
      for (const e of i)
        this.Ko.Jn() && o(e.user_id) !== n
          ? (h || (h = []), h.push(e))
          : t.push(e);
      t.length > 0 && (l.attributes = t);
    }
    if ((this.la(u, h), (l = this.Ps(l, !0)), c)) {
      c = this.Ps(c, !1);
      const t = { requestData: c, headers: this.Bs(c) };
      r.push(t);
    }
    if (l && !this.ga(l.events, l.attributes, t, e)) return c ? r : null;
    const f = { requestData: l, headers: this.Bs(l) };
    return r.push(f), r;
  }
  la(t, e) {
    if (t) {
      const e = [];
      for (const s of t) {
        const t = ue.fromJson(s);
        (t.time = Bt(t.time)), e.push(t);
      }
      this.h.bo(e);
    }
    if (e) for (const t of e) this.h.Aa(t);
  }
  Vs(t, e) {
    let s = "HTTP error ";
    null != t && (s += t + " "), (s += e), r.D.error(s);
  }
  yr(t) {
    return s.G(r.A.za, { n: t });
  }
  Ai(t, e, s) {
    const i = {};
    t && (i.feed = !0), e && (i.triggers = !0);
    const r = null != s ? s : this.v.getUserId();
    return (
      r && (i.user_id = r),
      (i.config = { config_time: this.gt.Zs() }),
      { respond_with: i }
    );
  }
  Bs(t, e, s, i) {
    const r = [["X-Braze-Api-Key", this._r]];
    let n = !1;
    if (
      (null != t.respond_with &&
        t.respond_with.triggers &&
        (r.push(["X-Braze-TriggersRequest", "true"]), (n = !0)),
      null != t.respond_with &&
        t.respond_with.feed &&
        (r.push(["X-Braze-FeedRequest", "true"]), (n = !0)),
      e)
    ) {
      r.push(["X-Braze-ContentCardsRequest", "true"]);
      let t = this.h.I(o.q.As);
      (t && i) || ((t = 0), this.h.B(o.q.As, t)),
        r.push(["BRAZE-SYNC-RETRY-COUNT", t.toString()]),
        (n = !0);
    }
    if (
      (s && (r.push(["X-Braze-FeatureFlagsRequest", "true"]), (n = !0)),
      n && r.push(["X-Braze-DataRequest", "true"]),
      this.Ko.Jn())
    ) {
      const t = this.Ko.Kn();
      null != t && r.push(["X-Braze-Auth-Signature", t]);
    }
    return r;
  }
  Gs(t, e) {
    const s = t.device;
    s && s.os_version instanceof Promise
      ? s.os_version.then(s => {
          (t.device.os_version = s), e();
        })
      : e();
  }
  Qs() {
    this.Ko.Qs();
  }
  Ks() {
    return this.Bo;
  }
  addSdkMetadata(t) {
    for (const e of t) -1 === this.oa.indexOf(e) && this.oa.push(e);
  }
}
