import React, { useCallback, useEffect, useState } from "react";

import Input from "src/components/elements/Input";

import Icon from "./Icon";
import Text from "./Text";

export default EmailInput;

function EmailInput({
  className,
  inputClassName,
  value,
  onChange,
  disabled,
  error,
  invalid,
  ...rest
}) {
  const [val, setVal] = useState(value);

  const updateVal = (val) => {
    setVal(val);
    if (typeof onChange === "function") onChange(val);
  };

  const handleChange = useCallback((e) => {
    let val = e.target.value || "";
    updateVal(val);
  });

  useEffect(() => {
    setVal(value);
  }, [value]);

  const classes = `miq-email-input-wrap ${className || ""}`;
  const inpClasses = `miq-email-input ${inputClassName || ""}`;

  return (
    <div className={classes}>
      <Input
        type="email"
        placeholder="Email"
        className={inpClasses}
        leftIcon={<Icon name="envelop" className="left-icon" />}
        {...rest}
        onChange={handleChange}
        value={val}
        valid={!error && !invalid}
        disabled={disabled}
      />
      {error && (
        <Text
          regular
          color="red"
          paragraph
          className="w-full text-left mt-[10px]"
          data-testid="email-error-message"
        >
          {error}
        </Text>
      )}
    </div>
  );
}
