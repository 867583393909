import React from "react";

import { formatNumberWithSuffix } from "src/services/utils";

export default CustomYAxisTick;

function CustomYAxisTick({ x, y, payload, highestValue }) {
  const tickText = formatNumberWithSuffix({
    value: payload.value,
    fractionDigits: highestValue > 1000 ? 1 : 0,
    referenceNumber: highestValue,
  });

  return (
    <text x={x} y={y + 4} textAnchor="end" fill="#666">
      {tickText}
    </text>
  );
}
