import ot from "../util/browser-detector.js";
import Ie from "../models/device.js";
import DeviceProperties from "../Core/device-properties.js";
import _t from "../models/identifier.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { values as At } from "../util/code-utils.js";
export default class It {
  constructor(e, t) {
    (this.h = e), null == t && (t = At(DeviceProperties)), (this.xo = t);
  }
  te() {
    let e = this.h.tu(o.eu.Jo);
    null == e && ((e = new _t(r.it.nt())), this.h.ru(o.eu.Jo, e));
    const t = new Ie(e.iu);
    for (let e = 0; e < this.xo.length; e++) {
      const r = this.xo[e];
      switch (r) {
        case DeviceProperties.BROWSER:
          t[r] = ot.browser;
          break;
        case DeviceProperties.BROWSER_VERSION:
          t[r] = ot.version;
          break;
        case DeviceProperties.OS:
          t[r] = this.qo();
          break;
        case DeviceProperties.RESOLUTION:
          t[r] = screen.width + "x" + screen.height;
          break;
        case DeviceProperties.LANGUAGE:
          t[r] = ot.language;
          break;
        case DeviceProperties.TIME_ZONE:
          t[r] = this.Qo(new Date());
          break;
        case DeviceProperties.USER_AGENT:
          t[r] = ot.userAgent;
      }
    }
    return t;
  }
  qo() {
    if (ot.Xo()) return ot.Xo();
    const e = this.h.I(o.q.Yo);
    return e && e.os_version ? e.os_version : ot.Zo();
  }
  Qo(e) {
    if ("undefined" != typeof Intl && "function" == typeof Intl.DateTimeFormat)
      try {
        if ("function" == typeof Intl.DateTimeFormat().resolvedOptions) {
          const e = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (null != e && "" !== e) return e;
        }
      } catch (t) {
        r.D.info(
          "Intl.DateTimeFormat threw an error, cannot detect user's time zone:" +
            t.message
        ),
          (e = "");
      }
    if (!e) return e;
    const t = e.getTimezoneOffset();
    return this.$o(t);
  }
  $o(e) {
    const t = parseInt(e / 60),
      r = parseInt(e % 60);
    let s = "GMT";
    return (
      0 !== e &&
        ((s += e < 0 ? "+" : "-"),
        (s +=
          ("00" + Math.abs(t)).slice(-2) +
          ":" +
          ("00" + Math.abs(r)).slice(-2))),
      s
    );
  }
}
