import React, { useContext } from "react";

import DrivesContext from "src/components/context/DrivesContext";

import Button from "src/components/elements/Button";

import { Pages } from "src/services/tracking";

/**
 *
 * @param {{drives: Drive[] }} props
 * @returns
 */
const Actions = ({ drives }) => {
  const { onDelete, onDuplicate, onJoin } = useContext(DrivesContext);
  const isSindleDrive = drives.length === 1;
  const drive = drives[0];
  return (
    <div className="grid grid-cols-2 gap-[10px]">
      {isSindleDrive ? (
        <Button
          tooltip={
            drive.isRoundTripStop
              ? {
                  timeout: 1,
                  text: "Round trip with a stop can't be duplicated",
                  offset: {
                    y: 25,
                  },
                  textProps: {
                    sm: true,
                  },
                }
              : null
          }
          icon="duplicate"
          secondary
          disabled={drives[0].isRoundTripStop}
          onClick={() => onDuplicate(drives[0])}
          className="flex-grow font-medium"
        >
          Duplicate
        </Button>
      ) : (
        <Button
          onClick={() => onJoin(drives)}
          icon="link"
          secondary
          className="flex-grow font-medium"
        >
          Join
        </Button>
      )}
      <Button
        onClick={() => onDelete(drives, { page: Pages.DRIVE_DETAILS })}
        secondary
        icon="trash-alt"
        className="flex-grow font-medium"
      >
        Delete
      </Button>
    </div>
  );
};

export default Actions;
