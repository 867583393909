import React, { useState } from "react";

import { getTime24 } from "src/services/utils";
import {
  WEEKDAYS,
  convertToTimePickerSlots,
  getEpochStartUTCDate,
  getTimePickerObject,
} from "src/services/work-hours";

import { SimpleTimeSlotsByDay } from "./SimpleTimeSlotsByDay";

export const SimpleSchedule = ({ workHours, onChange, hasError }) => {
  const [workHoursConverted, setWorkHoursConverted] = useState(
    convertToTimePickerSlots(workHours)
  );

  const handleChange = (value, startOrEnd) => {
    const correctedHours = getTime24(value);
    const newHour = Object.assign({});

    if (startOrEnd === "start") {
      newHour.start = getEpochStartUTCDate(correctedHours, value.minutes);
    } else {
      newHour.end = getEpochStartUTCDate(correctedHours, value.minutes);
    }

    WEEKDAYS.map((day, index) => {
      workHoursConverted[day][0] = {
        start: new Date(workHoursConverted[day][0].start.setDate(index + 1)),
        end: new Date(workHoursConverted[day][0].end.setDate(index + 1)),
        [Object.keys(newHour)[0]]: new Date(
          newHour[startOrEnd].setDate(index + 1)
        ),
      };
    });
    setWorkHoursConverted(workHoursConverted);
    onChange(workHoursConverted);
  };

  return (
    <SimpleTimeSlotsByDay
      simple
      workHours={getTimePickerObject(workHours, true)}
      onChange={handleChange}
      key="simple-schedule"
      hasError={hasError}
    />
  );
};
