import React, { useContext, useState } from "react";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDunning } from "src/components/context/TeamDunningContext";

import BulkInvite from "src/components/modals/BulkInvite";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "src/components/modals/DunningSubscriptionRecoverPayment";
import InviteUsers from "src/components/modals/InviteUsers";
import SwitchingToPro from "src/components/modals/SwitchingToPro";
import UpgradeSubscriptionModal from "src/components/modals/UpgradeSubscription";
import UpgradeSubscriptionConfirmModal from "src/components/modals/UpgradeSubscriptionConfirm";

import { MEMBER_STATUS } from "src/models/team-member";
import { TEAMS_SUBSCRIPTION_PLANS } from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import { createTeamsCheckoutSession } from "src/services/billing";
import { report } from "src/services/error-reporting";
import { setPendingInvites, setShowWelcomeSlides } from "src/services/storage";
import { getTeamMembers } from "src/services/teams";
import {
  TeamsUpdatePaymentSources,
  trackBulkUploadCompleted,
  trackBulkUploadFailed,
  trackBulkUploadStepCompleted,
} from "src/services/tracking";

const MODALS = {
  BULK_INVITE: "BULK_INVITE",
  UPGRADE_PLAN: "UPGRADE_PLAN",
  UPGRADE_PLAN_CONFIRM: "UPGRADE_PLAN_CONFIRM",
  INVITE_USERS: "INVITE_USERS",
  SWITCHING_TO_PRO: "SWITCHING_TO_PRO",
};

export function useUpgradePlanFlow({
  only,
  onBeforeStart,
  onBack,
  onDone,
  nextBtnText,
  skipConfirmation,
  excludePlans,
  customTitle,
} = {}) {
  const [modalVisible, setModalVisible] = useState(null);
  const [newSubPlan, setNewSubPlan] = useState(null);
  const [newSubType, setNewSubType] = useState(null);
  const { team } = useContext(TeamContext);
  const { resetDunningState } = useTeamDunning();

  const currentSubPlan = team.subscription?.plan;

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.UPGRADE_SUBSCRIPTION,
      },
    }
  );

  const finish = async ({ plan, type }) => {
    if (typeof onDone === "function")
      await onDone({ plan, type, prevPlan: currentSubPlan });

    setModalVisible(null);
  };

  const handleBack = () => {
    setModalVisible(null);
    onBack();
  };

  return [
    // func to start the flow
    (opts) => {
      if (team?.isPaymentFailing) {
        subscriptionRecoverPayment.activate();
        return;
      }

      if (typeof onBeforeStart === "function") onBeforeStart(opts);
      setModalVisible(MODALS.UPGRADE_PLAN);
    },
    () => (
      <>
        {modalVisible === MODALS.UPGRADE_PLAN && (
          <UpgradeSubscriptionModal
            customTitle={customTitle}
            only={only}
            excludePlans={excludePlans}
            nextBtnText={nextBtnText}
            onBack={onBack && handleBack}
            onClose={() => setModalVisible(null)}
            onDone={({ plan, type }) => {
              if (skipConfirmation) {
                finish({ plan, type });
              } else {
                setNewSubPlan(plan);
                setNewSubType(type);
                setModalVisible(MODALS.UPGRADE_PLAN_CONFIRM);
              }
            }}
          />
        )}
        {modalVisible === MODALS.UPGRADE_PLAN_CONFIRM && (
          <UpgradeSubscriptionConfirmModal
            newSubPlan={newSubPlan}
            newSubType={newSubType}
            onClose={() => setModalVisible(null)}
            onBack={() => setModalVisible(MODALS.UPGRADE_PLAN)}
            onDone={() => {
              finish({ plan: newSubPlan, type: newSubType });
            }}
          />
        )}
      </>
    ),
  ];
}

export function usePickFirstPlanFlow({
  forceShowPickPlan,
  only,
  onBeforeStart,
  onBack,
  excludePlans,
  forceInviteUsers,
} = {}) {
  const { team, refreshTeam } = useContext(TeamContext);

  const [modalVisible, setModalVisible] = useState(null);
  const [forceOnlyPlan, setForceOnlyPlan] = useState(null);
  const [invites, setInvites] = useState([]);
  const [newSubPlan, setNewSubPlan] = useState(null);
  const [newSubType, setNewSubType] = useState(null);

  const handleBulkSuccessUpload = () => {
    trackBulkUploadStepCompleted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      bulkUploadStepName: "Upload File",
      bulkUploadStepNumber: 1,
      plan: team.subscription?.plan,
    });
  };

  const handleBulkInviteUploadError = (isError) => {
    if (isError) {
      trackBulkUploadFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        plan: team.subscription?.plan,
      });
    }
  };

  const onReviewUsers = (bulkUsers) => {
    trackBulkUploadCompleted({
      bulkUploadUserCount: bulkUsers.length,

      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      plan: team.subscription?.plan,
    });
    setInvites((currentUsers = []) => {
      // REMOVE DUPLICATES
      let invites = [...bulkUsers, ...currentUsers];
      invites = Array.from(
        new Map(invites.map((invite) => [invite.email, invite])).values()
      );
      return invites;
    });
  };

  const eligibleMember = (member) => {
    return MEMBER_STATUS.ACTIVE == member.status && member.isDriver;
  };

  const [startSelectPlanFlow, renderSelectPlanFlow] = useUpgradePlanFlow({
    only: forceOnlyPlan || only,
    excludePlans,
    onBeforeStart,
    onBack,
    skipConfirmation: true,
    nextBtnText: forceInviteUsers ? "Next" : "Proceed to Checkout",
    onDone: async ({ plan, type }) => {
      if (forceInviteUsers) {
        setNewSubPlan(plan);
        setNewSubType(type);
        setModalVisible(MODALS.INVITE_USERS);
        return;
      }

      try {
        const members = await getTeamMembers(team?.id);
        const eligibleMembers = members.filter(eligibleMember);
        const numberOfDrivers = eligibleMembers.length || 1;

        const checkout = await createTeamsCheckoutSession(
          plan,
          type,
          numberOfDrivers
        );

        if (checkout?.session_url) {
          window.location = checkout.session_url;
        }
      } catch (error) {
        console.error(error);
        report(error);
      } finally {
        setModalVisible(null);
      }
    },
  });
  if (!team?.id) return null;

  return [
    (opts) => {
      if (forceShowPickPlan) return startSelectPlanFlow(opts);
      startSelectPlanFlow(opts);
    },
    ({ defaultUserType } = {}) => (
      <>
        {renderSelectPlanFlow()}
        {modalVisible === MODALS.INVITE_USERS && (
          <InviteUsers
            onBulkInviteButtonClick={() => setModalVisible(MODALS.BULK_INVITE)}
            defaultUserType={defaultUserType}
            invites={invites}
            onUpgrade={(tags) => {
              const teamsPro = TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO;

              setInvites(tags);
              setNewSubPlan(teamsPro);
              setModalVisible(MODALS.SWITCHING_TO_PRO);

              setTimeout(() => {
                setModalVisible(MODALS.INVITE_USERS);
              }, 2000);

              refreshTeam();
            }}
            onNext={async (tags) => {
              setPendingInvites(team.id, tags);
              try {
                const validPaidInvites = tags.filter(
                  (invite) => invite.userType !== USER_TYPES.ADMIN
                );

                const members = await getTeamMembers(team.id);
                const eligibleMembers = members.filter(eligibleMember);

                const checkout = await createTeamsCheckoutSession(
                  newSubPlan,
                  newSubType,
                  validPaidInvites.length + eligibleMembers.length
                );

                if (checkout && checkout.session_url) {
                  setShowWelcomeSlides(team.subscription?.plan, newSubPlan);
                  window.location = checkout.session_url;
                }
              } catch (err) {
                console.log(err);
              }
            }}
            onClose={({ setTags, setInOtherTeams }) => {
              setTags([]);
              setInOtherTeams([]);
              setInvites([]);
              setModalVisible(null);
              setForceOnlyPlan(null);
            }}
            hideDisabledRoleOptions={false}
            subscription={{
              plan: newSubPlan,
              type: newSubType,
            }}
            nextBtnText="Proceed to Checkout"
            title="To begin, invite drivers to your team"
            subtitle="Once you invite drivers, they can start using MileIQ to send you reports of their business drives."
          />
        )}
        {modalVisible === MODALS.BULK_INVITE && (
          <BulkInvite
            onBack={() => setModalVisible(MODALS.INVITE_USERS)}
            onClose={() => setModalVisible(MODALS.INVITE_USERS)}
            onSuccessFileLoad={handleBulkSuccessUpload}
            onUploadError={handleBulkInviteUploadError}
            onReviewUsers={onReviewUsers}
          />
        )}
        {modalVisible === MODALS.SWITCHING_TO_PRO && <SwitchingToPro />}
      </>
    ),
  ];
}
