import PropTypes from "prop-types";
import React from "react";

export default Text;

function Text({
  paragraph,
  custom, // if you want to apply custom styles for size and weight
  color,
  sm,
  md,
  lg,
  regular,
  semibold,
  bold,
  italic,
  left,
  center,
  right,
  nowrap,
  className,
  children,
  ...rest
}) {
  let classes = [];

  if (className) classes.push(className);

  if (color) {
    classes.push(`text-${color}`);
  }

  if (nowrap) {
    classes.push("whitespace-nowrap");
  }

  switch (true) {
    case center:
      classes.push("text-center");
      break;
    case right:
      classes.push("text-right");
      break;
    case left:
    default:
      classes.push("text-left");
      break;
  }

  if (!custom) {
    // font size
    switch (true) {
      case sm:
        classes.push("text-sm");
        break;
      case md:
        classes.push("text-md");
        break;
      case lg:
        classes.push("text-lg");
        break;
      default:
        classes.push("text-lg");
        break;
    }

    // font weight
    switch (true) {
      case regular:
        classes.push("font-normal"); // 400
        break;
      case semibold:
        classes.push("font-medium"); // 500
        break;
      case bold:
        classes.push("font-semibold"); // 600
        break;
      default:
        classes.push("font-normal");
        break;
    }

    // font style
    if (italic) {
      classes.push("italic");
    }
  }

  const props = {
    className: classes.join(" "),
    ...rest,
  };

  return paragraph ? (
    <p {...props}>{children}</p>
  ) : (
    <span {...props}>{children}</span>
  );
}

Text.propTypes = {
  paragraph: PropTypes.bool,
  custom: PropTypes.bool,
  // font sizes
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  // font weights
  regular: PropTypes.bool,
  semibold: PropTypes.bool,
  bold: PropTypes.bool,
  // font styles
  italic: PropTypes.bool,
  // align
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
};

Text.defaultProps = {
  paragraph: false,
  custom: false,
  sm: false,
  md: false,
  lg: false,
  regular: false,
  semibold: false,
  bold: false,
  italic: false,
  left: false,
  center: false,
  right: false,
};
