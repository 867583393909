import Base from "src/models/base";

export const REMINDER_PERIOD = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  CUSTOM: "custom",
};
export default class ReportReminder extends Base {
  isEnabled = null;
  period = null;
  frequency = null;
  dayNumber = null;
  startDate = null;

  constructor(data) {
    super("report_reminder_model");
    if (data) {
      this.isEnabled = data.is_enabled || null;
      this.period = data.period || null;
      this.frequency = data.frequency || null;
      this.dayNumber = data.day_number || null;
      this.startDate = data.start_date ? new Date(data.start_date) : null;
    }
  }

  get isWeekPeriod() {
    return this.period === REMINDER_PERIOD.WEEK;
  }

  get isMonthPeriod() {
    return this.period === REMINDER_PERIOD.MONTH;
  }

  get isYearPeriod() {
    return this.period === REMINDER_PERIOD.YEAR;
  }

  get isDayPeriod() {
    return this.period === REMINDER_PERIOD.DAY;
  }

  get isCustomReminder() {
    if (this.isDayPeriod || this.isYearPeriod) {
      return true;
    }

    if (this.isWeekPeriod) {
      return (
        this.frequency > 1 || this.startDate?.getDay?.() !== this.dayNumber
      );
    }

    if (this.isMonthPeriod) {
      return this.frequency > 1;
    }

    return false;
  }
}
