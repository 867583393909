import {
  differenceInMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  getYear,
  isEqual,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import React, { useState } from "react";

import Button from "src/components/elements/Button";
import DatePicker from "src/components/elements/DatePicker";
import Dropdown from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";
import Text from "src/components/elements/Text";

import { YearMonthCalendar } from "./YearMonthPicker";

export default DateRangePicker;

export const PICKER_TYPE = {
  MONTH: "month",
  RANGE: "range",
  CUSTOM: "custom",
};

export const RANGES = {
  THIS_MONTH: "this-month",
  LAST_MONTH: "last-month",
  LAST_3_MONTH: "last-three-month",
  YEAR_TO_DATE: "year-to-date",
  LAST_365: "last-365-days",
  LAST_YEAR: "last-year",
};

export const RANGE_LABELS = {
  [RANGES.THIS_MONTH]: "This month",
  [RANGES.LAST_MONTH]: "Last month",
  [RANGES.LAST_3_MONTH]: "Last 3 months",
  [RANGES.YEAR_TO_DATE]: "Year to date",
  [RANGES.LAST_365]: "Last 365 days",
  [RANGES.LAST_YEAR]: "Last year",
};

function isDefaultRange(range, defaultRange) {
  if (
    !range ||
    !defaultRange ||
    range.length !== 2 ||
    defaultRange.length !== 2
  ) {
    return false;
  }

  const [startDate, endDate] = range;
  const [defaultStartDate, defaultEndDate] = defaultRange;
  const s1 = startOfDay(startDate);
  const e1 = endOfDay(endDate);
  const s2 = startOfDay(defaultStartDate);
  const e2 = endOfDay(defaultEndDate);
  return isEqual(s1, s2) && isEqual(e1, e2);
}

export function guessRangeFilter(range) {
  if (!range || range.length !== 2) return null;
  try {
    const [startDate, endDate] = range;
    const now = new Date();

    // check Last 365 days
    if (
      isEqual(startOfDay(startDate), startOfDay(subDays(now, 365))) &&
      isEqual(endOfDay(endDate), endOfDay(now))
    ) {
      return RANGES.LAST_365;
    }

    // check This month
    if (
      isEqual(startOfDay(startDate), startOfDay(startOfMonth(now))) &&
      isEqual(endOfDay(endDate), endOfDay(endOfMonth(now)))
    ) {
      return RANGES.THIS_MONTH;
    }

    // check Last month
    if (
      isEqual(
        startOfDay(startDate),
        startOfDay(startOfMonth(subMonths(now, 1)))
      ) &&
      isEqual(endOfDay(endDate), endOfDay(endOfMonth(subMonths(now, 1))))
    ) {
      return RANGES.LAST_MONTH;
    }

    // check Last 3 months
    if (
      isEqual(
        startOfDay(startDate),
        startOfDay(startOfMonth(subMonths(now, 3)))
      ) &&
      isEqual(endOfDay(endDate), endOfDay(endOfMonth(subMonths(now, 1))))
    ) {
      return RANGES.LAST_3_MONTH;
    }

    // check Year to date
    if (
      isEqual(startOfDay(startDate), startOfDay(startOfYear(now))) &&
      isEqual(endOfDay(endDate), endOfDay(now))
    ) {
      return RANGES.YEAR_TO_DATE;
    }

    // check Last year
    if (
      isEqual(
        startOfDay(startDate),
        startOfDay(startOfYear(subYears(now, 1)))
      ) &&
      isEqual(endOfDay(endDate), endOfDay(endOfYear(subYears(now, 1))))
    ) {
      return RANGES.LAST_YEAR;
    }

    return null;
  } catch (err) {
    return null;
  }
}

function guessPickerType(dates, rangeFilter) {
  const isRangeFilter = !!rangeFilter;

  if (isRangeFilter) return PICKER_TYPE.RANGE;

  const [startDate, endDate] = dates || [new Date(), new Date()];
  const isFullMonthFilter =
    isEqual(startOfDay(startOfMonth(startDate)), startDate) &&
    isEqual(endOfDay(endOfMonth(endDate)), endDate);

  if (isFullMonthFilter) {
    return PICKER_TYPE.MONTH;
  }

  return PICKER_TYPE.CUSTOM;
}

/**
 * Renders a date range picker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} [props.onSelect=() => {}] - The callback function called when a date range is selected.
 * @param {Function} [props.onClear=() => {}] - The callback function called when the date range is cleared.
 * @param {Function} [props.onOpen=() => {}] - The callback function called when the date range picker is opened.
 * @param {string|Function} [props.customLabel] - The custom label for the date range picker.
 * @param {Date[]} [props.initialValue=[startOfMonth(new Date()), endOfMonth(new Date())]] - The initial value for the date range.
 * @param {Date[]} [props.defaultValue=props.initialValue] - The default value for the date range.
 * @param {string} [props.defaultPickerType] - The default picker type for the date range.
 * @returns {JSX.Element} The rendered DateRangePicker component.
 */
function DateRangePicker({
  onSelect = () => {},
  onClear = () => {},
  onOpen = () => {},
  customLabel,
  initialValue = [startOfMonth(new Date()), endOfMonth(new Date())],
  defaultValue = initialValue,
  defaultPickerType,
  defaultColor = "blue-mediumLight",
  activeColor = "blue-mediumLight",
  triggerClassName = "",
  openTriggerClassName = "",
}) {
  const guessedRangeFilter = guessRangeFilter(initialValue);
  const guessedPickerType = guessPickerType(initialValue, guessedRangeFilter);

  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("month");
  const [datePickerStart, setDatePickerStart] = useState(new Date());
  const [datePickerEnd, setDatePickerEnd] = useState(new Date());
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const [activeRangeFilter, setActiveRangeFilter] =
    useState(guessedRangeFilter);
  const [datePickerType, setDatePickerType] = useState(
    defaultPickerType || guessedPickerType
  );

  const handleClear = (e) => {
    e.stopPropagation();

    const rangeFilter = guessRangeFilter(defaultValue);
    const pickerType = guessPickerType(defaultValue, rangeFilter);

    setActiveRangeFilter(rangeFilter);
    setDatePickerType(pickerType);
    setValue(defaultValue);

    onClear?.();
  };

  const handleSelect = ({ dates, datePickerType, dateRangeType }) => {
    setValue(dates);
    setActiveRangeFilter(dateRangeType);
    setDatePickerType(datePickerType);
    setOpen(false);

    onSelect(dates, datePickerType, dateRangeType);
  };

  const [startDate, endDate] = value;

  const getLabel = () => {
    if (datePickerType === PICKER_TYPE.MONTH) {
      const monthFormat = "MMMM";
      const monthYearFormat = "MMMM, yyyy";
      if (getYear(startDate) === getYear(new Date()))
        return format(startDate, monthFormat);
      else {
        return format(startDate, monthYearFormat);
      }
    } else if (PICKER_TYPE.RANGE === datePickerType) {
      return RANGE_LABELS[activeRangeFilter];
    } else {
      const monthDayFormat = "MMM d";
      const monthDayYearFormat = "MMM d, yyyy";
      if (
        [getYear(startDate), getYear(endDate)].every(
          (year) => year === getYear(new Date())
        )
      ) {
        return `${format(startDate, monthDayFormat)} - ${format(
          endDate,
          monthDayFormat
        )}`;
      } else {
        return `${format(startDate, monthDayYearFormat)} - ${format(
          endDate,
          monthDayYearFormat
        )}`;
      }
    }
  };

  let label = getLabel();

  if (customLabel) {
    if (typeof customLabel === "function") {
      label = customLabel({
        startDate,
        endDate,
        currentLabel: label,
        datePickerType,
      });
    } else if (typeof customLabel === "string") {
      label = customLabel;
    }
  }

  const openPicker = () => {
    const cancelOperation = onOpen?.();

    if (cancelOperation) return;

    setOpen(true);
  };

  const isDefaultDate = isDefaultRange(value, defaultValue);
  const canClear = !isDefaultDate;

  return (
    <Dropdown
      testId="date-range-picker"
      className="date-range-picker"
      icon="calendar-no-days"
      label={label}
      open={open}
      triggerClassName={`${triggerClassName} bg-${
        isDefaultDate ? defaultColor : activeColor
      }`}
      openTriggerClassName={openTriggerClassName}
      hasValue
      onOpen={openPicker}
      onClose={() => setOpen(false)}
      suffix={
        canClear ? (
          <IconButton
            testId="date-range-picker-clear-btn"
            name="close"
            onClick={handleClear}
          />
        ) : null
      }
    >
      <div className="flex">
        <div className="flex flex-col p-4 hover:cursor-pointer">
          <Button
            data-testid="this-month"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.THIS_MONTH ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [startOfMonth(new Date()), endOfMonth(new Date())],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.THIS_MONTH,
              });
            }}
          >
            This month
          </Button>
          <Button
            data-testid="last-month"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.LAST_MONTH ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [
                  startOfMonth(subMonths(new Date(), 1)),
                  endOfMonth(subMonths(new Date(), 1)),
                ],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.LAST_MONTH,
              });
            }}
          >
            Last month
          </Button>
          <Button
            data-testid="last-three-month"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.LAST_3_MONTH ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [
                  startOfMonth(subMonths(new Date(), 3)),
                  endOfMonth(subMonths(new Date(), 1)),
                ],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.LAST_3_MONTH,
              });
            }}
          >
            Last 3 months
          </Button>
          <Button
            data-testid="year-to-date"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.YEAR_TO_DATE ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [startOfYear(new Date()), endOfDay(new Date())],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.YEAR_TO_DATE,
              });
            }}
          >
            Year to date
          </Button>
          <Button
            data-testid="last-365-days"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.LAST_365 ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [subDays(new Date(), 365), new Date()],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.LAST_365,
              });
            }}
          >
            Last 365 days
          </Button>
          <Button
            data-testid="last-year"
            ghost
            className={`justify-start ${
              activeRangeFilter === RANGES.LAST_YEAR ? "bg-beige-medium" : ""
            }`}
            onClick={() => {
              handleSelect({
                dates: [
                  startOfYear(subYears(new Date(), 1)),
                  endOfYear(subYears(new Date(), 1)),
                ],
                datePickerType: PICKER_TYPE.RANGE,
                dateRangeType: RANGES.LAST_YEAR,
              });
            }}
          >
            Last year
          </Button>
        </div>
        <div className="flex flex-col p-3">
          <div className="flex gap-1 rounded-[100px] bg-beige p-[5px]">
            <span onClick={() => setTab("month")} data-testid="month-tab">
              <div className={`tab ${tab === "month" ? " active" : ""}`}>
                <Text semibold>Month</Text>
              </div>
            </span>
            <span onClick={() => setTab("custom")} data-testid="custom-tab">
              <div className={`tab ${tab === "custom" ? " active" : ""}`}>
                <Text semibold>Custom range</Text>
              </div>
            </span>
          </div>
          <div className="mt-2">
            {tab === "month" && (
              <YearMonthCalendar
                value={
                  // If the range value is more than one month, don't select a date in the Month's Calendar
                  Math.abs(differenceInMonths(startDate, endDate)) > 1
                    ? []
                    : value
                }
                onSelect={(value) => {
                  handleSelect({
                    dates: value,
                    datePickerType: PICKER_TYPE.MONTH,
                    dateRangeType: null,
                  });
                }}
              />
            )}
            {tab === "custom" && (
              <>
                <div
                  className="mt-3 flex flex-col gap-2"
                  data-testid="custom-inputs"
                >
                  <Text semibold>Start date</Text>
                  <DatePicker
                    testId="start"
                    date={datePickerStart}
                    onSelect={(date) => {
                      setDatePickerStart(startOfDay(date));
                      setOpenEndCalendar(true);
                    }}
                    semibold
                    icon="calendar-no-days"
                    maxDate={datePickerEnd}
                  />
                  <Text semibold className="mt-4">
                    End date
                  </Text>
                  <DatePicker
                    testId="end"
                    open={openEndCalendar}
                    setOpen={setOpenEndCalendar}
                    date={datePickerEnd}
                    onSelect={(date) => {
                      setDatePickerEnd(endOfDay(date));
                      setOpenEndCalendar(false);
                    }}
                    semibold
                    icon="calendar-no-days"
                    minDate={datePickerStart}
                    maxDate={new Date()}
                  />
                </div>
                <Button
                  data-testid="btn-done"
                  className="mt-8"
                  primary
                  onClick={() => {
                    handleSelect({
                      dates: [datePickerStart, datePickerEnd],
                      datePickerType: PICKER_TYPE.CUSTOM,
                      dateRangeType: null,
                    });
                  }}
                >
                  Done
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Dropdown>
  );
}
