import React from "react";

import Input from "src/components/elements/Input";

import { decimalPlaces, isValidDecimal, numberOnly } from "src/services/utils";

export default DistanceInput;

function DistanceInput({
  inKm,
  disabled,
  className,
  onChange,
  fractionalDigits = 1,
  ...rest
}) {
  let inpClasses = `distance-input relative after:absolute after:right-4 ${
    className || ""
  }
  ${disabled ? "opacity-50" : ""}`;

  // for tailwindcss compiler keep it explicit
  inpClasses += inKm ? " after:content-['km']" : " after:content-['mi']";

  const currentOnChange = (e) => {
    if (
      !isValidDecimal(e.target.value) ||
      decimalPlaces(e.target.value) > fractionalDigits
    )
      return false;
    const value = numberOnly(e.target.value);
    const event = { ...e };

    event.target = {
      ...e.target,
      value,
    };

    onChange(event);
  };

  return (
    <Input
      label="Distance"
      disabled={disabled}
      onChange={(e) => currentOnChange(e)}
      type="text"
      className={inpClasses}
      {...rest}
    />
  );
}
