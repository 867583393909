import React, { useState } from "react";

import Button from "src/components/elements/Button";
import Textarea from "src/components/elements/Textarea";

const Notes = ({ notes = "", onEditDrive }) => {
  const [dirty, setDirty] = useState(false);
  const onNotesChange = (e) => {
    if (notes?.trim() === e.target.value.trim()) return;
    setDirty(false);
    onEditDrive({ notes: e.target.value });
  };

  const handleChange = (e) => {
    setDirty(notes?.trim() !== e.target.value.trim());
  };

  return (
    <div className="relative">
      {!!dirty && (
        <Button
          link
          type="button"
          className="absolute right-0 inline-block mb-2 text-right text-lg font-medium no-underline text-blue"
        >
          Save
        </Button>
      )}
      <Textarea
        testId="notes"
        label="Notes"
        height={60}
        maxHeight={250}
        defaultValue={notes}
        onChange={handleChange}
        onBlur={onNotesChange}
      />
    </div>
  );
};

export default Notes;
