import React from "react";

import useElement from "src/lib/layers/useElement";

import { useContacts } from "src/components/context/ContactsContext";
import { useAppFlash } from "src/components/context/Flash";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { REMOVE_CONTACT_MODAL_ID } from "src/components/modals/RemoveContact";

import ActionBtn from "./Actions";

export default ContactsList;

function ContactsList({ testId }) {
  const { contacts } = useContacts();
  const { flash } = useAppFlash();

  const removeContactsModal = useElement(REMOVE_CONTACT_MODAL_ID, {
    props: {
      onClose: () => {
        removeContactsModal.deactivate();
      },
      onError: handleRemoveError,
      onSuccess: handleRemoveSuccess,
    },
  });

  async function handleAction(contact) {
    removeContactsModal.activate({ props: { contact } });
  }

  function handleRemoveSuccess(message) {
    flash(<Text>{message}</Text>, {
      type: FlashTypes.SAVED,
    });
    removeContactsModal.deactivate();
  }

  function handleRemoveError(message) {
    flash(<Text>{message}</Text>, {
      type: FlashTypes.ERROR,
    });
  }

  if (!contacts?.length) {
    return false;
  }

  return (
    <div
      className="rounded border border-border-1 overflow-hidden shadow-sm [&_div:last-child]:border-none"
      data-testid={testId}
    >
      {contacts?.map((contact) => {
        let itemClass =
          "contacts-list-item h-[60px] px-3 border-b border-b-border-1 grid grid-cols-[35px_1fr_minmax(30px,auto)] gap-[25px] items-center";
        if (contact.isActive) {
          itemClass += " cursor-pointer hover:bg-[#fbfafa]";
        }
        return (
          <div key={contact.id} className={itemClass}>
            <ContactIcon />
            <div className="flex items-center gap-5 overflow-hidden">
              <ContactTitle contact={contact} />
            </div>
            <ActionBtn onAction={() => handleAction(contact)} />
          </div>
        );
      })}
    </div>
  );
}

function ContactIcon() {
  let cls =
    "min-w-[35px] h-[35px] flex items-center justify-center rounded-10 bg-[#ffe4b0]";
  return (
    <span className={cls}>
      <Icon name={"user"} color={"black"} />
    </span>
  );
}

function ContactTitle({ contact }) {
  return (
    <Text
      color={"black"}
      className="w-full flex flex-1 items-center justify-start gap-1s"
    >
      <span className="font-semibold truncate max-w-[50%]">
        {`${contact.firstName} ${contact.lastName}`}
      </span>
      <span className="mx-2">•</span>
      <span className="truncate max-w-[50%]">{contact.email}</span>
    </Text>
  );
}
