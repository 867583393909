import React, { useEffect, useRef, useState } from "react";

import { debounce } from "src/services/utils";

export default MiqTooltip;

function MiqTooltip({
  children,
  className,
  width,
  offset,
  alignX,
  timeout,
  triggerRef,
  paused,
  adjustPosition,
  ...otherProps
}) {
  const [visible, setVisible] = useState(false);
  const elRef = useRef();

  const debouncedAdjustment = debounce(calcPosition, 500);
  const showTimeout = useRef(null);

  const triggerRect = triggerRef?.current?.getBoundingClientRect?.() || {};

  useEffect(() => {
    function onMouseEnter() {
      clearTimeout(showTimeout.current);

      if (!paused) {
        showTimeout.current = setTimeout(() => setVisible(true), timeout || 0);
      }
    }
    function onMouseLeave() {
      // timeout before hiding the tooltip
      // so user can hover it and tooltip will remain open while hovered
      clearTimeout(showTimeout.current);
      setTimeout(() => {
        setVisible(false);
      }, 100);
    }

    function onWindowChange() {
      onMouseLeave();
      debouncedAdjustment();
    }

    if (triggerRef?.current) {
      triggerRef.current.addEventListener("mouseenter", onMouseEnter);
      triggerRef.current.addEventListener("mouseleave", onMouseLeave);
      triggerRef.current.addEventListener("click", onMouseLeave);

      // add event listener to scroll, resize and zoom
      window.addEventListener("resize", onWindowChange);
      window.addEventListener("scroll", onWindowChange);
      window.addEventListener("zoom", onWindowChange);
      window.addEventListener("wheel", onWindowChange);
      window.addEventListener("gesturechange", onWindowChange);
    }

    return () => {
      if (triggerRef?.current) {
        triggerRef.current.removeEventListener("mouseenter", onMouseEnter);
        triggerRef.current.removeEventListener("mouseleave", onMouseLeave);
        triggerRef.current.removeEventListener("click", onMouseLeave);

        // remove event listener to scroll, resize and zoom
        window.removeEventListener("resize", onWindowChange);
        window.removeEventListener("scroll", onWindowChange);
        window.removeEventListener("zoom", onWindowChange);
        window.removeEventListener("wheel", onWindowChange);
        window.removeEventListener("gesturechange", onWindowChange);
      }
    };
  }, [triggerRef, paused]);

  useEffect(() => {
    calcPosition();
  }, [elRef.current, triggerRect]);

  function calcPosition() {
    if (!elRef?.current || !triggerRef?.current) return;

    const el = elRef.current;

    // fixed position is calculated relative to the containing block
    // most of the time it is the viewport
    // but containing block could also be the first parent element with transform property set
    // so first we need to position the element at the top of its containing block
    el.style.position = "fixed";
    el.style.top = 0;
    el.style.left = 0;

    // elRect will have .x set to the offset of the containing block
    // if containing block is viewport .x === 0
    const elRect = el.getBoundingClientRect();

    // by default align tooltip to the top left of trigger
    let top = (offset?.y || 0) + triggerRect.y - elRect.y;
    let left = (offset?.x || 0) + triggerRect.x - elRect.x;
    switch (alignX) {
      case "center":
        left += triggerRect.width / 2 - elRect.width / 2;
        break;
      case "right":
        left += triggerRect.width - elRect.width;
        break;
      case "left":
      default:
        break;
    }

    if (typeof adjustPosition === "function") {
      const newPos = adjustPosition(top, left, elRect, triggerRect);
      top = newPos?.top || top;
      left = newPos?.left || left;
    }

    el.style.top = `${top}px`;
    el.style.left = `${left}px`;
    el.style.zIndex = "999999";
    el.style.width = width ? `${width}px` : "auto";
  }

  const cls = ["miq-tooltip"];
  cls.push(visible ? "is-visible" : "is-hidden");
  if (className) cls.push(className);

  return (
    <div ref={elRef} className={cls.join(" ")} {...otherProps}>
      {children}
    </div>
  );
}
