import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useQueryParams from "src/hooks/useQueryParams";

import { VERIFY_EMAIL } from "src/graphql/mutations";

// Blank page that will be used to send a request to the backend to verify email.
const VerifyEmail = () => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    notifyOnNetworkStatusChange: true,
  });

  const verificationSecret = searchParams?.get?.("verification");
  const username = searchParams?.get?.("username");

  useEffect(() => {
    verifyEmail({
      variables: {
        username,
        verificationSecret,
      },
    }).then(() => {
      history.push("/user/settings/profile", { isVerification: true });
    });
  }, [verificationSecret, username]);
  return <></>;
};

export default VerifyEmail;
