import { useMutation } from "@apollo/client";
import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

const { DELETE_INTEGRATION } = require("src/graphql/mutations");

export default RemoveConcurIntegrationModal;

export const ELEMENT_ID = "REMOVE_CONCUR_INTEGRATION_MODAL";
registerElement(ELEMENT_ID, RemoveConcurIntegrationModal);

function RemoveConcurIntegrationModal({
  onClose,
  onSuccess,
  onError,
  integration,
}) {
  const [removeIntegration, { loading: removeIntegrationLoading }] =
    useMutation(DELETE_INTEGRATION, { notifyOnNetworkStatusChange: true });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await removeIntegration({
        variables: { integrationId: integration?.id },
      });

      onSuccess(`Your Concur integration has been removed`);
    } catch (error) {
      console.log(error);
      onError(
        `Something went wrong while removing your Concur integration. Please try again.`
      );
    }
  };

  return (
    <Modal closable={false} className="w-[400px]" onClose={onClose}>
      <h5>Are you sure you want to remove Concur integration?</h5>
      <p className="mt-2">
        Removing the integration will impact all members of your team.
      </p>
      <div className="flex justify-end gap-2 mt-6">
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
        <Button
          destructive
          loading={removeIntegrationLoading}
          onClick={handleSubmit}
        >
          {removeIntegrationLoading ? "Loading" : "Remove"}
        </Button>
      </div>
    </Modal>
  );
}
