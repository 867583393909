import { kmToMi, roundDistance } from "./utils";

export async function getPredictions(query) {
  if (!window.google?.maps?.places?.AutocompleteService) return [];

  const acs = new window.google.maps.places.AutocompleteService();

  if (!acs) return [];

  const res = await acs.getPredictions({ input: query });

  return res.predictions;
}

export async function getPlaceDetails(placeId, { map }) {
  return new Promise((resolve) => {
    if (!window.google?.maps?.places?.PlacesService) {
      resolve(null);
      return null;
    }

    const ps = new window.google.maps.places.PlacesService(map);

    if (!ps) {
      resolve(null);
      return null;
    }

    ps.getDetails({ placeId, fields: ["geometry.location"] }, (res) => {
      resolve(res.geometry);
    });
  });
}

/**
 *
 * @param {{latitude: Number, longitude: Number}} startLocation
 * @param {{latitude: Number, longitude: Number}} endLocation
 * @param {Boolean} inMiles
 * @returns
 */
export async function getDirectionData(
  startLocation,
  endLocation,
  inMiles = true
) {
  if (!window.google?.maps?.DirectionsService) {
    return null;
  }

  const ds = new window.google.maps.DirectionsService();

  if (!ds) {
    return null;
  }

  let distance = 0;
  let duration = 0;

  try {
    const res = await ds.route({
      origin: { lat: startLocation.latitude, lng: startLocation.longitude },
      destination: { lat: endLocation.latitude, lng: endLocation.longitude },
      // NOTE: shouldn't this be related to the drive's travel mode?
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    const data = res.routes[0].legs[0];
    const distanceInMeters = Math.abs(data?.distance?.value || 0);

    if (inMiles) {
      distance = kmToMi(distanceInMeters / 1000);
    } else {
      distance = distanceInMeters / 1000;
    }

    duration = Math.abs(data?.duration?.value || 0);
  } catch (err) {
    console.log(err);
  }

  return { distance: roundDistance(distance), duration };
}

// Decode an encoded levels string into an array of levels.
export const decodeLevels = (encodedLevelsString) => {
  const decodedLevels = [];

  for (let i = 0; i < encodedLevelsString.length; ++i) {
    const level = encodedLevelsString.charCodeAt(i) - 63;
    decodedLevels.push(level);
  }

  return decodedLevels;
};
