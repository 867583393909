import React from "react";
import { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

export default ExistingNamedLocation;

export const ELEMENT_ID = "EXISTING_NAMED_LOCATION";
registerElement(ELEMENT_ID, ExistingNamedLocation);

function ExistingNamedLocation({
  onClose,
  onRenameNamedLocation,
  onCreateNamedLocation,
}) {
  const [createLoading, setCreateLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);

  const onCreateClick = async () => {
    setCreateLoading(true);
    await onCreateNamedLocation();
    setCreateLoading(false);
    onClose();
  };

  const onRenameClick = async () => {
    setRenameLoading(true);
    await onRenameNamedLocation();
    setRenameLoading(false);
    onClose();
  };

  return (
    <Modal closable show onClose={onClose} className="named-location-modal">
      <div className="text-center">
        <h4>Are you sure?</h4>
        <Text center paragraph regular className="mt-3">
          Changing the name of this location means all previous and future
          visits here will be changed to this name.
        </Text>
      </div>
      <div className="mt-7 grid grid-cols-2 gap-[10px]">
        <Button lg loading={createLoading} secondary onClick={onCreateClick}>
          {createLoading ? "Loading..." : "Create new location"}
        </Button>
        <Button lg loading={renameLoading} onClick={onRenameClick}>
          {renameLoading ? "Loading..." : "Rename"}
        </Button>
      </div>
    </Modal>
  );
}
