import { useContext } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import SadCloudyCar2 from "public/assets/img/illustrations/sad-cloudy-car-2.png";
import SadCloudyCar from "public/assets/img/illustrations/sad-cloudy-car.png";

import TeamContext from "../context/TeamContext";
import { useUserData } from "../context/UserContext";

export const DUNNING_DRIVERS_WARNING_MODAL_ID =
  "DUNNING_DRIVERS_WARNING_MODAL_ID";

registerElement(DUNNING_DRIVERS_WARNING_MODAL_ID, DunningDriversWarning);

function DunningDriversWarning({ onClose }) {
  const { team } = useContext(TeamContext);
  const {
    userData: { isPremium, subscriptionData },
  } = useUserData();

  const isFree = !isPremium || !subscriptionData.status;
  const teamOwnerEmail = team?.owner?.email || "";

  const AdminSnippet = () => (
    <a className="text-blue" href={`mailto:${teamOwnerEmail}`}>
      {teamOwnerEmail}
    </a>
  );

  const expiredDescriptionCopy = () => (
    <p>
      {isFree &&
        "You now have a free account that's limited to 40 drives per month. Miss unlimited drives and easy report submission? "}
      Contact your admin at <AdminSnippet /> to ask about resubscribing.
    </p>
  );
  const gracePeriodDescriptionCopy = () => (
    <p>
      Your team's subscription ends on {team?.longExpiredDateText}.{" "}
      {isFree && "Don't want to lose access to unlimited drives?"} Check in with
      your admin at <AdminSnippet />.
    </p>
  );
  const titleCopy = team?.isExpired
    ? "Your team's subscription has expired"
    : "Your team's MileIQ subscription is ending soon";
  const DescriptionCopy = team?.isExpired
    ? expiredDescriptionCopy
    : gracePeriodDescriptionCopy;
  const Illustration = team?.isExpired ? SadCloudyCar2 : SadCloudyCar;

  return (
    <Modal
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      passiveBackdrop
    >
      <div className="flex flex-col gap-[30px]">
        <div>
          <h5>{titleCopy}</h5>
          <div className="mt-2.5">
            <DescriptionCopy />
          </div>
        </div>
        <div className="w-full flex justify-center">
          <img
            src={Illustration}
            alt="Illustration of a sad car"
            className="h-[213px]"
          />
        </div>
        <div className="flex items-center gap-2.5 justify-end">
          <Button
            className="font-medium min-w-[120px]"
            type="submit"
            onClick={onClose}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
}
