import b from "./util/net.js";
import ue from "./models/braze-event.js";
import s from "./common/event-logger.js";
import { randomInclusive as pt } from "./util/math.js";
import t from "./models/request-result.js";
import r from "../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "./managers/storage-manager.js";
import u from "./managers/subscription-manager.js";
import dt from "./Push/utils/push-utils.js";
export default class Lt {
  constructor(t, s, i, e, n, l, h, o, r, a) {
    (this._r = t),
      (this.Bo = s),
      (this.kl = 0),
      (this.yl = h.Pa()),
      (this.jl = null),
      (this.j = i),
      (this.Ur = e),
      (this.v = n),
      (this.gt = l),
      (this.h = h),
      (this.Ko = r),
      (this.vt = a),
      (this.$l = o),
      (this.Al = new u()),
      (this.Tl = 50);
  }
  ql(t, s) {
    return !t && !s && this.Ko.Yn() >= this.Tl;
  }
  Fl(t) {
    let s = this.j.gh();
    if (t.length > 0) {
      const i = this.v.getUserId();
      for (const e of t) {
        const t = (!e.userId && !i) || e.userId === i;
        e.type === r.A.Ch && t && (s = !0);
      }
    }
    return s;
  }
  Dl(t, s, i, e, n, l, h) {
    null == i && (i = !0), i && this.Ll();
    const u = this.h.Ua(),
      a = this.h.Ba();
    let c = !1;
    const d = (t, s) => {
        let h = !1;
        b.Hs({
          url: this.Bo + "/data/",
          data: t,
          headers: s,
          S: i => {
            null != t.respond_with &&
              t.respond_with.triggers &&
              (this.kl = Math.max(this.kl - 1, 0)),
              this.vt.Os(t, i, s)
                ? (this.Ko.Qs(),
                  this.gt.al(i),
                  (null != t.respond_with &&
                    t.respond_with.user_id != this.v.getUserId()) ||
                    (null != t.device && this.h.B(o.q.Yo, t.device),
                    null != t.sdk_metadata &&
                      (this.h.B(o.q.ua, t.sdk_metadata),
                      this.h.B(o.q.ha, this.j.ca())),
                    this.$l(i),
                    "function" == typeof e && e()))
                : i.auth_error && (h = !0);
          },
          error: () => {
            null != t.respond_with &&
              t.respond_with.triggers &&
              (this.kl = Math.max(this.kl - 1, 0)),
              this.vt.la(t.events, t.attributes),
              "function" == typeof n && n();
          },
          Pl: t => {
            if (("function" == typeof l && l(t), i && !c)) {
              if (t && !h) this.Rl();
              else {
                let t = this.jl;
                (null == t || t < 1e3 * this.yl) && (t = 1e3 * this.yl),
                  this.Rl(Math.min(3e5, pt(1e3 * this.yl, 3 * t)));
              }
              c = !0;
            }
          }
        });
      },
      m = this.Fl(u),
      f = s || m;
    if (this.ql(h, m))
      return void r.D.info(
        "Declining to flush data due to 50 consecutive authentication failures"
      );
    if (i && !this.vt.ga(u, a, t, f))
      return this.Rl(), void ("function" == typeof l && l(!0));
    const g = this.vt.qa(t, f, u, a);
    f && this.kl++;
    let p = !1;
    if (g)
      for (const t of g)
        this.vt.Gs(t.requestData, () => d(t.requestData, t.headers)), (p = !0);
    this.Ko.Jn() && i && !p
      ? this.Rl()
      : m && (r.D.info("Invoking new session subscriptions"), this.Al.St());
  }
  Ml() {
    return this.kl > 0;
  }
  Rl(t) {
    this.Il ||
      (null == t && (t = 1e3 * this.yl),
      this.Ll(),
      (this.Nl = setTimeout(() => {
        if (document.hidden) {
          const t = "visibilitychange",
            s = () => {
              document.hidden ||
                (document.removeEventListener(t, s, !1), this.Dl());
            };
          document.addEventListener(t, s, !1);
        } else this.Dl();
      }, t)),
      (this.jl = t));
  }
  Ll() {
    null != this.Nl && (clearTimeout(this.Nl), (this.Nl = null));
  }
  initialize() {
    (this.Il = !1), this.Rl();
  }
  destroy() {
    this.Al.removeAllSubscriptions(),
      this.Ko.Wn(),
      this.Ll(),
      (this.Il = !0),
      this.Dl(null, null, !1),
      (this.Nl = null);
  }
  cr(t) {
    return this.Al.ut(t);
  }
  openSession() {
    const t = this.j.ca() !== this.j.mo();
    t && (this.h.ya(o.eu.Jo), this.h.ya(o.eu.su)),
      this.Dl(null, !1, null, null, null),
      this.Ln(),
      t &&
        import("./Push/push-manager-factory.js").then(t => {
          if (this.Il) return;
          const s = t.default.aa();
          if (
            null != s &&
            (dt.isPushPermissionGranted() || dt.isPushBlocked())
          ) {
            const t = () => {
                s.Gr()
                  ? r.D.info(
                      "Push token maintenance is disabled, not refreshing token for backend."
                    )
                  : s.subscribe();
              },
              i = (s, i) => {
                i && t();
              },
              e = () => {
                const s = this.h.I(o.q.Pn);
                (null == s || s) && t();
              },
              n = r.xt.Ft;
            new r.qt(n, r.D).hr(n.$t.cu, i, e);
          }
        });
  }
  changeUser(t, s, i) {
    const e = this.v.getUserId();
    if (e !== t) {
      this.j.Gh(),
        null != e && this.Dl(null, !1, null, null, null),
        this.v.uu(t),
        i ? this.Ko.setSdkAuthenticationSignature(i) : this.Ko.Qn();
      for (let t = 0; t < s.length; t++) s[t].changeUser(null == e);
      null != e && this.h.Xs(o.q.P),
        this.h.Xs(o.q.Yo),
        this.openSession(),
        r.D.info('Changed user to "' + t + '".');
    } else {
      let s = "Doing nothing.";
      i &&
        this.Ko.Kn() !== i &&
        (this.Ko.setSdkAuthenticationSignature(i),
        (s = "Updated SDK authentication signature")),
        r.D.info(`Current user is already ${t}. ${s}`);
    }
  }
  requestImmediateDataFlush(t) {
    this.Ll(), this.j.mo();
    this.Dl(
      null,
      null,
      null,
      null,
      () => {
        r.D.error(
          "Failed to flush data, request will be retried automatically."
        );
      },
      t,
      !0
    );
  }
  requestFeedRefresh() {
    this.j.mo(), this.Dl(!0);
  }
  zr(t, s) {
    this.j.mo(),
      r.D.info("Requesting explicit trigger refresh."),
      this.Dl(null, !0, null, t, s);
  }
  Nn(t, i) {
    const e = r.A.Ol,
      n = { a: t, l: i },
      l = s.G(e, n);
    return l && r.D.info(`Logged alias ${t} with label ${i}`), l;
  }
  In(i, e, n) {
    if (this.gt.hu(e))
      return (
        r.D.info(`Custom Attribute "${e}" is blocklisted, ignoring.`), new t()
      );
    const l = { key: e, value: n },
      h = s.G(i, l);
    return h && r.D.info(`Logged custom attribute: ${e} with value: ${n}`), h;
  }
  setLastKnownLocation(t, i, e, n, l, h) {
    const o = { latitude: i, longitude: e };
    null != n && (o.altitude = n),
      null != l && (o.ll_accuracy = l),
      null != h && (o.alt_accuracy = h);
    const u = s.G(r.A.Ul, o, t);
    return (
      u &&
        r.D.info(
          `Set user last known location as ${JSON.stringify(o, null, 2)}`
        ),
      u
    );
  }
  kr(t, s) {
    const i = this.j.mo();
    return new ue(this.v.getUserId(), r.A.xl, t, i, { cid: s });
  }
  Ln() {
    const t = r.xt.Ft;
    new r.qt(t, r.D).setItem(t.$t.Kl, 1, {
      baseUrl: this.Bo,
      data: { api_key: this._r, device_id: this.Ur.te().id },
      userId: this.v.getUserId(),
      sdkAuthEnabled: this.Ko.Jn()
    });
  }
  wr(t) {
    for (const s of t)
      if (s.api_key === this._r) this.vt.la(s.events, s.attributes);
      else {
        const t = r.xt.Ft;
        new r.qt(t, r.D).setItem(t.$t.$r, r.it.nt(), s);
      }
  }
  Tn(i, e, n) {
    if (this.gt.hu(i))
      return (
        r.D.info(`Custom Attribute "${i}" is blocklisted, ignoring.`), new t()
      );
    let l, h;
    return (
      null === e && null === n
        ? ((l = r.A.Wl), (h = { key: i }))
        : ((l = r.A.Bl), (h = { key: i, latitude: e, longitude: n })),
      s.G(l, h)
    );
  }
  $n(t, i) {
    const e = { group_id: t, status: i };
    return s.G(r.A.Gl, e);
  }
}
