import Base from "src/models/base";

export default class CommuteSettings extends Base {
  userParseId = null;
  userEmail = null;
  userFirstName = null;
  userLastName = null;
  distance = null;
  createdAt = null;

  constructor(data) {
    super("commute_settings_model");
    if (data) {
      this.distance = data.distance !== null ? parseFloat(data.distance) : null;
      this.userEmail = data.user_email || null;
      this.userFirstName = data.user_first_name || null;
      this.userLastName = data.user_last_name || null;
      this.userParseId = data.user_parse_id || null;
      this.createdAt = data.created_at || null;
    }
  }

  get isEnabled() {
    return this.distance != null;
  }
}
