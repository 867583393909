import r from "../../shared-lib/braze-shared-lib.js";
import ee from "./feature-flag.js";
export function newFeatureFlagFromJson(e) {
  if (e[ee.At.ns] && "boolean" == typeof e[ee.At.Fe])
    return new ee(e[ee.At.ns], e[ee.At.Fe], e[ee.At.we]);
  r.D.info(`Unable to create feature flag from ${JSON.stringify(e, null, 2)}`);
}
export function newFeatureFlagFromSerializedValue(e) {
  if (e[ee.hs.ns] && "boolean" == typeof e[ee.hs.Fe])
    return new ee(e[ee.hs.ns], e[ee.hs.Fe], e[ee.hs.we]);
  r.D.info(
    `Unable to deserialize feature flag from ${JSON.stringify(e, null, 2)}`
  );
}
