import "src/models/typings";

import React from "react";

import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import RouteInput, { LocationType } from "./RouteInput";

/**
 *
 * @param {{ drive: Drive }} props
 * @returns
 */
const Routes = ({ drive }) => {
  const { miqDashMapDashboardRoutesWeb } = useFlags();
  const { startLocation, startDate, endLocation, endDate, id, secondId } =
    drive;
  return (
    <div className="flex flex-col gap-[10px]">
      <Text semibold>Route</Text>
      <RouteInput
        location={startLocation}
        locationType={LocationType.START}
        driveId={id}
        isRoundTripStop={drive.isRoundTripStop}
        date={startDate}
        dateFormatter={drive.dateFormatter}
      />
      {miqDashMapDashboardRoutesWeb && drive.isRoundTripStop && (
        <RouteInput
          location={drive.stopLocation}
          locationType={LocationType.STOP}
          driveId={id}
          isRoundTripStop={drive.isRoundTripStop}
          date={drive.stopDate}
          dateFormatter={drive.dateFormatter}
        />
      )}
      <RouteInput
        location={endLocation}
        locationType={LocationType.END}
        driveId={
          miqDashMapDashboardRoutesWeb && drive.isRoundTripStop ? secondId : id
        }
        isRoundTripStop={drive.isRoundTripStop}
        date={endDate}
        dateFormatter={drive.dateFormatter}
      />
    </div>
  );
};

export default Routes;
