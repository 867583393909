import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDrives } from "src/components/context/TeamDrivesContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import MiqInput from "src/components/elements/Input";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import StaticMap from "src/components/blocks/StaticMap";

import { VehicleTypeIcon } from "src/components/pages/DriverDashboard/settings/vehicle-and-odometer/VehiclesList";

import { useFlags } from "src/hooks/useFlags";
import { guessPageFromPath } from "src/hooks/usePageTracking";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import Comment from "src/models/comment";

import { report } from "src/services/error-reporting";
import { getPurposeIcon } from "src/services/purposes";
import { getDriveComments, postDriveComment } from "src/services/teams";
import {
  trackTeamsDriveCommentCompleted,
  trackTeamsDriveCommentFailed,
  trackTeamsDriveCommentStarted,
} from "src/services/tracking";
import { formatCurrency } from "src/services/utils";

import { SIGNED_MAP_URL } from "src/graphql/queries";

import DistanceWithCommute from "./DistanceWithCommute";

export default TeamDriveSummary;

function TeamDriveSummary({
  drive,
  onClose,
  showSubmittedAt,
  distanceUnits = "mi",
  currency = "$",
  showComments = false,
}) {
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const { miqAdminCommentsOnDrivesAllPlatforms } = useFlags();
  const [scrollTop, setScrollTop] = useState(0);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const contentContainerRef = useRef(null);
  const isCommentsToBeShown =
    showComments && miqAdminCommentsOnDrivesAllPlatforms;

  const checkOverflow = () => {
    if (contentContainerRef?.current) {
      setIsContentOverflowing(
        contentContainerRef.current.scrollHeight >
          contentContainerRef.current.clientHeight
      );
    }
  };

  // Add internal cache state since Apollo won't tell us if `onCompleted` was
  // called from cache or network
  const [cachedImages, setCachedImage] = useState([]);

  const { loading: loadingSignedMap, data } = useQuery(SIGNED_MAP_URL, {
    skip: !drive,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const startEndUrl = data?.signedMapUrl?.startEndUrl;

      if (cachedImages.includes(startEndUrl)) {
        setLoadingImage(false);
        setImageSrc(startEndUrl);
        return;
      }

      setLoadingImage(true);

      const fetchedImg = new Image();
      fetchedImg.src = startEndUrl;
      fetchedImg.onload = () => {
        setLoadingImage(false);
        setImageSrc(fetchedImg.src);
        setCachedImage((prev) => [...prev, startEndUrl]);
      };
    },
    variables: {
      driveId: drive?.drive_parse_id,
      driverId: drive?.user_parse_id,
      withRoutes: true,
      startLocation: {
        latitude: Number(drive?.start_loc_lat),
        longitude: Number(drive?.start_loc_long),
      },
      endLocation: {
        latitude: Number(drive?.end_loc_lat),
        longitude: Number(drive?.end_loc_long),
      },
      height: isCommentsToBeShown ? 148 : null,
    },
  });

  if (!drive) return null;

  const {
    id: driveId,
    drive_parse_id: driveParseId,
    started_at: startedAt,
    ended_at: endedAt,
    is_manually_added: isManual,
    notes,
    vehicle_name: vehicleName,
    vehicle_type: vehicleType,
    distance,
    total_value: value,
    parking_fees: parking,
    toll_fees: tolls,
    purpose,
    start_location: startLocation,
    end_location: endLocation,
    start_loc_name: startLocationName,
    end_loc_name: endLocationName,
    start_loc_lat: startLocLatitude,
    start_loc_long: startLocLongitude,
    end_loc_lat: endLocLatitude,
    end_loc_long: endLocLongitude,
    submitted_at: submittedAt,
    commute_distance: commuteDistance,
    total_distance: totalDistance,
  } = drive;

  const id = driveId || driveParseId;
  const startLocName = startLocation || startLocationName;
  const endLocName = endLocation || endLocationName;
  const startDate = new Date(startedAt);
  const endDate = new Date(endedAt);
  const startTime = format(startDate, "h:mm a");
  const endTime = format(endDate, "h:mm a");
  const extras = parseFloat(parking) + parseFloat(tolls);
  const totalValue = parseFloat(value) + extras;

  let formattedSubmissionDate = "";

  if (submittedAt) {
    const submissionDate = new Date(submittedAt);
    formattedSubmissionDate = format(submissionDate, "MMM d, yyyy");
  }

  const handleCloseSummary = () => {
    onClose?.();
  };

  const loading = loadingSignedMap || loadingImage;

  drive.map = {
    startEndUrl: imageSrc,
  };

  const purposeLabel =
    purpose === "Business + Commute" ? "With commute" : purpose;
  const purposeIcon =
    purpose === "Business + Commute" ? "building" : getPurposeIcon(2, purpose);

  return (
    <>
      <div className="px-5 pb-4">
        <Button
          icon="close"
          className="btn-close"
          onClick={handleCloseSummary}
        />
        <div className="px-1 pt-2">
          <h5 className="flex items-center gap-2.5 mb-[6px]">
            <span>{formatCurrency({ value: totalValue, currency })}</span>
            <span>&#8226;</span>
            <DistanceWithCommute
              distanceUnit={distanceUnits}
              totalDistance={totalDistance}
              finalDistance={distance}
              commute={commuteDistance}
              tooltipOffset={{ y: 50 }}
              className="high-contrast"
            >
              <span>
                {distance?.toFixed?.(1) || 0} {distanceUnits}
              </span>
            </DistanceWithCommute>
          </h5>
          {showSubmittedAt && (
            <p className="text-12 text-black/80">
              Submitted on {formattedSubmissionDate}
            </p>
          )}
        </div>
      </div>
      <div
        ref={contentContainerRef}
        onScroll={(e) => setScrollTop(e.target.scrollTop)}
        className={
          (isContentOverflowing ? "border-b " : "") +
          "overflow-y-auto px-5 pb-4 grow border-beige-dark transition-all " +
          (scrollTop > 0 ? " border-t border-solid " : "")
        }
      >
        <div className="p-0 flex flex-col info-card border border-border-1">
          <div className={isCommentsToBeShown ? "h-[148px]" : "h-[225px]"}>
            {loading ? (
              <Loader sm />
            ) : (
              <StaticMap
                imgClassName="rounded-b-none"
                unit={distanceUnits || "mi"}
                currency={currency}
                drive={{
                  ...drive,
                  routeWaypoints: data?.signedMapUrl?.routeWaypoints,
                  id,
                  value: Number(value),
                  parkingFees: Number(parking),
                  tollFees: Number(tolls),
                  ignoreDistanceTransformation: true,
                  googleDistance: distance,
                  startDate,
                  endDate,
                  startLocation: {
                    displayName: startLocName,
                    latitude: startLocLatitude,
                    longitude: startLocLongitude,
                  },
                  endLocation: {
                    displayName: endLocName,
                    latitude: endLocLatitude,
                    longitude: endLocLongitude,
                  },
                }}
              />
            )}
          </div>
          <div className="flex items-center justify-center gap-2 py-3 px-4">
            <div className="flex flex-col flex-grow gap-3">
              <div className="flex justify-between relative gap-2">
                <div className="absolute h-[calc(100%-3px)] top-[18px] left-[6px] border-l-[1px] border-black/30 border-dashed" />
                <div className="flex gap-2">
                  <div className="start-icon mt-1" />
                  <Text>{startLocName}</Text>
                </div>

                <div className="whitespace-nowrap">
                  <Text right paragraph color="black/70">
                    {startTime}
                  </Text>
                </div>
              </div>
              <div className="flex justify-between gap-2">
                <div className="flex gap-2">
                  <div className="end-icon mt-1" />
                  <Text>{endLocName}</Text>
                </div>
                <div className="whitespace-nowrap">
                  <Text right paragraph color="black/70">
                    {endTime}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex flex-col info-card border border-border-1">
          {isManual && isManual !== " No" && (
            <div className="mb-3 flex items-center">
              <Icon name="m-in-circle" className="mr-3" />
              <Text>Manually added</Text>
            </div>
          )}
          <div className="flex items-center">
            <Icon name={purposeIcon} className="mr-3" />
            <Text>{purposeLabel}</Text>
          </div>
        </div>
        <div className="mt-3 flex flex-col info-card border border-border-1">
          <div className="flex justify-between">
            <div className="flex flex-col w-1/3">
              <Text md color="black/70" className="mb-2">
                Drive
              </Text>
              <Text>
                {formatCurrency({ value: parseFloat(value), currency })}
              </Text>
            </div>
            <div className="flex flex-col w-1/3">
              <Text md color="black/70" className="mb-2">
                Parking
              </Text>
              <Text>
                {formatCurrency({ value: parseFloat(parking), currency })}
              </Text>
            </div>
            <div className="flex flex-col w-1/3">
              <Text md color="black/70" className="mb-2">
                Tolls
              </Text>
              <Text>
                {formatCurrency({ value: parseFloat(tolls), currency })}
              </Text>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex flex-col">
              <Text md color="black/70" className="mb-2">
                Vehicle
              </Text>
              {vehicleName ? (
                <div className="flex">
                  <Icon name={VehicleTypeIcon[vehicleType]} className="mr-2" />
                  <Text>{vehicleName}</Text>
                </div>
              ) : (
                <Text>-</Text>
              )}
            </div>
          </div>
          <div className="mt-6">
            <div className="flex flex-col">
              <Text md color="black/70" className="mb-2">
                Notes
              </Text>
              <Text>{notes || "-"}</Text>
            </div>
          </div>
        </div>
        {isCommentsToBeShown && (
          <div data-chmln="team-drive-comments">
            <TeamDriveComments checkOverflow={checkOverflow} drive={drive} />
          </div>
        )}
      </div>
    </>
  );
}

function TeamDriveComments({ checkOverflow, drive }) {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [isError, setIsError] = useState(false);
  const commentsContainerRef = useRef(null);
  const { team } = useContext(TeamContext);
  const { addCommentToSelectedDrive } = useTeamDrives();
  const { checkAndHandleDunning } = useTeamsCTA();

  useEffect(() => {
    setIsPostingComment(false);
    setIsError(false);
    setNewComment("");
    if (drive) {
      setLoadingComments(true);
      getDriveComments(drive.id)
        .then((res) => {
          setComments(res.comments?.map((comment) => new Comment(comment)));
        })
        .catch((error) => {
          console.error(error);
          report(error);
          setIsError(true);
        })
        .finally(() => {
          setLoadingComments(false);
        });
    }

    const commentsContainer = commentsContainerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (commentsContainer) {
      resizeObserver.observe(commentsContainer);
    }

    checkOverflow();

    return () => {
      if (commentsContainer) resizeObserver.disconnect(commentsContainer);
    };
  }, [drive?.id]);

  const handleSubmitComment = (e) => {
    e.preventDefault();
    if (!newComment) return;

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    setIsPostingComment(true);
    commentsContainerRef.current.scrollTop = 0;

    postDriveComment(drive.id, newComment)
      .then((res) => {
        const commentData = new Comment(res.drive_comment);
        setComments((prev) => [commentData, ...prev]);
        addCommentToSelectedDrive();
        setNewComment("");
        setIsError(false);
        trackTeamsDriveCommentCompleted({
          orgId: team.orgId,
          orgGroupId: team.orgGroupId,
          subPlan: team.subscription?.plan,
          webPage: guessPageFromPath(window.location.pathname),
        });
      })
      .catch((error) => {
        console.error(error);
        report(error);
        setIsError(true);
        trackTeamsDriveCommentFailed({
          orgId: team.orgId,
          orgGroupId: team.orgGroupId,
          subPlan: team.subscription?.plan,
          webPage: guessPageFromPath(window.location.pathname),
        });
      })
      .finally(() => {
        setIsPostingComment(false);
      });
  };

  return (
    <div className="mt-3 info-card border border-border-1 pb-1 px-0">
      <div className="px-3">
        <div className="flex gap-2">
          <Icon name="comment-dots" />
          <span className="text-14 font-semibold">Comments</span>
        </div>
        <div className="my-2">
          <form onSubmit={handleSubmitComment}>
            <MiqInput
              placeholder="Enter a comment"
              className={`h-[40px] ${
                newComment.length > 300 ? "border-2 border-[#D7260D]" : ""
              }`}
              value={newComment}
              disabled={isPostingComment}
              onFocus={() => {
                trackTeamsDriveCommentStarted({
                  orgId: team.orgId,
                  orgGroupId: team.orgGroupId,
                  subPlan: team.subscription?.plan,
                  webPage: guessPageFromPath(window.location.pathname),
                });
              }}
              onChange={(e) => {
                setIsError(false);
                setNewComment(e.target.value);
              }}
              rightIcon={
                <Button
                  className={"m-1 h-[32px] min-w-[32px] w-[32px] rounded-8"}
                  secondary
                  disabled={!newComment || newComment.length > 300}
                  loadingClassName={"mr-0 min-w-[16px]"}
                  loading={isPostingComment}
                  responsiveLoader
                  icon={"enter-key"}
                  onClick={handleSubmitComment}
                />
              }
            />
            {newComment.length > 300 && (
              <div className="flex flex-row gap-1 mt-1 items-start leading-tight">
                <Icon name="red-exclamation-circle-small" />
                <Text className={"text-[#D7260D] pt-1"}>
                  {"Please shorten text to under 300 characters."}
                </Text>
              </div>
            )}
            {isError && (
              <div className="flex flex-row gap-1 mt-1 items-start leading-tight">
                <Icon name="red-exclamation-circle-small" />
                <Text className={"text-[#D7260D] pt-1"}>
                  {"There was an error. Please try again later."}
                </Text>
              </div>
            )}
          </form>
        </div>
      </div>
      <div
        ref={commentsContainerRef}
        className="overflow-y-auto max-h-[240px] px-4"
      >
        {loadingComments && (
          <>
            <div className="w-full h-[16px] rounded-4 bg-black/10 animate-pulse mt-1" />
            <div className="w-1/2 h-[16px] rounded-4 bg-black/10 animate-pulse mt-1" />
          </>
        )}
        {!loadingComments &&
          comments?.map((comment) => {
            return (
              <div key={comment.id} className="flex-1 mb-2 break-words">
                <Text paragraph className="leading-normal">
                  {comment.text}
                </Text>
                <Text paragraph sm className="leading-relaxed" color="black/60">
                  {comment.firstName && comment.lastName
                    ? `${comment.firstName} ${comment.lastName}`
                    : comment.email}
                  {" · "}
                  {format(new Date(comment.createdAt), "MMM d, yyyy")}
                </Text>
              </div>
            );
          })}
      </div>
    </div>
  );
}
