import { useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import {
  formatCurrency,
  getDriveValue,
  roundDistance,
  toUIDistance,
} from "src/services/utils";

import { POST_DRIVE_FEEDBACK } from "src/graphql/mutations";
import { GET_DRIVE_FEEDBACK } from "src/graphql/queries";

import Map from "../blocks/map/Map";
import Button from "../elements/Button";
import Dropdown from "../elements/Dropdown";
import Icon from "../elements/Icon";
import Radio from "../elements/Radio";
import Textarea from "../elements/Textarea";
import Tooltip from "../elements/Tooltip";

export default EnlargedMap;

export const ELEMENT_ID = "ENLARGED_MAP";
registerElement(ELEMENT_ID, EnlargedMap);

const LocationType = {
  LOCATION: "LOCATION",
  ROUTE: "ROUTE",
  TIME: "TIME",
};

/**
 *
 * @param {{drive: Drive }} props
 * @returns
 */

function EnlargedMap({ drive, onClose, unit, currency }) {
  const {
    startLocation,
    endLocation,
    stopLocation,
    startDate,
    endDate,
    stopDate,
    stopDuration,
    routeWaypoints,
    isRoundTrip,
  } = drive;
  const { miqDashMapDashboardRoutesWeb } = useFlags();
  return (
    <Modal
      passiveBackdrop
      testId="enlarged-map-modal"
      childrenAsBackground
      closable
      show
      onClose={onClose}
      closableClassName="close-btn mt-[5px] mr-[7px]"
      className="enlarged-map w-[1040px] tablet:w-[940px] h-[654px]"
      topBarComponent={
        miqDashMapDashboardRoutesWeb ? <ReportIssue id={drive.id} /> : <></>
      }
    >
      <Map
        withRoutes={miqDashMapDashboardRoutesWeb}
        infoWindows
        centerOnMarkerClick
        mapClassName="map rounded-[30px] border-border-1 overflow-visible"
        containerClassName="h-[654px]"
        isRoundTrip={isRoundTrip}
        polylines={routeWaypoints}
        stopDate={stopDate}
        endDate={endDate}
        startDate={startDate}
        stopDuration={stopDuration}
        startLocation={startLocation}
        endLocation={endLocation}
        stopLocation={stopLocation}
      />
      <DriveDetail
        drive={drive}
        unit={unit}
        currency={currency}
        withRoutes={miqDashMapDashboardRoutesWeb}
      />
    </Modal>
  );
}

/**
 *
 * @param {{drive: Drive }} props
 * @returns
 */
const DriveDetail = ({ drive, unit, currency, withRoutes }) => {
  const startLocationRef = React.useRef(null);
  const stopLocationRef = React.useRef(null);
  const endLocationRef = React.useRef(null);
  const rawDistance = drive.googleDistance?.toFixed?.(1);
  const transformedDistance = toUIDistance(
    roundDistance(drive.googleDistance),
    unit
  );
  const distance = drive.ignoreDistanceTransformation
    ? rawDistance
    : transformedDistance;

  const getLocationDisplayText = (location) => {
    let locationTextToDisplay = location.fullAddress;

    if (location.isNamed) {
      locationTextToDisplay = location.displayName;
    }

    return locationTextToDisplay || location?.displayName || "";
  };

  return (
    <div
      data-testid="enlarged-map"
      className="enlarged-map-table items-center left-[50%] -translate-x-[50%] absolute px-4 bottom-4 rounded bg-white w-[1010px] tablet:w-[912px] h-[50px]"
    >
      <Text
        className="py-[4px] px-[8px] mr-[4px] border border-border-1 rounded-10"
        md
      >
        {distance} {unit}
      </Text>
      <div
        className={`p-2 ${
          withRoutes && drive.stopLocation
            ? "route-locations-stop"
            : "route-locations"
        } items-center`}
      >
        <div ref={startLocationRef} className="flex items-center gap-[6px]">
          <Icon name="start-location-input" />
          <Text className="truncate leading-none">
            <Text bold>Start</Text>:{" "}
            {getLocationDisplayText(drive.startLocation)}
            <Tooltip
              alignX="center"
              offset={{ y: -40 }}
              timeout={500}
              triggerRef={startLocationRef}
            >
              <Text>{getLocationDisplayText(drive.startLocation)}</Text>
            </Tooltip>
          </Text>
        </div>
        {withRoutes && drive.stopLocation && (
          <>
            <Icon name="large-arrow-right" />
            <div ref={stopLocationRef} className="flex items-center gap-[6px]">
              <Icon name="stop-location-input" />
              <Text className="truncate leading-none">
                <Text bold>Stop</Text>:{" "}
                {getLocationDisplayText(drive.stopLocation)}
                <Tooltip
                  alignX="center"
                  offset={{ y: -40 }}
                  timeout={500}
                  triggerRef={stopLocationRef}
                >
                  <Text>{getLocationDisplayText(drive.stopLocation)}</Text>
                </Tooltip>
              </Text>
            </div>
          </>
        )}
        <Icon name="large-arrow-right" />
        <div ref={endLocationRef} className="flex items-center gap-[6px]">
          <Icon name="end-location-input" />
          <Text className="truncate leading-none">
            <Text bold>End</Text>: {getLocationDisplayText(drive.endLocation)}
            <Tooltip
              alignX="center"
              offset={{ y: -40 }}
              timeout={500}
              triggerRef={endLocationRef}
            >
              <Text>{getLocationDisplayText(drive.endLocation)}</Text>
            </Tooltip>
          </Text>
        </div>
      </div>
      <div>
        {drive.dateFormatter ? (
          <>
            <Text>{drive.dateFormatter.format(drive.startDate, "h:mm a")}</Text>{" "}
            - <Text>{drive.dateFormatter.format(drive.endDate, "h:mm a")}</Text>
          </>
        ) : (
          <>
            <Text>{format(new Date(drive.startDate), "h:mm a")}</Text> -{" "}
            <Text>{format(new Date(drive.endDate), "h:mm a")}</Text>
          </>
        )}
      </div>
      <Text>{formatCurrency({ value: getDriveValue(drive), currency })}</Text>
    </div>
  );
};

const ReportIssue = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [more, setMore] = useState("");
  const [type, setType] = useState("");
  const [postDriveFeedback, { loading: postFeebackLoading }] = useMutation(
    POST_DRIVE_FEEDBACK,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getDriveFeedback"],
    }
  );

  const { data } = useQuery(GET_DRIVE_FEEDBACK, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const reset = () => {
    setSubmitted(true);
    setMore("");
    setType("");
  };

  return (
    <Dropdown
      testId="report-issue-dropdown"
      icon="comment-alt-exclamation"
      label="Report an issue"
      open={open}
      triggerClassName="report-issue z-[999] opacity-[0.7]"
      className="no-animation"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      suffix={<></>}
    >
      <div className="flex gap-2 flex-col bg-white p-3 w-[240px]">
        {submitted || data?.driveFeedback ? (
          <>
            <Text bold>Thank you!</Text>
            <Text md className="inline-block mb-2 text-black/70 leading-[18px]">
              Your feedback helps us get better. If you require more help,
              please{" "}
              <a
                target="_blank"
                href="https://support.mileiq.com/hc/en-us/requests/new"
                className="text-blue underline font-bold"
              >
                contact us
              </a>
              .
            </Text>
            <Button
              secondary
              className="flex-grow font-medium"
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postDriveFeedback({
                variables: { feedbackType: type, feedbackDetails: more, id },
              }).then(() => reset());
            }}
          >
            <Text bold>Report an issue</Text>
            <Text md className="inline-block mb-2 text-black/70 leading-[18px]">
              Help us improve by sharing issues with this drive.
            </Text>
            <div className="flex flex-col gap-4 mb-2">
              <Radio
                onChange={() => setType(LocationType.LOCATION)}
                checked={type === LocationType.LOCATION}
                label="Location"
                value={LocationType.LOCATION}
              />
              <Radio
                onChange={() => setType(LocationType.ROUTE)}
                checked={type === LocationType.ROUTE}
                label="Route"
                value={LocationType.ROUTE}
              />
              <Radio
                onChange={() => setType(LocationType.TIME)}
                checked={type === LocationType.TIME}
                label="Time"
                value={LocationType.TIME}
              />
            </div>
            <Textarea
              onChange={(e) => {
                if (e.target.value.length < 500) setMore(e.target.value);
              }}
              value={more}
              placeholder="Tell us more"
            />
            <div className="grid grid-cols-2 mt-[10px] gap-[10px]">
              <Button
                onClick={() => setOpen(false)}
                secondary
                className="flex-grow font-medium"
              >
                Cancel
              </Button>
              <Button
                primary
                disabled={!type}
                loading={postFeebackLoading}
                type="submit"
                secondary
                className="flex-grow font-medium"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    </Dropdown>
  );
};
