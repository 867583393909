import React, { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import Checkbox from "src/components/elements/Checkbox";
import Dropdown from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";
import SearchInput from "src/components/elements/SearchInput";
import Text from "src/components/elements/Text";

import TeamMemberName from "src/components/blocks/reports/teams/TeamMemberName";

export default FilterDrivers;

function FilterDrivers({
  selected: _selected,
  drivers,
  onSelect,
  onOpen,
  customLabel,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([..._selected]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isOpen) setSearchQuery("");
  }, [isOpen]);

  const handleSubmitFilter = () => {
    setIsOpen(false);
    setSearchQuery("");
    if (typeof onSelect === "function") {
      onSelect(drivers.filter((d) => selected.includes(d.id)));
    }
  };

  const handleSearchChange = (val) => {
    setSearchQuery(val);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setSelected([]);
    setSearchQuery("");
    if (typeof onSelect === "function") onSelect([]);
  };

  const handleOpen = () => {
    const cancelOperation = onOpen?.();

    if (cancelOperation) return;

    setIsOpen(true);
  };

  let label = customLabel || "Drivers";

  const hasValue =
    _selected && _selected.length > 0 && selected && selected.length > 0;

  if (hasValue) {
    label = (
      <Text bold>
        Drivers <Text className="ml-2">{selected.length}</Text>
      </Text>
    );
  }

  const searchQueryLowerCase = searchQuery.toLowerCase();
  const filteredDrivers = drivers.filter((d) => {
    const memberName =
      d.firstName && d.lastName ? `${d.firstName} ${d.lastName}` : null;

    return (
      d.email.toLowerCase().includes(searchQueryLowerCase) ||
      d.firstName?.toLowerCase().includes(searchQueryLowerCase) ||
      d.lastName?.toLowerCase().includes(searchQueryLowerCase) ||
      memberName?.toLowerCase().includes(searchQueryLowerCase)
    );
  });

  return (
    <Dropdown
      icon="avatar"
      label={label}
      open={isOpen}
      hasValue={hasValue}
      onOpen={handleOpen}
      onClose={() => setIsOpen(false)}
      contentWidth={305}
      tooltip={{
        text: "Filter by drivers",
        alignX: "center",
        offset: { y: 50 },
      }}
      suffix={
        hasValue && !isOpen ? (
          <IconButton name="close" onClick={handleClear} />
        ) : null
      }
      triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
      openTriggerClassName="border-blue hover:border-blue active:border-blue"
    >
      <div>
        <div className="flex items-center">
          <div>
            <SearchInput
              placeholder="Search"
              className="mr-2.5"
              inputClassName="w-full"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <Button onClick={handleSubmitFilter} className="flex-shrink-0">
            Done
          </Button>
        </div>
        <ul style={{ maxHeight: "300px" }} className="overflow-auto m-0 mt-3">
          {filteredDrivers.length === 0 ? (
            <li className="w-full text-center">
              <Text>No matching drivers</Text>
            </li>
          ) : (
            filteredDrivers.map((d) => (
              <li key={d.id} className="mb-3">
                <Checkbox
                  className={"items-start"}
                  label={
                    <div className="w-3/4">
                      <TeamMemberName member={d} />
                    </div>
                  }
                  checked={selected.includes(d.id)}
                  onChange={(checked) => {
                    let newSelected = [];
                    if (checked) {
                      newSelected = [d.id, ...selected];
                    } else {
                      newSelected = selected.filter((id) => id !== d.id);
                    }
                    setSelected(newSelected);
                  }}
                />
              </li>
            ))
          )}
        </ul>
      </div>
    </Dropdown>
  );
}
