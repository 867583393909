import Base from "src/models/base";

import Img01automatic from "public/assets/img/teams-features/01automatic.png";
import Img02addremove from "public/assets/img/teams-features/02addremove.png";
import Img03billing from "public/assets/img/teams-features/03billing.png";
import Img04reports from "public/assets/img/teams-features/04reports.png";
import Img05dashboard from "public/assets/img/teams-features/05dashboard.png";
import Img06multiadmins from "public/assets/img/teams-features/06multiadmins.png";
import Img07rates from "public/assets/img/teams-features/07rates.png";
import Img08support from "public/assets/img/teams-features/08support.png";
import Img09report from "public/assets/img/teams-features/09report.png";
import Img10concur from "public/assets/img/teams-features/10concur.png";

export const TEAM_SUBSCRIPTION_TYPES = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
};

export const TEAMS_SUBSCRIPTION_PLANS = {
  TEAMS_LITE: "TEAMS_LITE",
  TEAMS: "TEAMS",
  TEAMS_PRO: "TEAMS_PRO",
};

export const TEAMS_SUBSCRIPTION_PLANS_DATA = {
  stripe: {
    ids: {
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: [
        "teams_lite_monthly_recurring",
        "teams_lite_yearly_recurring",
      ],
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: [
        "teams_monthly_recurring",
        "teams_yearly_recurring",
      ],
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: [
        "teams_pro_monthly_recurring",
        "teams_pro_yearly_recurring",
      ],
    },
    idsByType: {
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: {
        [TEAM_SUBSCRIPTION_TYPES.MONTH]: "teams_lite_monthly_recurring",
        [TEAM_SUBSCRIPTION_TYPES.YEAR]: "teams_lite_yearly_recurring",
      },
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: {
        [TEAM_SUBSCRIPTION_TYPES.MONTH]: "teams_monthly_recurring",
        [TEAM_SUBSCRIPTION_TYPES.YEAR]: "teams_yearly_recurring",
      },
      [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: {
        [TEAM_SUBSCRIPTION_TYPES.MONTH]: "teams_pro_monthly_recurring",
        [TEAM_SUBSCRIPTION_TYPES.YEAR]: "teams_pro_yearly_recurring",
      },
    },
  },
  features: [
    {
      title: "Automatic mileage tracking",
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Add/remove drivers",
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Streamlined billing",
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Reporting dashboard",
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Consolidated reports",
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Team Locations",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Report reminders",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Automatic report submission",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Multiple admins",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Integrations and API",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Custom rates",
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
      hint: "Set a company-wide mileage reimbursement rate that is different from your country's default rate.",
    },
  ],
  labels: {
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: "Teams Lite",
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: "Teams",
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: "Teams Pro",
    [TEAM_SUBSCRIPTION_TYPES.MONTH]: "Monthly",
    [TEAM_SUBSCRIPTION_TYPES.YEAR]: "Yearly",
  },
  shortLabels: {
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: "Lite",
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: "Teams",
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: "Pro",
  },
  descriptions: {
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: `
    App + web dashboard
    Our most basic plan, with unlimited mileage tracking for all drivers
    `,
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: `
    App + web dashboard
    Need mileage reports for reimbursement? Get everything in our Teams Lite plan plus the ability to submit and review reports.
    `,
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: `
    App + web dashboard
    This plan is the whole package: reporting for easy reimbursement, setting custom mileage rates, on-demand support, & more.
    `,
  },
  featuresv2: [
    {
      title: "Automatic tracking",
      hint: "Each driver will be able to automatically track an unlimited number of drives.",
      description:
        "MileIQ automatically detects when your drivers start and stop a drive.\n\n The app tracks their routes along the way, so you can be confident that each and every business mile is accurately captured. \n\n You'll know that their business miles are recorded and saved for reimbursement purposes - and your drivers will save time, too!",
      illustration: Img01automatic,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Add/remove drivers",
      hint: "Easily manage the memberships for your Team of drivers.",
      description:
        "MileIQ for Teams allows you to license the mobile app to as many drivers as you need. As an admin, you have full control and visibility to who is using the app at any point. \n\n Your admin web dashboard allows you to add and remove drivers as your Team expands or contracts.",
      illustration: Img02addremove,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Streamlined billing",
      hint: "One secure place for payment & automatic renewal.",
      description:
        "With a streamlined billing process, you'll no longer have multiple expenses for mileage tracking. Simply use a company credit card and let MileIQ do the calculations. We'll manage the number of licenses, and even provide prorated billing if needed.",
      illustration: Img03billing,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Consolidated reports",
      hint: "All reports are submitted to and reviewed in one place - on the web dashboard.",
      description:
        "Having multiple expense line items and versions of reports wastes time - yours and your drivers'. With MileIQ for Teams, each driver submits reports in the same format. \n\n Your straightforward, easy-to-use web dashboard permits you to view these standard reports in a single spot.",
      illustration: Img04reports,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Reporting dashboard",
      hint: "View, download, & email reports of your drivers' submitted business drives.",
      description:
        "With the admin dashboard, all of your drivers' reports are in one, easy-to-read format. You can sort, filter, download, and email reports based on your needs. \n\n Keep track of which reports have been submitted and by which driver. See where they've been and how many business miles they've racked up.",
      illustration: Img05dashboard,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Simple reporting",
      hint: "Drivers can submit reports directly from the app to the admin dashboard.",
      description:
        "Drivers send business mileage reports from the MileIQ app on their phone, straight to your admin dashboard. \n\n The process is simple: drivers submit business mileage reports via the 'Monthly Summaries' tab on the app - just choose the month and select 'Submit Report to My Team'.",
      illustration: Img09report,
      activeInPlans: [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      ],
    },
    {
      title: "Multiple admins",
      hint: "Allow for coordination between multiple departments.",
      description:
        "Do you have sub-teams to manage within your company? Use the multiple admins feature to allow web dashboard access to different roles and departments. \n\n MIQ grows with you as you develop and build your business - from small to large. Add and remove admins as your circumstances change.",
      illustration: Img06multiadmins,
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Custom reimbursement rates",
      hint: "Set a unique company-wide mileage reimbursement rate.",
      description:
        "Each company has a default mileage reimbursement rate, set by your tax institution per year. \n\n If you need a mileage reimbursement rate that's different from your country's default rate, our Teams Pro plan allows you to set a unique rate for all drivers in your Team at once - instead of doing it manually.",
      illustration: Img07rates,
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Premier Support",
      hint: "Request to schedule a call anytime.",
      description:
        "We know sometimes it's just easier to talk to someone. For our Teams Pro plan admins, We offer individual, one-on-one help with onboarding, inviting drivers, or any specific question. \n\n Request to schedule a call, and one of our agents can guide you through the admin dashboard, confirm driver invites have been sent, and more.",
      illustration: Img08support,
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
    {
      title: "Concur Integration",
      hint: "Submit MileIQ mileage reports to Concur as expense line items.",
      description:
        "If you use Concur for expense reimbursement, we've made it simple to integrate MileIQ into your system. MileIQ mileage reports can be sent directly to Concur and added to expense reports. \n\n This will streamline your reimbursement process and save your company valuable time.",
      illustration: Img10concur,
      activeInPlans: [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO],
    },
  ],
  prices: {
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE]: {
      [TEAM_SUBSCRIPTION_TYPES.MONTH]: 5,
      [TEAM_SUBSCRIPTION_TYPES.YEAR]: 50,
    },
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS]: {
      [TEAM_SUBSCRIPTION_TYPES.MONTH]: 8,
      [TEAM_SUBSCRIPTION_TYPES.YEAR]: 80,
    },
    [TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO]: {
      [TEAM_SUBSCRIPTION_TYPES.MONTH]: 10,
      [TEAM_SUBSCRIPTION_TYPES.YEAR]: 100,
    },
  },
};

export function getPlanFromStripeCode(plan) {
  const plans = Object.keys(TEAMS_SUBSCRIPTION_PLANS);
  const code = plans.find((p) =>
    TEAMS_SUBSCRIPTION_PLANS_DATA.stripe.ids[p].includes(plan)
  );
  return code || null;
}

export function getTypeFromStripeCode(type) {
  const types = Object.keys(TEAM_SUBSCRIPTION_TYPES);
  const code = types.find((t) => TEAM_SUBSCRIPTION_TYPES[t] === type);
  return code || null;
}

export function isTeamsProSubscription(planId) {
  if (planId === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO) return true;
  return TEAMS_SUBSCRIPTION_PLANS_DATA.stripe.ids[
    TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO
  ].includes(planId);
}

export function isTeamsLiteSubscription(planId) {
  if (planId === TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE) return true;
  return TEAMS_SUBSCRIPTION_PLANS_DATA.stripe.ids[
    TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE
  ].includes(planId);
}

export function isTeamsSubscription(planId) {
  if (planId === TEAMS_SUBSCRIPTION_PLANS.TEAMS) return true;
  return TEAMS_SUBSCRIPTION_PLANS_DATA.stripe.ids[
    TEAMS_SUBSCRIPTION_PLANS.TEAMS
  ].includes(planId);
}

export function getStripeSubscriptionCode(planCode, planType) {
  return TEAMS_SUBSCRIPTION_PLANS_DATA.stripe.idsByType[planCode][planType];
}

export default class TeamSubscription extends Base {
  id = null;
  plan = null;
  description = null;
  status = null;
  type = null;
  numberOfSeats = 0;
  percentOff = 0;
  nextPaymentAt = 0;
  nextBillingAmount = 0;
  cancelAt = null;
  cancelAtPeriodEnd = false;
  freeTrialStart = null;
  freeTrialEnd = null;

  constructor(data) {
    super("subscription_model");
    if (data) {
      this.id = data.id;
      this.plan = getPlanFromStripeCode(data.plan);
      this.description = data.plan_description;
      this.status = data.status;
      this.type = getTypeFromStripeCode(data.type);
      this.numberOfSeats = data.number_of_seats;
      this.percentOff = data.percent_off;
      this.nextPaymentAt = data.next_payment_at;
      this.nextBillingAmount = data.next_billing_amount;
      this.cancelAt = data.cancel_at ? data.cancel_at * 1000 : null;
      this.cancelAtPeriodEnd = data.cancel_at_period_end;
      this.freeTrialStart = data.free_trial_start
        ? data.free_trial_start * 1000
        : null;
      this.freeTrialEnd = data.free_trial_end
        ? data.free_trial_end * 1000
        : null;
    }
  }

  get isFreeTrialActive() {
    if (!this.freeTrialEnd) return false;

    return this.freeTrialEnd > new Date().getTime();
  }

  get remainingTrialDays() {
    if (!this.freeTrialEnd) return null;
    const now = new Date();
    return Math.ceil(
      (new Date(this.freeTrialEnd) - now) / (1000 * 60 * 60 * 24)
    );
  }
}
