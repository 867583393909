import "src/models/typings";

import { useMutation } from "@apollo/client";
import getYear from "date-fns/getYear";
import React, { useContext, useEffect, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";
import Textarea from "src/components/elements/Textarea";
import Tooltip from "src/components/elements/Tooltip";

import { trackVehicleUpdated } from "src/services/tracking";
import { COUNTRIES, VEHICLE_TYPES } from "src/services/utils";

import { ADD_VEHICLE, EDIT_VEHICLE } from "src/graphql/mutations";

export default AddVehicleModal;

export const ELEMENT_ID = "ADD_VEHICLE_MODAL";
registerElement(ELEMENT_ID, AddVehicleModal);

function getOdometerObj(odometer) {
  let res = {};

  odometer.forEach(({ year, value }) => {
    res[year] = value;
  });

  const currentYear = new Date().getFullYear();
  if (!odometer.some((od) => od.year === currentYear)) {
    res[currentYear] = "";
  }

  return res;
}

function AddVehicleModal({
  vehicle,
  onClose,
  onSubmit,
  onStartAddDetails,
  onAddDetailsCompleted,
}) {
  const { userData } = useContext(UserDataContext);
  const [model, setModel] = useState(vehicle?.model || "");
  const [make, setMake] = useState(vehicle?.make || "");
  const [vehicleName, setVehicleName] = useState(vehicle?.name || "");
  const [type, setType] = useState(vehicle?.type || "");
  const [year, setYear] = useState(vehicle?.year || "");
  const [notes, setNotes] = useState(vehicle?.notes || "");

  const [isDefault, setIsDefault] = useState(
    vehicle
      ? vehicle.id == userData.defaultVehicle // preselect Yes if vehicle is default
      : !userData.defaultVehicle // if user has no default vehicle, we preselect Yes for new one
  );
  const [odometer, setOdometer] = useState(
    vehicle?.odometer && vehicle.odometer.length > 0
      ? getOdometerObj(vehicle.odometer)
      : {
          [getYear(new Date())]: 0,
        }
  );

  const [addVehicleMutFn, addVehicleMutState] = useMutation(ADD_VEHICLE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const [editVehicleMutFn, editVehicleMutState] = useMutation(EDIT_VEHICLE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const canSubmit = () => {
    return model?.trim() !== "" || make?.trim() !== "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!canSubmit()) return;

    const isEdit = !!vehicle?.id;

    const data = {
      make,
      model,
      vehicleName,
      year: year || null,
      notes,
      type: type || VEHICLE_TYPES.AUTOMOBILE,
      isDefault,
      odometer: Object.keys(odometer)
        .filter((year) => odometer[year])
        .map((year) => ({
          year: parseInt(year),
          value: odometer[year],
        })),
    };
    let mutFn;

    if (isEdit) {
      mutFn = editVehicleMutFn;
      data.id = vehicle.id;
    } else {
      mutFn = addVehicleMutFn;
    }

    const res = await mutFn({
      variables: {
        data,
      },
    });

    if (isEdit) {
      onSubmit(vehicle.id, {
        name: vehicleName || `${make} ${model}`.trim(),
        updated: true,
      });
      const isDetailsChanged =
        model != (vehicle.model || "") ||
        make != (vehicle.make || "") ||
        vehicleName != (vehicle.name || "") ||
        type != (vehicle.type || "") ||
        year != (vehicle.year || "") ||
        notes != (vehicle.notes || "") ||
        JSON.stringify(odometer) !=
          JSON.stringify(getOdometerObj(vehicle.odometer));

      const wasDefault = vehicle.id == userData.defaultVehicle;
      const isPriorityChanged = isDefault != wasDefault;
      trackVehicleUpdated({
        isDetailsChanged,
        isPriorityChanged,
        isPrimary: isDefault,
        isVisibilityChanged: false,
        isVisible: vehicle.isActive,
      });
    } else {
      onSubmit(res.data.addVehicle.id);
      onAddDetailsCompleted({ type: type });
    }
  };

  const handleHideVehicleClicked = async () => {
    await editVehicleMutFn({
      variables: {
        data: { id: vehicle.id, isActive: false },
      },
    });
    onSubmit(vehicle.id, {
      name: vehicleName || `${make} ${model}`.trim(),
      hidden: true,
    });
    trackVehicleUpdated({
      isVisibilityChanged: true,
      isVisible: false,
      isPriorityChanged: vehicle.id === userData.defaultVehicle, // if was default, then priority is changed to non-primary, coz hidden
      isPrimary: false,
    });
  };

  const handleVehicleTypeSelected = (t) => {
    if (!isEdit && !type && isVehicleTypeSupported) {
      onStartAddDetails?.({ type: t });
    }
    setType(t);
  };

  useEffect(() => {
    if (!isEdit && !isVehicleTypeSupported) {
      onStartAddDetails?.({ type: VEHICLE_TYPES.AUTOMOBILE });
    }
  }, []);

  const isEdit = !!vehicle?.id;
  const isVehicleTypeSupported = userData.country === COUNTRIES.GB;
  const showDetails = isVehicleTypeSupported ? type !== "" : true;
  return (
    <Modal
      closable
      show
      className="w-[600px]"
      onClose={onClose}
      testId="add-vehicle-modal"
    >
      <>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-[20px] laptop:gap-[15px]"
        >
          <h4>{isEdit ? "Edit vehicle" : "Add a new vehicle"}</h4>
          {isVehicleTypeSupported && (
            <VehicleType
              type={type}
              onChange={handleVehicleTypeSelected}
              isEdit={isEdit}
            />
          )}
          {showDetails && (
            <>
              <div className="grid grid-cols-2 gap-[10px]">
                <MakeInput value={make} onChange={setMake} />
                <ModelInput value={model} onChange={setModel} />
              </div>
              <div className="grid grid-cols-2 gap-[10px]">
                <YearInput value={year} onChange={setYear} />
                <Input
                  label="Nickname"
                  placeholder="Ex: Maxy"
                  value={vehicleName}
                  onChange={(e) => setVehicleName(e.target.value)}
                />
              </div>
              <PrimaryPicker onChange={setIsDefault} primary={isDefault} />
              <OdometerReading data={odometer} onChange={setOdometer} />
              <div>
                <Textarea
                  label="Notes"
                  height={90}
                  maxHeight={300}
                  placeholder="Ex: Registration up on 1/1/2020"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="flex gap-3 justify-end">
                {isEdit ? (
                  <>
                    <Button
                      secondary
                      type="button"
                      disabled={editVehicleMutState.loading}
                      loading={editVehicleMutState.loading}
                      onClick={handleHideVehicleClicked}
                    >
                      Hide this vehicle
                    </Button>
                    <Button
                      type="submit"
                      data-testid="edit-vehicle-submit-btn"
                      disabled={!canSubmit() || editVehicleMutState.loading}
                      loading={editVehicleMutState.loading}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    type="submit"
                    data-testid="add-vehicle-submit-btn"
                    disabled={!canSubmit() || addVehicleMutState.loading}
                    loading={addVehicleMutState.loading}
                  >
                    Add vehicle
                  </Button>
                )}
              </div>
            </>
          )}
        </form>
      </>
    </Modal>
  );
}

function VehicleType({ type, onChange, isEdit }) {
  const btnCls = "px-[20px] border border-border-1 font-semibold ";
  const activeBtnCls = btnCls + "px-[19px] bg-blue/5 border-2 border-blue";
  const hideBtnCls = (currentType) =>
    isEdit && currentType !== type ? "hidden" : "";
  return (
    <div>
      <Text semibold>What type of vehicle do you want to add?</Text>
      <div className="flex gap-[10px] laptop:gap-[8px] mt-[10px]">
        <Button
          ghost
          type="button"
          onClick={() => onChange(VEHICLE_TYPES.AUTOMOBILE)}
          className={
            VEHICLE_TYPES.AUTOMOBILE === type
              ? activeBtnCls
              : btnCls + hideBtnCls(VEHICLE_TYPES.AUTOMOBILE)
          }
          icon="car-side"
        >
          Car or Van
        </Button>
        <Button
          ghost
          type="button"
          onClick={() => onChange(VEHICLE_TYPES.MOTORCYCLE)}
          className={
            VEHICLE_TYPES.MOTORCYCLE === type
              ? activeBtnCls
              : btnCls + hideBtnCls(VEHICLE_TYPES.MOTORCYCLE)
          }
          icon="motorbike"
        >
          Motorcycle
        </Button>
        <Button
          ghost
          type="button"
          onClick={() => onChange(VEHICLE_TYPES.BICYCLE)}
          className={
            VEHICLE_TYPES.BICYCLE === type
              ? activeBtnCls
              : btnCls + hideBtnCls(VEHICLE_TYPES.BICYCLE)
          }
          icon="bicycle"
        >
          Bike
        </Button>
      </div>
    </div>
  );
}

function MakeInput({ value, onChange }) {
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };
  const handleBlur = (e) => {
    let val = e.target.value;
    onChange(val.trim());
  };
  return (
    <Input
      label="Make"
      placeholder="Ex: Nissan"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

function ModelInput({ value, onChange }) {
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };
  const handleBlur = (e) => {
    let val = e.target.value;
    onChange(val.trim());
  };
  return (
    <Input
      label="Model"
      placeholder="Ex: Maxima"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

function YearInput({ value, onChange }) {
  const currentYear = getYear(new Date());
  const handleChange = (e) => {
    let val = e.target.value;
    val = parseInt(val);
    if (isNaN(val)) val = "";
    val = Math.abs(val);
    if (val > currentYear) val = currentYear;
    onChange(val);
  };
  const handleBlur = (e) => {
    let val = e.target.value;
    if (val.trim() === "") return;
    val = parseInt(val);
    if (isNaN(val)) val = "";
    if (val < 1908) val = 1908; // start of Ford Model T production :)
    onChange(val);
  };
  return (
    <Input
      label="Year"
      placeholder="Ex: 2016"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

function PrimaryPicker({ primary, onChange }) {
  const tooltipRef = useRef();
  const btnCls =
    "h-40-35 leading-none rounded-14-12 px-[20px] border border-border-1 font-semibold";
  const activeBtnCls = btnCls + " px-[19px] bg-blue/5 border-2 border-blue";
  return (
    <div className="flex items-center gap-5">
      <Text semibold className="flex gap-2">
        Primary vehicle
        <span ref={tooltipRef}>
          <Icon name="question-circle" />
        </span>
      </Text>
      <Tooltip
        timeout={500}
        width={240}
        offset={{ y: 24, x: -30 }}
        triggerRef={tooltipRef}
        className="py-2.5 px-3"
      >
        <Text md>
          This is the vehicle used primarily for business purposes.
        </Text>
      </Tooltip>
      <div className="flex items-center justify-start gap-2.5">
        <button
          type="button"
          className={!primary ? activeBtnCls : btnCls}
          onClick={() => onChange(false)}
        >
          No
        </button>
        <button
          type="button"
          className={primary ? activeBtnCls : btnCls}
          onClick={() => onChange(true)}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

function OdometerReading({ data, onChange }) {
  const inpContainerRef = useRef();

  const handleRemoveYearClicked = () => {
    const newData = { ...data };
    const last = Math.min(...Object.keys(data));

    delete newData[last];
    onChange({ ...newData });
  };

  const handleAddYearClicked = () => {
    const y = Math.min(...Object.keys(data)) - 1;
    onChange({ [y]: 0, ...data });
    setTimeout(() => {
      const inp = inpContainerRef.current?.lastChild?.querySelector("input");
      inp?.focus();
    }, 100);
  };

  const handleChange = (year, e) => {
    let val = e.target.value;
    val = parseFloat(val);
    if (isNaN(val)) val = 0;
    val = Math.abs(val);
    data[year] = val;
    onChange({ ...data });
  };

  const items = Object.keys(data);

  return (
    <div>
      <Text semibold>Odometer reading</Text>
      <div className="max-h-[220px] overflow-auto mt-[10px]">
        <div className="grid grid-cols-2 items-end gap-[10px]">
          <div
            ref={inpContainerRef}
            className="flex flex-col items-start gap-[10px]"
          >
            {items
              .sort((y1, y2) => y2 - y1)
              .map((year) => (
                <Input
                  maxLength={6}
                  key={year}
                  prefix={year}
                  prefixClassName="w-[90px]"
                  placeholder="Ex: 20,000"
                  value={data[year] || ""}
                  onChange={(e) => handleChange(year, e)}
                  className="w-full"
                />
              ))}
          </div>
          {items.length > 1 ? (
            <div className="h-40-35 flex item-center gap-1">
              <IconButton
                name="minus-circle"
                onClick={handleRemoveYearClicked}
              />
              <IconButton name="add-circle" onClick={handleAddYearClicked} />
            </div>
          ) : (
            <div>
              <Button
                type="button"
                icon="add-circle"
                iconClassName="fill-blue"
                ghost
                nohover
                className="px-0"
                onClick={handleAddYearClicked}
              >
                Add year
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
