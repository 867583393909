import "src/models/typings";

import { isEqual } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import React, { useContext, useState } from "react";

import SettingsContext from "src/components/context/SettingsContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

import EffectiveDatePicker from "src/components/blocks/custom-rates/EffectiveDatePicker";
import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import { ratesInKmToMiles, ratesInMilesToKm } from "src/services/utils";

export default RatesFormCA;

const pref = `$/km`;

function transformRatesWith(callback, rates) {
  if (!rates) return false;
  const ratesClone = rates.clone();
  ratesClone.value = callback(ratesClone.value)?.toFixed(3);
  ratesClone.value2 = callback(ratesClone.value2)?.toFixed(3);
  return ratesClone;
}

function getDefaultValues(team) {
  return transformRatesWith(ratesInMilesToKm, team?.cloneRatesForEdit());
}

function RatesFormCA({
  rates: defaultRates,
  compareRates,
  isRevertingTo,
  onSave,
  onCancel,
  disabled,
  loading,
}) {
  /** @type {{team: Team}} */
  const { team } = useContext(TeamContext);
  const ctxData = useContext(SettingsContext);

  const initialRates =
    transformRatesWith(ratesInMilesToKm, defaultRates) ||
    getDefaultValues(team);
  const ratesToCompare =
    transformRatesWith(ratesInMilesToKm, compareRates) || initialRates;

  /** @type {[RatesCA, React.Dispatch<React.SetStateAction<RatesCA>]} */
  const [rates, setRates] = useState(initialRates);
  const [rateEmpty, setRateEmpty] = useState(false);

  if (!team?.id) return;

  const updateRates = (r) => {
    if (r.effectiveDate === null) {
      r.effectiveDate = startOfDay(new Date());
    }
    setRates(r);
    ctxData.unsavedRates = transformRatesWith(ratesInKmToMiles, r.clone());
    setRateEmpty(false);
  };

  const handleChangeValue = (v) => {
    const newRates = rates.clone();
    newRates.value = v;
    updateRates(newRates);
  };

  const handleChangeValue2 = (v) => {
    const newRates = rates.clone();
    newRates.value2 = v;
    updateRates(newRates);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(transformRatesWith(ratesInKmToMiles, rates));
  };

  const handleCancelClicked = () => {
    ctxData.unsavedRates = null;
    updateRates(getDefaultValues(team));
    onCancel();
  };

  const handleEffectiveDateChange = (date) => {
    const newRates = rates.clone();
    newRates.effectiveDate = date;
    updateRates(newRates);
  };

  const handleClearInput = () => {
    setRateEmpty(true);
    ctxData.unsavedRates = null;
  };

  const isDifferentDate = !isEqual(
    ratesToCompare.effectiveDate,
    rates.effectiveDate
  );

  const isValidValue =
    (rates.value >= 0 || rates.value2 >= 0) &&
    (rates.value !== ratesToCompare.value ||
      rates.value2 !== ratesToCompare.value2);

  const canSaveRate = !rateEmpty && (isValidValue || isDifferentDate);

  const isDisabled = disabled || loading;

  return (
    <form className="w-full mt-8" onSubmit={handleSubmit}>
      <div className="mb-4 flex items-center">
        <Text semibold className="w-1/2">
          First 5K kms
        </Text>
        <Text semibold className="w-1/2">
          After 5K kms
        </Text>
      </div>
      <div>
        <div className="flex items-center">
          <div className="w-1/2 flex items-center flex-grow">
            <RatesInput
              prefix={pref}
              rate={rates.value}
              disabled={isRevertingTo || isDisabled}
              onChange={handleChangeValue}
              placeholder={initialRates.value}
              onClear={handleClearInput}
            />
          </div>
          <div className="w-1/2 flex items-center flex-grow">
            <RatesInput
              prefix={pref}
              rate={rates.value2}
              disabled={isRevertingTo || isDisabled}
              onChange={handleChangeValue2}
              placeholder={initialRates.value2}
              onClear={handleClearInput}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex w-[250px]">
        <EffectiveDatePicker
          alignment={{ right: true, offset: { x: 5 } }}
          contentClassName="right"
          defaultDate={rates.effectiveDate}
          disabled={isDisabled}
          onSelect={handleEffectiveDateChange}
        />
      </div>
      <div className="flex mt-8">
        <Button
          type="submit"
          className="mr-2"
          disabled={isDisabled || (!canSaveRate && !isRevertingTo)}
          loading={loading}
        >
          Save
        </Button>
        <Button
          ghost
          type="button"
          onClick={handleCancelClicked}
          disabled={isDisabled}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
