import e from "../managers/braze-instance.js";
import pe from "./in-app-message-manager-factory.js";
export function subscribeToInAppMessage(n) {
  if (e.rr())
    return "function" != typeof n
      ? null
      : pe.m().We(function(r) {
          return n(r[0]), r.slice(1);
        });
}
