import React, { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { removeBannerSidenavClosed } from "src/services/storage";
import { deleteTeam } from "src/services/teams";
import { trackTeamsDeletionCompleted } from "src/services/tracking";

export default DeleteTeamConfirm;

function DeleteTeamConfirm({ team, show, onClose }) {
  const [loading, setLoading] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [secLeft, setSecLeft] = useState(5);
  const [confirmName, setConfirmName] = useState("");

  const counter = React.createRef();

  useEffect(() => {
    if (removed) {
      counter.current = setInterval(
        () => setSecLeft((secLeft) => secLeft - 1),
        1000
      );
    } else {
      clearInterval(counter.current);
      counter.current = null;
    }
  }, [removed]);

  useEffect(() => {
    if (secLeft === 0) {
      window.location.href = "/user";
    }
  }, [secLeft]);

  const _remove = async () => {
    if (loading) return;

    setLoading(true);

    try {
      await deleteTeam(team.id);
      trackTeamsDeletionCompleted();
      removeBannerSidenavClosed(); // ensure we show CTA to create a team
      setRemoved(true);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onClose={() => {
        if (!removed) onClose();
      }}
      closable={!removed}
      loading={loading}
      className="w-[500px] delete-team-confirm-modal"
    >
      {removed ? (
        <div className="p-6">
          <h4 className="text-center mb-8">
            Your Team {team.name} has been deleted
          </h4>
          <Text paragraph className="text-center">
            Redirecting you in{" "}
            <Text bold lg>
              {secLeft}
            </Text>
            ..
          </Text>
        </div>
      ) : (
        <>
          <h4 className="text-center mb-8">
            Are you sure you want to delete your team?
          </h4>
          <Text paragraph lg className="text-center">
            This will permanently and irrevocably delete your team and cancel
            all of your drivers' Unlimited subscriptions.
          </Text>
          <form
            className="mt-8"
            onSubmit={(e) => {
              e.preventDefault();
              _remove();
            }}
          >
            <Text paragraph lg semibold color="black" className="mb-3">
              Confirm by typing{" "}
              <Text bold color="red">
                {team.name}
              </Text>{" "}
              below
            </Text>
            <Input
              autoFocus
              className="w-full mb-3"
              value={confirmName}
              onChange={(e) => setConfirmName(e.target.value)}
            />
            <Button
              lg
              destructive
              className="w-full"
              type="submit"
              disabled={confirmName != team.name}
            >
              Delete my team
            </Button>
          </form>
        </>
      )}
    </Modal>
  );
}
