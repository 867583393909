import { PAGE_SIZE } from "src/services/drives";

export function getRefetchParams(query) {
  if (query.queryName == "getUserDrives") {
    let limit = query.last?.result?.data?.drives?.length || 0;
    if (limit < PAGE_SIZE) {
      limit = PAGE_SIZE;
    }
    if (limit) {
      return { limit };
    }
  }
}
