import { useQuery } from "@apollo/client";

import Loader from "src/components/elements/Loader";

import useQueryParams from "src/hooks/useQueryParams";

import { getZendeskJwtAuthUrl } from "src/services/utils";

import { GET_ZENDESK_JWT } from "src/graphql/queries";

export default ZendeskSSOLogin;

function ZendeskSSOLogin() {
  const qs = useQueryParams();
  useQuery(GET_ZENDESK_JWT, {
    onCompleted: (data) => {
      const jwt = data?.zendeskJWT;

      let url = getZendeskJwtAuthUrl(jwt);

      const returnTo = qs.get("return_to");
      if (returnTo) {
        url += `&return_to=${encodeURIComponent(returnTo)}`;
      }

      window.location.href = url;
    },
    onError: (err) => {
      if (
        err?.graphQLErrors?.find(
          (e) => e.extensions?.code === "UNAUTHENTICATED"
        )
      ) {
        const retUrl = window.location.href;
        const url = `/login?retUrl=${encodeURIComponent(retUrl)}`;
        window.location.href = url;
      }
    },
  });

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
}
