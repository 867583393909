import React, { useEffect, useRef, useState } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import {
  trackDistanceRateUpdateCompleted,
  trackDistanceRateUpdateStarted,
} from "src/services/tracking";

const RatesUS = ({
  rate,
  editable,
  setByTeam,
  loading,
  onSave = () => {},
  isCustomRatePresent,
  handleRatesReset = () => {},
}) => {
  const updateStarted = useRef(false);
  const customRates = useCustomRates();
  const defaultCountryRates = customRates?.defaultCountryRates?.data;

  const source = defaultCountryRates?.source || "IRS";
  const year = defaultCountryRates?.year || "2022";
  const value = defaultCountryRates?.values?.business?.default || "0.625";

  const getValue = (value) => {
    return Number(value).toFixed(3);
  };

  const defaultValue = getValue(rate.values.business.default);
  const [businessValue, setBusinessValue] = useState(defaultValue);

  useEffect(() => {
    setBusinessValue(rate.values.business.default);
  }, [JSON.stringify(rate)]);

  const handleRateChanged = (val) => {
    if (!updateStarted.current) {
      trackDistanceRateUpdateStarted();
    }
    updateStarted.current = true;
    setBusinessValue(val);
  };

  const handleSave = async () => {
    let values = { ...rate.values };
    values.business.default = Number(businessValue);

    await onSave({ values });
    updateStarted.current = false;
    trackDistanceRateUpdateCompleted();
  };

  const handleCancel = () => {
    setBusinessValue(defaultValue);
    updateStarted.current = false;
  };

  const isModified = Number(defaultValue) !== Number(businessValue);

  return (
    <>
      <h6 className="mb-[5px]">Rates</h6>
      <Text paragraph>
        {setByTeam
          ? "You are part of a team which means that your team's admin sets your currency, mileage rate, and distance unit. Current rate is listed below."
          : `The ${source} standard mileage rate for ${year} is ${value}/mile. You can customize your business reimbursement rate below. Caution: once you have added a custom rate, MileIQ can't use the default rate set by the ${source}, unless you reset to the standard rate.`}
      </Text>
      <div className="my-[20px] laptop:my-[15px] flex items-center">
        <div className="flex-grow-0">
          <RatesInput
            prefix={`$/${rate.units}`}
            rate={businessValue}
            disabled={setByTeam || !editable || loading}
            onChange={handleRateChanged}
            placeholder={businessValue}
          />
        </div>
        {!setByTeam && isCustomRatePresent && (
          <button
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={handleRatesReset}
          >
            Reset Rates
          </button>
        )}
      </div>
      {setByTeam ? null : (
        <div className="mt-4 flex gap-2">
          <Button
            loading={loading}
            disabled={!isModified || loading}
            className="font-medium"
            primary
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            disabled={!isModified || loading}
            className="font-medium"
            ghost
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default RatesUS;
