import { useRef } from "react";

import Tooltip from "src/components/elements/Tooltip";

export default DistanceWithCommute;

function DistanceWithCommute({
  totalDistance,
  finalDistance,
  distanceUnit,
  commute,
  tooltipOffset,
  children,
  className = "",
}) {
  const cellRef = useRef();

  if (!commute) return children;

  return (
    <>
      <div ref={cellRef} className={`commute-indicator ${className}`}>
        {children}
      </div>
      <Tooltip
        offset={tooltipOffset}
        alignX="center"
        triggerRef={cellRef}
        className="py-[10px] px-[12px] cursor-text"
        timeout={500}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col gap-[8px] min-w-[150px] font-normal">
          <div>
            <p className="text-13 text-right flex gap-[4px] justify-end">
              <span className="text-[var(--color-white-600)]">Original</span>
              <span className="text-white">
                {totalDistance?.toFixed?.(1)} {distanceUnit}
              </span>
            </p>
            <p className="text-13 text-right flex gap-[4px] justify-end">
              <span className="text-[var(--color-white-600)]">Commute</span>
              <span className="text-white">
                -{commute?.toFixed?.(1)} {distanceUnit}
              </span>
            </p>
          </div>
          <p className="pt-[4px] text-13 text-right border-t border-[var(--color-white-600)] flex gap-[4px] justify-end">
            <span className="text-[var(--color-white-600)]">Total</span>
            <span className="text-white">
              {finalDistance?.toFixed?.(1)} {distanceUnit}
            </span>
          </p>
        </div>
      </Tooltip>
    </>
  );
}
