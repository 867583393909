import { useQuery } from "@apollo/client";
import React, { useContext } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useUser, useUserData } from "src/components/context/UserContext";

import Loader from "src/components/elements/Loader";

import { ACCOUNT_REALM_TYPE } from "src/services/auth";
import { SUBSCRIPTION_TYPE } from "src/services/utils";

import { GET_REALM } from "src/graphql/queries";

import ChangePassword from "./ChangePassword";
import ContactInfo from "./ContactInfo";

export default ProfilePage;

function ProfilePage() {
  const { userData } = useUserData();
  const { team } = useContext(TeamContext);
  const { user } = useUser();
  const { data, loading } = useQuery(GET_REALM, {
    notifyOnNetworkStatusChange: true,
    variables: {
      username: user.email,
    },
  });

  if (loading) {
    return (
      <div className="h-[204px]">
        <Loader />
      </div>
    );
  }
  const isMileIQUser = data?.realm.realm === ACCOUNT_REALM_TYPE.MILEIQ;
  const isEditEmailEnabled =
    isMileIQUser &&
    !team &&
    [
      SUBSCRIPTION_TYPE.FREE,
      SUBSCRIPTION_TYPE.COMPED_PREM_OR_TAX_PROS,
      SUBSCRIPTION_TYPE.MONTHLY,
      SUBSCRIPTION_TYPE.ANNUAL,
    ].includes(userData.subscriptionType);
  return (
    <div className="p-[20px] laptop:p-[15px]" data-testid="profile-page">
      <ContactInfo isEditEmailEnabled={isEditEmailEnabled} />
      <div className="my-[20px] laptop:my-[15px] h-px bg-border-1"></div>
      <ChangePassword
        isMSAUser={data?.realm.realm === ACCOUNT_REALM_TYPE.MSA}
      />
    </div>
  );
}
