import { useQuery } from "@apollo/client";
import { format } from "date-fns/esm";
import { useContext } from "react";

import { useNotifications } from "src/components/context/NotificationsContext";
import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import {
  PAYMENT_METHOD,
  PAYMENT_METHOD_DATA,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_DATA,
  SUBSCRIPTION_TYPE_DATA,
} from "src/services/billing";
import {
  COUNTRIES_DATA,
  formatCurrency,
  toUIDistance,
} from "src/services/utils";

import { GET_USER_ALL_TIME_TOTALS } from "src/graphql/queries";

import ImgDogInCar from "public/assets/img/car-with-dog-sm.svg";

export default UnlimitedPlan;

function UnlimitedPlan() {
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);
  const { notifications } = useNotifications();
  const teamName = notifications?.team_name;

  const { isTeamsUser } = userData;

  const {
    paymentService,
    status,
    type,
    amount,
    currentAmount,
    nextPaymentDate,
    endDate,
  } = userData.subscriptionData;

  const paymentMethodLabel = PAYMENT_METHOD_DATA[paymentService]?.label;
  const subscriptionStatusLabel = SUBSCRIPTION_STATUS_DATA[status]?.label;
  const typeLabel = SUBSCRIPTION_TYPE_DATA[type]?.label;

  const isActive = status === SUBSCRIPTION_STATUS.ACTIVE;
  const isCancelled = status === SUBSCRIPTION_STATUS.CANCELLED;
  const now = new Date();
  const expirationDate = endDate ? new Date(endDate) : null;
  const isDateExpired = expirationDate ? now > expirationDate : false;
  const isExpired = status === SUBSCRIPTION_STATUS.EXPIRED || isDateExpired;
  const textBlack = isDateExpired ? "black/50" : "black";

  const isApplePayment =
    paymentService === PAYMENT_METHOD.APPLE ||
    paymentService === PAYMENT_METHOD.APPLE_SANDBOX;
  const isGooglePayment = paymentService === PAYMENT_METHOD.GOOGLE;

  return (
    <div className="flex flex-col gap-[20px]">
      <div className="flex flex-col gap-1">
        {isExpired ? (
          <>
            <Text bold color="red">
              Your subscription has expired
            </Text>
            <Text paragraph md color="black/70">
              Clicking the Renew subscription button below will take you to
              Stripe where you can renew your subscription and update your
              payment information.
            </Text>
          </>
        ) : isCancelled ? (
          <>
            <Text bold>Your subscription is expiring soon</Text>
            <Text paragraph md color="black/70">
              Clicking the Renew subscription button below will take you to
              Stripe where you can renew your subscription and update your
              payment information.
            </Text>
          </>
        ) : null}
        {!isTeamsUser && isActive && (
          <>
            <Text bold>Your subscription</Text>
            {isApplePayment && (
              <Text paragraph md color="black/70">
                Clicking the Manage Subscription button below will take you to
                the App Store where you can update your payment information.
              </Text>
            )}
            {isGooglePayment && (
              <Text paragraph md color="black/70">
                Clicking the Manage Subscription button below will take you to
                the Google Play Store where you can manage your subscription and
                update your payment information.
              </Text>
            )}
          </>
        )}
      </div>

      {isTeamsUser ? (
        <>
          <div className="flex items-center gap-5 rounded p-5 bg-bone-white">
            <div>
              <h6>You are a Driver on Team {team?.name || teamName}</h6>
              <Text paragraph md color="black/70">
                As part of a MileIQ Team, you have an Unlimited drives plan and
                your subscription is paid for by your Team.
              </Text>
            </div>
            <div className="w-[125px]">
              <ImgDogInCar />
            </div>
          </div>
          {isApplePayment ? (
            <Text paragraph md color="black/70">
              Clicking the Cancel Subscription button above will take you to the
              App Store where you can update your payment information.
            </Text>
          ) : null}
          {isGooglePayment ? (
            <Text paragraph md color="black/70">
              Clicking the Cancel Subscription button above will take you to the
              Google Play Store where you can manage your subscription and
              update your payment information.
            </Text>
          ) : null}
        </>
      ) : null}

      {/* Table */}
      <div className="border border-border-1 rounded">
        {isTeamsUser && isActive ? (
          <div className="m-2 mb-0 rounded bg-red-pastel px-5 py-3 flex items-center gap-3">
            <Icon name="alert" color="red" />
            <Text bold md>
              Don't pay twice! Downgrade your MileIQ Unlimited plan
            </Text>
          </div>
        ) : null}
        <div>
          <div className="flex items-center justify-between py-3 px-5">
            <div className="flex flex-col">
              <Text
                bold
                color={textBlack}
                className="text-18 laptop:text-15 leading-[1.4]"
              >
                Unlimited, {typeLabel}
              </Text>
              <Text md color={textBlack} className="leading-[1.4]">
                {subscriptionStatusLabel}
              </Text>
            </div>
          </div>
          <div className="flex items-center justify-between py-3 px-5">
            <Text bold={isActive} color={textBlack}>
              {isCancelled || isExpired ? "Last payment" : "Total"}
            </Text>
            <Text bold color={textBlack}>
              {formatCurrency({
                value:
                  (isCancelled || isExpired ? currentAmount : amount) / 100,
                currency: userData.currency,
              })}
            </Text>
          </div>
        </div>
        <div className="flex items-center justify-between py-3 px-5 border-t border-border-1">
          {isExpired ? (
            <Text bold color="black/50">
              Expired
            </Text>
          ) : isCancelled ? (
            <Text bold color="red">
              Expires
            </Text>
          ) : null}
          {!isActive && (
            <Text color={isExpired ? "black/50" : "red"} bold>
              {expirationDate ? format(expirationDate, "MMM d, yyyy") : "-"}
            </Text>
          )}
          {isActive && (
            <>
              <Text>Next payment due</Text>
              <Text>
                {nextPaymentDate
                  ? format(new Date(nextPaymentDate), "MMM d, yyyy")
                  : "-"}
              </Text>
            </>
          )}
        </div>
        <div className="flex items-center justify-between py-3 px-5 border-t border-border-1">
          <Text color={textBlack}>Payment method</Text>
          <Text color={textBlack}>{paymentMethodLabel}</Text>
        </div>
      </div>

      {isApplePayment ? (
        <div>
          <Text paragraph md color="black/70">
            To edit your payment information from this computer, you will need
            the iTunes or Apple Music app installed. If you do not have either
            of these apps, using your iPhone, please follow the instructions to{" "}
            <a
              className="underline"
              href="https://support.mileiq.com/hc/en-us/articles/205151293-How-to-Update-Billing-Information#apple"
              rel="noopener noreferrer"
              target="_blank"
            >
              update billing information in iTunes
            </a>
            .
            <br />
            <br />
            If you need additional support with your Apple billing, you will
            need to{" "}
            <a
              className="underline"
              href="https://getsupport.apple.com/?caller=cups&PGF=PGF63003"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact Apple support
            </a>{" "}
            directly.
          </Text>
        </div>
      ) : null}

      <Totals />
    </div>
  );
}

function Totals() {
  const { userData } = useContext(UserDataContext);
  const allTimeTotalsQuery = useQuery(GET_USER_ALL_TIME_TOTALS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (allTimeTotalsQuery.loading)
    return (
      <div className="py-14">
        <Loader />
      </div>
    );

  const { value, distance, totals } = allTimeTotalsQuery.data.drivesSummary;

  /*
      From Emma: we are saving users on average 4 hrs a month,
      which is about 3 minutes per drive.
      The calculation can be Total drives * 3 / 60 and round the number
  */
  const hoursSaved = Math.round((totals.all * 3) / 60);

  return (
    <>
      <Text bold>Since you joined, MileIQ has logged</Text>
      <div className="grid grid-cols-2 items-center gap-2">
        <div className="flex items-center gap-4 border border-border-1 rounded p-4">
          <Icon name="money-bill" color="blue" />
          <div>
            <h6 className="laptop:text-[18px]">
              {value
                ? formatCurrency({ value, currency: userData.currency })
                : 0}
            </h6>
            <Text className="text-[14px] laptop:text-[13px]" color="black/70">
              Total value
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-4 border border-border-1 rounded p-4">
          <Icon name="globe" color="vegasGold" />
          <div>
            <h6 className="laptop:text-[18px]">
              {Math.round(toUIDistance(distance, userData.distanceUnit))}
            </h6>
            <Text className="text-[14px] laptop:text-[13px]" color="black/70">
              Total{" "}
              {userData.distanceUnit === "km"
                ? COUNTRIES_DATA[userData.country].unitName.toLowerCase()
                : "miles"}
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-4 border border-border-1 rounded p-4">
          <Icon name="car-side" color="yellow" />
          <div>
            <h6 className="laptop:text-[18px]">{totals.all}</h6>
            <Text className="text-[14px] laptop:text-[13px]" color="black/70">
              Total drives
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-4 border border-border-1 rounded p-4">
          <Icon name="clock" color="green" />
          <div>
            <h6 className="laptop:text-[18px]">{hoursSaved || 0}</h6>
            <Text className="text-[14px] laptop:text-[13px]" color="black/70">
              Hours saved
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
