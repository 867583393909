import { useCallback, useContext } from "react";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";

import { DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID } from "src/components/modals/DunningResubscribe";

import { TeamsResubscribeModalSources } from "src/services/tracking";

const DUNNING_STATUS = {
  EXPIRED: "EXPIRED",
  IN_GRACE_PERIOD: "IN_GRACE_PERIOD",
  STABLE: "STABLE",
};

function useTeamsCTA() {
  const { team } = useContext(TeamContext);

  const resubscribeModal = useElement(
    DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID,
    {
      props: {
        onClose: () => {
          resubscribeModal.deactivate();
        },
        source: TeamsResubscribeModalSources.DASHBOARD_CTAS,
      },
    }
  );

  const checkAndHandleDunning = useCallback(() => {
    if (team?.isExpired) {
      resubscribeModal.activate();
      return DUNNING_STATUS.EXPIRED;
    }

    if (team?.isInGracePeriod) {
      return DUNNING_STATUS.IN_GRACE_PERIOD;
    }

    return DUNNING_STATUS.STABLE;
  }, [team?.isExpired, resubscribeModal]);

  return { checkAndHandleDunning };
}

export { useTeamsCTA, DUNNING_STATUS };
