import { useContext, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { useTeamLocations } from "src/components/context/TeamLocationsContext";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import PlacesAutocomplete from "src/components/blocks/PlacesAutocomplete";

import { NETWORK_STATES } from "src/services/http";
import {
  AddTeamLocationStepNames,
  Pages,
  trackAddTeamLocationStep,
} from "src/services/tracking";

import TeamContext from "../context/TeamContext";

export const ADD_TEAM_LOCATION_MODAL_ID = "TEAM_LOCATION_MODAL";

registerElement(ADD_TEAM_LOCATION_MODAL_ID, AddTeamLocation);

function AddTeamLocation({ onClose }) {
  const { team } = useContext(TeamContext);
  const ref = useRef();
  const [place, setPlace] = useState(null);
  const [locationName, setLocationName] = useState("");
  const {
    addTeamLocation,
    addTeamLocationNetworkState,
    addTeamLocationError,
    resetTeamLocationErrorStates,
  } = useTeamLocations();

  const isCreatingTeamLocation =
    addTeamLocationNetworkState === NETWORK_STATES.LOADING;

  const handleSetPlace = (newPlace) => {
    setPlace(newPlace);
    resetTeamLocationErrorStates();
  };

  const handleSetLocationName = (e) => {
    setLocationName(e.target.value);
    resetTeamLocationErrorStates();
  };

  const handleSubmitLocation = async (e) => {
    e?.preventDefault();

    if (isCreatingTeamLocation) return;

    const addressName = place.name;
    const lat = place.location.latitude;
    const lng = place.location.longitude;

    const success = await addTeamLocation({
      name: locationName,
      address: addressName,
      lat,
      lng,
    });

    if (success) {
      onClose();
    }
  };

  const errorField = addTeamLocationError?.field;
  const errorMessage = addTeamLocationError?.message;

  const isLocationNameValid = locationName?.trim?.().length > 0;

  return (
    <Modal
      show
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      contentClassName="pt-[30px]"
      passiveBackdrop
    >
      {/* Google Maps does not work without a Div element or Map instance */}
      <div id="team-location-fake-map-obj" ref={ref} className="hidden" />

      <h5>Add a Team Location</h5>
      <Text paragraph className="text-15 mt-[10px]">
        Assign a name to a location your team regularly drives to. This name
        will show up in future reports.
      </Text>
      <form className="mt-[30px] flex flex-col" onSubmit={handleSubmitLocation}>
        <div className="flex flex-col gap-[10px]">
          <Text className="text-15" semibold>
            Address
          </Text>
          <PlacesAutocomplete
            mapObj={ref}
            onSelectPlace={handleSetPlace}
            placeName={place?.name}
            placeholder={"Search for a location"}
            maximumNamedLocations={0}
            showClearButton={false}
            itemClassName="flex items-center h-[34px]"
            onFocus={() => {
              trackAddTeamLocationStep({
                orgId: team.orgId,
                orgGroupId: team.orgGroupId,
                page: Pages.TEAM_LOCATIONS,
                step: AddTeamLocationStepNames.ADDRESS,
              });
            }}
            inputProps={{
              name: "locationAddress",
              valid: errorField !== "address",
              autoComplete: "off",
            }}
          />
          {errorField === "address" && errorMessage && (
            <Text color="red" className="text-13">
              {errorMessage}
            </Text>
          )}
        </div>
        <div className="flex flex-col gap-[10px] mt-5">
          <Text className="text-15" semibold>
            Location name
          </Text>
          <Input
            value={locationName}
            onChange={handleSetLocationName}
            name="locationName"
            placeholder="Name this location"
            valid={errorField !== "name"}
            autoComplete="off"
            onFocus={() => {
              trackAddTeamLocationStep({
                orgId: team.orgId,
                orgGroupId: team.orgGroupId,
                page: Pages.TEAM_LOCATIONS,
                step: AddTeamLocationStepNames.LOCATION_NAME,
              });
            }}
          />
          {errorField === "name" && errorMessage && (
            <Text color="red" className="text-13">
              {errorMessage}
            </Text>
          )}
        </div>
        <div className="flex gap-[10px] justify-end mt-[30px]">
          <Button
            type="button"
            secondary
            onClick={onClose}
            className="w-[110px] font-medium text-15"
          >
            Cancel
          </Button>
          <Button
            loading={isCreatingTeamLocation}
            disabled={
              !place?.location?.latitude ||
              !locationName ||
              !!errorField ||
              !isLocationNameValid
            }
            type="submit"
            primary
            className="min-w-[110px] font-medium text-15"
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
}
