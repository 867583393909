import React, { useState } from "react";

import DatePicker from "src/components/elements/DatePicker";
import Text from "src/components/elements/Text";

export default EffectiveDatePicker;

function EffectiveDatePicker({ onSelect, defaultDate, ...props }) {
  const now = new Date();
  const [date, setDate] = useState(defaultDate || now);

  const handleSelect = (d) => {
    setDate(d);
    onSelect(d);
  };

  return (
    <DatePicker
      date={date}
      prefix={
        <Text bold className="mr-2">
          Effective
        </Text>
      }
      minDate={now}
      onSelect={handleSelect}
      {...props}
    />
  );
}
