import format from "date-fns/format";
import React, { useContext } from "react";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import TeamDriveSummary from "src/components/blocks/teams-drives/TeamDriveSummary";

import {
  reportDownloadSources,
  reportEmailSources,
} from "src/services/tracking";

export default ReportDetailSidebar;

function ReportDetailSidebar() {
  const { team } = useContext(TeamContext);
  const {
    generatingPDF,
    generatingCSV,
    generateReportAsCSV,
    generateReportAsPDF,
    detailedReport: report,
    openEmailReportModal,
    selectedDrive,
    pastSelectedDrive,
    setPastSelectedDrive,
    setSelectedDrive,
  } = useTeamReports();

  const handleCloseSummary = () => {
    setPastSelectedDrive(selectedDrive);
    setSelectedDrive(null);
  };

  if (!team?.id || !report) return null;

  const submittedAt = report.lastSubmittedAt
    ? format(report.lastSubmittedAt, "MMMM, yyyy")
    : null;
  const distance = report.formattedDistance;
  const drives = report.totalDrives;
  const manualDrives = report.totalManualDrives;
  const value = report.formattedValue;
  const drivesRange = report.formattedDrivesRange;

  let drive = selectedDrive || pastSelectedDrive;

  // TODO: remove this once the backend is updated
  // Total value in reports api is different from total_value in drives-review api
  // In reports api, total_value takes the value of the drive, including parking and tolls
  // In drives-review api, total_value only takes the value of the drive, including commute only
  if (drive) {
    drive = { ...drive, total_value: drive.value };
  }

  return (
    <>
      <div className="right-panel px-5">
        <h4>{submittedAt}</h4>
        <div className="mt-3">
          <Text>Download report:</Text>
          <div className="flex items-center gap-2 mt-5">
            <Button
              className="w-2/5"
              icon="download"
              onClick={() => {
                if (generatingPDF) return;

                generateReportAsPDF(
                  report.id,
                  reportDownloadSources.REPORT_DETAILS_SIDEBAR
                );
              }}
              loading={generatingPDF}
            >
              PDF
            </Button>
            <Button
              className="w-2/5"
              icon="download"
              onClick={() => {
                if (generatingCSV) return;

                generateReportAsCSV(
                  report.id,
                  reportDownloadSources.REPORT_DETAILS_SIDEBAR
                );
              }}
              loading={generatingCSV}
            >
              CSV
            </Button>
            <Button
              icon="mail"
              onClick={() =>
                openEmailReportModal(reportEmailSources.REPORT_DETAILS_SIDEBAR)
              }
              tooltip={{
                text: "Email report",
                alignX: "right",
                offset: { x: 8, y: 45 },
              }}
            />
          </div>
        </div>
        <div className="mt-6 p-5 flex flex-col gap-7 rounded bg-white border border-border-1 shadow-sm">
          {/* Total Value */}
          <div className="flex items-center">
            <Icon name="money-bill" className="mr-3" />
            <Text bold>Total value</Text>
            <Text bold color="green" className="ml-auto">
              {value ? value : "-"}
            </Text>
          </div>

          {/* Drive range */}
          <div className="flex items-center">
            <Icon name="calendar" className="mr-3" />
            <Text bold>Drive range</Text>
            <Text className="ml-auto">{drivesRange}</Text>
          </div>

          {/* Total Drives */}
          <div className="flex items-center">
            <Icon name="car" className="mr-3" />
            <Text bold>Total drives</Text>
            <Text className="ml-auto">{drives || "-"}</Text>
          </div>

          {/* Manual Drives */}
          <div className="flex items-center">
            <Icon name="m-in-circle" className="mr-3" />
            <Text bold>Manual drives</Text>
            <Text className="ml-auto">{manualDrives || "-"}</Text>
          </div>

          {/* Distance */}
          <div className="flex items-center">
            <Icon name="arrows-h" className="mr-3" />
            <Text bold>Distance</Text>
            <Text className="ml-auto">{distance || "-"}</Text>
          </div>
        </div>
      </div>
      <div
        className={`right-panel with-shadow ${
          selectedDrive ? "open" : "closed"
        }`}
      >
        <TeamDriveSummary
          showSubmittedAt={false}
          drive={drive}
          onClose={handleCloseSummary}
          currency={report.currencyUnits}
          distanceUnits={report.distanceUnits}
        />
      </div>
    </>
  );
}
