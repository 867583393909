import { STORAGE_KEYS as o } from "./storage-manager.js";
import u from "./subscription-manager.js";
import Ft from "../models/server-config.js";
import s from "../common/event-logger.js";
import r from "../../shared-lib/braze-shared-lib.js";
export default class Pt {
  constructor(t) {
    (this.h = t),
      (this.tl = new u()),
      (this.sl = new u()),
      (this.il = new u()),
      (this.rl = null);
  }
  hl() {
    if (null == this.rl) {
      const t = this.h.I(o.q.ol);
      this.rl = null != t ? Ft.Sn(t) : new Ft();
    }
    return this.rl;
  }
  Zs() {
    return this.hl().ul;
  }
  al(t) {
    if (null != t && null != t.config) {
      const e = t.config;
      if (e.time > this.hl().ul) {
        const t = new Ft(
          e.time,
          e.events_blacklist,
          e.attributes_blacklist,
          e.purchases_blacklist,
          e.messaging_session_timeout,
          e.vapid_public_key,
          e.content_cards,
          e.feature_flags
        );
        let s = !1;
        null != t.gl && this.wn() !== t.gl && (s = !0);
        let i = !1;
        null != t.ml.enabled && this.Ys() !== t.ml.enabled && (i = !0);
        let r = !1;
        null != t.cl.enabled && this.ye() !== t.cl.enabled && (r = !0),
          (this.rl = t),
          this.h.B(o.q.ol, t.ss()),
          s && this.tl.St(),
          i && this.sl.St(),
          r && this.il.St();
      }
    }
  }
  yn(t) {
    let e = this.tl.ut(t);
    return this.fl && this.tl.removeSubscription(this.fl), (this.fl = e), e;
  }
  Ms(t) {
    return this.sl.ut(t);
  }
  Te(t) {
    return this.il.ut(t);
  }
  ge(t) {
    return -1 !== this.hl().bl.indexOf(t);
  }
  hu(t) {
    return -1 !== this.hl().dl.indexOf(t);
  }
  Dr(t) {
    return -1 !== this.hl().vl.indexOf(t);
  }
  wl() {
    return this.hl().Cl;
  }
  wn() {
    return this.hl().gl;
  }
  Ys() {
    return this.hl().ml.enabled || !1;
  }
  ye() {
    return this.hl().cl.enabled && null == this.$e()
      ? (s.G(r.A.qs, { e: "Missing feature flag refresh_rate_limit." }), !1)
      : this.hl().cl.enabled || !1;
  }
  $e() {
    return this.hl().cl.refresh_rate_limit;
  }
}
