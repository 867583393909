import React from "react";

import Text from "./Text";

export default MiqSwitcher;

function MiqSwitcher({
  isOn,
  onChange,
  lg,
  className,
  containerClassName,
  label,
  labelAfter,
  disabled,
}) {
  let classes = [
    lg ? "miq-switcher-big" : "miq-switcher",
    isOn ? "on" : "",
    disabled ? "disabled" : "",
  ];

  if (className) classes.push(className);

  const onClick = (e) => {
    if (disabled) return;

    onChange(e);
  };
  return (
    <div
      data-testid="miq-switcher"
      className={`flex justify-between items-center ${containerClassName}`}
    >
      {!labelAfter && label && (
        <Text
          md
          color={`${disabled ? "black/30" : "black/50"}`}
          className="mr-[10px]"
        >
          {label}
        </Text>
      )}
      <div className={classes.join(" ")} onClick={onClick}></div>
      {labelAfter && label && (
        <Text md color="black/50" className="ml-[10px]">
          {label}
        </Text>
      )}
    </div>
  );
}
