import e, { OPTIONS as T } from "../../managers/braze-instance.js";
import ot from "../../util/browser-detector.js";
import {
  clickElement as Et,
  supportsPassive as Tt
} from "../../util/dom-utils.js";
import { KeyCodes as at } from "../../util/key-codes.js";
import u from "../../managers/subscription-manager.js";
export default class InAppMessage {
  constructor(
    t,
    s,
    i,
    e,
    h,
    n,
    r,
    o,
    l,
    E,
    T,
    a,
    m,
    c,
    I,
    A,
    N,
    _,
    L,
    d,
    O,
    S,
    D,
    M,
    R,
    b,
    P,
    p,
    C
  ) {
    (this.message = t),
      (this.messageAlignment = s || InAppMessage.TextAlignment.CENTER),
      (this.duration = E || 5e3),
      (this.slideFrom = i || InAppMessage.SlideFrom.BOTTOM),
      (this.extras = e || {}),
      (this.triggerId = h),
      (this.clickAction = n || InAppMessage.ClickAction.NONE),
      (this.uri = r),
      (this.openTarget = o || InAppMessage.OpenTarget.NONE),
      (this.dismissType = l || InAppMessage.DismissType.AUTO_DISMISS),
      (this.icon = T),
      (this.imageUrl = a),
      (this.imageStyle = m || InAppMessage.ImageStyle.TOP),
      (this.iconColor = c || InAppMessage.xi.Ei),
      (this.iconBackgroundColor = I || InAppMessage.xi.Ci),
      (this.backgroundColor = A || InAppMessage.xi.Ei),
      (this.textColor = N || InAppMessage.xi.Ni),
      (this.closeButtonColor = _ || InAppMessage.xi.Li),
      (this.animateIn = L),
      null == this.animateIn && (this.animateIn = !0),
      (this.animateOut = d),
      null == this.animateOut && (this.animateOut = !0),
      (this.header = O),
      (this.headerAlignment = S || InAppMessage.TextAlignment.CENTER),
      (this.headerTextColor = D || InAppMessage.xi.Ni),
      (this.frameColor = M || InAppMessage.xi.Oi),
      (this.buttons = R || []),
      (this.cropType = b || InAppMessage.CropType.FIT_CENTER),
      (this.orientation = P),
      (this.htmlId = p),
      (this.css = C),
      (this.isControl = !1),
      (this.Ri = !1),
      (this.nl = !1),
      (this.Pi = !1),
      (this.lt = new u()),
      (this.Ui = new u());
  }
  subscribeToClickedEvent(t) {
    return this.lt.ut(t);
  }
  subscribeToDismissedEvent(t) {
    return this.Ui.ut(t);
  }
  removeSubscription(t) {
    this.lt.removeSubscription(t), this.Ui.removeSubscription(t);
  }
  removeAllSubscriptions() {
    this.lt.removeAllSubscriptions(), this.Ui.removeAllSubscriptions();
  }
  closeMessage() {
    this.on(this.mn);
  }
  Me() {
    return !0;
  }
  ll() {
    return this.Me();
  }
  an() {
    return null != this.htmlId && this.htmlId.length > 4;
  }
  sn() {
    return this.an() && null != this.css && this.css.length > 0;
  }
  rn() {
    if (this.an() && this.sn()) return this.htmlId + "-css";
  }
  R() {
    return !this.Ri && ((this.Ri = !0), !0);
  }
  Cr() {
    return this.Ri;
  }
  k() {
    return !this.nl && ((this.nl = !0), this.lt.St(), !0);
  }
  J() {
    return !this.Pi && ((this.Pi = !0), this.Ui.St(), !0);
  }
  Hi(t) {
    if (t && t.parentNode) {
      let s = t.closest(".ab-iam-root");
      if ((null == s && (s = t), this.Me() && null != s.parentNode)) {
        const t = s.parentNode.classList;
        t && t.contains(InAppMessage.Fi) && t.remove(InAppMessage.Fi),
          document.body.removeEventListener("touchmove", InAppMessage.Bi);
      }
      s.className = s.className.replace(InAppMessage.Gi, InAppMessage.Wi);
    }
    return this.animateOut;
  }
  on(t, s) {
    if (null == t) return;
    let i;
    (this.mn = null),
      (i =
        -1 === t.className.indexOf("ab-in-app-message")
          ? t.getElementsByClassName("ab-in-app-message")[0]
          : t);
    let e = !1;
    i && (e = this.Hi(i));
    const h = document.body;
    if (null != h) var n = h.scrollTop;
    const r = () => {
      if (t && t.parentNode) {
        let s = t.closest(".ab-iam-root");
        null == s && (s = t), s.parentNode && s.parentNode.removeChild(s);
      }
      if (null != this.rn()) {
        const t = document.getElementById(this.rn());
        t && t.parentNode && t.parentNode.removeChild(t);
      }
      null != h && "Safari" === ot.browser && (h.scrollTop = n),
        s ? s() : this.J();
    };
    e ? setTimeout(r, InAppMessage.Ki) : r(), this.ke && this.ke.focus();
  }
  Ae() {
    return document.createTextNode(this.message);
  }
  Le(t) {
    let s = "";
    this.message || this.header || !this.Me() || (s = "Modal Image"),
      t.setAttribute("alt", s);
  }
  static Bi(t) {
    (t.targetTouches && t.targetTouches.length > 1) ||
      (t.target.classList &&
        t.target.classList.contains("ab-message-text") &&
        t.target.scrollHeight > t.target.clientHeight) ||
      (document.querySelector(`.${InAppMessage.Fi}`) && t.preventDefault());
  }
  Yi(t) {
    this.Me() &&
      null != t.parentNode &&
      this.orientation !== InAppMessage.Orientation.LANDSCAPE &&
      (null != t.parentNode.classList &&
        t.parentNode.classList.add(InAppMessage.Fi),
      document.body.addEventListener(
        "touchmove",
        InAppMessage.Bi,
        !!Tt() && { passive: !1 }
      )),
      (t.className += " " + InAppMessage.Gi);
  }
  static Xi(t) {
    if (
      t.keyCode === at.Vi &&
      !e.nn(T.qi) &&
      document.querySelectorAll(".ab-modal-interactions").length > 0
    ) {
      const t = document.getElementsByClassName("ab-html-message");
      let s = !1;
      for (let i = 0; i < t.length; i++) {
        const e = t[i].contentWindow.document.getElementsByClassName(
          "ab-programmatic-close-button"
        )[0];
        null != e && (Et(e), (s = !0));
      }
      if (!s) {
        const t = document.querySelectorAll(
          ".ab-modal-interactions > .ab-close-button"
        )[0];
        null != t && Et(t);
      }
    }
  }
  Ji() {
    this.Qi ||
      e.nn(T.qi) ||
      (document.addEventListener("keydown", InAppMessage.Xi, !1),
      e.Zi(() => {
        document.removeEventListener("keydown", InAppMessage.Xi);
      }),
      (this.Qi = !0));
  }
}
(InAppMessage.xi = {
  Ni: 4281545523,
  Ei: 4294967295,
  Ci: 4278219733,
  th: 4293914607,
  sh: 4283782485,
  Oi: 3224580915,
  Li: 4288387995
}),
  (InAppMessage.li = {
    ih: "hd",
    eh: "ias",
    hh: "of",
    nh: "do",
    ki: "umt",
    zi: "tf",
    rh: "te"
  }),
  (InAppMessage.SlideFrom = { TOP: "TOP", BOTTOM: "BOTTOM" }),
  (InAppMessage.ClickAction = {
    NEWS_FEED: "NEWS_FEED",
    URI: "URI",
    NONE: "NONE"
  }),
  (InAppMessage.DismissType = {
    AUTO_DISMISS: "AUTO_DISMISS",
    MANUAL: "SWIPE"
  }),
  (InAppMessage.OpenTarget = { NONE: "NONE", BLANK: "BLANK" }),
  (InAppMessage.ImageStyle = { TOP: "TOP", GRAPHIC: "GRAPHIC" }),
  (InAppMessage.Orientation = { PORTRAIT: "PORTRAIT", LANDSCAPE: "LANDSCAPE" }),
  (InAppMessage.TextAlignment = {
    START: "START",
    CENTER: "CENTER",
    END: "END"
  }),
  (InAppMessage.CropType = {
    CENTER_CROP: "CENTER_CROP",
    FIT_CENTER: "FIT_CENTER"
  }),
  (InAppMessage.es = {
    oh: "SLIDEUP",
    lh: "MODAL",
    Ke: "MODAL_STYLED",
    Tr: "FULL",
    el: "WEB_HTML",
    cn: "HTML"
  }),
  (InAppMessage.Ki = 500),
  (InAppMessage.Eh = 200),
  (InAppMessage.Gi = "ab-show"),
  (InAppMessage.Wi = "ab-hide"),
  (InAppMessage.Fi = "ab-pause-scrolling");
