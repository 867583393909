import "src/models/typings";

import { isEqual } from "date-fns";
import React, { useContext, useState } from "react";

import SettingsContext from "src/components/context/SettingsContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import EffectiveDatePicker from "src/components/blocks/custom-rates/EffectiveDatePicker";
import RatesInput from "src/components/blocks/custom-rates/RatesInput";

import { COUNTRIES, COUNTRIES_DATA } from "src/services/utils";

export default RatesFormUK;

const pref = `${COUNTRIES_DATA[COUNTRIES.GB].currency.sign}/mi`;

function RatesFormUK({
  rates: defaultRates,
  compareRates,
  isRevertingTo,
  onSave,
  onCancel,
  disabled,
  loading,
}) {
  /** @type {{team: Team}} */
  const { team } = useContext(TeamContext);
  const ctxData = useContext(SettingsContext);

  const initialRates = defaultRates || team.cloneRatesForEdit();
  const ratesToCompare = compareRates || initialRates;

  /** @type {[RatesUK, React.Dispatch<React.SetStateAction<RatesUK>]} */
  const [rates, setRates] = useState(initialRates);
  const [rateEmpty, setRateEmpty] = useState(false);

  if (!team?.id) return;

  const updateRate = (r) => {
    setRates(r);
    ctxData.unsavedRates = r;
    setRateEmpty(false);
  };

  const handleChangeCarRate = (v) => {
    const newRates = rates.clone();
    newRates.car = v;
    updateRate(newRates);
  };

  const handleChangeCar2Rate = (v) => {
    const newRates = rates.clone();
    newRates.car2 = v;
    updateRate(newRates);
  };

  const handleChangeMotoRate = (v) => {
    const newRates = rates.clone();
    newRates.moto = v;
    updateRate(newRates);
  };

  const handleChangeMoto2Rate = (v) => {
    const newRates = rates.clone();
    newRates.moto2 = v;
    updateRate(newRates);
  };

  const handleChangeBicycleRate = (v) => {
    const newRates = rates.clone();
    newRates.bicycle = v;
    updateRate(newRates);
  };

  const handleChangeBicycle2Rate = (v) => {
    const newRates = rates.clone();
    newRates.bicycle2 = v;
    updateRate(newRates);
  };

  const handleCancelClicked = () => {
    ctxData.unsavedRates = null;
    updateRate(team.cloneRatesForEdit());
    onCancel();
  };

  const handleEffectiveDateChange = (date) => {
    const newRates = rates.clone();
    newRates.effectiveDate = date;
    updateRate(newRates);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(rates);
  };

  const handleClearInput = () => {
    setRateEmpty(true);
    ctxData.unsavedRates = null;
  };

  const isDifferentDate = !isEqual(
    ratesToCompare.effectiveDate,
    rates.effectiveDate
  );

  const isValidValue =
    rates.car >= 0 &&
    rates.car2 >= 0 &&
    rates.moto >= 0 &&
    rates.moto2 >= 0 &&
    rates.bicycle >= 0 &&
    rates.bicycle2 >= 0 &&
    (rates.car !== ratesToCompare.car ||
      rates.car2 !== ratesToCompare.car2 ||
      rates.moto !== ratesToCompare.moto ||
      rates.moto2 !== ratesToCompare.moto2 ||
      rates.bicycle !== ratesToCompare.bicycle ||
      rates.bicycle2 !== ratesToCompare.bicycle2);

  const canSaveRate = !rateEmpty && (isValidValue || isDifferentDate);

  const isDisabled = disabled || loading;

  return (
    <form className="w-full mt-8" onSubmit={handleSubmit}>
      <div className="border border-border-1 rounded p-5">
        <div className="mb-4 flex items-center">
          <Text semibold className="w-1/3">
            Vehicles
          </Text>
          <Text semibold className="w-1/3">
            First 10K Miles
          </Text>
          <Text semibold className="w-1/3">
            After 10K Miles
          </Text>
        </div>
        <div>
          <div className="flex items-center">
            {/* cars and vans */}
            <div className="w-1/3 flex items-center flex-grow">
              <Icon name="car" className="mr-3" />
              <Text>Cars and Vans</Text>
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.car}
                onChange={handleChangeCarRate}
                placeholder={initialRates.car}
                onClear={handleClearInput}
              />
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.car2}
                onChange={handleChangeCar2Rate}
                placeholder={initialRates.car2}
                onClear={handleClearInput}
              />
            </div>
          </div>

          {/* Motorcycles */}
          <div className="mt-5 flex items-center">
            <div className="w-1/3 flex items-center flex-grow">
              <Icon name="bike" className="mr-3" />
              <Text>Motorcycles</Text>
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.moto}
                onChange={handleChangeMotoRate}
                placeholder={initialRates.moto}
                onClear={handleClearInput}
              />
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.moto2}
                onChange={handleChangeMoto2Rate}
                placeholder={initialRates.moto2}
                onClear={handleClearInput}
              />
            </div>
          </div>

          {/* Bikes */}
          <div className="mt-5 flex items-center">
            <div className="w-1/3 flex items-center flex-grow">
              <Icon name="scooter" className="mr-3" />
              <Text>Bikes</Text>
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.bicycle}
                onChange={handleChangeBicycleRate}
                placeholder={initialRates.bicycle}
                onClear={handleClearInput}
              />
            </div>
            <div className="w-1/3 flex items-center flex-grow">
              <RatesInput
                disabled={isRevertingTo || isDisabled}
                prefix={pref}
                rate={rates.bicycle2}
                onChange={handleChangeBicycle2Rate}
                placeholder={initialRates.bicycle2}
                onClear={handleClearInput}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex w-[250px]">
        <EffectiveDatePicker
          alignment={{ right: true, offset: { x: 5 } }}
          contentClassName="right"
          defaultDate={rates.effectiveDate}
          disabled={isDisabled}
          onSelect={handleEffectiveDateChange}
        />
      </div>
      <div className="flex mt-8">
        <Button
          type="submit"
          className="mr-2"
          disabled={isDisabled || (!canSaveRate && !isRevertingTo)}
          loading={loading}
        >
          Save
        </Button>
        <Button
          ghost
          type="button"
          onClick={handleCancelClicked}
          disabled={isDisabled}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
