import { useContext, useEffect } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import { ROUTE_TELEMETRY_MAP } from "src/hooks/usePageTracking";

import {
  ModalAbandonMethods,
  TeamsUpdatePaymentSources,
  trackTeamsDunningWarningModalAbandoned,
  trackTeamsDunningWarningModalStarted,
} from "src/services/tracking";
import { timeout } from "src/services/utils";

import SadCloudyCar2 from "public/assets/img/illustrations/sad-cloudy-car-2.png";
import SadCloudyCar from "public/assets/img/illustrations/sad-cloudy-car.png";

import TeamContext from "../context/TeamContext";
import { useTeamDunning } from "../context/TeamDunningContext";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "./DunningSubscriptionRecoverPayment";

export const DUNNING_TEAMS_WARNING_MODAL_ID = "DUNNING_TEAMS_WARNING_MODAL_ID";

registerElement(DUNNING_TEAMS_WARNING_MODAL_ID, DunningTeamsWarning);

function DunningTeamsWarning({ onClose }) {
  const { team } = useContext(TeamContext);
  const { daysUntilGracePeriodEnds, dayLabel, resetDunningState } =
    useTeamDunning();

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.DUNNING_WARNING_MODAL,
      },
    }
  );

  const trackingParams = {
    orgId: team?.orgId,
    orgGroupId: team?.orgGroupId,
    subPlan: team?.subscription?.plan,
    subType: team?.subscription?.type,
    daysUntilGracePeriodEnds: team?.daysUntilGracePeriodEnds,
    teamsDriverCount: team?.subscription?.numberOfSeats,
    subscriptionExpired: team?.isExpired,
    webPage: ROUTE_TELEMETRY_MAP[window.location.pathname],
  };

  useEffect(() => {
    trackTeamsDunningWarningModalStarted(trackingParams);
  }, []);

  const handleUpdatePayment = async () => {
    onClose();
    await timeout(0);
    subscriptionRecoverPayment.activate();
  };

  const handleClose = (modalAbandonMethod) => {
    trackTeamsDunningWarningModalAbandoned({
      ...trackingParams,
      modalAbandonMethod,
    });

    onClose();
  };

  const expiredDescriptionCopy =
    "Resubscribe to access your dashboard and keep using MileIQ for Teams.";
  const gracePeriodDescriptionCopy = `Please update your payment information to keep using MileIQ for Teams. Without updated payment information, your subscription will end in ${daysUntilGracePeriodEnds} ${dayLabel}.`;
  const titleCopy = team?.isExpired
    ? "Your subscription has expired."
    : "Your payment has failed";
  const descriptionCopy = team?.isExpired
    ? expiredDescriptionCopy
    : gracePeriodDescriptionCopy;
  const cancelButtonCopy = "I'll do this later";
  const submitButtonCopy = team?.isExpired ? "Resubscribe" : "Update payment";
  const Illustration = team?.isExpired ? SadCloudyCar2 : SadCloudyCar;

  return (
    <Modal
      closable={false}
      className="w-[440px]"
      onClose={() => handleClose(ModalAbandonMethods.MODAL_DISMISS)}
      passiveBackdrop
    >
      <div className="flex flex-col gap-[30px]">
        <div>
          <h5>{titleCopy}</h5>
          <p className="mt-2.5">{descriptionCopy}</p>
        </div>
        <div className="w-full flex justify-center">
          <img
            src={Illustration}
            alt="Illustration of a sad car"
            className="h-[213px]"
          />
        </div>
        <div className="flex items-center gap-2.5 justify-end">
          <Button
            className="font-medium min-w-[135px]"
            secondary
            onClick={() => handleClose(ModalAbandonMethods.CLOSE_CTA)}
            type="button"
          >
            {cancelButtonCopy}
          </Button>
          <Button
            className={`font-medium ${
              team?.isExpired ? "min-w-[135px]" : "min-w-[149px]"
            }`}
            type="submit"
            onClick={handleUpdatePayment}
          >
            {submitButtonCopy}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
