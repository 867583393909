import { addDays, format, getDay, isSameDay, startOfDay } from "date-fns";
import { createContext, useContext, useMemo, useState } from "react";

import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { REMINDER_PERIOD } from "src/models/report-reminder";

import { NETWORK_STATES } from "src/services/http";
import {
  fetchTeamReportReminder,
  updateTeamReportReminder,
} from "src/services/teams";
import {
  ReportReminderRepeatsScheduleSettingTypes,
  trackTeamsReportRemindersDisabled,
  trackTeamsReportRemindersEnabled,
  trackTeamsReportRemindersUpdated,
} from "src/services/tracking";

import { useAppFlash } from "./Flash";
import TeamContext from "./TeamContext";

const ReportReminderContext = createContext();

export const useReportReminder = () => {
  const context = useContext(ReportReminderContext);

  if (!context) {
    throw new Error(
      "useReportReminder must be used within a ReportReminderContext"
    );
  }

  return context;
};

export const BASE_WORDS_FOR_PERIOD = {
  [REMINDER_PERIOD.DAY]: "day",
  [REMINDER_PERIOD.WEEK]: "week",
  [REMINDER_PERIOD.MONTH]: "month",
  [REMINDER_PERIOD.YEAR]: "year",
};

const INITIAL_STATE = {
  isReminderEnabled: false,
  reminderPeriod: REMINDER_PERIOD.MONTH,
  customReminderSettings: null,
  reminderStartDate: startOfDay(addDays(new Date(), 1)),
};

export function getDayName(dayOfWeek) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days[dayOfWeek];
}

export function getNumericDayPositionDescription(date) {
  return format(date, "'the' do");
}

export function getWeekdayPositionDescription(date) {
  const dayOfWeek = getDay(date);
  const dayName = getDayName(dayOfWeek);

  return dayName;
}

function getCustomPeriodLabel({ customReminderSettings, reminderStartDate }) {
  if (!customReminderSettings) return "Custom";

  const { period, frequency } = customReminderSettings;

  if ([REMINDER_PERIOD.DAY, REMINDER_PERIOD.YEAR].includes(period)) {
    const baseWord = BASE_WORDS_FOR_PERIOD[period];
    const word = `${baseWord}${frequency > 1 ? "s" : ""}`;
    const countPrefix = frequency > 1 ? `${frequency} ` : "";

    return `Every ${countPrefix}${word}`;
  }

  if (period === REMINDER_PERIOD.WEEK) {
    const word = frequency > 1 ? "weeks" : "week";
    const countPrefix = frequency > 1 ? `${frequency} ` : "";
    const occurrenceDescription =
      getWeekdayPositionDescription(reminderStartDate);

    if (frequency === 1) {
      return `Weekly on ${occurrenceDescription}`;
    }

    return `Every ${countPrefix}${word} on ${occurrenceDescription}`;
  }

  if (period === REMINDER_PERIOD.MONTH) {
    const word = frequency > 1 ? "months" : "month";
    const countPrefix = frequency > 1 ? `${frequency} ` : "";
    const occurrenceDescription =
      getNumericDayPositionDescription(reminderStartDate);

    if (frequency === 1) {
      return `Monthly on ${occurrenceDescription}`;
    }

    return `Every ${countPrefix}${word} on ${occurrenceDescription}`;
  }

  return "Custom";
}

function mapToApiContract({
  isReminderEnabled,
  reminderStartDate,
  reminderPeriod,
  customReminderSettings,
}) {
  let payload = {
    is_enabled: isReminderEnabled,
    start_date: format(reminderStartDate, "yyyy-MM-dd'T'HH:mm:ss"),
  };

  switch (reminderPeriod) {
    case REMINDER_PERIOD.MONTH:
      payload.period = "month";
      payload.frequency = 1;
      payload.day_number = reminderStartDate.getDate();
      break;

    case REMINDER_PERIOD.DAY:
      payload.period = "day";
      payload.frequency = customReminderSettings.frequency;
      break;

    case REMINDER_PERIOD.YEAR:
      payload.period = "year";
      payload.frequency = customReminderSettings.frequency;
      break;

    case REMINDER_PERIOD.WEEK:
      payload.period = "week";
      payload.frequency = 1;
      payload.day_number = reminderStartDate.getDay();
      break;

    case REMINDER_PERIOD.CUSTOM:
      payload.period = customReminderSettings.period;
      payload.frequency = customReminderSettings.frequency;
      if (payload.period === REMINDER_PERIOD.WEEK) {
        payload.day_number = reminderStartDate.getDay();
      }
      if (payload.period === REMINDER_PERIOD.MONTH) {
        payload.day_number = reminderStartDate.getDate();
      }
      break;

    default:
      throw new Error(`Unsupported reminder period: ${reminderPeriod}`);
  }

  return payload;
}

export const ReportReminderProvider = ({ children }) => {
  const { flash } = useAppFlash();
  const [isReminderEnabled, setIsReminderEnabled] = useState(
    INITIAL_STATE.isReminderEnabled
  );
  const [reminderPeriod, setReminderPeriod] = useState(
    INITIAL_STATE.reminderPeriod
  );
  const [customReminderSettings, setCustomReminderSettings] = useState(
    INITIAL_STATE.customReminderSettings
  );
  const [reminderStartDate, setReminderStartDate] = useState(
    INITIAL_STATE.reminderStartDate
  );
  const [fetchReportReminderNetworkState, setFetchReportReminderNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [
    updateReportReminderNetworkState,
    setUpdateReportReminderNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const [originalReminderSettings, setOriginalReminderSettings] =
    useState(INITIAL_STATE);

  const { team } = useContext(TeamContext);

  const isFetching = fetchReportReminderNetworkState === NETWORK_STATES.LOADING;
  const isSubmitting =
    updateReportReminderNetworkState === NETWORK_STATES.LOADING;

  const periodOptions = {
    [REMINDER_PERIOD.WEEK]: {
      label: format(reminderStartDate, "'Weekly on' EEEE"),
    },
    [REMINDER_PERIOD.MONTH]: {
      label: format(reminderStartDate, "'Monthly on the' do"),
    },
    [REMINDER_PERIOD.CUSTOM]: {
      label: getCustomPeriodLabel({
        customReminderSettings,
        reminderStartDate,
      }),
      dropdownLabel: "Custom",
    },
  };

  async function toggleReminder() {
    if (isSubmitting) return;

    const newState = {
      ...INITIAL_STATE,
      isReminderEnabled: !isReminderEnabled,
    };

    const trackFunction = newState.isReminderEnabled
      ? trackTeamsReportRemindersEnabled
      : trackTeamsReportRemindersDisabled;

    setIsReminderEnabled(newState.isReminderEnabled);
    setReminderPeriod(newState.reminderPeriod);
    setCustomReminderSettings(newState.customReminderSettings);
    setReminderStartDate(newState.reminderStartDate);
    setOriginalReminderSettings(newState);

    try {
      setUpdateReportReminderNetworkState(NETWORK_STATES.LOADING);

      await updateTeamReportReminder(team.id, mapToApiContract(newState));

      trackFunction({
        freeTrial: team.subscription?.isFreeTrialActive,
      });
      setUpdateReportReminderNetworkState(NETWORK_STATES.LOADED);
    } catch (err) {
      setUpdateReportReminderNetworkState(NETWORK_STATES.ERROR);
      console.log("Failed to toggle reminder settings", err);
    }
  }

  async function fetchReportReminder() {
    try {
      if (team.isExpired) {
        setIsReminderEnabled(false);
        return;
      }

      setFetchReportReminderNetworkState(NETWORK_STATES.LOADING);

      const reportReminder = await fetchTeamReportReminder(team.id);
      const {
        startDate,
        period,
        dayNumber,
        frequency,
        isEnabled,
        isCustomReminder,
        isWeekPeriod,
      } = reportReminder;

      if (!isEnabled) {
        setIsReminderEnabled(false);
        setFetchReportReminderNetworkState(NETWORK_STATES.LOADED);
        return;
      }

      setIsReminderEnabled(true);
      setReminderStartDate(startDate);

      let newCustomSettings = null;
      let reminderPeriod = period;

      if (isCustomReminder) {
        newCustomSettings = {
          period,
          frequency,
          occurrence: isWeekPeriod ? dayNumber : undefined,
        };
      }

      setReminderPeriod(
        isCustomReminder ? REMINDER_PERIOD.CUSTOM : reminderPeriod
      );
      setCustomReminderSettings(newCustomSettings);

      setOriginalReminderSettings({
        isReminderEnabled: isEnabled,
        reminderPeriod: period,
        customReminderSettings: newCustomSettings,
        reminderStartDate: startDate,
      });

      setFetchReportReminderNetworkState(NETWORK_STATES.LOADED);
    } catch (err) {
      if (
        err?.status === 404 &&
        err?.data?.detail ===
          "This MIQ Teams account does not have a report reminders."
      ) {
        setIsReminderEnabled(false);
        setFetchReportReminderNetworkState(NETWORK_STATES.LOADED);
        return;
      }

      setFetchReportReminderNetworkState(NETWORK_STATES.ERROR);
      console.log("Failed to fetch report reminder", err);
    }
  }

  async function submitReminderSettings() {
    if (isSubmitting) return;

    try {
      setUpdateReportReminderNetworkState(NETWORK_STATES.LOADING);

      const isCustomReminder = reminderPeriod === REMINDER_PERIOD.CUSTOM;

      const data = {
        isReminderEnabled: true,
        reminderPeriod,
        customReminderSettings,
        reminderStartDate,
      };

      await updateTeamReportReminder(team.id, mapToApiContract(data));

      setUpdateReportReminderNetworkState(NETWORK_STATES.LOADED);

      const settingTypeMapping = {
        [REMINDER_PERIOD.MONTH]:
          ReportReminderRepeatsScheduleSettingTypes.MONTHLY,
        [REMINDER_PERIOD.WEEK]:
          ReportReminderRepeatsScheduleSettingTypes.WEEKLY,
        [REMINDER_PERIOD.CUSTOM]:
          ReportReminderRepeatsScheduleSettingTypes.CUSTOM,
      };

      trackTeamsReportRemindersUpdated({
        firstDate: reminderStartDate.toISOString(),
        freeTrial: team.subscription?.isFreeTrialActive,
        scheduleSettingType: settingTypeMapping[reminderPeriod],
        scheduleSetting: periodOptions[reminderPeriod].label,
      });

      data.reminderPeriod = isCustomReminder
        ? customReminderSettings.period
        : reminderPeriod;

      setOriginalReminderSettings(data);

      flash(<Text>Your report submission reminder has been saved.</Text>, {
        type: FlashTypes.BLACK,
      });
    } catch (err) {
      console.log("Failed to submit reminder settings", err);
      setUpdateReportReminderNetworkState(NETWORK_STATES.ERROR);
      flash(
        <Text>Failed to save your report submission reminder settings</Text>,
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  }

  function revertReminderSettingsToOriginal() {
    setIsReminderEnabled(originalReminderSettings.isReminderEnabled);
    setReminderPeriod(
      originalReminderSettings.customReminderSettings
        ? REMINDER_PERIOD.CUSTOM
        : originalReminderSettings.reminderPeriod
    );
    setCustomReminderSettings(originalReminderSettings.customReminderSettings);
    setReminderStartDate(originalReminderSettings.reminderStartDate);
  }

  function hasReminderSettingsChanged() {
    const currentReminderPeriod =
      reminderPeriod === REMINDER_PERIOD.CUSTOM
        ? customReminderSettings.period
        : reminderPeriod;

    const hasReminderEnabledChanged =
      originalReminderSettings.isReminderEnabled !== isReminderEnabled;

    const hasReminderPeriodChanged =
      originalReminderSettings.reminderPeriod !== currentReminderPeriod;

    const hasCustomReminderSettingsChanged =
      JSON.stringify(originalReminderSettings.customReminderSettings) !==
      JSON.stringify(customReminderSettings);

    const hasReminderStartDateChanged = !isSameDay(
      originalReminderSettings.reminderStartDate,
      reminderStartDate
    );

    return (
      hasReminderEnabledChanged ||
      hasReminderPeriodChanged ||
      hasCustomReminderSettingsChanged ||
      hasReminderStartDateChanged
    );
  }

  const hasChanges = hasReminderSettingsChanged();

  const contextValues = useMemo(
    () => ({
      customReminderSettings,
      isReminderEnabled,
      reminderPeriod,
      reminderStartDate,
      isSubmitting,
      isFetching,
      hasChanges,
      periodOptions,
      toggleReminder,
      setReminderPeriod,
      setReminderStartDate,
      setCustomReminderSettings,
      submitReminderSettings,
      fetchReportReminder,
      revertReminderSettingsToOriginal,
    }),
    [
      customReminderSettings,
      isReminderEnabled,
      reminderPeriod,
      reminderStartDate,
      updateReportReminderNetworkState,
      fetchReportReminderNetworkState,
      originalReminderSettings,
    ]
  );

  return (
    <ReportReminderContext.Provider value={contextValues}>
      {children}
    </ReportReminderContext.Provider>
  );
};
