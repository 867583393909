import React, { useEffect, useState } from "react";

import Icon from "src/components/elements/Icon";
import PasswordInput from "src/components/elements/PasswordInput";
import Text from "src/components/elements/Text";

import { INPUT_ERROR_MESSAGES } from "src/services/auth";

export const validations = [
  {
    key: "pwdLength",
    title: "8+ characters",
    reg: /.{8,}/,
  },
  {
    key: "pwdSpecialChar",
    title: "One special character",
    // eslint-disable-next-line no-useless-escape
    reg: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
  },
  {
    key: "pwdUppercase",
    title: "One uppercase letter",
    reg: /[A-Z]/g,
  },
  {
    key: "pwdLowercase",
    title: "One lowercase letter",
    reg: /[a-z]/g,
  },
  {
    key: "pwdDigits",
    title: "One digit",
    reg: /[0-9]/g,
  },
];

export function PasswordValidations({
  password,
  hasConfirmPassword,
  onChange,
  onValid,
  showValidationErrors,
  errorSameAsCurrent,
  requirementsClassName,
  inputProps,
}) {
  const [confirmPassword, setConfirmPassword] = useState(password);
  const [match, setMatch] = useState(!hasConfirmPassword);

  const isPasswordValid = validations.reduce((isValid, { reg }, index) => {
    const matched = password && password.match(reg);
    validations[index].matched = matched;
    return isValid && matched;
  }, !!password);

  const handleConfirmPasswordChange = (confirm) => {
    setConfirmPassword(confirm);
    setMatch(password === confirm);
  };

  useEffect(() => {
    if (typeof onValid === "function") onValid(isPasswordValid && match);
  }, [match, isPasswordValid]);

  const errorMsg1 = showValidationErrors
    ? INPUT_ERROR_MESSAGES.VALID_PASSWORD_REQUIRED
    : "";
  const errorMsg2 = showValidationErrors
    ? errorSameAsCurrent
      ? INPUT_ERROR_MESSAGES.SAME_AS_OLD_PASSWORD
      : INPUT_ERROR_MESSAGES.NOT_MATCH
    : "";

  return (
    <div>
      <PasswordInput
        autoFocus
        className={"mt-12 mb-5"}
        value={password}
        onChange={onChange}
        errorMessage={errorMsg1}
        valid={!showValidationErrors || isPasswordValid}
        placeholder={hasConfirmPassword ? "New password" : "Password"}
        {...inputProps}
      />
      {hasConfirmPassword && (
        <PasswordInput
          className={"mb-5"}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Repeat password"
          errorMessage={errorMsg2}
          valid={!showValidationErrors || (match && !errorSameAsCurrent)}
        />
      )}
      <div className={`${requirementsClassName || "mt-5 mb-4"}`}>
        {validations.map((val) => (
          <div className="flex mb-1" key={val.title}>
            <Icon
              name={val.matched ? "checked-green" : "dot"}
              className={val.matched ? "mr-3" : "mr-4"}
              color={!val.matched && showValidationErrors ? "red" : "gray"}
            />
            <Text className="text-black/60">{val.title}</Text>
          </div>
        ))}
      </div>
    </div>
  );
}
