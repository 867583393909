import Kt from "./trigger-condition.js";
export default class Qt {
  constructor(t, i) {
    (this.fu = t), (this.au = i);
  }
  Hl(t) {
    if (null == this.fu) return !1;
    const i = Kt.du(t[0], this.fu);
    if (!i) return !1;
    let r = null == this.au || 0 === this.au.length;
    if (null != this.au)
      for (let i = 0; i < this.au.length; i++)
        if (this.au[i] === t[1]) {
          r = !0;
          break;
        }
    return i && r;
  }
  static fromJson(t) {
    return new Qt(t ? t.id : null, t ? t.buttons : null);
  }
  ss() {
    return this.fu;
  }
}
