import r from "../../shared-lib/braze-shared-lib.js";
export default class _t {
  constructor(t, e, s) {
    null == t && (t = r.it.nt()),
      (s = parseInt(s)),
      (isNaN(s) || 0 === s) && (s = new Date().valueOf()),
      (this.iu = t),
      (this.fh = s),
      (this.bh = new Date().valueOf()),
      (this.jh = e);
  }
  ss() {
    return { g: this.iu, e: this.jh, c: this.fh, l: this.bh };
  }
  static Sn(t) {
    if (null == t || null == t.g) return null;
    const e = new _t(t.g, t.e, t.c);
    return (e.bh = t.l), e;
  }
}
