/* eslint-disable */
import { useMutation } from "@apollo/client";
import { Button, EmailInput, FormControl } from "@mileiq/design-system";
import "firebase/app";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "src/components/context/UserContext";

import useQueryParams from "src/hooks/useQueryParams";

import {
  ACCOUNT_REALM_TYPE,
  loginWithFirebase,
  setSessionEmailHint,
} from "src/services/auth";
import { report } from "src/services/error-reporting";
import {
  getAuthRedirectResult,
  getFirebaseUser,
  signInWithMicrosoft,
} from "src/services/firebase";
import { setAccessToken, setRefreshToken } from "src/services/storage";
import {
  trackLoginCompleted,
  trackLoginEmailAdded,
  trackLoginFailed,
} from "src/services/tracking";
import { AUTH_SCREENS, isValidEmail, parseJwt } from "src/services/utils";

import { VALIDATE_EMAIL } from "src/graphql/mutations";

export function LoginEmailStep() {
  const inpRef = useRef();
  const { refreshUser } = useUser();
  const history = useHistory();
  const searchParams = useQueryParams();

  const oAuthEmail = searchParams?.get?.("oauth_hint");

  const [errorMessage, setErrorMessage] = useState(null);
  const [oAuthLoading, setOAuthLoading] = useState(oAuthEmail);

  const hint = searchParams?.get?.("hint") || searchParams?.get?.("email");
  const [email, setEmail] = useState(hint || oAuthEmail || "");

  const [validateEmail, { loading: validateEmailLoading }] = useMutation(
    VALIDATE_EMAIL,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const focusInput = () => {
    setTimeout(() => inpRef.current?.focus(), 100);
  };

  const handleEmailChange = (email) => {
    setErrorMessage(null);
    setEmail(email);
  };

  const handleLoginSuccess = (token, refreshToken) => {
    // get parseId
    const { sub } = parseJwt(token);
    trackLoginCompleted({ userId: sub });
    setAccessToken(token);
    setRefreshToken(refreshToken);

    refreshUser();

    history.push("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    if (!isValidEmail(email)) {
      trackLoginFailed({ email, isEmailIncorrect: true });
      return setErrorMessage("Please enter a valid email address");
    }

    trackLoginEmailAdded({ email });

    try {
      searchParams?.delete?.("hint");
      searchParams?.set?.("email", email);
      setErrorMessage(null);

      const {
        data: {
          validateEmail: { code, realm, userExists },
        },
      } = await validateEmail({ variables: { email } });
      if (!userExists) {
        setSessionEmailHint(email);
        trackLoginFailed({ email, isEmailIncorrect: true });
        focusInput();
        return setErrorMessage(
          "An account with this email address does not exist"
        );
      }

      if (userExists) {
        if (realm === ACCOUNT_REALM_TYPE.MSA) {
          const firebaseUser = getFirebaseUser();

          if (
            firebaseUser?.accessToken &&
            firebaseUser?.email?.toLowerCase() === email?.toLowerCase()
          ) {
            try {
              setOAuthLoading(true);

              const { token, refresh_token } = await loginWithFirebase(
                firebaseUser.accessToken,
                email,
                "LoginEmailStep.jsx:116"
              );

              return handleLoginSuccess(token, refresh_token);
            } catch (err) {
              report({
                devInfo: "LoginEmailStep.jsx::handleSubmit::MSA",
                properties: { email },
                error: err,
              });
            } finally {
              setOAuthLoading(false);
            }
          }

          history.push(`/login?oauth_hint=${email}`);

          signInWithMicrosoft(email);
        } else {
          history.replace(`/login?${searchParams?.toString()}`, {
            screen: AUTH_SCREENS.EMAIL,
          });

          history.push(`/login?${searchParams?.toString()}`, {
            screen: AUTH_SCREENS.PWD,
          });
        }
      }
    } catch (error) {
      report({
        devInfo: "LoginEmailStep.jsx::handleSubmit::MSA",
        properties: { email },
        error,
      });
      setErrorMessage("Something went wrong. Please try again later");
      focusInput();
    }
  };

  useEffect(() => {
    if (oAuthLoading) {
      getAuthRedirectResult()
        .then((response) => {
          if (!response) return;
          handleLoginSuccess(response?.token, response?.refresh_token);
        })
        .catch((error) => {
          console.error(error);
          trackLoginFailed({ email });
          setErrorMessage(
            "There was an error logging in with Microsoft. Please try again."
          );
        })
        .finally(() => setOAuthLoading(false));
    }
  }, []);

  const loading = validateEmailLoading || oAuthLoading;

  return (
    <>
      <h2>Log in</h2>
      <form
        onSubmit={handleSubmit}
        noValidate
        className="mt-12 flex flex-col gap-[15px] laptop:gap-[10px]"
      >
        <FormControl error={errorMessage}>
          <EmailInput
            autoFocus
            placeholder="Email"
            disabled={loading}
            value={email}
            onChange={handleEmailChange}
            data-testid="login-email-input"
            ref={inpRef}
          />
        </FormControl>
        <div style={{ "--button-width": "100%" }}>
          <Button
            label="Continue"
            type="submit"
            data-testid="login-continue-btn"
            loading={loading}
          />
        </div>
      </form>
      <p className="text-18 laptop:text-[14px] mt-12 text-black/70">
        Don't have an account?
        <br /> Check out our plans for{" "}
        <a
          href="https://mileiq.com/pricing"
          className="text-blue hover:text-blue-hover underline font-medium"
        >
          individuals
        </a>{" "}
        and{" "}
        <a
          href="https://mileiq.com/pricing-for-business"
          className="text-blue hover:text-blue-hover underline font-medium"
        >
          teams
        </a>
        .
      </p>
    </>
  );
}
