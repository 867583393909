import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default AutoClassificationPageQuickTips;

function AutoClassificationPageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/211261803-How-to-Auto-classify-Frequent-Drives">
            How to: Auto-Classify Frequent Drives
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/203798899-How-to-Set-Work-Hours">
            How to: Set Work Hours
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          Watch a video on{" "}
          <QuickTipsLink url="https://www.youtube.com/watch?v=6hxInwL8RXA">
            setting up Work Hours in the mobile app.
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          Watch a video on{" "}
          <QuickTipsLink url="https://www.youtube.com/watch?v=X4QZYfxhxDk">
            setting up Frequent Drives in the mobile app.
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
