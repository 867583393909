import React from "react";

import { useUser } from "src/components/context/UserContext";

import Text from "src/components/elements/Text";

export default function TeamMemberName({ member, adminMeTag }) {
  const { user } = useUser();
  const memberName =
    member.firstName && member.lastName
      ? `${member.firstName} ${member.lastName}`
      : null;
  let displayNameText = memberName || member?.email;
  let emailText = member.email;
  if (adminMeTag && member.id === user.id) {
    displayNameText += " (me)";
  }
  return (
    <div className="flex flex-col">
      <Text lg className="truncate" title={displayNameText}>
        {displayNameText}
      </Text>
      <Text sm color={"black/50"} className="truncate" title={emailText}>
        {emailText}
      </Text>
    </div>
  );
}
