import e from "../managers/braze-instance.js";
import re from "./feed-provider.js";
const ie = {
  t: !1,
  provider: null,
  er: () => (
    ie.o(),
    ie.provider || ((ie.provider = new re(e.l(), e.mr())), e.ar(ie.provider)),
    ie.provider
  ),
  o: () => {
    ie.t || (e.u(ie), (ie.t = !0));
  },
  destroy: () => {
    (ie.provider = null), (ie.t = !1);
  }
};
export default ie;
