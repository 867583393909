import "src/models/typings";

import { useMutation } from "@apollo/client";
import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import DriveForm from "src/components/blocks/drives/DriveForm";

import Drive from "src/models/drive";

import { getRefetchParams } from "src/services/apollo-utils";

import { ADD_DRIVE } from "src/graphql/mutations";

export default AddDriveModal;

export const ELEMENT_ID = "ADD_DRIVE_MODAL";
registerElement(ELEMENT_ID, AddDriveModal);

function AddDriveModal({ onClose, onSubmit }) {
  const [addDriveMutFn, addDriveMutState] = useMutation(ADD_DRIVE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
    onQueryUpdated: (q) => {
      q.refetch(getRefetchParams(q));
    },
  });

  const handleAddNewDrive = async (data) => {
    const res = await addDriveMutFn({
      variables: {
        data,
      },
    });
    onSubmit(new Drive(res.data.addDrive));
  };

  return (
    <Modal
      closable
      show
      className="min-w-[680px] laptop:min-w-[660px]"
      onClose={onClose}
      testId="add-drive-modal"
    >
      <DriveForm
        title="Add drive"
        onSubmit={handleAddNewDrive}
        submitBtnText="Add drive"
        loading={addDriveMutState.loading}
      />
    </Modal>
  );
}
