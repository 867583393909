import { useContext, useEffect, useRef, useState } from "react";

import LayersContext from "src/lib/layers/LayersContext";

export default useElement;

// hook to control some element
// TODO: autoregistration ??
function useElement(elId, defaultOpts) {
  const layerCtx = useContext(LayersContext);
  const [open, setOpen] = useState(false);
  const elRef = useRef();

  useEffect(() => {
    return () => {
      elRef.current?.deactivate();
    };
  }, []);

  return {
    getProps: () => {
      return elRef.current?.getProps();
    },
    updateProps: (props) => {
      if (elRef.current) {
        elRef.current.updateProps(props);
      }
    },
    activate: (opts = { props: {} }) => {
      if (!elRef.current) {
        const newOpts = { ...defaultOpts, ...opts };
        const newProps = { ...defaultOpts?.props, ...opts.props };
        elRef.current = layerCtx.activate(elId, {
          ...newOpts,
          props: newProps,
          onActivate: () => {
            setOpen(true);
            if (typeof newOpts.onActivate === "function") {
              newOpts.onActivate();
            }
          },
          onDeactivate: () => {
            if (typeof newOpts.onDeactivate === "function") {
              newOpts.onDeactivate();
            }
            setOpen(false);
            elRef.current = null;
          },
        });
      } else {
        elRef.current?.deactivate();
      }
    },
    deactivate: () => {
      elRef.current?.deactivate();
    },
    open,
  };
}
