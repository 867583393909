import { useMutation } from "@apollo/client";
import React, { useContext } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Loader from "src/components/elements/Loader";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";

import { Pages, trackEmailSettingUpdated } from "src/services/tracking";

import { EDIT_SEND_NOTIFICATION_STATUS_MUTATION } from "src/graphql/mutations";

export default NotificationsPage;

const NOTIFICATION_OPTIONS = {
  SEND_WEEKLY: "sendWeekly",
  SEND_MONTHLY: "sendMonthly",
  SEND_OTHER: "sendOther",
  SEND_PROMO: "isPromoOptin",
};

const notificationOptionsData = {
  [NOTIFICATION_OPTIONS.SEND_WEEKLY]: {
    title: "Weekly email",
    text: "A summary of your drives delivered to your inbox every Monday.",
  },
  [NOTIFICATION_OPTIONS.SEND_MONTHLY]: {
    title: "Month-end email",
    text: "Last month at a glance - great for expense reports",
  },
  [NOTIFICATION_OPTIONS.SEND_OTHER]: {
    title: "Other email",
    text: "Product updates, resources you can use to help your business, company news, marketing emails",
  },
  [NOTIFICATION_OPTIONS.SEND_PROMO]: {
    title: "Promotional email",
    text: "Receive special discounts and promotions from MileIQ",
  },
};

function NotificationsPage() {
  const { userData } = useContext(UserDataContext);

  const [editSendNotifMutFn, { loading }] = useMutation(
    EDIT_SEND_NOTIFICATION_STATUS_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserData"],
      onQueryUpdated: (q) => q.refetch(),
    }
  );

  const handleSwitchChange = async (key) => {
    const newVal = !userData[key];
    const data = {
      page: Pages.NOTIFICATION_SETTINGS,
    };
    switch (key) {
      case NOTIFICATION_OPTIONS.SEND_WEEKLY:
        data.weeklyEnabled = newVal;
        break;
      case NOTIFICATION_OPTIONS.SEND_MONTHLY:
        data.monthlyEnabled = newVal;
        break;
      case NOTIFICATION_OPTIONS.SEND_OTHER:
        data.otherEnabled = newVal;
        break;
      case NOTIFICATION_OPTIONS.SEND_PROMO:
        data.promoEnabled = newVal;
        break;
    }
    trackEmailSettingUpdated(data);

    await editSendNotifMutFn({
      variables: {
        data: {
          [key]: newVal,
        },
      },
    });
  };

  return (
    <>
      {loading ? (
        <Loader className="p-8" />
      ) : (
        <>
          <div
            className="p-[20px] laptop:p-[15px] relative"
            data-testid="notifications-page"
          >
            <div className="title-row flex items-center justify-between ">
              <div className="">
                <h6 className="mb-[5px]">Email communications</h6>
                <Text paragraph lg>
                  Receive mileage summaries, reports and product announcements.
                </Text>
                <div className="mt-[25px]">
                  {Object.keys(notificationOptionsData).map((key) => {
                    const item = notificationOptionsData[key];
                    return (
                      <div
                        key={key}
                        className="border-beige-medium border-t flex justify-between py-5 w-[340px]"
                      >
                        <div className="mr-[10px]">
                          <Text paragraph lg bold className="leading-[21px]">
                            {item.title}
                          </Text>
                          <Text md color="black/70" className="leading-[18px]">
                            {item.text}
                          </Text>
                        </div>
                        <Switcher
                          lg
                          containerClassName="self-start"
                          isOn={userData[key]}
                          label={userData[key] ? "Yes" : "No"}
                          onChange={() => handleSwitchChange(key)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
