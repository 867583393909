import "src/models/typings";

import { useMutation } from "@apollo/client";
import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import DriveForm from "src/components/blocks/drives/DriveForm";

import { getRefetchParams } from "src/services/apollo-utils";

import { ADD_DRIVE } from "src/graphql/mutations";

export default DuplicateDriveModal;

export const ELEMENT_ID = "DUPLICATE_DRIVE_MODAL";
registerElement(ELEMENT_ID, DuplicateDriveModal);

function DuplicateDriveModal({ drive, onClose, onSubmit }) {
  const [addDriveMutFn, addDriveMutState] = useMutation(ADD_DRIVE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
    onQueryUpdated: (q) => {
      q.refetch(getRefetchParams(q));
    },
  });

  const handleDuplicateDrive = async (data) => {
    await addDriveMutFn({
      variables: {
        data: {
          originaldriveid: drive.id,
          ...data,
        },
      },
    });

    onSubmit();
  };

  return (
    <Modal
      closable
      show
      className="min-w-[680px] laptop:min-w-[660px]"
      onClose={onClose}
      testId="duplicate-drive-modal"
    >
      <DriveForm
        title="Duplicate drive"
        drive={drive}
        onSubmit={handleDuplicateDrive}
        submitBtnText="Duplicate drive"
        loading={addDriveMutState.loading}
      />
    </Modal>
  );
}
