import React, { useContext } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

import { PAYMENT_METHOD } from "src/services/billing";

export default SubscriptionQuickTips;

function SubscriptionQuickTips() {
  const { userData } = useContext(UserDataContext);
  const { subscriptionData, subscriptionType } = userData;

  return (
    <div className="quick-tips">
      <h4 className="mb-4 text-[26px]">Quick Tips</h4>
      {/* Conditional, waiting for information */}
      {/* <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          How to:{" "}
          <QuickTipsLink
            url="https://support.mileiq.com/hc/en-us/articles/203799099-How-to-Cancel-Subscription"
          >
            Cancel subscription
          </QuickTipsLink>{" "}
        </Text>
      </div> */}

      {subscriptionData.paymentService == PAYMENT_METHOD.APPLE ||
      subscriptionData.paymentService == PAYMENT_METHOD.APPLE_SANDBOX ? (
        <div className="tip">
          <Icon name="book-open" color="black" />
          <Text md>
            How to:{" "}
            <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/205151293-How-to-Update-Billing-Information#apple">
              Update billing information in App Store
            </QuickTipsLink>{" "}
          </Text>
        </div>
      ) : subscriptionData.paymentService == PAYMENT_METHOD.GOOGLE ? (
        <div className="tip">
          <Icon name="book-open" color="black" />
          <Text md>
            How to:{" "}
            <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/205151293-How-to-Update-Billing-Information#google">
              Update billing information in Google Play Store
            </QuickTipsLink>{" "}
          </Text>
        </div>
      ) : subscriptionData.paymentService == PAYMENT_METHOD.STRIPE ? (
        <div className="tip">
          <Icon name="book-open" color="black" />
          <Text md>
            How to:{" "}
            <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/205151293-How-to-Update-Billing-Information#web">
              Update billing information in Stripe
            </QuickTipsLink>{" "}
          </Text>
        </div>
      ) : null}
      {subscriptionType === 0 && (
        <>
          <div className="tip">
            <Icon name="book-open" color="black" />
            <Text md>
              How to: Redeem a{" "}
              <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/204480785-How-to-Redeem-a-Promo-Code">
                Promo code
              </QuickTipsLink>{" "}
            </Text>
          </div>
          <div className="tip">
            <Icon name="book-open" color="black" />
            <Text md>
              How to:{" "}
              <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/203799109-How-to-Upgrade-Get-Unlimited-Drives">
                Upgrade / Get Unlimited Drives
              </QuickTipsLink>{" "}
            </Text>
          </div>
          <div className="tip">
            <Icon name="book-open" color="black" />
            <Text md>
              <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/217515866-I-paid-for-a-Premium-subscription-but-my-account-still-says-Free-">
                I paid for a Premium subscription but my account still says Free
              </QuickTipsLink>
            </Text>
          </div>
        </>
      )}
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </div>
  );
}
