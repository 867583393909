import Base from "src/models/base";

export default class Invite extends Base {
  email = null;
  userType = null;

  constructor(email, userType, focus) {
    super("invite_model");
    this.email = email?.toLowerCase() || null;
    this.userType = userType || null;
    this.focus = focus || false;
  }

  get id() {
    return this.__inst_id;
  }
}
