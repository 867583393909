export const ORIENTATION = { PORTRAIT: 0, LANDSCAPE: 1 };
export function _isPhone() {
  return screen.width <= 600;
}
export function _getOrientation() {
  if ("orientation" in window)
    return 90 === Math.abs(window.orientation) || 270 === window.orientation
      ? ORIENTATION.LANDSCAPE
      : ORIENTATION.PORTRAIT;
  if ("screen" in window) {
    let n =
      window.screen.orientation ||
      screen.mozOrientation ||
      screen.msOrientation;
    return (
      null != n && "object" == typeof n && (n = n.type),
      "landscape-primary" === n || "landscape-secondary" === n
        ? ORIENTATION.LANDSCAPE
        : ORIENTATION.PORTRAIT
    );
  }
  return ORIENTATION.PORTRAIT;
}
export function _openUri(n, e, r) {
  e || (null != r && r.metaKey) ? window.open(n) : (window.location = n);
}
export function _getCurrentUrl() {
  return window.location.href;
}
export const WindowUtils = {
  openUri: _openUri,
  so: _isPhone,
  io: _getOrientation,
  gn: _getCurrentUrl
};
