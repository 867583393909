import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import {
  SORT_ORDER,
  TEAM_LOCATIONS_COLUMNS,
  useTeamLocations,
} from "src/components/context/TeamLocationsContext";

import Button from "src/components/elements/Button";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Loader from "src/components/elements/Loader";
import Pagination from "src/components/elements/Pagination";
import Text from "src/components/elements/Text";

import { EDIT_TEAM_LOCATION_MODAL_ID } from "src/components/modals/EditTeamLocation";
import { REMOVE_TEAM_LOCATION_MODAL_ID } from "src/components/modals/RemoveTeamLocation";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { NETWORK_STATES } from "src/services/http";
import {
  Pages,
  trackDeleteTeamLocationStarted,
  trackRenameTeamLocationStarted,
} from "src/services/tracking";

export default TeamLocationsList;

const RECORDS_PER_PAGE = 10;

function TeamLocationsList() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const [selectedTeamLocation, setSelectedTeamLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get("page")) || 1
  );
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    teamLocationsNetworkState,
    teamLocations,
    sortColumn,
    sortOrder,
    sortTeamLocations,
    fetchTeamLocations,
    resetTeamLocationErrorStates,
    resetTeamLocations,
  } = useTeamLocations();

  const editTeamLocationModal = useElement(EDIT_TEAM_LOCATION_MODAL_ID, {
    props: {
      onClose: () => {
        editTeamLocationModal.deactivate();
        resetTeamLocationErrorStates();
      },
    },
  });

  const removeTeamLocationModal = useElement(REMOVE_TEAM_LOCATION_MODAL_ID, {
    props: {
      onClose: () => {
        removeTeamLocationModal.deactivate();
        resetTeamLocationErrorStates();
      },
    },
  });

  useEffect(() => {
    fetchTeamLocations(
      queryParams.get("sort"),
      queryParams.get("order"),
      currentPage
    );

    return () => resetTeamLocations();
  }, []);

  const colsData = {
    [TEAM_LOCATIONS_COLUMNS.NAME]: {
      id: TEAM_LOCATIONS_COLUMNS.NAME,
      title: "Location name",
      extraClass: "pl-5 laptop:pl-[15px]",
      sortable: true,
    },
    [TEAM_LOCATIONS_COLUMNS.ADDRESS]: {
      id: TEAM_LOCATIONS_COLUMNS.ADDRESS,
      title: "Address",
      sortable: false,
    },
    [TEAM_LOCATIONS_COLUMNS.DATE_ADDED]: {
      id: TEAM_LOCATIONS_COLUMNS.DATE_ADDED,
      title: "Date added",
      sortable: true,
    },
    [TEAM_LOCATIONS_COLUMNS.ACTIONS]: {
      id: TEAM_LOCATIONS_COLUMNS.ACTIONS,
      title: "",
      extraClass: "pr-5 laptop:pr-[15px]",
    },
  };

  const handleRenameTeamLocation = () => {
    editTeamLocationModal.activate({
      props: {
        selectedTeamLocation,
      },
    });

    trackRenameTeamLocationStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      page: Pages.TEAM_LOCATIONS,
    });

    setSelectedTeamLocation(null);
  };

  const handleRemoveTeamLocation = () => {
    removeTeamLocationModal.activate({
      props: {
        selectedTeamLocation,
      },
    });

    trackDeleteTeamLocationStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      page: Pages.TEAM_LOCATIONS,
    });

    setSelectedTeamLocation(null);
  };

  const canSort = teamLocations?.length > 1;

  let paginatedTeamLocations = teamLocations;

  if (teamLocations?.length > RECORDS_PER_PAGE) {
    paginatedTeamLocations = teamLocations.slice(
      (currentPage - 1) * RECORDS_PER_PAGE,
      (currentPage - 1) * RECORDS_PER_PAGE + RECORDS_PER_PAGE
    );
  }

  return (
    <div className="flex-grow">
      <div className="miq-list-table team-locations-list-table">
        <div className="miq-list-table-header">
          {Object.values(colsData).map(
            ({ id, title, extraClass, sortable }) => (
              <div
                key={`column-${id}`}
                className={`select-none ${extraClass || ""} ${
                  sortable && canSort ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (sortable && canSort) {
                    sortTeamLocations(id);
                    setCurrentPage(1);
                  }
                }}
              >
                <div className="flex">
                  <Text color="black/70">{title}</Text>
                  {sortable && canSort && sortColumn === id && (
                    <Icon
                      name="sort"
                      color="black-op50"
                      className={`ml-2 transform ${
                        sortOrder === SORT_ORDER.DESC
                          ? "rotate-0"
                          : "rotate-180"
                      }`}
                    />
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <div className="miq-list-table-body">
          {[NETWORK_STATES.IDLE, NETWORK_STATES.LOADING].includes(
            teamLocationsNetworkState
          ) && (
            <div className="mt-20">
              <Loader
                message="Processing... This may take a few minutes depending on the number of locations."
                timeout={10000}
              />
            </div>
          )}

          {teamLocationsNetworkState === NETWORK_STATES.LOADED &&
            paginatedTeamLocations.map((teamLocation) => (
              <div
                key={`team-location-${teamLocation.id}`}
                className={`miq-list-table-body-row h-[unset] min-h-[70px] py-3 md:py-0 cursor-default ${
                  selectedTeamLocation?.id === teamLocation.id ? "hovered" : ""
                }`}
              >
                <div className="pl-5 laptop:pl-[15px]">
                  <Text paragraph className="truncate">
                    {teamLocation.name}
                  </Text>
                </div>
                <div className="leading-5">
                  <Text>{teamLocation.address}</Text>
                </div>
                <div>
                  <Text className="leading-5">
                    {format(new Date(teamLocation.created_at), "MMM d, yyy")}
                  </Text>
                </div>
                <div className="pr-5 laptop:pr-[15px]">
                  <Dropdown
                    open={selectedTeamLocation?.id === teamLocation.id}
                    onOpen={() => {
                      const dunningStatus = checkAndHandleDunning();

                      if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

                      setSelectedTeamLocation(teamLocation);
                    }}
                    onClose={() => setSelectedTeamLocation(null)}
                    renderTrigger={() => <Button ghost icon="ellipsis-vert" />}
                    triggerClassName="shadow-none bg-transparent p-0"
                    contentClassName="right-0 top-10 p-2"
                  >
                    <ul className="p-0 m-0">
                      <li>
                        <Button
                          className="rounded-8 gap-[10px]"
                          ghost
                          onClick={() => handleRenameTeamLocation(teamLocation)}
                        >
                          <Icon
                            name="edit-2"
                            color="black"
                            className="w-[21px]"
                          />
                          <Text className="text-15">Rename</Text>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="rounded-8 gap-[10px]"
                          ghost
                          onClick={() => handleRemoveTeamLocation(teamLocation)}
                        >
                          <Icon
                            name="trash"
                            color="black"
                            className="w-[21px]"
                          />
                          <Text className="text-15">Remove</Text>
                        </Button>
                      </li>
                    </ul>
                  </Dropdown>
                </div>
              </div>
            ))}
        </div>
      </div>

      {teamLocationsNetworkState === NETWORK_STATES.LOADED && (
        <div className="my-10 px-5 laptop:px-[15px]">
          <Pagination
            pageSize={RECORDS_PER_PAGE}
            currentPage={currentPage}
            total={teamLocations?.length}
            onSelectPage={(pageIndex) => {
              setCurrentPage(pageIndex);
              queryParams.set("page", pageIndex);
              history.replace({ search: queryParams.toString() });
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}

      {/* Make room for the dropdown menu from the last row */}
      <div className="h-28" />
    </div>
  );
}
