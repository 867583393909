import { useQuery } from "@apollo/client";
import React, { useContext } from "react";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as SET_IDENTITY_SUCCESS_MODAL_ID } from "src/components/modals/ConcurIdentitySuccess";
import { ELEMENT_ID as SET_IDENTITY_MODAL_ID } from "src/components/modals/SetConcurIdentity";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import {
  BlogSource,
  TeamsSignupSource,
  trackBlogSelected,
} from "src/services/tracking";
import { prepareTeamsOnboardingUrl } from "src/services/utils";

import {
  GET_INTEGRATION_IDENTITY,
  LIST_INTEGRATIONS,
} from "src/graphql/queries";

export default IntegrationsPage;

function IntegrationsPage() {
  const { team } = useContext(TeamContext);
  const setIdentitySuccessModal = useElement(SET_IDENTITY_SUCCESS_MODAL_ID);
  const setIdentityModal = useElement(SET_IDENTITY_MODAL_ID, {
    props: {
      onClose: () => setIdentityModal.deactivate(),
      onSuccess: () => {
        setIdentityModal.deactivate();
        setIdentitySuccessModal.activate();

        setTimeout(() => {
          refetchIdentity();
          setIdentitySuccessModal.deactivate();
        }, 2000);
      },
    },
  });

  const { data: integrationsData, loading: loadingIntegrations } = useQuery(
    LIST_INTEGRATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );
  const {
    data: identityData,
    loading: identityLoading,
    refetch: refetchIdentity,
  } = useQuery(GET_INTEGRATION_IDENTITY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      integrationType: "concur",
    },
  });

  const isStandalone = !team;
  const isTeamConcurEnabled =
    integrationsData?.integrations?.integrations?.some(
      (i) => i.type === "concur"
    );
  const isIdentitySet = Boolean(
    identityData?.integrationIdentity?.identity?.identity
  );

  if (loadingIntegrations || identityLoading) {
    return <Loader className="p-8" />;
  }

  const ConcurIntegrationText = () => {
    if (isStandalone) {
      return (
        <Text paragraph>
          Concur Integration is a feature for our Teams and Teams Pro plans.
          This feature allows you to submit your MileIQ reports directly to
          Concur. If this is something you'd like, you'll need to create a
          MileIQ Team. If there's a different person in your company who needs
          to create and manage your Team, please share this{" "}
          <a
            className="underline"
            href="https://dashboard.mileiq.com/documents/TeamsOneSheeter.pdf"
            target="_blank"
          >
            overview doc
          </a>{" "}
          with them so they can get the ball rolling.
        </Text>
      );
    } else if (isTeamConcurEnabled) {
      return (
        <Text paragraph>
          Your team provides integration with Concur, which allows you to submit
          your MileIQ reports directly to Concur. Check out{" "}
          <a
            className="underline font-medium"
            href="https://support.mileiq.com/hc/en-us/articles/11268740428180"
            target="_blank"
            onClick={() => {
              trackBlogSelected({
                src: BlogSource.ENABLE_CONCUR_INTEGRATION,
              });
            }}
          >
            this article
          </a>{" "}
          for how to submit your drives to your Concur expense reports.
        </Text>
      );
    } else {
      return (
        <Text paragraph>
          Your Team is not currently integrated with Concur, which allows you to
          submit your MileIQ drives directly to your expense reports. Reach out
          to your admin at{" "}
          <a
            className="underline font-medium"
            href={`mailto:${team?.owner?.email}?subject=`}
          >
            {team?.owner?.email}
          </a>{" "}
          to get Concur integration for your Team.
        </Text>
      );
    }
  };

  function handleCreateTeam() {
    const url = prepareTeamsOnboardingUrl(
      TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      TEAM_SUBSCRIPTION_TYPES.MONTH,
      TeamsSignupSource.INTEGRATIONS_SETTINGS
    );
    window.location.href = url;
  }

  return (
    <div className="p-[20px] laptop:p-[15px]">
      <h6>Integrations</h6>
      <div className="flex items-center gap-2 mt-6">
        <h6>Concur</h6>
        {isIdentitySet && (
          <Text bold className="miq-badge miq-badge-lime">
            Activated
          </Text>
        )}
      </div>
      <div className="mt-3 w-3/4">
        <ConcurIntegrationText />
      </div>
      {!isIdentitySet && (isTeamConcurEnabled || isStandalone) && (
        <div className="mt-5">
          {isStandalone ? (
            <Button onClick={handleCreateTeam}>Create a team</Button>
          ) : (
            <Button onClick={() => setIdentityModal.activate()}>
              Activate my Concur Integration
            </Button>
          )}
        </div>
      )}
      <Text paragraph className="mt-5">
        Have an integration idea?{" "}
        <a
          className="underline"
          href="mailto:support@mileiq.com?subject=Teams integration suggestion"
        >
          Tell us about it!
        </a>
      </Text>
    </div>
  );
}
