import "src/models/typings";

import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useContext } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Flash, { FlashTypes } from "src/components/elements/Flash";

import SingleDriveDetails from "src/components/blocks/drives/details/single-drive-details/SingleDriveDetails";

import { getRefetchParams } from "src/services/apollo-utils";

import { EDIT_DRIVES_MUTATION } from "src/graphql/mutations";

import MultipleDrivesDetails from "./multiple-drives-details/MultipleDrivesDetails";

export default DriveDetailsSidebar;

function checkScrollable() {
  const cls = "has-scrollbar";
  let hasScrollbar = false;
  const el = document.querySelector(".drive-details-sidebar");

  if (!el) return;

  if (el.scrollHeight == el.offsetHeight) {
    // at this point we actually might have an empty scrollbar
    // so need to check the width
    if (el.scrollWidth < el.offsetWidth) {
      hasScrollbar = true;
    }
  } else if (el.scrollHeight > el.offsetHeight) {
    hasScrollbar = true;
  }

  if (hasScrollbar) {
    el.classList.add(cls);
  } else {
    el.classList.remove(cls);
  }
}

/**
 *
 * @param {{drives: Drive[]}} props
 * @returns
 */
function DriveDetailsSidebar({ drives, onClose }) {
  const [msg, setMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [editDrivesMutation, { loading, error }] = useMutation(
    EDIT_DRIVES_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getDrivesTotals", "getDrivesSummary"],
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    }
  );

  const { userData } = useContext(UserDataContext);

  const onEditDrives = async (editDrivesInput, notifySuccess = true) => {
    setMsg("Saving...");
    setVisible(true);
    let res = null;
    try {
      const { isRoundTripStop, ...editDrivePayload } = editDrivesInput;
      res = await editDrivesMutation({
        variables: {
          editDrivesInput: isRoundTripStop
            ? editDrivePayload.payload
            : editDrivesInput,
        },
        fetchPolicy: isRoundTripStop ? "no-cache" : "network-only",
      });

      if (notifySuccess) {
        setMsg("Changes saved!");
        setTimeout(() => {
          setVisible(false);
        }, 1500);
      } else {
        setVisible(false);
      }
    } catch (e) {
      console.log(e);
      setMsg("An error has ocurred!");
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
    return res;
  };

  useEffect(() => {
    checkScrollable();

    window.addEventListener("resize", checkScrollable);
    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, []);

  return (
    <>
      <div
        className={`drive-details-sidebar bg-white pb-[90px] ${
          loading ? "opacity-50" : ""
        }`}
      >
        {drives.length === 1 ? (
          <SingleDriveDetails
            unit={userData.distanceUnit}
            currency={userData.currency}
            drive={drives[0]}
            onClose={onClose}
            onEditDrive={onEditDrives}
          />
        ) : (
          <MultipleDrivesDetails
            unit={userData.distanceUnit}
            currency={userData.currency}
            drives={drives}
            onClose={onClose}
            onEditDrives={onEditDrives}
          />
        )}
      </div>
      <Flash
        className="miq-flash-single-drive"
        visible={visible}
        msg={msg}
        type={
          loading
            ? FlashTypes.SAVING
            : error
            ? FlashTypes.ERROR
            : FlashTypes.SAVED
        }
      />
    </>
  );
}
