import React, { useEffect, useState } from "react";

import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import ImgChatBubble from "public/assets/img/chat-bubble.svg";

export default ZendeskWidget;

const ZENDESK_WIDGET_SCRIPT_ID = "zendesk-widget-script";

function ZendeskWidget() {
  const { miqDashHelpChatWeb: isEnabled } = useFlags();
  const setOpen = useState(false)[1];

  const handleClick = () => {
    setOpen((currentState) => {
      if (typeof window.zE !== "function") return;
      window.zE("messenger", currentState ? "close" : "open");
      return !currentState;
    });
  };

  useEffect(() => {
    loadZendeskScript();
    return removeZendeskScript;
  }, []);

  if (!isEnabled) return null;

  return (
    <div className="fixed right-6 bottom-6" style={{ zIndex: "88888" }}>
      <button
        onClick={handleClick}
        className="flex items-center rounded-55 bg-black/50 hover:bg-black/70 px-5 text-white"
        style={{ height: "45px" }}
      >
        <ImgChatBubble />
        <Text bold color="white" className="ml-2">
          Help
        </Text>
      </button>
    </div>
  );
}

function loadZendeskScript() {
  if (document.getElementById(ZENDESK_WIDGET_SCRIPT_ID)) return;

  const s = document.createElement("script");
  s.id = ZENDESK_WIDGET_SCRIPT_ID;
  s.innerText = `
    window.zEmbed||function(f,g){var e,b,c,d,h=[],a=document.createElement("iframe");window.zEmbed=function(){h.push(arguments)},window.zE=window.zE||window.zEmbed,a.src="javascript:false",a.title="",a.role="presentation",(a.frameElement||a).style.cssText="display: none",(c=(c=document.getElementsByTagName("script"))[c.length-1]).parentNode.insertBefore(a,c),d=a.contentWindow.document;try{b=d}catch(i){e=document.domain,a.src='javascript:var d=document.open();d.domain="'+e+'";void(0);',b=d}b.open()._l=function(){var a=this.createElement("script");e&&(this.domain=e),a.id="js-iframe-async",a.src=f,this.t=+new Date,this.zendeskHost=g,this.zEQueue=h,this.body.appendChild(a)},b.write('<body onload="document._l();">'),b.close()}("https://assets.zendesk.com/embeddable_framework/main.js","mileiq.zendesk.com");
    zE(function(){
      if ("hide" in zE) zE.hide();
      else if (typeof zE === "function") zE("messenger", "close");
    });
    `;
  document.body.appendChild(s);

  // hide zendesk widget button
  const style = document.createElement("style");
  style.id = `${ZENDESK_WIDGET_SCRIPT_ID}-style`;
  style.innerText = `iframe[title="Button to launch messaging window"] { display: none !important; }`;
  document.body.appendChild(style);
}

function removeZendeskScript() {
  document.getElementById(ZENDESK_WIDGET_SCRIPT_ID)?.remove();
  document.getElementById(`${ZENDESK_WIDGET_SCRIPT_ID}-style`)?.remove();
}
