import React, { useState } from "react";

import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { updateUser } from "src/services/teams";
import {
  teamsUserUpdateSources,
  trackTeamsUpdateUserCompleted,
} from "src/services/tracking";

export default DowngradeUserConfirm;

function DowngradeUserConfirm({ user, team, show, onClose, onConfirm }) {
  const { user: me } = useUser();
  const [loading, setLoading] = useState(false);

  const handleDowngrade = async () => {
    if (loading) return;

    setLoading(true);

    try {
      await updateUser(team.id, user);
      trackTeamsUpdateUserCompleted({
        email: user.email,
        src: user.isAdmin
          ? teamsUserUpdateSources.DOWNGRADE_TO_ADMIN
          : teamsUserUpdateSources.DOWNGRADE_TO_DRIVER,
      });
      onConfirm();
    } catch (err) {
      console.log(err);
      alert("Failed to remove");
    }

    setLoading(false);
  };

  if (!team || !user) return null;
  const isMe = user.id === me.id;

  let title = "";
  let desc = null;
  switch (true) {
    // Downgrade to Admin
    case user.isAdmin:
      title = isMe
        ? "Are you sure you want to downgrade yourself to Admin"
        : "Are you sure you want to downgrade this user to Admin?";
      desc = isMe ? (
        <Text paragraph lg className="mt-3">
          You will remain an admin, but this will remove your MileIQ Unlimited
          subscription.
        </Text>
      ) : (
        <Text paragraph lg className="mt-3">
          This will downgrade <Text bold>{user.email}</Text> to Admin and they
          will lose access to their MileIQ Unlimited subscription.
        </Text>
      );
      break;
    // Downgrade to Driver
    case user.isDriver:
      title = "Are you sure you want to downgrade this user to Driver?";
      desc = (
        <Text paragraph lg className="mt-3">
          This will downgrade <Text bold>{user.email}</Text> to Driver and they
          will lose access to your team's Admin dashboard.
        </Text>
      );
      break;
  }

  return (
    <Modal
      show={show}
      closable={false}
      onClose={onClose}
      loading={loading}
      className="w-[400px] downgrade-admin-confirm-modal"
    >
      <>
        <h5>{title}</h5>
        <div className="mt-3">{desc}</div>

        <div className="flex justify-end mt-5">
          <Button secondary className="text-13" onClick={onClose}>
            Cancel
          </Button>
          <Button
            destructive
            className="ml-3 text-13"
            onClick={handleDowngrade}
          >
            Downgrade
          </Button>
        </div>
      </>
    </Modal>
  );
}
