import React from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

export default MiqTabButton;

function MiqTabButton({
  className,
  active,
  label,
  count,
  important,
  icon = null,
  onClick,
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`miq-tab-btn ${
        active ? "miq-tab-btn-active" : "miq-tab-btn-default"
      } ${className || ""}`}
    >
      {icon && (
        <span className="mr-2">
          <Icon name={icon} color={active ? "blue" : "black"} />
        </span>
      )}
      <Text bold className="miq-tab-btn-label">
        {label}
      </Text>
      {count ? (
        <span
          className={`miq-tab-btn-count ${
            important ? "miq-tab-btn-count-important" : ""
          }`}
        >
          {count}
        </span>
      ) : null}
    </button>
  );
}
