import Kt from "./trigger-condition.js";
export default class Yt {
  constructor(t) {
    this.fu = t;
  }
  Hl(t) {
    return null == this.fu || Kt.du(t[0], this.fu);
  }
  static fromJson(t) {
    return new Yt(t ? t.campaign_id : null);
  }
  ss() {
    return this.fu;
  }
}
