import Base from "src/models/base";

export default class Comment extends Base {
  id = null;
  driveId = null;
  userEmail = null;
  userFirstName = null;
  userLastName = null;
  commentText = null;
  createdAt = null;

  constructor(data) {
    super("comment_model");
    if (data) {
      this.id = data.id || null;
      this.driveId = data.reported_drive_id || null;
      this.email = data.user_email || null;
      this.firstName = data.user_first_name || null;
      this.lastName = data.user_last_name || null;
      this.text = data.text || null;
      this.createdAt = data.created_at || null;
    }
  }
}
