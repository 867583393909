import { useContext, useEffect } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import { ROUTE_TELEMETRY_MAP } from "src/hooks/usePageTracking";

import { TEAMS_SUBSCRIPTION_PLANS_DATA } from "src/models/team-subscription";

import {
  ModalAbandonMethods,
  TeamsUpdatePaymentSources,
  trackTeamsResubscribeModalAbandoned,
  trackTeamsResubscribeModalStarted,
} from "src/services/tracking";
import { timeout } from "src/services/utils";

import TeamContext from "../context/TeamContext";
import { useTeamDunning } from "../context/TeamDunningContext";
import { DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID } from "./DunningSubscriptionRecoverPayment";
import { SinglePlan } from "./UpgradeSubscription";

export const DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID =
  "DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID";

registerElement(DUNNING_RESUBSCRIBE_SUBSCRIPTION_MODAL_ID, DunningResubscribe);

function DunningResubscribe({ onClose, source }) {
  const { team } = useContext(TeamContext);
  const { resetDunningState } = useTeamDunning();

  const subscriptionRecoverPayment = useElement(
    DUNNING_SUBSCRIPTION_RECOVER_PAYMENT_MODAL_ID,
    {
      props: {
        onClose: () => {
          subscriptionRecoverPayment.deactivate();
          resetDunningState();
        },
        source: TeamsUpdatePaymentSources.RESUBSCRIBE_MODAL,
      },
    }
  );

  const trackingParams = {
    orgId: team?.orgId,
    orgGroupId: team?.orgGroupId,
    subPlan: team?.subscription?.plan,
    subType: team?.subscription?.type,
    teamsDriverCount: team?.subscription?.numberOfSeats,
    webPage: ROUTE_TELEMETRY_MAP[window.location.pathname],
    teamsResubscribeModalSource: source,
  };

  useEffect(() => {
    trackTeamsResubscribeModalStarted(trackingParams);
  }, []);

  const handleUpdatePayment = async () => {
    onClose();
    await timeout(0);
    subscriptionRecoverPayment.activate();
  };

  const handleClose = (modalAbandonMethod) => {
    trackTeamsResubscribeModalAbandoned({
      ...trackingParams,
      modalAbandonMethod,
    });

    onClose();
  };

  const teamPlanLabel = team?.subscription?.plan
    ? TEAMS_SUBSCRIPTION_PLANS_DATA.labels[team.subscription.plan]
    : "";

  return (
    <Modal
      closable
      className="w-[490px]"
      onClose={() => handleClose(ModalAbandonMethods.MODAL_DISMISS)}
      passiveBackdrop
    >
      <div className="mt-8">
        <div className="text-center">
          <h3>Resubscribe to {teamPlanLabel}</h3>
          <p className="mt-2.5">
            Resubscribe to your original plan to access features.
          </p>
        </div>
        <div className="mt-[30px]">
          <SinglePlan
            showBadge={false}
            plan={team?.subscription?.plan}
            subType={team?.subscription?.type}
          />
        </div>
        <Button
          onClick={handleUpdatePayment}
          className="font-medium mt-5 w-full h-[55px]"
          type="submit"
        >
          Proceed to payment
        </Button>
      </div>
    </Modal>
  );
}
