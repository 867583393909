import React from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { report } from "src/services/error-reporting";

export default class RatesErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error) {
    report(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="rounded p-[20px] bg-red/20">
          <div className="flex items-start gap-[15px]">
            <Icon name="alert-octagon" color="red" />
            <Text>Unable to load rates data.</Text>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
