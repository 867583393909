import { useEffect, useRef, useState } from "react";

export { TopBanner, BannerStrip };

function TopBanner({ children }) {
  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef(null);

  useEffect(() => {
    if (bannerRef.current) {
      const updateHeight = () => {
        const height = `${bannerRef.current?.offsetHeight}px`;

        setBannerHeight(height);

        document.documentElement.style.setProperty(
          "--top-banner-height",
          height
        );
      };

      updateHeight();

      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(bannerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <div className="relative" style={{ height: bannerHeight }}>
      <div ref={bannerRef} className="w-full z-[1000] fixed top-0 left-0">
        {children}
      </div>
    </div>
  );
}

function BannerStrip({ backgroundVariant = "warning", children }) {
  const backgroundClass =
    {
      warning: "bg-[#FFF2D6]",
      danger: "bg-[#FFE9E6]",
    }[backgroundVariant] || "bg-white";

  return (
    <div
      className={`flex gap-2 items-center px-6 py-[15px] border-b border-b-border-1 ${backgroundClass}`}
    >
      {children}
    </div>
  );
}
