import React, { forwardRef, useCallback, useEffect, useState } from "react";
import GReCAPTCHA from "react-google-recaptcha";

import { useFlags } from "src/hooks/useFlags";

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

export const captchaErrors = {
  RECAPTCHA_TOKEN_REQUIRED: "reCAPTCHA token is required",
  RECAPTCHA_TOKEN_INVALID: "reCAPTCHA token is invalid",
  RECAPTCHA_TOKEN_EXPIRED: "reCAPTCHA token has expired",
  RECAPTCHA_TOKEN_ERROR: "reCAPTCHA token error",
};

export const Captcha = forwardRef(
  ({ invisible = true, onTokenChange, onLoad }, ref) => {
    const { miqDashRecaptchaEnabledWeb: isCaptchaLive } = useFlags();
    if (!isCaptchaLive) {
      onLoad();
      return null;
    }
    return (
      <ReCaptcha
        ref={ref}
        invisible={invisible}
        onChange={onTokenChange}
        onLoad={onLoad}
      />
    );
  }
);

const ReCaptcha = forwardRef(({ invisible, onChange, onLoad }, ref) => {
  const [captchaToken, setCaptchaToken] = useState("");

  const resetCaptcha = useCallback(() => {
    setCaptchaToken("");
    ref.current?.reset();
  }, [ref]);

  const handleCaptcha = useCallback((token) => {
    setCaptchaToken(token || "");
  }, []);

  useEffect(() => {
    const refreshCaptcha = () => {
      resetCaptcha();
    };

    let tokenRefreshTimeout = null;

    if (captchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds token expires in 2min
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
      }
    };
  }, [captchaToken]);

  useEffect(() => {
    onChange?.(captchaToken);
  }, [captchaToken, onChange]);

  return (
    <GReCAPTCHA
      ref={ref}
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleCaptcha}
      asyncScriptOnLoad={onLoad}
      size={invisible ? "invisible" : "normal"}
      style={{ display: "inline-block" }}
    />
  );
});
