import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ForgotPasswordEmailStep } from "src/components/blocks/auth/forgot/ForgotPasswordEmailStep";
import { ResetPasswordStep } from "src/components/blocks/auth/forgot/ResetPasswordStep";

import useQueryParams from "src/hooks/useQueryParams";

import { removeAccessToken, removeRefreshToken } from "src/services/storage";
import { AUTH_SCREENS, isValidEmail } from "src/services/utils";

import Logo from "public/assets/img/miq-logo-black-orange.svg";

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const { state: locationState } = useLocation();

  const screen = locationState?.screen || AUTH_SCREENS.EMAIL;

  useEffect(() => {
    removeRefreshToken();
    removeAccessToken(false);
  }, []);

  useEffect(() => {
    const prefix = "/forgot-password";
    const username = searchParams?.get?.("username");
    const token = searchParams?.get?.("token");
    if (!isValidEmail(username)) {
      searchParams?.delete?.("username");
      searchParams?.delete?.("token");
      const params = searchParams?.toString();
      history.replace(`${prefix}${params ? "?" + params : ""}`, {
        screen: AUTH_SCREENS.EMAIL,
      });
    } else {
      history.replace(`${prefix}?${searchParams?.toString()}`, {
        screen: AUTH_SCREENS.EMAIL,
      });

      history.push(`${prefix}?${searchParams?.toString()}`, {
        screen: AUTH_SCREENS[token ? "PWD" : "EMAIL"],
      });
    }
  }, []);

  return (
    <div className="relative w-full">
      <a
        className="absolute left-[35px] top-[30px] z-10"
        href="https://mileiq.com"
        target="_blank"
        rel="noopener"
      >
        <Logo />
      </a>
      <div className="relative w-full h-full table table-fixed">
        <div className="w-full table-cell align-middle">
          <div className="page-forgot-password__form">
            {screen === AUTH_SCREENS.EMAIL && <ForgotPasswordEmailStep />}
            {screen === AUTH_SCREENS.PWD && <ResetPasswordStep />}
          </div>
        </div>
      </div>
    </div>
  );
};
