import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamDrives } from "src/components/context/TeamDrivesContext";

import MiqButton from "src/components/elements/Button";
import Dropdown, { MiqDropdownItem } from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";

import useQueryParams from "src/hooks/useQueryParams";

import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
} from "src/services/teams";
import { trackTeamsDriveReviewAllSelected } from "src/services/tracking";

export default TeamDrivesToolbar;

const flashPortal = document.querySelector("#portal-flash");

function TeamDrivesToolbar() {
  const searchParams = useQueryParams();
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { checkedDrives, reviewDrives, areAllDrivesChecked, teamDrives } =
    useTeamDrives();

  const [shouldActOnAllPages, setActOnAllPages] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setActOnAllPages(false);
  }, [checkedDrives.join(",")]);

  const isDrivesToApprovePage = history.location.pathname.includes(
    "/teams/drives-to-approve"
  );
  const isAllDrivesPage = history.location.pathname.includes("/teams/drives/");

  const handleSelectAllDrivesAcrossPages = () => {
    setActOnAllPages(true);
    trackTeamsDriveReviewAllSelected({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driveCount: teamDrives?.total_results,
    });
  };

  const handleReviewDrives = async ({ action, isChangingStatus }) => {
    await reviewDrives({
      driveIds: shouldActOnAllPages ? undefined : checkedDrives,
      action,
      additionalFilters: isDrivesToApprovePage
        ? { review_status: DRIVE_REVIEW_STATUS.PENDING }
        : {},
      shouldActOnAllPages,
      shouldFetchMore: isDrivesToApprovePage,
      shouldUpdateCurrentPage: isAllDrivesPage,
      isChangingStatus,
    });

    setActOnAllPages(false);
    setDropdownOpen(false);
  };

  const numberOfCheckedDrives = checkedDrives?.length || 0;

  const isVisible = numberOfCheckedDrives > 0;
  const totalResults = teamDrives?.total_results;

  const numberOfDrives = shouldActOnAllPages
    ? totalResults
    : numberOfCheckedDrives;

  const shouldRenderSelectAllAcrossPages =
    !shouldActOnAllPages && areAllDrivesChecked && teamDrives?.pages > 1;

  return createPortal(
    <div
      className={`miq-flash-msg miq-flash-msg-black toolbar ${
        isVisible ? "on-screen" : "off-screen"
      }`}
    >
      <div className="miq-flash-msg-content pl-5 pr-2 py-[7px] text-white flex items-center">
        <div className="min-w-[485px] flex items-center justify-between gap-[60px]">
          <div className="flex items-center gap-2.5">
            <p>
              {numberOfDrives || "No"} {numberOfDrives > 1 ? "drives" : "drive"}{" "}
              selected{shouldRenderSelectAllAcrossPages ? " on this page" : ""}.
            </p>
            {shouldRenderSelectAllAcrossPages && (
              <button
                onClick={handleSelectAllDrivesAcrossPages}
                className="underline text-white/80 hover:text-white"
              >
                Select all {totalResults} across pages
              </button>
            )}
          </div>
          <div className="flex items-center gap-1">
            {isAllDrivesPage ? (
              <Dropdown
                open={isDropdownOpen}
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                triggerClassName="p-0 bg-transparent hover:bg-white/10 px-5 py-2 shadow-none"
                contentClassName="bg-[#383838] -top-[148px] -left-[5px]"
                renderTrigger={() => (
                  <div className="flex items-center gap-2">
                    <p className="font-medium">Change status</p>
                    <Icon color="white" name="chevron-down-2" />
                  </div>
                )}
              >
                <MiqDropdownItem
                  className="hover:bg-white/10 active:bg-white/20 pr-5"
                  color="white"
                  icon="checked-circle-black"
                  onClick={() =>
                    handleReviewDrives({
                      action: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
                      isChangingStatus: true,
                    })
                  }
                >
                  Change to Approved
                </MiqDropdownItem>
                <MiqDropdownItem
                  className="hover:bg-white/10 active:bg-white/20 pr-5"
                  color="white"
                  icon="ban"
                  onClick={() =>
                    handleReviewDrives({
                      action: DRIVE_REVIEW_STATUS_ACTION.REJECT,
                      isChangingStatus: true,
                    })
                  }
                >
                  Change to Rejected
                </MiqDropdownItem>
                <MiqDropdownItem
                  className="hover:bg-white/10 active:bg-white/20 pr-5"
                  color="white"
                  icon="file-times-alt"
                  onClick={() =>
                    handleReviewDrives({
                      action: DRIVE_REVIEW_STATUS_ACTION.RESET,
                      isChangingStatus: true,
                    })
                  }
                >
                  Remove status
                </MiqDropdownItem>
              </Dropdown>
            ) : (
              <>
                <MiqButton
                  onClick={() =>
                    handleReviewDrives({
                      action: DRIVE_REVIEW_STATUS_ACTION.ACCEPT,
                      isChangingStatus: false,
                    })
                  }
                  ghost
                  icon="checked-circle-black"
                  color="white"
                  className="hover:bg-white/10"
                >
                  Approve
                </MiqButton>
                <MiqButton
                  onClick={() =>
                    handleReviewDrives({
                      action: DRIVE_REVIEW_STATUS_ACTION.REJECT,
                      isChangingStatus: false,
                    })
                  }
                  ghost
                  icon="ban"
                  color="white"
                  className="hover:bg-white/10"
                >
                  Reject
                </MiqButton>
              </>
            )}
            <MiqButton
              onClick={() => {
                searchParams.delete("checkedDrives");
                history.replace({ search: searchParams.toString() });
              }}
              ghost
              icon="close"
              color="white"
              className="hover:bg-white/10"
            />
          </div>
        </div>
        <div className="miq-flash-msg-content-bg" />
      </div>
    </div>,
    flashPortal
  );
}
