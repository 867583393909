import format from "date-fns/format";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Loader from "src/components/elements/Loader";
import Pagination from "src/components/elements/Pagination";
import SearchInput from "src/components/elements/SearchInput";
import TabButton from "src/components/elements/TabButton";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { LOCATION_SUGGESTION_LIST_ID } from "src/components/blocks/LocationSuggestionList";

import useQueryParams from "src/hooks/useQueryParams";

import { getReportDrives } from "src/services/reports";
import {
  reportDetailsDriveFilters,
  reportDetailsSearchFilters,
  trackDriverReportDetailsFiltered,
  trackDriverReportDriveSelected,
} from "src/services/tracking";
import { formatCurrency } from "src/services/utils";

import ImgEmptyStateFiltered from "public/assets/img/empty-car.svg";

export default ReportDrivesList;

const FILTERS = {
  ALL: "ALL",
  MANUAL: "MANUAL",
};

const Tabs = [
  {
    id: FILTERS.ALL,
    label: "All drives",
    icon: "car",
    filter: (report) => report.totalDrives,
  },
  {
    id: FILTERS.MANUAL,
    label: "Manually added",
    icon: "m-in-circle",
    filter: (report) => report.totalManualDrives,
  },
];

const RECORDS_PER_PAGE = 20;

const cols = [
  {
    title: "Start location",
    extraClass: "pl-8",
  },
  {
    title: "End location",
    extraClass: "desktop-version",
  },
  {
    title: "Date",
    extraClass: "desktop-version",
    sortable: true,
  },
  {
    title: "Distance",
    extraClass: "desktop-version",
  },
  {
    title: "Man. Added",
    extraClass: "desktop-version",
  },
  {
    title: "Value",
    extraClass: "pr-8 text-green text-right",
    headerExtraClass: "justify-end",
  },
];

const PAGE_STATES = {
  REPORT_DRIVES_LOADING: "REPORT_DRIVES_LOADING",
  REPORT_DRIVES_ERROR: "REPORT_DRIVES_ERROR",
  REPORT_DRIVES_EMPTY: "REPORT_DRIVES_EMPTY",
  REPORT_DRIVES_LOADED: "REPORT_DRIVES_LOADED",
};

const EmptyVal = ({ className }) => (
  <Text bold className={`text-black/50 ${className || ""}`}>
    -
  </Text>
);

function ReportDrivesList() {
  const query = useQueryParams();
  const history = useHistory();
  const { report_id: reportId } = useParams();
  const {
    selectedDrive,
    setSelectedDrive,
    detailedReport,
    setPastSelectedDrive,
  } = useTeamReports();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);

  const [drives, setDrives] = useState([]);
  const [pageState, setPageState] = useState(PAGE_STATES.REPORT_DRIVES_LOADING);
  const [totalDrives, setTotalDrives] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    parseInt(query.get("page")) || 1
  );
  const [currentFilter, setCurrentFilter] = useState(
    query.get("tab") || (query.get("search") ? null : FILTERS.ALL)
  );
  const [searchValue, setSearchValue] = useState(
    query.get("tab") ? "" : query.get("search") || ""
  );
  const [possibleLocations, setPossibleLocations] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const suggestionsRef = React.useRef(null);

  const loadReportDrives = async ({
    page,
    filter,
    search,
    shouldTrack = true,
  }) => {
    selectedDrive && setPastSelectedDrive(selectedDrive);
    setSelectedDrive(null);
    setPageState(PAGE_STATES.REPORT_DRIVES_LOADING);

    try {
      setCurrentPage(page);
      setCurrentFilter(filter);
      setSearchValue(search);
      query.set("page", page);
      query.set("tab", filter);
      query.set("search", search);

      if (!search) {
        query.delete("search");
      }

      if (!filter) {
        query.delete("tab");
      }

      if (shouldTrack && (search || filter)) {
        const avoFilter =
          filter === FILTERS.ALL
            ? reportDetailsDriveFilters.ALL_DRIVES
            : reportDetailsDriveFilters.MANUALLY_ADDED;

        const avoSearchFilter = search
          ? reportDetailsSearchFilters.APPLIED
          : reportDetailsSearchFilters.NOT_APPLIED;

        trackDriverReportDetailsFiltered({
          orgGroupId: team.orgGroupId,
          orgId: team.orgId,
          subscriptionId: userData.subscriptionType,
          filter: filter && avoFilter,
          searchApplied: avoSearchFilter,
        });
      }

      history.replace(
        `/teams/reports/${reportId}?${query.toString()}`,
        history.location.state
      );

      const drives = await getReportDrives(
        team.id,
        reportId,
        page,
        filter === FILTERS.MANUAL ? 1 : 0,
        search
      );

      setDrives(drives?.data);
      setTotalDrives(drives?.total_results);

      setPageState(
        drives?.data?.length === 0
          ? PAGE_STATES.REPORT_DRIVES_EMPTY
          : PAGE_STATES.REPORT_DRIVES_LOADED
      );

      // if it's the first time fetching drives, set the possible locations and search suggestions
      if (possibleLocations.length === 0) {
        setPossibleLocations(drives?.search_locations);

        setSearchSuggestions(
          drives?.search_locations?.map((location) => ({
            name: location,
            length: 0,
            offset: 0,
          }))
        );
      }
    } catch (err) {
      setPageState(PAGE_STATES.REPORT_DRIVES_ERROR);
      console.error("failed to load report drives", err);
    }
  };

  useEffect(() => {
    if (reportId) {
      loadReportDrives({
        page: currentPage,
        filter: currentFilter,
        search: searchValue,
        shouldTrack: false,
      });
    }
  }, [reportId]);

  const locationSuggestionList = useElement(LOCATION_SUGGESTION_LIST_ID, {
    props: {
      trigger: suggestionsRef.current,
      onClose: () => locationSuggestionList.deactivate(),
      onSelect: (suggestion) => {
        onSearchUpdate(suggestion);
        locationSuggestionList.deactivate();

        loadReportDrives({
          page: 1,
          filter: null,
          search: suggestion,
        });
      },
    },
  });

  const _changeFilter = (tabId) => {
    onSearchUpdate("");
    loadReportDrives({
      page: 1,
      filter: tabId,
      search: "",
    });
  };

  const onSearchUpdate = (search) => {
    setSearchValue(search);

    if (search) {
      const suggestions = possibleLocations.reduce((acc, possibleLocation) => {
        const _possibleLocation = possibleLocation.toLowerCase();
        const _val = search.toLowerCase();

        if (_possibleLocation.includes(_val)) {
          return [
            ...acc,
            {
              name: possibleLocation,
              length: search.length,
              offset: _possibleLocation.indexOf(_val),
            },
          ];
        }

        return acc;
      }, []);

      setSearchSuggestions(suggestions);

      if (locationSuggestionList.open) {
        locationSuggestionList.updateProps({ suggestions });
      } else {
        locationSuggestionList.activate({
          props: {
            suggestions,
          },
        });
      }
    } else {
      if (locationSuggestionList.open) {
        locationSuggestionList.deactivate();
      }

      setSearchSuggestions(
        possibleLocations.map((location) => ({
          name: location,
          length: 0,
          offset: 0,
        }))
      );
    }
  };

  const onClearSearch = () => {
    onSearchUpdate("");

    loadReportDrives({
      page: 1,
      filter: FILTERS.ALL,
      search: "",
    });
  };

  const onFocusSearch = () => {
    if (!locationSuggestionList.open) {
      locationSuggestionList.activate({
        props: {
          suggestions: searchSuggestions,
        },
      });
    }
  };

  const pagination = {
    pageSize: RECORDS_PER_PAGE,
    currentPage,
    total: totalDrives,
    onSelectPage: (pageIndex) => {
      window.scrollTo({ top: 0, behavior: "smooth" });

      loadReportDrives({
        page: pageIndex,
        filter: currentFilter,
        search: searchValue,
      });
    },
  };

  const getEmptyStateText = () => {
    if (currentFilter === FILTERS.MANUAL) {
      return "No manually added drives found";
    }

    if (searchValue) {
      return "No drives found for this location";
    }

    return "No drives found";
  };

  return (
    <div className="report-drives-list">
      <div className="flex m-5 items-center justify-between">
        <div className="report-drives-list-tabs flex items-center flex-shrink-0">
          {Tabs.map((t) => (
            <TabButton
              key={t.id}
              className="mr-2"
              label={t.label}
              icon={t.icon}
              active={t.id === currentFilter}
              count={t.filter(detailedReport)}
              onClick={() => {
                _changeFilter(t.id);
              }}
            />
          ))}
        </div>
        <div ref={suggestionsRef}>
          <SearchInput
            className="min-w-[333px]"
            onChange={onSearchUpdate}
            value={searchValue}
            placeholder="Search for location"
            autoFocus={false}
            onClear={onClearSearch}
            onFocus={onFocusSearch}
          />
        </div>
      </div>

      {pageState === PAGE_STATES.REPORT_DRIVES_EMPTY && (
        <div className="flex flex-col items-center justify-center">
          <ImgEmptyStateFiltered className="ml-[-40px]" />
          <Text
            lg
            color="black/70"
            className="mt-6 text-center"
            style={{ maxWidth: "270px" }}
          >
            {getEmptyStateText()}
          </Text>
        </div>
      )}

      {pageState === PAGE_STATES.REPORT_DRIVES_LOADING && (
        <div className="h-[300px]">
          <Loader
            message="Processing... This may take a few minutes depending on the number of drives."
            timeout={10000}
          />
        </div>
      )}

      {pageState === PAGE_STATES.REPORT_DRIVES_LOADED && (
        <>
          <div className="miq-list-table report-drives-list-table">
            <div className="miq-list-table-header">
              {cols.map((col) => {
                return (
                  <div
                    key={col.title}
                    className={`p-2 ${col.extraClass || ""}`}
                  >
                    <Text className="text-black/70">{col.title}</Text>
                    {col.sortable ? (
                      <IconButton
                        name="sort"
                        color="black/50"
                        className="ml-2"
                        onClick={() => console.log("sort by")}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="miq-list-table-body">
              {drives.map((d) => {
                const currentYear = new Date().getFullYear();
                const startDate = new Date(d.started_at);
                const endDate = new Date(d.ended_at);
                const endedInCurrentYear =
                  currentYear === endDate.getFullYear();
                const endedAt = format(
                  endDate,
                  endedInCurrentYear ? "MMM d" : "MMM d, yyyy"
                );
                const startLocation = d.start_loc_name;
                const startTime = format(startDate, "hh:mm a");
                const endLocation = d.end_loc_name;
                const endTime = format(endDate, "hh:mm a");
                const {
                  drive_parse_id: id,
                  is_manually_added: isManual,
                  distance,
                  total_value: value,
                } = d;
                const selected = selectedDrive?.drive_parse_id === id;

                return (
                  <div
                    key={id}
                    className={`miq-list-table-body-row ${
                      selected ? "selected" : ""
                    }`}
                    onClick={() => {
                      const newSelectedDrive = selected ? null : d;
                      newSelectedDrive &&
                        trackDriverReportDriveSelected({
                          orgGroupId: team.orgGroupId,
                          orgId: team.orgId,
                          subscriptionId: userData.subscriptionType,
                        });

                      selectedDrive && setPastSelectedDrive(selectedDrive);
                      setSelectedDrive(newSelectedDrive);
                    }}
                  >
                    {/* Start location */}
                    <Location
                      address={startLocation}
                      time={startTime}
                      isStartLocation
                    />

                    {/* End location */}
                    <Location address={endLocation} time={endTime} />

                    {/* Date */}
                    <div className="p-2 desktop-version">
                      <Text className="text-black">{endedAt}</Text>
                    </div>

                    {/* Distance */}
                    <div className="p-2 desktop-version">
                      {distance ? (
                        <Text className="text-black">
                          {distance.toFixed?.(1)}{" "}
                          {detailedReport.distanceUnits || "mi"}
                        </Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>

                    {/* Manually Added */}
                    <div className="p-2 desktop-version">
                      {isManual && isManual !== " No" ? (
                        <span className="flex">
                          <Icon name="m-in-circle" />
                        </span>
                      ) : (
                        <EmptyVal className="pl-1.5" />
                      )}
                    </div>

                    {/* Value */}
                    <div className="p-2 pr-8 text-right">
                      {value ? (
                        <Text bold color="green">
                          {formatCurrency({
                            value,
                            currency: userData.currency,
                          })}
                        </Text>
                      ) : (
                        <EmptyVal />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-10 pl-8 pr-5">
            <Pagination {...pagination} />
          </div>
        </>
      )}
    </div>
  );
}

function Location({ address, time, isStartLocation }) {
  const containerRef = useRef();

  const adjustPosition = (top, left, tooltipRect) => {
    top -= tooltipRect.height;

    return { top, left };
  };

  return (
    <div className={`p-2 ${isStartLocation ? "pl-8" : ""}`}>
      <div ref={containerRef} className="flex flex-col">
        <div className="flex justify-between h-[21px]">
          <Text
            lg
            className="text-black overflow-ellipsis overflow-hidden whitespace-nowrap"
          >
            {address}
          </Text>
          {isStartLocation && (
            <Icon name="arrow-right-3" className="relative -right-3" />
          )}
        </div>
        <Text sm className="text-black/70">
          {time}
        </Text>
      </div>
      <Tooltip
        timeout={500}
        triggerRef={containerRef}
        adjustPosition={adjustPosition}
        className="max-w-[220px]"
      >
        <Text>{address}</Text>
      </Tooltip>
    </div>
  );
}
