import "src/models/typings";

/* eslint-disable */
import { useQuery } from "@apollo/client";
import { getYear } from "date-fns";
import addSeconds from "date-fns/addSeconds";
import getMinutes from "date-fns/getMinutes";
import isBefore from "date-fns/isBefore";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import React, { useContext, useEffect, useState } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import DatePicker from "src/components/elements/DatePicker";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";
import Textarea from "src/components/elements/Textarea";
import TimePicker from "src/components/elements/TimePicker";

import PlacesAutocomplete from "src/components/blocks/PlacesAutocomplete";
import SelectVehicle from "src/components/blocks/drives/SelectVehicle";
import CurrencyInput from "src/components/blocks/inputs/CurrencyInput";
import DistanceInput from "src/components/blocks/inputs/DistanceInput";
import Map from "src/components/blocks/map/Map";

import DriveLocation from "src/models/location";
import Purpose from "src/models/purpose";

import { getDirectionData } from "src/services/maps";
import {
  CustomPurposeSource,
  Pages,
  trackCustomPurposeAddCompleted,
  trackCustomPurposeAddStarted,
  trackVehicleAddCompleted,
  trackVehicleAddStarted,
} from "src/services/tracking";
import {
  COUNTRIES,
  COUNTRIES_DATA,
  MILES_TO_KM,
  VEHICLE_TYPES,
  formatCurrency,
  getDefaultLocation,
  getHoursAMPM,
  getTime24,
  getTimeZoneDiffInHours,
  toBEDistance,
  toUIDistance,
} from "src/services/utils";

import { GET_RATES } from "src/graphql/queries";

import PurposePicker from "./classification/PurposePicker";

export default DriveForm;

function isValidLocation(location) {
  const { name, latitude, longitude } = location;

  if (name?.trim() !== "" && latitude && longitude) return true;

  return false;
}

function DriveForm({ drive, title, onSubmit, submitBtnText, loading }) {
  const { userData } = useContext(UserDataContext);
  const calculatedDriveDistance = toUIDistance(
    drive?.googleDistance || 0,
    userData.distanceUnit
  ).toFixed(1);
  const defaultLocation = getDefaultLocation(userData.country);
  const [tabIdx, setTabIdx] = useState(0);
  /** @type {[purpose:  Purpose]} */
  const [purpose, setPurpose] = useState(drive?.purpose || null);
  const [startLocation, setStartLocation] = useState(
    drive?.startLocation || new DriveLocation(defaultLocation)
  );
  const [endLocation, setEndLocation] = useState(
    drive?.endLocation || new DriveLocation(defaultLocation)
  );
  const [startDate, setStartDate] = useState(drive?.startDate || new Date());
  const [endDate, setEndDate] = useState(drive?.endDate || new Date());
  const [distance, setDistance] = useState(calculatedDriveDistance);
  const [duration, setDuration] = useState(drive?.duration || 0);
  const [parkingFees, setParkingFees] = useState(
    (drive?.parkingFees || 0).toFixed(2)
  );
  const [tollFees, setTollFees] = useState((drive?.tollFees || 0).toFixed(2));
  const [notes, setNotes] = useState(drive?.notes || "");
  const [vehicle, setVehicle] = useState({
    id: drive?.vehicleId || null,
    type: drive?.vehicleType || null,
  });
  const [startLocInpTouched, setStartLocInpTouched] = useState(false);
  const [endLocInpTouched, setEndLocInpTouched] = useState(false);
  const [locationWasChanged, setLocationWasChanged] = useState(false);

  const ratesQuery = useQuery(GET_RATES, {
    notifyOnNetworkStatusChange: true,
  });

  const calcValue = () => {
    if (!purpose || !purpose.category) return 0;

    if (ratesQuery?.data?.rates) {
      // sort rates by year if server returns them in random order
      const rates = [...ratesQuery.data.rates].sort((a, b) => b.year - a.year);
      const currRate =
        rates.find((r) => r.year === getYear(new Date())) || rates[0];

      if (currRate) {
        try {
          const rateData = JSON.parse(currRate.dataJSON);
          const catKey = purpose.isBusiness ? "business" : "personal";
          const { values } = rateData;
          let rate = 0;

          if (typeof values[catKey] !== "undefined") {
            if (userData.country === COUNTRIES.GB) {
              if (
                vehicle.type &&
                typeof values[catKey]["vehicle"][vehicle.type] !== "undefined"
              ) {
                rate = values[catKey]["vehicle"][vehicle.type];
              } else {
                rate = values[catKey]["vehicle"][VEHICLE_TYPES.AUTOMOBILE] || 0;
              }
            } else {
              const id = purpose.id.toLowerCase();
              if (typeof values[catKey][id] !== "undefined") {
                rate = values[catKey][id];
              } else {
                rate = values[catKey]["default"] || 0;
              }
            }
          }
          // in US some personal purposes (e.g. medical, moving, charity) might have rate (!== 0)
          // so we should calc potential values as for business purposes
          if (purpose.isBusiness || rate !== 0) {
            let value = parseFloat(distance || 0) * rate;
            if (userData.distanceUnit === "km") {
              value = value / MILES_TO_KM;
            }
            return (
              value + parseFloat(parkingFees || 0) + parseFloat(tollFees || 0)
            );
          } else {
            return 0;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }

    return 0;
  };

  const updateDates = (startDate, endDate) => {
    const now = new Date();
    let sd = startDate;
    let ed = endDate;

    if (isBefore(now, sd)) {
      sd = now;
    }
    if (isBefore(now, ed)) {
      ed = now;
    }
    if (isBefore(ed, sd)) ed = sd;

    setStartDate(sd);
    setEndDate(ed);
  };

  const updateLocations = async (startLocation, endLocation) => {
    setLocationWasChanged(true);
    if (
      startLocation?.lat &&
      startLocation?.lng &&
      endLocation?.lat &&
      endLocation?.lng
    ) {
      try {
        const { distance, duration } = await getDirectionData(
          startLocation,
          endLocation
        );

        setDistance(toUIDistance(distance, userData.distanceUnit).toFixed(1));
        setDuration(duration);
        updateDates(startDate, addSeconds(startDate, duration));
      } catch (err) {
        console.log(err);
      }
    }
    setStartLocation(startLocation);
    setEndLocation(endLocation);
  };

  const handleSelectPurpose = (purpose) => {
    setPurpose(purpose);
  };

  const handleSelectStartLocation = (location) => {
    updateLocations(location, endLocation);
  };

  const handleSelectEndLocation = (location) => {
    updateLocations(startLocation, location);
  };

  const handleSelectVehicle = (v) => {
    setVehicle({
      id: v.id,
      type: v.type,
    });
  };

  const handleChangeDistance = (e) => {
    const val = e.target.value;
    setDistance(val);
  };

  const handleChangeParking = (e) => {
    const val = e.target.value;
    setParkingFees(val);
  };

  const handleChangeTolls = (e) => {
    const val = e.target.value;
    setTollFees(val);
  };

  const handleChangeNotes = (e) => {
    const val = e.target.value;
    setNotes(val);
  };

  const handleSwapLocations = () => {
    updateLocations(endLocation, startLocation);
  };

  const handleSubmit = async (e) => {
    if (loading) return;

    onSubmit({
      category: purpose.category,
      purpose: purpose.id,
      distance: toBEDistance(distance, userData.distanceUnit),
      startLocation: {
        latitude: startLocation.lat,
        longitude: startLocation.lng,
      },
      endLocation: {
        latitude: endLocation.lat,
        longitude: endLocation.lng,
      },
      notes,
      parkingFees: parseFloat(parkingFees),
      tollFees: parseFloat(tollFees),
      startDate,
      endDate,
      timeZoneDiffInHours: getTimeZoneDiffInHours(startDate),
      vehicleId: vehicle.id,
      vehicleType: vehicle.type,
    });
  };

  const startLocValid = isValidLocation(startLocation);
  const endLocValid = isValidLocation(endLocation);

  const hasStartLocation =
    startLocValid &&
    startLocation?.latitude !== defaultLocation.latitude &&
    startLocation?.longitude !== defaultLocation.longitude;

  const hasEndLocation =
    endLocValid &&
    endLocation?.latitude !== defaultLocation.latitude &&
    endLocation?.longitude !== defaultLocation.longitude;

  const startLocInpValid = startLocInpTouched ? startLocValid : true;
  const endLocInpValid = endLocInpTouched ? endLocValid : true;

  // on Duplicate drives, show warning if one of the locations is invalid
  const showInvalidRouteWarn =
    locationWasChanged && (!startLocInpValid || !endLocInpValid);

  const disableSubmitBtn =
    hasStartLocation && hasEndLocation && !!purpose && !purpose.isUnclassified;

  const value = calcValue();

  if (ratesQuery.loading)
    return (
      <div className="h-[204px]">
        <Loader />
      </div>
    );

  const isMilesUnit = userData.distanceUnit === "mi";
  const isGb = userData.country === "GB";
  return (
    <>
      <h4 className="mb-4" data-testid="drive-form-title">
        {title}
      </h4>
      <SelectPurpose selected={purpose} onSelect={handleSelectPurpose} />
      {!!purpose && (
        <div>
          <div className="mt-[20px] laptop:mt-[15px]">
            {tabIdx === 0 ? (
              <div
                key={tabIdx}
                className="animate-fade-in-300"
                data-testid="drive-form-tab-1-content"
              >
                <div className="flex justify-between">
                  <LocationForm
                    start
                    formData={{ location: startLocation, date: startDate }}
                    onSelectLocation={handleSelectStartLocation}
                    onDateChanged={(d) => {
                      setStartDate(d);
                      if (duration) {
                        updateDates(d, addSeconds(d, duration));
                      } else {
                        updateDates(d, d);
                      }
                    }}
                    onBlurPlaceAutosuggest={(e) => {
                      if (e.target?.value?.trim() !== "") {
                        setStartLocInpTouched(true);
                      }
                    }}
                    locationInputValid={startLocInpValid}
                  />
                  <IconButton
                    name="arrows-swap"
                    className="mt-[122px] laptop:mt-[125px] [&_i]:scale-75"
                    onClick={handleSwapLocations}
                  />
                  <LocationForm
                    end
                    formData={{ location: endLocation, date: endDate }}
                    onSelectLocation={handleSelectEndLocation}
                    onDateChanged={(d) => {
                      updateDates(startDate, d);
                    }}
                    minDate={startDate}
                    onBlurPlaceAutosuggest={(e) => {
                      if (e.target?.value?.trim() !== "") {
                        setEndLocInpTouched(true);
                      }
                    }}
                    locationInputValid={endLocInpValid}
                  />
                </div>
                <div className="mt-[20px] laptop:mt-[15px] w-[300px]">
                  <SelectVehicle
                    onStartAddDetails={({ type }) => {
                      trackVehicleAddStarted({
                        page: Pages.DRIVES,
                        type: type,
                      });
                    }}
                    onAddDetailsCompleted={({ type }) => {
                      trackVehicleAddCompleted({
                        page: Pages.DRIVES,
                        type: type,
                      });
                    }}
                    onSelect={handleSelectVehicle}
                    selected={{
                      id: vehicle.id,
                      type: vehicle.type,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                key={tabIdx}
                className="animate-fade-in-300 flex flex-col gap-[20px] laptop:gap-[15px] min-h-[349px] laptop:min-h-[323px]"
                data-testid="drive-form-tab-2-content"
              >
                <div className="grid grid-cols-3 gap-3">
                  <div className="w-full">
                    <DistanceInput
                      inKm={!isMilesUnit}
                      data-testid="drive-form-distance-input"
                      value={distance}
                      onChange={handleChangeDistance}
                      onBlur={() =>
                        setDistance((distance) => Number(distance).toFixed(1))
                      }
                    />
                  </div>
                  <div className="w-full">
                    <CurrencyInput
                      inPounds={isGb}
                      label="Parking"
                      data-testid="drive-form-parking-input"
                      value={parkingFees}
                      onChange={handleChangeParking}
                      onBlur={() =>
                        setParkingFees((parkingFees) =>
                          Number(parkingFees).toFixed(2)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <CurrencyInput
                      inPounds={isGb}
                      label="Tolls"
                      data-testid="drive-form-tolls-input"
                      value={tollFees}
                      onChange={handleChangeTolls}
                      onBlur={() =>
                        setTollFees((tollFees) => Number(tollFees).toFixed(2))
                      }
                    />
                  </div>
                </div>
                <div>
                  <Textarea
                    label="Notes"
                    value={notes}
                    onChange={handleChangeNotes}
                    className="w-full"
                    height={130}
                    maxHeight={300}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-[20px] border-t border-border-1 flex relative">
            <div
              className={`transition-transform ${
                tabIdx === 1 ? "translate-x-full" : ""
              } absolute top-0 left-0 h-[5px] w-[60px] bg-blue`}
            />
            <div className="flex justify-center w-[60px]">
              <IconButton
                name="pin-outline"
                color="black"
                className="w-full h-[60px]"
                onClick={() => setTabIdx(0)}
              />
            </div>
            <div className="flex justify-center w-[60px]">
              <IconButton
                data-testid="drive-form-tab-2-btn"
                name="notes"
                color="black"
                className="w-full h-[60px]"
                onClick={() => setTabIdx(1)}
              />
            </div>
            <div className="flex-grow" />
            <div className="flex flex-col pt-4 mr-[60px] items-end">
              <h5 className="break-all" data-testid="drive-form-distance">
                {distance}
              </h5>
              <Text>
                {isMilesUnit
                  ? "Miles"
                  : COUNTRIES_DATA[userData.country].unitName}
              </Text>
            </div>
            <div className="flex flex-col pt-4 items-end">
              <h5 className="break-all" data-testid="drive-form-value">
                {formatCurrency({ value, currency: userData.currency })}
              </h5>
              <Text>Value</Text>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-end mt-[20px] laptop:mt-[15px]">
        {showInvalidRouteWarn && (
          <div className="animate-[fadeIn_400ms_linear_forwards] flex items-center mr-auto">
            <Icon name="warning-triangle" />
            <Text className="text-red ml-3">Please enter a valid route</Text>
          </div>
        )}
        <Button
          onClick={handleSubmit}
          loading={loading}
          disabled={!disableSubmitBtn}
          data-testid="drive-form-submit-btn"
        >
          {loading ? "Loading..." : submitBtnText}
        </Button>
      </div>
    </>
  );
}

function LocationForm({
  start,
  end,
  formData,
  onSelectLocation,
  onDateChanged,
  onBlurPlaceAutosuggest,
  minDate,
  locationInputValid,
}) {
  const { userData } = useContext(UserDataContext);
  const maxDate = new Date();
  const [mapObj, setMapObj] = useState(null);

  const [location, setLocation] = useState(
    formData.location || new DriveLocation()
  );

  const handleTimeUpdated = ({ hours, minutes, isAm }) => {
    const newDate = setMinutes(
      setHours(formData?.date || new Date(), getTime24({ hours, isAm })),
      minutes
    );
    onDateChanged(newDate);
  };

  const handleDateUpdated = (date) => {
    const d = formData?.date || new Date();
    const { hours, isAm } = getHoursAMPM(d);
    const minutes = getMinutes(d);
    const newDate = setMinutes(
      setHours(date, getTime24({ hours, isAm })),
      minutes
    );
    onDateChanged(newDate);
  };

  const handlePlaceSelected = async (place) => {
    const newLoc = new DriveLocation({
      latitude: place.location.latitude,
      longitude: place.location.longitude,
      name: place.name,
      displayName: place.name,
    });
    setLocation(newLoc);
    onSelectLocation(newLoc);
  };

  const checkIsValidTime = ({ hours, minutes, isAm }) => {
    const maxDate = new Date(); // don't allow to select future dates
    const newDate = setMinutes(
      setHours(formData.date, getTime24({ hours, isAm })),
      minutes
    );

    // check if newDate is not in future
    if (!isBefore(newDate, maxDate)) {
      return {
        isValid: false,
        recommended: { ...getHoursAMPM(maxDate), minutes: getMinutes(maxDate) },
      };
    }

    // check if newDate is earlier than minDate provided
    if (minDate && isBefore(newDate, minDate)) {
      return {
        isValid: false,
        recommended: { ...getHoursAMPM(minDate), minutes: getMinutes(minDate) },
      };
    }

    return { isValid: true };
  };

  const theDate = formData?.date || new Date();
  const { hours, isAm } = getHoursAMPM(theDate);
  const minutes = getMinutes(theDate);
  const time = { hours, minutes, isAm };

  const locationProps = {
    [start ? "startLocation" : "endLocation"]: location,
    defaultCenter: new DriveLocation(getDefaultLocation(userData.country)),
    onInit: (mapObj) => setMapObj(mapObj),
  };

  const icon = start ? (
    <i className="inline-block w-[12px] h-[12px] rounded-full border-4 border-[#117E32]" />
  ) : (
    <i className="inline-block w-[12px] h-[12px] rounded-2 border-4 border-[#FF480E]" />
  );

  useEffect(() => {
    if (formData.location !== location) {
      setLocation(formData.location);
    }
  }, [formData]);

  return (
    <div className="w-[300px]">
      <Map
        mapClassName="rounded border border-border-1 overflow-hidden [&_.gm-style]:rounded [&_.gm-style]:overflow-hidden"
        containerClassName="w-full h-[160px] rounded"
        {...locationProps}
      />
      <PlacesAutocomplete
        className="mt-[15px] laptop:mt-[10px]"
        onSelectPlace={handlePlaceSelected}
        mapObj={mapObj}
        placeName={location.fullAddress}
        icon={icon}
        placeholder={start ? "Start location" : "End location"}
        onBlur={onBlurPlaceAutosuggest}
        valid={locationInputValid}
        adjustPosition={(top, left, elRect, pivotRect) => {
          let t = top;
          let l = left;
          const maxLeft =
            document.documentElement.clientWidth - elRect.width - 10;
          if (l > maxLeft) {
            l = maxLeft - 5;
          }
          return { top: t, left: l };
        }}
        maximumNamedLocations={5}
      />
      <div className="grid grid-cols-2 gap-[10px] mt-[10px] [&_.miq-icon]:scale-[0.85]">
        <DatePicker
          icon="calendar-no-days"
          date={theDate}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={handleDateUpdated}
          alignment={{ right: !!start, left: !!end }}
        />
        <TimePicker
          time={time}
          onUpdate={handleTimeUpdated}
          isTimeValid={checkIsValidTime}
        />
      </div>
    </div>
  );
}

/**
 *
 * @param {{selected: Purpose}} props
 * @returns
 */
function SelectPurpose({ onSelect, selected }) {
  return (
    <div className="flex items-center justify-between p-[10px] pl-[20px] border border-border-1 rounded">
      <Text semibold>What is this drive?</Text>
      <div
        className={`flex items-center ${
          selected ? "w-[451px]" : "w-[312px] laptop:w-[250px]"
        } `}
      >
        <PurposePicker
          className="w-full"
          selected={selected}
          onSelect={onSelect}
          onAddNewStarted={({ category }) => {
            trackCustomPurposeAddStarted({
              category,
              src: CustomPurposeSource.ADD_DRIVE,
            });
          }}
          onAddNewCompleted={({ category }) => {
            trackCustomPurposeAddCompleted({
              category,
              src: CustomPurposeSource.ADD_DRIVE,
            });
          }}
        />
      </div>
    </div>
  );
}
