import React, { useContext, useEffect, useState } from "react";

import { UserDataContext } from "src/components/context/UserContext";

import CurrencyInput from "src/components/blocks/inputs/CurrencyInput";
import DistanceInput from "src/components/blocks/inputs/DistanceInput";

import {
  handleEnterSpaceKeyPress,
  toBEDistance,
  toUIDistance,
} from "src/services/utils";

const Inputs = ({
  distance,
  parkingFees,
  tollFees,
  onEditDrive,
  isMultipleDrive,
}) => {
  const { userData } = useContext(UserDataContext);
  const [newDistance, setNewDistance] = useState(0);
  const [newParkingFee, setNewParkingFee] = useState(0);
  const [newTollFees, setNewTollsFees] = useState(0);

  useEffect(
    () =>
      setNewDistance(toUIDistance(distance, userData.distanceUnit).toFixed(1)),
    [distance]
  );
  useEffect(() => setNewParkingFee(parkingFees?.toFixed(2)), [parkingFees]);
  useEffect(() => setNewTollsFees(tollFees?.toFixed(2)), [tollFees]);

  const onDistanceBlur = (e) => {
    if (+e.target.value === +distance) return;

    const newDistance = parseFloat(e.target.value);
    onEditDrive({
      googleDistance: toBEDistance(newDistance, userData.distanceUnit),
      calPotentialValue: "True",
    }).then(() => {
      setNewDistance(Number(e.target.value).toFixed(1));
    });
  };

  const onParkingFeesBlur = (e) => {
    if (+e.target.value === +parkingFees) return;

    onEditDrive({ parkingFees: parseFloat(e.target.value) }).then(() => {
      setNewParkingFee(Number(e.target.value).toFixed(2));
    });
  };

  const onTollsBlur = (e) => {
    if (+e.target.value === +tollFees) return;

    onEditDrive({ tollFees: parseFloat(e.target.value) }).then(() => {
      setNewTollsFees(Number(e.target.value).toFixed(2));
    });
  };

  const getMultipleDrivesInputProps = (field) => {
    if (isMultipleDrive) {
      const value = Number(field);
      return {
        placeholder: value ? "" : "Multiple",
        noCurrency: !value,
      };
    }

    return {};
  };

  const isGb = userData.country === "GB";
  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="flex flex-col gap-2">
        <DistanceInput
          inKm={userData.distanceUnit === "km"}
          data-testid="distance-input"
          disabled={isMultipleDrive}
          value={newDistance}
          onChange={(e) => setNewDistance(e.target.value)}
          onKeyPress={handleEnterSpaceKeyPress(onDistanceBlur)}
          onBlur={onDistanceBlur}
        />
      </div>
      <div className="flex flex-col gap-2">
        <CurrencyInput
          inPounds={isGb}
          data-testid="parking-input"
          label="Parking"
          value={newParkingFee}
          onChange={(e) => setNewParkingFee(e.target.value)}
          onKeyPress={handleEnterSpaceKeyPress(onParkingFeesBlur)}
          onBlur={onParkingFeesBlur}
          {...getMultipleDrivesInputProps(newParkingFee)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <CurrencyInput
          inPounds={isGb}
          data-testid="tolls-input"
          label="Tolls"
          value={newTollFees}
          onChange={(e) => setNewTollsFees(e.target.value)}
          onKeyPress={handleEnterSpaceKeyPress(onTollsBlur)}
          onBlur={onTollsBlur}
          {...getMultipleDrivesInputProps(newTollFees)}
        />
      </div>
    </div>
  );
};

export default Inputs;
