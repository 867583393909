import { createContext, useContext, useEffect, useState } from "react";

import { useFlags } from "src/hooks/useFlags";

const SCRIPT_ID = "profitwell-js";

const ProfitwellContext = createContext();

export function useProfitwell() {
  return useContext(ProfitwellContext);
}

export function ProfitwellProvider({ children }) {
  const [isLoaded, setLoaded] = useState(false);
  const [pending, setPending] = useState(false);
  const { miqDashProfitwellWeb: isEnabled } = useFlags();

  function identify({ email }) {
    if (window?.profitwell) {
      window?.profitwell("start", { user_email: email });
      setPending(false);
    } else {
      setPending(email);
    }
  }

  function handleScriptLoaded() {
    setLoaded(true);
    if (pending) {
      identify({ email: pending });
    }
  }

  useEffect(() => {
    if (isLoaded && pending) {
      identify({ email: pending });
    }
  }, [isLoaded, pending]);

  useEffect(() => {
    if (isEnabled && !document.getElementById(SCRIPT_ID)) {
      loadScript({
        id: SCRIPT_ID,
        async: 1,
        ["data-pw-auth"]: "ebcc774d0355d3a99936752e8c149700",
        innerText: `
        (function (i, s, o, g, r, a, m) {
          i[o] =
            i[o] ||
            function () {
              (i[o].q = i[o].q || []).push(arguments);
            };
          a = s.createElement(g);
          m = s.getElementsByTagName(g)[0];
          a.async = 1;
          a.src =
            r +
            "?auth=" +
            s.getElementById(o + "-js").getAttribute("data-pw-auth");
          m.parentNode.insertBefore(a, m);
        })(
          window,
          document,
          "profitwell",
          "script",
          "https://public.profitwell.com/js/profitwell.js"
        );
        `,
      }).then(handleScriptLoaded);
      return removeScript;
    }
  }, [isEnabled]);

  return (
    <ProfitwellContext.Provider value={{ identify, isEnabled }}>
      {children}
    </ProfitwellContext.Provider>
  );
}

async function loadScript({ innerText, ...props }) {
  if (document.getElementById(props.id)) return;

  const script = document.createElement("script");
  Object.entries(props).forEach(([key, value]) => {
    script.setAttribute(key, value);
  });
  if (innerText) {
    script.innerText = innerText;
  }
  document.body.appendChild(script);

  return new Promise((resolve) => {
    script.addEventListener("load", resolve);
  });
}

function removeScript() {
  document.getElementById(SCRIPT_ID)?.remove();
}
