import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";

import Button from "src/components/elements/Button";
import Loader from "src/components/elements/Loader";
import MobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import ReportDetailSidebar from "src/components/blocks/reports/teams/ReportDetailSidebar";
import ReportDrivesList from "src/components/blocks/reports/teams/ReportDrivesList";

import PageLayout from "src/components/PageLayout";

import { getReport } from "src/services/reports";

export default ReportDetailsPage;

const PAGE_STATES = {
  IDLE: "IDLE",
  REPORT_LOADING: "REPORT_LOADING",
  REPORT_LOADED: "REPORT_LOADED",
  REPORT_ERROR: "REPORT_ERROR",
};

function ReportDetailsPage() {
  const history = useHistory();
  const { report_id: reportId } = useParams();
  const { team } = useContext(TeamContext);
  const { setDetailedReport, setSelectedDrive } = useTeamReports();

  const [pageState, setPageState] = useState(PAGE_STATES.IDLE);
  const [report, setReport] = useState(null);

  const handleBackBtnClicked = () => {
    if (history.location.state?.fromReportList) {
      return history.goBack();
    }

    history.push("/teams/reports");
  };

  const loadReport = async () => {
    setPageState(PAGE_STATES.REPORT_LOADING);

    try {
      const report = await getReport(team?.id, reportId);

      setDetailedReport(report);
      setReport(report);
      setPageState(PAGE_STATES.REPORT_LOADED);

      return report;
    } catch (err) {
      console.log("failed to load the report");
      setPageState(PAGE_STATES.REPORT_ERROR);
    }
  };

  useEffect(() => {
    if (!reportId) {
      history.replace("/reports");
    } else {
      loadReport();
    }

    return () => {
      setSelectedDrive(null);
    };
  }, []);

  if (!team?.id) return null;

  const shouldDisplayName = report?.userFirstName && report?.userLastName;
  return (
    <>
      <PageLayout className="page-reports">
        <PageLayout.Main>
          {pageState === PAGE_STATES.REPORT_LOADING && (
            <Loader
              className="p-8"
              message="Processing... This may take a few minutes depending on the report size."
              timeout={10000}
            />
          )}

          {pageState === PAGE_STATES.REPORT_LOADED && (
            <div className="h-full flex flex-col">
              <div className="page-header">
                <div className="-ml-2">
                  <Button
                    text
                    icon="chevron-left-small"
                    color="blue"
                    onClick={handleBackBtnClicked}
                  >
                    Back to Reports
                  </Button>
                </div>
                <div className="flex items-center">
                  <MobMenu />
                  <div className="flex flex-col gap-1">
                    <h3 className="mt-2">
                      {shouldDisplayName
                        ? `${report.userFirstName} ${report.userLastName}`
                        : report.userEmail}
                    </h3>
                    {shouldDisplayName && (
                      <Text title={report.userEmail} className="truncate">
                        {report.userEmail}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <ReportDrivesList reportId={report.id} />
              </div>
            </div>
          )}
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <ReportDetailSidebar />
        </PageLayout.Sidebar>
      </PageLayout>
    </>
  );
}
