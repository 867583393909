import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import LayersContext from "src/lib/layers/LayersContext";

export default LayersProvider;

/** @type {Map<String, Layer>} */
const knownLayers = new Map();

/** @type {Map<String, Element>} */
const knownElements = new Map();

/** @type {Map<String, any>} */
const activeElementsProps = new Map();

class Layer {
  id = null;
  order = 0;
  constructor(id, order) {
    this.id = id;
    this.order = order;
  }
}

class Element {
  uuid = null;
  id = null;
  layerId = null;
  component = null;
  props = null;

  constructor(id, layerId, component) {
    this.id = id;
    this.layerId = layerId;
    this.component = component;
    this.uuid = uuid();
  }

  clone() {
    return new Element(this.id, this.layerId, this.component);
  }

  setProps(props) {
    const newProps = { ...this.props, ...props };
    this.props = newProps;
  }

  render() {
    const Component = this.component;
    const props = this.props || {};

    return <Component {...props} />;
  }
}

export function registerLayer(layerId, order) {
  if (knownLayers.has(layerId)) {
    console.warn(
      `Layer with the key "${layerId}" has been already registered. Skipping.`
    );
    return;
  }

  const layer = new Layer(layerId, order);
  knownLayers.set(layerId, layer);
}

// create default layer
export const DEFAULT_LAYER = "DEFAULT_LAYER";
registerLayer(DEFAULT_LAYER, 1);

export function registerElement(id, component, opts = {}) {
  let layerId = opts.layer?.key || DEFAULT_LAYER;

  if (layerId !== DEFAULT_LAYER && !knownLayers.has(layerId)) {
    registerLayer(layerId, opts.layer?.order || 0);
  }

  if (knownElements.has(id)) {
    console.warn(
      `Element with the id "${id}" has been already registered. Skipping.`
    );
    return;
  }

  const el = new Element(id, layerId, component);
  knownElements.set(id, el);
}

function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
  // return crypto.randomUUID();
}

/** @type Map<String, Element> */
const activated = new Map();

function activateElement(id, opts = {}) {
  let el = knownElements.get(id);
  if (!el) return null;

  el = el.clone();
  el.setProps(opts?.props);

  const elUuid = el.uuid;
  activated.set(elUuid, el);

  // console.log(activated);
  if (typeof opts.onActivate === "function") {
    opts.onActivate();
  }

  return {
    getProps: () => {
      return activated.get(elUuid).props;
    },
    updateProps: (props) => {
      activated.get(elUuid).setProps(props);
    },
    deactivate: () => {
      activated.delete(elUuid);
      if (typeof opts.onDeactivate === "function") {
        opts.onDeactivate();
      }
    },
  };
}

function LayersProvider({ children }) {
  // eslint-disable-next-line
  const [updated, setUpdated] = useState(uuid());

  /** @type {Set<Layer>} */
  let activeLayersKeys = new Set();

  for (let el of activated.values()) {
    activeLayersKeys.add(el.layerId);
  }

  return (
    <LayersContext.Provider
      value={{
        activate: (id, opts) => {
          // console.log("activate: ", id);
          const activeEl = activateElement(id, {
            ...opts,
            onActivate: () => {
              if (typeof opts.onActivate === "function") {
                opts.onActivate();
              }
              setUpdated(uuid());
            },
            onDeactivate: () => {
              if (typeof opts.onDeactivate === "function") {
                opts.onDeactivate();
              }
              setUpdated(uuid());
            },
          });

          let activeForMsTimeout = null;
          if (opts?.activeForMs) {
            activeForMsTimeout = setTimeout(() => {
              activeEl.deactivate();
            }, opts.activeForMs);
          }

          return {
            getProps: () => {
              return activeEl.getProps();
            },
            updateProps: (props) => {
              activeEl.updateProps(props);
              setUpdated(uuid());
            },
            deactivate: () => {
              if (activeForMsTimeout) {
                clearTimeout(activeForMsTimeout);
              }
              activeEl.deactivate();
            },
          };
        },
      }}
    >
      {children}
      <>
        {[...activeLayersKeys].map((layerId) => {
          const layer = knownLayers.get(layerId);
          return (
            <LayersPlaceholder
              key={layerId}
              layer={layer}
              elements={[...activated.values()].filter(
                (el) => el.layerId === layer.id
              )}
            />
          );
        })}
      </>
    </LayersContext.Provider>
  );
}

function LayersPlaceholder(props) {
  /** @type {{layer: Layer, elements: [Element]}} */
  const { layer, elements } = props;

  let portalId = `layer-${layer.id}`;
  let el = document.getElementById(portalId);
  if (!el) {
    el = document.createElement("div");
    el.id = portalId;
    el.style.position = "relative";
    // el.style.position = "fixed";
    // el.style.top = 0;
    // el.style.left = 0;
    // el.style.width = "100vw";
    // el.style.height = "100vh";
    el.style.zIndex = elements?.length ? 10000 + layer.order : -1;
    document.body.appendChild(el);
  }

  useEffect(() => {
    return () => {
      el.remove();
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {elements.map((el) => {
        const props = activeElementsProps.get(el.id) || {};
        return (
          <React.Fragment key={el.uuid}>{el.render(props)}</React.Fragment>
        );
      })}
    </>,
    el
  );
}
