import { isToday } from "date-fns";
import React, { useContext, useState } from "react";

import SettingsContext from "src/components/context/SettingsContext";
import TeamContext from "src/components/context/TeamContext";

import Text from "src/components/elements/Text";

import RatesFormCA from "src/components/blocks/custom-rates/RatesFormCA";
import RatesFormUK from "src/components/blocks/custom-rates/RatesFormUK";
import RatesFormUS from "src/components/blocks/custom-rates/RatesFormUS";

import { updateRates, updateRatesByID } from "src/services/teams";
import {
  customRatesEffectiveDateTypes,
  customRatesSelectionTypes,
  customRatesUpdateTypes,
  trackTeamsCustomRatesUpdateCompleted,
} from "src/services/tracking";
import { COUNTRIES } from "src/services/utils";

export default CustomRates;

const RATES_DATA = {
  [COUNTRIES.US]: {
    description:
      "Changing the mileage rate will change it for every member of your team starting from the Effective Date. It will not affect reports that have already been submitted, and may take a moment to take effect.",
  },
  [COUNTRIES.GB]: {
    description:
      "We've set the current HMRC rate for your team by default, but feel free to change it (drivers do not have control over the rate).",
  },
  [COUNTRIES.CA]: {
    description:
      "We've set the current CRA rate for your team by default, but feel free to change it (drivers do not have control over the rate). Note: The CRA gives Northern Canada (Northwest Territories, Yukon, and Nunavut) an additional $0.04/km for travel.",
  },
};

function CustomRates({ rates: defaultRates, isRevertingTo, onSave, onCancel }) {
  const { team } = useContext(TeamContext);
  const ctxData = useContext(SettingsContext);
  const { country } = team;
  const [loading, setLoading] = useState(false);

  const saveRates = async (rates) => {
    setLoading(true);

    const effectiveDateType = isToday(rates.effectiveDate)
      ? customRatesEffectiveDateTypes.IMMEDIATE
      : customRatesEffectiveDateTypes.FUTURE;

    let updateType = "";
    let selectionType = "";

    try {
      if (isRevertingTo) {
        // Reverting to previous custom rates
        await updateRates(team.id, rates);

        updateType = customRatesUpdateTypes.REVERT_TO_PREVIOUS;
        selectionType = customRatesSelectionTypes.PAST;
      } else if (!defaultRates) {
        // Creating new custom rates
        await updateRates(team.id, rates);

        updateType = customRatesUpdateTypes.CHANGE;
        selectionType = customRatesSelectionTypes.CURRENT;
      } else {
        // Editing upcoming rates
        await updateRatesByID(team.id, rates);

        updateType = customRatesUpdateTypes.CHANGE;
        selectionType = customRatesSelectionTypes.UPCOMING;
      }

      ctxData.unsavedRates = null;
      if (typeof onSave === "function") onSave();
    } catch (err) {
      console.log(err);
    }

    trackTeamsCustomRatesUpdateCompleted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      updateType,
      selection: selectionType,
      effectiveDate: effectiveDateType,
    });

    setLoading(false);
  };

  const renderForm = ({ loading }) => {
    switch (country) {
      case COUNTRIES.US:
        return (
          <RatesFormUS
            rates={defaultRates}
            isRevertingTo={isRevertingTo}
            compareRates={isRevertingTo && team.cloneRatesForEdit()}
            loading={loading}
            onSave={saveRates}
            onCancel={onCancel}
          />
        );
      case COUNTRIES.GB:
        return (
          <RatesFormUK
            rates={defaultRates}
            isRevertingTo={isRevertingTo}
            compareRates={isRevertingTo && team.cloneRatesForEdit()}
            onSave={saveRates}
            onCancel={onCancel}
          />
        );
      case COUNTRIES.CA:
        return (
          <RatesFormCA
            rates={defaultRates}
            loading={loading}
            isRevertingTo={isRevertingTo}
            compareRates={isRevertingTo && team.cloneRatesForEdit()}
            onSave={saveRates}
            onCancel={onCancel}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <h5 className="mb-1">
        {isRevertingTo ? "Revert to this rate" : "Change rate"}
      </h5>
      <Text paragraph>{RATES_DATA[country].description}</Text>
      {renderForm({ loading, isRevertingTo })}
    </>
  );
}
