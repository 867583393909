import r from "../../shared-lib/braze-shared-lib.js";
import e from "../managers/braze-instance.js";
import { newFeatureFlagFromJson as tt } from "./feature-flag-factory.js";
import ir from "./feature-flags-provider-factory.js";
export function getAllFeatureFlags() {
  if (!e.rr()) return;
  let t = [];
  if (!e.ir().ye()) return r.D.info("Feature flags are not enabled."), t;
  const a = ir.er().xe();
  for (let r in a) t.push(tt(a[r]));
  return t;
}
