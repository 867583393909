import "src/models/typings";

import { useLazyQuery } from "@apollo/client";
import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import { SEND_RESET_PASSWORD_EMAIL } from "src/graphql/queries";

import Button from "../elements/Button";
import Text from "../elements/Text";

export default ResetPasswordModal;

export const ELEMENT_ID = "RESET_PASSWORD_MODAL";
registerElement(ELEMENT_ID, ResetPasswordModal);

function ResetPasswordModal({ onClose, onSubmit, email }) {
  const [sendResetPasswordEmail, { loading }] = useLazyQuery(
    SEND_RESET_PASSWORD_EMAIL
  );

  const handleSendResetPasswordEmail = async () => {
    await sendResetPasswordEmail({
      fetchPolicy: "network-only",
      variables: {
        username: email,
      },
    });
    onSubmit();
  };

  return (
    <Modal
      closable
      className="w-[500px]"
      onClose={onClose}
      testId="reset-password-modal"
    >
      <div className="flex flex-col items-center text-center mt-2">
        <h4 className="mb-[15px]">Reset password</h4>
        <Text paragraph lg className="text-center mx-0.5">
          We'll send an email to <Text bold>{email}</Text> with password
          resetting instructions.
        </Text>
        <Button
          loading={loading}
          className="mt-[30px] w-[100%] font-medium"
          onClick={handleSendResetPasswordEmail}
        >
          Send email
        </Button>
      </div>
    </Modal>
  );
}
