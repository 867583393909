export default class Ft {
  constructor(t, s, i, h, l, e, r, u) {
    (this.ul = t || 0),
      (this.bl = s || []),
      (this.dl = i || []),
      (this.vl = h || []),
      (this.Cl = l),
      (null != l && "" !== l) || (this.Cl = null),
      (this.gl = e || null),
      (this.ml = r || {}),
      (this.cl = u || {});
  }
  ss() {
    return {
      s: "4.5.0",
      l: this.ul,
      e: this.bl,
      a: this.dl,
      p: this.vl,
      m: this.Cl,
      v: this.gl,
      c: this.ml,
      f: this.cl
    };
  }
  static Sn(t) {
    let s = t.l;
    return (
      "4.5.0" !== t.s && (s = 0), new Ft(s, t.e, t.a, t.p, t.m, t.v, t.c, t.f)
    );
  }
}
