import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useNotifications } from "src/components/context/NotificationsContext";
import TeamContext from "src/components/context/TeamContext";
import { useUser } from "src/components/context/UserContext";

import MiqLoader from "src/components/elements/Loader";
import MiqMobMenu from "src/components/elements/MobMenu";

import PageLayout from "src/components/PageLayout";

import { useFlags } from "src/hooks/useFlags";

import { trackExperimentViewed } from "src/services/tracking";

import { CreateTeamPage } from "./CreateTeam";
import { TeamInvitedPage } from "./TeamInvited";
import { ReverseTrialPage } from "./experiments/31-ReverseTrial";
import { useReverseTrial } from "./experiments/31-ReverseTrial/hooks";

export function TeamsPageWrapper({ children }) {
  return (
    <PageLayout className="page-create-team bg-beige overflow-x-hidden">
      <div className="absolute top-4 left-6">
        <MiqMobMenu />
      </div>
      <PageLayout.Main>{children}</PageLayout.Main>
    </PageLayout>
  );
}

export function TeamsPageGuard() {
  const { user } = useUser();
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { notifications, loading: isFetchingNotifications } =
    useNotifications();
  const [loading, setLoading] = useState(true);
  const [showHasTeamOrInvite, setShowHasTeamOrInvite] = useState(false);
  const { evalFlagReverseTrial } = useReverseTrial();
  const {
    miqDashboardTeamsOverviewPageWeb: { active: isTeamsOverviewActive },
  } = useFlags();

  useEffect(() => {
    if (isFetchingNotifications) return;
    const isTeamAdmin = team?.isAdmin(user?.email);
    const trialExpired = ["canceled", "ended"].includes(
      team?.subscription?.status
    );
    if (showHasTeamOrInvite) {
      // redirect for non access /user/teams (admins and non-trial expired)
      if (isTeamAdmin && !trialExpired) {
        if (window.parent?.name === "mileiq.teams") {
          window.parent.focus();
        } else {
          const teamsHomePage = isTeamsOverviewActive
            ? "/teams/overview"
            : "/teams/users";
          window.open(teamsHomePage, "mileiq.teams")?.focus();
          if (history.length > 2) {
            history.goBack();
          } else {
            window.location.href = "/user";
          }
        }
      }
      // while auto accepting invite, teams is loading but null
      // don't ever evaluate the rest if we've already set showHasTeamOrInvite
      return;
    }

    const inviteAvailable = notifications && notifications.code !== 404;
    setShowHasTeamOrInvite(inviteAvailable || (team?.id && !isTeamAdmin));
    setLoading(false);
  }, [
    isFetchingNotifications,
    notifications,
    team,
    user,
    evalFlagReverseTrial,
    isTeamsOverviewActive,
    history,
  ]);

  if (loading) {
    return <MiqLoader />;
  }

  if (showHasTeamOrInvite) {
    return <TeamInvitedPage />;
  }

  return <ShowReverseOrCreateTeam />;
}

const ShowReverseOrCreateTeam = () => {
  const [showReverseTrial, setShowReverseTrial] = useState(false);
  const { evalFlagReverseTrial } = useReverseTrial();

  useEffect(() => {
    const flagDetails = evalFlagReverseTrial();
    if (flagDetails.reason?.inExperiment) {
      trackExperimentViewed({
        experimentId: 31,
        experimentName: "Teams Reverse Trial for Existing SA Users",
        variationId: flagDetails.value ? 1 : 0,
        variationName: flagDetails.value ? "var_1" : "control",
      });
    }
    const showReverseTrial = flagDetails.value;
    setShowReverseTrial(showReverseTrial);
  }, []);

  if (showReverseTrial) {
    return <ReverseTrialPage />;
  }
  return <CreateTeamPage />;
};
