import { useEffect, useRef, useState } from "react";

const usePrompt = ({ history, modalElement }) => {
  const [isDirty, setDirty] = useState(false);
  const unblockRef = useRef(null);
  const currentPath = useRef("");

  useEffect(() => {
    unblockRef.current = history?.block((location) => {
      if (isDirty) {
        currentPath.current = location.pathname;
        modalElement.activate();
        return false;
      }

      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [isDirty]);

  return { unblockRef, currentPath, isDirty, setDirty };
};

export default usePrompt;
