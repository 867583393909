import format from "date-fns/format";

import Report from "src/models/report";

import http from "src/services/http";

const reportsApiUrl = process.env.REPORTS_API_URL;

export async function emailReport(teamId, reportId, email) {
  return http.post(
    `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/${reportId}/generate`,
    { to_email: email, pdf: true, csv: true }
  );
}

export async function getReports(teamId, filters) {
  const { dateRange, drivers, month, year, page, pageSize, orderBy, isDesc } =
    filters;
  const qs = new URLSearchParams();
  if (dateRange && dateRange.length === 2) {
    qs.set("from_date", format(dateRange[0], "yyyy-MM-dd"));
  }
  if (drivers && drivers.length > 0) {
    drivers.forEach((d) => qs.append("driver", d));
  }
  if (typeof month !== "undefined" && month !== null && year) {
    // month could be 0 - January
    qs.set("month", month + 1); // api accepts 1-12, but in js we count 0-11
    qs.set("year", year);
  }
  if (orderBy) {
    const dirSign = isDesc ? "-" : "";
    switch (orderBy) {
      // api doesn't support sorting by last_drive_datetime as param
      case "last_drive_datetime": {
        qs.set("order_by", dirSign + "year");
        qs.append("order_by", dirSign + "month");
        break;
      }
      // api doesn't support sorting by user_email as param
      case "user_email":
        qs.set("order_by", dirSign + "user_parse_id");
        break;
      default:
        qs.set("order_by", dirSign + orderBy);
    }
  }
  if (page) {
    qs.set("page", page);
  }
  if (pageSize) {
    qs.set("page_size", pageSize);
  }

  // to get current name of the driver (if in team)
  // or last reported name (for removed drivers)
  qs.append("current_name", 1);

  return http
    .get(
      `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports?${qs.toString()}`
    )
    .then((response) => {
      let reports = [];
      let metadata = null;
      if (response) {
        const { data, ...meta } = response;
        if (data && data.length > 0) {
          reports = data.map((repData) => new Report(repData));
        }
        metadata = { ...meta };
      }
      return {
        metadata,
        reports,
      };
    });
}

export async function getReport(teamId, reportId) {
  return http
    .get(
      `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/${reportId}?current_name=1`
    )
    .then((data) => {
      if (data) return new Report(data);
      return null;
    });
}

export async function getTeamSubmitters(teamId) {
  return http
    .get(`${reportsApiUrl}/api/1/teams/${teamId}/submissions/submitters`)
    .then((response) => {
      const { submitters } = response || {};
      return submitters?.map((m) => ({
        id: m.user_parse_id,
        email: m.user_email,
        firstName: m.user_first_name,
        lastName: m.user_last_name,
      }));
    });
}

export async function getReportDownloadLink(
  teamId,
  reportId,
  types = { pdf: true, csv: true }
) {
  return http.post(
    `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/${reportId}/generate`,
    types
  );
}

export async function getBulkReportsDownloadLink(teamId, reportIds, fileType) {
  return http.post(
    `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/bulk-download`,
    { report_ids: reportIds, file_type: fileType }
  );
}

export async function getReportDrives(
  teamId,
  reportId,
  page = 1,
  manualDrives = 0,
  search = ""
) {
  const searchParams = new URLSearchParams();
  searchParams.set("page", page);
  manualDrives && searchParams.set("manually_added", manualDrives);
  search && searchParams.set("location", search);

  return http.get(
    `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/${reportId}/drives?${searchParams.toString()}`
  );
}

export async function getConsolidatedReportDownloadLink(teamId, filters) {
  return http.get(
    `${reportsApiUrl}/api/1/teams/${teamId}/driver-reports/consolidated-report?${filters.toString()}`
  );
}

export function getContactSharedReports() {
  return http.get(`${reportsApiUrl}/api/1/contact/reports`);
}

export function getContactSharedReportByToken(token) {
  return http.get(`${reportsApiUrl}/api/1/contact/report`, {
    authToken: token,
  });
}
