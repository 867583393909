import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import { PhoneInput } from "src/components/elements/PhoneInput";
import Text from "src/components/elements/Text";

import {
  AppDownloadRequestMethod,
  trackAppDownloadRequestFailed,
  trackAppDownloadRequested,
  trackTextDownloadLink,
} from "src/services/tracking";

import { SEND_DOWNLOAD_LINK_SMS } from "src/graphql/mutations";

export default TextToDownload;

function TextToDownload() {
  const [state, setState] = useState({});

  const { number, formatted, isValid, failed } = state || {};

  const [sendDownloadLinkSMS, { loading, data, error, reset }] = useMutation(
    SEND_DOWNLOAD_LINK_SMS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  function setFailed(failed) {
    setState((state) => ({ ...state, failed }));
  }

  function handleChange({ country, number, formatted, isValid }) {
    setState((state) => ({ ...state, country, number, formatted, isValid }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (loading) return;

    if (!isValid) {
      trackAppDownloadRequestFailed();
      return setFailed(true);
    } else {
      setFailed(false);
    }

    try {
      await sendDownloadLinkSMS({
        variables: {
          phoneNumber: number,
        },
        onCompleted: (data) => {
          if (data?.sendDownloadLinkSMS?.code == 200) {
            trackTextDownloadLink();
            trackAppDownloadRequested({
              method: AppDownloadRequestMethod.TEXT_TO_DOWNLOAD,
            });
          } else {
            trackAppDownloadRequestFailed();
          }
        },
        onError: () => {
          trackAppDownloadRequestFailed();
        },
      });
    } catch (err) {
      console.log(err);
      trackAppDownloadRequestFailed();
    }
  }

  useEffect(() => {
    // clear failed if its valid
    if (failed && isValid) {
      setFailed(false);
      return;
    }

    const hasError =
      error ||
      data?.sendDownloadLinkSMS?.errorCode ||
      data?.sendDownloadLinkSMS?.code > 200;

    setFailed(hasError);
  }, [error, data, isValid]);

  const sent = data?.sendDownloadLinkSMS?.code === 200;

  return (
    <>
      {!sent ? (
        <>
          <PhoneInput valid={!failed} onChange={handleChange} />
          {failed && (
            <Text paragraph className="text-red">
              {!isValid
                ? `Invalid phone number`
                : `Something went wrong, please try again!`}
            </Text>
          )}
          <Button
            className="w-full"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            Text me a download link
          </Button>
        </>
      ) : (
        <>
          <div
            className="px-6 py-4 rounded-10 flex align-center ites-center"
            style={{ background: "#DFF591" }}
          >
            <Icon name="smartphone" className="inline-block mr-4" />
            <Text>
              Your download link has been sent to
              <br />
              <strong>{formatted}</strong>
            </Text>
          </div>
          <Text paragraph className="cursor-pointer" onClick={reset}>
            Didn't receive it?{" "}
            <span className="font-bold underline">Try again</span>
          </Text>
        </>
      )}
    </>
  );
}
