export default {
  Zn: "allowCrawlerActivity",
  ho: "baseUrl",
  po: "noCookies",
  Eo: "devicePropertyAllowlist",
  ta: "disablePushTokenMaintenance",
  _o: "enableLogging",
  Io: "enableSdkAuthentication",
  ia: "manageServiceWorkerExternally",
  Ao: "minimumIntervalBetweenTriggerActionsInSeconds",
  So: "sessionTimeoutInSeconds",
  No: "appVersion",
  na: "serviceWorkerLocation",
  ra: "safariWebsitePushId",
  jn: "localization",
  ao: "contentSecurityNonce",
  wo: "enableHtmlInAppMessages",
  To: "allowUserSuppliedJavascript",
  no: "inAppMessageZIndex",
  lo: "openInAppMessagesInNewTab",
  en: "openNewsFeedCardsInNewTab",
  qi: "requireExplicitInAppMessageDismissal",
  Oo: "doNotLoadFontAwesome",
  vo: "sdkFlavor",
  tn: "openCardsInNewTab"
};
