import React from "react";

import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import FlyingCar from "public/assets/img/flying-car.svg";

export default SwitchingToPro;

function SwitchingToPro() {
  return (
    <Modal className="w-[720px] switching-to-pro-modal" closable={false}>
      <div className="flex flex-col items-center py-24">
        <Text custom className="text-30 font-semibold">
          Switching you to Teams Pro!
        </Text>
        <FlyingCar className="mt-10" />
      </div>
    </Modal>
  );
}
