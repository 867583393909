import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default CustomPurposesPageQuickTips;

function CustomPurposesPageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          See the in app Tutorial for a quick walkthrough of classifying with
          Custom Purposes on the mobile app:
          <br />
          <br />
          1. In the mobile app, navigate to the Menu
          <br />
          2. Tap "Help"
          <br />
          3. Tap "Getting Started" and follow the interactive guide
          <br />
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          Use{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/208173686-How-to-Search-for-Drives">
            Custom Purposes to find specific drives
          </QuickTipsLink>{" "}
          on the dashboard and to{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/203531259-How-to-Create-Reports">
            create custom reports
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
