import "src/models/typings";

import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";

import { BlogSource, trackBlogSelected } from "src/services/tracking";

import { ADD_INTEGRATION, EDIT_INTEGRATION } from "src/graphql/mutations";

import Text from "../elements/Text";

export default UpdateConcurIntegrationModal;

export const ELEMENT_ID = "UPDATE_CONCUR_INTEGRATION_MODAL";
registerElement(ELEMENT_ID, UpdateConcurIntegrationModal);

const CONCUR_SECRETS = [
  {
    name: "client_id",
    label: "Client ID",
    type: "text",
    placeholder: "9yzmdcxs-e055-52ta-7l1a-65z4opqockvz",
    required: true,
  },
  {
    name: "client_secret",
    label: "Client Secret",
    type: "text",
    placeholder: "5kem4yq6-1426-4837-2093-bnr9sinq041h",
    required: true,
  },
  {
    name: "refresh_token",
    label: "Refresh Token",
    type: "text",
    placeholder: "gx1gt45pf57y3onxyba63mm672mehw",
    required: true,
  },
  {
    name: "api_url",
    label: "API URL Prefix",
    type: "text",
    placeholder: "https://www-us2.api.concursolutions.com",
    required: true,
  },
];

function UpdateConcurIntegrationModal({
  onClose,
  onSuccess,
  onError,
  isEdit,
  integration,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [editIntegration, { loading: editIntegrationLoading }] = useMutation(
    EDIT_INTEGRATION,
    { notifyOnNetworkStatusChange: true }
  );

  const [addIntegration, { loading: addIntegrationLoading }] = useMutation(
    ADD_INTEGRATION,
    { notifyOnNetworkStatusChange: true }
  );

  const submitLoading = editIntegrationLoading || addIntegrationLoading;

  const initialIntegrationSecrets =
    isEdit &&
    integration?.secrets?.reduce((acc, secret) => {
      return {
        ...acc,
        [secret.key]: secret.value,
      };
    }, {});

  const [secrets, setSecrets] = useState(initialIntegrationSecrets || {});

  const handleSubmit = async (e) => {
    e.preventDefault();

    const variables = {
      integrationId: integration?.id,
      integration: {
        type: isEdit ? undefined : "concur",
        secrets: Object.keys(secrets).map((key) => ({
          key,
          value: secrets[key],
        })),
      },
    };

    const errorMessage =
      "Something doesn't match. Please check one or more of the items above";

    try {
      const { data } = isEdit
        ? await editIntegration({ variables })
        : await addIntegration({ variables });

      const responseKey = isEdit ? "editIntegration" : "addIntegration";

      if (data?.[responseKey]?.code === 200) {
        return onSuccess(
          "Your Concur integration has been successfully updated"
        );
      }
      onError?.(errorMessage);
      setErrorMessage(errorMessage);
    } catch (error) {
      console.log(error);
      setErrorMessage(errorMessage);
      onError?.(errorMessage);
    }
  };

  const handleSecretChange = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSecrets({ ...secrets, [e.target.name]: e.target.value });
  };

  const isValid = CONCUR_SECRETS.filter(({ required }) => required).every(
    ({ name }) => Boolean(secrets[name])
  );

  return (
    <Modal closable className="w-[540px]" onClose={onClose}>
      <>
        <h4 className="text-center">
          {isEdit ? "Edit Concur Integration" : "Enable Concur Integration"}
        </h4>
        <div className="mt-3">
          <Text paragraph center>
            You'll need your Client Web Services (CWS) OAuth2.0 credentials. If
            you have questions about how to get these, check out{" "}
            <a
              href="https://support.mileiq.com/hc/en-us/articles/11235633044500"
              target="_blank"
              className="underline"
              onClick={() => {
                trackBlogSelected({
                  src: BlogSource.ENABLE_CONCUR_INTEGRATION,
                });
              }}
            >
              this quick guide
            </a>
            .
          </Text>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col mt-8">
          <div className="flex flex-col gap-8">
            {CONCUR_SECRETS.map(({ name, label, type, placeholder }) => (
              <Input
                key={name}
                label={label}
                name={name}
                type={type}
                placeholder={placeholder}
                value={secrets[name]}
                onChange={handleSecretChange}
              />
            ))}
            {errorMessage && (
              <Text regular left md color="red">
                {errorMessage}
              </Text>
            )}
          </div>
          <div className="flex gap-4 mt-8">
            <Button
              lg
              className="flex-grow"
              onClick={onClose}
              secondary
              type="button"
            >
              Cancel
            </Button>
            <Button
              lg
              className="flex-grow"
              loading={submitLoading}
              type="submit"
              disabled={!isValid}
            >
              {submitLoading ? "Loading" : "Save"}
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
}
