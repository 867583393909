import { format } from "date-fns";
import React, { useContext, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import FilterButton from "src/components/elements/FilterButton";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import DateRangePicker, {
  DATE_RANGES,
  DateRangeData,
} from "src/components/blocks/DateRangePicker";

import { getConsolidatedReportDownloadLink } from "src/services/reports";
import {
  consolidatedReportFormats,
  consolidatedReportTimeframes,
  trackTeamsConsolidatedReportCompleted,
} from "src/services/tracking";
import { downloadFileFromLink } from "src/services/utils";

import { useAppFlash } from "../context/Flash";
import { UserDataContext } from "../context/UserContext";

export const ELEMENT_ID = "CONSOLIDATED_REPORTS";
registerElement(ELEMENT_ID, ConsolidatedReports);

const DEFAULT_RANGE_TYPE = DATE_RANGES.LAST_30_DAYS;

const REPORT_FORMATS = {
  WEEKLY: "Weekly",
  BIWEEKLY: "Biweekly",
  MONTHLY: "Monthly",
};

function ConsolidatedReports({ onClose }) {
  const [selectedRangeTypeFilter, setSelectedRangeTypeFilter] =
    useState(DEFAULT_RANGE_TYPE);
  const [selectedRangeDatesFilter, setSelectedRangeDatesFilter] = useState(
    DateRangeData[DEFAULT_RANGE_TYPE].getDates()
  );
  const [reportFormat, setReportFormat] = useState(REPORT_FORMATS.WEEKLY);
  const [isDownloading, setIsDownloading] = useState(false);
  const reportTimeframeRef = useRef();
  const reportFormatRef = useRef();
  const modalRef = useRef();

  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const { flash } = useAppFlash();

  const handleDateRangeFilterSelect = (rangeKey, range) => {
    setSelectedRangeTypeFilter(rangeKey);
    setSelectedRangeDatesFilter(range);
  };

  const handleDownloadReport = async () => {
    setIsDownloading(true);

    try {
      const filters = new URLSearchParams();
      filters.set("time_period", reportFormat.toLowerCase());
      filters.set(
        "drives_from_date",
        format(selectedRangeDatesFilter[0], "yyyy-MM-dd")
      );
      filters.set(
        "drives_to_date",
        format(selectedRangeDatesFilter[1], "yyyy-MM-dd")
      );

      const { link } = await getConsolidatedReportDownloadLink(
        team.id,
        filters
      );

      const fileName =
        link?.split?.(".csv")?.[0]?.split?.("/")?.pop?.() ||
        "consolidated_report";

      await downloadFileFromLink({
        link,
        fileName,
        fileExtension: "csv",
      });

      const avoTimeFrame =
        selectedRangeTypeFilter === DATE_RANGES.YEAR_MONTH
          ? consolidatedReportTimeframes.MONTH
          : consolidatedReportTimeframes[selectedRangeTypeFilter];

      trackTeamsConsolidatedReportCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subscriptionId: userData.subscriptionType,
        format: consolidatedReportFormats[reportFormat.toUpperCase()],
        timeFrame: avoTimeFrame,
      });

      onClose();
    } catch (error) {
      const errorMessage =
        "Something went wrong while generating consolidated reports";

      console.log(errorMessage, error);

      flash(errorMessage, {
        type: FlashTypes.ERROR,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDropdownOpen = () => {
    if (modalRef?.current) {
      const scrollHeight = modalRef.current.scrollHeight;
      const modalHeight = modalRef.current.offsetHeight;
      modalRef.current.scrollTop = scrollHeight - modalHeight - 25;
    }
  };

  const handleDropdownClose = () => {
    if (modalRef?.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  return (
    <Modal
      closable
      show
      onClose={onClose}
      className="w-[460px]"
      additionalContentSize={300}
      modalRef={modalRef}
      contentClassName="p-[30px]"
    >
      <h3 className="text-center">Create a consolidated report</h3>
      <Text paragraph center className="mt-2 text-15">
        This will be an aggregated report of all your team's drives
      </Text>
      <div className="mt-7 flex flex-col gap-7">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-1">
            <Text paragraph>Report timeframe</Text>
            <Icon
              iconRef={reportTimeframeRef}
              name="info"
              className="cursor-pointer"
            />
            <Tooltip
              triggerRef={reportTimeframeRef}
              className="py-2.5 px-3"
              offset={{ y: 20, x: 0 }}
              width={240}
            >
              <Text md>Time-frame during which drives took place</Text>
            </Tooltip>
          </div>
          <div className="w-full">
            <DateRangePicker
              selectedRangeType={selectedRangeTypeFilter}
              selectedRangeDates={selectedRangeDatesFilter}
              onSelect={handleDateRangeFilterSelect}
              onClose={handleDropdownClose}
              onOpen={handleDropdownOpen}
              excludeRangeTypes={[DATE_RANGES.CUSTOM]}
              label="Drives"
              startAsPristine={false}
              triggerClassName="bg-blue-mediumLight border-blue border-2"
            />
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-1">
            <Text paragraph>Report format</Text>
            <Icon
              iconRef={reportFormatRef}
              name="info"
              className="cursor-pointer"
            />
            <Tooltip
              triggerRef={reportFormatRef}
              className="py-2.5 px-3"
              offset={{ y: 20, x: 0 }}
              width={240}
            >
              <Text md>Choose the type of report you need</Text>
            </Tooltip>
          </div>
          <div className="flex w-full gap-3">
            {Object.values(REPORT_FORMATS).map((format) => (
              <FilterButton
                key={format}
                label={format}
                className="w-1/3"
                active={reportFormat === format}
                onClick={() => setReportFormat(format)}
              />
            ))}
          </div>
        </div>
      </div>
      <Button
        className="w-full mt-7"
        loading={isDownloading}
        disabled={isDownloading}
        onClick={handleDownloadReport}
      >
        Download report
      </Button>
    </Modal>
  );
}
