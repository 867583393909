import { NetworkStatus, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";

import Loader from "src/components/elements/Loader";

import { useFlagsMethods } from "src/hooks/useFlags";
import useQueryParams from "src/hooks/useQueryParams";

import User, { USER_TYPES } from "src/models/user";

import { tryRefreshToken } from "src/services/auth";
import { setUser as setUserForErrorReporting } from "src/services/error-reporting";
import { getPurposesDataForCategory } from "src/services/purposes";
import { getContactSharedReports } from "src/services/reports";
import {
  getAccessToken,
  getPendingUserSubscription,
  removeAccessToken,
  removeCheckoutInProgress,
  removePendingUserSubscription,
  removeRefreshToken,
} from "src/services/storage";
import {
  UpgradeSource,
  init as initTracking,
  trackSubscriptionUpdateCompleted,
  trackUpgradeCompleted,
} from "src/services/tracking";
import { COUNTRIES, parseJwt, timeout } from "src/services/utils";

import {
  GET_ME,
  GET_USER_DATA,
  GET_USER_SUBSCRIPTION_DATA,
} from "src/graphql/queries";
import { useProfitwell } from "src/vendors/profitwell";

import { isPublicRoute } from "./ApolloContext";

const UserContext = React.createContext({});

export function useUser() {
  return useContext(UserContext);
}

export default UserContext;

export const USER_NETWORK_STATES = {
  LOADING: "LOADING",
  IDLE: "IDLE",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export function UserProvider({ children }) {
  const flagsMethods = useFlagsMethods();
  const [user, setUser] = useState(null);
  const [hasSharedReports, setHasSharedReports] = useState(false);
  const [userNetworkState, setUserNetworkState] = useState(
    USER_NETWORK_STATES.IDLE
  );
  const profitwell = useProfitwell();
  const qp = useQueryParams();
  const checkoutStatus = qp.get("checkout-status") || qp.get("checkout_status");

  const [meQuery] = useLazyQuery(GET_ME, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const refreshUser = async () => {
    try {
      // If user is coming from Stripe checkout, refresh their token
      // so following requests consider them with the new subscription
      if (checkoutStatus) {
        await tryRefreshToken();
      }

      const token = getAccessToken();
      if (!token) {
        const goTo = window.location.pathname + window.location.search;
        const url = `/login?goTo=${encodeURIComponent(goTo)}`;
        return (window.location.href = url);
      }

      setUserNetworkState(USER_NETWORK_STATES.LOADING);

      const user = await meQuery({
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      }).then((res) => {
        return new User(res?.data?.me);
      });

      if (!user) {
        setUserNetworkState(USER_NETWORK_STATES.IDLE);
        removeRefreshToken();
        return removeAccessToken();
      }

      const { team_admin, team_driver, subscription_type } = parseJwt(token);
      user.isAdmin = team_admin;
      user.isDriver = team_driver;
      user.subscriptionType = subscription_type;
      user.role = team_admin
        ? team_driver
          ? USER_TYPES.ADMIN_DRIVER
          : USER_TYPES.ADMIN
        : USER_TYPES.DRIVER;

      setUser(user);

      setUserNetworkState(USER_NETWORK_STATES.LOADED);

      getContactSharedReports()
        .then((res) => {
          setHasSharedReports(res.length > 0);
        })
        .catch((err) => {
          console.log(err);
          setHasSharedReports(false);
        });

      initTracking(user);

      flagsMethods.identify(user);

      profitwell.identify({ email: user.email });

      setUserForErrorReporting({
        id: user.id,
        email: user.email,
        name: user.email,
      });
    } catch (err) {
      setUserNetworkState(USER_NETWORK_STATES.ERROR);
      console.log(err);

      removeRefreshToken();
      removeAccessToken();
    }
  };

  useEffect(() => {
    if (isPublicRoute(window.location.pathname)) {
      return;
    }

    refreshUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userNetworkState,
        refreshUser,
        hasSharedReports,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserDataContext = React.createContext(null);
let retries = 1;
const maxRetries = 4;

export function useUserData() {
  return useContext(UserDataContext);
}

export function UserDataProvider({ children }) {
  const qp = useQueryParams();
  const checkoutStatus = qp.get("checkout-status") || qp.get("checkout_status");
  const refetchForPendingSub = useRef(false);
  const userDataQuery = useQuery(GET_USER_DATA, {
    notifyOnNetworkStatusChange: true,
  });
  const subscriptionDataQuery = useQuery(GET_USER_SUBSCRIPTION_DATA, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loading =
    userDataQuery.networkStatus === NetworkStatus.loading ||
    subscriptionDataQuery.networkStatus === NetworkStatus.loading; // initial load, not refetch

  // HACK: retry few times to make sure user really doesn't have a subscription
  // WHY: there might be delay(~10 secs) after user buys a sub via stripe checkout
  // and our system actually knows about it(updated via webhook by stripe)
  const pendingSubData = getPendingUserSubscription();
  if (pendingSubData) {
    if (checkoutStatus === "cancel") {
      // user clicked back to mileiq on checkout page
      // so no sub was purchased
      removePendingUserSubscription();
    } else if (!loading && !refetchForPendingSub.current) {
      if (retries < maxRetries) {
        refetchForPendingSub.current = true;
        timeout(4000).then(() => {
          subscriptionDataQuery
            .refetch()
            .then(({ data }) => {
              if (
                data?.getSubscriptionData?.paymentService &&
                data?.getSubscriptionData?.status
              ) {
                // we got sub data from BE so no longer need to query(refetch) for it
                removeCheckoutInProgress();
                removePendingUserSubscription();
                if (pendingSubData?.isNew) {
                  trackUpgradeCompleted({
                    src: UpgradeSource.SUBSCRIPTION_PAGE,
                    subType: data.getSubscriptionData.type,
                  });
                } else {
                  trackSubscriptionUpdateCompleted({
                    subType: data.getSubscriptionData.type,
                    service: data.getSubscriptionData.paymentService,
                  });
                }
                return userDataQuery.refetch();
              }
            })
            .finally(() => {
              refetchForPendingSub.current = false;
              retries++;
            });
        });
      } else {
        removeCheckoutInProgress();
        // max retries reached, so no need to query for it anymore
        // but leave space for user to retry the process from the beginning
        removePendingUserSubscription();
        retries = 0;
      }
    }
  }

  if (loading || refetchForPendingSub.current) return <Loader />;

  const userData = {
    ...userDataQuery.data?.userData,
    currency:
      userDataQuery.data?.userData?.country === COUNTRIES.GB ? "£" : "$",
    subscriptionData: {
      ...subscriptionDataQuery.data?.getSubscriptionData,
    },
  };

  userData.isTeamsUser = [9, 10, 11].includes(userData.subscriptionType);

  function getPurposeLabel(purposeId, category) {
    return getPurposesDataForCategory(userData.purposes, category).find(
      (p) => p.id === purposeId
    )?.label;
  }

  const refreshSubscription = async () => {
    const newUserData = await userDataQuery.refetch();
    await subscriptionDataQuery.refetch();
    return newUserData?.data?.userData;
  };

  return (
    <UserDataContext.Provider
      value={{
        userData,
        getPurposeLabel,
        refreshSubscription,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}
