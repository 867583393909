import "src/models/typings";

import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import { UserDataContext } from "src/components/context/UserContext";

import Modal from "src/components/elements/Modal";

import { getRefetchParams } from "src/services/apollo-utils";
import { FrequentDrivePromptAction } from "src/services/tracking";

import {
  DEFER_AUTOCLASSIFICATION,
  START_AUTOCLASSIFICATION,
} from "src/graphql/mutations";

import Button from "../elements/Button";
import Checkbox from "../elements/Checkbox";
import Icon from "../elements/Icon";
import Text from "../elements/Text";

export default AutoClassifyPromptModal;

export const ELEMENT_ID = "AUTOCLASSIFY_PROMPT_MODAL";
registerElement(ELEMENT_ID, AutoClassifyPromptModal);

const mapPrefs = {
  width: 335,
  height: 160,
};

const imgContainerStyles = {
  width: mapPrefs.width + "px",
  height: mapPrefs.height + "px",
};

/**
 *
 * @param {{ drive: Drive }} props
 * @returns
 */
function AutoClassifyPromptModal({ drive, onClose }) {
  const { getPurposeLabel } = useContext(UserDataContext);
  const [shouldClassifyOther, setShouldClassifyOther] = useState(true);
  const [startAutoClassificationMutFn, startAutoClassificationMutState] =
    useMutation(START_AUTOCLASSIFICATION, {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getDrivesTotals"],
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    });
  const [deferAutoclassifyMutFn, deferAutoclassifyMutState] = useMutation(
    DEFER_AUTOCLASSIFICATION,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserDrives", "getDrivesTotals"],
      onQueryUpdated: (q) => {
        q.refetch(getRefetchParams(q));
      },
    }
  );

  const drivesToClassify = drive.routeUnclassifiedDriveCount;

  const handleNotNowClicked = async () => {
    await deferAutoclassifyMutFn({
      variables: {
        data: {
          routeId: drive.routeId,
        },
      },
    });
    onClose({
      tracking: {
        promptAction: FrequentDrivePromptAction.NOT_NOW,
        shouldIncludeUnclassidied: shouldClassifyOther,
      },
    });
  };

  const handleYesClicked = async () => {
    await startAutoClassificationMutFn({
      variables: {
        data: {
          backfill: shouldClassifyOther,
          routeId: drive.routeId,
        },
      },
    });
    onClose({
      tracking: {
        promptAction: FrequentDrivePromptAction.AUTO_CLASSIFY,
        shouldIncludeUnclassidied: shouldClassifyOther,
      },
    });
  };

  const handleDissmiss = () => {
    onClose({
      tracking: {
        promptAction: FrequentDrivePromptAction.DISMISSED,
        shouldIncludeUnclassidied: shouldClassifyOther,
      },
    });
  };

  const startLocName = drive.startLocation.displayName;
  const endLocName = drive.endLocation.displayName;

  return (
    <Modal
      closable
      show
      className="w-[750px]"
      onClose={handleDissmiss}
      testId="autoclassify-prompt-modal"
    >
      <div className="flex flex-col gap-[30px]">
        <h4>Auto-classify drives like this?</h4>
        <div className="flex items-center gap-4">
          <div>
            <div style={imgContainerStyles}>
              <img
                className="w-full h-full rounded border border-border-1"
                src={drive.map?.startUrl}
              />
            </div>
            <div className="flex items-center gap-2 mt-3">
              <i className="inline-block w-[12px] h-[12px] rounded-full border-4 border-[#117E32]" />
              <Text bold>{startLocName}</Text>
            </div>
          </div>
          <div>
            <div style={imgContainerStyles}>
              <img
                className="w-full h-full rounded border border-border-1"
                src={drive.map?.endUrl}
              />
            </div>
            <div className="flex items-center gap-2 mt-3">
              <i className="inline-block w-[12px] h-[12px] rounded-2 border-4 border-[#FF480E]" />
              <Text bold>{endLocName}</Text>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-5 bg-bone-white rounded px-8 py-6">
          <Icon name="auto-classification" />
          <Text>
            From now on the drives from <Text bold>{startLocName} </Text> to{" "}
            <Text bold>{endLocName}</Text> will automatically be classified as{" "}
            <Text bold color="blue">
              {getPurposeLabel(drive.purpose.id, drive.purpose.category)}
            </Text>
          </Text>
        </div>
        {drivesToClassify > 3 ? (
          <div className="py-4 border-y border-border-1">
            <Checkbox
              checked={shouldClassifyOther}
              onChange={setShouldClassifyOther}
              label={
                <Text
                  semibold
                >{`Include ${drivesToClassify} unclassified drives`}</Text>
              }
            />
          </div>
        ) : null}
        <div className="flex gap-3 items-center justify-end">
          <Button
            secondary
            onClick={handleNotNowClicked}
            disabled={
              deferAutoclassifyMutState.loading ||
              startAutoClassificationMutState.loading
            }
            loading={deferAutoclassifyMutState.loading}
          >
            Not now
          </Button>
          <Button
            onClick={handleYesClicked}
            disabled={
              startAutoClassificationMutState.loading ||
              deferAutoclassifyMutState.loading
            }
            loading={startAutoClassificationMutState.loading}
          >
            Yes, Auto-classify
          </Button>
        </div>
      </div>
    </Modal>
  );
}
