import e, { OPTIONS as T } from "../../managers/braze-instance.js";
export function attachCSS(n, t, o) {
  const c = n || document.querySelector("head"),
    s = `ab-${t}-css-definitions-${"4.5.0".replace(/\./g, "-")}`,
    a = c.ownerDocument || document;
  if (null == a.getElementById(s)) {
    const n = a.createElement("style");
    (n.innerHTML = o), (n.id = s);
    const t = e.nn(T.ao);
    null != t && n.setAttribute("nonce", t), c.appendChild(n);
  }
}
