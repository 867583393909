import * as React from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";

export function FeaturingTimelineTemplate({
  title,
  items,
  illustration,
  onBack,
  primaryCTA,
  secondaryCTA,
}) {
  return (
    <div className="bg-[#D6F2FF] w-full h-full flex md:items-center md:justify-center">
      <div className="bg-white w-full h-full md:w-auto md:h-auto md:rounded-24 shadow-lg py-24 pb-12 px-8 md:px-32 flex-shrink-0 max-w-[992px] my-0 md:mx-[80px]">
        {onBack && (
          <Button
            link
            className="opacity-50 py-4 pr-4 mb-6 h-[55px] text-[15px] no-underline"
            onClick={onBack}
          >
            <Icon name="arrow-left" /> Back
          </Button>
        )}
        {title && <h2 className="mt-[15px]">{title}</h2>}
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <ul className="mt-[20px] ">
              {items?.map((item, idx) => (
                <li
                  key={item.title}
                  className="flex flex-col items-start pb-[15px] mb-[7px] laptop:mb-[9px]"
                >
                  <div className="flex items-center justify-center">
                    <h4 className="font-normal text-15 ml-[33px] relative">
                      <span
                        className={`w-4 h-4 rounded-full mr-4 block absolute left-[-33px] border border-blue border-2 ${
                          item.isActive ? "bg-blue" : "bg-white"
                        }`}
                      ></span>
                      {typeof item.title === "function"
                        ? item.title()
                        : item.title}
                    </h4>
                  </div>
                  <p
                    className={`text-13 opacity-50 ${
                      idx < items.length - 1
                        ? "border-l border-l-blue border-l-2"
                        : ""
                    } ml-[33px] pl-6 left-[-26px] top-[-5px] mb-[-30px] pt-3 pb-6 relative`}
                  >
                    {typeof item.subtitle === "function"
                      ? item.subtitle()
                      : item.subtitle}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="overflow-hidden rounded-20 w-full h-[200px] md:h-auto md:w-[310px] flex justify-center items-center">
            {illustration}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center py-8 mt-8 gap-4 ">
          {secondaryCTA && (
            <Button
              secondary
              className="px-20 h-[55px] text-[15px]"
              onClick={secondaryCTA.onClick}
            >
              {secondaryCTA.label}
            </Button>
          )}
          <div
            className={
              secondaryCTA ? "md:max-w-[310px]" : "w-full md:max-w-[360px]"
            }
          >
            {primaryCTA && (
              <Button
                className="w-full px-20 h-[55px] text-[15px]"
                onClick={primaryCTA.onClick}
              >
                {primaryCTA.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
