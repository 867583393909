import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import BestExperienceModal from "src/components/modals/BestExperience";

import { getSeenBestExpModal, setSeenBestExpModal } from "src/services/storage";

export default BestExperienceNotification;

function BestExperienceNotification() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSeenBestExpModal();
    setShow(false);
  };
  const check = function () {
    if (window.matchMedia("(max-width: 992px)")?.matches) {
      if (!getSeenBestExpModal()) setShow(true);
    } else {
      setShow(false);
    }
  };
  const portalId = "portalBestExperience";
  let el = document.getElementById(portalId);
  if (!el) {
    el = document.createElement("div");
    el.id = portalId;
    el.style.position = "relative";
    el.style.zIndex = 99999;
    document.body.appendChild(el);
  }
  useEffect(() => {
    check();
    window.addEventListener("resize", check);
    return () => {
      window.removeEventListener("resize", check);
    };
  }, []);
  if (!show) return null;
  return ReactDOM.createPortal(
    <BestExperienceModal onClose={handleClose} />,
    el
  );
}
