const De = {
    CustomEvent: "ce",
    Pr: "p",
    xl: "pc",
    cc: "ca",
    za: "i",
    qs: "ie",
    T: "cci",
    W: "ccic",
    _: "ccc",
    L: "ccd",
    Ch: "ss",
    dh: "se",
    gi: "si",
    pi: "sc",
    mi: "sbc",
    ui: "sfe",
    ro: "iec",
    Ul: "lr",
    Ol: "uae",
    U: "ci",
    F: "cc",
    Bl: "lcaa",
    Wl: "lcar",
    Fn: "inc",
    Un: "add",
    En: "rem",
    An: "set",
    Gl: "sgu"
  },
  Be = { Fr: "feed_displayed", dc: "content_cards_displayed" };
export { De as EventTypes, Be as InternalEventTypes };
