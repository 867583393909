import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";

import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { ELEMENT_ID as ADD_VEHICLE_MODAL } from "src/components/modals/AddVehicle";

import useFlash from "src/hooks/useFlash";

import { trackVehicleUpdated } from "src/services/tracking";
import { getVehicleDisplayName } from "src/services/utils";

import { EDIT_VEHICLE } from "src/graphql/mutations";

import ActionBtn, { ACTIONS } from "./Actions";

export default VehiclesList;

export const VehicleTypeIcon = {
  automobile: "car-front",
  motorcycle: "motorbike",
  bicycle: "bicycle",
  "Car/Van": "car-front",
  Motorcycle: "motorbike",
  Bike: "bicycle",
};

function VehiclesList({ vehicles, testId }) {
  const { userData } = useContext(UserDataContext);
  const [editId, setEditId] = useState(null); // id of the vehicle being edited
  const [flash, Flash] = useFlash();

  const [editVehicleMutFn, editVehicleMutState] = useMutation(EDIT_VEHICLE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
    onCompleted: () => setEditId(null),
    onError: () => setEditId(null),
  });

  const vehicleModal = useElement(ADD_VEHICLE_MODAL, {
    props: {
      onClose: () => {
        vehicleModal.deactivate();
      },
      onSubmit: (id, { name, updated, hidden }) => {
        vehicleModal.deactivate();

        let msg = "";
        if (updated) {
          msg = `${name || "Vehicle"} details updated`;
        } else if (hidden) {
          msg = "Vehicle hidden";
        }
        if (msg) {
          flash(<Text>{msg}</Text>, {
            type: FlashTypes.SAVED,
          });
        }
      },
    },
  });

  const handleVehicleClicked = (vehicle) => {
    if (!vehicle.isActive) return;
    vehicleModal.activate({
      props: {
        vehicle,
      },
    });
  };

  const handleAction = async (vehicle, action) => {
    if (editVehicleMutState.loading) return;

    let msg;
    switch (action) {
      case ACTIONS.MAKE_PRIMARY:
        setEditId(vehicle.id);
        await editVehicleMutFn({
          variables: {
            data: { id: vehicle.id, isDefault: true },
          },
        });
        trackVehicleUpdated({
          isPriorityChanged: true,
          isPrimary: true,
          isVisible: true,
        });
        msg = `${getVehicleDisplayName(vehicle)} set as primary vehicle`;
        break;
      case ACTIONS.HIDE_VEHICLE:
        setEditId(vehicle.id);
        await editVehicleMutFn({
          variables: {
            data: { id: vehicle.id, isActive: false },
          },
        });
        trackVehicleUpdated({
          isVisibilityChanged: true,
          isVisible: false,
          isPriorityChanged: vehicle.id === userData.defaultVehicle, // if was default, then priority is changed to non-primary, coz hidden
          isPrimary: false,
        });
        msg = `Vehicle hidden`;
        break;
      case ACTIONS.RESTORE_VEHICLE:
        setEditId(vehicle.id);
        await editVehicleMutFn({
          variables: { data: { id: vehicle.id, isActive: true } },
        });
        trackVehicleUpdated({
          isVisibilityChanged: true,
          isVisible: true,
          isPrimary: false,
        });
        msg = "Vehicle restored";
        break;
    }

    if (msg) {
      flash(<Text>{msg}</Text>, {
        type: FlashTypes.SAVED,
      });
    }
  };

  const defaultVehId = userData.defaultVehicle;

  return (
    <div
      className="rounded border border-border-1 overflow-hidden shadow-sm [&_div:last-child]:border-none"
      data-testid={testId}
    >
      {vehicles.map((v) => {
        let itemClass =
          "vehicles-list-item h-[60px] px-3 border-b border-b-border-1 grid grid-cols-[35px_1fr_minmax(30px,auto)] gap-[25px] items-center";
        if (v.isActive) {
          itemClass += " cursor-pointer hover:bg-[#fbfafa]";
        }
        return (
          <div
            key={v.id}
            className={itemClass}
            onClick={() => handleVehicleClicked(v)}
          >
            <VehicleIcon active={v.isActive} vehicleType={v.type} />
            <div className="flex items-center gap-5">
              <VehicleTitle vehicle={v} />
              {v.id == defaultVehId && <PrimaryBadge />}
            </div>
            <ActionBtn
              vehicle={v}
              loading={editVehicleMutState.loading && editId == v.id}
              onAction={(action) => {
                handleAction(v, action);
              }}
            />
          </div>
        );
      })}
      {Flash}
    </div>
  );
}

function VehicleIcon({ active, vehicleType }) {
  const VehicleTypeIconColor = {
    automobile: "bg-[#ffe4b0]",
    motorcycle: "bg-[#C5E4FA]",
    bicycle: "bg-[#E8F8B4]",
  };
  let cls = "w-[35px] h-[35px] flex items-center justify-center rounded-10";

  if (active) cls += ` ${VehicleTypeIconColor[vehicleType]}`;
  else cls += " bg-beige-medium";
  return (
    <span className={cls}>
      <Icon
        name={VehicleTypeIcon[vehicleType] || "car-side"}
        color={active ? "black" : "black-op50"}
      />
    </span>
  );
}

function PrimaryBadge() {
  return (
    <span className="rounded-10 bg-black px-2 py-1">
      <Text md semibold className="text-white">
        Primary
      </Text>
    </span>
  );
}

function VehicleTitle({ vehicle }) {
  const { year } = vehicle;
  let title = (
    <Text semibold color={vehicle.isActive ? "black" : "black/70"}>
      {getVehicleDisplayName(vehicle)}
    </Text>
  );
  if (year)
    title = (
      <Text color={vehicle.isActive ? "black" : "black/70"}>
        {title}
        <span className="mx-2">•</span>
        <Text>{year}</Text>
      </Text>
    );
  return title;
}
