import e from "../managers/braze-instance.js";
import InAppMessage from "./models/in-app-message.js";
import pe from "./in-app-message-manager-factory.js";
import { logInAppMessageImpression } from "./log-in-app-message-impression.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as or } from "../common/constants.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageClick(o) {
  if (!e.rr()) return !1;
  if (!(o instanceof InAppMessage)) return r.D.error(or), !1;
  const s = pe.m().G(o, r.A.pi);
  if (s) {
    o.Cr() || logInAppMessageImpression(o);
    for (let r = 0; r < s.ve.length; r++)
      W.er().je(V.Ar, [o.triggerId], s.ve[r]);
  }
  return s.S;
}
