import { useContext } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import SadCloudyCar2 from "public/assets/img/illustrations/sad-cloudy-car-2.png";

import { useNotifications } from "../context/NotificationsContext";
import TeamContext from "../context/TeamContext";
import { useUserData } from "../context/UserContext";

export const DUNNING_UNABLE_TO_JOIN_TEAM_MODAL_ID =
  "DUNNING_UNABLE_TO_JOIN_TEAM_MODAL_ID";

registerElement(DUNNING_UNABLE_TO_JOIN_TEAM_MODAL_ID, DunningUnableToJoinTeam);

function DunningUnableToJoinTeam({ onClose }) {
  const { team } = useContext(TeamContext);
  const { notifications } = useNotifications();
  const {
    userData: { isPremium, subscriptionData },
  } = useUserData();

  const isFree = !isPremium || !subscriptionData.status;
  const teamOwnerEmail = (notifications?.admin || team?.owner)?.email;

  const AdminSnippet = () => (
    <>
      Please contact your admin at{" "}
      <a className="text-blue" href={`mailto:${teamOwnerEmail}`}>
        {teamOwnerEmail}
      </a>{" "}
      to resubscribe.
    </>
  );

  return (
    <Modal
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      passiveBackdrop
    >
      <div className="flex flex-col gap-[30px]">
        <div>
          <h5>Unable to join team</h5>
          <div className="mt-2.5 flex flex-col gap-2.5">
            <p>
              Unfortunately, your team's subscription is currently expired.{" "}
              {!isFree && <AdminSnippet />}
            </p>
            {isFree && (
              <p>
                You can still use MileIQ but you are on a free account that's
                limited to 40 drives limit per month until your admin
                resubscribes. <AdminSnippet />
              </p>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <img
            src={SadCloudyCar2}
            alt="Illustration of a sad car"
            className="h-[213px]"
          />
        </div>
        <div className="flex items-center gap-2.5 justify-end">
          <Button
            className="font-medium min-w-[120px]"
            type="submit"
            onClick={onClose}
          >
            {isFree ? "Get started with free account" : "Got it"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
