import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useNotifications } from "src/components/context/NotificationsContext";
import { useUser } from "src/components/context/UserContext";

import MiqLoader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import { useFlagsMethods } from "src/hooks/useFlags";

import { METRIC_EVENTS } from "src/services/feature-flags";
import { prepareTeamsOnboardingUrl } from "src/services/utils";

import CarWithDog from "public/assets/img/car-with-dog.svg";
import MiqLogo from "public/assets/img/miq-logo-black-orange.svg";

import UsageIntentPicker from "./UsageIntentPicker";

export default OnboardingPage;

function OnboardingPage() {
  const history = useHistory();
  const me = useUser()?.user;
  const [showRedirecting, setShowRedirecting] = useState(false);

  const { track: flagsTrack, flush: flagsFlush } = useFlagsMethods();

  const {
    notifications,
    called: notificationsCalled,
    loading: isLoadingNotifications,
  } = useNotifications();
  const hasPendingInvite = notifications?.invite_link;

  if (!me?.id) return null;

  function handleStepBack() {
    history.goBack();
  }

  function handleBackToDashboard() {
    handleRedirecting("/get-the-app", "Taking you to the Driver Dashboard");
  }

  function handleUpdateIntent(intent) {
    if (intent === "standalone") {
      handleBackToDashboard();
    } else {
      window.location.href = prepareTeamsOnboardingUrl();
    }
  }

  function handleRedirecting(
    url = "/",
    title = "Loading",
    subtitle = "One second while we get you set up...",
    timeout = 2000
  ) {
    setShowRedirecting({ url, title, subtitle });
    handleRedirect(timeout, url);
  }

  function handleRedirect(timeout = 0, url = "/") {
    // this is for Tinuiti TV ads pixel
    setTimeout(() => {
      // this is for Tinuiti TV ads pixel
      setTimeout(() => {
        flagsTrack(METRIC_EVENTS.SIGNUP_COMPLETED);
        flagsFlush(() => {
          setTimeout(() => {
            window.location.href = url;
          }, timeout);
        });
      }, 200);
    }, 200);
  }

  if (hasPendingInvite) {
    window.location.href = "/user/teams";
  }

  if (!notificationsCalled || isLoadingNotifications || hasPendingInvite) {
    return <MiqLoader />;
  }

  return (
    <div className="page-onboarding page-onboarding-teams bg-white w-screen h-screen relative">
      <span className="logo absolute top-8 left-8">
        <MiqLogo />
      </span>
      {showRedirecting ? (
        <div className="text-center flex flex-col items-center max-w-[540px] m-auto h-full justify-center">
          <h2 className="mt-6 whitespace-pre-line">{showRedirecting.title}</h2>
          <Text paragraph className="mt-3 text-black/70">
            {showRedirecting.subtitle}
          </Text>
          <div className="mt-14">
            <CarWithDog />
          </div>
        </div>
      ) : (
        <UsageIntentPicker
          onSubmit={handleUpdateIntent}
          onBack={handleStepBack}
          onSkip={handleBackToDashboard}
        />
      )}
    </div>
  );
}
