import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import {
  DEFAULT_DATE_RANGE,
  useTeamDrivesSummary,
} from "src/components/context/TeamDrivesSummaryContext";

import MobMenu from "src/components/elements/MobMenu";

import { CustomDateRangePickerLabel } from "src/components/blocks/dates/CustomDateRangePickerLabel";
import DateRangePicker, {
  PICKER_TYPE,
} from "src/components/blocks/dates/DateRangePicker";
import FilterDrivers from "src/components/blocks/reports/teams/FilterDrivers";
import DownloadTeamDriverReport from "src/components/blocks/teams-drives/DownloadTeamDriverReport";
import TeamDrivesSummaryList from "src/components/blocks/teams-drives/TeamDrivesSummaryList";

import PageLayout from "src/components/PageLayout";

import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import {
  TeamDrivesDriversFilters,
  trackTeamsDrivesSummaryFiltered,
} from "src/services/tracking";

export default TeamDrivesSummary;

function TeamDrivesSummary() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { team } = useContext(TeamContext);
  const [trackingDriversFilter, setTrackingDriversFilter] = useState(null);
  const { checkAndHandleDunning } = useTeamsCTA();

  const {
    driverSummaries,
    exportDrives,
    teamMembers,
    selectedRangeDatesFilter,
    filteredDrivers,
    fetchTeamDrivesSummary,
    isGeneratingCSV,
    isGeneratingPDF,
    page,
    sortBy,
    order,
  } = useTeamDrivesSummary();

  useEffect(() => {
    fetchTeamDrivesSummary();
  }, [page, sortBy, order, selectedRangeDatesFilter, filteredDrivers]);

  const handleDateRangeFilterSelect = (
    dates,
    datePickerType,
    dateRangeType
  ) => {
    queryParams.set(
      "rangeDates",
      dates.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);

    const dateFilter =
      datePickerType === PICKER_TYPE.RANGE ? dateRangeType : datePickerType;

    trackTeamsDrivesSummaryFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      dateFilter,
      driversFilter: trackingDriversFilter,
    });

    history.replace({ search: queryParams.toString() });
  };

  const handleClearDateRangeFilter = () => {
    queryParams.set(
      "rangeDates",
      DEFAULT_DATE_RANGE.map((date) => new Date(date).getTime())
    );
    queryParams.set("page", 1);
    history.replace({ search: queryParams.toString() });
  };

  const handleDriverFilterSelect = (filteredDrivers) => {
    if (filteredDrivers.length) {
      queryParams.set(
        "drivers",
        filteredDrivers.map((driver) => driver.id)
      );
    } else {
      queryParams.delete("drivers");
    }

    queryParams.set("page", 1);

    let trackingDriversFilter = TeamDrivesDriversFilters.NONE;

    if (filteredDrivers.length === 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.INDIVIDUAL;
    } else if (filteredDrivers.length > 1) {
      trackingDriversFilter = TeamDrivesDriversFilters.MULTIPLE;
    }

    trackTeamsDrivesSummaryFiltered({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      driversFilter: trackingDriversFilter,
    });

    setTrackingDriversFilter(trackingDriversFilter);

    history.replace({ search: queryParams.toString() });
  };

  const handleOpenFilters = () => {
    const dunningStatus = checkAndHandleDunning();

    return dunningStatus === DUNNING_STATUS.EXPIRED;
  };

  async function handleExportDrives({ type }) {
    await exportDrives({ type, driverId: "all-drivers" });
  }

  return (
    <PageLayout className="page-drives-summary">
      <PageLayout.Main>
        <div className="h-full flex flex-col">
          <div className="p-5 page-header">
            <div className="flex justify-between flex-wrap">
              <div className="flex flex-col">
                <MobMenu />
                <h3 className="text-28">Drives and reports</h3>
                <p className="mt-2 text-black/80">
                  You can view and download all submitted drives from your team
                  here.
                </p>
              </div>
              <div
                className="h-full min-w-fit"
                data-chmln="download-driver-reports-button"
              >
                <DownloadTeamDriverReport
                  handleExportDrives={handleExportDrives}
                  isGeneratingCSV={isGeneratingCSV}
                  isGeneratingPDF={isGeneratingPDF}
                  drivers={driverSummaries?.data?.length}
                />
              </div>
            </div>
            <div className="flex items-center mt-4 gap-3">
              <DateRangePicker
                onOpen={handleOpenFilters}
                customLabel={CustomDateRangePickerLabel}
                onSelect={handleDateRangeFilterSelect}
                onClear={handleClearDateRangeFilter}
                defaultValue={DEFAULT_DATE_RANGE}
                initialValue={selectedRangeDatesFilter?.values}
                defaultColor="white"
                triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
                openTriggerClassName="border-blue hover:border-blue active:border-blue"
              />
              <FilterDrivers
                onOpen={handleOpenFilters}
                selected={filteredDrivers}
                drivers={teamMembers}
                onSelect={handleDriverFilterSelect}
                customLabel="All drivers"
              />
            </div>
          </div>
          <div className="m-5 rounded-[14px] border border-border-1 shadow-sm">
            <TeamDrivesSummaryList />
          </div>
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
}
