import differenceInSeconds from "date-fns/differenceInSeconds";

import Base from "src/models/base";
import DriveLocation from "src/models/location";
import Purpose from "src/models/purpose";

import { formatInTZ } from "src/services/utils";

export default class Drive extends Base {
  id = null;
  secondId = null;
  distance = null;
  roundTripNumber = null;
  /** @type Purpose */
  purpose = null;
  parkingFees = null;
  tollFees = null;
  potentialBusinessValue = null;
  potentialPersonalValue = null;
  startDate = null;
  /** @type DriveLocation */
  startLocation = null;
  endDate = null;
  /** @type DriveLocation */
  endLocation = null;
  stopDate = null;
  /** @type DriveLocation */
  stopLocation = null;
  stopDuration = 0;
  isAutoClassified = false;
  isAutoClassifiedWorkHours = false;
  isAutoClassifiedFrequentDrives = false;
  isAutoClassifiedFrequentDrivesOverriden = false;
  isReported = false;
  isRoundTrip = false;
  isRoundTripStop = false;
  timeZoneDiffInHours = 0;
  routeId = null;
  routeShouldAutoClassify = false;
  routeClassificationStreak = 0;
  routeClassificationsToPrompt = 9999;
  routeUnclassifiedDriveCount = 0;
  vehicleId = null;
  vehicleType = null;
  map = null;
  routeWaypoints = null;
  // value could be calculated on BE
  // if not set, we use potential values to calc it (see getter below)
  _value = null;

  constructor(data) {
    super("drive_model");
    if (data) {
      this.id = data.id || null;
      this.secondId = data.secondId || null;
      this.roundTripNumber = data.roundTripNumber || null;
      this.distance = data.distance;
      this.googleDistance = data.googleDistance || 0;
      this.parkingFees = data.parkingFees || 0;
      this.tollFees = data.tollFees || 0;
      this.notes = data.notes;
      this.potentialBusinessValue = data.potentialBusinessValue || null;
      this.potentialPersonalValue = data.potentialPersonalValue || null;
      this.purpose = new Purpose(data.purpose);
      this.startLocation = new DriveLocation(data.startLocation);
      this.endLocation = new DriveLocation(data.endLocation);
      this.stopLocation = data.stopLocation
        ? new DriveLocation(data.stopLocation)
        : null;
      this.stopDuration = data.stopLocation
        ? data.stopLocation.stopDuration
        : null;
      this.startDate = data.startDate ? new Date(data.startDate) : null;
      this.endDate = data.endDate ? new Date(data.endDate) : null;
      this.stopDate = data.stopDate ? new Date(data.stopDate) : null;
      this.isAutoClassified = !!data.isAutoClassified;
      this.isRoundTrip = !!data.isRoundTrip;
      this.isRoundTripStop = !!data.isRoundTripStop;
      this.isJoinedDrive = !!data.isJoinedDrive;
      this.isAutoClassifiedWorkHours = !!data.isAutoClassifiedWorkHours;
      this.isAutoClassifiedFrequentDrives =
        !!data.isAutoClassifiedFrequentDrives;
      this.isAutoClassifiedFrequentDrivesOverriden =
        !!data.isAutoClassifiedFrequentDrivesOverriden;
      this.isReported = !!data.isReported;
      this.timeZoneDiffInHours = data.timeZoneDiffInHours || 0;

      this.routeId = data.routeId || null;
      this.routeShouldAutoClassify = data.routeShouldAutoClassify || false;
      this.routeClassificationStreak = data.routeClassificationStreak || 0;
      this.routeClassificationsToPrompt =
        data.routeClassificationsToPrompt || 9999;
      this.routeUnclassifiedDriveCount = data.routeUnclassifiedDriveCount;

      this.state = data.state;

      this.vehicleId = data.vehicleId || null;
      this.vehicleType = data.vehicleType || null;
      this.map = data.map || null;
      this.routeWaypoints = data.routeWaypoints || null;

      this._value = data.value;
    }
  }

  get isUnclassified() {
    return this.purpose?.isUnclassified;
  }

  get isPersonal() {
    return this.purpose?.isPersonal;
  }

  get isBusiness() {
    return this.purpose?.isBusiness;
  }

  get value() {
    if (this._value) return this._value;

    if (this.isBusiness || this.isUnclassified) {
      return this.potentialBusinessValue?.default || 0;
    }

    if (this.isPersonal) {
      if (this.purpose.isMedical) {
        return this.potentialPersonalValue.medical || 0;
      }
      if (this.purpose.isCharity) {
        return this.potentialPersonalValue.charity || 0;
      }
      if (this.purpose.isMoving) {
        return this.potentialPersonalValue.moving || 0;
      }
    }

    return 0;
  }

  get dateFormatter() {
    const tz = this.timeZoneDiffInHours;
    return {
      format: (date, formatStr) => {
        return formatInTZ(date, formatStr, tz);
      },
    };
  }

  get formattedTimeRange() {
    if (this.startDate && this.endDate) {
      const formatter = this.dateFormatter;
      const s = formatter
        .format(this.startDate, "p")
        .replace(/(am|AM|pm|PM)/, "")
        .trim();
      const e = formatter.format(this.endDate, "p");

      return `${s} – ${e}`;
    }
    return "";
  }

  /**
   * @returns duration in seconds
   */
  get duration() {
    let dur = 0;

    if (this.startDate && this.endDate) {
      dur = Math.abs(differenceInSeconds(this.startDate, this.endDate));
    }
    return dur;
  }
}
