import React, { useEffect, useState } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import TimePicker from "src/components/elements/TimePicker";

import { WEEKDAYS } from "src/services/work-hours";

export const SimpleTimeSlotsByDay = ({ workHours, onChange, hasError }) => {
  const [timeStart, setTimeStart] = useState({});
  const [timeEnd, setTimeEnd] = useState({});

  useEffect(() => {
    setTimeStart(workHours.timeStart);
    setTimeEnd(workHours.timeEnd);
  }, []);

  const handleChange = (val, startOrEnd) => {
    switch (startOrEnd) {
      case "start":
        setTimeStart(val);
        break;
      case "end":
        setTimeEnd(val);
        break;
    }
    onChange(val, startOrEnd, workHours.index);
  };

  return (
    <div className="w-[547px] min-h-[60px] bg-beige rounded-[14px] flex items-center my-[10px] p-[10px] pl-[20px] justify-between">
      <Text paragraph lg semibold className="w-[90px] capitalize">
        {WEEKDAYS[0].substring(0, 3)}-{WEEKDAYS[4].substring(0, 3)}
      </Text>
      <div className="flex flex-col">
        <div className="flex">
          <TimePicker
            time={timeStart}
            className="w-[150px] bg-white [&_.miq-icon]:scale-90"
            contentType="separated"
            onUpdate={(value) => handleChange(value, "start")}
            hasError={Object.keys(hasError).length != 0}
            icon="auto-classified-work-hours"
          />
          <Icon name="arrow-right" color="black" className="" />
          <TimePicker
            time={timeEnd}
            className="w-[150px] bg-white [&_.miq-icon]:scale-90"
            contentType="separated"
            onUpdate={(value) => handleChange(value, "end")}
            hasError={Object.keys(hasError).length != 0}
            icon="auto-classified-work-hours"
          />
        </div>
        {Object.keys(hasError).length != 0 && (
          <Text lg color="red" className="ml-1 mt-2">
            The slot overlaps with another work shift
          </Text>
        )}
      </div>
    </div>
  );
};
