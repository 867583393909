import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Pivoted from "src/components/elements/Pivoted";
import SearchInput from "src/components/elements/SearchInput";
import Text from "src/components/elements/Text";

import {
  SearchSelectionType,
  trackSearchCompleted,
  trackSearchStarted,
} from "src/services/tracking";

import { SEARCH_SUGGESTIONS } from "src/graphql/queries";

export default Search;

const PLACE_OPTION_LI_CLASSNAME = "place-option-li";
const PLACE_OPTION_BTN_CLASSNAME = "place-option-btn";
const SUGGESTIONS_LIST_ID = "SUGGESTIONS_LIST_ID";

const REQUEST_DEBOUNCE_MS = 500;
let requestTimeout = null;

function isValidQuery(q) {
  return q?.trim() !== "";
}

function getLocationName(data) {
  const { isNamed, name, city, hood, state, country } = data;

  const locationName = isNamed
    ? name
    : [hood, city, state, country].filter((f) => !!f).join(", ");
  return locationName;
}

function getSubmitOption(data, query) {
  const locationName = getLocationName(data);
  const opt = { ...data };
  opt.query = locationName ? locationName : query;

  return opt;
}

function Search({ onSelectSuggestion }) {
  const { userData } = useContext(UserDataContext);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState(null);
  const { data: defaultSearchSuggestions, loading: loadingDefaults } = useQuery(
    SEARCH_SUGGESTIONS,
    {
      variables: {
        query: "",
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [fetchSuggestions] = useLazyQuery(SEARCH_SUGGESTIONS);

  const inpBlockRef = useRef();

  const shouldTrackSearchStartedRef = useRef(false);
  const focusIdx = useRef();

  const handleSelectSuggestions = (option) => {
    {
      let type;
      switch (true) {
        case option.isNotes:
          type = SearchSelectionType.NOTES;
          break;
        case option.isAll:
          type = SearchSelectionType.ALL_RESULTS;
          break;
        case option.isNamed:
          type = SearchSelectionType.NAMED_LOCATION;
          break;
        default:
          type = SearchSelectionType.GENERIC_ADDRESS;
          break;
      }
      trackSearchCompleted({
        subscriptionId: userData.subscriptionType,
        type,
      });
    }
    suggestionsList.deactivate();
    if (option.name) {
      setSelectedSuggestion(option);
      setSuggestions((suggestions) => {
        let locs = suggestions
          ? suggestions.locations
          : defaultSearchSuggestions?.searchSuggestions?.locations;
        if (!locs) return null;
        return { locations: locs.filter((l) => l.name === option.name) };
      });
    }
    setSearchQuery(option.query);
    onSelectSuggestion(option);
  };

  const suggestionsList = useElement(SUGGESTIONS_LIST_ID, {
    props: {
      trigger: inpBlockRef.current,
      onClose: () => suggestionsList.deactivate(),
      onSelect: handleSelectSuggestions,
      focusIdx: -1,
    },
  });

  const resetFocusIdx = () => {
    focusIdx.current = -1;
  };

  const handleBlur = (e) => {
    shouldTrackSearchStartedRef.current = false;
    // check if blur happend as a result of the click on one of the options in place autocomplete
    // !!! IMPORTANT:
    // !!! In Safari e.relatedTarget === null
    // !!! reason: Safari does not put focus on the <button> (even with tabindex) after it was clicked ,
    // !!! (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus)
    // !!! so, as a workaround we made surrounding <li> focusable with tabindex
    // !!! and we expect to be relatedTarget when option was clicked.
    // !!! in other browser <button> will be in e.relatedTarget

    const clList = e.relatedTarget?.classList;
    const isOptionClicked =
      clList?.contains(PLACE_OPTION_LI_CLASSNAME) || // Safari hack
      clList?.contains(PLACE_OPTION_BTN_CLASSNAME); // normal browsers

    if (!isOptionClicked) {
      suggestionsList.deactivate();
    }
  };

  const handleFocus = () => {
    shouldTrackSearchStartedRef.current = true;
    resetFocusIdx();
    suggestionsList.activate({
      props: {
        data: suggestions || defaultSearchSuggestions?.searchSuggestions,
        query: searchQuery,
        selected: selectedSuggestion,
        focusIdx: focusIdx.current,
      },
    });
  };

  const handleQueryChange = (v) => {
    if (shouldTrackSearchStartedRef.current) {
      trackSearchStarted({
        subscriptionId: userData.subscriptionType,
      });
      shouldTrackSearchStartedRef.current = false;
    }
    resetFocusIdx();
    clearTimeout(requestTimeout);

    const newSelected = null;
    requestTimeout = setTimeout(async () => {
      const res = await fetchSuggestions({
        variables: {
          query: v,
        },
      });
      const newSuggestions = isValidQuery(v)
        ? res?.data?.searchSuggestions
        : defaultSearchSuggestions?.searchSuggestions;
      setSuggestions(newSuggestions);

      const newProps = {
        data: newSuggestions,
        query: v,
        selected: newSelected,
      };
      if (!suggestionsList.open) {
        suggestionsList.activate({
          props: newProps,
        });
      } else {
        suggestionsList.updateProps(newProps);
      }
    }, REQUEST_DEBOUNCE_MS);

    setSelectedSuggestion(newSelected);
    setSearchQuery(v);
  };

  const handleClear = () => {
    resetFocusIdx();
    clearTimeout(requestTimeout);
    setSelectedSuggestion(null);
    setSearchQuery("");
    onSelectSuggestion(null);
    suggestionsList.deactivate();
    setSuggestions(defaultSearchSuggestions?.searchSuggestions);
    const newProps = {
      data: defaultSearchSuggestions?.searchSuggestions,
      query: "",
      selected: null,
    };
    suggestionsList.activate({
      props: newProps,
    });
  };

  const handleKeyboard = (e) => {
    if (e.code === "Escape") {
      e.preventDefault();
      handleClear();
      return;
    }

    if (!suggestionsList.open) return;

    const getNavData = () => {
      const props = suggestionsList.getProps();
      const numLocations = props?.data?.locations?.length || 0;
      let totalOptions = numLocations;
      if (selectedSuggestion) totalOptions = 1;
      else if (searchQuery) totalOptions += 2;
      return {
        totalOptions,
        locations: props?.data?.locations || [],
      };
    };

    const handleEnter = () => {
      const idx = focusIdx.current;

      if (idx < -1) return;

      if (idx == -1) {
        handleSelectSuggestions({ isAll: true, query: searchQuery });
      } else {
        const { totalOptions, locations } = getNavData();

        if (idx > totalOptions) return;

        const maxLocationIdx = locations.length - 1;
        let option = null;
        if (idx <= maxLocationIdx) {
          option = getSubmitOption(locations[idx], searchQuery);
        } else {
          if (idx == maxLocationIdx + 1) {
            option = { isNotes: true, query: searchQuery };
          } else if (idx == maxLocationIdx + 2) {
            option = { isAll: true, query: searchQuery };
          }
        }
        if (option) handleSelectSuggestions(option);
      }
    };

    const handleArrows = () => {
      const { totalOptions } = getNavData();

      let newIdx = focusIdx.current;
      switch (e.code) {
        case "ArrowUp":
          newIdx -= 1;
          if (newIdx < 0) newIdx = totalOptions - 1;
          break;
        case "ArrowDown":
          newIdx = (newIdx + 1) % totalOptions;
          break;
      }
      focusIdx.current = newIdx;
      suggestionsList.updateProps({
        focusIdx: focusIdx.current,
      });
    };

    switch (e.code) {
      case "Enter":
        e.preventDefault();
        handleEnter();
        break;
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault();
        handleArrows();
        break;
    }
  };

  return (
    <div ref={inpBlockRef} className="w-[500px]">
      <SearchInput
        inputClassName="drives-search-input"
        placeholder="Search in drives"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleQueryChange}
        onClear={handleClear}
        onKeyDown={handleKeyboard}
        disabled={loadingDefaults}
        value={searchQuery}
      />
    </div>
  );
}

registerElement(SUGGESTIONS_LIST_ID, Suggestions);
function Suggestions({
  trigger,
  query,
  data,
  selected,
  focusIdx,
  onSelect,
  onClose,
}) {
  const { locations } = data;

  return (
    <Pivoted
      pivot={trigger}
      offset={{ y: 5 }}
      onClickOutside={onClose}
      style={{ zIndex: 30000 }}
      matchPivotWidth
    >
      <div className="p-2 bg-white w-full rounded border border-border-1 shadow-md shadow-[#0003]">
        <ul className="m-0 p-0">
          {locations.map((loc, idx) => {
            const key = loc.locationId
              ? loc.locationId
              : `${idx}-${loc.city}-${loc.hood}-${loc.state}-${loc.country}-${loc.name}`;
            return (
              <Option
                key={key}
                query={query}
                data={loc}
                onSelect={onSelect}
                active={idx == focusIdx}
              />
            );
          })}
        </ul>
        {query && !selected ? (
          <>
            {locations.length > 0 ? (
              <div className="h-[1px] p-0 my-2 -mx-2 bg-border-1" />
            ) : null}
            <ul className="p-0 m-0">
              <Option
                data={{ isNotes: true }}
                query={query}
                onSelect={onSelect}
                active={locations.length == focusIdx}
              />
              <Option
                data={{ isAll: true, showPressEnterBadge: focusIdx == -1 }}
                query={query}
                onSelect={onSelect}
                active={locations.length + 1 == focusIdx}
              />
            </ul>
          </>
        ) : null}
      </div>
    </Pivoted>
  );
}

function Option({ data, query, active, onSelect }) {
  let icon;
  let text = "";
  if (data?.isAll) {
    text = (
      <Text semibold className="flex items-center">
        All matching results for "
        <span className="max-w-[190px] truncate">{query}</span>"
      </Text>
    );
    icon = "search-magnifier";
  } else if (data?.isNotes) {
    text = <Text semibold>Notes containing "{query}"</Text>;
    icon = "search-notes";
  } else {
    const { isNamed } = data;
    const locationName = getLocationName(data);

    let hlStart = 0;
    let hlLen = 0;
    const match = locationName.match(new RegExp(query, "i"));
    if (match) {
      hlStart = match?.index || 0;
      hlLen = match[0] ? match[0].length : 0;
    }
    const title = {
      start: locationName.slice(0, hlStart),
      highlighted: locationName.slice(hlStart, hlStart + hlLen),
      end: locationName.slice(hlStart + hlLen),
    };
    text = (
      <Text>
        {title.start}
        <Text bold>{title.highlighted}</Text>
        {title.end}
      </Text>
    );
    icon = isNamed ? "search-favorite" : "search-location-pin";
  }

  const handleSelect = () => {
    const opt = getSubmitOption(data, query);
    onSelect(opt);
  };

  return (
    <li
      className={`${PLACE_OPTION_LI_CLASSNAME} w-full p-[8px] laptop:p-[6px] rounded-8 hover:bg-beige cursor-pointer ${
        active ? "bg-beige" : ""
      }`}
      tabIndex="0"
    >
      <button
        className={`${PLACE_OPTION_BTN_CLASSNAME} w-full text-left`}
        onClick={handleSelect}
      >
        <div className="flex items-center">
          <Icon name={icon} />
          <span className="ml-[10px] mt-[2px] truncate">{text}</span>
          {data?.isAll && data?.showPressEnterBadge ? (
            <div className="shrink-0 ml-auto text-black/30">
              <span className="text-11">Press</span>{" "}
              <span className="text-11 py-[2px] px-[4px] border rounded-[4px]">
                Enter
              </span>
            </div>
          ) : null}
        </div>
      </button>
    </li>
  );
}
