import React from "react";

import CircleMetric from "src/components/elements/CircleMetric";

import { formatNumberWithSuffix, formatPercentage } from "src/services/utils";

export default TeamStat;

function TeamStat({ type, unit, label, value, graph }) {
  if (type === "percent" && graph) {
    return (
      <CircleMetric
        percentage={value * 100}
        radius={80}
        strokeWidth={12}
        title={label}
      />
    );
  }

  let labelText = "";
  let valueText = "0";

  switch (type) {
    case "distance":
      labelText = unit === "mi" ? label : label.replace("miles", "kilometers");
      valueText = formatNumberWithSuffix({ value, fractionDigits: 1 });
      break;
    case "currency":
      labelText = label;
      valueText = `${unit}${formatNumberWithSuffix({
        value,
        fractionDigits: 2,
      })}`;
      break;
    case "percent":
      labelText = label;
      valueText = formatPercentage({ percent: value * 100 });
      break;
    default:
      labelText = label;
      valueText = value;
      break;
  }

  return (
    <div className="text-center grid grid-rows-2 justify-center">
      <p className="text-[32px] font-medium tabular-nums text-black/80 leading-[125%]">
        {valueText}
      </p>
      <p className="text-12 text-black/60 max-w-[120px] leading-[120%] mt-1">
        {labelText}
      </p>
    </div>
  );
}
