import React, { useContext, useState } from "react";

import { useCustomRates } from "src/components/context/CustomRatesContext";
import TeamContext from "src/components/context/TeamContext";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  COUNTRIES,
  COUNTRIES_DATA,
  RATES_STATUS,
  ratesInMilesToKm,
} from "src/services/utils";

import RatesActions from "./RatesActions";
import RatesBadge from "./RatesBadge";

export default RatesList;

const COLUMNS = {
  STATUS: "STATUS",
  TYPE: "TYPE",
  RATE_FIRST_10K_MILES: "RATE_FIRST_10K_MILES",
  RATE_AFTER_10K_MILES: "RATE_AFTER_10K_MILES",
  RATE_FIRST_5K_KM: "RATE_FIRST_5K_KM",
  RATE_AFTER_5K_KM: "RATE_AFTER_5K_KM",
  RATE: "RATE",
  EFFECTIVE_DATE: "EFFECTIVE_DATE",
  ACTIONS: "ACTIONS",
};

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const INITIAL_SORT_ORDER = SORT_ORDER.DESC;

function RatesList({ onEdit, onRemove, onRevert }) {
  const [sortByCol, setSortByCol] = useState(COLUMNS.EFFECTIVE_DATE);
  const [sortOrder, setSortOrder] = useState(INITIAL_SORT_ORDER);
  const [actingOn, setActingOn] = useState(new Map());

  const {
    team: { country },
  } = useContext(TeamContext);
  const { customRates } = useCustomRates();

  const handleSortByColumn = (column) => {
    if (sortByCol === column) {
      setSortOrder(
        sortOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC
      );
    } else {
      setSortByCol(column);
      setSortOrder(INITIAL_SORT_ORDER);
    }
  };

  const handleToggleActionMenu = (ratesId, open) => {
    const newActingOn = new Map(actingOn);
    newActingOn.set(ratesId, open);

    setActingOn(newActingOn);
  };

  const colsData = {
    [COLUMNS.STATUS]: {
      id: COLUMNS.STATUS,
      title: "Status",
      extraClass: "pl-5",
      sortable: false,
      show: true,
    },
    [COLUMNS.TYPE]: {
      id: COLUMNS.TYPE,
      title: "Type",
      sortable: false,
      show: country === COUNTRIES.GB,
    },
    [COLUMNS.RATE_FIRST_10K_MILES]: {
      id: COLUMNS.RATE_FIRST_10K_MILES,
      title: "First 10K Miles",
      sortable: false,
      show: country === COUNTRIES.GB,
    },
    [COLUMNS.RATE_AFTER_10K_MILES]: {
      id: COLUMNS.RATE_AFTER_10K_MILES,
      title: "After 10K Miles",
      sortable: false,
      show: country === COUNTRIES.GB,
    },
    [COLUMNS.RATE_FIRST_5K_KM]: {
      id: COLUMNS.RATE_FIRST_5K_KM,
      title: `First 5K ${COUNTRIES_DATA[country].unitName}`,
      sortable: false,
      show: country === COUNTRIES.CA,
    },
    [COLUMNS.RATE_AFTER_5K_KM]: {
      id: COLUMNS.RATE_AFTER_5K_KM,
      title: `After 5K ${COUNTRIES_DATA[country].unitName}`,
      sortable: false,
      show: country === COUNTRIES.CA,
    },
    [COLUMNS.RATE]: {
      id: COLUMNS.RATE,
      title: "Rate",
      sortable: false,
      show: country === COUNTRIES.US,
    },
    [COLUMNS.EFFECTIVE_DATE]: {
      id: COLUMNS.EFFECTIVE_DATE,
      title: "Effective date",
      extraClass: "cursor-pointer",
      sortable: true,
      show: true,
    },
    [COLUMNS.ACTIONS]: {
      id: COLUMNS.ACTIONS,
      title: "",
      extraClass: "pr-5",
      sortable: false,
      show: true,
    },
  };

  let filteredCustomRates = [...customRates];

  if (sortByCol === COLUMNS.EFFECTIVE_DATE) {
    filteredCustomRates.sort((a, b) => {
      if (sortOrder === SORT_ORDER.ASC) {
        return a.effectiveDate - b.effectiveDate;
      } else {
        return b.effectiveDate - a.effectiveDate;
      }
    });
  }

  const renderUSValues = () =>
    filteredCustomRates.map((rates) => {
      const { id, value, status, effectiveDateText } = rates;

      const bold = [RATES_STATUS.CURRENT, RATES_STATUS.UPCOMING].includes(
        status
      );

      return (
        <div
          key={id}
          className={`miq-list-table-body-row cursor-default ${
            actingOn.get(id) ? "hovered" : ""
          }`}
        >
          <div className="p-2 pl-5">
            <RatesBadge status={status} />
          </div>
          <div className="p-2">
            <Text bold={bold}>${value?.toFixed(3)}/mi</Text>
          </div>
          <div className="p-2">
            <Text bold={bold}>{effectiveDateText}</Text>
          </div>
          <div className="p-2 pr-5">
            {status !== RATES_STATUS.CURRENT && (
              <div className="flex items-center justify-end">
                <RatesActions
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onRevert={onRevert}
                  rates={rates}
                  onToggle={(open) => handleToggleActionMenu(id, open)}
                />
              </div>
            )}
          </div>
        </div>
      );
    });

  const renderUKValues = () =>
    filteredCustomRates.map((rates) => {
      const {
        id,
        car,
        moto,
        bicycle,
        car2,
        moto2,
        bicycle2,
        status,
        effectiveDateText,
      } = rates;

      const bold = [RATES_STATUS.CURRENT, RATES_STATUS.UPCOMING].includes(
        status
      );

      return (
        <div
          key={id}
          className={`miq-list-table-body-row cursor-default py-5 items-start ${
            actingOn.get(id) ? "hovered" : ""
          }`}
        >
          <div className="p-2 pl-5 self-center">
            <RatesBadge status={status} />
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold={bold}>Cars and Vans</Text>
              <Text bold={bold}>Motorcycles</Text>
              <Text bold={bold}>Bikes</Text>
            </div>
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold={bold}>£{car?.toFixed(3)}/mi</Text>
              <Text bold={bold}>£{moto?.toFixed(3)}/mi</Text>
              <Text bold={bold}>£{bicycle?.toFixed(3)}/mi</Text>
            </div>
          </div>
          <div className="p-2">
            <div className="flex flex-col gap-y-2.5">
              <Text bold={bold}>£{car2?.toFixed(3)}/mi</Text>
              <Text bold={bold}>£{moto2?.toFixed(3)}/mi</Text>
              <Text bold={bold}>£{bicycle2?.toFixed(3)}/mi</Text>
            </div>
          </div>
          <div className="p-2">
            <Text bold={bold}>{effectiveDateText}</Text>
          </div>
          <div className="p-2 pt-0 pr-5">
            {status !== RATES_STATUS.CURRENT && (
              <div className="flex items-center justify-end">
                <RatesActions
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onRevert={onRevert}
                  rates={rates}
                  onToggle={(open) => handleToggleActionMenu(id, open)}
                />
              </div>
            )}
          </div>
        </div>
      );
    });

  const renderCAValues = () =>
    filteredCustomRates.map((rates) => {
      const { id, value, value2, status, effectiveDateText } = rates;

      const valueInKm = ratesInMilesToKm(value)?.toFixed(3);
      const value2InKm = ratesInMilesToKm(value2)?.toFixed(3);

      const bold = [RATES_STATUS.CURRENT, RATES_STATUS.UPCOMING].includes(
        status
      );

      return (
        <div
          key={id}
          className={`miq-list-table-body-row cursor-default ${
            actingOn.get(id) ? "hovered" : ""
          }`}
        >
          <div className="p-2 pl-5">
            <RatesBadge status={status} />
          </div>
          <div className="p-2">
            <Text bold={bold}>${valueInKm}/km</Text>
          </div>
          <div className="p-2">
            <Text bold={bold}>${value2InKm}/km</Text>
          </div>
          <div className="p-2">
            <Text bold={bold}>{effectiveDateText}</Text>
          </div>
          <div className="p-2 pr-5">
            {status !== RATES_STATUS.CURRENT && (
              <div className="flex items-center justify-end">
                <RatesActions
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onRevert={onRevert}
                  rates={rates}
                  onToggle={(open) => handleToggleActionMenu(id, open)}
                />
              </div>
            )}
          </div>
        </div>
      );
    });

  return (
    <div className="flex-grow">
      <div className={`miq-list-table rates-list-table ${country}`}>
        <div className="miq-list-table-header">
          {Object.values(colsData).map(
            ({ id, title, show, extraClass, sortable }) => {
              if (show) {
                return (
                  <div
                    key={id}
                    className={`select-none p-2 ${extraClass || ""}`}
                    onClick={() => sortable && handleSortByColumn(id)}
                  >
                    <div className="flex">
                      <Text color="black-op70">{title}</Text>
                      {sortable && sortByCol === id && (
                        <Icon
                          name="sort"
                          color="black-op50"
                          className={`ml-2 transform ${
                            sortOrder === SORT_ORDER.DESC
                              ? "rotate-0"
                              : "rotate-180"
                          }`}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            }
          )}
        </div>

        <div className="miq-list-table-body">
          {country === COUNTRIES.US && renderUSValues()}
          {country === COUNTRIES.GB && renderUKValues()}
          {country === COUNTRIES.CA && renderCAValues()}
        </div>
      </div>
    </div>
  );
}
