import React from "react";

import { COUNTRIES } from "src/services/utils";

import IconCA from "public/assets/img/flags/ca.svg";
import IconUK from "public/assets/img/flags/uk.svg";
import IconUS from "public/assets/img/flags/us.svg";

export const CountryFlag = ({ country, ...rest }) => {
  switch (country) {
    case COUNTRIES.US:
      return <IconUS {...rest} />;
    case COUNTRIES.GB:
      return <IconUK {...rest} />;
    case COUNTRIES.CA:
      return <IconCA {...rest} />;
  }
};
