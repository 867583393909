import React from "react";
import { useEffect, useRef, useState } from "react";

const NamedLocationInput = ({ setValue, value, ...rest }) => {
  const [width, setWidth] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setWidth(contentRef.current.offsetWidth);
  }, [value]);

  return (
    <div data-testid="named-location">
      <span className="value" ref={contentRef}>
        {value}
      </span>
      <input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        style={{ width: width + 20 }}
        className="location-name "
        {...rest}
      />
    </div>
  );
};
export default NamedLocationInput;
