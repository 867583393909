import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import InAppMessage from "./models/in-app-message.js";
import pe from "./in-app-message-manager-factory.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as or } from "../common/constants.js";
import r from "../../shared-lib/braze-shared-lib.js";
export function logInAppMessageImpression(o) {
  if (!e.rr()) return !1;
  if (!(o instanceof InAppMessage || o instanceof ControlMessage))
    return r.D.error(or), !1;
  const s = o instanceof ControlMessage ? r.A.ro : r.A.gi;
  return pe.m().G(o, s).S;
}
