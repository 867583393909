import React, { useEffect, useState } from "react";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { CsvDropzone } from "src/components/blocks/CsvDropzone";

import Invite from "src/models/invite";

export default BulkInvite;

function BulkInvite({
  show,
  showCancel,
  onSuccessFileLoad,
  onBack,
  onClose,
  onReviewUsers,
  onUploadError,
}) {
  const [fileContents, setFileContents] = useState(new Set([]));

  const handleFileLoad = (newFileContent) => {
    onSuccessFileLoad(newFileContent);
    setFileContents((current) => {
      return new Set([...current, newFileContent]);
    });
  };

  const handleClear = () => {
    setFileContents(new Set([]));
  };

  const handleBack = () => {
    handleClear();
    onBack();
  };

  const handleClose = () => {
    handleClear();
    onClose();
  };

  const handleUploadError = (isError) => {
    onUploadError(isError);
  };

  const handleReviewUsers = () => {
    // FLAT FILES PARSED LINES INTO A LIST OF USERS
    let users = Array.from(fileContents).reduce((prevUsers, current) => {
      const parsedUsers = current?.users || [];
      return prevUsers.concat(parsedUsers);
    }, []);
    // REMOVE DUPLICATES
    users = new Map(
      users.map(({ email, type }) => [email, new Invite(email, type)])
    ).values();
    onReviewUsers(Array.from(users));
    handleClose();
  };

  const handleDownloadCSVTemplate = () => {
    const csv = `Emails\njack@mileiq.com\ntim@mileiq.com\nrobin@mileiq.com`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sample-bulk.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    return () => {
      setFileContents(new Set([]));
    };
  }, [setFileContents]);

  return (
    <Modal
      show={show}
      onClose={handleClose}
      closable={!showCancel}
      onBack={!showCancel && handleBack}
      className="max-w-[550px]"
    >
      <h5 className="mt-0">Bulk invite</h5>
      <Text paragraph className="mt-2">
        Here's an example CSV file that you can use to invite multiple users at
        once. Fill out with your users' email addresses, upload it here, and
        we'll do the rest.
      </Text>
      {!fileContents?.size && (
        <Button
          ghost
          nohover
          icon="download"
          className="mt-4 px-0"
          color="blue"
          onClick={handleDownloadCSVTemplate}
        >
          <Text semibold>Download CSV template</Text>
        </Button>
      )}
      <CsvDropzone
        onClear={handleClear}
        onFileLoad={handleFileLoad}
        onUploadError={handleUploadError}
      >
        {({ open, isLoading }) => (
          <>
            <div className="flex">
              <Button
                primary
                className="mr-2"
                onClick={!fileContents?.size ? open : handleReviewUsers}
                disabled={isLoading}
              >
                {!fileContents?.size ? `Choose file` : `Review users`}
              </Button>
              {showCancel && (
                <Button secondary className="mr-2" onClick={handleClose}>
                  Cancel
                </Button>
              )}
            </div>
          </>
        )}
      </CsvDropzone>
    </Modal>
  );
}
