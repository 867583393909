import { useState } from "react";

import { uuidv4 } from "src/services/utils";

export const useFiles = () => {
  const [files, setFiles] = useState(new Map());

  const add = (file) => {
    setFiles((map) => {
      const id = uuidv4();
      map.set(id, { id, file });
      return new Map(map);
    });
  };

  const update = (id, file) => {
    setFiles((map) => {
      map.set(id, { id, file });
      return new Map(map);
    });
  };

  const remove = (id) => {
    setFiles((map) => {
      map.delete(id);
      return new Map(map);
    });
  };

  const concat = (array) => array.forEach(add);

  const clean = () => {
    setFiles(new Map());
  };

  return {
    files,
    add,
    update,
    remove,
    clean,
    concat,
  };
};
