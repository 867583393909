import { useLazyQuery } from "@apollo/client";
import { format, isAfter, isBefore, isValid } from "date-fns";
import React, { createContext, useContext, useEffect, useState } from "react";

import RatesCA from "src/models/rates/RatesCA";
import RatesUK from "src/models/rates/RatesUK";
import RatesUS from "src/models/rates/RatesUS";

import { getDefaultRates, getTeamRates, updateRates } from "src/services/teams";
import { COUNTRIES, RATES_STATUS } from "src/services/utils";

import { GET_DEFAULT_RATE } from "src/graphql/queries";

import TeamContext from "./TeamContext";
import { useUserData } from "./UserContext";

const CustomRatesContext = createContext();

export function useCustomRates() {
  return useContext(CustomRatesContext);
}

const RatesModel = {
  [COUNTRIES.CA]: RatesCA,
  [COUNTRIES.US]: RatesUS,
  [COUNTRIES.GB]: RatesUK,
};

function formatDate(date) {
  const _date = new Date(date);

  if (isValid(_date) === false) return null;

  return format(_date, "MMM d, yyyy");
}

export function CustomRatesProvider({ children }) {
  const { team, refreshTeam } = useContext(TeamContext);
  const { userData } = useUserData();
  const [defaultRates, setDefaultRates] = useState(null);
  const [defaultCountryRates, setDefaultCountryRates] = useState(null);
  const [isCurrentSameAsDefault, setCurrentSameAsDefault] = useState(false);
  const [isFetchingCustomRates, setFetchingCustomRates] = useState(true);
  const [isRevertingCustomRates, setRevertingCustomRates] = useState(false);
  const [customRates, setCustomRates] = useState([]);

  const [defaultCountryRateQuery] = useLazyQuery(GET_DEFAULT_RATE, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchCountryRates = async (defaultCountry = userData?.country) => {
    try {
      let name = null;
      let country = defaultCountry.toUpperCase();
      switch (country) {
        case COUNTRIES.CA:
          name = "country_ca";
          break;
        case COUNTRIES.GB:
          name = "country_gb";
          break;
        case COUNTRIES.US:
          name = "country_us";
          break;
      }
      const {
        data: { defaultRate },
      } = await defaultCountryRateQuery({
        variables: { name },
      });
      const rates = new RatesModel[country](defaultRate);
      setDefaultCountryRates(rates);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomRates = async () => {
    try {
      setFetchingCustomRates(true);

      const currentTeam = await refreshTeam();

      const fetchedRates = await getTeamRates(currentTeam.id);

      const currentTeamRates = currentTeam.rates;

      // getTeamRates doesn't return default rates like getTeam does
      // Add current default rates if there are only upcoming rates
      if (
        fetchedRates.length === 0 ||
        isAfter(new Date(fetchedRates.at(-1).effective_date), new Date())
      ) {
        fetchedRates.push(currentTeamRates.data);
      }

      const parsedRates = fetchedRates
        .filter(({ effective_date }) => Boolean(effective_date))
        .map((fetchedRate, index) => {
          const rates = new RatesModel[currentTeam.country](fetchedRate);
          const { effectiveDate } = rates;

          if (rates.parseId === currentTeamRates.parseId) {
            rates.status = RATES_STATUS.CURRENT;
          } else if (isBefore(effectiveDate, currentTeamRates.effectiveDate)) {
            rates.status = RATES_STATUS.PAST;
          } else if (isAfter(effectiveDate, currentTeamRates.effectiveDate)) {
            rates.status = RATES_STATUS.UPCOMING;
          }

          const { status } = rates;

          const teamCreatedAt = formatDate(currentTeam.createdAt);
          const thisEffectiveDate = formatDate(effectiveDate);
          const nextEffectiveDate = formatDate(
            fetchedRates[index - 1]?.effective_date
          );
          const previousEffectiveDate = formatDate(
            fetchedRates[index + 1]?.effective_date
          );

          let effectiveDateText = "";

          switch (status) {
            case RATES_STATUS.CURRENT:
              if (fetchedRates.length === 1)
                effectiveDateText = `${teamCreatedAt} - Now`;
              else if (nextEffectiveDate && previousEffectiveDate)
                effectiveDateText = `${thisEffectiveDate} - ${nextEffectiveDate}`;
              else if (nextEffectiveDate && !previousEffectiveDate)
                effectiveDateText = `${teamCreatedAt} - ${nextEffectiveDate}`;
              else if (!nextEffectiveDate && previousEffectiveDate)
                effectiveDateText = `${thisEffectiveDate} - Now`;
              break;
            case RATES_STATUS.PAST:
              effectiveDateText = `${thisEffectiveDate} - ${nextEffectiveDate}`;
              break;
            case RATES_STATUS.UPCOMING:
              if (index === 0) effectiveDateText = `${thisEffectiveDate}`;
              else
                effectiveDateText = `${thisEffectiveDate} - ${nextEffectiveDate}`;
              break;
            default:
              effectiveDateText = "";
              break;
          }

          rates.data.effectiveDateText = effectiveDateText;

          return rates;
        });

      setCustomRates(parsedRates);
    } catch (e) {
      console.log("Could not fetch custom rates", e);
    } finally {
      setFetchingCustomRates(false);
    }
  };

  const revertToDefaultRates = async () => {
    try {
      setRevertingCustomRates(true);

      let values = defaultRates;
      if (!values) {
        values = await loadDefaultRates();
      }

      await updateRates(team.id, {
        data: {
          values: values.data.values,
        },
        effectiveDate: new Date(),
      });
    } catch (e) {
      console.log("Could not revert to default rates", e);
    } finally {
      setRevertingCustomRates(false);
    }
  };

  const resetCustomRates = () => {
    setFetchingCustomRates(true);
    setCustomRates([]);
  };

  const loadDefaultRates = async () => {
    if (!team?.id) return;
    let defaultRates = null;
    try {
      // could respond with error 403 if user is not team admin.
      // wrapping into try-catch to not spam bugsnag with unhandled error
      const defaultRatesData = await getDefaultRates(team?.id);
      defaultRates = new RatesModel[team?.country](defaultRatesData);
    } catch (err) {
      console.log("Failed to load default rates for user.", err);
    }
    setDefaultRates(defaultRates);
    return defaultRates;
  };

  useEffect(() => {
    loadDefaultRates();
  }, [team]);

  useEffect(() => {
    userData?.country && fetchCountryRates(userData.country);
  }, [userData]);

  useEffect(() => {
    if (!team?.rates || !defaultRates) return;
    const { country, rates } = team;
    let isSame = false;
    if (country === COUNTRIES.CA) {
      isSame =
        rates.value === defaultRates.value &&
        rates.value2 === defaultRates.value2;
    }
    if (country === COUNTRIES.US) {
      isSame = rates.value === defaultRates.value;
    }
    if (country === COUNTRIES.GB) {
      isSame =
        rates.car === defaultRates.car &&
        rates.car2 === defaultRates.car2 &&
        rates.moto === defaultRates.moto &&
        rates.moto2 === defaultRates.moto2 &&
        rates.bicycle === defaultRates.bicycle &&
        rates.bicycle2 === defaultRates.bicycle2;
    }
    setCurrentSameAsDefault(isSame);
  }, [team?.rates, defaultRates]);

  return (
    <CustomRatesContext.Provider
      value={{
        getCustomRates,
        resetCustomRates,
        revertToDefaultRates,
        customRates,
        defaultRates,
        defaultCountryRates,
        isCurrentSameAsDefault,
        isFetchingCustomRates,
        isRevertingCustomRates,
      }}
    >
      {children}
    </CustomRatesContext.Provider>
  );
}
