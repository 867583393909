import "src/models/typings";

import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";

import { USER_TYPES } from "src/models/user";

import { UPDATE_NAME } from "src/graphql/mutations";

import { useUser } from "../context/UserContext";
import Button from "../elements/Button";
import Text from "../elements/Text";

export default EditNameModal;

export const ELEMENT_ID = "EDIT_NAME_MODAL";
registerElement(ELEMENT_ID, EditNameModal);

function EditNameModal({ onClose, onSubmit }) {
  const { user } = useUser();
  const [newFirstName, setNewFirstName] = useState(user.firstName);
  const isNewFirstNameValid = newFirstName && newFirstName?.trim() !== "";
  const [newLastName, setNewLastName] = useState(user.lastName);
  const isNewLastNameValid = newLastName && newLastName?.trim() !== "";
  const [updateName, updateNameState] = useMutation(UPDATE_NAME, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getMe"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const handleFirstNameChange = (e) => {
    const val = e.target.value || "";
    setNewFirstName(val);
  };

  const handleLastNameChange = (e) => {
    const val = e.target.value || "";
    setNewLastName(val);
  };

  const handleSubmitName = async (e) => {
    e?.preventDefault();
    if (!isNewFirstNameValid || !isNewLastNameValid) return;

    const success = await updateName({
      variables: {
        firstName: newFirstName.trim(),
        lastName: newLastName.trim(),
      },
    });

    if (success) {
      onClose();
      onSubmit();
    }
  };
  return (
    <Modal
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      passiveBackdrop
      testId="edit-name-modal"
    >
      <div className="flex flex-col">
        <h5 className="mb-[15px]">
          {user.firstName && user.lastName ? "Edit Name" : "Add name"}
        </h5>
        <Text paragraph lg className="mx-0.5">
          {user?.isDriver || user?.role === USER_TYPES.ADMIN_DRIVER
            ? "This name will appear on the reports you send to your admin."
            : "This name will be used in our communications to you."}
        </Text>
        <form onSubmit={handleSubmitName}>
          <div className="flex flex-row gap-[10px] mt-5">
            <div className="flex flex-col gap-[10px]">
              <Text className="text-15" semibold>
                First name
              </Text>
              <Input
                name="firstName"
                placeholder="First name"
                value={newFirstName}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <Text className="text-15" semibold>
                Last name
              </Text>
              <Input
                name="lastName"
                placeholder="Last name"
                value={newLastName}
                onChange={handleLastNameChange}
              />
            </div>
          </div>
          <div className="flex gap-[10px] justify-end mt-[30px]">
            <Button
              type="button"
              secondary
              onClick={onClose}
              className="w-[110px] font-medium text-15"
            >
              Cancel
            </Button>
            <Button
              loading={updateNameState.loading}
              disabled={
                !isNewFirstNameValid ||
                !isNewLastNameValid ||
                (user.firstName === newFirstName?.trim() &&
                  user.lastName === newLastName?.trim())
              }
              type="submit"
              primary
              className="min-w-[110px] font-medium text-15"
            >
              Save name
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
