import { PURPOSE_CATEGORY } from "src/models/purpose";

import { report } from "src/services/error-reporting";

export const PURPOSE_IDS = {
  BUSINESS: "Business",
  BUSINESS_PLUS_COMMUTE: "Business + Commute",
  BETWEEN_OFFICES: "Between Offices",
  CUSTOMER_VISIT: "Customer Visit",
  MEETING: "Meeting",
  ERRAND_SUPPLIES: "Errand/Supplies",
  MEAL_ENTERTAIN: "Meal/Entertain",
  TEMPORARY_SITE: "Temporary Site",
  AIRPORT_TRAVEL: "Airport/Travel",
  COMMUTE: "Commute",
  MEDICAL: "Medical",
  CHARITY: "Charity",
  MOVING: "Moving",
};

const purposesData = {
  [PURPOSE_CATEGORY.BUSINESS]: [
    { id: "", label: "Business", icon: "suitcase-24" },
    { id: "Business + Commute", label: "Business + Commute", icon: "car-side" },
    {
      id: PURPOSE_IDS.BUSINESS_PLUS_COMMUTE,
      label: "Business + Commute",
      icon: "car-side",
    },
    {
      id: PURPOSE_IDS.BETWEEN_OFFICES,
      label: "Between Offices",
      icon: "between-offices",
    },
    {
      id: PURPOSE_IDS.CUSTOMER_VISIT,
      label: "Customer Visit",
      icon: "customer-visit",
    },
    { id: PURPOSE_IDS.MEETING, label: "Meeting", icon: "meeting" },
    {
      id: PURPOSE_IDS.ERRAND_SUPPLIES,
      label: "Errand/Supplies",
      icon: "supplies",
    },
    { id: PURPOSE_IDS.MEAL_ENTERTAIN, label: "Meal/Entertain", icon: "meal" },
    {
      id: PURPOSE_IDS.TEMPORARY_SITE,
      label: "Temporary Site",
      icon: "temp-site",
    },
    { id: PURPOSE_IDS.AIRPORT_TRAVEL, label: "Airport/Travel", icon: "travel" },
  ],
  [PURPOSE_CATEGORY.PERSONAL]: [
    { id: "", label: "Personal", icon: "home" },
    { id: PURPOSE_IDS.COMMUTE, label: "Commute", icon: "commute" },
    { id: PURPOSE_IDS.MEDICAL, label: "Medical", icon: "medical" },
    { id: PURPOSE_IDS.CHARITY, label: "Charity", icon: "charity" },
    { id: PURPOSE_IDS.MOVING, label: "Moving", icon: "moving" },
  ],
};

export function filterOutCommutePurpose(purposesList) {
  return purposesList.filter(
    (purpose) => purpose.id !== PURPOSE_IDS.BUSINESS_PLUS_COMMUTE
  );
}

export function getPurposesDataForCategory(userPurposes, category) {
  if (!Object.keys(purposesData).includes(category?.toString())) return [];

  const purposes = purposesData[category];
  const data = [purposes[0]];
  try {
    userPurposes
      .filter((userPurp) => userPurp.category === category)
      .forEach((userPurp) => {
        const pData = purposes.find((p) => userPurp.id === p.id);
        data.push({
          id: userPurp.id,
          label: userPurp.label || pData?.label,
          icon: pData?.icon || purposes[0].icon, // for custom purposes use default icon of the category
          isHidden: userPurp.isHidden,
          isCustom: userPurp.isCustom,
          category,
        });
      });
  } catch (e) {
    report(e);
  }
  return data;
}

export function getPurposeIcon(category, purpose) {
  if (!category || !purpose) return null;

  const pData = purposesData[category]?.find((p) => p.id === purpose);
  return pData?.icon || purposesData[category]?.[0]?.icon;
}
