import { useCallback, useEffect, useState } from "react";

import { COUNTDOWN_STATUS } from "src/services/utils";

const useCountdown = () => {
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [intervalRef, setIntervalRef] = useState(null);
  const [status, setStatus] = useState(COUNTDOWN_STATUS.NOT_STARTED);

  useEffect(() => {
    if (secondsLeft <= 0 && status === COUNTDOWN_STATUS.IN_PROGRESS) {
      setStatus(COUNTDOWN_STATUS.DONE);
      intervalRef && clearInterval(intervalRef);
      setIntervalRef(null);
    }
  }, [secondsLeft]);

  useEffect(
    () => () => intervalRef && clearInterval(intervalRef),
    [intervalRef]
  );

  const startCountdown = useCallback(
    (seconds) => {
      setStatus(COUNTDOWN_STATUS.IN_PROGRESS);
      setSecondsLeft(seconds);

      intervalRef && clearInterval(intervalRef);

      const interval = setInterval(() => {
        setSecondsLeft((s) => s - 1);
      }, 1000);

      setIntervalRef(interval);
    },
    [secondsLeft, setSecondsLeft]
  );

  return {
    startCountdown,
    status,
    secondsLeft,
    ...parseValuesFromSeconds(secondsLeft),
  };
};

const parseValuesFromSeconds = (secondsLeft) => {
  const hours = Math.floor(secondsLeft / 60 / 60);
  const minutes = Math.floor(secondsLeft / 60) - hours * 60;
  const seconds = secondsLeft % 60;

  return { hours, minutes, seconds };
};

export { useCountdown };
