import Base from "src/models/base";
import User from "src/models/user";

export const MEMBER_STATUS = {
  INVITED: 0,
  ACTIVE: 1,
  ARCHIVED: 2,
};

export function getStatusCode(status) {
  if (Object.keys(MEMBER_STATUS).includes(status)) return status;

  const code = Object.keys(MEMBER_STATUS).find(
    (k) => MEMBER_STATUS[k] === status
  );
  return code || null;
}

export function getStatusName(statusCode) {
  return (
    Object.entries(MEMBER_STATUS).find((entries) => {
      const code = entries[1];
      return code === statusCode;
    })[0] || "Unknown"
  );
}

export default class TeamMember extends Base {
  user = null;
  status = null;
  teamId = null;
  invitedAt = null;

  constructor(data) {
    super("team_member_model");
    if (data) {
      this.user = new User(data.details);
      this.status = data.status;
      this.teamId = data.details.team_id;
      this.invitedAt = new Date(
        data.details.invited_at || data.details.created_at
      );
    }
  }

  get id() {
    return this.user?.id || null;
  }

  get email() {
    return this.user?.email || null;
  }

  get firstName() {
    return this.user?.firstName || null;
  }

  get lastName() {
    return this.user?.lastName || null;
  }

  get statusName() {
    return getStatusName(this.status);
  }

  get isDriver() {
    return !!this.user?.isDriver;
  }

  get isAdmin() {
    return !!this.user?.isAdmin;
  }
}
