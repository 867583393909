import React, { useCallback } from "react";

import { Dropzone } from "src/components/elements/Dropzone";

import { USER_TYPES } from "src/models/user";

import { isValidEmail } from "src/services/utils";

export function CsvDropzone({ onClear, onFileLoad, children, ...props }) {
  const handleFileRead = useCallback((file) => {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onabort = () => reject("File submission was aborted");
      reader.onerror = (ev) => reject(ev);
      reader.onload = () => {
        try {
          const content = reader.result;

          const usersMap = new Map([]);

          let lines = content.split("\n");
          lines.shift(); // remove first line (header)
          while (typeof lines[0] !== "undefined") {
            let line = lines.shift();
            let columns = line.split("\r");
            if (columns.length >= 1) {
              let email = columns[0];
              if (email.length > 0) {
                if (isValidEmail(email)) {
                  usersMap.set(email, { email, type: USER_TYPES.DRIVER });
                }
              }
            }
          }
          if (!usersMap.size) {
            reject("No valid emails found");
          }
          const users = Array.from(usersMap.values());
          const fullFile = { file, content, users };
          setTimeout(() => {
            resolve(fullFile);
          }, 600);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsText(file);
    });

    return promise;
  }, []);

  const handleUpdateFileList = (files) => {
    onClear();
    files?.forEach(({ file }) => onFileLoad(file));
  };

  return (
    <Dropzone
      accept={{ "text/csv": [".csv"] }}
      maxFiles={1}
      onFileRead={handleFileRead}
      onUpdateFileList={handleUpdateFileList}
      {...props}
    >
      {children}
    </Dropzone>
  );
}
