import React from "react";
import { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

export default NewNamedLocation;

export const ELEMENT_ID = "NEW_NAMED_LOCATION";
registerElement(ELEMENT_ID, NewNamedLocation);

function NewNamedLocation({ onClose, namedLocation, onNewNamedLocation }) {
  const [loading, setLoading] = useState(false);

  const createNamedLocation = async () => {
    setLoading(true);
    await onNewNamedLocation();
    setLoading(false);

    onClose();
  };

  return (
    <Modal closable onClose={onClose} className="named-location-modal">
      <div className="text-center">
        <h4>{`”${namedLocation}”`}</h4>
        <p className="mt-3 font-normal">
          Save this location as '{namedLocation}' for all previous and future
          drives here.
        </p>
      </div>
      <div className="mt-7 flex gap-1">
        <Button
          lg
          loading={loading}
          onClick={createNamedLocation}
          className="w-full"
        >
          {loading ? "Loading..." : "Save location name"}
        </Button>
      </div>
    </Modal>
  );
}
