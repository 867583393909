import St from "./auth-manager.js";
import y from "../common/base-provider.js";
import ot from "../util/browser-detector.js";
import It from "./device-manager.js";
import DeviceProperties from "../Core/device-properties.js";
import {
  isArray as p,
  keys as rr,
  validateValueIsFromEnum as F,
  values as At
} from "../util/code-utils.js";
import { logDeprecationWarning as D } from "../util/deprecation-utils.js";
import Ot from "./network-manager.js";
import Lt from "../request-controller.js";
import Pt from "./server-config-manager.js";
import Rt from "./session-manager.js";
import r from "../../shared-lib/braze-shared-lib.js";
import G, { STORAGE_KEYS as o } from "./storage-manager.js";
import Mt from "./storage-manager-factory.js";
import u from "./subscription-manager.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
import bt from "../User/user-manager.js";
import { User } from "../User/index.js";
import { parseQueryStringKeyValues as it } from "../util/url-utils.js";
import { WindowUtils as H } from "../util/window-utils.js";
const T = {
  Zn: "allowCrawlerActivity",
  ho: "baseUrl",
  po: "noCookies",
  Eo: "devicePropertyAllowlist",
  ta: "disablePushTokenMaintenance",
  _o: "enableLogging",
  Io: "enableSdkAuthentication",
  ia: "manageServiceWorkerExternally",
  Ao: "minimumIntervalBetweenTriggerActionsInSeconds",
  So: "sessionTimeoutInSeconds",
  No: "appVersion",
  na: "serviceWorkerLocation",
  ra: "safariWebsitePushId",
  jn: "localization",
  ao: "contentSecurityNonce",
  wo: "enableHtmlInAppMessages",
  To: "allowUserSuppliedJavascript",
  no: "inAppMessageZIndex",
  lo: "openInAppMessagesInNewTab",
  tn: "openCardsInNewTab",
  en: "openNewsFeedCardsInNewTab",
  qi: "requireExplicitInAppMessageDismissal",
  Oo: "doNotLoadFontAwesome",
  vo: "sdkFlavor"
};
class jt {
  constructor() {
    (this.Co = !1),
      (this.Lo = !1),
      (this.Po = new u()),
      (this.Ro = new u()),
      (this.Mo = {}),
      (this.jo = []),
      (this.yo = []),
      (this.Pe = []),
      (this.Do = "4.5.0");
  }
  Uo(t) {
    this.Po.ut(t);
  }
  Zi(t) {
    this.Ro.ut(t);
  }
  initialize(t, i) {
    if (this.zo())
      return (
        r.D.info("Braze has already been initialized with an API key."), !0
      );
    this.Mo = i || {};
    let s = this.nn(T._o);
    const e = it(H.gn());
    if (
      (e && "true" === e.brazeLogging && (s = !0),
      r.D.init(s),
      r.D.info(`Initialization Options: ${JSON.stringify(this.Mo, null, 2)}`),
      null == t || "" === t || "string" != typeof t)
    )
      return r.D.error("Braze requires a valid API key to be initialized."), !1;
    this._r = t;
    let n = this.nn(T.ho);
    if (null == n || "" === n || "string" != typeof n)
      return r.D.error("Braze requires a valid baseUrl to be initialized."), !1;
    !1 === /^https?:/.test(n) && (n = `https://${n}`);
    let a = n;
    if (
      ((n = document.createElement("a")),
      (n.href = a),
      "/" === n.pathname && (n = `${n}api/v3`),
      (this.Bo = n.toString()),
      ot.Wo && !this.nn(T.Zn))
    )
      return (
        r.D.info("Ignoring activity from crawler bot " + navigator.userAgent),
        (this.Lo = !0),
        !1
      );
    const h = this.nn(jt.po) || !1;
    if (((this.h = Mt.Fo(t, h)), new G.ne(null, !0).lr(o.ae)))
      return (
        r.D.info("Ignoring all activity due to previous opt out"),
        (this.Lo = !0),
        !1
      );
    for (const t of rr(this.Mo))
      -1 === At(r.Vo).indexOf(t) &&
        r.D.warn(`Ignoring unknown initialization option '${t}'.`);
    const l = ["mparticle", "wordpress", "tealium"];
    if (null != this.nn(T.vo)) {
      const t = this.nn(T.vo);
      -1 !== l.indexOf(t)
        ? (this.Go = t)
        : r.D.error("Invalid sdk flavor passed: " + t);
    }
    let f = this.nn(r.Vo.Eo);
    if (null != f)
      if (p(f)) {
        const t = [];
        for (let i = 0; i < f.length; i++)
          F(
            DeviceProperties,
            f[i],
            "devicePropertyAllowlist contained an invalid value.",
            "DeviceProperties"
          ) && t.push(f[i]);
        f = t;
      } else
        r.D.error(
          "devicePropertyAllowlist must be an array. Defaulting to all properties."
        ),
          (f = null);
    (this.Ur = new It(this.h, f)),
      (this.gt = new Pt(this.h)),
      (this.v = new bt(this.gt, this.h)),
      (this.j = new Rt(this.h, this.v, this.gt, this.nn(T.So)));
    const m = new u();
    return (
      (this.Ko = new St(this.h, this.nn(T.Io), m)),
      this.jt(m),
      (this.vt = new Ot(
        this.Ur,
        this.h,
        this.Ko,
        this.v,
        this.j,
        this.gt,
        this._r,
        this.Bo,
        this.Do,
        this.Go,
        this.nn(T.No)
      )),
      (this.si = new Lt(
        this._r,
        this.Bo,
        this.j,
        this.Ur,
        this.v,
        this.gt,
        this.h,
        t => {
          if (this.zo()) for (const i of this.re()) i.Rs(t);
        },
        this.Ko,
        this.vt
      )),
      this.si.initialize(),
      r.D.info(
        `Initialized for the Braze backend at "${this.nn(
          T.ho
        )}" with API key "${this._r}".`
      ),
      null != this.nn(T.wo) &&
        D(
          "enableHtmlInAppMessages",
          "initialization option",
          "allowUserSuppliedJavascript"
        ),
      W.init(),
      this.gt.Te(() => {
        this.gt.ye() &&
          import("../FeatureFlags/refresh-feature-flags.js").then(t => {
            (0, t.default)();
          });
      }),
      this.si.cr(() => {
        this.gt.ye() &&
          import("../FeatureFlags/refresh-feature-flags.js").then(t => {
            (0, t.default)(null, null, !0);
          });
      }),
      this.Po.St(this.Mo),
      (this.Co = !0),
      !0
    );
  }
  destroy(t) {
    if ((r.D.destroy(), this.zo())) {
      this.Ro.St(), this.Ro.removeAllSubscriptions();
      for (const t of this.jo) t.destroy();
      this.jo = [];
      for (const t of this.yo) t.clearData(!1);
      (this.yo = []),
        this.removeAllSubscriptions(),
        (this.Pe = []),
        this.si.destroy(),
        (this.si = null),
        (this.Ko = null),
        (this.Ur = null),
        (this.vt = null),
        (this.gt = null),
        (this.j = null),
        (this.v = null),
        (this.Mo = {}),
        (this.Go = void 0),
        (this.Co = !1),
        (this.Lo = !1),
        t && (this.h = null);
    }
  }
  rr() {
    if (this.Ho()) return !1;
    if (!this.zo())
      throw new Error("Braze must be initialized before calling methods.");
    return !0;
  }
  ea() {
    return this._r;
  }
  ii() {
    return this.Ko;
  }
  Ks() {
    return this.Bo;
  }
  ce() {
    return this.Ur;
  }
  nr() {
    return this.vt;
  }
  nn(t) {
    return this.Mo[t];
  }
  re() {
    return this.yo;
  }
  mr() {
    return this.si;
  }
  ir() {
    return this.gt;
  }
  g() {
    return this.j;
  }
  l() {
    return this.h;
  }
  gr() {
    if (this.v && this.si) return new User(this.v, this.si);
  }
  p() {
    return this.v;
  }
  tr() {
    return !0 === this.nn(T.To) || !0 === this.nn(T.wo);
  }
  u(t) {
    let i = !1;
    for (const s of this.jo) s === t && (i = !0);
    i || this.jo.push(t);
  }
  ar(t) {
    let i = !1;
    for (const s of this.yo) s.constructor === t.constructor && (i = !0);
    t instanceof y && !i && this.yo.push(t);
  }
  jt(t) {
    t instanceof u && this.Pe.push(t);
  }
  removeAllSubscriptions() {
    if (this.rr()) for (const t of this.Pe) t.removeAllSubscriptions();
  }
  removeSubscription(t) {
    if (this.rr()) for (const i of this.Pe) i.removeSubscription(t);
  }
  le(t) {
    this.Lo = t;
  }
  zo() {
    return this.Co;
  }
  Ho() {
    return this.Lo;
  }
  Ds() {
    return this.Do;
  }
}
const e = new jt();
export { e as default, jt as BrazeSdkInstance, T as OPTIONS };
