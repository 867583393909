import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useUser } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import { isTeamsProSubscription } from "src/models/team-subscription";

import { report } from "src/services/error-reporting";
import { NETWORK_STATES } from "src/services/http";
import {
  fetchTeamCommuteSettings,
  updateTeamCommuteSettings,
} from "src/services/teams";
import {
  trackExcludeCommuteDisabled,
  trackExcludeCommuteEnabled,
  trackExcludeCommuteSettingsUpdateCompleted,
  trackExcludeCommuteSettingsUpdateFailed,
} from "src/services/tracking";

import { useAppFlash } from "./Flash";
import TeamContext from "./TeamContext";

const CommuteSettingsContext = createContext();

export const useTeamsCommuteSettings = () => {
  const context = useContext(CommuteSettingsContext);

  if (!context) {
    throw new Error(
      "useTeamsCommuteSettings must be used within a CommuteSettingsContext"
    );
  }

  return context;
};

export const CommuteSettingsProvider = ({ children }) => {
  const { flash } = useAppFlash();
  const { team } = useContext(TeamContext);
  const { user } = useUser();
  const flags = useFlags();
  const { miqExcludeCommuteMilesAllPlatforms: isCommuteFlagEnabled } =
    flags || {};

  const [isEnabled, setIsEnabled] = useState(false);
  const [distance, setDistance] = useState(0);
  const [
    fetchCommuteSettingsNetworkState,
    setFetchCommuteSettingsNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const [
    updateCommuteSettingsNetworkState,
    setUpdateCommuteSettingsNetworkState,
  ] = useState(NETWORK_STATES.IDLE);

  const isFetching =
    fetchCommuteSettingsNetworkState === NETWORK_STATES.LOADING;
  const isSubmitting =
    updateCommuteSettingsNetworkState === NETWORK_STATES.LOADING;

  const shouldFilterOutCommutePurpose =
    !isCommuteFlagEnabled || !user?.isDriver || !isEnabled;

  useEffect(() => {
    if (isTeamsProSubscription(team?.subscription?.plan)) {
      fetchCommuteSettings();
    }
  }, [team]);

  async function toggleCommuteSettings() {
    if (isSubmitting) return;

    // to disable we should update setting with distance = null
    const payload = isEnabled ? { distance: null } : { distance: 0 };
    try {
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.LOADING);
      const settings = await updateTeamCommuteSettings(payload);
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.LOADED);
      setIsEnabled(settings.isEnabled);
      setDistance(settings.distance);
      if (!isEnabled) {
        trackExcludeCommuteEnabled({
          userId: user?.id,
          orgId: team.orgId,
          country: team.country,
          subPlan: team.subscription?.plan,
          freeTrial: team.subscription?.isFreeTrialActive,
        });
      } else {
        trackExcludeCommuteDisabled({
          userId: user?.id,
          orgId: team.orgId,
          country: team.country,
          subPlan: team.subscription?.plan,
          freeTrial: team.subscription?.isFreeTrialActive,
        });
      }
    } catch (err) {
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.ERROR);
      console.log("Failed to toggle commute settings", err);
      report(err);
      flash(<Text>Commute distance failed to update.</Text>, {
        type: FlashTypes.ERROR,
      });
    }
  }

  async function fetchCommuteSettings() {
    try {
      if (team.isExpired) {
        setIsEnabled(false);
        return;
      }
      setFetchCommuteSettingsNetworkState(NETWORK_STATES.LOADING);
      const commuteSettings = await fetchTeamCommuteSettings();

      setIsEnabled(commuteSettings.isEnabled);
      setDistance(parseFloat(commuteSettings.distance));

      setFetchCommuteSettingsNetworkState(NETWORK_STATES.LOADED);
    } catch (err) {
      if (
        err?.status === 404 &&
        err?.data?.detail ===
          "This MIQ Teams account does not have commute settings."
      ) {
        setIsEnabled(false);
        setFetchCommuteSettingsNetworkState(NETWORK_STATES.LOADED);
        return;
      }

      setFetchCommuteSettingsNetworkState(NETWORK_STATES.ERROR);
      console.log("Failed to fetch commute settings", err);
    }
  }

  async function submitCommuteSettings(data) {
    if (isSubmitting) return;

    try {
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.LOADING);
      const successMessage = distance
        ? "Commute distance has been updated."
        : "Commute distance has been saved.";
      const settings = await updateTeamCommuteSettings(data);

      setDistance(settings.distance);
      setIsEnabled(settings.isEnabled);
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.LOADED);

      flash(<Text>{successMessage}</Text>, {
        type: FlashTypes.BLACK,
      });

      trackExcludeCommuteSettingsUpdateCompleted({
        orgId: team.orgId,
        country: team.country,
        subPlan: team.subscription?.plan,
        freeTrial: team.subscription?.isFreeTrialActive,
      });
    } catch (err) {
      console.log("Failed to submit commute settings", err);
      report(err);
      setUpdateCommuteSettingsNetworkState(NETWORK_STATES.ERROR);
      flash(<Text>Commute distance failed to update.</Text>, {
        type: FlashTypes.ERROR,
      });

      trackExcludeCommuteSettingsUpdateFailed({
        orgId: team.orgId,
        country: team.country,
        subPlan: team.subscription?.plan,
        freeTrial: team.subscription?.isFreeTrialActive,
      });
    }
  }

  const contextValues = useMemo(
    () => ({
      isEnabled,
      distance,
      isSubmitting,
      isFetching,
      shouldFilterOutCommutePurpose,
      toggleCommuteSettings,
      setIsEnabled,
      setDistance,
      submitCommuteSettings,
      fetchCommuteSettings,
    }),
    [
      isEnabled,
      distance,
      isFetching,
      isSubmitting,
      updateCommuteSettingsNetworkState,
      fetchCommuteSettingsNetworkState,
      isCommuteFlagEnabled,
      user,
    ]
  );

  return (
    <CommuteSettingsContext.Provider value={contextValues}>
      {children}
    </CommuteSettingsContext.Provider>
  );
};
