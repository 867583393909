import "src/models/typings";

import React, { useContext } from "react";
import { useHistory } from "react-router";

import DrivesContext from "src/components/context/DrivesContext";
import TeamContext from "src/components/context/TeamContext";
import { UserDataContext } from "src/components/context/UserContext";

import Actions from "src/components/blocks/drives/Actions";
import Notes from "src/components/blocks/drives/Notes";
import SelectVehicle from "src/components/blocks/drives/SelectVehicle";
import PurposePicker from "src/components/blocks/drives/classification/PurposePicker";
import Inputs from "src/components/blocks/drives/details/inputs/Inputs";
import Header from "src/components/blocks/drives/details/single-drive-details/Header";
import Routes from "src/components/blocks/drives/details/single-drive-details/routes/Routes";

import { useFlags } from "src/hooks/useFlags";

import Drive from "src/models/drive";
import { PURPOSE_CATEGORY } from "src/models/purpose";

import { report } from "src/services/error-reporting";
import {
  CustomPurposeSource,
  DriveEditDetail,
  Pages,
  ReportCreationFailure,
  trackCustomPurposeAddCompleted,
  trackCustomPurposeAddStarted,
  trackDriveClassifyCompleted,
  trackDriveClassifyStarted,
  trackDriveEdited,
  trackReportCreationCompleted,
  trackReportCreationFailed,
  trackReportCreationStarted,
  trackVehicleAddCompleted,
  trackVehicleAddStarted,
} from "src/services/tracking";

import StaticMap from "../../../StaticMap";
import CreateReport from "../../../reports/CreateReport";
import AutoClassified from "./AutoClassified";
import DriveTypes from "./DriveTypes";

export default SingleDriveDetails;

const isValidAttribute = (value) => typeof value !== "undefined";
/**
 *
 * @param {{drive: Drive}} props
 * @returns
 */
function SingleDriveDetails({ drive, onClose, onEditDrive, unit, currency }) {
  const history = useHistory();
  const { userData } = useContext(UserDataContext);
  const { team } = useContext(TeamContext);
  const { miqDashMapDashboardRoutesWeb } = useFlags();

  const { onPurposeSelectedFromDriveDetails } = useContext(DrivesContext);
  const {
    id,
    secondId,
    isRoundTripStop,
    googleDistance,
    notes,
    parkingFees,
    tollFees,
    purpose,
  } = drive;

  const onSingleEditDrive = async (fields, notifySuccess = true) =>
    onEditDrive([{ objectId: id, ...fields }], notifySuccess);

  const onRoundTripStopEditDrive = async (payload, notifySuccess = true) =>
    onEditDrive(
      {
        isRoundTripStop: true,
        payload,
      },
      notifySuccess
    );

  const handleSelectPurpose = async (p) => {
    let res = null;
    if (isRoundTripStop) {
      res = await onRoundTripStopEditDrive(
        [
          { objectId: id, category: p.category, purpose: p.id },
          { objectId: secondId, category: p.category, purpose: p.id },
        ],
        false
      );
    } else {
      res = await onSingleEditDrive(
        { category: p.category, purpose: p.id },
        false
      );
    }

    try {
      const d = new Drive(res.data.editDrives[0]);
      trackDriveClassifyCompleted({
        page: Pages.DRIVE_DETAILS,
        count: 1,
        type: p.category,
        existingTypes: [drive.purpose.category],
        purpose: p.id,
      });
      onPurposeSelectedFromDriveDetails([d], p);
    } catch (e) {
      report(e);
    }
  };

  const handleSelectVehicle = async (vehicle) => {
    trackDriveEdited({
      count: 1,
      detail: DriveEditDetail.VEHICLE,
    });

    if (isRoundTripStop) {
      return onRoundTripStopEditDrive([
        {
          objectId: id,
          vehicleId: vehicle.id,
        },
        {
          objectId: secondId,
          vehicleId: vehicle.id,
        },
      ]);
    }

    return onSingleEditDrive({
      vehicleId: vehicle.id,
    });
  };

  const handleEditNotes = (data) => {
    trackDriveEdited({
      count: 1,
      detail: DriveEditDetail.NOTES,
    });
    if (isRoundTripStop) {
      return onRoundTripStopEditDrive([
        { objectId: id, ...data },
        { objectId: secondId, ...data },
      ]);
    }

    return onSingleEditDrive(data);
  };

  const handleEditInputs = async (data) => {
    if (isValidAttribute(data.googleDistance))
      trackDriveEdited({
        count: 1,
        detail: DriveEditDetail.DISTANCE,
      });

    if (isValidAttribute(data.parkingFees))
      trackDriveEdited({
        count: 1,
        detail: DriveEditDetail.PARKING,
      });

    if (isValidAttribute(data.tollFees))
      trackDriveEdited({
        count: 1,
        detail: DriveEditDetail.TOLLS,
      });

    let field = "";
    let payload = { ...data };
    if (isRoundTripStop) {
      if (isValidAttribute(data.parkingFees)) field = "parkingFees";
      if (isValidAttribute(data.tollFees)) field = "tollFees";
      if (isValidAttribute(data.googleDistance)) field = "googleDistance";

      payload = [
        { objectId: id, ...data },
        { objectId: secondId, [field]: 0 },
      ];
    }

    return isRoundTripStop
      ? onRoundTripStopEditDrive(payload)
      : onSingleEditDrive(payload);
  };

  const handleStartSelectPurpose = ({ category }) => {
    trackDriveClassifyStarted({
      page: Pages.DRIVE_DETAILS,
      count: 1,
      type: category,
      existingTypes: [drive.purpose.category],
    });
  };

  const handleAddPurposeStarted = ({ category }) => {
    trackCustomPurposeAddStarted({
      category,
      src: CustomPurposeSource.DRIVES_FILTER,
    });
  };

  const handleAddPurposeCompleted = ({ category }) => {
    trackCustomPurposeAddCompleted({
      category,
      src: CustomPurposeSource.DRIVES_FILTER,
    });
  };

  const hasBusinessDrives =
    drive.purpose.category === PURPOSE_CATEGORY.BUSINESS;

  return (
    <div
      key={drive.id}
      data-testid="single-drive"
      className="drive-details flex flex-col gap-[20px] laptop:gap-[15px]"
    >
      {drive.isAutoClassified && !miqDashMapDashboardRoutesWeb ? (
        <AutoClassified drive={drive} isBanner />
      ) : null}
      <Header onClose={onClose} drive={drive} unit={unit} currency={currency} />
      <DriveTypes drive={drive} />
      <StaticMap
        className="min-h-[210px] laptop:min-h-[160px] h-full"
        drive={drive}
        unit={unit}
        currency={currency}
      />
      <PurposePicker
        selected={purpose}
        onStart={handleStartSelectPurpose}
        onSelect={handleSelectPurpose}
        onAddNewStarted={handleAddPurposeStarted}
        onAddNewCompleted={handleAddPurposeCompleted}
      />
      <Routes drive={drive} />
      <Inputs
        onEditDrive={handleEditInputs}
        distance={googleDistance}
        parkingFees={parkingFees}
        tollFees={tollFees}
      />
      <SelectVehicle
        onStartAddDetails={({ type }) => {
          trackVehicleAddStarted({
            page: Pages.DRIVE_DETAILS,
            type: type,
          });
        }}
        onAddDetailsCompleted={({ type }) => {
          trackVehicleAddCompleted({
            page: Pages.DRIVE_DETAILS,
            type: type,
          });
        }}
        onSelect={handleSelectVehicle}
        selected={{
          id: drive.vehicleId,
          type: drive.vehicleType,
        }}
      />
      <Notes notes={notes} onEditDrive={handleEditNotes} />
      <CreateReport
        onStart={() => {
          trackReportCreationStarted({
            page: Pages.DRIVE_DETAILS,
            businessDrivesCount: hasBusinessDrives ? 1 : 0,
            personalDrivesCount: hasBusinessDrives ? 0 : 1,
            subscriptionId: userData.subscriptionType,
            orgId: team?.orgId,
            orgGroupId: team?.orgGroupId,
          });
        }}
        onFinish={(
          success,
          msg,
          { recipientType, contactRelationship, markAsReported }
        ) => {
          if (success) {
            trackReportCreationCompleted({
              page: Pages.DRIVE_DETAILS,
              businessDrivesCount: hasBusinessDrives ? 1 : 0,
              personalDrivesCount: hasBusinessDrives ? 0 : 1,
              subscriptionId: userData.subscriptionType,
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              recipientType,
              contactRelationship,
              markAsReported,
            });
            history.push({
              pathname: "/user/reports",
              state: {
                showReportMessage: msg,
              },
            });
          } else {
            trackReportCreationFailed({
              page: Pages.DRIVE_DETAILS,
              subscriptionId: userData.subscriptionType,
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reportCreationFailure: ReportCreationFailure.SOMETHING_WENT_WRONG,
            });
          }
        }}
        disabled={drive.purpose.category === PURPOSE_CATEGORY.UNCLASSIFIED}
        hasBusinessDrives={hasBusinessDrives}
        reportData={{
          driveIds: isRoundTripStop ? [id, secondId] : [id],
        }}
        icon="file-text"
        className="w-full relative font-medium"
        secondary
      >
        Report
      </CreateReport>
      <Actions drives={[drive]} />
    </div>
  );
}
