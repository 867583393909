import React, { useEffect, useRef } from "react";

export default MiqCheckbox;

function MiqCheckbox({
  label,
  onChange,
  checked,
  indeterminate,
  className,
  testId,
  stopPropagation,
  disabled,
}) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.indeterminate = indeterminate && !checked;
    inputRef.current.checked = checked;
  }, [indeterminate, checked, inputRef]);

  const handleChange = (e) => {
    if (stopPropagation) e.stopPropagation();

    onChange(e.target.checked, e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleChange(e);
    }
  };

  return (
    <label className={`miq-checkbox ${className || ""}`}>
      <input
        data-testid={testId || "miq-checkbox"}
        type="checkbox"
        checked={!!checked}
        onClick={handleChange}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        disabled={disabled}
      />
      {label || null}
    </label>
  );
}
