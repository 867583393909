import { registerElement } from "src/lib/layers/LayersProvider";

import { useTeamLocations } from "src/components/context/TeamLocationsContext";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { NETWORK_STATES } from "src/services/http";

export const REMOVE_TEAM_LOCATION_MODAL_ID = "REMOVE_TEAM_LOCATION_MODAL_ID";

registerElement(REMOVE_TEAM_LOCATION_MODAL_ID, RemoveTeamLocation);

function RemoveTeamLocation({ onClose, selectedTeamLocation }) {
  const {
    removeTeamLocation,
    removeTeamLocationNetworkState,
    removeTeamLocationError,
  } = useTeamLocations();

  const isRemovingTeamLocation =
    removeTeamLocationNetworkState === NETWORK_STATES.LOADING;
  const errorMessage = removeTeamLocationError?.message;

  const handleRemoveLocation = async (e) => {
    e?.preventDefault();

    if (isRemovingTeamLocation) return;

    const success = await removeTeamLocation(selectedTeamLocation?.id);

    if (success) {
      onClose();
    }
  };

  return (
    <Modal
      show
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      contentClassName="pt-[30px]"
      passiveBackdrop
    >
      <h5>Are you sure you want to remove this Team Location?</h5>
      <Text paragraph className="text-15 mt-[10px]">
        Future reports will show the city or neighborhood name instead of this
        location name.
      </Text>
      <Text paragraph className="text-15 mt-5" semibold>
        {selectedTeamLocation?.name}
      </Text>
      <Text paragraph className="text-15">
        {selectedTeamLocation?.address}
      </Text>
      {Boolean(errorMessage) && (
        <Text paragraph color="red" className="text-13 mt-2">
          {errorMessage}
        </Text>
      )}
      <div className="flex gap-[10px] justify-end mt-[30px]">
        <Button
          type="button"
          secondary
          onClick={onClose}
          className="font-medium text-15"
        >
          Cancel
        </Button>
        <Button
          onClick={handleRemoveLocation}
          loading={isRemovingTeamLocation}
          type="submit"
          destructive
          className="font-medium text-15"
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
}
