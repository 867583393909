import { format, isAfter, isBefore } from "date-fns";
import startOfDay from "date-fns/startOfDay";

import Base from "src/models/base";
import RatesCA from "src/models/rates/RatesCA";
import RatesUK from "src/models/rates/RatesUK";
import RatesUS from "src/models/rates/RatesUS";
import TeamSubscription from "src/models/team-subscription";
import User from "src/models/user";

import { COUNTRIES, RATES_STATUS } from "src/services/utils";

export default class Team extends Base {
  id = null;
  name = null;
  country = null;
  subscription = null;
  admins = null;
  rates = null;
  upcomingRates = null;
  owner = null;
  industry = null;
  size = null;
  daysUntilGracePeriodEnds = null;
  gracePeriodEndsAt = null;

  constructor(data) {
    super("team_model");
    if (data) {
      this.id = data.team_id || null;
      this.orgId = data.org_id || null;
      this.name = data.name || null;
      this.industry = data.industry || null;
      this.size = data.size || null;
      this.country = data.country?.toUpperCase() || COUNTRIES.US;
      this.createdAt = data.created_at;
      this.pendingSubscription = null;
      this.gracePeriodEndsAt = data.grace_period_end_timestamp
        ? data.grace_period_end_timestamp * 1000
        : null;
      this.daysUntilGracePeriodEnds = data.grace_period_ends_in;

      this.subscription = data.subscription
        ? new TeamSubscription(data.subscription)
        : null;
      if (data.admins && data.admins.length > 0) {
        this.admins = [];
        data.admins.forEach((adm) => {
          this.admins.push(new User(adm));
          if (adm.is_owner) {
            this.owner = new User(adm); // creator of the team
          }
        });
      }

      data.current_rate.status = RATES_STATUS.CURRENT;

      switch (this.country) {
        case COUNTRIES.US:
          this.rates = new RatesUS(data.current_rate);
          if (data.upcoming_rate) {
            this.upcomingRates = new RatesUS(data.upcoming_rate);
          }
          break;
        case COUNTRIES.GB:
          this.rates = new RatesUK(data.current_rate);
          if (data.upcoming_rate) {
            this.upcomingRates = new RatesUK(data.upcoming_rate);
          }
          break;
        case COUNTRIES.CA:
          this.rates = new RatesCA(data.current_rate);
          if (data.upcoming_rate) {
            this.upcomingRates = new RatesCA(data.upcoming_rate);
          }
          break;
      }
    }
  }

  hasAdminWithId(id) {
    if (!this.admins || this.admins.length === 0) return false;
    return !!this.admins.find((adm) => adm.id === id);
  }

  isAdmin(email) {
    if (!this.admins || this.admins.length === 0) return false;
    return !!this.admins.find((adm) => adm.email === email);
  }

  // used for event tracking
  get orgGroupId() {
    return this.id;
  }

  cloneRatesForEdit() {
    let r = this.rates.clone();
    r.effectiveDate = startOfDay(new Date());
    return r;
  }

  get isPaymentFailing() {
    return !!this.gracePeriodEndsAt;
  }

  get expiredDateText() {
    if (!this.gracePeriodEndsAt) return "";

    const date = new Date(this.gracePeriodEndsAt);
    return format(date, "MMM dd, yyyy");
  }

  get longExpiredDateText() {
    if (!this.gracePeriodEndsAt) return "";

    const date = new Date(this.gracePeriodEndsAt);
    return format(date, "MMMM dd, yyyy");
  }

  get isInGracePeriod() {
    if (!this.gracePeriodEndsAt) return false;

    const date = new Date(this.gracePeriodEndsAt);
    return isBefore(Date.now(), date);
  }

  get isExpired() {
    if (!this.gracePeriodEndsAt) return false;

    const date = new Date(this.gracePeriodEndsAt);
    return isAfter(Date.now(), date);
  }
}
