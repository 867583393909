import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LoginEmailStep } from "src/components/blocks/auth/login/LoginEmailStep";
import { LoginPasswordStep } from "src/components/blocks/auth/login/LoginPasswordStep";

import useQueryParams from "src/hooks/useQueryParams";

import {
  clean,
  getCookie,
  removeCookie,
  setAccessToken,
} from "src/services/storage";
import { trackLoginStarted } from "src/services/tracking";
import { AUTH_SCREENS, isValidEmail } from "src/services/utils";

import Logo from "public/assets/img/miq-logo-black-orange.svg";

function tryLoginWithCookie() {
  // this is for impersonate feature in admin panel
  const accessTokenFromCookie = getCookie("access_token");
  if (accessTokenFromCookie) {
    removeCookie("access_token");
    setAccessToken(accessTokenFromCookie);
    window.location.href = "/";
  }
}

export const LoginPage = () => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const { state: locationState } = useLocation();

  const screen = locationState?.screen || AUTH_SCREENS.EMAIL;

  useEffect(() => {
    clean();
    tryLoginWithCookie();
    trackLoginStarted({
      source: searchParams.get("src") || "",
    });
    const prefix = "/login";
    const email = searchParams?.get?.("email");
    if (!isValidEmail(email)) {
      searchParams?.delete?.("email");
      const params = searchParams?.toString();
      history.replace(`${prefix}${params ? "?" + params : ""}`);
    } else {
      history.replace(`${prefix}?${searchParams?.toString()}`, {
        screen: AUTH_SCREENS.EMAIL,
      });

      history.push(`${prefix}?${searchParams?.toString()}`, {
        screen: AUTH_SCREENS.PWD,
      });
    }
  }, []);

  return (
    <div className="relative w-full">
      <a
        className="absolute left-[35px] top-[30px] z-10"
        href="https://mileiq.com"
        target="_blank"
        rel="noopener"
      >
        <Logo />
      </a>
      <div className="relative w-full h-full table table-fixed">
        <p className="absolute bottom-20 text-center w-full text-13 text-black/50">
          By logging in, you agree to our{" "}
          <a
            className="underline"
            href="https://mileiq.com/terms"
            target="_blank"
            rel="noopener"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            className="underline"
            href="https://mileiq.com/privacy"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </a>
        </p>
        <div className="w-full table-cell align-middle">
          <div className="login-wrapper relative my-0 mx-auto bg-white px-6 sm:px-0">
            <div className="text-center">
              {screen === AUTH_SCREENS.EMAIL && <LoginEmailStep />}
              {screen === AUTH_SCREENS.PWD && <LoginPasswordStep />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
