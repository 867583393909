import r from "../../shared-lib/braze-shared-lib.js";
import qt from "../models/backend-errors.js";
const b = {
  Hs: t => {
    let e, o;
    try {
      const n = () => {
        r.D.error("This browser does not have any supported ajax options!");
      };
      if (
        (window.XMLHttpRequest ? ((e = new XMLHttpRequest()), e || n()) : n(),
        null != e)
      ) {
        const r = () => {
          "function" == typeof t.error && t.error(e.status),
            "function" == typeof t.Pl && t.Pl(!1);
        };
        (e.onload = () => {
          let o = !1;
          if (4 === e.readyState)
            if (
              ((o = (e.status >= 200 && e.status < 300) || 304 === e.status), o)
            ) {
              if ("function" == typeof t.S) {
                let o, r;
                try {
                  (o = JSON.parse(e.responseText)),
                    (r = e.getAllResponseHeaders());
                } catch (o) {
                  const n = {
                    error: "" === e.responseText ? qt.fa : qt.ma,
                    response: e.responseText
                  };
                  t.S(n, r);
                }
                o && t.S(o, r);
              }
              "function" == typeof t.Pl && t.Pl(!0);
            } else r();
        }),
          (e.onerror = () => {
            r();
          }),
          (e.ontimeout = () => {
            r();
          }),
          (o = JSON.stringify(t.data)),
          e.open("POST", t.url, !0),
          e.setRequestHeader("Content-type", "application/json"),
          e.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        const n = t.headers || [];
        for (const t of n) e.setRequestHeader(t[0], t[1]);
        e.send(o);
      }
    } catch (t) {
      r.D.error(`Network request error: ${t.message}`);
    }
  }
};
export const readResponseHeaders = t => {
  let e = {};
  const o = t.toString().split("\r\n");
  if (!o) return e;
  let r, n;
  for (const t of o)
    t &&
      ((r = t
        .slice(0, t.indexOf(":"))
        .toLowerCase()
        .trim()),
      (n = t.slice(t.indexOf(":") + 1).trim()),
      (e[r] = n));
  return e;
};
export default b;
