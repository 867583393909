import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

export default ConcurIntegrationSuccessModal;

export const ELEMENT_ID = "CONCUR_INTEGRATION_SUCCESS_MODAL";
registerElement(ELEMENT_ID, ConcurIntegrationSuccessModal);

function ConcurIntegrationSuccessModal({ onClose }) {
  return (
    <Modal closable show className="w-[540px]" onClose={onClose}>
      <h4 className="text-center">Concur integration enabled</h4>
      <Text paragraph center className="mt-5">
        You did it! You've now connected your MileIQ Team to your Concur
        account. Your drivers can now submit their business drives to their
        expense reports.{" "}
        <a
          className="underline"
          href="https://support.mileiq.com/hc/en-us/articles/11268740428180"
          target="_blank"
        >
          Here's a guide
        </a>{" "}
        to help your drivers submit from MileIQ to Concur.
      </Text>
      <Button lg onClick={onClose} type="button" className="w-full mt-8">
        Done
      </Button>
    </Modal>
  );
}
