import { format, isSameYear } from "date-fns";
import React, { useRef } from "react";
import { useEffect } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Calendar from "src/components/elements/Calendar";
import Icon from "src/components/elements/Icon";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

export default DatePicker;
export const ELEMENT_ID = "DATE_PICKER";

registerElement(ELEMENT_ID, DatePickerCard);

function DatePicker({
  icon = "calendar",
  semibold = false,
  date,
  minDate,
  maxDate,
  alignment,
  onSelect,
  open = false,
  setOpen,
  testId,
  prefix = <></>,
  customFormat,
}) {
  const triggerRef = useRef();

  const theDate = date || new Date();
  const now = new Date();

  const dp = useElement("DATE_PICKER", {
    props: {
      selected: theDate,
      minDate,
      maxDate,
      onSelect: (d) => {
        onSelect(d);
        dp.deactivate();
      },
      onClickOutside: () => {
        dp.deactivate();
      },
    },
  });

  const handlePickerClicked = () => {
    dp.activate({
      props: {
        alignment,
        trigger: triggerRef.current,
      },
    });
  };

  useEffect(() => {
    if (open) {
      dp.activate({
        props: {
          alignment,
          trigger: triggerRef.current,
          onClickOutside: () => {
            setOpen(false);
            dp.deactivate();
          },
        },
      });
    }
  }, [open]);

  return (
    <button
      type="button"
      data-testid={testId || "date-picker"}
      ref={triggerRef}
      className={`w-full rounded-14-12 ${
        dp.open
          ? "border-blue-medium border-2 p-0"
          : "p-[1px] border border-border-1 hover:border-black/30"
      } h-40-35`}
      onClick={handlePickerClicked}
    >
      <span className="flex justify-between items-center px-3">
        <Icon name={icon} color="black" />
        <Text semibold={semibold} className="flex-grow px-3">
          {prefix}
          {format(
            theDate,
            customFormat || (isSameYear(theDate, now) ? "MMM d" : "MMM d, yyyy")
          )}
        </Text>
        <Icon name="caret" color="black" />
      </span>
    </button>
  );
}

export function DatePickerCard({
  trigger,
  alignment,
  minDate,
  maxDate,
  selected,
  onSelect,
  onClickOutside,
}) {
  return (
    <Pivoted
      pivot={trigger}
      onClickOutside={onClickOutside}
      style={{ zIndex: 20000 }}
      {...alignment}
    >
      <div className="bg-white p-5 rounded-20 shadow-xl">
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          onSelect={onSelect}
          selected={selected}
        />
      </div>
    </Pivoted>
  );
}
