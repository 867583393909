import { useMutation } from "@apollo/client";
import React, { useRef, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Input from "src/components/elements/Input";
import Modal from "src/components/elements/Modal";

import { report } from "src/services/error-reporting";
import { setAccessToken, setRefreshToken } from "src/services/storage";
import {
  trackUsernameEditCompleted,
  trackUsernameEditStepCompleted,
} from "src/services/tracking";
import { EditUsernameStepName } from "src/services/tracking/avo/Avo";
import { isValidEmail } from "src/services/utils";

import { EDIT_USERNAME } from "src/graphql/mutations";

import { Captcha, captchaErrors } from "../blocks/captcha";
import { useAppFlash } from "../context/Flash";
import { useUser, useUserData } from "../context/UserContext";
import Button from "../elements/Button";
import { FlashTypes } from "../elements/Flash";
import PasswordInput from "../elements/PasswordInput";
import Text from "../elements/Text";

export default EditEmailModal;

export const ELEMENT_ID = "EDIT_EMAIL_MODAL";
registerElement(ELEMENT_ID, EditEmailModal);

function EditEmailModal({ onClose, onSubmit }) {
  const captchaRef = useRef();
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const { flash } = useAppFlash();

  const { user } = useUser();
  const { userData } = useUserData();
  const [newEmail, setNewEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [editUsername, updateEmailState] = useMutation(EDIT_USERNAME, {
    notifyOnNetworkStatusChange: true,
  });

  const onEmailChange = (e) => {
    const val = e.target.value || "";
    if (isValidEmail(val) === false) {
      setEmailError("Please enter a valid email address");
    } else {
      if (emailError) {
        setEmailError(null);
      }
    }
    setNewEmail(val);
  };

  const onLoad = () => {
    setLoadingCaptcha(false);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    try {
      let headers;
      if (loadingCaptcha) return;
      if (captchaRef?.current) {
        const captchaToken = await captchaRef.current.executeAsync?.();
        captchaRef.current.reset?.();
        if (!captchaToken) {
          throw new Error(captchaErrors.RECAPTCHA_TOKEN_REQUIRED);
        }
        headers = {
          "x-recaptcha-token": captchaToken,
        };
      }

      const { data } = await editUsername({
        variables: {
          username: user.email,
          newUsername: newEmail,
          password,
        },
        context: {
          headers,
        },
      });
      const error = data?.editUsername?.detail;
      if (error) {
        // BE doesn't return error only when password is wrong.
        // As user doesn't have to type the current username (only new username is typed)
        // We can be sure that he won't have a wrong username, only a password.
        if (error === "Incorrect username or password") {
          setPasswordError(
            "This password doesn't match our records. Please try again."
          );
        } else {
          setEmailError(error);
        }
        return;
      }

      setAccessToken(data.editUsername.access_token);
      setRefreshToken(data.editUsername.refresh_token);
      trackUsernameEditCompleted?.({
        subscriptionId: userData.subscriptionType,
      });

      onClose();
      onSubmit();
    } catch (e) {
      const errorMessage =
        e?.message || e || "Something went wrong while editing this account.";
      report(errorMessage);
      if (
        [400, 422, 500].includes(e?.networkError?.statusCode) ||
        (errorMessage === captchaErrors.RECAPTCHA_TOKEN_REQUIRED &&
          captchaRef?.current)
      ) {
        captchaRef.current.reset?.();
        captchaRef.current.execute?.();
      }
      flash(
        <p>
          Something went wrong while editing your account. Please try again
          later or contact the support.
        </p>,
        {
          type: FlashTypes.ERROR,
        }
      );
    }
  };
  return (
    <Modal
      closable={false}
      className="w-[440px]"
      onClose={onClose}
      passiveBackdrop
      testId="edit-email-modal"
    >
      <div className="flex flex-col">
        <h5 className="mb-[15px]">Edit email</h5>
        <Text paragraph lg className="mx-0.5">
          You will receive MileIQ communications at this email address. This is
          also your credential for logging in.
        </Text>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-5 gap-[10px]">
            <Input
              label="Email"
              autoFocus
              name="email"
              value={newEmail}
              onChange={onEmailChange}
              valid={emailError === null}
              onBlur={() =>
                trackUsernameEditStepCompleted?.({
                  subscriptionId: userData.subscriptionType,
                  editUsernameStepName: EditUsernameStepName.ADD_EMAIL,
                })
              }
            />
            {emailError && (
              <Text md regular color="red">
                {emailError}
              </Text>
            )}
          </div>
          <div className="flex flex-col mt-5 gap-[10px]">
            <PasswordInput
              onChange={(e) => {
                if (passwordError) {
                  setPasswordError(null);
                }
                setPassword(e);
              }}
              onBlur={() =>
                trackUsernameEditStepCompleted?.({
                  subscriptionId: userData.subscriptionType,
                  editUsernameStepName: EditUsernameStepName.ADD_PASSWORD,
                })
              }
              value={password}
              valid={!passwordError}
              errorMessage={passwordError}
              label="Your password"
              secondaryLabel="Before we change your email, let's verify it's you."
            />
          </div>
          <div className="flex mt-[15px] mb-[15px] justify-center">
            <Captcha ref={captchaRef} onLoad={onLoad} invisible />
          </div>
          <div className="flex gap-[10px] justify-end">
            <Button
              type="button"
              secondary
              onClick={onClose}
              className="w-[110px] font-medium text-15"
            >
              Cancel
            </Button>
            <Button
              loading={updateEmailState.loading}
              disabled={
                loadingCaptcha ||
                newEmail === user.email ||
                emailError !== null ||
                passwordError !== null
              }
              type="submit"
              primary
              className="min-w-[110px] font-medium text-15"
            >
              Save email
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
