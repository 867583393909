import React, { useContext, useEffect, useState } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import InviteUserTag from "src/components/elements/InviteUserTag";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { BulkInviteButton } from "src/components/blocks/bulk-invite/Button";

import Invite from "src/models/invite";
import { isTeamsProSubscription } from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import { checkIsAlreadyInTeam } from "src/services/teams";
import {
  teamsInviteFailureReasons,
  trackBulkUploadStarted,
  trackTeamsInviteEmailAdded,
  trackTeamsInviteFailed,
} from "src/services/tracking";
import {
  cloneObjectWithClassInstance,
  isValidEmail,
  plural,
} from "src/services/utils";

import { useUser } from "../context/UserContext";

export default InviteUsers;

const MAX_USERS_FOR_CHECKOUT = 1000;

function InviteUsers({
  invites,
  defaultUserType,
  availableSeats,
  show,
  onClose,
  onNext,
  onUpgrade,
  hideDisabledRoleOptions,
  subscription,
  nextBtnText,
  onBulkInviteButtonClick,
  title,
  subtitle,
}) {
  const { team } = useContext(TeamContext);
  const { user: me } = useUser();

  const [inputAreaFocused, setInputAreaFocused] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inOtherTeams, setInOtherTeams] = useState([]);
  const inputRef = React.createRef();

  const _addTags = async (newTags) => {
    const _tags = newTags.filter((tag) => {
      if (tag?.email?.trim() === "") return false;
      return !tags.map((t) => t.email).includes(tag.email);
    });
    setTags((tags) => tags.concat(_tags));
    validateExistingEmails(_tags.filter(({ email }) => isValidEmail(email)));
  };

  const validateExistingEmails = async (emails = []) => {
    const noValidEmails = emails.every(({ email }) => !isValidEmail(email));

    if (emails.length === 0 || noValidEmails) return false;

    try {
      const res = await checkIsAlreadyInTeam(team.id, emails);

      res.existing.forEach(() => {
        trackTeamsInviteFailed({
          reason: teamsInviteFailureReasons.USER_ON_ANOTHER_TEAM,
        });
      });

      setInOtherTeams([...inOtherTeams, ...res.existing]);

      return res.existing.length > 0;
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkInviteButtonClick = () => {
    trackBulkUploadStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      plan: team.subscription?.plan,
    });
    onBulkInviteButtonClick();
  };

  const handleRemoveTag = (tag) => {
    console.log("Remove: ", tag);
    setTags((tags) => tags.filter((t) => t.email !== tag.email));
    setInOtherTeams((inOtherTeams) =>
      inOtherTeams.filter((e) => e !== tag.email)
    );

    inputRef?.current?.focus();
  };

  const handleClearAllFromOtherTeams = () => {
    setTags(tags.filter((t) => !inOtherTeams.includes(t.email)));
    setInOtherTeams([]);
  };

  const handleUpgradeClicked = (plan) => {
    onUpgrade(tags, plan);
  };

  const handleEmailChange = (tag, email) => {
    const index = tags.findIndex((t) => t.id === tag.id);

    const tagsClone = tags.map(cloneObjectWithClassInstance);

    const tagClone = cloneObjectWithClassInstance(tagsClone[index]);

    tagClone.email = email;
    tagClone.focus = false;

    tagsClone[index] = tagClone;

    setTags(tagsClone);

    inputRef?.current?.focus();

    validateExistingEmails([{ email }]);
  };

  const handleSubmitInvitesClicked = async () => {
    if (loading) return;

    const hasExistingEmails = await validateExistingEmails(
      tags.filter((t) => isValidEmail(t.email))
    );

    if (hasExistingEmails) return;

    setLoading(true);

    try {
      tags.forEach((t) => trackTeamsInviteEmailAdded({ email: t.email }));
      await onNext(tags);
    } catch (err) {
      console.log(err);
      trackTeamsInviteFailed({ reason: teamsInviteFailureReasons.OTHER });
    } finally {
      setTags([]);
      setInOtherTeams([]);
      setLoading(false);
    }
  };

  const _createTags = (val, autoFocus = false) => {
    if (!val || val.trim() === "") return;
    val = val.replace(/\s+/g, ",");
    _addTags(
      val
        .split(",")
        .filter((v) => v && v.trim() !== "")
        .map(
          (email) =>
            new Invite(email, defaultUserType || USER_TYPES.DRIVER, autoFocus)
        )
    );
  };

  const _removeLastTag = () => {
    handleRemoveTag(tags.pop());
  };

  useEffect(() => {
    if (invites && invites.length) {
      _addTags(invites);
    }
  }, [invites]);

  if (!me.id || !team?.id) return null;
  const btnText = nextBtnText || "Proceed to Confirmation";

  const invalidEmails = tags
    .filter((t) => !isValidEmail(t.email) && !t.focus)
    .map((t) => t.email);

  const errors = [];

  if (invalidEmails.length > 0) {
    errors.push({
      code: "invalid_emails",
      label: (
        <div className="flex items-center">
          <Text color="red">
            It looks like some of the email addresses above aren't valid.
          </Text>
        </div>
      ),
    });
  }

  if (inOtherTeams.length > 0) {
    errors.push({
      code: "in_other_teams",
      label: (
        <div className="flex items-center">
          <Text color="red">
            {`${inOtherTeams.length} ${plural(
              inOtherTeams.length,
              "user",
              "users"
            )} already part of another team: `}
            <Text color="black/70" className="mr-2">
              {inOtherTeams.join(", ")}
            </Text>
            <Button
              link
              onClick={handleClearAllFromOtherTeams}
              className="inline-block text-black"
            >
              Remove
            </Button>
          </Text>
        </div>
      ),
    });
  }

  if (tags.length > 0 && !isTeamsProSubscription(team.subscription?.plan)) {
    const hasPaidInvites = tags.find((t) => t.userType !== USER_TYPES.ADMIN);
    if (!hasPaidInvites) {
      errors.push({
        code: "no_paid_invites",
        label: (
          <div className="flex items-center">
            <Text paragraph>
              <Text color="red">
                In order to proceed, add at least one driver to your team.
              </Text>{" "}
              Or{" "}
              <a
                href="https://support.mileiq.com"
                target="_blank"
                className="text-black hover:text-black underline"
              >
                contact customer support
              </a>
            </Text>
          </div>
        ),
      });
    }
  }

  const valid = errors.length === 0;

  return (
    <Modal
      show={show}
      onClose={() => {
        // NOTE: regarding the bulk invite, we should not clean on close modal until parent says so
        // setTags([]);
        onClose({ setTags, setInOtherTeams });
      }}
      loading={loading}
      className="w-[720px] invite-users-modal"
      passiveBackdrop
    >
      <>
        <h3 className="text-center">{title || "Invite users"}</h3>
        <div className="mt-2 m-auto md:w-[440px]">
          <Text color="black/70" paragraph center className="mt-2.5">
            {subtitle ||
              "We'll send them an invitation to join you on MileIQ for Teams."}
          </Text>
        </div>
        <div className="invite-users-input-legend flex flex-col mt-[30px] mb-2">
          <Text custom className="text-15 font-medium">
            Type or paste email addresses
          </Text>
        </div>
        <div
          className="mb-5"
          style={{
            height: "180px",
            padding: inputAreaFocused || !valid ? 0 : "1px",
          }}
        >
          <label
            className={`h-full p-1 overflow-auto flex flex-wrap items-start justify-start content-start rounded ${
              !valid
                ? "border-2 border-red"
                : inputAreaFocused
                ? "border-2 border-blue-medium"
                : "border border-border-2"
            }`}
          >
            {tags.map((t) => (
              <InviteUserTag
                key={t.id}
                onClose={() => handleRemoveTag(t)}
                onSelectType={(type) => {
                  t.userType = type;
                  setTags([...tags]);
                }}
                onUpgrade={(plan, email) => {
                  t.email = email;
                  t.focus = false;

                  handleUpgradeClicked(plan);
                }}
                onEmailChange={(email) => handleEmailChange(t, email)}
                tag={t}
                forceInvalid={inOtherTeams.includes(t.email)}
                hideDisabledRoleOptions={hideDisabledRoleOptions}
                subscription={subscription}
              />
            ))}
            <input
              autoFocus
              className="w-auto flex-grow border-none outline-none px-2 h-9 m-1"
              ref={inputRef}
              placeholder={
                tags.length === 0
                  ? "Separate emails with a comma, enter, or space."
                  : ""
              }
              onFocus={() => {
                setInputAreaFocused(true);
              }}
              onBlur={() => {
                setInputAreaFocused(false);
              }}
              onPaste={(e) => {
                e.preventDefault();
                _createTags(e.clipboardData.getData("text"));
                e.target.value = "";
              }}
              onKeyDown={(e) => {
                if (e.key === "@") {
                  e.preventDefault();

                  _createTags(`${e.target.value}@`, true);

                  e.target.value = "";
                } else if (["Enter", ",", " "].includes(e.key)) {
                  e.preventDefault();

                  _createTags(e.target.value);

                  e.target.value = "";
                } else if (e.key === "Backspace" && e.target.value === "") {
                  _removeLastTag();
                }
              }}
            />
          </label>
        </div>
        {tags.length > MAX_USERS_FOR_CHECKOUT ? (
          <div className="mt-3">
            <p className="flex items-center">
              <Icon name="alert" color="red" className="mr-2" />
              <Text>
                Wow, that’s an impressive organization! To add more than{" "}
                {MAX_USERS_FOR_CHECKOUT}
                users,{" "}
                <a
                  className="font-bold underline text-black"
                  href="https://support.mileiq.com"
                  target="_blank"
                  rel="noopener"
                >
                  please contact our customer support
                </a>{" "}
                who will kindly add them for you!
              </Text>
            </p>
          </div>
        ) : (
          !valid && (
            <div className="mt-3">
              {errors.map((err) => {
                return (
                  <div key={err.code} className="flex my-2">
                    <Icon name="alert" color="red" className="mr-3" />
                    {err.label}
                  </div>
                );
              })}
            </div>
          )
        )}
        {availableSeats ? (
          <div className="bg-green-pastel flex items-center mt-8 p-5 rounded">
            <div>
              <Icon name="chair" color="black" className="mr-3" />
            </div>
            <Text paragraph lg>
              You have{" "}
              <Text bold className="text-green">
                {`${availableSeats} open ${plural(
                  availableSeats,
                  "seat",
                  "seats"
                )}`}
              </Text>
            </Text>
          </div>
        ) : null}
        <BulkInviteButton onClick={handleBulkInviteButtonClick} />
        <Button
          lg
          appearance="primary"
          className="w-full mt-[30px]"
          disabled={
            tags.length === 0 || !valid || tags.length > MAX_USERS_FOR_CHECKOUT
          }
          onClick={handleSubmitInvitesClicked}
        >
          {btnText}
        </Button>
      </>
    </Modal>
  );
}
