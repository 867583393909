import "src/models/typings";

import React from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Modal from "src/components/elements/Modal";

import Button from "../elements/Button";
import Text from "../elements/Text";

export default UnsavedChangesPromptModal;

export const ELEMENT_ID = "UNSAVED_CHANGES_PROMPT_MODAL";
registerElement(ELEMENT_ID, UnsavedChangesPromptModal);

function UnsavedChangesPromptModal({
  title,
  message,
  cancelLabel,
  okLabel,
  onCancel,
  onOk,
  okLoading,
}) {
  const handleCancel = async () => {
    onCancel();
  };

  const handleOk = async () => {
    onOk();
  };

  return (
    <Modal
      closable={false}
      className="w-[440px]"
      testId="unsaved-changes-modal"
    >
      <div className="flex flex-col justify-between content-between">
        <div>
          <h5 className="mb-[10px]">
            {title || "Your changes haven't been saved"}
          </h5>
          <Text paragraph lg>
            {message || "Would you like to save these changes?"}
          </Text>
        </div>
        <div className="flex self-end mt-[30px]">
          <Button
            ghost
            secondary
            type="button"
            className="font-medium mr-2.5"
            onClick={handleCancel}
            disabled={okLoading}
          >
            {cancelLabel || "Discard changes"}
          </Button>
          <Button
            onClick={handleOk}
            className="font-medium"
            loading={okLoading}
            disabled={okLoading}
          >
            {okLabel || "Save changes"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
