import y from "../common/base-provider.js";
import { newInAppMessageFromJson as lt } from "../InAppMessage/in-app-message-factory.js";
import ue from "../models/braze-event.js";
import { InAppMessage } from "../InAppMessage/index.js";
import {
  isArray as p,
  isEqual as ii,
  validateValueIsFromEnum as F
} from "../util/code-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import wt from "../InAppMessage/models/templated-in-app-message.js";
import mt from "./models/trigger.js";
import V from "./models/trigger-events.js";
import r from "../../shared-lib/braze-shared-lib.js";
export default class gr extends y {
  constructor(t, i, s, e, r) {
    super(),
      (this.tg = t),
      (this.yt = i),
      (this.h = s),
      (this.si = e),
      (this.ig = r),
      (this.sg = []),
      (this.eg = []),
      (this.hg = null),
      (this.og = {}),
      (this.ng = {}),
      this.ag(),
      this.lg();
  }
  gg() {
    (this.hg = this.h.I(o.q.Na) || this.hg),
      (this.og = this.h.I(o.q.va) || this.og),
      (this.ng = this.h.I(o.q.Ga) || this.ng);
    for (let t = 0; t < this.cg.length; t++) {
      const i = this.cg[t];
      null != this.ng[i.id] && i.Zh(this.ng[i.id]);
    }
  }
  ag() {
    this.fg = this.h.I(o.q.Da) || 0;
    const t = this.h.I(o.q.Ya) || [],
      i = [];
    for (let s = 0; s < t.length; s++) i.push(mt.Sn(t[s]));
    (this.cg = i), this.gg();
  }
  lg() {
    const t = this,
      i = function(i, s, e, r, h) {
        return function() {
          t.ug(i, s, e, r, h);
        };
      },
      s = {};
    for (let t = 0; t < this.cg.length; t++) s[this.cg[t].id] = this.cg[t];
    let e = !1;
    for (let t = 0; t < this.cg.length; t++) {
      const r = this.cg[t];
      if (null != this.og[r.id]) {
        const t = this.og[r.id],
          h = [];
        for (let e = 0; e < t.length; e++) {
          const o = t[e],
            n = r.gu(o.Si);
          if (n > 0) {
            let t, e;
            h.push(o),
              null != o.dg && (t = o.dg),
              null != o.mg && ue.Fa(o.mg) && (e = ue.Sn(o.mg));
            const a = [];
            if (p(o.pg))
              for (let t = 0; t < o.pg.length; t++) {
                const i = s[o.pg[t]];
                null != i && a.push(i);
              }
            this.eg.push(setTimeout(i(r, o.Si, t, e, a), n));
          }
        }
        this.og[r.id].length > h.length &&
          ((this.og[r.id] = h),
          (e = !0),
          0 === this.og[r.id].length && delete this.og[r.id]);
      }
    }
    e && this.h.B(o.q.va, this.og);
  }
  bg() {
    const t = [];
    for (let i = 0; i < this.cg.length; i++) t.push(this.cg[i].ss());
    (this.fg = new Date().valueOf()),
      this.h.B(o.q.Ya, t),
      this.h.B(o.q.Da, this.fg);
  }
  Tg() {
    (this.h.I(o.q.Da) || 0) > this.fg ? this.ag() : this.gg();
  }
  Rs(t) {
    let i = !1;
    if (null != t && t.triggers) {
      this.gg();
      const s = {},
        e = {};
      this.cg = [];
      for (let r = 0; r < t.triggers.length; r++) {
        const h = mt.fromJson(t.triggers[r]);
        null != this.ng[h.id] &&
          (h.Zh(this.ng[h.id]), (s[h.id] = this.ng[h.id])),
          null != this.og[h.id] && (e[h.id] = this.og[h.id]);
        for (let t = 0; t < h.Ph.length; t++)
          if (h.Ph[t].ec(V.ks, null)) {
            i = !0;
            break;
          }
        null != h && this.cg.push(h);
      }
      ii(this.ng, s) || ((this.ng = s), this.h.B(o.q.Ga, this.ng)),
        ii(this.og, e) || ((this.og = e), this.h.B(o.q.va, this.og)),
        this.bg(),
        i &&
          (r.D.info("Trigger with test condition found, firing test."),
          this.je(V.ks)),
        this.je(V.OPEN);
      const h = this.sg;
      this.sg = [];
      for (let t = 0; t < h.length; t++) this.je.apply(this, h[t]);
    }
  }
  async ug(t, i, s, e, h) {
    const o = e => {
        this.gg();
        const h = new Date().valueOf();
        if (!t.pu(i))
          return !1 === navigator.onLine && t.type === mt._i.Ti && e.imageUrl
            ? (r.D.info(
                `Not showing ${t.type} trigger action ${t.id} due to offline state.`
              ),
              void this.ig.ni(t.id, InAppMessage.li.nh))
            : void (t.Yh(h) && this.yg(t, h, s)
                ? 0 === this.yt.bu()
                  ? r.D.info(
                      `Not displaying trigger ${t.id} because neither automaticallyShowInAppMessages() nor subscribeToInAppMessage() were called.`
                    )
                  : (this.yt.St([e]), this.vg(t, h))
                : r.D.info(
                    `Not displaying trigger ${t.id} because display time fell outside of the acceptable time window.`
                  ));
        t.type === mt._i.Tu
          ? this.ig.ni(t.id, InAppMessage.li.zi)
          : this.ig.ni(t.id, InAppMessage.li.rh);
      },
      n = () => {
        this.gg();
        const o = h.pop();
        if (null != o)
          if ((this.wg(o, i, s, e, h), o.pu(i))) {
            let t = `Server aborted in-app message display, but the timeout on fallback trigger ${o.id} has already elapsed.`;
            h.length > 0 && (t += " Continuing to fall back."),
              r.D.info(t),
              this.ig.ni(o.id, InAppMessage.li.rh),
              n();
          } else {
            r.D.info(
              `Server aborted in-app message display. Falling back to lower priority ${o.type} trigger action ${t.id}.`
            );
            const n = 1e3 * o.$h - (new Date().valueOf() - i);
            n > 0
              ? this.eg.push(
                  setTimeout(() => {
                    this.ug(o, i, s, e, h);
                  }, n)
                )
              : this.ug(o, i, s, e, h);
          }
      };
    switch (t.type) {
      case mt._i.Ti:
        const h = lt(t.data);
        if (null == h) {
          r.D.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`
          ),
            this.ig.ni(t.id, InAppMessage.li.ki);
          break;
        }
        let a = this.ig.vi(h);
        if (a) {
          r.D.error(a), n();
          break;
        }
        o(h);
        break;
      case mt._i.Tu:
        const l = wt.fromJson(t.data, o, n, i, t.$i);
        if (null == l) {
          r.D.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`
          ),
            this.ig.ni(t.id, InAppMessage.li.ki);
          break;
        }
        this.ig.Ii(l, s, e);
        break;
      default:
        r.D.error(
          `Trigger ${t.id} was of unexpected type ${t.type}, ignoring.`
        ),
          this.ig.ni(t.id, InAppMessage.li.ki);
    }
  }
  je(t, i, s) {
    if (!F(V, t, "Cannot fire trigger action.", "TriggerEvents")) return;
    if (this.si.Ml())
      return (
        r.D.info(
          "Trigger sync is currently in progress, awaiting sync completion before firing trigger event."
        ),
        void this.sg.push(arguments)
      );
    this.Tg();
    const e = new Date().valueOf(),
      h = e - this.hg;
    let o = !0,
      n = !0;
    const a = [];
    for (let s = 0; s < this.cg.length; s++) {
      const r = this.cg[s],
        h = e + 1e3 * r.$h;
      if (
        r.Yh(h) &&
        (null == r.startTime || r.startTime <= e) &&
        (null == r.endTime || r.endTime >= e)
      ) {
        let s = !1;
        for (let e = 0; e < r.Ph.length; e++)
          if (r.Ph[e].ec(t, i)) {
            s = !0;
            break;
          }
        s && ((o = !1), this.yg(r, h, t) && ((n = !1), a.push(r)));
      }
    }
    if (o)
      return void r.D.info(
        `Trigger event ${t} did not match any trigger conditions.`
      );
    if (n)
      return void r.D.info(
        `Ignoring ${t} trigger event because a trigger was displayed ${h /
          1e3}s ago.`
      );
    a.sort((t, i) => t.priority - i.priority);
    const l = a.pop();
    null != l &&
      (r.D.info(
        `Firing ${l.type} trigger action ${l.id} from trigger event ${t}.`
      ),
      this.wg(l, e, t, s, a),
      0 === l.$h
        ? this.ug(l, e, t, s, a)
        : this.eg.push(
            setTimeout(() => {
              this.ug(l, e, t, s, a);
            }, 1e3 * l.$h)
          ));
  }
  changeUser(t) {
    if (((this.cg = []), this.h.Xs(o.q.Ya), !t)) {
      (this.sg = []), (this.hg = null), (this.ng = {}), (this.og = {});
      for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
      (this.eg = []), this.h.Xs(o.q.Na), this.h.Xs(o.q.Ga), this.h.Xs(o.q.va);
    }
  }
  clearData() {
    (this.cg = []), (this.hg = null), (this.ng = {}), (this.og = {});
    for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
    this.eg = [];
  }
  yg(t, i, s) {
    if (null == this.hg) return !0;
    if (s === V.ks)
      return (
        r.D.info(
          "Ignoring minimum interval between trigger because it is a test type."
        ),
        !0
      );
    let e = t.Kh;
    return null == e && (e = this.tg), i - this.hg >= 1e3 * e;
  }
  wg(t, i, s, e, r) {
    this.gg(), (this.og[t.id] = this.og[t.id] || []);
    const h = {};
    let n;
    (h.Si = i), (h.dg = s), null != e && (n = e.ss()), (h.mg = n);
    const a = [];
    for (let t = 0; t < r.length; t++) a.push(r[t].id);
    (h.pg = a), this.og[t.id].push(h), this.h.B(o.q.va, this.og);
  }
  vg(t, i) {
    this.gg(),
      t.Zh(i),
      (this.hg = i),
      this.h.B(o.q.Na, i),
      (this.ng[t.id] = i),
      this.h.B(o.q.Ga, this.ng);
  }
}
