import React from "react";

import Button from "src/components/elements/Button";
import Text from "src/components/elements/Text";

export { BulkInviteButton };

function BulkInviteButton(props) {
  const { label = "Upload an email list", color = "blue", onClick } = props;
  return (
    <Button
      ghost
      nohover
      color={color}
      icon="file-upload"
      className="mt-5 px-0"
      onClick={onClick}
    >
      <Text semibold>{label}</Text>
    </Button>
  );
}
