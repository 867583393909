import React, { useRef, useState } from "react";
import { useContext } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import { useTeamsCommuteSettings } from "src/components/context/TeamsCommuteSettingsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Icon from "src/components/elements/Icon";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

import AddPurposeForm from "src/components/blocks/drives/classification/AddPurposeForm";

import Purpose, { PURPOSE_CATEGORY } from "src/models/purpose";

import {
  filterOutCommutePurpose,
  getPurposesDataForCategory,
} from "src/services/purposes";

export default PurposePicker;

const PURPOSE_SELECT_ELEMENT_ID = "PURPOSE_SELECT";

/**
 *
 * @param {{ selected: Purpose}} props
 * @returns
 */
function PurposePicker({
  short,
  selected,
  onStart,
  onSelect,
  onAddNewStarted,
  onAddNewCompleted,
  className,
}) {
  if (!selected || selected.isUnclassified) {
    return (
      <PickerButtons
        short={short}
        onStart={onStart}
        onSelect={onSelect}
        onAddNewStarted={onAddNewStarted}
        onAddNewCompleted={onAddNewCompleted}
        className={className}
      />
    );
  } else {
    return (
      <PickerSelect
        short={short}
        selected={selected}
        onStart={onStart}
        onSelect={onSelect}
        onAddNewStarted={onAddNewStarted}
        onAddNewCompleted={onAddNewCompleted}
        className={className}
      />
    );
  }
}

function PickerButtons({
  short,
  onStart = () => {},
  onSelect,
  onAddNewStarted,
  onAddNewCompleted,
  className,
}) {
  const [loading, setLoading] = useState(false);
  const pickerRefBusiness = useRef();
  const pickerRefPersonal = useRef();

  const sp = useElement(PURPOSE_SELECT_ELEMENT_ID, {
    props: {
      onSelectPurpose: async (p) => {
        sp.deactivate();
        setLoading(true);
        await onSelect(p);
        setLoading(false);
      },
      onAddNewStarted,
      onAddNewCompleted,
      onClickOutside: () => {
        sp.deactivate();
      },
    },
  });

  const handlePickBusinessPurpose = () => {
    sp.activate({
      onActivate: () => {
        onStart({ category: PURPOSE_CATEGORY.BUSINESS });
      },
      props: {
        trigger: pickerRefBusiness.current,
        onlyForCategory: PURPOSE_CATEGORY.BUSINESS,
      },
    });
  };

  const handlePickPersonalPurpose = () => {
    sp.activate({
      onActivate: () => {
        onStart({ category: PURPOSE_CATEGORY.PERSONAL });
      },
      props: {
        trigger: pickerRefPersonal.current,
        onlyForCategory: PURPOSE_CATEGORY.PERSONAL,
      },
    });
  };

  let btnClass = "flex items-center justify-center rounded-14-12";
  if (short) {
    btnClass += " h-[40px] laptop:h-[38px]";
  } else {
    btnClass += " h-40-35";
  }

  const bizActive =
    sp.open && sp.getProps()?.onlyForCategory === PURPOSE_CATEGORY.BUSINESS;
  const persActive =
    sp.open && sp.getProps()?.onlyForCategory === PURPOSE_CATEGORY.PERSONAL;

  if (loading) {
    return (
      <div className="h-[40px] flex items-center">
        <Icon name="spinner" />
        <Text md={short} className="ml-3">
          Loading...
        </Text>
      </div>
    );
  }

  return (
    <div
      data-testid="purpose-picker-buttons"
      className={`miq-purpose-picker grid gap-[7px] ${
        short
          ? "grid-cols-[40px_40px] laptop:grid-cols-[38px_38px]"
          : "grid-cols-2"
      } ${className || ""}`}
    >
      <button
        data-testid="purpose-picker-buttons-business"
        ref={pickerRefBusiness}
        onClick={handlePickBusinessPurpose}
        className={
          btnClass + ` ${bizActive ? "bg-blue" : "bg-purpose-business"}`
        }
      >
        <div className="flex items-center">
          <Icon name="suitcase" color={bizActive ? "white" : "black"} />
          {!short && (
            <Text
              semibold
              className="leading-none ml-3"
              color={bizActive ? "white" : "black"}
            >
              Business
            </Text>
          )}
        </div>
      </button>
      <button
        data-testid="purpose-picker-buttons-personal"
        ref={pickerRefPersonal}
        onClick={handlePickPersonalPurpose}
        className={
          btnClass + ` ${persActive ? "bg-yellow" : "bg-purpose-personal"}`
        }
      >
        <div className="flex items-center">
          <Icon name="home" color="black" />
          {!short && (
            <Text semibold className="leading-none ml-3">
              Personal
            </Text>
          )}
        </div>
      </button>
    </div>
  );
}

/**
 *
 * @param {{ selected: Purpose}} props
 * @returns
 */
function PickerSelect({
  short,
  selected,
  onStart = () => {},
  onSelect,
  onAddNewStarted,
  onAddNewCompleted,
  className,
}) {
  const { userData } = useContext(UserDataContext);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const pickerRef = useRef();

  const sp = useElement(PURPOSE_SELECT_ELEMENT_ID, {
    onActivate: () => {
      onStart({ category: selected.category });
    },
    props: {
      onSelectPurpose: async (p) => {
        sp.deactivate();
        setLoading(true);
        await onSelect(p);
        setLoading(false);
      },
      onAddNewStarted,
      onAddNewCompleted,
      onClickOutside: () => {
        sp.deactivate();
      },
    },
  });

  const handlePickPurpose = () => {
    sp.activate({
      props: {
        selected,
        trigger: pickerRef.current,
      },
    });
  };

  const purposes = getPurposesDataForCategory(
    userData.purposes,
    selected.category
  );
  const { label, icon, category } =
    purposes.find((p) => p.id === selected.id) || purposes[0];

  const getIconName = (category) => {
    switch (category) {
      case PURPOSE_CATEGORY.PERSONAL:
        return "home";
      case PURPOSE_CATEGORY.BUSINESS:
        return "suitcase";
      default:
        return icon;
    }
  };

  const isActive = sp.open;

  let btnClass = "truncate flex items-center rounded-14-12 ";
  let txtClass = "leading-none";
  let iconClass = "";
  let iconColor = "black";
  if (short) {
    btnClass += " px-1 py-[6px]";
    if (isHovered || isActive) btnClass += " bg-[#e8e5e3]";
    iconClass += " scale-[0.8]";
  } else {
    btnClass += " w-full h-40-35 px-4 justify-between";
    if (selected.category === PURPOSE_CATEGORY.BUSINESS) {
      if (isHovered || isActive) {
        btnClass += " bg-blue";
        iconColor = "white";
        txtClass += " text-white";
      } else {
        btnClass += " bg-purpose-business";
      }
    }
    if (selected.category === PURPOSE_CATEGORY.PERSONAL) {
      if (isHovered || isActive) {
        btnClass += " bg-yellow";
      } else {
        btnClass += " bg-purpose-personal";
      }
    }
  }

  return (
    <div
      className={`miq-purpose-picker ${className || ""}`}
      data-testid="purpose-picker-select"
    >
      <button
        data-testid="purpose-picker-select-btn"
        ref={pickerRef}
        onClick={handlePickPurpose}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={btnClass}
        disabled={loading}
      >
        {loading ? (
          <div className="flex items-center">
            <Icon name="spinner" />
            <Text md={short} className="ml-3">
              Loading...
            </Text>
          </div>
        ) : (
          <>
            <span
              className={`grid ${
                short
                  ? "gap-[7px] grid-cols-[24px_auto_17px]"
                  : "w-full gap-[15px] grid-cols-[24px_1fr_20px]"
              } items-center`}
            >
              <Icon
                name={getIconName(category)}
                color={iconColor}
                className={iconClass}
              />
              <Text
                semibold
                md={short}
                className={txtClass}
                data-testid="purpose-picker-select-label"
              >
                {label}
              </Text>
              {!short || isHovered || isActive ? (
                <Icon name="caret" color={iconColor} className={iconClass} />
              ) : null}
            </span>
          </>
        )}
      </button>
    </div>
  );
}

registerElement(PURPOSE_SELECT_ELEMENT_ID, PurposeOptionsList);
function PurposeOptionsList({
  trigger,
  selected,
  onlyForCategory,
  onSelectPurpose,
  onAddNewStarted = () => {},
  onAddNewCompleted = () => {},
  onClickOutside,
}) {
  const { userData } = useContext(UserDataContext);
  const { shouldFilterOutCommutePurpose } = useTeamsCommuteSettings();
  const [viewCategory, setViewCategory] = useState(
    onlyForCategory || selected?.category || PURPOSE_CATEGORY.BUSINESS
  ); // used to toggle between list of options

  const [showAddPurpForm, setShowAddPurpForm] = useState(false);

  const handleSelect = (purpose) => {
    onSelectPurpose(
      new Purpose({
        label: purpose.label,
        category: viewCategory,
        id: purpose.id,
      })
    );
  };

  const handleAddNewClicked = () => {
    setShowAddPurpForm(true);
    onAddNewStarted({ category: viewCategory });
  };

  const handleNewPurpAdded = (p) => {
    setShowAddPurpForm(false);
    onSelectPurpose(new Purpose(p));
    onAddNewCompleted({ category: viewCategory });
  };

  const handleCancelAddNewPurp = () => {
    setShowAddPurpForm(false);
  };

  const [mainPurpose, ...purposes] = getPurposesDataForCategory(
    shouldFilterOutCommutePurpose
      ? filterOutCommutePurpose(userData.purposes)
      : userData.purposes,
    onlyForCategory || viewCategory
  );

  const showBoth = !onlyForCategory;

  return (
    <Pivoted
      pivot={trigger}
      offset={{ y: 5 }}
      onClickOutside={onClickOutside}
      style={{ zIndex: 40000 }}
      bottom
      adjustPosition={(top, left, elRect, pivotRect) => {
        let t = top;
        let l = left;
        const maxTop =
          document.documentElement.clientHeight - elRect.height - 10;
        const maxLeft =
          document.documentElement.clientWidth - elRect.width - 10;
        if (t > maxTop) {
          t = maxTop;
          l = l + pivotRect.width + 5; // try to place to the right of the trigger
          if (l > maxLeft) {
            l = left - elRect.width - 5; // place to the left of the trigger
          }
        } else if (l > maxLeft) {
          l = left + pivotRect.width - elRect.width; // align to the right edge of the screen
        }

        return { top: t, left: l };
      }}
    >
      <div
        data-testid="purpose-options"
        className={`${
          showBoth ? "min-w-[250px]" : "min-w-[200px]"
        } w-full p-2 pb-2 bg-white rounded border border-border-1 shadow-md shadow-[#0003]`}
      >
        {showBoth ? (
          <div
            data-testid="purpose-options-tabs"
            className="relative mx-2 grid grid-cols-2 h-40-35 border-b border-border-1"
          >
            <button onClick={() => setViewCategory(PURPOSE_CATEGORY.BUSINESS)}>
              <Text semibold>Business</Text>
            </button>
            <button onClick={() => setViewCategory(PURPOSE_CATEGORY.PERSONAL)}>
              <Text semibold>Personal</Text>
            </button>
            <div
              className={`transition-transform ${
                viewCategory === PURPOSE_CATEGORY.PERSONAL
                  ? "translate-x-full"
                  : ""
              } absolute bottom-0 left-0 h-[2px] w-1/2 bg-blue`}
            />
          </div>
        ) : (
          <Text paragraph bold className="px-2 pt-2">
            Select a purpose:
          </Text>
        )}
        <div className="mt-2">
          <button
            className="w-full p-2 rounded-8 hover:bg-beige flex items-center"
            onClick={() => handleSelect(mainPurpose)}
          >
            <Icon name={mainPurpose.icon} />
            <Text className="ml-3">{mainPurpose.label}</Text>
          </button>
          <div className="my-1 mx-2 border-b border-border-1" />
        </div>

        <ul className="m-0 p-0 max-h-[400px] overflow-auto">
          {purposes
            .filter((p) => !p.isHidden)
            .map((p) => {
              return (
                <li key={p.id} className="w-full">
                  <button
                    className="w-full p-2 rounded-8 hover:bg-beige flex items-center"
                    onClick={() => handleSelect(p)}
                  >
                    <Icon name={p.icon} />
                    <Text
                      data-testid="purpose-options-list-item-label"
                      className="ml-3"
                    >
                      {p.label}
                    </Text>
                  </button>
                </li>
              );
            })}
          <li key="add-new-purpose" className="w-full">
            {showAddPurpForm ? (
              <div className="p-2">
                <AddPurposeForm
                  sm
                  category={viewCategory}
                  onAdd={handleNewPurpAdded}
                  onCancel={handleCancelAddNewPurp}
                  cancelBtnProps={{ secondary: true }}
                  existingPurposes={purposes}
                />
              </div>
            ) : (
              <button
                data-testid="purpose-options-add-new-btn"
                className="w-full p-2  flex items-center"
                onClick={handleAddNewClicked}
              >
                <Icon name="add-circle" />
                <Text semibold className="ml-3">
                  Add purpose
                </Text>
              </button>
            )}
          </li>
        </ul>
      </div>
    </Pivoted>
  );
}
