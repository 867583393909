import React from "react";

import { BlogSource, trackBlogSelected } from "src/services/tracking";

export default QuickTipsLink;

function QuickTipsLink({ url, children }) {
  const handleClick = () => {
    trackBlogSelected({ src: BlogSource.QUICK_TIPS });
  };
  return (
    <a
      href={url}
      onClick={handleClick}
      target="_blank"
      rel="noopener"
      className="underline"
    >
      {children}
    </a>
  );
}
