import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { UserDataContext, useUser } from "src/components/context/UserContext";

import MiqLoader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { tryRefreshToken } from "src/services/auth";
import { createTeamsCheckoutSession } from "src/services/billing";
import { createTeam } from "src/services/teams";
import { trackTeamsReverseTrialStarted } from "src/services/tracking";
import { timeout } from "src/services/utils";

import CarIllustration from "public/assets/img/car-flowers.svg";
import MiqLogo from "public/assets/img/miq-logo-black-orange.svg";

export function StartTrialPage() {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const { team, refreshTeam } = useContext(TeamContext);
  const [redirecting, setRedirecting] = useState(false);

  const { user } = useUser();
  const { userData } = useContext(UserDataContext);

  function handleError(e) {
    console.error(e);
    push("/");
  }

  async function handleStartTrial() {
    if (loading || redirecting) return;
    if (team) {
      push("/teams");
      return;
    }
    setLoading(true);

    try {
      // create a random team for this user
      const teamPrefix =
        user?.firstName || user?.lastName || user?.email.split("@")[0];
      const country = userData?.country || "US";
      await createTeam(
        teamPrefix ? `${teamPrefix}'s Team`.trim() : "My Team",
        country
      );
      await tryRefreshToken();

      // headless checkout
      await createTeamsCheckoutSession(
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
        TEAM_SUBSCRIPTION_TYPES.MONTH,
        1,
        null,
        false,
        true
      );

      setLoading(false);
      setRedirecting(true);
      trackTeamsReverseTrialStarted();
      timeout(3000).then(async () => {
        await refreshTeam();
        timeout(1000).then(() => {
          push("/teams");
        });
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleStartTrial();
  }, []);

  if (redirecting) {
    return (
      <div className="page-onboarding page-onboarding-teams bg-white w-screen h-screen relative">
        <span className="logo absolute top-8 left-8">
          <MiqLogo />
        </span>
        <div className="text-center flex flex-col items-center max-w-[540px] m-auto h-full justify-center">
          <h2 className="mt-6 whitespace-pre-line">
            Getting you trial ready 🪄
          </h2>
          <Text paragraph className="mt-3 text-black/70">
            in 3...2...1
          </Text>
          <div className="mt-14">
            <CarIllustration />
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="page-onboarding page-onboarding-teams bg-white w-screen h-screen relative">
        <span className="logo absolute top-8 left-8">
          <MiqLogo />
        </span>
        <div className="text-center flex flex-col items-center max-w-[540px] m-auto h-full justify-center">
          <MiqLoader />
        </div>
      </div>
    );
  }

  return false;
}
