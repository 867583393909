import e from "../managers/braze-instance.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as or } from "../common/constants.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import pe from "./in-app-message-manager-factory.js";
import r from "../../shared-lib/braze-shared-lib.js";
import V from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as W } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageButtonClick(t, o) {
  if (!e.rr()) return !1;
  if (!(t instanceof InAppMessageButton))
    return r.D.error("button must be an InAppMessageButton object"), !1;
  if (!(o instanceof InAppMessage)) return r.D.error(or), !1;
  const s = pe.m().fi(t, o);
  if (s.S)
    for (let r = 0; r < s.ve.length; r++)
      W.er().je(V.Ar, [o.triggerId, t.id], s.ve[r]);
  return s.S;
}
