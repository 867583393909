import { endOfMonth, format, startOfMonth } from "date-fns";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import React, { useState } from "react";

import Dropdown from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";
import Text from "src/components/elements/Text";

export default MiqYearMonthPicker;

export const months = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["Jun", "June"],
  ["Jul", "July"],
  ["Aug", "August"],
  ["Sep", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];

function MiqYearMonthPicker({
  label,
  selected,
  dateFormat,
  onSelect,
  onClear,
}) {
  const [open, setOpen] = useState(false);

  const handleClear = (e) => {
    e.stopPropagation();
    if (typeof onClear === "function") onClear(null);
  };

  const hasValue = selected !== null;
  const getLabel = () => {
    if (typeof dateFormat === "string") {
      return format(dateFormat);
    } else if (hasValue) {
      return months[getMonth(selected)][1];
    }

    return label;
  };
  return (
    <Dropdown
      icon="calendar"
      label={getLabel()}
      open={open}
      hasValue={hasValue}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      suffix={
        hasValue && !open ? (
          <IconButton name="close" onClick={handleClear} />
        ) : null
      }
    >
      <YearMonthCalendar value={selected} onSelect={onSelect} />
    </Dropdown>
  );
}

export const YearMonthCalendar = ({ value, onSelect }) => {
  const now = new Date();
  const currentMonth = getMonth(now);
  const currentYear = getYear(now);

  const compareAndGetYear = () => {
    const [startMonthDate, endMonthDate] = value;
    const hasValue = Array.isArray(value) && value.length === 2;
    if (hasValue) {
      if (getYear(startMonthDate) === getYear(endMonthDate)) {
        return getYear(startMonthDate);
      } else {
        return currentYear;
      }
    }

    return currentYear;
  };

  const [year, setYear] = useState(compareAndGetYear());
  const prevYear = () => {
    setYear((y) => y - 1);
  };

  const nextYear = () => {
    setYear((y) => (y < currentYear ? y + 1 : currentYear));
  };

  const handleSelect = (monthIdx) => {
    if (typeof onSelect === "function") {
      const newDate = new Date(year, monthIdx);
      onSelect([startOfMonth(newDate), endOfMonth(newDate)]);
    }
  };

  const [startMonthDate] = value;
  return (
    <div className="p-2 flex flex-col" data-testid="year-month-calendar">
      <div className="flex items-center justify-between mb-6">
        <IconButton
          testId="year-month-picker-previous-year-btn"
          onClick={prevYear}
          name="chevron-left-small"
        />
        <Text bold>{year}</Text>
        <IconButton
          testId="year-month-picker-next-year-btn"
          onClick={nextYear}
          name="chevron-right-small"
          className={
            year < currentYear
              ? "pointer-events-auto opacity-100"
              : "pointer-events-none opacity-30"
          }
        />
      </div>
      <div className="grid grid-cols-3 grid-rows-4 gap-2">
        {months.map((m, i) => (
          <button
            data-testid="month-button"
            key={m}
            className={`m-0 p-2 h-40-35 rounded-14-12 ${
              getMonth(startMonthDate) === i && year === compareAndGetYear()
                ? "bg-black text-white"
                : "bg-beige"
            } disabled:bg-transparent disabled:border disabled:border-border-1 disabled:text-black/30`}
            disabled={year == currentYear ? currentMonth < i : false}
            onClick={() => handleSelect(i)}
          >
            <Text semibold>{m[0]}</Text>
          </button>
        ))}
      </div>
    </div>
  );
};
