import format from "date-fns/format";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import TeamContext from "src/components/context/TeamContext";
import { useTeamReports } from "src/components/context/TeamReportsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import QuickTips from "src/components/blocks/QuickTips";

import {
  reportDownloadSources,
  reportEmailSources,
  reportOpenSources,
  trackDriverReportOpened,
} from "src/services/tracking";
import { formatCurrency } from "src/services/utils";

export default ReportSidebar;

function ReportSidebar() {
  const history = useHistory();
  const { team } = useContext(TeamContext);
  const { userData } = useContext(UserDataContext);
  const {
    selectedReports,
    toggleReports,
    getSelectedReports,
    getPastSelectedReports,
    generateReportAsCSV,
    generateReportAsPDF,
    generateBulkReportsAsCSV,
    generateBulkReportsAsPDF,
    generatingCSV,
    generatingPDF,
    openEmailReportModal,
  } = useTeamReports();

  // This is a hack to maintain the selected reports view during close sidebar animation
  const reports =
    selectedReports.size > 0 ? getSelectedReports() : getPastSelectedReports();
  const report = reports[0];
  const isSingleReport = reports.length === 1;

  if (!team?.id) return null;

  const handleEmailReportClicked = () => {
    openEmailReportModal(reportEmailSources.REPORTS_SIDEBAR);
  };

  const handleViewDriveDetailsClicked = () => {
    trackDriverReportOpened({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subscriptionId: userData.subscriptionType,
      source: reportOpenSources.SIDEBAR_DETAILS,
    });

    history.push(`/teams/reports/${report.id}`, {
      fromReportList: true,
    });
  };

  const handleGeneratePDF = () => {
    if (generatingPDF) return;

    if (isSingleReport) {
      return generateReportAsPDF(
        report.id,
        reportDownloadSources.REPORTS_SIDEBAR
      );
    }

    return generateBulkReportsAsPDF(
      reports.map((report) => report.id),
      reportDownloadSources.REPORTS_SIDEBAR
    );
  };

  const handleGenerateCSV = () => {
    if (generatingCSV) return;

    if (isSingleReport) {
      return generateReportAsCSV(
        report.id,
        reportDownloadSources.REPORTS_SIDEBAR
      );
    }

    return generateBulkReportsAsCSV(
      reports.map((report) => report.id),
      reportDownloadSources.REPORTS_SIDEBAR
    );
  };

  const totalValue = reports.reduce(
    (acc, _report) => acc + parseFloat(_report.totalValue),
    0
  );
  const totalDistance = reports.reduce(
    (acc, _report) => acc + _report.totalDistance,
    0
  );

  const submittedAt = format(
    report?.lastSubmittedAt || new Date(),
    "MMMM, yyyy"
  );
  const distance = `${totalDistance?.toFixed?.(1) || 0} ${
    report?.distanceUnits || "mi"
  }`.trim();
  const drives = report?.totalDrives;
  const manualDrives = report?.totalManualDrives;
  const value = formatCurrency({
    value: totalValue,
    currency: report?.currencyUnits,
  });
  const drivesRange = report?.formattedDrivesRange;

  return (
    <>
      <QuickTips />
      <div
        className={`right-panel  ${
          selectedReports.size > 0 ? "open" : "closed"
        }`}
      >
        <Button
          icon="close"
          className="btn-close"
          onClick={() => toggleReports(reports)}
        />
        <h4>{isSingleReport ? submittedAt : "Multiple Reports"}</h4>
        <div className="mt-3">
          <Text>
            {isSingleReport
              ? "Download report:"
              : `Download ${reports.length} reports:`}
          </Text>
          <div className="flex items-center gap-2 mt-5">
            <Button
              className={`${isSingleReport ? "w-2/5" : "w-1/2"}`}
              icon="download"
              onClick={handleGeneratePDF}
              loading={generatingPDF}
            >
              PDF
            </Button>
            <Button
              className={`${isSingleReport ? "w-2/5" : "w-1/2"}`}
              icon="download"
              onClick={handleGenerateCSV}
              loading={generatingCSV}
            >
              CSV
            </Button>
            {isSingleReport && (
              <Button
                icon="mail"
                onClick={handleEmailReportClicked}
                tooltip={{
                  text: "Email report",
                  alignX: "right",
                  offset: { x: 8, y: 45 },
                }}
              />
            )}
          </div>
        </div>
        {isSingleReport && (
          <div className="mt-5 px-5 py-4 rounded bg-white border border-border-1 shadow-sm">
            <div className="flex gap-4">
              <Icon name="car" />
              <div className="flex flex-col">
                <Text bold>
                  {drives} {drives > 1 ? "Drives" : "Drive"}
                </Text>
                <Text>
                  {manualDrives ? `${manualDrives} manually added` : "-"}
                </Text>
              </div>
            </div>
            <div
              onClick={handleViewDriveDetailsClicked}
              className="flex items-center justify-between px-5 py-2 mt-4 rounded cursor-pointer bg-blue-mediumLight hover:bg-[#C1D8FA] transition-all"
            >
              <div className="flex gap-2">
                <Icon name="eye-fixed" color="black" />
                <Text bold>View drive details</Text>
              </div>
              <Icon name="arrow-right" />
            </div>
          </div>
        )}
        <div className="mt-3 p-5 flex flex-col gap-7 rounded bg-white border border-border-1 shadow-sm">
          <div className="flex items-center">
            <Icon name="money-bill" className="mr-3" />
            <Text bold>Total value</Text>
            <Text bold color="green" className="ml-auto">
              {value ? value : "-"}
            </Text>
          </div>
          <div className="flex items-center">
            <Icon name="calendar" className="mr-3" />
            <Text bold>Drive range</Text>
            <Text className="ml-auto">
              {isSingleReport ? drivesRange : "Multiple"}
            </Text>
          </div>
          <div className="flex items-center">
            <Icon name="arrows-h" className="mr-3" />
            <Text bold>Distance</Text>
            <Text className="ml-auto">{distance || "-"}</Text>
          </div>
        </div>
      </div>
    </>
  );
}
