import {
  endOfDay,
  endOfMonth,
  format,
  isSameYear,
  startOfDay,
  startOfMonth,
} from "date-fns";
import startOfYear from "date-fns/startOfYear";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Calendar from "src/components/elements/Calendar";
import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Text from "src/components/elements/Text";

import { YearMonthPicker } from "src/components/blocks/reports/teams/FilterMonths";

export default MiqDateRangePicker;

export const DATE_RANGES = {
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_3_MONTHS: "LAST_3_MONTHS",
  LAST_12_MONTHS: "LAST_12_MONTHS",
  YEAR_TO_DATE: "YEAR_TO_DATE",
  ALL_TIME: "ALL_TIME",
  CUSTOM: "CUSTOM",
  YEAR_MONTH: "YEAR_MONTH",
};

export const DateRangeData = {
  [DATE_RANGES.LAST_30_DAYS]: {
    label: "Last 30 days",
    getDescription: () => "Showing reports submitted in the last 30 days",
    getDates: () => {
      const end = new Date();
      const start = subDays(end, 30);
      return [start, end];
    },
  },
  [DATE_RANGES.LAST_3_MONTHS]: {
    label: "Last 3 months",
    getDescription: () => "Showing reports submitted in the last 3 months",
    getDates: () => {
      const end = new Date();
      const start = subMonths(end, 3);
      return [start, end];
    },
  },
  [DATE_RANGES.LAST_12_MONTHS]: {
    label: "Last 12 months",
    getDescription: () => "Showing reports submitted in the last 12 months",
    getDates: () => {
      const end = new Date();
      const start = subMonths(end, 12);
      return [start, end];
    },
  },
  [DATE_RANGES.YEAR_TO_DATE]: {
    label: "Year to date",
    getDescription: () => "Showing reports submitted this year",
    getDates: () => {
      const end = new Date();
      const start = startOfYear(end);
      return [start, end];
    },
  },
  [DATE_RANGES.ALL_TIME]: {
    label: "All time",
    getDescription: () => "Showing all reports ever submitted",
    getDates: () => {
      const end = new Date();
      const start = new Date(2020, 0, 1);
      return [start, end];
    },
  },
  [DATE_RANGES.CUSTOM]: {
    label: "Custom",
    getDescription: (range) => {
      return "Showing reports submitted from START_DATE to END_DATE"
        .replace("START_DATE", range[0])
        .replace("END_DATE", range[1]);
    },
  },
  [DATE_RANGES.YEAR_MONTH]: {
    label: "Choose month",
    getDescription: () => "",
  },
};

const SELECTION_MODES = {
  NONE: "NONE",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
};

function MiqDateRangePicker({
  selectedRangeDates,
  selectedRangeType,
  excludeRangeTypes,
  tooltip,
  onSelect,
  onClear,
  onOpen = () => {},
  onClose = () => {},
  triggerClassName,
  startAsPristine = true,
  label = "Submitted",
  showFullDateRange = false,
}) {
  const now = new Date();
  const [isPristine, setIsPristine] = useState(startAsPristine);
  const [isOpen, setIsOpen] = useState(false);
  const [currentRangeDates, setCurrentRangeDates] = useState([
    ...selectedRangeDates,
  ]);
  const [currentRangeType, setCurrentRangeType] = useState(selectedRangeType);

  // for custom range
  const [customStartDate, setCustomStartDate] = useState(currentRangeDates[0]);
  const [customEndDate, setCustomEndDate] = useState(currentRangeDates[1]);
  const [customSelectionMode, setCustomSelectionMode] = useState(
    SELECTION_MODES.START_DATE
  );
  const [customYearMonth, setCustomYearMonth] = useState(selectedRangeDates);

  useEffect(() => {
    setCurrentRangeDates([...selectedRangeDates]);
    setCustomStartDate(selectedRangeDates[0]);
    setCustomEndDate(selectedRangeDates[1]);
  }, [selectedRangeDates]);

  useEffect(() => {
    setCurrentRangeType(selectedRangeType);
  }, [selectedRangeType]);

  const openPicker = () => {
    const cancelOperation = onOpen?.();

    if (cancelOperation) return;

    setIsOpen(true);
    setCustomSelectionMode(SELECTION_MODES.START_DATE);
  };

  const closePicker = () => {
    // restore last wrap selection
    // in case user just closes the picker
    // (mostly for case with incomplete custom range selection)
    setCurrentRangeType(selectedRangeType);
    setCurrentRangeDates(selectedRangeDates);
    setCustomStartDate(selectedRangeDates[0]);
    setCustomEndDate(selectedRangeDates[1]);
    setCustomSelectionMode(SELECTION_MODES.NONE);
    setIsOpen(false);
    onClose?.();
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setIsPristine(true);
    if (typeof onClear === "function") onClear();
  };

  const submitSelection = (k, range) => {
    range[0] = startOfDay(range[0]);
    range[1] = endOfDay(range[1]);
    if (typeof onSelect === "function") onSelect(k, range);
  };

  const handleSelectFromRangeList = (k, range) => {
    setIsPristine(false);
    setCustomStartDate(null);
    setCustomEndDate(null);
    setCurrentRangeType(k);
    if (![DATE_RANGES.CUSTOM, DATE_RANGES.YEAR_MONTH].includes(k)) {
      setIsOpen(false);
      onClose?.();
      submitSelection(k, range);
      setCustomYearMonth([null, null]);
    }
  };

  const handleSelectFromCalendar = (date) => {
    switch (customSelectionMode) {
      case SELECTION_MODES.START_DATE:
        setCustomStartDate(date);
        setCustomSelectionMode(SELECTION_MODES.END_DATE);
        break;
      case SELECTION_MODES.END_DATE:
        setCustomEndDate(date);
        setIsOpen(false);
        onClose?.();
        setCustomSelectionMode(SELECTION_MODES.NONE);
        submitSelection(DATE_RANGES.CUSTOM, [customStartDate, date]);
        break;
      default:
        return;
    }
  };

  const handleSelectYearMonth = (month, year) => {
    const date = new Date(year, month, 10);
    const yearMonth = [startOfMonth(date), endOfMonth(date)];

    setIsOpen(false);
    onClose?.();
    setCustomYearMonth(yearMonth);
    submitSelection(DATE_RANGES.YEAR_MONTH, yearMonth);
  };

  const getYearMonthLabel = () => {
    const _format =
      now.getFullYear() === customYearMonth[0].getFullYear()
        ? "MMMM"
        : "MMMM, yyyy";
    return format(customYearMonth[0], _format);
  };

  const isCustomRange = currentRangeType === DATE_RANGES.CUSTOM;
  const hasValue = currentRangeType && !isPristine;
  const otherProps = {};
  if (isCustomRange) {
    otherProps.renderTrigger = () => (
      <CustomRangePickerLabel
        startDate={customStartDate}
        endDate={customEndDate}
        mode={customSelectionMode}
      />
    );
  } else {
    const dates = DateRangeData[selectedRangeType]?.getDates?.();
    let fullDateRangeStr = "";

    if (dates && dates.length > 1) {
      const [start, end] = dates;
      const formatStr = "MMM d, yyyy";
      fullDateRangeStr = `(${format(start, formatStr)} - ${format(
        end,
        formatStr
      )})`;
    }

    otherProps.renderTrigger = () => (
      <>
        <div className="flex items-center">
          <Icon name="calendar" color="black" className="prefix" />
          <div className="miq-dropdown-label flex gap-2">
            <p className="font-semibold">{label}</p>{" "}
            <p>
              {currentRangeType === DATE_RANGES.YEAR_MONTH && customYearMonth[0]
                ? getYearMonthLabel()
                : DateRangeData[selectedRangeType].label}
            </p>
            {showFullDateRange && fullDateRangeStr && <p>{fullDateRangeStr}</p>}
          </div>
        </div>
        <div className="suffix">
          {onClear && hasValue && !isOpen ? (
            <IconButton name="close" onClick={handleClear} />
          ) : (
            <Icon name="caret" color="black" />
          )}
        </div>
      </>
    );
  }

  return (
    <Dropdown
      className="dropdown-filter-date-range"
      triggerClassName={`border-2 border-transparent active:border-blue hover:border-blue/30 justify-between ${
        isCustomRange ? "p-0" : ""
      } ${triggerClassName || ""}`}
      openTriggerClassName="border-blue hover:border-blue active:border-blue"
      open={isOpen}
      onOpen={openPicker}
      onClose={closePicker}
      hasValue={hasValue}
      // minContentWidth={isCustomRange ? 580 : 240}
      tooltip={tooltip}
      {...otherProps}
    >
      <div className="flex">
        <ul className="m-0 p-0">
          {Object.keys(DATE_RANGES)
            .filter((k) => !excludeRangeTypes.includes(k))
            .map((k) => {
              const { label, getDates } = DateRangeData[k];
              // const isSelected = currentRangeType === k;
              const isCustom = [
                DATE_RANGES.CUSTOM,
                DATE_RANGES.YEAR_MONTH,
              ].includes(k);
              let range;
              let start = null;
              let end = null;
              if (!isCustom) {
                const dates = getDates();
                start = dates[0];
                end = dates[1];
                range = `${format(
                  start,
                  isSameYear(start, now) ? "MMM d" : "MMM d, yyyy"
                )} - ${format(
                  end,
                  isSameYear(end, now) ? "MMM d" : "MMM d, yyyy"
                )}`;
              }

              return (
                <li key={k} className="p-0">
                  <button
                    type="button"
                    className="w-full border-none outline-none px-2.5 py-2 rounded-[14px] hover:bg-beige active:bg-beige-medium"
                    onClick={() => handleSelectFromRangeList(k, [start, end])}
                  >
                    <span className="flex flex-col items-start whitespace-nowrap">
                      <Text>{label}</Text>
                      {!isCustom && <Text color="black/30">{range}</Text>}
                    </span>
                  </button>
                </li>
              );
            })}
        </ul>
        {currentRangeType === DATE_RANGES.CUSTOM && (
          <div className="ml-4">
            <Calendar
              onSelect={handleSelectFromCalendar}
              selected={
                customStartDate === null
                  ? selectedRangeDates[0]
                  : selectedRangeDates[1]
              }
            />
          </div>
        )}
        {currentRangeType === DATE_RANGES.YEAR_MONTH && (
          <div className="w-[285px]">
            <YearMonthPicker
              value={customYearMonth[0]}
              onSelect={handleSelectYearMonth}
            />
          </div>
        )}
      </div>
    </Dropdown>
  );
}

MiqDateRangePicker.propTypes = {
  selectedRangeType: PropTypes.string,
  selectedRangeDates: PropTypes.array,
  initialRangeLabel: PropTypes.string,
  onSelect: PropTypes.func,
  prepareRanges: PropTypes.func,
};

MiqDateRangePicker.defaultProps = {
  selectedRangeType: "",
  selectedRangeDates: [null, null],
  initialRangeLabel: "",
  onSelect: () => {},
  prepareRanges: (list) => list,
};

function CustomRangePickerLabel({ startDate, endDate, mode }) {
  const startDateLabel =
    startDate && mode !== SELECTION_MODES.START_DATE
      ? format(startDate, "MMM d, yyyy")
      : "Start Date";
  const endDateLabel =
    endDate && mode !== SELECTION_MODES.END_DATE
      ? format(endDate, "MMM d, yyyy")
      : "End Date";

  return (
    <div className="h-full flex items-center justify-between">
      <div
        className={`flex-shrink-0 h-full rounded px-4 flex items-center ${
          mode === SELECTION_MODES.START_DATE ? "bg-blue-mediumLight" : ""
        }`}
      >
        <Icon name="calendar" className="mr-2" color="black" />
        <Text bold>{startDateLabel}</Text>
      </div>
      <Icon name="arrow-right" color="black" className="mx-3" />
      <div
        className={`flex-shrink-0 h-full rounded px-4 flex items-center ${
          mode === SELECTION_MODES.END_DATE ? "bg-blue-mediumLight" : ""
        }`}
      >
        <Icon name="calendar" className="mr-2" color="black" />
        <Text bold>{endDateLabel}</Text>
      </div>
    </div>
  );
}
