import Base from "src/models/base";

export const USER_TYPES = {
  DRIVER: "DRIVER",
  ADMIN_DRIVER: "ADMIN_DRIVER",
  ADMIN: "ADMIN",
};

export const USER_TYPES_DATA = {
  [USER_TYPES.DRIVER]: {
    label: "Driver",
    icon: "car",
    className: "usertype-driver",
  },
  [USER_TYPES.ADMIN_DRIVER]: {
    label: "Admin & Driver",
    icon: "car-admin",
    className: "usertype-admindriver",
  },
  [USER_TYPES.ADMIN]: {
    label: "Admin (Free)",
    icon: "avatar-admin",
    className: "usertype-admin",
  },
};

export const DRIVERS_LIST_ACTIONS = {
  UPGRADE_TO_ADMIN_AND_DRIVER: "UPGRADE_TO_ADMIN_AND_DRIVER",
  DOWNGRADE_TO_ADMIN: "DOWNGRADE_TO_ADMIN",
  DOWNGRADE_TO_DRIVER: "DOWNGRADE_TO_DRIVER",
  RESEND_INTIVE: "RESEND_INVITE",
  REMOVE_FROM_TEAM: "REMOVE_FROM_TEAM",
  UPGRADE_TO_PRO: "UPGRADE_TO_PRO",
};

export default class User extends Base {
  id = null;
  email = null;
  isAdmin = null;
  isDriver = null;
  role = null;
  firstName = null;
  lastName = null;
  enabled = null;
  isPremium = null;
  isVerified = null;
  distinctId = null;
  createdAt = null;
  meta = {}; // for any additional info

  constructor(data) {
    super("user_model");
    if (data) {
      this.id = data.id || data.parse_id || data.user_parse_id || null;
      this.email = data.email?.toLowerCase() || null;
      this.isAdmin = !!data.is_admin;
      this.isDriver = !!data.is_driver;
      this.role = data.role || null;
      this.firstName = data.first_name || null;
      this.lastName = data.last_name || null;
      this.isEnabled = data.enabled || null;
      this.isPremium = data.is_premium || null;
      this.isVerified = data.is_verified || null;
      this.distinctId = data.distinct_id || null;
      this.createdAt = data.created_at || null;
    }
    this.meta = {};
  }

  clone() {
    const cloned = new User();
    cloned.id = this.id;
    cloned.email = this.email;
    cloned.isAdmin = this.isAdmin;
    cloned.isDriver = this.isDriver;
    cloned.role = this.role;
    cloned.firstName = this.firstName;
    cloned.isEnabled = this.isEnabled;
    cloned.isPremium = this.isPremium;
    cloned.isVerified = this.isVerified;
    cloned.distinctId = this.distinctId;
    cloned.createdAt = this.createdAt;
    return cloned;
  }
}
