import React, { useRef } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";
import useElement from "src/lib/layers/useElement";

import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Pivoted from "src/components/elements/Pivoted";
import Text from "src/components/elements/Text";

export default ActionBtn;

export const ACTIONS = {
  REMOVE_CONTACT: "REMOVE_CONTACT",
};

const ACTIONS_MENU_ID = "ACTIONS_MENU_CONTACT";

function ActionBtn({ loading, onAction }) {
  const trigger = useRef();

  const menuEl = useElement(ACTIONS_MENU_ID, {
    props: {
      onClose: () => menuEl.deactivate(),
      onAction: (action) => {
        menuEl.deactivate();
        onAction(action);
      },
    },
  });

  const handleMenuClick = (e) => {
    e.stopPropagation();
    menuEl.activate({
      props: {
        trigger: trigger.current,
      },
    });
  };

  if (loading)
    return (
      <span className="w-[40px]">
        <Icon name="spinner" className="opacity-30" />
      </span>
    );

  return (
    <div ref={trigger}>
      <IconButton
        onClick={handleMenuClick}
        name="ellipsis-vert"
        className="scale-75 hover:bg-beige-medium/50 rounded-10 p-2"
      />
    </div>
  );
}

registerElement(ACTIONS_MENU_ID, ActionsMenu);
function ActionsMenu({ trigger, onClose, onAction }) {
  let itemClass =
    "cursor-pointer m-0 px-2 py-2 rounded-10 hover:bg-beige active:bg-beige-medium";
  return (
    <Pivoted
      pivot={trigger}
      onClickOutside={onClose}
      style={{ zIndex: 30000 }}
      bottomRight
    >
      <ul className="min-w-[200px] bg-white rounded shadow-[0px_10px_30px_rgba(0,_0,_0,_0.15)] border border-border-1 p-2">
        <li className={itemClass}>
          <button
            className="flex gap-3 items-center"
            onClick={() => onAction(ACTIONS.REMOVE_CONTACT)}
          >
            <Icon name="trash" />
            <Text semibold>Remove this contact</Text>
          </button>
        </li>
      </ul>
    </Pivoted>
  );
}
