import React from "react";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { TeamsSignupSource } from "src/services/tracking";
import { prepareTeamsOnboardingUrl } from "src/services/utils";

import Img from "public/assets/img/miq-for-teams-city.png";
import Img2x from "public/assets/img/miq-for-teams-city@2x.png";

export function CreateTeamPage({ onClick }) {
  const features = [
    {
      Icon: () => <Icon name="car-side" color="green" className="mr-2 w-6" />,
      text: "Add/remove drivers",
    },
    {
      Icon: () => (
        <Icon name="billing-receipt" color="green" className="mr-2 w-6" />
      ),
      text: "Centralized billing",
    },
    {
      Icon: () => <Icon name="file-text" color="green" className="mr-2 w-6" />,
      text: "Detailed reports",
    },
    {
      Icon: () => (
        <Icon name="taller-people" color="green" className="mr-2 w-6" />
      ),
      text: "Multiple admins",
    },
    {
      Icon: () => (
        <Icon name="circle-money" color="green" className="mr-2 w-6" />
      ),
      text: "Custom rates",
    },
    {
      Icon: () => (
        <Icon name="speech-balloon" color="green" className="mr-2 w-6" />
      ),
      text: "Premium support",
    },
    {
      Icon: () => (
        <Icon name="name-location-pin" color="green" className="mr-2 w-6" />
      ),
      text: "Teams location",
    },
  ];

  function handleCreateTeam() {
    if (typeof onClick === "function") {
      return onClick();
    }

    const url = prepareTeamsOnboardingUrl(
      TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
      TEAM_SUBSCRIPTION_TYPES.MONTH,
      TeamsSignupSource.TEAMS_PAGE
    );

    window.location.href = url;
  }

  return (
    <>
      <div className="w-full h-full flex items-center justify-between">
        <div className="flex-shrink-0 max-w-[370px] my-0 mr-[20px] ml-[80px] laptop:ml-[60px] min1920:ml-[200px] min2560:ml-[260px]">
          <span className="text-blue text-15">Introducing</span>
          <h3 className="mt-[15px]">MileIQ for Teams</h3>
          <Text paragraph className="mt-[15px]">
            Create a Team account to license the MileIQ app to each of your
            drivers. See their drive reports all in one dashboard and seamlessly
            manage your team's mileage tracking.
          </Text>
          <ul className="mt-[20px]">
            {features.map((feature) => (
              <li
                key={feature.text}
                className="flex items-center mb-[7px] laptop:mb-[9px]"
              >
                <feature.Icon />
                <Text>{feature.text}</Text>
              </li>
            ))}
          </ul>
          <Button lg className="mt-[20px] w-[160px]" onClick={handleCreateTeam}>
            Create a team
          </Button>
        </div>
        <div className="overflow-hidden rounded-r flex-shrink-0 h-[60vh] laptop:h-[50vh]">
          <img src={Img} srcSet={`${Img2x} 2x`} className="h-full" />
        </div>
      </div>
    </>
  );
}
