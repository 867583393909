import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

import Base from "src/models/base";

import { formatCurrency } from "src/services/utils";

export default class Report extends Base {
  id = null;
  userId = null;
  lastSubmittedAt = null;
  totalDistance = null;
  totalDrives = null;
  totalManualDrives = null;
  totalValue = null;
  firstDriveDate = null;
  lastDriveDate = null;
  currencyUnits = null;
  distanceUnits = null;
  month = null;
  year = null;
  csvLink = null;
  pdfLink = null;
  userFirstName = null;
  userLastName = null;

  constructor(data) {
    super("report_model");
    if (data) {
      this.id = data.id || null;
      this.userId = data.user_parse_id || null;
      this.userEmail = data.user_email || null;
      this.lastSubmittedAt = data.last_submitted_at
        ? new Date(data.last_submitted_at)
        : null;
      this.totalDistance = data.total_distance;
      this.totalDrives = data.total_drives;
      this.totalManualDrives = data.total_manual_drives;
      this.totalValue = data.total_value;
      this.firstDriveDate = data.first_drive_datetime
        ? new Date(data.first_drive_datetime)
        : null;
      this.lastDriveDate = data.last_drive_datetime
        ? new Date(data.last_drive_datetime)
        : null;
      this.currencyUnits = data.currency_units || null;
      this.distanceUnits = data.distance_units || null;
      this.csvLink = data.csv_link;
      this.pdfLink = data.pdf_link;
      this.userFirstName = data.user_first_name || null;
      this.userLastName = data.user_last_name || null;

      const m = parseInt(data.month);
      if (isNaN(m)) {
        this.month = null;
      } else {
        this.month = m - 1; // api stores month in range 1-12, in js we count 0-11
      }

      this.year = parseInt(data.year);
      if (isNaN(this.year)) this.year = null;
    }
  }

  get formattedDistance() {
    return `${this.totalDistance?.toFixed?.(1) || 0} ${
      this.distanceUnits || "mi"
    }`.trim();
  }

  get formattedValue() {
    return formatCurrency({
      value: this.totalValue,
      currency: this.currencyUnits,
    });
  }

  get formattedDrivesRange() {
    if (!this.firstDriveDate || !this.lastDriveDate) return "";

    const fd = this.firstDriveDate;
    const ld = this.lastDriveDate;
    const fdy = getYear(fd);
    const ldy = getYear(ld);
    if (fdy !== ldy) {
      const fmt = "MMM d, yyyy";
      return `${format(fd, fmt)} - ${format(ld, fmt)}`;
    }

    const fdm = getMonth(fd);
    const ldm = getMonth(ld);
    if (fdm !== ldm) {
      return `${format(fd, "MMM d")} - ${format(ld, "MMM d, yyyy")}`;
    }

    return `${format(fd, "MMMM d")} - ${format(ld, "d")}`;
  }

  get formattedDrivesMonth() {
    let str = "";
    if (this.month !== null && this.year !== null) {
      const now = new Date();
      let d = new Date(this.year, this.month, 2);
      if (this.year !== getYear(now)) {
        str = format(d, "MMM, yyyy");
      } else {
        str = format(d, "MMMM");
      }
    }
    return str;
  }
}
