import React from "react";

import Text from "./Text";

const Radio = ({
  label,
  name,
  value,
  inputClassName,
  labelClassname,
  ...rest
}) => {
  return (
    <div className="flex gap-2">
      <input
        id={value}
        name={name}
        className={`${inputClassName || ""}`}
        type="radio"
        {...rest}
      />
      <label htmlFor={value}>
        <Text className={`${labelClassname || ""}`}>{label}</Text>
      </label>
    </div>
  );
};

export default Radio;
