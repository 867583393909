import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  fromPromise,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { offsetLimitPagination } from "@apollo/client/utilities";
import React from "react";

import { tryRefreshToken } from "src/services/auth";
import { report } from "src/services/error-reporting";
import { getAccessToken } from "src/services/storage";

export default ApolloContextProvider;

const PUBLIC_ROUTES = new Set([
  "/login",
  "/signup",
  "/qr",
  "/forgot-password",
  "/forgot-password/reset-password",
  "/forgot-password/reset-password-with-token",
  "/logout",
  "/partner",
  "/partner/",
  "/login/resetPasswordWithToken",
  "/login/resetPassword",
]);

const PUBLIC_ROUTE_PREFIXES = ["/public/unsubscribe"];

export const isPublicRoute = (route = window.location.pathname) =>
  PUBLIC_ROUTES.has(route) ||
  PUBLIC_ROUTE_PREFIXES.some((prefix) => route.startsWith(prefix));

let isRefreshingToken = false;
let pendingRequests = [];

const setIsRefreshing = (value) => {
  isRefreshingToken = value;
};

const addPendingRequest = (pendingRequest) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  pendingRequests = [];
};

const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) => {
  if (isPublicRoute()) return { headers };

  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const resetToken = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    if (networkError) {
      report(networkError);
    }
    if (graphQLErrors?.length > 0) {
      graphQLErrors.forEach(report);
    }
    if (
      (networkError && networkError.statusCode === 401) ||
      graphQLErrors?.find((err) => err.extensions.code === "UNAUTHENTICATED")
    ) {
      if (isPublicRoute()) {
        return;
      }

      if (isRefreshingToken) {
        // Add this operation to the list of pending operations to retry after the token refresh completes
        return fromPromise(
          new Promise((resolve) => {
            addPendingRequest(() => resolve());
          })
        ).flatMap(() => {
          return forward(operation);
        });
      } else {
        setIsRefreshing(true);

        return fromPromise(
          tryRefreshToken().catch(() => {
            pendingRequests = [];
          })
        ).flatMap(() => {
          resolvePendingRequests();
          setIsRefreshing(false);

          return forward(operation);
        });
      }
    }
  }
);

export const apolloClient = new ApolloClient({
  link: from([resetToken, authLink, httpLink]),
  connectToDevTools: process.env.NODE_ENV !== "production",
  defaultOptions: {
    query: { errorPolicy: "all" },
    watchQuery: { errorPolicy: "all" },
    mutate: { errorPolicy: "all" },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          drives: offsetLimitPagination(),
        },
      },
      Location: {
        keyFields: ["name", "displayName", "latitude", "longitude"],
      },
      DrivePurpose: {
        keyFields: ["id", "category"],
      },
    },
  }),
});

function ApolloContextProvider({ children }) {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
