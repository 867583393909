import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  TeamsSignupSource,
  trackTeamsReverseTrialConfirmationClicked,
  trackTeamsReverseTrialTryForFreeClicked,
  trackTeamsSignupStarted,
} from "src/services/tracking";

import { useReverseTrial } from "./hooks";
import { ReverseTrialAvailableScreen } from "./screens/ReverseTrialAvailable";
import { ReverseTrialEndedScreen } from "./screens/ReverseTrialEnded";
import { ReverseTrialHowItWorksScreen } from "./screens/ReverseTrialHowItWorks";

const REVERSE_TRIAL_DAYS = 30;

export function ReverseTrialPage() {
  const { hasTrialEnded } = useReverseTrial();
  const { push } = useHistory();
  function handleCreateTeam() {
    window.location.href = `/teams/onboarding?from=reverse-trial`;
  }

  if (hasTrialEnded()) {
    return <ReverseTrialEndedScreen handleCreateTeam={handleCreateTeam} />;
  }

  return (
    <ReverseTrialAvailableScreen
      daysInTrial={REVERSE_TRIAL_DAYS}
      handleTryForFree={() => {
        trackTeamsReverseTrialTryForFreeClicked();
        trackTeamsSignupStarted({
          teamsSignupSource: TeamsSignupSource.TEAMS_PAGE,
        });
        push("/user/trial-teams/how-it-works");
      }}
      handleCreateTeam={handleCreateTeam}
    />
  );
}

export function ReverseTrialHowItWorksPage() {
  const { push, goBack } = useHistory();

  function handleStartTrial() {
    trackTeamsReverseTrialConfirmationClicked();
    push("/user/trial-teams/start");
  }

  return (
    <ReverseTrialHowItWorksScreen
      daysInTrial={REVERSE_TRIAL_DAYS}
      handleStartTrial={handleStartTrial}
      handleBack={goBack}
    />
  );
}
