import Base from "src/models/base";

export default class RatesCA extends Base {
  constructor(data) {
    super("rates_ca_model");
    /* Expected scheme
      {
          "year": 2013,
          "parse_id": "20L1oeKsbg",
          "display_name": "Canada (Standard)",
          "currency": "CAD",
          "units": "km",
          "name": "country_ca",
          "effective_date": null,
          "values": {
              "business": {
                  "default": 0.8690458,
                  "ge_3125": 0.7724851
              },
              "personal": {
                  "default": 0
              }
          }
      }
    */

    if (data) {
      this.data = data;
    } else {
      this.data = {
        values: {
          business: {
            default: 0,
            ge_3125: 0,
          },
          personal: {
            default: 0,
          },
        },
      };
    }
  }

  get id() {
    return this.__inst_id;
  }

  get parseId() {
    return this.data?.parse_id;
  }

  clone() {
    return new RatesCA(JSON.parse(JSON.stringify(this.data)));
  }

  get effectiveDateText() {
    return this.data?.effectiveDateText;
  }

  get effectiveDate() {
    const effectiveDate = this.data?.effective_date;
    if (!effectiveDate) {
      return null;
    }
    if (typeof effectiveDate === "string") {
      return new Date(effectiveDate.replace(/z/gi, ""));
    }
    return effectiveDate;
  }

  set effectiveDate(date) {
    this.data.effective_date = date;
  }

  get value() {
    return this.data?.values?.business?.default;
  }

  set value(v) {
    this.data.values.business.default = parseFloat(v);
  }

  get value2() {
    return this.data?.values?.business?.ge_3125;
  }
  set value2(v) {
    this.data.values.business.ge_3125 = parseFloat(v);
  }

  get status() {
    return this.data?.status;
  }

  set status(status) {
    this.data.status = status;
  }
}
