import { isArray as p, isEqual as ii } from "../util/code-utils.js";
import ei from "../models/push-token.js";
import r from "../../shared-lib/braze-shared-lib.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
import { WindowUtils as H } from "../util/window-utils.js";
import dt from "./utils/push-utils.js";
export default class aa {
  constructor(i, e, t, s, r, n, o, u, a, h) {
    (this.Sr = i),
      (this._r = e),
      (this.Ur = t),
      (this.Wr = s + "/safari/" + e),
      (this.Nr = r || "/service-worker.js"),
      (this.Ir = n),
      (this.gt = o),
      (this.Vr = u || !1),
      (this.Er = a || !1),
      (this.h = h),
      (this.Or = dt.Br()),
      (this.Mr = dt.Yr());
  }
  Gr() {
    return this.Er;
  }
  Hr(i, e, t, s, n) {
    i.unsubscribe()
      .then(i => {
        i
          ? this.Jr(e, t, s, n)
          : (r.D.error("Failed to unsubscribe device from push."),
            "function" == typeof n && n(!1));
      })
      .catch(i => {
        r.D.error("Push unsubscription error: " + i),
          "function" == typeof n && n(!1);
      });
  }
  Kr(i, e, t) {
    const s = (i => {
      if ("string" == typeof i) return i;
      if (0 !== i.endpoint.indexOf("https://android.googleapis.com/gcm/send"))
        return i.endpoint;
      let e = i.endpoint;
      return (
        i.Lr &&
          -1 === i.endpoint.indexOf(i.Lr) &&
          (e = i.endpoint + "/" + i.Lr),
        e
      );
    })(i);
    let r = null,
      n = null;
    if (null != i.getKey)
      try {
        (r = btoa(
          String.fromCharCode.apply(null, new Uint8Array(i.getKey("p256dh")))
        )),
          (n = btoa(
            String.fromCharCode.apply(null, new Uint8Array(i.getKey("auth")))
          ));
      } catch (i) {
        if ("invalid arguments" !== i.message) throw i;
      }
    const o = (i => {
      let e;
      return i.options &&
        (e = i.options.applicationServerKey) &&
        e.byteLength &&
        e.byteLength > 0
        ? btoa(String.fromCharCode.apply(null, new Uint8Array(e)))
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
        : null;
    })(i);
    this.Sr.Qr(s, t, r, n, o), s && "function" == typeof e && e(s, r, n);
  }
  Xr() {
    this.Sr.Zr(!0);
  }
  hn(i, e) {
    this.Sr.Zr(!1), r.D.info(i), "function" == typeof e && e(!1);
  }
  fn(i, e, t, s) {
    if ("default" === e.permission)
      try {
        window.safari.pushNotification.requestPermission(
          this.Wr,
          i,
          { api_key: this._r, device_id: this.Ur.te().id },
          e => {
            "granted" === e.permission &&
              this.Sr.setPushNotificationSubscriptionType(
                User.NotificationSubscriptionTypes.OPTED_IN
              ),
              this.fn(i, e, t, s);
          }
        );
      } catch (i) {
        this.hn("Could not request permission for push: " + i, s);
      }
    else
      "denied" === e.permission
        ? this.hn(
            "The user has blocked notifications from this site, or Safari push is not configured in the Braze dashboard.",
            s
          )
        : "granted" === e.permission &&
          (r.D.info("Device successfully subscribed to push."),
          this.Kr(e.deviceToken, t, new Date()));
  }
  dn(i, e, t) {
    const s = s => {
      switch (s) {
        case "granted":
          return void ("function" == typeof i && i());
        case "default":
          return void ("function" == typeof e && e());
        case "denied":
          return void ("function" == typeof t && t());
        default:
          r.D.error("Received unexpected permission result " + s);
      }
    };
    let n = !1;
    const o = window.Notification.requestPermission(i => {
      n && s(i);
    });
    o
      ? o.then(i => {
          s(i);
        })
      : (n = !0);
  }
  Jr(i, e, t, s) {
    const n = { userVisibleOnly: !0 };
    null != e && (n.applicationServerKey = e),
      i.pushManager
        .subscribe(n)
        .then(i => {
          r.D.info("Device successfully subscribed to push."),
            this.Kr(i, t, new Date());
        })
        .catch(i => {
          dt.isPushBlocked()
            ? (r.D.info("Permission for push notifications was denied."),
              "function" == typeof s && s(!1))
            : r.D.error("Push subscription failed: " + i);
        });
  }
  pn() {
    return this.Vr
      ? navigator.serviceWorker.getRegistration()
      : navigator.serviceWorker.register(this.Nr).then(() =>
          navigator.serviceWorker.ready.then(
            i => (
              i &&
                "function" == typeof i.update &&
                i.update().catch(i => {
                  r.D.info("ServiceWorker update failed: " + i);
                }),
              i
            )
          )
        );
  }
  bn(i) {
    this.Vr ||
      (i.unregister(), r.D.info("Service worker successfully unregistered."));
  }
  subscribe(i, e) {
    if (dt.isPushSupported()) {
      if (this.Or) {
        if (!this.Vr && null != window.location) {
          let i = this.Nr;
          -1 === i.indexOf(window.location.host) &&
            (i = window.location.host + i),
            -1 === i.indexOf(window.location.protocol) &&
              (i = window.location.protocol + "//" + i);
          const e = i.substr(0, i.lastIndexOf("/") + 1);
          if (0 !== H.gn().indexOf(e))
            return void r.D.error(
              "Cannot subscribe to push from a path higher than the service worker location (tried to subscribe from " +
                window.location.pathname +
                " but service worker is at " +
                i +
                ")"
            );
        }
        if (dt.isPushBlocked())
          return void this.hn(
            "Notifications from this site are blocked. This may be a temporary embargo or a permanent denial.",
            e
          );
        if (this.gt && !this.gt.wn() && 0 === this.gt.Zs())
          return (
            r.D.info(
              "Waiting for VAPID key from server config before subscribing to push."
            ),
            void this.gt.yn(() => {
              this.subscribe(i, e);
            })
          );
        const t = () => {
            r.D.info("Permission for push notifications was denied."),
              "function" == typeof e && e(!1);
          },
          s = () => {
            let i = "Permission for push notifications was ignored.";
            dt.isPushBlocked() &&
              (i +=
                " The browser has automatically blocked further permission requests for a period (probably 1 week)."),
              r.D.info(i),
              "function" == typeof e && e(!0);
          },
          n = dt.isPushPermissionGranted(),
          u = () => {
            n ||
              this.Sr.setPushNotificationSubscriptionType(
                User.NotificationSubscriptionTypes.OPTED_IN
              ),
              this.pn()
                .then(t => {
                  if (null == t)
                    return (
                      r.D.error(
                        "No service worker registration. Set the `manageServiceWorkerExternally` initialization option to false or ensure that your service worker is registered before calling registerPush."
                      ),
                      void ("function" == typeof e && e())
                    );
                  t.pushManager
                    .getSubscription()
                    .then(s => {
                      let n = null;
                      if (
                        (this.gt &&
                          null != this.gt.wn() &&
                          (n = r.kn.vn(this.gt.wn())),
                        s)
                      ) {
                        let u = null,
                          a = null;
                        const h = this.h.I(o.q.Pn);
                        if (h && !p(h)) {
                          let i;
                          try {
                            i = ei.Sn(h).Dn;
                          } catch (e) {
                            i = null;
                          }
                          null == i ||
                            isNaN(i.getTime()) ||
                            0 === i.getTime() ||
                            ((u = i),
                            (a = new Date(u)),
                            a.setMonth(u.getMonth() + 6));
                        }
                        null != n &&
                        s.options &&
                        s.options.applicationServerKey &&
                        s.options.applicationServerKey.byteLength &&
                        s.options.applicationServerKey.byteLength > 0 &&
                        !ii(n, new Uint8Array(s.options.applicationServerKey))
                          ? (s.options.applicationServerKey.byteLength > 12
                              ? r.D.info(
                                  "Device was already subscribed to push using a different VAPID provider, creating new subscription."
                                )
                              : r.D.info(
                                  "Attempting to upgrade a gcm_sender_id-based push registration to VAPID - depending on the browser this may or may not result in the same gcm_sender_id-based subscription."
                                ),
                            this.Hr(s, t, n, i, e))
                          : s.expirationTime &&
                            new Date(s.expirationTime) <= new Date().valueOf()
                          ? (r.D.info(
                              "Push subscription is expired, creating new subscription."
                            ),
                            this.Hr(s, t, n, i, e))
                          : h && p(h)
                          ? this.Hr(s, t, n, i, e)
                          : null == a
                          ? (r.D.info(
                              "No push subscription creation date found, creating new subscription."
                            ),
                            this.Hr(s, t, n, i, e))
                          : a <= new Date().valueOf()
                          ? (r.D.info(
                              "Push subscription older than 6 months, creating new subscription."
                            ),
                            this.Hr(s, t, n, i, e))
                          : (r.D.info(
                              "Device already subscribed to push, sending existing subscription to backend."
                            ),
                            this.Kr(s, i, u));
                      } else this.Jr(t, n, i, e);
                    })
                    .catch(i => {
                      r.D.error(
                        "Error checking current push subscriptions: " + i
                      );
                    });
                })
                .catch(i => {
                  r.D.error("ServiceWorker registration failed: " + i);
                });
          };
        this.dn(u, s, t);
      } else if (this.Mr) {
        if (null == this.Ir || "" === this.Ir)
          return void r.D.error(
            "You must supply the safariWebsitePushId initialization option in order to use registerPush on Safari"
          );
        const t = window.safari.pushNotification.permission(this.Ir);
        this.fn(this.Ir, t, i, e);
      }
    } else r.D.info(this._n);
  }
  unsubscribe(i, e) {
    dt.isPushSupported()
      ? this.Or
        ? navigator.serviceWorker.getRegistration().then(t => {
            t
              ? t.pushManager
                  .getSubscription()
                  .then(s => {
                    s &&
                      (this.Xr(),
                      s
                        .unsubscribe()
                        .then(s => {
                          s
                            ? (r.D.info(
                                "Device successfully unsubscribed from push."
                              ),
                              "function" == typeof i && i())
                            : (r.D.error(
                                "Failed to unsubscribe device from push."
                              ),
                              "function" == typeof e && e()),
                            this.bn(t);
                        })
                        .catch(i => {
                          r.D.error("Push unsubscription error: " + i),
                            "function" == typeof e && e();
                        }));
                  })
                  .catch(i => {
                    r.D.error("Error unsubscribing from push: " + i),
                      "function" == typeof e && e();
                  })
              : (r.D.info("Device already unsubscribed from push."),
                "function" == typeof i && i());
          })
        : this.Mr &&
          (this.Xr(),
          r.D.info("Device unsubscribed from push."),
          "function" == typeof i && i())
      : r.D.info(this._n);
  }
}
aa._n = "Push notifications are not supported in this browser.";
