const { gql } = require("@apollo/client");

export const ADD_NAMED_LOCATION = gql`
  mutation AddNamedLocation($namedLocationInput: NamedLocationInput) {
    addNamedLocation(namedLocationInput: $namedLocationInput) {
      id
      distance
      value
      parkingFees
      tollFees
      notes
      startLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      endLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
    }
  }
`;

export const RENAME_NAMED_LOCATION = gql`
  mutation RenameNamedLocation(
    $renameNamedLocationInput: RenameNamedLocationInput
  ) {
    renameNamedLocation(renameNamedLocationInput: $renameNamedLocationInput) {
      id
      distance
      value
      parkingFees
      tollFees
      notes
      startLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      endLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
    }
  }
`;

export const CREATE_NAMED_LOCATION_MUTATION = gql`
  mutation CreateNewNamedLocation(
    $createNewNamedLocationInput: CreateNewNamedLocationInput
  ) {
    createNewNamedLocation(
      createNewNamedLocationInput: $createNewNamedLocationInput
    ) {
      id
      distance
      value
      parkingFees
      tollFees
      notes
      startLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      endLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
    }
  }
`;

export const EDIT_DRIVES_MUTATION = gql`
  mutation EditDrives($editDrivesInput: [EditDrivesInput]) {
    editDrives(editDrivesInput: $editDrivesInput) {
      id
      purpose {
        id
        category
      }
      startLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
        fullAddress
      }
      endLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
        fullAddress
      }
      distance
      googleDistance
      value
      notes
      tollFees
      parkingFees
      routeShouldAutoClassify
      routeClassificationsToPrompt
      routeClassificationStreak
      routeUnclassifiedDriveCount
      routeId
      vehicleId
      map {
        startEndUrl
        startUrl
        endUrl
      }
    }
  }
`;

export const DELETE_DRIVES_MUTATION = gql`
  mutation DeleteDrives($deleteDrivesInput: [DeleteDrivesInput]) {
    deleteDrives(deleteDrivesInput: $deleteDrivesInput) {
      objectId
    }
  }
`;

export const ADD_DRIVE = gql`
  mutation AddDrive($data: AddDriveInput) {
    addDrive(data: $data) {
      id
      purpose {
        id
        category
      }
      distance
      googleDistance
      value
      parkingFees
      tollFees
      notes
      potentialBusinessValue {
        default
      }
      potentialPersonalValue {
        charity
        medical
        moving
      }
      startLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      startDate
      endLocation {
        latitude
        longitude
        name
        displayName
        isNamed
        namedLocationDetails {
          id
          isActive
        }
      }
      endDate
      isAutoClassified
      isReported
      vehicleId
      routeId
      routeShouldAutoClassify
      routeClassificationsToPrompt
      routeClassificationStreak
      routeUnclassifiedDriveCount
      map {
        startEndUrl
        startUrl
        endUrl
      }
    }
  }
`;

export const EDIT_SEND_NOTIFICATION_STATUS_MUTATION = gql`
  mutation EditSendNotificationStatus($data: EditSendNotificationStatusInput) {
    editSendNotificationStatus(data: $data) {
      code
      objectId
    }
  }
`;

export const UPDATE_COMMON_ROUTES_OPT_OUT_MUTATION = gql`
  mutation UpdateCommonRoutesOptOutStatus(
    $data: UpdateCommonRoutesOptOutStatusInput
  ) {
    updateCommonRoutesOptOutStatus(data: $data) {
      code
      objectId
    }
  }
`;

export const ADD_PURPOSE = gql`
  mutation AddPurpose($data: AddPurposeInput) {
    addPurpose(data: $data) {
      id
      label
      category
      isCustom
    }
  }
`;

export const EDIT_PURPOSE = gql`
  mutation EditPurpose($data: EditPurposeInput) {
    editPurpose(data: $data) {
      id
      label
      category
      isCustom
      isHidden
    }
  }
`;
export const REORDER_PURPOSES = gql`
  mutation ReorderPurposes($data: ReorderPurposesInput) {
    reorderPurposes(data: $data) {
      id
      category
    }
  }
`;

export const ADD_VEHICLE = gql`
  mutation AddVehicle($data: AddVehicleInput) {
    addVehicle(data: $data) {
      id
    }
  }
`;

export const EDIT_VEHICLE = gql`
  mutation EditVehicle($data: EditVehicleInput) {
    editVehicle(data: $data) {
      id
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation AddContact($data: AddContactInput) {
    addContact(data: $data) {
      id
    }
  }
`;

export const REMOVE_CONTACT = gql`
  mutation RemoveContact($data: RemoveContactInput) {
    removeContact(data: $data) {
      id
    }
  }
`;

export const JOIN_DRIVES = gql`
  mutation JoinDrives($driveIdsInput: DriveIdsInput) {
    joinDrives(driveIdsInput: $driveIdsInput) {
      id
    }
  }
`;

export const UNJOIN_DRIVE = gql`
  mutation UnjoinDrive($driveId: String!) {
    unjoinDrive(driveId: $driveId) {
      id
    }
  }
`;

export const SUBMIT_REPORT_TO_TEAM = gql`
  mutation SubmitReportToTeam($data: SubmitReportToTeamInput) {
    submitReportToTeam(data: $data) {
      id
      task_id
      status
    }
  }
`;

export const DELETE_REPORT_SUBMISSION = gql`
  mutation DeleteReportSubmission($data: SubmissionIdsInput) {
    deleteReportSubmission(data: $data) {
      code
      submissionId
    }
  }
`;

export const START_AUTOCLASSIFICATION = gql`
  mutation StartAutoClassification($data: StartAutoclassificationInput) {
    startAutoClassification(data: $data) {
      ok
    }
  }
`;

export const STOP_AUTOCLASSIFICATION = gql`
  mutation StopAutoClassification($data: StopAutoclassificationInput) {
    stopAutoClassification(data: $data) {
      ok
    }
  }
`;

export const DEFER_AUTOCLASSIFICATION = gql`
  mutation DeferAutoClassification($data: DeferAutoclassificationInput) {
    deferAutoClassification(data: $data) {
      ok
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($data: SetPasswordInput) {
    setPassword(data: $data) {
      code
    }
  }
`;

export const VALIDATE_EMAIL = gql`
  mutation ValidateEmail($email: String!) {
    validateEmail(email: $email) {
      code
      error
      suggestion
      validated
      realm
      userExists
    }
  }
`;

export const RESET_PASSWORD_WITH_TOKEN = gql`
  mutation ResetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      code
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $username: String!
    $password: String!
    $country: String
    $signUpOrigin: String
    $mixpanelDistinctId: String
    $promoOptIn: Boolean
    $firstName: String
    $lastName: String
  ) {
    createAccount(
      username: $username
      password: $password
      country: $country
      signUpOrigin: $signUpOrigin
      mixpanelDistinctID: $mixpanelDistinctId
      promoOptIn: $promoOptIn
      firstName: $firstName
      lastName: $lastName
    ) {
      code
      user {
        objectId
        email
        username
        password
        country
        signUpOrigin
        mixpanelDistinctID
        isPromoOptin
        sendMonthly
        sendWeekly
        sendDaily
        sendOther
        isPremium
        subscriptionType
        MIQAPIShouldUse
        MIQAPIUsing
      }
      token
      refreshToken
      errorMessage
    }
  }
`;

export const LOGIN_WITH_CREDENTIALS = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      code
      token
      type
      refreshToken
      errorMessage
    }
  }
`;

export const LOGIN_WITH_FIREBASE = gql`
  mutation LoginWithFirebase($token: String!) {
    loginWithFirebase(token: $token) {
      type
      token
      refresh_token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      code
      token
      type
      refreshToken
      errorMessage
    }
  }
`;

export const ADD_INTEGRATION = gql`
  mutation AddIntegration($integration: AddIntegrationInput!) {
    addIntegration(integration: $integration) {
      code
      error
      integration {
        id
        type
        status
        teamId
        userParserId
      }
    }
  }
`;

export const UPDATE_RATE = gql`
  mutation UpdateRate(
    $values: RateValuesInput!
    $units: String!
    $objectId: String!
    $effective_date: String
  ) {
    updateRate(
      values: $values
      units: $units
      objectId: $objectId
      effective_date: $effective_date
    ) {
      objectId
    }
  }
`;

export const RESET_RATES = gql`
  mutation ResetRates {
    resetRates {
      ok
    }
  }
`;

export const EDIT_INTEGRATION = gql`
  mutation EditIntegration(
    $integrationId: String!
    $integration: EditIntegrationInput!
  ) {
    editIntegration(integrationId: $integrationId, integration: $integration) {
      code
      error
      integration {
        id
        type
        status
        teamId
        userParserId
      }
    }
  }
`;

export const CREATE_RATE = gql`
  mutation CreateRate(
    $values: RateValuesInput!
    $currency: String!
    $units: String!
    $year: String!
    $effective_date: String
  ) {
    createRate(
      values: $values
      currency: $currency
      units: $units
      year: $year
      effective_date: $effective_date
    ) {
      objectId
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation DeleteIntegration($integrationId: String!) {
    deleteIntegration(integrationId: $integrationId) {
      code
      error
      integrationId
    }
  }
`;

export const UPDATE_REGION = gql`
  mutation UpdateRegion($newRegion: String!) {
    updateRegion(newRegion: $newRegion) {
      ok
    }
  }
`;

export const DELETE_WORK_HOURS = gql`
  mutation DeleteWorkHours {
    deleteWorkHours {
      ok
    }
  }
`;

export const EDIT_WORK_HOURS = gql`
  mutation EditWorkHours($data: EditWorkHoursInput) {
    editWorkHours(data: $data) {
      processRule
      processRuleOutsideHours
      shifts
    }
  }
`;

export const UPDATE_EMAIL_PREFERENCES = gql`
  mutation UpdateEmailPrefs(
    $userId: String!
    $sendWeekly: Boolean
    $sendMonthly: Boolean
    $sendOther: Boolean
    $sendPromo: Boolean
    $sendWarnings: Boolean
  ) {
    updateEmailPreferences(
      userId: $userId
      sendWeekly: $sendWeekly
      sendMonthly: $sendMonthly
      sendOther: $sendOther
      sendPromo: $sendPromo
      sendWarnings: $sendWarnings
    ) {
      ok
    }
  }
`;

export const CHECK_INTEGRATION = gql`
  mutation CheckIntegration($integrationId: String!) {
    checkIntegration(integrationId: $integrationId) {
      code
      error
      integrationId
      status
      reason
    }
  }
`;

export const UPDATE_DISTANCE_UNIT = gql`
  mutation UpdateDistanceUnit($distanceUnit: String) {
    updateDistanceUnit(distanceUnit: $distanceUnit) {
      code
      objectId
    }
  }
`;

export const SEND_DOWNLOAD_LINK_SMS = gql`
  mutation SendDownloadLinkSMS($phoneNumber: String!) {
    sendDownloadLinkSMS(phoneNumber: $phoneNumber) {
      code
      result
      errorCode
      errorDetail
      errorMessage
      status
      dateSent
      uri
    }
  }
`;

export const SET_INTEGRATION_IDENTITY = gql`
  mutation SetIntegrationIdentity(
    $integrationType: String!
    $identity: String!
  ) {
    setIntegrationIdentity(
      integrationType: $integrationType
      identity: $identity
    ) {
      code
      error
      identity {
        integrationType
        integrationId
        identity
        isManuallySet
      }
    }
  }
`;

export const ADD_CUSTOM_REPORT = gql`
  mutation AddCustomReport($data: CustomReport) {
    addCustomReport(data: $data) {
      ok
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($token: String!) {
    acceptInvite(token: $token) {
      code
      detail
    }
  }
`;

export const AUTHENTICATE_MIQ_USER = gql`
  mutation AuthenticateMIQUser(
    $clientId: String!
    $username: String!
    $password: String!
    $redirectUrl: String!
  ) {
    authenticateMIQUser(
      clientId: $clientId
      username: $username
      password: $password
      redirectUrl: $redirectUrl
    ) {
      code
      error
      authCode
    }
  }
`;

export const AUTHENTICATE_MSA_USER = gql`
  mutation AuthenticateMSAUser(
    $clientId: String!
    $username: String!
    $userId: String
    $accessToken: String!
    $refreshToken: String!
    $redirectUrl: String!
  ) {
    authenticateMSAUser(
      clientId: $clientId
      username: $username
      userId: $userId
      accessToken: $accessToken
      refreshToken: $refreshToken
      redirectUrl: $redirectUrl
    ) {
      code
      error
      authCode
    }
  }
`;

export const AUTHENTICATE_SESSION_USER = gql`
  mutation AuthenticateSessionUser(
    $clientId: String!
    $sessionToken: String!
    $redirectUrl: String!
  ) {
    authenticateSessionUser(
      clientId: $clientId
      sessionToken: $sessionToken
      redirectUrl: $redirectUrl
    ) {
      code
      error
      authCode
    }
  }
`;

export const EXCHANGE_JWT_TO_SESSION_TOKEN = gql`
  mutation ExchangeJwtToSessionToken($token: String!) {
    exchangeJwtToSessionToken(token: $token) {
      code
      token
      errorMessage
    }
  }
`;

export const SEND_ADMIN_INVITE_TO_CREATE_TEAM = gql`
  mutation SendAdminInviteToCreateTeam(
    $adminEmail: String!
    $fromName: String!
  ) {
    sendAdminInviteToCreateTeam(adminEmail: $adminEmail, fromName: $fromName) {
      code
      result
    }
  }
`;

export const EDIT_USERNAME = gql`
  mutation EditUsername(
    $username: String!
    $newUsername: String!
    $password: String!
  ) {
    editUsername(
      username: $username
      newUsername: $newUsername
      password: $password
    ) {
      ... on EditUsernameTokenResponse {
        new_username
        access_token
        refresh_token
        type
      }
      ... on EditUsernameErrorResponse {
        detail
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($username: String!, $verificationSecret: String!) {
    verifyEmail(username: $username, verificationSecret: $verificationSecret) {
      detail
    }
  }
`;

export const POST_DRIVE_FEEDBACK = gql`
  mutation DriveFeedback(
    $feedbackType: FeedbackType
    $feedbackDetails: String
    $id: String
  ) {
    driveFeedback(
      feedbackType: $feedbackType
      feedbackDetails: $feedbackDetails
      id: $id
    ) {
      objectId
    }
  }
`;

export const UPDATE_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE = gql`
  mutation UpdateAutomaticReportSubmissionPreference($isEnabled: Boolean!) {
    updateAutomaticReportSubmissionPreference(isEnabled: $isEnabled) {
      isAutomaticReportSubmissionEnabled
      code
      error
    }
  }
`;

export const TOGGLE_AUTOMATIC_REPORT_SUBMISSION_PREFERENCE = gql`
  mutation ToggleAutomaticReportSubmissionPreference {
    toggleAutomaticReportSubmissionPreference {
      code
      error
      isAutomaticReportSubmissionEnabled
      automaticReportSubmissionRecurrence
    }
  }
`;

export const UPDATE_AUTOMATIC_REPORT_SUBMISSION_RECURRENCE = gql`
  mutation UpdateAutomaticReportSubmissionRecurrence($recurrence: String!) {
    updateAutomaticReportSubmissionRecurrence(recurrence: $recurrence) {
      code
      error
      isAutomaticReportSubmissionEnabled
      automaticReportSubmissionRecurrence
    }
  }
`;

export const CANCEL_INDIVIDUAL_SUBSCRIPTION = gql`
  mutation CancelIndividualSubscription {
    cancelIndividualSubscription {
      ok
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation UpdateName($firstName: String, $lastName: String) {
    updateName(firstName: $firstName, lastName: $lastName) {
      code
      objectId
    }
  }
`;
