import PropTypes from "prop-types";
import React, { useRef } from "react";

import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

export default MiqButton;

function MiqButton({
  children,
  className,
  iconClassName,
  primary,
  secondary,
  secondaryBordered,
  destructive,
  ghost,
  text,
  link,
  icon,
  iconAfter,
  disabled,
  nohover,
  color,
  tooltip,
  loading,
  lg,
  loadingClassName,
  responsiveLoader,
  ...rest
}) {
  const btnRef = useRef();

  const classes = ["miq-btn"];
  let iconColor = "white";

  if (color) classes.push("custom-color");

  // btn type
  switch (true) {
    case primary:
      classes.push("miq-btn-primary");
      iconColor = "white";
      break;
    case secondary:
      classes.push("miq-btn-secondary");
      iconColor = disabled ? "black/30" : "black";
      break;
    case secondaryBordered:
      classes.push("miq-btn-secondary-bordered");
      iconColor = color || "black";
      break;
    case destructive:
      classes.push("miq-btn-destructive");
      iconColor = "white";
      break;
    case ghost:
      classes.push("miq-btn-ghost");
      if (color) {
        classes.push(`text-${color}`);
        iconColor = color;
      } else {
        iconColor = "black";
      }
      break;
    case text:
      classes.push("miq-btn-text");
      if (color) {
        classes.push(`text-${color}`);
        iconColor = color;
      } else {
        iconColor = "black";
      }
      break;
    case link:
      classes.push("miq-btn-link");
      iconColor = "black";
      break;
    default:
      classes.push("miq-btn-primary");
      iconColor = "white";
      break;
  }

  if (lg) classes.push("miq-btn-large");

  if (nohover) classes.push("miq-btn-nohover");

  if (!children && icon) classes.push("only-icon");

  if (className) classes.push(className);

  const props = {
    ...rest,
    disabled,
    className: classes.join(" "),
  };

  let iconClasses = iconClassName || "";
  if (children) iconClasses += " mr-2";

  return (
    <>
      <button {...props} ref={btnRef}>
        {loading ? (
          <Icon
            name={responsiveLoader ? "spinner-responsive" : "spinner"}
            className={loadingClassName || "mr-2"}
          />
        ) : (
          !iconAfter &&
          icon && <Icon name={icon} color={iconColor} className={iconClasses} />
        )}
        {children}
        {!loading && iconAfter && icon && (
          <Icon
            name={icon}
            color={iconColor}
            className={`${children ? "ml-3" : ""}`}
          />
        )}
      </button>
      {tooltip && (
        <Tooltip
          triggerRef={btnRef}
          timeout={tooltip.timeout || 1000}
          className={tooltip.className}
          offset={tooltip.offset}
          alignX={tooltip.alignX}
        >
          <Text nowrap {...tooltip?.textProps}>
            {tooltip.text}
          </Text>
        </Tooltip>
      )}
    </>
  );
}

MiqButton.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  secondaryBordered: PropTypes.bool,
  destructive: PropTypes.bool,
  ghost: PropTypes.bool,
  text: PropTypes.bool,
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  lg: PropTypes.bool,
  icon: PropTypes.string,
  iconAfter: PropTypes.bool,
  nohover: PropTypes.bool,
  loading: PropTypes.bool,
};

MiqButton.defaultProps = {
  primary: false,
  secondary: false,
  secondaryBordered: false,
  destructive: false,
  ghost: false,
  text: false,
  link: false,
  disabled: false,
  lg: false,
  icon: null,
  iconAfter: false,
  nohover: false,
  loading: false,
};
