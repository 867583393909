import React, { memo, useState } from "react";

import Dropdown from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  customRatesSelectionTypes,
  customRatesUpdateTypes,
  trackTeamsCustomRatesUpdateStarted,
} from "src/services/tracking";
import { RATES_STATUS } from "src/services/utils";

export default memo(RatesActions);

function RatesActions({ rates, onToggle, onEdit, onRemove, onRevert }) {
  const { status } = rates;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (open) => {
    setIsOpen(open);
    onToggle(open);
  };

  const handleEditRates = () => {
    trackTeamsCustomRatesUpdateStarted({
      updateType: customRatesUpdateTypes.CHANGE,
      selection: customRatesSelectionTypes.UPCOMING,
    });

    handleToggle(false);
    onEdit(rates);
  };

  const handleRemoveRates = () => {
    trackTeamsCustomRatesUpdateStarted({
      updateType: customRatesUpdateTypes.REMOVE,
      selection: customRatesSelectionTypes.UPCOMING,
    });

    handleToggle(false);
    onRemove(rates);
  };

  const handleRevertToRates = () => {
    trackTeamsCustomRatesUpdateStarted({
      updateType: customRatesUpdateTypes.REVERT_TO_PREVIOUS,
      selection: customRatesSelectionTypes.PAST,
    });

    handleToggle(false);
    onRevert(rates);
  };

  const btnClasses =
    "w-full border-none outline-none hover:bg-beige active:bg-beige-medium flex items-center p-2.5 rounded";

  const renderUpcomingRatesOptions = () => (
    <>
      <li>
        <button className={btnClasses} onClick={handleEditRates}>
          <Icon name="edit" color="black" className="mr-3 w-6" />
          <Text>Edit this rate</Text>
        </button>
      </li>
      <li>
        <button className={btnClasses} onClick={handleRemoveRates}>
          <Icon name="close-in-circle" color="black" className="mr-3 w-6" />
          <Text>Remove this rate</Text>
        </button>
      </li>
    </>
  );

  const renderPastRatesOptions = () => (
    <li>
      <button className={btnClasses} onClick={handleRevertToRates}>
        <Icon name="restore" color="black" className="mr-3 w-6" />
        <Text>Revert to this rate</Text>
      </button>
    </li>
  );

  return (
    <Dropdown
      open={isOpen}
      onOpen={() => handleToggle(true)}
      onClose={() => handleToggle(false)}
      renderTrigger={() => <Icon name="ellipsis-vert" />}
      triggerClassName="shadow-none bg-transparent"
      contentClassName="right-0 top-10 p-2"
    >
      <ul className="p-0 m-0">
        {status === RATES_STATUS.UPCOMING && renderUpcomingRatesOptions()}
        {status === RATES_STATUS.PAST && renderPastRatesOptions()}
      </ul>
    </Dropdown>
  );
}
