import React from "react";

import Icon from "src/components/elements/Icon";
import QuickTipsLink from "src/components/elements/QuickTipsLink";
import Text from "src/components/elements/Text";

export default VehicleAndOdometerPageQuickTips;

function VehicleAndOdometerPageQuickTips() {
  return (
    <>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/205144255-How-to-Add-Vehicles-and-Odometer-Readings">
            How to: Add Vehicles and Odometer Readings in the app
          </QuickTipsLink>
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          To create a{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/203531259-How-to-Create-Reports">
            custom report
          </QuickTipsLink>{" "}
          of drives taken in one or more vehicles, go to the Reports View.
        </Text>
      </div>
      <div className="tip">
        <Icon name="book-open" color="black" />
        <Text md>
          In the MileIQ mobile app, use the Car icon in the{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/articles/115000157163-Anatomy-of-a-Drive-Card">
            Drive Card
          </QuickTipsLink>{" "}
          to assign a vehicle to each drive.
        </Text>
      </div>
      <div className="tip">
        <Icon name="light-bulb" className="self-start mt-[5px]" color="black" />
        <Text md>
          To contact support or for time-saving tips, tricks, and tools, visit
          our{" "}
          <QuickTipsLink url="https://support.mileiq.com/hc/en-us/">
            Help Center
          </QuickTipsLink>
          .
        </Text>
      </div>
    </>
  );
}
