import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import useElement from "src/lib/layers/useElement";

import { useTeamsCommuteSettings } from "src/components/context/TeamsCommuteSettingsContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import { FlashTypes } from "src/components/elements/Flash";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Text from "src/components/elements/Text";

import AddPurposeForm, {
  usePurposeInputValidation,
} from "src/components/blocks/drives/classification/AddPurposeForm";

import { ELEMENT_ID as UNSAVED_CHANGES_PROMPT } from "src/components/modals/UnsavedChangesPrompt";

import useFlash from "src/hooks/useFlash";

import { PURPOSE_CATEGORY } from "src/models/purpose";

import {
  filterOutCommutePurpose,
  getPurposesDataForCategory,
} from "src/services/purposes";
import {
  CustomPurposeSource,
  CustomPurposeUpdate,
  trackCustomPurposeAddCompleted,
  trackCustomPurposeAddStarted,
  trackCustomPurposeUpdated,
} from "src/services/tracking";
import { handleEnterKeyPress } from "src/services/utils";

import { EDIT_PURPOSE, REORDER_PURPOSES } from "src/graphql/mutations";

export default CustomPurposesPage;

const noop = () => {};
const EditContext = React.createContext({
  getPurposeInEdit: noop,
  setPurposeInEdit: noop,
  doneEdit: (purpose) => {}, // eslint-disable-line
  setUnsavedHandler: (obj = { onOk: noop, onCancel: noop }) => {}, // eslint-disable-line
  trySaveUnsaved: noop,
});

function CustomPurposesPage() {
  const { userData } = useContext(UserDataContext);
  const { shouldFilterOutCommutePurpose } = useTeamsCommuteSettings();
  const [flash, Flash] = useFlash();
  const history = useHistory();
  const unsavedPrompt = useElement(UNSAVED_CHANGES_PROMPT, {
    props: {
      title: "You have unsaved changes!",
    },
  });
  const purposeInEdit = useRef(null);
  const unsavedHandler = useRef(null);
  const getPurposeInEdit = () => purposeInEdit.current;
  const setPurposeInEdit = (purpose) => {
    purposeInEdit.current = purpose;
  };
  const setUnsavedHandler = (obj) => {
    unsavedHandler.current = obj;
  };

  const promptSaveUnsaved = () => {
    return new Promise((resolve) => {
      unsavedPrompt.activate({
        props: {
          onOk: async () => {
            unsavedPrompt.updateProps({ okLoading: true });
            await unsavedHandler.current?.onOk?.();
            resolve("ok");
            unsavedPrompt.deactivate();
          },
          onCancel: async () => {
            await unsavedHandler.current?.onCancel?.();
            resolve("cancel");
            unsavedPrompt.deactivate();
          },
        },
      });
    });
  };

  useEffect(() => {
    const unblock = history.block((transition) => {
      const retry = () => {
        unblock();
        if (transition?.pathname) history.push(transition.pathname);
      };
      if (!purposeInEdit?.current) {
        retry();
        return "true";
      } else {
        promptSaveUnsaved().then(retry);
        return false;
      }
    });
    return () => {
      unblock();
    };
  }, []);

  const userPurposes = shouldFilterOutCommutePurpose
    ? filterOutCommutePurpose(userData.purposes)
    : userData.purposes;

  const personalPurposes = getPurposesDataForCategory(
    userPurposes,
    PURPOSE_CATEGORY.PERSONAL
  ).slice(1); // skip first that is general Personal purpose

  const businessPurposes = getPurposesDataForCategory(
    userPurposes,
    PURPOSE_CATEGORY.BUSINESS
  ).slice(1); // skip first that is general Business purpose

  const handlePurposeAdded = () => {
    flash(<Text>Custom purpose added</Text>, {
      type: FlashTypes.SAVED,
    });
  };

  return (
    <EditContext.Provider
      value={{
        getPurposeInEdit,
        setPurposeInEdit,
        setUnsavedHandler,
        doneEdit: (purpose) => {
          if (purpose?.id == purposeInEdit.current?.id) {
            setPurposeInEdit(null);
            setUnsavedHandler(null);
          }
        },
        trySaveUnsaved: promptSaveUnsaved,
      }}
    >
      <div className="min-w-[610px] max-w-[700px]">
        <div className="p-[20px] laptop:p-[15px]">
          <h6 className="mb-[5px]">Custom purposes</h6>
          <Text paragraph lg>
            Add custom purposes to get specific when classifying a drive. The
            standard IRS drive purposes are here by default.
          </Text>
          <div className="mt-[15px] flex items-start gap-[10px]">
            <List
              category={PURPOSE_CATEGORY.PERSONAL}
              purposes={personalPurposes}
              onAdd={handlePurposeAdded}
              testId="settings-purposes-personal"
            />
            <List
              category={PURPOSE_CATEGORY.BUSINESS}
              purposes={businessPurposes}
              onAdd={handlePurposeAdded}
              testId="settings-purposes-business"
            />
          </div>
        </div>
        {Flash}
      </div>
    </EditContext.Provider>
  );
}

const DragContext = React.createContext({
  category: null,
  itemId: null,
  update: ({ itemId }) => {}, // eslint-disable-line
  reorder: ({ movedId, targetId, setAfter }) => {}, // eslint-disable-line
  enable: noop, // eslint-disable-line
  disable: noop, // eslint-disable-line
  isEnabled: true, // eslint-disable-line
});

const newPurp = { id: "new" };
function List({ category, purposes, onAdd, testId }) {
  const [showForm, setShowForm] = useState(false);
  const [dragItemId, setDragItemId] = useState(null);
  const [dragEnabledCounter, setDragEnabledCounter] = useState(1);
  const [_purposes, _setPurposes] = useState([...purposes]);
  const editCtx = useContext(EditContext);
  const addPurpFormRef = useRef();
  const [reorderPurpMutFn, reorderPurpMutState] = useMutation(
    REORDER_PURPOSES,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["getUserData"],
      onQueryUpdated: (q) => q.refetch(),
    }
  );

  useEffect(() => {
    // we update internal state immediately after drag-n-drop
    // so no need to update internal state, while saving new order
    // since it may cause flickering, showing old order and then new once save is complete
    if (reorderPurpMutState.loading) return;
    _setPurposes([...purposes]);
  }, [purposes]);

  const handleUpdateDragContext = ({ itemId }) => {
    setDragItemId(itemId);
  };

  // set `moveId` after or before `targetId`
  const handleReorder = ({ movedId, targetId, setAfter }) => {
    const newListIds = [];
    _purposes.forEach((p) => {
      if (p.id === movedId) return;
      if (p.id === targetId) {
        if (setAfter) {
          newListIds.push(p.id);
          newListIds.push(movedId);
        } else {
          newListIds.push(movedId);
          newListIds.push(p.id);
        }
        return;
      }
      newListIds.push(p.id);
    });

    _setPurposes(newListIds.map((id) => _purposes.find((p) => p.id === id)));

    trackCustomPurposeUpdated({
      category,
      update: CustomPurposeUpdate.ORDER,
    });

    reorderPurpMutFn({
      variables: {
        data: {
          ids: newListIds,
          category,
        },
      },
    });
  };

  const handleAddClicked = async () => {
    if (editCtx.getPurposeInEdit()) {
      await editCtx.trySaveUnsaved();
    }
    setShowForm(true);
    editCtx.setPurposeInEdit(newPurp);
    trackCustomPurposeAddStarted({
      category,
      src: CustomPurposeSource.SETTINGS,
    });
  };

  const handleAdded = () => {
    setShowForm(false);
    trackCustomPurposeAddCompleted({
      category,
      src: CustomPurposeSource.SETTINGS,
    });
    cancelAddPurpose();
    onAdd();
  };

  let title, titleIcon, btnTitle, bgCls;
  if (category === PURPOSE_CATEGORY.BUSINESS) {
    title = "Business";
    btnTitle = "Add business purpose";
    titleIcon = "suitcase";
    bgCls = "bg-[#c5e4fa]";
  } else {
    title = "Personal";
    btnTitle = "Add personal purpose";
    titleIcon = "home";
    bgCls = "bg-[#f5ebd5]";
  }

  const cancelAddPurpose = () => {
    setShowForm(false);
    editCtx.doneEdit(newPurp);
    editCtx.setUnsavedHandler(noop);
  };

  return (
    <div
      className={`${bgCls} rounded p-[20px] w-full flex flex-col gap-[20px]`}
      data-testid={testId}
    >
      <div className="flex gap-3 items-center">
        <Icon name={titleIcon} />
        <Text bold>{title}</Text>
      </div>
      <DragContext.Provider
        value={{
          category,
          update: handleUpdateDragContext,
          itemId: dragItemId,
          reorder: handleReorder,
          enable: () => setDragEnabledCounter((c) => c + 1),
          disable: () => setDragEnabledCounter((c) => c - 1),
          isEnabled: dragEnabledCounter > 0,
        }}
      >
        <ul className="relative m-0 p-0 max-h-[600px] overflow-auto flex flex-col pr-[2px]">
          {_purposes.map((p) => {
            return (
              <ListItem purpose={p} key={p.id} existingPurposes={_purposes} />
            );
          })}
        </ul>
      </DragContext.Provider>
      {showForm ? (
        <AddPurposeForm
          ref={addPurpFormRef}
          category={category}
          existingPurposes={_purposes}
          onAdd={handleAdded}
          onCancel={cancelAddPurpose}
          onBlur={(e) => {
            const val = e.target.value;
            if (val?.trim() !== "") {
              editCtx.setUnsavedHandler({
                onOk: () => {
                  return addPurpFormRef?.current?.submit();
                },
                onCancel: cancelAddPurpose,
              });
            } else {
              cancelAddPurpose();
            }
          }}
        />
      ) : (
        <Button onClick={handleAddClicked} className="w-[200px]">
          {btnTitle}
        </Button>
      )}
    </div>
  );
}

function ListItem({ purpose, existingPurposes }) {
  const [isEditing, setIsEditing] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, validate] = usePurposeInputValidation(existingPurposes);
  const [label, setLabel] = useState(purpose.label);
  const dragCtx = useContext(DragContext);
  const editCtx = useContext(EditContext);
  const [editPurpMutFn, editPurpMutState] = useMutation(EDIT_PURPOSE, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getUserData"],
    onQueryUpdated: (q) => q.refetch(),
  });

  const handleToggleVisibility = async () => {
    const setAsHidden = !purpose.isHidden;
    await editPurpMutFn({
      variables: {
        data: {
          id: purpose.id,
          category: purpose.category,
          label: purpose.label,
          isHidden: setAsHidden,
        },
      },
    });
    trackCustomPurposeUpdated({
      category: purpose.category,
      update: setAsHidden
        ? CustomPurposeUpdate.HIDE
        : CustomPurposeUpdate.UNHIDE,
    });
  };

  const toggleEdit = (isEdit) => {
    setSubmitted(false);

    if (isEdit) {
      setIsEditing(true);
      editCtx.setPurposeInEdit(purpose);
      dragCtx.disable();
    } else {
      setIsEditing(false);
      editCtx.doneEdit(purpose);
      dragCtx.enable();
    }
  };

  const discard = () => {
    setLabel(purpose.label);
  };

  const handleLabelClicked = async () => {
    if (editCtx.getPurposeInEdit()) {
      await editCtx.trySaveUnsaved();
    }
    if (!purpose.isCustom || editPurpMutState.loading) return;
    toggleEdit(true);
  };

  const handleUpdatePurpLabel = (e) => {
    const v = e.target.value;
    setLabel(v);
    if (submitted) validate(v);
  };

  const handleBlur = () => {
    if (purpose.label === label) {
      toggleEdit(false);
    } else {
      editCtx.setUnsavedHandler({
        onOk: handleSubmitNewLabel,
        onCancel: () => {
          discard();
          toggleEdit(false);
        },
      });
    }
  };

  const handleSubmitNewLabel = async () => {
    setSubmitted(true);
    if (editPurpMutState.loading) return;

    if (label.trim() === "") return;

    if (!validate(label)) return;

    await editPurpMutFn({
      variables: {
        data: {
          id: purpose.id,
          category: purpose.category,
          label: label,
          isHidden: purpose.isHidden,
        },
      },
    });
    toggleEdit(false);
  };

  useEffect(() => {
    if (!isEditing) setLabel(purpose.label);
  }, [purpose]);

  let itemCls =
    "h-[35px] p-[10px] bg-white flex items-center gap-3 w-full rounded shadow-sm border-2 border-transparent overflow-hidden";

  if (isEditing) {
    if (error) {
      itemCls += " border-red";
    } else {
      itemCls += " border-blue";
    }
  }

  if (editPurpMutState.loading || purpose.isHidden) {
    itemCls += " opacity-50";
  }

  const showSaveBtn = isEditing && purpose.label !== label;
  const isDraggable = dragCtx.isEnabled;
  const isEditable = purpose.isCustom;

  return (
    <li
      key={purpose.id}
      className={`purposes-list-item rounded  ${
        purpose.id === dragCtx.itemId ? "" : "my-[5px]"
      } `}
    >
      <Draggable purpose={purpose}>
        <div className={itemCls}>
          <Icon
            name="drag-vertical"
            className={isDraggable ? "cursor-move" : null}
          />
          {isEditing ? (
            <>
              <input
                autoFocus
                value={label}
                onChange={handleUpdatePurpLabel}
                onBlur={handleBlur}
                className="w-full h-[35px]  text-[13px] p-0 m-0 border-none focus:border-none focus:outline-none font-semibold"
                onKeyDown={handleEnterKeyPress(handleSubmitNewLabel)}
                disabled={editPurpMutState.loading}
              />
            </>
          ) : (
            <Text
              className={`purpose-edit-toggle w-full text-[13px] ${
                isEditable ? "cursor-text" : ""
              }`}
              tabIndex="0"
              bold={purpose.isCustom}
              onClick={handleLabelClicked}
            >
              {purpose.label}
            </Text>
          )}
          {editPurpMutState.loading ? (
            <Icon name="spinner" />
          ) : showSaveBtn ? (
            <div tabIndex="0" className="btn-save-purpose-wrap">
              <Button
                onClick={handleSubmitNewLabel}
                className="btn-save-purpose px-[8px] py-[4px] rounded-8 h-auto"
                disabled={label.trim() === ""}
              >
                Save
              </Button>
            </div>
          ) : (
            <IconButton
              name={purpose.isHidden ? "eye-slash" : "eye"}
              onClick={handleToggleVisibility}
            />
          )}
        </div>
        {error ? (
          <Text
            paragraph
            color="red"
            className="mt-[5px] text-[11px] animate-slideTopBottom"
          >
            {error}
          </Text>
        ) : null}
      </Draggable>
    </li>
  );
}

const ZONES = {
  BEFORE: "BEFORE",
  AFTER: "AFTER",
};
function Draggable({ children, purpose }) {
  const dragCtx = useContext(DragContext);
  const [activeDropZone, setActiveDropZone] = useState(null);

  const elRef = useRef();

  const handleDragStart = () => {
    setTimeout(() => {
      dragCtx.update({ itemId: purpose.id });
    }, 100);
  };

  const handleDragEnd = (e) => {
    e.dataTransfer.dropEffect = "move";
    dragCtx.update({ itemId: null });
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!dragCtx.category || !dragCtx.itemId) return;
    if (dragCtx.category !== purpose.category) return;
    if (dragCtx.itemId === purpose.id) return;

    const el = elRef.current;

    if (!el) return;

    const elRect = el.getBoundingClientRect();
    if (e.clientY > elRect.y && e.clientY < elRect.y + elRect.height) {
      if (e.clientY < elRect.y + elRect.height / 2) {
        setActiveDropZone(ZONES.BEFORE);
      } else {
        setActiveDropZone(ZONES.AFTER);
      }
    }
  };

  const handleDragLeave = () => {
    setActiveDropZone(null);
  };

  const handleDrop = () => {
    setActiveDropZone(null);

    if (!dragCtx.category || !dragCtx.itemId) return;
    if (dragCtx.category !== purpose.category) return;
    if (dragCtx.itemId === purpose.id) return;

    dragCtx.reorder({
      movedId: dragCtx.itemId,
      targetId: purpose.id,
      setAfter: activeDropZone === ZONES.AFTER,
    });
  };

  let cls = "rounded";
  cls += " opacity-[0.99]"; // chrome hack to have rounded border for drag image
  if (dragCtx.itemId) {
    cls += " [&_.draggable-content_*]:pointer-events-none"; // disable pointer events on children to prevent dragEnd event
    if (dragCtx.itemId === purpose.id) {
      cls += " hidden";
    }
  }

  if (activeDropZone === ZONES.BEFORE) {
    cls += " pt-[40px]";
  } else if (activeDropZone === ZONES.AFTER) {
    cls += " pb-[40px]";
  }

  return (
    <div
      ref={elRef}
      draggable={dragCtx.isEnabled}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      className={cls}
    >
      <div className="draggable-content">{children}</div>
    </div>
  );
}
