import React, { useEffect, useState } from "react";

import Icon from "src/components/elements/Icon";
import Input from "src/components/elements/Input";

import Text from "./Text";

export default PasswordInput;

function PasswordInput({
  className,
  inputClassName,
  value,
  onChange,
  disabled,
  valid,
  errorMessage,
  forChangePassword,
  ...rest
}) {
  const [val, setVal] = useState(value);
  const [type, setType] = useState("password");

  const updateVal = (val) => {
    setVal(val);
    if (typeof onChange === "function") onChange(val);
  };

  const handleChange = (e) => {
    let val = e.target.value || "";
    updateVal(val);
  };

  const togglePassword = () => {
    if (type === "password") {
      setType("text");
      return;
    }

    setType("password");
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  const classes = `miq-password-input-wrap ${className || ""}`;
  const inpClasses = `miq-password-input ${inputClassName || ""}  ${
    forChangePassword && "mb-[5px]"
  }`;

  return (
    <div className={classes}>
      <Input
        type={type}
        className={inpClasses}
        leftIcon={<Icon name="lock" className="left-icon" />}
        rightIcon={
          !disabled && (
            <Icon
              onClick={togglePassword}
              name={type == "password" ? "eye-fixed" : "eye-slash"}
              className={`eye right-icon ${!valid && "has-error"} black-op30`}
              color={"black-op30"}
            />
          )
        }
        {...rest}
        onChange={handleChange}
        value={val}
        valid={valid}
        disabled={disabled}
      />
      {!valid && !forChangePassword && (
        <Text
          paragraph
          className="w-full text-left mt-[10px]"
          regular
          color="red"
          data-testid="password-error-message"
        >
          {errorMessage}
        </Text>
      )}
      {!valid && forChangePassword && (
        <Text md regular color="red" data-testid="password-error-message">
          {errorMessage}
        </Text>
      )}
    </div>
  );
}
