import QRCode from "qrcode";
import React, { useEffect, useState } from "react";

export default QR;

function QR({ width, height, url }) {
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    QRCode.toDataURL(url, {
      width,
      height,
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 0.7,
      margin: 1,
    }).then((du) => setDataUrl(du));
  }, [url]);

  return <>{dataUrl ? <img src={dataUrl} /> : null}</>;
}
