export default class ee {
  constructor(t, s = !1, i = {}) {
    (this.id = t), (this.enabled = s), (this.properties = i);
  }
  ss() {
    const t = {};
    return (
      (t[ee.hs.ns] = this.id),
      (t[ee.hs.Fe] = this.enabled),
      (t[ee.hs.we] = this.properties),
      t
    );
  }
}
(ee.hs = { ns: "id", Fe: "e", we: "pr" }),
  (ee.At = { ns: "id", Fe: "enabled", we: "properties" });
