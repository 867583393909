/** @typedef {import("src/models/drive").default} Drive */
/** @typedef {import("src/models/purpose").default} Purpose */
/** @typedef {import("src/models/location").default} DriveLocation */

/** @typedef {import("src/models/user").default} User */
/** @typedef {import("src/models/team-member").default} TeamMember */

/** @typedef {import("src/models/team").default} Team */

/** @typedef {import("src/models/rates/RatesUS").default} RatesUS */
/** @typedef {import("src/models/rates/RatesUK").default} RatesUK */
/** @typedef {import("src/models/rates/RatesCA").default} RatesCA */
