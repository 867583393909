import React from "react";

export default PageLayout;

function PageLayout({ children, className }) {
  return <div className={`page-layout ${className || ""}`}>{children}</div>;
}

PageLayout.Main = function ({ children, className }) {
  return (
    <div className={`page-layout-main ${className || ""}`}>{children}</div>
  );
};

PageLayout.Sidebar = function ({ children, className, asOverlay, withShadow }) {
  return (
    <div
      className={`page-layout-sidebar overflow-auto ${
        asOverlay ? "as-overlay animate-slideRightSidebar" : ""
      } ${withShadow ? "with-shadow" : ""} ${className || ""}`}
    >
      {children}
    </div>
  );
};
