import React, { useState } from "react";

import Dropdown, { MiqDropdownItem } from "src/components/elements/Dropdown";
import IconButton from "src/components/elements/IconButton";

export default DropdownFilter;

function DropdownFilter({
  options = [],
  value,
  onSelect,
  onOpen,
  clearable = true,
  onClear,
  label = "Dropdown Filter",
  icon = "file-text",
  tooltip,
  dropdownOptions = {},
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (selectedOption) => {
    setIsOpen(false);
    onSelect?.(selectedOption);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    handleSelect(null);
    onClear?.();
  };

  const handleOpen = () => {
    const cancelOperation = onOpen?.();

    if (cancelOperation) return;

    setIsOpen(true);
  };

  const selectedOption = options.find((option) => option.value === value);
  const hasValue = Boolean(value) && !selectedOption?.default;

  return (
    <Dropdown
      icon={icon}
      iconColor="no-color"
      label={selectedOption?.label || label}
      open={isOpen}
      hasValue={hasValue}
      onOpen={handleOpen}
      onClose={() => setIsOpen(false)}
      tooltip={
        tooltip && {
          text: tooltip,
          alignX: "center",
          offset: { y: 50 },
        }
      }
      suffix={
        hasValue && clearable ? (
          <IconButton name="close" onClick={handleClear} />
        ) : null
      }
      triggerClassName="border-2 border-transparent active:border-blue hover:border-blue/30"
      openTriggerClassName="border-blue hover:border-blue active:border-blue"
      {...dropdownOptions}
    >
      {options.map(
        ({ label: optionLabel, value: optionValue, icon: optionIcon }) => (
          <MiqDropdownItem
            icon={optionIcon}
            key={optionLabel}
            onClick={() => handleSelect(optionValue)}
          >
            {optionLabel}
          </MiqDropdownItem>
        )
      )}
    </Dropdown>
  );
}
