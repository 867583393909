import React from "react";

const DrivesContext = React.createContext({
  selectedDrives: [],
  updateSelectedDrives: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
  onJoin: () => {},
  onPurposeSelectedFromDriveDetails: () => {},
});

export default DrivesContext;
