import e, { OPTIONS as T } from "../../managers/braze-instance.js";
export function loadFontAwesome() {
  if (e.nn(T.Oo)) return;
  const t = "https://use.fontawesome.com/7f85a56ba4.css";
  if (
    !(null !== document.querySelector('link[rel=stylesheet][href="' + t + '"]'))
  ) {
    const e = document.createElement("link");
    e.setAttribute("rel", "stylesheet"),
      e.setAttribute("href", t),
      document.getElementsByTagName("head")[0].appendChild(e);
  }
}
