import React, { useEffect, useRef, useState } from "react";

import Text from "src/components/elements/Text";

import SmallSpinner from "public/assets/img/icons/spinner-yellow.svg";
import Spinner from "public/assets/img/spinner.svg";

export default MiqLoader;

function MiqLoader({ message, timeout, sm }) {
  const [showMessage, setShowMessage] = useState(message && !timeout);
  const t = useRef(null);

  useEffect(() => {
    if (message && timeout) {
      t.current = setTimeout(() => setShowMessage(true), timeout);
    }
    return () => {
      if (t.current) clearTimeout(t.current);
    };
  }, []);

  useEffect(() => {
    setShowMessage(message && !timeout);
  }, [message, timeout]);

  return (
    <div className="w-full min-h-full flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div>
          <span className="inline-block animate-spin">
            {sm ? <SmallSpinner /> : <Spinner />}
          </span>
        </div>
        {message && showMessage && (
          <Text paragraph lg className="mt-8 pt-4 px-8 text-center">
            {message}
          </Text>
        )}
      </div>
    </div>
  );
}
